<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import type { DescriptionDto } from '@/api';
import highlight from '@/utils/filters/highlight';
import src from '@/utils/filters/src';
import i18n from '@/i18n';
import { onMounted } from 'vue';
import HtmlView from '@/components/html-view/Html-View.vue';
import SRichTextViewer from '@/components/SRichTextViewer.vue';

interface ExternalDescriptionProps {
  description: DescriptionDto;
  search?: string;
  shouldShowRichtext?: boolean;
}

const props = defineProps({
  description: null,
  search: { default: '' },
  shouldShowRichtext: { type: Boolean, default: false }
});

const descriptionRef = _ref<InstanceType<typeof HtmlView>>();

let isOpen = _ref(false);
let canFold = _ref(false);

const imgsrc = _computed(() => src(`${props.description.sourceType}-lock`, 'datasources'));
const alt = _computed(() => props.description?.sourceType);
const matchedDescription = _computed(() => highlight(props.description.description || '', props.search || ''));
const text = _computed(() => (isOpen.value ? i18n.t('common.words.show_less') : i18n.t('common.words.show_more')));
const classes = _computed(() => (isOpen.value ? 'limit-line limit-line--no-limit' : 'limit-line limit-line-2'));

const setCanFold = () => {
  if (!props.shouldShowRichtext) {
    const el = descriptionRef.value?.$el;
    canFold.value = el?.scrollHeight && el?.clientHeight ? el?.scrollHeight > el?.clientHeight : false;
  }
};

const toggleOpenState = () => {
  isOpen.value = !isOpen.value;
};

onMounted(() => setCanFold());
</script>

<template lang="pug">
.d-flex
  .d-flex.align-start
    VAvatar.mr-1(tile size="16")
      img( :src="imgsrc" :alt="alt")
    .d-block( v-resize="setCanFold" v-if="!shouldShowRichtext")
      HtmlView.grey--text.caption( ref="descriptionRef" :class="classes" :html="matchedDescription" )
      span.caption( v-if="canFold" class="text-decoration-underline blue--text pointer" @click="toggleOpenState") {{ text }}
    div(v-else)
      SRichTextViewer(:content="description.description")
</template>
