<template lang="pug">
div
  v-list-item.ml-0.pl-0(v-if="!isFilter")
    v-icon( left ) {{ icon }}
    .d-flex.align-center
      span.font-weight-medium.ml-1.text-h5 {{ title }}
      a.ml-3.mt-2.pointer.link(v-if="link" @click="$router.push(link)") {{ $t('dashboard.see_all') }}
      v-chip.ml-2(v-if="isBeta" small outlined color="textPrimary") {{ $t('common.words.beta') }}
  .d-flex.align-start(v-else)
    v-icon.mt-2( left style="width: 24px;" ) {{ icon }}
    span.mt-1.font-weight-medium(style="width:194px; font-size: 24px;") {{ title }}

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';

const auth = getModule(authModule, store);

@Component
export default class DashboardTitle extends Vue {
  @Prop({ type: String }) readonly title!: string;

  @Prop({ type: String }) readonly icon!: string;

  @Prop({ type: String }) readonly link!: string;

  @Prop({ type: Boolean, default: false }) readonly isBeta!: boolean;

  @Prop({ type: Boolean, default: false }) readonly isFilter!: boolean;
}
</script>
