<template lang="pug">
.d-flex
    v-avatar(tile size="20" class="flex-grow-0 flex-shrink-0 mr-1" )
      img( :src="image" :alt="$t('common.words.image')" )
    span.text-truncate.text-capitalize {{ datasource.platformLabel }}

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DatasourceCatalogAssetDto } from '@/api';
import { getProviderIconSrc } from '@/utils/provider-icons';

@Component
export default class DatasourceIcon extends Vue {
  @Prop(Object) datasource!: DatasourceCatalogAssetDto;

  get image() {
    return getProviderIconSrc(this.datasource.platform);
  }
}
</script>
