/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DatapointDto = {
    date: string;
    groupValuesHash?: string;
    type: DatapointDto.type;
};

export namespace DatapointDto {

    export enum type {
        SCALAR = 'SCALAR',
        RULE_RESULT = 'RULE_RESULT',
        SCALAR_EXPECTATION = 'SCALAR_EXPECTATION',
    }


}
