import { type MonitorSettingsOptions } from './common/types';
import { staticSettings } from './common/thresholdSettings';

const SiffletAverageRule: MonitorSettingsOptions = {
    thresholdSettings: [
        staticSettings,
    ],
};

export default SiffletAverageRule;
