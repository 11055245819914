<template lang="pug">
v-container(fluid)
  v-row

    v-slide-group.full-width.mt-4(
      v-if="recommendations.length && !loading"
      show-arrows="always" )
      v-slide-item.mr-6(
        v-for="recommendation in recommendations"
        :key="recommendation.id")
        DashboardAiAssistantMonitorsCard(
          :recommendation="recommendation"
          @onFeedbackSent="onFeedbackSent"
          @openModal="onModalOpen")

    .d-flex.mx-10.mt-4( v-else-if="loading" )
      v-skeleton-loader.mx-3(
        v-for="n in 3"
        :key="n"
        width="320"
        type="card")

    EmptyDataPlaceholder(v-else)

    ModalDialog(ref="modalDialog" :title="dialogTitle")
      template(v-slot:body)
        HtmlView(:html="dialogDescription").mt-4
        img.mt-7(
          :src="dialogImage"
          :alt="recommendationModalType"
          width="550")

</template>

<script lang="ts">
import {
  Component,
  Vue,
  Ref,
  Watch,
} from 'vue-property-decorator';
import {
  RuleMonitoringRecommendationDto,
  RuleService,
  DescriptionPredictionFeedbackDto,
  RuleMonitoringRecommendationChangeDto,
} from '@/api';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import {
  aiAssistantGenerateModalDescription,
  aiAssistantGenerateModalImg,
  aiAssistantGenerateModalTitle,
} from '@/utils/AiAssistantGenerators';
import store from '@/store';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import dashboardModule from '@/store/modules/dashboard';

const auth = getModule(authModule, store);
const dashboard = getModule(dashboardModule, store);

@Component
export default class DashboardAiAssistantMonitors extends Vue {
  @Ref() modalDialog! : ModalDialog;

  recommendations: RuleMonitoringRecommendationDto[] = [];

  loading = true;

  recommendationModalType: RuleMonitoringRecommendationChangeDto.recommendationType = RuleMonitoringRecommendationChangeDto.recommendationType.OFFSET;

  get domain() {
    return auth.selectedDomain?.name;
  }

  get dialogTitle() {
    return aiAssistantGenerateModalTitle(this.recommendationModalType);
  }

  get dialogDescription() {
    return aiAssistantGenerateModalDescription(this.recommendationModalType);
  }

  get dialogImage() {
    return aiAssistantGenerateModalImg(this.recommendationModalType);
  }

  get tags() {
    return dashboard.getSelectedTags;
  }

  @Watch('tags', { deep: true })
  onTagsChange() {
    this.getRecommendations();
  }

  onModalOpen(recommendationType: RuleMonitoringRecommendationChangeDto.recommendationType) {
    this.recommendationModalType = recommendationType;
    this.modalDialog.openDialog();
  }

  onFeedbackSent(id: string) {
    this.recommendations = [...this.recommendations.filter((item) => item.id !== id)];
  }

  getRecommendations() {
    RuleService.getAllRuleMonitoringRecommendations({
      domain: this.domain,
      tag: this.tags,
      validationStatus: DescriptionPredictionFeedbackDto.validationStatus.NO_FEEDBACK,
      itemsPerPage: 150,
    }).then((response) => {
      this.recommendations = response.data!;
      this.loading = false;
    });
  }

  mounted() {
    this.getRecommendations();
  }
}
</script>
