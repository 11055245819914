/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DatasourceParams } from './DatasourceParams';

export type MysqlParams = (DatasourceParams & {
    database?: string;
    host?: string;
    mysqlTlsVersion?: MysqlParams.mysqlTlsVersion;
    port?: number;
} & {
    database: string;
    host: string;
    mysqlTlsVersion: MysqlParams.mysqlTlsVersion;
    port: number;
});

export namespace MysqlParams {

    export enum mysqlTlsVersion {
        TLS_V_1_2 = 'TLS_V_1_2',
        TLS_V_1_3 = 'TLS_V_1_3',
    }


}
