/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CreateJiraConfigDto } from './CreateJiraConfigDto';

export type CreateCollaborationToolConfigDto = {
    config: CreateJiraConfigDto;
    type: CreateCollaborationToolConfigDto.type;
};

export namespace CreateCollaborationToolConfigDto {

    export enum type {
        JIRA = 'JIRA',
    }


}
