/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AsCodeDistributionThresholdDto = {
    kind: AsCodeDistributionThresholdDto.kind;
};

export namespace AsCodeDistributionThresholdDto {

    export enum kind {
        DYNAMIC = 'Dynamic',
        STATIC = 'Static',
    }


}
