<template lang="pug">
v-card
  v-container( fluid style="min-height: 500px" )
    v-row
      v-col( cols="12" )
        .font-weight-medium.mb-2 {{ $t('common.words.timeline') }}
        InputComment(
          v-model="comment"
          outlined
          placeholder="Add a comment"
          height="130"
          @update:error="hasError"
          style="min-width: 500px" )
        v-btn(
          @click="addComment"
          :disabled="!comment"
          color="secondary"
          class="custom-secondary"
          text outlined) {{ $t('cta.save') }}
      v-col( cols="12" )
        v-timeline( dense )
          TimelineEvent( v-for="event in events" :value="event" :key="event.id" )

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { EventDto, IncidentService } from '@/api';
import store from '@/store';
import authModule from '@/store/modules/auth';
import eventBus, { EventType } from '@/utils/eventBus';

const auth = getModule(authModule, store);

@Component
export default class Timeline extends Vue {
  @Prop({ type: String, required: true }) issue!: string;

  events: EventDto[] = [];

  comment = '';

  error = false;

  id: string | null = null;

  get canComment() {
    return this.comment && !this.error;
  }

  get issueNo() {
    return Number(this.issue);
  }

  async getEvents() {
    this.events = (await IncidentService.getIncidentEventsByIssueNumber({ issueNo: this.issueNo, itemsPerPage: 100 })).data;
  }

  async addComment() {
    const payload = { userId: auth.user?.id, comment: this.comment };
    if (!this.id) {
      this.id = await (await IncidentService.getIncidentByIssueNumber({ issueNo: this.issueNo })).id;
    }
    await IncidentService.addComment({ id: this.id, requestBody: payload });
    this.comment = '';
    this.getEvents();
  }

  hasError(error: boolean) {
    this.error = error;
  }

  mounted() {
    this.getEvents();
    eventBus.$on(EventType.REFRESH_ISSUE, this.getEvents);
  }

  beforeDestroy() {
    eventBus.$off(EventType.REFRESH_ISSUE, this.getEvents);
  }
}
</script>
