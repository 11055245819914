<script setup lang="ts">import { computed as _computed } from 'vue';

import { IncidentLightDto } from '@/api';
import i18n from '@/i18n';

interface StatusProps {
  status: IncidentLightDto.status,
  qualification?: IncidentLightDto.qualification | null
}

const props = defineProps({
  status: null,
  qualification: null
});

const status = _computed(() => {
  switch (props.status) {
    case IncidentLightDto.status.OPEN:
      return {
        text: i18n.t('incidents.statuses.OPEN'),
        color: 'borderAttention',
        icon: 'icon-arrow-up-circle-fill',
        iconColor: 'iconAttention',
      };
    case IncidentLightDto.status.IN_PROGRESS:
      return {
        text: i18n.t('incidents.statuses.IN_PROGRESS'),
        color: 'borderAccent',
        icon: 'icon-arrow-right-circle-fill',
        iconColor: 'iconInfo',
      };
    case IncidentLightDto.status.CLOSED:
      return {
        text: i18n.t('incidents.statuses.CLOSED') + (props.qualification ? ` • ${i18n.t(`incidents.qualifications.${props.qualification}`)}` : ''),
        color: 'borderPrimary',
        icon: 'icon-check-circle-fill',
        iconColor: 'iconNeutral',
      };
    default:
      return {
        text: '',
        color: 'grey',
        icon: '',
        iconColor: '',
      };
  }
});
</script>

<template lang="pug">
v-chip(:color="status.color" small outlined)
  v-icon.mr-1(small :color="status.iconColor") {{ status.icon }}
  span.status-text.font-weight-medium {{ status.text }}
</template>

<style lang="scss" scoped>
.status-text {
  color: var(--v-textPrimary-base);
}
</style>
