<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="schemaRules"
    label='Schema'
    data-cy="schema"
    dense clearable outlined required)

</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputSchema extends Vue {
  schemaRules = [(v: string) => isConvertibleToNotBlankString(v) || 'Schema is required'];
}
</script>
