/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertingHookDto } from '../models/AlertingHookDto';
import type { SearchCollectionAlertingHookDto } from '../models/SearchCollectionAlertingHookDto';
import type { SearchCollectionMailAlertingHookDto } from '../models/SearchCollectionMailAlertingHookDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AlertingHookService {

    /**
     * Get alerting hooks
     * @returns SearchCollectionAlertingHookDto List of AlertingHooks
     * @throws ApiError
     */
    public static getAllAlertingHooks({
        textSearch,
        type,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * Filter on given alerting hook types
         */
        type?: 'SLACK' | 'MAIL' | 'MS_TEAMS',
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<SearchCollectionAlertingHookDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/alerting-hooks',
            query: {
                'textSearch': textSearch,
                'type': type,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create an AlertingHook
     * @returns AlertingHookDto Successfully create AlertingHook
     * @throws ApiError
     */
    public static createAlertingHook({
        requestBody,
    }: {
        requestBody: AlertingHookDto,
    }): CancelablePromise<AlertingHookDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/alerting-hooks',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all mail alerting hooks
     * @returns SearchCollectionMailAlertingHookDto List of AlertingHooks
     * @throws ApiError
     */
    public static getAllMailAlertingHooks(): CancelablePromise<SearchCollectionMailAlertingHookDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/alerting-hooks/mails',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete an AlertingHook
     * @returns AlertingHookDto Successfully deleted AlertingHook
     * @throws ApiError
     */
    public static deleteAlertingHook({
        id,
    }: {
        id: string,
    }): CancelablePromise<AlertingHookDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/alerting-hooks/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get AlertingHook by id
     * @returns AlertingHookDto Successfully fetch AlertingHook
     * @throws ApiError
     */
    public static getAlertingHookById({
        id,
    }: {
        id: string,
    }): CancelablePromise<AlertingHookDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/alerting-hooks/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
