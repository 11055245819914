<template lang="pug">
ModalDialog( ref="modalDialogRef" :title="$t('common.words.sql')" full )

  template( v-slot:body )
    v-row
      v-col(cols="12")
        InputCode( :value="sqlQuery" resize-behavior="none" :read-only="true" )

  template( v-slot:actions )
    v-btn( depressed color="primary" @click="copySql")
      v-icon( left ) $content_copy
      | {{ $t('common.words.copy') }}

</template>

<script lang="ts">
import {
  Component, Prop, Vue, Ref,
} from 'vue-property-decorator';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import InputCode from '@/components/inputs/Input-Code.vue';
import copyToClipboard from '@/utils/copyToClipboard';

@Component({
  components: { InputCode },
})
export default class SqlDialog extends Vue {
  @Prop({ required: true })
  readonly sqlStatement!: string | null;

  get sqlQuery() {
    return this.sqlStatement;
  }

  @Ref() modalDialogRef! : ModalDialog;

  open() {
    this.modalDialogRef.openDialog();
  }

  copySql() {
    if (this.sqlStatement) {
      copyToClipboard(this.sqlStatement, 'SQL copied to clipboard');
    }
  }
}
</script>

<style lang="scss" scoped>
.sql-code {
  max-height: 70vh;
  font-size: 14px;
}
</style>
