/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SearchCollectionTagDto } from '../models/SearchCollectionTagDto';
import type { SearchCollectionTagWithAssetCountDto } from '../models/SearchCollectionTagWithAssetCountDto';
import type { SearchCollectionV2TagWithAssetCountDto } from '../models/SearchCollectionV2TagWithAssetCountDto';
import type { TagCreateDto } from '../models/TagCreateDto';
import type { TagDto } from '../models/TagDto';
import type { TagUpdateDto } from '../models/TagUpdateDto';
import type { TagWithAssetsDto } from '../models/TagWithAssetsDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TermService {

    /**
     * Get terms
     * @returns SearchCollectionTagDto List of Term
     * @throws ApiError
     */
    public static getAllTerm({
        textSearch,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<SearchCollectionTagDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/terms',
            query: {
                'textSearch': textSearch,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a Term
     * @returns TagDto Successfully create Term
     * @throws ApiError
     */
    public static createTerm({
        requestBody,
    }: {
        requestBody: TagCreateDto,
    }): CancelablePromise<TagDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/terms',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get term with assets
     * @returns TagWithAssetsDto Term with assets
     * @throws ApiError
     */
    public static getTermWithAssets({
        assetFilterType,
        tagId,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        /**
         * Filter on given asset types
         */
        assetFilterType?: Array<'TABLE_AND_VIEW' | 'PIPELINE' | 'DASHBOARD' | 'MONITOR' | 'ML_MODEL' | 'GENERIC'>,
        /**
         * Filter on given tag id
         */
        tagId?: string,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<TagWithAssetsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/terms/with-assets',
            query: {
                'assetFilterType': assetFilterType,
                'tagId': tagId,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get terms with count
     * @returns SearchCollectionTagWithAssetCountDto List of Terms with count
     * @throws ApiError
     */
    public static getAllTermsWithCount({
        textSearch,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<SearchCollectionTagWithAssetCountDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/terms/with-count',
            query: {
                'textSearch': textSearch,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get terms with count and overall elements
     * @returns SearchCollectionV2TagWithAssetCountDto List of Term with count and overall elements
     * @throws ApiError
     */
    public static getAllTermsWithCountEnhanced({
        textSearch,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<SearchCollectionV2TagWithAssetCountDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/terms/with-count-enhanced',
            query: {
                'textSearch': textSearch,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a Term
     * @returns void
     * @throws ApiError
     */
    public static deleteTerm({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/terms/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get Term by id
     * @returns TagDto Successfully fetch Term
     * @throws ApiError
     */
    public static getTermById({
        id,
    }: {
        id: string,
    }): CancelablePromise<TagDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/terms/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a Term
     * @returns TagDto Successfully update Term
     * @throws ApiError
     */
    public static updateTerm({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: TagUpdateDto,
    }): CancelablePromise<TagDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ui/v1/terms/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
