<template lang="pug">
v-item-group( :value="idpsIndex" @change="change" mandatory )
    v-row
      v-col(
        v-for="( values, name ) in idps"
        class="d-flex col-3"
        :key="name")
        v-item( v-slot="{ active, toggle }" )
          RuleCategoryCard(
            :data-cy="values.title"
            v-bind="$attrs"
            :active="active"
            :disabled="disabled"
            :value="values.title"
            @click.native="toggle")
</template>

<script lang="ts">
import { IDPJsonSchemaParamsDto } from '@/api';
import {
  Vue, Component, Model, Prop,
} from 'vue-property-decorator';

@Component
export default class RuleCategorySelector extends Vue {
  @Prop({ type: Object }) idps!: Record<string, IDPJsonSchemaParamsDto>;

  @Model('input', String) selectedIdp!: string;

  @Prop({ type: Boolean }) readonly disabled!: boolean;

  get idpsIndex() {
    return Object.keys(this.idps).indexOf(this.selectedIdp);
  }

  change(value: number) {
    const selectedIdp = Object.keys(this.idps)[value];
    this.$emit('input', selectedIdp);
  }
}
</script>
