<template lang="pug">
ModalDialog( ref="modalDialog" :title="$t('sources.datasource_ingestion_run_summary')" )

  template( v-slot:body v-if="ingestionRun && ingestionRun.runSummary")
    v-col(cols='12')
      v-row
        v-list-item.two-line
          v-list-item-content
            v-list-item-title {{ $t('sources.execution_date') }}
            v-list-item-subtitle
              DateTooltip( :value="ingestionRun.createdDate" )
      v-row
        v-list-item.two-line
          v-list-item-content
            v-list-item-title {{ $t('sources.duration') }}
            v-list-item-subtitle
              span {{ durationtime() }}
      v-row
        v-list-item.two-line
          v-list-item-content
            v-list-item-title {{ $t('sources.status') }}
            v-list-item-subtitle
              span.text-capitalize {{ ingestionRun?.lastStatus?.toLowerCase() }}
      v-row
        v-list-item.two-line
          v-list-item-content
            v-list-item-title {{ $t('sources.type') }}
            v-list-item-subtitle
              span {{ ingestionRun.trigger }}
      v-row( v-if="ingestionRunSummary?.errors")
        v-list-item.two-line
          v-list-item-content
            v-list-item-title {{ $t('sources.errors_encountered') }}
            v-simple-table( dense )
              template( v-slot:default )
                tbody
                  tr( v-for="error in ingestionRunSummary.errors" :key="error")
                    td {{ error }}
    </template>

<script lang="ts">
import {
  Vue, Component, Prop, Ref,
} from 'vue-property-decorator';
import { DatasourceIngestionRunDto, IngestionRunSummary } from '@/api';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import dayjs from 'dayjs';

export type DatasourceRunSummaryProps = {
  ingestionRun?: DatasourceIngestionRunDto,
  ingestionRunSummary?: IngestionRunSummary;
};

@Component
export default class DatasourceRunSummaryDialog extends Vue {
  @Prop(Object) readonly ingestionRun!: DatasourceIngestionRunDto;

  @Prop(Object) readonly ingestionRunSummary?: IngestionRunSummary;

  @Ref() modalDialog!: ModalDialog;

  open() {
    this.modalDialog.openDialog();
  }

  close() {
    this.modalDialog.closeDialog();
  }

  durationtime() {
    return dayjs.duration(this.ingestionRun.durationInSeconds!, 'seconds').format('H[h] m[m] s[s]')
      .replace(' 0s', '')
      .replace(' 0m', '')
      .replace('0h', '');
  }
}
</script>
