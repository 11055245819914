/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AssetOverview } from './AssetOverview';

export type DashboardAssetOverview = (AssetOverview & {
    type?: DashboardAssetOverview.type;
});

export namespace DashboardAssetOverview {

    export enum type {
        LOOKER_DASHBOARD = 'LOOKER_DASHBOARD',
        TABLEAU_DASHBOARD = 'TABLEAU_DASHBOARD',
        MICROSTRATEGY_DASHBOARD = 'MICROSTRATEGY_DASHBOARD',
        MICROSTRATEGY_REPORT = 'MICROSTRATEGY_REPORT',
        POWERBI_REPORT = 'POWERBI_REPORT',
        POWERBI_DASHBOARD = 'POWERBI_DASHBOARD',
        QUICKSIGHT_DASHBOARD = 'QUICKSIGHT_DASHBOARD',
        QUICKSIGHT_ANALYSIS = 'QUICKSIGHT_ANALYSIS',
    }


}
