<template lang="pug">
v-card.py-5.px-6( outlined fill-height style="height: 100%;" )
  v-row
    v-col( cols="6" )
      h5.h5.font-weight-medium.text-h6 {{ $t('dashboard.tabs.rule_activity') }}
    v-col( cols="6" )
      v-select(
        :items="statusList"
        v-model="status"
        outlined
        dense
        :menu-props="{ bottom: true, offsetY: true }"
      )
  v-data-iterator(
    :items="monitors"
    :options.sync="options"
    hide-default-footer
    :loading="loading" )

    template(v-slot:loading)
      MonitorsResultsPlaceholder

    template(v-slot:no-data)
      NoDataAvailablePlaceholder( :text="$t('no_data.no_monitors_found')")

    template(v-slot:default="props")
      DashboardDataRuleActivityCard(
        v-for="rule in props.items"
        :key="rule.id"
        :rule="rule" )

</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import dashboardModule from '@/store/modules/dashboard';
import authModule from '@/store/modules/auth';
import { RuleCatalogAssetDto, RuleService } from '@/api';

let dashboard: dashboardModule;
let auth: authModule;

@Component
export default class DashboardDataRuleActivity extends Vue {
  statusList = [
    { text: 'All run types', value: '' },
    { text: 'Successful last runs', value: 'SUCCESS' },
    { text: 'Failed last runs', value: 'FAILED' },
    { text: 'Requires attention', value: 'REQUIRES_YOUR_ATTENTION' },
  ];

  status: 'PENDING' | 'RUNNING' | 'SUCCESS' | 'FAILED' | 'TECHNICAL_ERROR' | 'REQUIRES_YOUR_ATTENTION' = 'FAILED';

  monitors: RuleCatalogAssetDto[] = [];

  loading = false;

  get tag() {
    return dashboard.selectedTags;
  }

  get domain() {
    return auth.selectedDomain?.name;
  }

  get options() {
    return {
      itemsPerPage: 5,
      page: 0,
    };
  }

  set options(value) {
    this.options.page = value.page;
    this.options.itemsPerPage = value.itemsPerPage;
  }

  @Watch('tag')
  @Watch('status')
  setFilter() {
    this.searchMonitors();
  }

  async searchMonitors() {
    this.loading = true;
    const res = await RuleService.getAllRule({
      page: this.options.page,
      itemsPerPage: this.options.itemsPerPage,
      tag: this.tag,
      domain: this.domain,
      lastRunStatus: [this.status],
    });
    this.monitors = res.searchRules.data!;
    this.loading = false;
  }

  beforeCreate() {
    dashboard = getModule(dashboardModule, store);
    auth = getModule(authModule, store);
  }

  mounted() {
    this.searchMonitors();
  }
}
</script>
