/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RuleMonitoringRecommendationChangeDto = {
    recommendationType: RuleMonitoringRecommendationChangeDto.recommendationType;
};

export namespace RuleMonitoringRecommendationChangeDto {

    export enum recommendationType {
        INTERLINKED_METRIC_TIME_FIELD = 'INTERLINKED_METRIC_TIME_FIELD',
        TIME_FIELD = 'TIME_FIELD',
        TIME_DEPTH = 'TIME_DEPTH',
        OFFSET = 'OFFSET',
        SENSITIVITY = 'SENSITIVITY',
    }


}
