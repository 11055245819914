<template lang="pug">
div
  RuleGraph( v-if="hasGraph" :id="id" :run-id="item.id" :key="item.id" )
  RunAggregatedDetails( v-if="hasGroupBy" :id="id" :run-id="item.id" :key="item.id" )
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { RuleInfoDto, RuleRunDto } from '@/api';

@Component
export default class RulegOverviewRuns extends Vue {
  @Prop(String) readonly id!: RuleInfoDto['id'];

  @Prop(Object) readonly item!: RuleRunDto;

  get hasGraph() {
    return this.item.hasGraph;
  }

  get hasGroupBy() {
    return this.item.hasGroupBy;
  }
}
</script>
