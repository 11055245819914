import Vue, { getCurrentInstance } from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/es5/locale/fr';
import en from 'vuetify/es5/locale/en';
import theme from './theme';
import icons from './icons';

Vue.use(Vuetify);

export default new Vuetify({
  // customVariables: ['~/assets/variables.scss'],
  icons,
  theme,
  lang: {
    locales: { fr, en },
    current: 'en',
  },
});

export const useVuetify = () => {
  const vm = getCurrentInstance();
  return vm?.proxy?.$vuetify || undefined;
};
