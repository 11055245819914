export type AppConfig = {
  VUE_APP_API_URL: string;
  VUE_APP_API_TIMEOUT: string;
  VUE_APP_MAX_MONITORED_TABLES?: string;
  VUE_APP_MIN_PERIOD?: string;
  VUE_APP_MIN_DATASOURCE_PERIOD?: string;
  VUE_APP_I18N_LOCALE?: string;
  VUE_APP_I18N_FALLBACK_LOCALE?: string;
  VUE_APP_TENANT?: string;
  VUE_APP_UNLEASH_URL?: string;
  VUE_APP_UNLEASH_CLIENT?: string;
  VUE_APP_TENANT_NAME: string;
  VUE_APP_UNLEASH_ENVIRONMENT?: 'demo' | 'qa' | 'prod' | 'dev';
  // Values for SENTRY_ENVIRONMENT are aligned on values used by other services (such as the backend)
  VUE_APP_SENTRY_ENVIRONMENT?: 'demo' | 'qa' | 'production' | 'dev';
  VUE_APP_SENTRY_ENABLED?: 'true' | 'false';
}
declare global {

  interface Window {
    configs?: AppConfig;
  }

}

/**
 * Returns the current env
 * @param {string} name - The name of the env
 * @return string - The corresponding env
 */
export default function getEnv(name: keyof AppConfig): string | undefined {
  return window?.configs?.[name] || process.env[name]!;
}
