<template lang="pug">
v-row
  v-col( cols="6")
    InputName( v-model.trim='datasource.name' label='Shared Prefix Name' )

  v-col( cols="6")
    InputHost( v-model.trim='datasource.params.host' )

  v-col( cols="6")
    InputPort( v-model.trim='datasource.params.port' )

  v-col( cols="6")
    InputDatabase( v-model.trim='datasource.params.database' )

  v-col( cols="6" v-if="modifyExisting"  class="border")
    InputSchema( v-model.trim='datasource.params.schema' )

  v-col( cols="6" v-else class="border")
    SchemaSelector(
      type="schemas"
      :tooltip-list-schema="tooltipListSchema"
      :is-scan-active="isScanActive"
      v-model.trim='datasource.params.schema'
      :datasource="datasource")

  v-col( cols="6")
    CredentialsSelector( v-model.trim='datasource.secretId' )

</template>

<script lang="ts">
import {
  Vue, Component, VModel, Watch,
} from 'vue-property-decorator';
import { DatasourceDto, PostgresqlParams } from '@/api';

type PostgresSourceDto = DatasourceDto & { params: PostgresqlParams; };

@Component
export default class PostgresConnection extends Vue {
  @VModel({ type: Object }) datasource!: PostgresSourceDto;

  get modifyExisting() {
    return this.datasource.id;
  }

  get tooltipListSchema() {
    let tooltipValue = '';
    if ('accountIdentifier' in this.datasource.params) {
      tooltipValue += 'The Account identifier';
    }
    if ('host' in this.datasource.params) {
      tooltipValue += 'The Host';
    }
    if ('warehouse' in this.datasource.params) {
      tooltipValue += ', Warehouse identifier';
    }
    if ('port' in this.datasource.params) {
      tooltipValue += ', Port';
    }
    if ('database' in this.datasource.params) {
      tooltipValue += ', Database';
    }
    tooltipValue += ' and Secret';
    tooltipValue += ' must be configured';
    return tooltipValue;
  }

  get isScanActive() {
    let accountIdentifier = true;
    if ('accountIdentifier' in this.datasource.params) {
      accountIdentifier = !!this.datasource.params.accountIdentifier;
    }
    let host = true;
    if ('host' in this.datasource.params) {
      host = !!this.datasource.params.host;
    }
    let warehouse = true;
    if ('warehouse' in this.datasource.params) {
      warehouse = !!this.datasource.params.warehouse;
    }
    let port = true;
    if ('port' in this.datasource.params) {
      port = !!this.datasource.params.port;
    }
    let database = true;
    if ('database' in this.datasource.params) {
      database = !!this.datasource.params.database;
    }
    return !(accountIdentifier && host && warehouse && port && database && this.datasource.secretId);
  }
}
</script>
