<template lang="pug">
v-text-field(
        v-bind="$attrs"
        v-on="$listeners"
        label='Billing Project Id'
        data-cy="billing-project-id"
        dense clearable outlined required)
</template>
<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputBillingProjectId extends Vue {}
</script>
