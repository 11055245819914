import { DatapointQualificationDto, GraphPointSpecialDate, ProviderDto } from '@/api';
import i18n from '@/i18n';

export const QUALIFICATION_SYMBOLS = {
  [DatapointQualificationDto.qualification.FALSE_NEGATIVE]: 'image:///next/icons/material-icons/alert.svg',
  [DatapointQualificationDto.qualification.EXPECTED]: 'image:///next/icons/material-icons/check_grey.svg',
  [DatapointQualificationDto.qualification.FIXED]: 'image:///next/icons/material-icons/check.svg',
  [DatapointQualificationDto.qualification.KNOWN_ERROR]: 'image:///next/icons/material-icons/warning.svg',
  [DatapointQualificationDto.qualification.FALSE_POSITIVE]: 'image:///next/icons/material-icons/check.svg',
  [DatapointQualificationDto.qualification.UNDER_INVESTIGATION]: 'image:///next/icons/material-icons/check.svg',
  [DatapointQualificationDto.qualification.NO_QUALIFICATION]: null,
  SPECIAL_DATE: 'image:///next/icons/material-icons/dot_grey.svg',
};

export const QUALIFICATION_SYMBOLS_WITH_NOTE = {
  [DatapointQualificationDto.qualification.FALSE_NEGATIVE]: 'image:///next/icons/material-icons/alert_dot.svg',
  [DatapointQualificationDto.qualification.EXPECTED]: 'image:///next/icons/material-icons/check_grey_dot.svg',
  [DatapointQualificationDto.qualification.FIXED]: 'image:///next/icons/material-icons/check_dot.svg',
  [DatapointQualificationDto.qualification.KNOWN_ERROR]: 'image:///next/icons/material-icons/warning_dot.svg',
  [DatapointQualificationDto.qualification.FALSE_POSITIVE]: 'image:///next/icons/material-icons/check_dot.svg',
  [DatapointQualificationDto.qualification.UNDER_INVESTIGATION]: 'image:///next/icons/material-icons/check_dot.svg',
  [DatapointQualificationDto.qualification.NO_QUALIFICATION]: 'image:///next/icons/material-icons/note.svg',
  SPECIAL_DATE: 'image:///next/icons/material-icons/dot_grey_dot.svg',
};

export const hints = {
  [DatapointQualificationDto.qualification.FALSE_NEGATIVE]: 'There is an anomaly that the model has not raised',
  [DatapointQualificationDto.qualification.EXPECTED]: 'Expected behavior, for instance for a peak in sales coming from a new product launch or Black Friday sales',
  [DatapointQualificationDto.qualification.FIXED]: 'The issue has been fixed',
  [DatapointQualificationDto.qualification.KNOWN_ERROR]: 'Known issue, but not a priority to fix for now',
  [DatapointQualificationDto.qualification.FALSE_POSITIVE]: 'The alert raised is not correct',
  [DatapointQualificationDto.qualification.NO_QUALIFICATION]: 'No feedback recorded. To use only to have a neutral state. For instance, when the datapoint was qualified by mistake',
  [DatapointQualificationDto.qualification.UNDER_INVESTIGATION]: 'There is an issue, we are currently investigating it',
};

export type TooltipInfo = {
  label: string;
  value: string;
  anomaly?: boolean | null;
};

export type TooltipQualificationInfo = {
  type?: DatapointQualificationDto.qualification|null;
  createdDate: string;
  provider: ProviderDto;
  comment?: string;
  specialDates?: GraphPointSpecialDate[];
};

export const QUALIFICATION_ICONS = {
  FALSE_NEGATIVE: 'icon-dismiss-circle-fill',
  EXPECTED: 'icon-check-circle-fill',
  FIXED: 'icon-check-circle-fill',
  KNOWN_ERROR: 'icon-warning-fill',
  FALSE_POSITIVE: 'icon-check-circle-fill',
  NO_QUALIFICATION: 'icon-circle-outline',
  UNDER_INVESTIGATION: 'icon-circle-outline',
};

export const QUALIFICATION_COLORS = {
  FALSE_POSITIVE: 'green lighten-1',
  EXPECTED: 'grey',
  FIXED: 'green lighten-1',
  KNOWN_ERROR: 'yellow',
  FALSE_NEGATIVE: 'red',
  NO_QUALIFICATION: '',
  UNDER_INVESTIGATION: 'white',
};

export const QUALIFICATION_LABELS: Record<DatapointQualificationDto.qualification, string | null> = {
  NO_QUALIFICATION: null,
  FALSE_NEGATIVE: i18n.t('charts.qualifications.false_negative.title'),
  FALSE_POSITIVE: i18n.t('charts.qualifications.false_positive.title'),
  EXPECTED: i18n.t('charts.qualifications.expected.title'),
  FIXED: i18n.t('charts.qualifications.fixed.title'),
  KNOWN_ERROR: i18n.t('charts.qualifications.known_error.title'),
  UNDER_INVESTIGATION: i18n.t('charts.qualifications.under_investigation.title'),
};
