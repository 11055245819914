import { AnomalyConditionsEnum } from '@/components/monitor-wizard/composables/monitors/common/types';
import { type MonitorSettingsOptions } from './common/types';
import { staticSettings, relativeSettings } from './common/thresholdSettings';

const SiffletCompletenessStaticRule: MonitorSettingsOptions = {
  thresholdSettings: [{
    ...staticSettings,
    anomalyConditions: [
      {
        text: 'monitor_wizard.anomaly_conditions.single_threshold',
        value: AnomalyConditionsEnum.SINGLE_THRESHOLD,
        isInteger: true,
        min: 0,
      },
      {
        text: 'monitor_wizard.anomaly_conditions.compound_threshold',
        value: AnomalyConditionsEnum.COMPOUND_THRESHOLD,
        isInteger: true,
        min: 0,
      },
    ],
  },
    relativeSettings,
  ],
};

export default SiffletCompletenessStaticRule;
