<template lang="pug">
v-container.mt-3( class="px-4" fluid data-cy="sso" id="sso")
  v-alert.mb-n1.pt-3.pb-2.pl-4(
    v-if="hasSSO"
    dense
    outlined
    :colored-border="false"
    class="alert-sso" )
    .d-inline-flex.align-center
      v-icon( small ) icon-info-circle-outline
      .ml-2 {{ $t('sso.success_text') }}

  v-card.card-sso(
    :disabled="hasSSO"
    class="mb-3 border-around pa-6"
    style="width: 100%; border-radius: 4px;" )

    v-row.no-gutters
      v-col.mb-2( cols="auto"  )
        .d-flex.align-center.text-h5( data-cy="access-tokens-page-title" )
          v-icon.mr-2 icon-key
          .text-h5.font-weight-medium  {{ $t('common.names.sso') }}

    v-row.no-gutters
      v-col( cols="12")
        .d-inline-flex.align-center
          v-switch(
            data-cy="sso-switch"
            v-model="switchSso" inset :label="$t('sso.enable_sso')" )
          v-tooltip(top :disabled="switchSso" max-width="300" )
            template(v-slot:activator="{ on, attrs }")
              div(v-on="on" v-bind="attrs")
                v-icon.ml-2.mb-1(small) icon-info-circle-outline
            .caption {{ $t('sso.switch_tooltip') }}

    v-card(:disabled="hasSSO || !switchSso")
      v-row
        v-col( md="2" sm="3" cols="3" )
          .font-weight-medium.mt-2 {{ $t('sso.platform') }}
        v-col( md="10" sm="9" cols="9" )
          IdpPlatformSelector(
            v-model="idpPlatform"
            :disabled="hasSSO || !switchSso"
            :idps="idps")

    v-card.mt-4.mb-2(:disabled="hasSSO || !switchSso")
      v-row
        v-col( md="2" sm="3" cols="3")
          .font-weight-medium.mt-2 {{ $t('sso.method') }}
        v-col( md="6" sm="9" cols="9" )
          IdpMethodSelector(
            v-if="idpPlatform && method"
            v-model="method"
            :disabled="hasSSO || !switchSso"
            :methods="methods")
    SpConfig( :disabled="hasSSO || !switchSso" :config="{ signInMethod, registrationId }" )

    v-card.mt-3(:disabled="hasSSO || !switchSso")
      v-row.sso-title
        v-col(md="8" sm="12")
          .text-h6.font-weight-medium {{ $t('sso.configuration') }}
      v-row.no-gutters
        v-col(md="8" sm="12")
          v-form( disabled v-if="canSetValues" v-model='valid' data-cy="sso-configuration" )
            v-jsf(
              v-if="isFilledSchema"
              v-model="configuration"
              :schema='enrichedSchema'
              :options='options'
              @input="onInput")
                template( v-for="propertyKey in schemaPropertiesKeys" :slot="`${propertyKey}-prepend`" )
                  // eslint-disable-next-line vuejs-accessibility/label-has-for
                  label.id-provider-label(v-if="schema.properties[propertyKey].type !== 'boolean'" v-bind:for="propertyKey" :key="propertyKey") {{ schema.properties[propertyKey].title }}
                  // eslint-disable-next-line vuejs-accessibility/label-has-for
                  label.user-provisioning(v-else v-bind:for="propertyKey" :key="propertyKey") {{ $i18n.t('sso.user_provisioning') }}

    v-row.no-gutters(v-if="showButtons")
      v-col.d-flex.justify-end.mt-6
        v-btn( v-if="testIsSuccess" id="ssosave" :disabled="!canValidate" color="primary" class="custom-primary" @click="save")
          v-icon(left) icon-save
          | {{ $t('sso.save') }}
        v-btn.mr-3( v-else-if="!testIsSuccess" id="ssotest" :disabled="!canValidate" color="primary" class="custom-primary" @click="test")
          v-icon(left) icon-arrow-sync
          | {{ $t('sso.test_connection') }}
</template>

<script lang="ts">

import {
  Vue, Component, Watch,
} from 'vue-property-decorator';

import { IdpService, IDPJsonSchemaParamsDto, Saml2ConfigDto } from '@/api';
import httpLib from '@/api/axios';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';
import broadcast from '@/utils/broadcast';
import i18n from '@/i18n';

const auth = getModule(authModule, store);

@Component
export default class Sso extends Vue {
  switchSso = false;

  defaultIdpPlatform: Saml2ConfigDto.idpPlatform | null = null;

  valid = false;

  idps: Record<string, IDPJsonSchemaParamsDto> = {};

  idpPlatform: Saml2ConfigDto.idpPlatform = Saml2ConfigDto.idpPlatform.OKTA;

  method = '';

  schema: any = {};

  configuration: Saml2ConfigDto = {
    autoProvisionUsers: true,
    registrationId: 'saml2', // Keep this to saml2 for now, this is to fix https://gitlab.com/sifflet/sifflet/-/issues/1249
    idpPlatform: Saml2ConfigDto.idpPlatform.OKTA,
    idpIssuer: '',
    idpSsoUrl: '',
    x509Certificate: '',
  };

  testResult: null | 'FAIL' | 'SUCCESS' = null;

  get hasSSO() {
    return auth.hasSSO;
  }

  get options() {
    return {
      httpLib,
      initialValidation: false,
      fieldProps: {
        dense: true,
        outlined: true,
        singleLine: true,
      },
      disableAll: this.hasSSO || !this.switchSso,
      textareaProps: {
        filled: false,
      },
    };
  }

  get methods() {
    return this.idps[this.idpPlatform]?.signInMethods;
  }

  get canSetValues() {
    return this.idpPlatform && this.method;
  }

  getSchema() {
    this.schema = this.methods[this.method];
  }

  get schemaPropertiesKeys() {
    return Object.keys(this.schema.properties);
  }

  get showButtons() {
    return !this.hasSSO && this.switchSso;
  }

  get testIsSuccess() {
    return this.testResult === 'SUCCESS';
  }

  get canValidate(): boolean {
    return this.valid;
  }

  get registrationId() {
    return this.configuration.registrationId;
  }

  get signInMethod() {
    return this.method;
  }

  get isFilledSchema() {
    return Object.keys(this.schema).length;
  }

  get enrichedSchema() {
    if (!this.schema.properties) {
      return {};
    }

    return {
      ...this.schema,
      properties: {
        ...this.schema.properties,
        idpSsoUrl: {
          ...this.schema.properties.idpSsoUrl,
          title: `${i18n.t('sso.user_action')} ${this.schema.properties.idpSsoUrl.title}`,
        },
        idpIssuer: {
          ...this.schema.properties.idpIssuer,
          title: `${i18n.t('sso.user_action')} ${this.schema.properties.idpIssuer.title}`,
        },
        x509Certificate: {
          ...this.schema.properties.x509Certificate,
          title: `${i18n.t('sso.user_action')} ${this.schema.properties.x509Certificate.title}`,
        },
      },
    };
  }

  onInput() {
    this.testResult = null;
  }

  async save() {
    await this.create();
    await auth.getAuth();
    await this.getConfiguration();
    this.$notify({
      title: this.$t('sso.success_title') as string,
      text: this.$t('sso.success_message') as string,
      type: 'success',
    });
  }

  get idpPayload() {
    return {
      ...this.configuration,
      idpPlatform: this.idpPlatform,
    };
  }

  async create() {
    return IdpService.createIdp({
      requestBody: this.idpPayload,
    });
  }

  async getConfiguration() {
    if (auth.hasSSO) {
      this.configuration = await IdpService.getByRegistrationId({ registrationId: this.configuration.registrationId });
      this.idpPlatform = this.configuration.idpPlatform;
    }
  }

  async test() {
    const { saml2AuthNUrl } = await IdpService.testSamlConfig({
      requestBody: this.configuration,
    });
    window.open(saml2AuthNUrl, 'sso-test', 'location=yes,left=100,top=100,height=600,width=600,scrollbars=yes,status=yes');
  }

  async setTestResult(event: MessageEvent) {
    if (event.data.type === 'sso-test-result') {
      this.testResult = event.data.result;

      if (this.testResult === 'SUCCESS') {
        this.$notify({
          title: this.$t('sso.success_title'),
          text: this.$t('sso.success_message'),
          type: 'success',
        });
      }

      if (this.testResult === 'FAIL') {
        this.$notify({
          title: this.$t('sso.failed_title'),
          text: this.$t('sso.failed_message'),
          type: 'error',
        });
      }
    }
  }

  @Watch('idpPlatform')
  onPlatformChange() {
    this.getSchema();
  }

  async mounted() {
    broadcast.addEventListener('message', this.setTestResult);

    await auth.getAuth();

    this.idps = await IdpService.getAllIdpJsonSchemaParams();
    this.idpPlatform = Object.keys(this.idps)[0] as Saml2ConfigDto.idpPlatform;

    [this.method] = Object.keys(this.methods);

    this.getConfiguration();

    this.switchSso = this.hasSSO;
    this.defaultIdpPlatform = this.hasSSO ? this.idpPlatform : null;

    this.getConfiguration();
    this.getSchema();
  }

  destroyed() {
    broadcast.removeEventListener('message', this.setTestResult);
  }
}
</script>

<style lang="scss">
.alert-sso {
  border: 1px solid map-get($grey, 'lighten-3') !important;
  border-bottom: none !important;
  border-radius: 0 !important;
  font-size: map-get($headings, 'caption', 'size') !important;
  background-color: var(--v-bgPageSecondary-base) !important;
}

#sso {
  .vjsf-property {
    font-weight: 500;
    font-size: map-deep-get($headings, 'body-2', 'size');

    .v-label {
      font-weight: 400;
      color: rgba(map-get($grey, 'darken-4'), 0.38);
    }

    .v-input__slot,
    .v-text-field__details {
      width: 100%;
    }

    .v-input__slot fieldset {
      color: rgba(map-get($grey, 'darken-4'), 0.26);
    }
  }

  .vjsf-property-autoProvisionUsers .v-label,
  .user-provisioning {
    font-weight: 500;
    font-size: map-deep-get($headings, 'body-2', 'size');
    color: map-deep-get($grey, 'darken-4');
  }

  .vjsf-property-autoProvisionUsers .v-label::after {
    content: 'Automatically create Sifflet accounts for users logging in with SSO';
    display: block;
    position: absolute;
    left: 0;
    top: 20px;
    font-weight: 400;
    font-size: map-deep-get($headings, 'body-2', 'size');
    color: map-deep-get($grey, 'lighten-1');
  }

  .vjsf-property-root>.row>div:first-of-type {
    display: none;
  }

  .v-input__prepend-outer {
    width: calc(100% * (4/12));
    margin: 0;
    margin-right: 4px;
  }

  .id-provider-label,
  .user-provisioning {
    white-space: initial;
    word-break: normal;
    height: 36px;
    display: flex;
    align-items: center;
    color: map-deep-get($grey, 'darken-4');
  }

  .user-provisioning {
    margin: 0;
    height: 24px;
    display: flex;
    align-items: center;
  }

  .sso-title {
    margin-bottom: 0;
  }
}
</style>
