import { IncidentDetailDto, IncidentLightDto, IncidentService } from '@/api';
import { queryToArray, queryToNumber } from '@/utils/query';

export type StatusQualification = [IncidentLightDto.status, IncidentLightDto.qualification | null];

export const STATUSES: StatusQualification[] = [
  [IncidentLightDto.status.OPEN, null],
  [IncidentLightDto.status.IN_PROGRESS, null],
  [IncidentLightDto.status.CLOSED, IncidentLightDto.qualification.FIXED],
  [IncidentLightDto.status.CLOSED, IncidentLightDto.qualification.FALSE_POSITIVE],
  [IncidentLightDto.status.CLOSED, IncidentLightDto.qualification.KNOWN_ERROR],
  [IncidentLightDto.status.CLOSED, IncidentLightDto.qualification.EXPECTED],
  [IncidentLightDto.status.CLOSED, IncidentLightDto.qualification.DUPLICATE],
];

export const INITIAL_SEARCH_PARAMS = {
  page: 1,
  itemsPerPage: 10,
  textSearch: '',
  criticality: [],
  user: [],
  status: [],
  sort: ['createdDate,DESC'],
  dataset: [],
};

export const SORT_CONFIGURATION = [
  { value: 'createdDate', text: 'Creation date' },
  { value: 'lastOccurredDate', text: 'Last failure date' },
  { value: 'numberOfFailures', text: 'Number of failures' },
];

export const filtersValuesToQuery = (searchParams: Parameters<typeof IncidentService.getAllIncident>[0]['requestBody']) => {
  const {
    status,
    criticality,
    sort,
    page,
    itemsPerPage,
    textSearch,
    user,
  } = searchParams;

  return {
    status: queryToArray(status!),
    // @TODO: Change when API changes
    criticality: Array.isArray(criticality) ? queryToArray(criticality!.map((crit) => crit.toString())) : queryToArray(criticality!.toString()),
    textSearch: textSearch!,
    user: queryToArray(user!),
    sort: queryToArray(sort!),
    page: queryToNumber(page!.toString()),
    itemsPerPage: queryToNumber(itemsPerPage!.toString()),
  };
};
