import { RuleInfoDto } from '@/api';

export function enumToSeverity(severity: RuleInfoDto.criticality): number {
  switch (severity) {
    case RuleInfoDto.criticality.CRITICAL:
      return 0;
    case RuleInfoDto.criticality.HIGH:
      return 1;
    case RuleInfoDto.criticality.MODERATE:
      return 2;
    case RuleInfoDto.criticality.LOW:
      return 3;
    default:
      return 0;
  }
}

export function severityToEnum(severity: number): RuleInfoDto.criticality {
  switch (severity) {
    case 0:
      return RuleInfoDto.criticality.CRITICAL;
    case 1:
      return RuleInfoDto.criticality.HIGH;
    case 2:
      return RuleInfoDto.criticality.MODERATE;
    case 3:
      return RuleInfoDto.criticality.LOW;
    default:
      return RuleInfoDto.criticality.LOW;
  }
}
