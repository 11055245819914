<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import i18n from '@/i18n';

import { watch } from 'vue';

interface DatasourcesSortProps {
  sort: string[];
}
interface DatasourcesSortEmits {
  (event: 'updateSort', sort: string): void;
}

const props =  defineProps({
  sort: null
});
const emits = defineEmits(["updateSort"]);

let sortString: string = _ref(props.sort[0].split(',')[0]);
let sortDirection: string = _ref(props.sort[0].split(',')[1]);

const sortConfiguration = [{ value: 'name', text: i18n.t('sources.name') }];

const hasString = _computed(() => props.sort.length);

const sortByString = _computed({
  get: () => {
    if (!hasString.value) return '';
    return sortString.value;
  },
  set: (value: string) => {
    sortString.value = value;
  },
});

const outerIcon = _computed(() => (sortDirection.value === 'ASC' ? 'icon-sort-text-az' : 'icon-sort-text-za'));

const sortHandler = () => {
  if (!sortString.value) return;
  sortDirection.value = sortDirection.value === 'ASC' ? 'DESC' : 'ASC';
};

watch(() => [sortString.value, sortDirection.value], () => {
  const sort = `${sortString.value},${sortDirection.value}`;

  emits('updateSort', sort);
});

</script>

<template lang="pug">
v-select(
  :items="sortConfiguration"
  v-model="sortByString"
  style="width: 250px;"
  :append-outer-icon="outerIcon"
  @click:append-outer="sortHandler"
  :placeholder="$t('common.words.sort_by')"
  :menu-props="{ bottom: true, offsetY: true }"
  hide-details
  rounded
  dense outlined )
</template>
