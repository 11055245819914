export default [{
  text: 'Name',
  value: 'name',
  class: 'text-break',
  width: 300,
  sortable: true,
},
{
  text: 'Scope',
  value: 'scope',
  sortable: false,
  width: 150,
  class: 'text-break',
},
{
  text: 'Trigger time',
  value: 'triggerTime',
  sortable: false,
  width: 150,
  class: 'text-break',
},
{
  text: 'Severity',
  value: 'criticality',
  sortable: true,
  width: 150,
  class: 'text-break',
},
{
  text: 'Status',
  value: 'status',
  width: 130,
  sortable: true,
  class: 'text-break',
},
{
  text: 'Compromised assets',
  value: 'compromisedAssets',
  sortable: false,
  width: 200,
  class: 'text-break',
},
{
 text: 'Assigned',
  value: 'owners',
  sortable: false,
  width: 200,
},
{
  text: 'Last update',
  value: 'lastModifiedDate',
  sortable: true,
  width: 200,
  class: 'text-break',
},
];
