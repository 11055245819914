<template lang="pug">
v-row
  v-col( cols="6")
    InputName( v-model.trim='datasource.name' label='Name' )

  v-col( cols="6")
    InputHost( v-model.trim='datasource.params.host' )

  v-col( cols="6" )
    InputPort( v-model.trim='datasource.params.port' )

  v-col( cols="6")
    InputDatabase( v-model.trim='datasource.params.database' )

  v-col( cols="6" )
    InputMysqlTlsVersion( v-model.trim='datasource.params.mysqlTlsVersion' )

  v-col( cols="6")
    CredentialsSelector( v-model.trim='datasource.secretId' )

</template>

<script lang="ts">
import {
  Vue, Component, VModel,
} from 'vue-property-decorator';
import { DatasourceDto, MysqlParams } from '@/api';

type MySqlSourceDto = DatasourceDto & { params: MysqlParams; };

@Component
export default class MySqlConnection extends Vue {
  @VModel({ type: Object }) datasource!: MySqlSourceDto;
}
</script>
