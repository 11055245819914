<script lang="ts">
const DECLARATIVE_SOURCE_TYPE = 'declarative'
</script>
<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { onBeforeMount } from 'vue';
import { getModule } from 'vuex-module-decorators';
import { DataOptions } from 'vuetify';
import authModule from '@/store/modules/auth';
import store from '@/store';
import {
  DatasourceCatalogAssetDto,
} from '@/api';
import SiffletifyDataFooter
  from '@/components/siffletify/siffletify-data-footer/siffletify-data-footer.vue';
import i18n from '@/i18n';



interface DatasourcesResultsProps {
  count: number;
  datasources: DatasourceCatalogAssetDto[];
  options: DataOptions;
  loading: boolean;
}
interface DatasourceResultsEmits {
  (event: 'updateDatasources'): void;
  (event: 'updateOptions', options: Partial<DataOptions>): void;
  (event: 'deleteDatasources', datasources: DatasourceCatalogAssetDto[]): void;
}
const props =  defineProps({
  count: null,
  datasources: null,
  options: null,
  loading: { type: Boolean }
});
const emits = defineEmits(["updateDatasources", "updateOptions", "deleteDatasources"]);

let auth: authModule;
const inSelectMode = _ref(false);
let selectedDatasources: DatasourceCatalogAssetDto[] = _ref([]);

const from = _computed(() => ((props.options.page - 1) * props.options.itemsPerPage) + 1);
const to = _computed(() => ((props.options.page - 1) * props.options.itemsPerPage + props.options.itemsPerPage <= props.count ? (props.options.page - 1) * props.options.itemsPerPage + props.options.itemsPerPage : props.count));

const results = _computed(() => `${from.value}-${to.value} of ${i18n.tc('sources.sources_count', props.count)}`);

const someAreSelected = _computed(() => !!selectedDatasources.value.length);
const allAreSelected = _computed(() => props.datasources.length && props.datasources.length === selectedDatasources.value.length);
const isCheckIndeterminate = _computed(() => !allAreSelected.value && someAreSelected.value);

const filteredSelectedDatasources = _computed(() => selectedDatasources.value.filter(({ type }) => type !== DECLARATIVE_SOURCE_TYPE));

const selectedDatasourcesCount = _computed(() => filteredSelectedDatasources.value.length);

const createDatasourceLocation = _computed(() => ({
  name: 'sources.source.new',
}));

const canDelete = _computed(() => filteredSelectedDatasources.value.length && inSelectMode.value && auth.userActions['integration.datasource.write']);
const canAdd = _computed(() => auth.userActions['integration.datasource.write']);

const updateOptions = (options: Partial<DataOptions>) => {
  const {
    itemsPerPage,
  } = options;
  const page = itemsPerPage !== props.options.itemsPerPage ? 1 : options.page;
  emits('updateOptions', { page, itemsPerPage });
};

const selectAllHandler = () => {
  if (allAreSelected.value) {
    selectedDatasources.value = selectedDatasources.value.filter((sourceItem: any) => !props.datasources.some((targetItem: any) => sourceItem.id === targetItem.id));
  } else {
    selectedDatasources.value.push(...props.datasources);
  }
};

const cardClickHandler = (cardProps: any, source: DatasourceCatalogAssetDto) => {
  if (inSelectMode.value) {
    const isSelected = cardProps.isSelected(source);
    cardProps.select(source, !isSelected);
  }
};

const clearSelection = () => {
  selectedDatasources.value = [];
};

const deleteDatasource = (datasource: DatasourceCatalogAssetDto[]) => {
  const message = i18n.tc('sources.delete_datasources_confirm');
  if (window.confirm(message)) emits('deleteDatasources', datasource);
};

const deleteDatasources = async () => {
  const ids = filteredSelectedDatasources.value.map((datasource) => datasource.id);
  const message = i18n.tc('sources.delete_datasources_confirm', ids.length, { count: ids.length });
  window.confirm(message);
  emits('deleteDatasources', filteredSelectedDatasources.value);
  clearSelection();
  emits('updateDatasources');
};

onBeforeMount(() => {
  auth = getModule(authModule, store);
});
</script>

<template lang="pug">
v-row( align="center" justify="center")
  v-col( cols="12" )
    div( class="d-flex flex-wrap justify-end"  )
      v-card( class="mr-auto d-flex align-center" )
        v-icon.mr-2 icon-data-source
        span.font-weight-medium(v-if="loading")
          v-progress-circular.mr-2( size="20" )
        span.font-weight-medium(v-else)
          | {{ $tc('sources.quantity', count, { count }) }}
        span.ml-1.font-weight-medium( v-if="selectedDatasourcesCount" ) {{ $t('monitors.slash') }} {{ selectedDatasourcesCount }} {{ $t('monitors.selected') }}

      v-switch(
        v-model="inSelectMode"
        class="pa-2 my-0 mr-6"
        :label="$t('common.words.multiple_selection')"
        hide-details inset)

      v-btn.mr-3(v-if="canDelete" color="alert" class="custom-alert" text outlined :disabled='!canDelete' @click="deleteDatasources")
        v-icon(left) icon-trash
        | {{ $t('cta.delete') }}

      v-btn(v-if='canAdd' color='primary' class="custom-primary" :to="createDatasourceLocation")
        v-icon(left) icon-add
        | {{ $t('sources.new_source') }}

  v-col( cols='12' )
    v-data-iterator(
      v-model="selectedDatasources"
      :items="datasources"
      :options="options"
      :server-items-length="count"
      :loading="loading"
      hide-default-footer)

      template(v-slot:loading)
        DatasourcesResultPlaceholder

      template(v-slot:no-data)
        NoDataAvailablePlaceholder

      template(v-slot:default="props")

        v-col.mr-4( align-self="stretch" )
          .d-flex.align-center( v-if="inSelectMode" style="height: 100%;" )
            v-checkbox(
              @click="selectAllHandler"
              color="primary"
              :value="allAreSelected"
              :indeterminate="isCheckIndeterminate" )
            .grey--text.font-weight-medium {{ results }}

        DatasourcesResultCard(
          v-for="datasource in props.items"
          :selected="props.isSelected(datasource)"
          :in-select-mode="inSelectMode"
          @click.native="cardClickHandler(props, datasource)"
          @delete="deleteDatasource"
          :key="datasource.id"
          :datasource="datasource" )

      template(v-slot:footer="footer")
        SiffletifyDataFooter( :footer="footer" :items-per-page-options="[10, 25, 50, 100]" @update="updateOptions($event)" )

</template>
