/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SearchCollectionTagDto } from '../models/SearchCollectionTagDto';
import type { TagCreateDto } from '../models/TagCreateDto';
import type { TagDto } from '../models/TagDto';
import type { TagUpdateDto } from '../models/TagUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TagService {

    /**
     * Get tags
     * @returns SearchCollectionTagDto List of Tag
     * @throws ApiError
     */
    public static getAllTag({
        type,
        textSearch,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        /**
         * Filter on given tag types
         */
        type?: Array<'GENERIC' | 'HIDDEN_DATA_CLASSIFICATION' | 'VISIBLE_DATA_CLASSIFICATION' | 'TERM' | 'BIGQUERY_EXTERNAL' | 'SNOWFLAKE_EXTERNAL'>,
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<SearchCollectionTagDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/tags',
            query: {
                'type': type,
                'textSearch': textSearch,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a Tag
     * @returns TagDto Successfully create Tag
     * @throws ApiError
     */
    public static createTag({
        requestBody,
    }: {
        requestBody: TagCreateDto,
    }): CancelablePromise<TagDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/tags',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a Tag
     * @returns void
     * @throws ApiError
     */
    public static deleteTag({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/tags/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get Tag by id
     * @returns TagDto Successfully fetch Tag
     * @throws ApiError
     */
    public static getTagById({
        id,
    }: {
        id: string,
    }): CancelablePromise<TagDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/tags/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a Tag
     * @returns TagDto Successfully update Tag
     * @throws ApiError
     */
    public static updateTag({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: TagUpdateDto,
    }): CancelablePromise<TagDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ui/v1/tags/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
