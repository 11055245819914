<template lang="pug">
v-tooltip( top )
    template( v-slot:activator='{ on }' )
      span.grey--text( v-on='on' class="date-tooltip" ) {{ fromNowDate }}
    span {{ formattedDate }}
</template>

<script lang="ts">
import {
 Vue, Component, VModel, Prop,
} from 'vue-property-decorator';
import formatDate from '@/utils/filters/formatDate';
import fromNow from '@/utils/filters/fromNow';
import capitalizeFirst from '@/utils/filters/capitalizeFirst';

@Component
export default class DateTooltip extends Vue {
  @VModel({ type: Number }) readonly date!: string;

  @Prop({ type: Boolean, default: false }) capitalize!: boolean;

  get formattedDate() {
    return formatDate(this.date);
  }

  get fromNowDate() {
    return this.capitalize ? capitalizeFirst(fromNow(this.date)) : fromNow(this.date);
  }
}
</script>
