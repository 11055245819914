<template lang="pug">
#lineage( ref="lineageContainer" )
  #lineage-canvas( ref="canvas"  )
  LineageFilters
  LineagePanel

</template>

<script lang="ts">
import {
  Vue, Component, Ref, Prop,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { LineageEntityDto } from '@/api';
import lineageModule from '@/store/modules/lineage';
import store from '@/store';

const lineage = getModule(lineageModule, store);

@Component
export default class Lineage extends Vue {
  @Ref('lineageContainer') readonly lineageContainer!: HTMLDivElement;

  @Ref('canvas') readonly canvas!: HTMLDivElement;

  @Prop(String) readonly urn!: LineageEntityDto['urn'];

  setLineageHeight() {
    const windowHeight = window.innerHeight;
    const magicNumber = 207; // header + asset info
    const totalHeight = windowHeight - magicNumber;
    this.lineageContainer.style.height = `${totalHeight}px`;
  }

  resizeObserver = new ResizeObserver(() => {
    this.setLineageHeight();
  });

  async mounted() {
    this.resizeObserver.observe(this.lineageContainer);
    lineage.createLineage({ el: this.canvas, urn: this.urn });
  }

  beforeDestroy() {
    lineage.destroyLineage();
    this.resizeObserver.disconnect();
  }
}
</script>

<style lang="scss">
#lineage {
  position: relative;

  &.modal {
    height: 65vh;
  }

  &-canvas {

    position: absolute;
    top: 1px;
    left: -16px;
    right: -16px;
    bottom: 0;

    cursor: move;

    g.field {
      rect.border {
        &.highlighted {
          stroke-width: 2;
          fill: rgba(map-get($grey, 'lighten-4'), 1) !important;
          stroke: rgba(map-get($grey, 'lighten-3'), 1) !important;
        }
      }
      .node-field-title {
        &.lowlighted {
          fill: rgba(map-get($grey, 'base'), 1) !important;
        }
      }
    }

    path.highlighted {
      stroke: rgba(map-get($grey, 'base'), 1);
      stroke-width: 2;
    }

  }

  .selected-node > rect {
    stroke: rgba(map-get($blue, 'base'), 1) !important;
    stroke-width: 2 !important;
    fill: rgba(map-get($blue, 'lighten-5'), 1) !important;
  }
}
</style>
