<template lang="pug">
v-text-field(
  full-width
  v-bind="$attrs"
  v-on="$listeners"
  :rules="rules"
  outlined dense type="number"
  :placeholder="$t('conditional-rules.put_value')"
  @keypress="isNumber($event)")

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ConditionalRulesConditionValueInputNumber extends Vue {
  isNumber(evt:any) {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      evt.preventDefault();
      return false;
    }
    return true;
  }

  rules = [
    (v: any) => !!v || 'Value is required',
  ];
}
</script>
