<template lang="pug">
div
  v-row
    v-col( cols="12" )
      .flex.d-flex
        v-btn( class="mr-auto custom-primary" color="primary" text outlined @click="addConfiguration" )
          v-icon( left ) icon-add-circle
          span {{ $t('inputs.add_git_connexion') }}
  v-row( v-if="gitConnections.length" )
    v-col
      v-card( outlined class="pa-3" )
        v-row( v-for="(connection, i) of gitConnections" :key="i" )
          v-col( cols="6"  )
            v-text-field(
              v-model.trim="connection.url"
              label='Url'
              data-cy="url"
              dense outlined required hide-details)
          v-col( cols="6"  )
            CredentialsSelector( v-model.trim='connection.secretId' )
          v-col( cols="6"  )
            v-text-field(
              v-model.trim="connection.branch"
              label='Branch'
              data-cy="branch"
              dense outlined required hide-details)
          v-col( cols="6"  )
            v-autocomplete(
              :items='authTypes'
              v-model='connection.authType'
              label='Auth Type'
              item-text='name'
              dense outlined required hide-details )
          v-col( cols="12" )
            .flex.d-flex
              v-btn.ml-auto.mr-2( color="primary" class="custom-primary" text outlined @click="testConnection(connection)")
                span {{ $t('inputs.test_connexion') }}
              v-btn( color="alert" class="custom-alert" text outlined @click="removeConfiguration(i)")
                v-icon( left ) icon-trash
                span {{ $t('common.words.delete') }}

</template>

<script lang="ts">
import { GitConnection, DatasourceConnectionService } from '@/api';
import { Vue, Component, VModel } from 'vue-property-decorator';

@Component
export default class InputGitConnections extends Vue {
  @VModel({ type: Array }) gitConnections!: GitConnection[];

  get authTypes() {
    return Object.values(GitConnection.authType);
  }

  addConfiguration() {
    this.gitConnections.push({
      url: '',
      secretId: '',
      branch: '',
      authType: GitConnection.authType.SSH,
    });
  }

  removeConfiguration(index: number) {
    this.gitConnections.splice(index, 1);
  }

  async testConnection(connection: GitConnection) {
    const response = await DatasourceConnectionService.testGitConnection({ requestBody: connection });
    this.$notify({
      text: response.message,
      type: response.success ? 'success' : 'error',
    });
  }
}
</script>
