/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EventPayload } from './EventPayload';

export type CollaborationToolItemLinkedEventPayload = (EventPayload & {
    itemKey?: string;
    itemType?: CollaborationToolItemLinkedEventPayload.itemType;
    itemUrl?: string;
} & {
    itemKey: string;
    itemType: CollaborationToolItemLinkedEventPayload.itemType;
});

export namespace CollaborationToolItemLinkedEventPayload {

    export enum itemType {
        JIRA = 'JIRA',
    }


}
