/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PatchJiraConfigDto } from './PatchJiraConfigDto';

export type PatchCollaborationToolConfigDto = {
    config: PatchJiraConfigDto;
    type: PatchCollaborationToolConfigDto.type;
};

export namespace PatchCollaborationToolConfigDto {

    export enum type {
        JIRA = 'JIRA',
    }


}
