<template lang="pug">
.d-flex.text-truncate
  .d-flex.align-center.my-1
    v-avatar.mr-2(:color="initialsToColor(user.name)" :size="size")
      b.white--text(:style="style") {{ stringToInitials(user.name) }}
    .d-flex.align-start.flex-column
      .body-2 {{ user.name }}
      .caption.grey--text.text--grey-secondary {{ user.email }}

</template>

<script setup lang="ts">import { computed as _computed } from 'vue';

import stringToInitials from '@/utils/filters/stringToInitials';
import initialsToColor from '@/utils/initialsToColor';

interface Props {
  user: { name: string, email?: string };
  size: number;
  overrideFontSize?: boolean;
}
const props = defineProps({
  user: null,
  size: { default: 32 },
  overrideFontSize: { type: Boolean, default: false }
});

const style = _computed(() => (props.overrideFontSize ? ({ fontSize: `${props.size - 12}px` }) : {}));
</script>
