/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TagPredictionFeedbackDto = {
    tagId: string;
    validationStatus: TagPredictionFeedbackDto.validationStatus;
};

export namespace TagPredictionFeedbackDto {

    export enum validationStatus {
        NO_FEEDBACK = 'NO_FEEDBACK',
        VALIDATED = 'VALIDATED',
        REJECTED = 'REJECTED',
    }


}
