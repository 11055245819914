<template lang="pug">
v-row.monitors-preview-card.pa-1( no-gutters )

  v-col.mr-2( cols="auto" )
    v-avatar(size="36" color="bgAccentSecondary" :alt="$t('common.words.monitor')")
      v-icon( color="iconInfo" ) icon-monitor-square

  v-col
    span.overline.grey--text.d-block {{ ruleLabel }}
    span.text-h6.font-weight-medium.mt-n1.break-spaces {{ name }}
    .d-flex.flex-column.mb-1.align-start
      div.d-flex.align-center.mr-2(v-for="datasource in datasources" :key="`${datasource.type}-${datasource.name}-${entity.urn}`")
        v-avatar.mr-1(tile size="16")
          img( :src="datasource.icon" :alt="datasource.type")
        span.caption.font-weight-bold.grey--text {{ datasource.name }}

</template>

<script setup lang="ts">
import src from '@/utils/filters/src';
import { computed, onMounted } from 'vue';

interface MonitorPreviewCardProps {
  entity: any // change type later on
}

type DatasetNameAndType = {
  datasetName: string,
  datasourceType: string
}

const props = defineProps({
  entity: null
});

const ruleLabel = computed(() => props.entity?.ruleLabel ?? '');
const name = computed(() => props.entity.name);
const datasources = computed(() => props.entity?.datasetNameAndSourceTypeDtos?.map((datasource: DatasetNameAndType) => ({
  name: datasource.datasetName,
  type: datasource.datasourceType,
  icon: src(datasource.datasourceType, 'datasources'),
})));
</script>
