<template lang="pug">
v-tooltip(top :disabled="!tooltip")
  template(v-slot:activator="{ on, attrs }")
    span
      v-progress-circular.mr-1.pa-1( v-if='isRunning' color="primary" indeterminate :width='2' :size='12')
      v-icon.mr-1( v-else small :color="color" v-bind="attrs" v-on="on") {{ icon }}
  span {{ tooltip }}
</template>

<script lang="ts">
import { RuleInfoDto } from '@/api';

import { Vue, Component, Prop } from 'vue-property-decorator';
import { createColor, createText, Health } from '@/utils/filters/health';

@Component
export default class DotHealth extends Vue {
  @Prop(String) readonly health!: Health;

  get isRunning() {
    return this.health === RuleInfoDto.lastRunStatus.RUNNING || this.health === RuleInfoDto.lastRunStatus.PENDING;
  }

  get grouped() {
    return 'grouped' in this.$attrs && this.$attrs.grouped;
  }

  get icon() {
    return this.grouped ? 'icon-chart-multiple' : 'icon-circle-fill';
  }

  get color() {
    return createColor(this.health);
  }

  get tooltip() {
    return createText(this.health);
  }
}
</script>
