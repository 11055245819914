<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    label='Site'
    data-cy="site"
    dense clearable outlined required)

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputSite extends Vue {
}
</script>
