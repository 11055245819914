/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type IssueBriefDto = {
    incidentName?: string;
    issueNo?: number;
    severity?: IssueBriefDto.severity;
};

export namespace IssueBriefDto {

    export enum severity {
        CRITICAL = 'CRITICAL',
        HIGH = 'HIGH',
        MODERATE = 'MODERATE',
        LOW = 'LOW',
    }


}
