import { AnomalyConditionsEnum } from '@/components/monitor-wizard/composables/monitors/common/types';
import { type MonitorSettingsOptions } from './common/types';
import { staticSettings } from './common/thresholdSettings';

const SiffletStaticCountDuplicateRule: MonitorSettingsOptions = {
    thresholdSettings: [
        {
            ...staticSettings,
            hasSensitivity: false,
            hasAnomalyConditions: true,
            anomalyConditions: [
                {
                    text: 'monitor_wizard.anomaly_conditions.single_threshold',
                    value: AnomalyConditionsEnum.SINGLE_VALUE,
                    isInteger: true,
                    min: 0,
                },
            ],
            convertThresholdToLegacy: (thresholdParams) => ({
                threshold: thresholdParams.anomalyConditions.singleValue || 0,
            }),
        },
    ],
};

export default SiffletStaticCountDuplicateRule;
