/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ChangeDto } from './ChangeDto';
import type { LogDto } from './LogDto';

export type WorkspaceApplyObjectResponseDto = {
    change?: ChangeDto;
    id?: string;
    kind?: string;
    logs?: Array<LogDto>;
    status?: WorkspaceApplyObjectResponseDto.status;
    subStatus?: WorkspaceApplyObjectResponseDto.subStatus;
};

export namespace WorkspaceApplyObjectResponseDto {

    export enum status {
        OK = 'OK',
        ERROR = 'Error',
        FATAL = 'Fatal',
    }

    export enum subStatus {
        SUCCESS = 'Success',
        SKIPPED = 'Skipped',
        VALIDATION_ERROR = 'ValidationError',
        DESERIALIZATION_ERROR = 'DeserializationError',
        UNAUTHORIZED = 'Unauthorized',
        INTERNAL_SERVER_ERROR = 'InternalServerError',
    }


}
