<template lang="pug">
v-container(fluid)
  v-row.no-gutters( v-if="isLoading || hasError" )
    v-col( cols="12" v-if="!hasError" )
      .d-flex.align-center.justify-center.flex-column( style="height: 300px;" )
        v-progress-circular.mb-2(indeterminate size=20 width="1" color="primary")
        div {{ loadingText }}

    v-col( cols="12" v-else )
      .d-flex.align-center.justify-center.flex-column( style="height: 300px;" )
        .mb-2 {{ $t('rules.retrieve_graph_error') }}
        v-btn( color="secondary" class="custom-secondary " text outlined @click="getGraphs" )
          v-icon( left ) icon-arrow-refresh
          span {{ $t('rules.retry') }}

  v-row.no-gutters( v-else )
    v-col( cols="12" v-for="(graph, index) in graphs" :key="index" )
      Component.chart(
        :is="component(graph)"
        :graph="graph"
        :qualifications="qualifications"
        :id="id"
        :runId="runId"
        :chart="charts[index]"
        :savedZoomStart="savedZoomStart"
        :savedZoomEnd="savedZoomEnd"
        @update="updateHandler"
        @downloadImage="downloadImage($event)")

</template>

<script lang="ts">
import {
  Vue, Component, Prop, ProvideReactive, Watch,
} from 'vue-property-decorator';
import * as echarts from 'echarts';
import { markRaw } from 'vue';
import {
  DatapointQualificationDto, GraphDto, RuleInfoDto, RuleRunDto, RuleService, RuleRunService, DatapointQualificationService, GroupIdentifierDto,
} from '@/api';
import Completeness from '@/components/charts/Chart-Completeness.vue';
import DuplicateCount from '@/components/charts/Chart-Duplicate-Count.vue';
import DuplicatePercentage from '@/components/charts/Chart-Duplicate-Percentage.vue';
import NullCount from '@/components/charts/Chart-Null-Count.vue';
import NullPercentage from '@/components/charts/Chart-Null-Percentage.vue';
import Freshness from '@/components/charts/Chart-Freshness.vue';
import MetadataFreshness from '@/components/charts/Chart-Metadata-Freshness.vue';
import NumericalTransformation from '@/components/charts/Chart-Numerical-Transformation.vue';
import Distribution from '@/components/charts/Chart-Distribution.vue';
import MetricsGlobal from '@/components/charts/Chart-Metrics-Global.vue';
import MetricsDifference from '@/components/charts/Chart-Metrics-Difference.vue';

const TIMEOUT_DELAY = 20;
const ABANDON_DELAY = 60;

@Component
export default class RuleGraph extends Vue {
  @Prop(String) readonly id!: RuleInfoDto['id'];

  @Prop(String) readonly runId!: RuleRunDto['id'];

  @Prop(Object) readonly group?: GroupIdentifierDto;

  isLoading = false;

  hasError = false;

  graphs: GraphDto[] | null = null;

  qualifications: DatapointQualificationDto[] = [];

  charts: any[] = [];

  savedZoomStart: number | null = null;

  savedZoomEnd: number | null = null;

  elapsedTime = 0;

  interval: NodeJS.Timeout | null = null;

  get loadingText() {
    return this.elapsedTime < TIMEOUT_DELAY
      ? this.$t('rules.retrieve_graph_infos')
      : this.$t('rules.retrieve_graph_infos_with_delay');
  }

  @ProvideReactive()
  get providedGroup() {
    return this.group;
  }

  get isGrouped() {
    return this.group !== undefined;
  }

  get hasManyGraphs() {
    return this.graphs && this.graphs.length > 1;
  }

  component(graph: GraphDto) {
    switch (graph?.graphType) {
      case GraphDto.graphType.COMPLETENESS:
        return Completeness;
      case GraphDto.graphType.DUPLICATE:
      case GraphDto.graphType.DUPLICATE_COUNT:
        return DuplicateCount;
      case GraphDto.graphType.DUPLICATE_PERCENTAGE:
        return DuplicatePercentage;
      case GraphDto.graphType.NULL_COUNT:
        return NullCount;
      case GraphDto.graphType.NULL_PERCENTAGE:
        return NullPercentage;
      case GraphDto.graphType.FRESHNESS:
        return Freshness;
      case GraphDto.graphType.METADATA_FRESHNESS:
        return MetadataFreshness;
      case GraphDto.graphType.NUMERICAL_TRANSFORMATION:
        return NumericalTransformation;
      case GraphDto.graphType.DISTRIBUTION:
        return Distribution;
      case GraphDto.graphType.MULTI_METRICS_GLOBAL_TIME_SERIES:
        return MetricsGlobal;
      case GraphDto.graphType.MULTI_METRICS_DIFFERENCE_TIME_SERIES:
        return MetricsDifference;
      default:
        return null;
    }
  }

  startCounter() {
    this.interval = setInterval(() => {
      this.elapsedTime += 1;
    }, 1000);
  }

  resetCounter() {
    if (this.interval) {
      clearInterval(this.interval);
      this.elapsedTime = 0;
    }
  }

  async getGraphs() {
    this.isLoading = true;
    this.hasError = false;
    this.resetCounter();
    this.startCounter();

    let graphs;
    let qualifications;

    try {
      if (this.group) {
        graphs = await RuleRunService.getSiffletRuleGraphByRunAndGroup({ id: this.id, runId: this.runId, requestBody: { group: this.group } });
        qualifications = await DatapointQualificationService.getCurrentDatapointQualificationsForSiffletRuleByGroup({ id: this.id, runId: this.runId, requestBody: { group: this.group } });
      } else {
        graphs = await RuleService.getSiffletRuleGraph({ id: this.id, runId: this.runId });
        qualifications = await DatapointQualificationService.getCurrentDatapointQualificationsForSiffletRule({ id: this.id, runId: this.runId });
      }
      this.graphs = markRaw(graphs) as GraphDto[]; // markRaw to avoid reactivity on the data
      this.qualifications = qualifications;
    } catch (error) {
      this.hasError = true;
    } finally {
      this.isLoading = false;
      this.resetCounter();
    }
  }

  updateHandler() {
    this.getGraphs();
  }

  setZoom(evt: any, id: number) {
    this.charts.forEach((chart, index) => {
      const start = evt.from ? evt.start : evt?.batch?.[0]?.start;
      const end = evt.from ? evt.end : evt?.batch?.[0]?.end;
      if (!start || !end || index === id) return;
      chart.dispatchAction({
        type: 'dataZoom',
        dataZoomIndex: 0,
        start,
        end,
      });
    });
  }

  @Watch('elapsedTime')
  resetCounterHandler() {
    if (this.elapsedTime > ABANDON_DELAY && this.interval) {
      this.resetCounter();
      this.hasError = true;
    }
  }

  downloadImage(chart: any) {
    const a = document.createElement('a');
    const img64 = chart.getDataURL({
      pixelRatio: 2,
      backgroundColor: '#fff',
    });
    a.href = img64;
    a.download = 'chart.png';
    a.click();
  }

  async mounted() {
    await this.getGraphs();
    Vue.nextTick(() => {
      const els = document.querySelectorAll('.chart');
      if (typeof els !== 'undefined') els.forEach((chart) => this.charts.push(echarts.getInstanceByDom(chart as HTMLElement)));
    });
    this.charts.forEach((chart, index) => {
      if (this.graphs![index].graphType !== GraphDto.graphType.MULTI_METRICS_GLOBAL_TIME_SERIES && chart.on) {
        chart.on('datazoom', (evt: any) => {
            const start = evt.start ? evt.start : evt.batch?.[0]?.start;
            const end = evt.end ? evt.end : evt.batch?.[0]?.end;
            const axis = chart.getModel().option.xAxis[0];
            const startIndex = Math.round(axis.data.length * (start / 100));
            const endIndex = Math.round(axis.data.length * (end / 100));
            this.savedZoomStart = axis.data[startIndex];
            this.savedZoomEnd = axis.data[endIndex];
        });
      }
    });
  }
}
</script>

<style lang="scss">
.v-input--reverse .v-input__slot {
  float: right;
  flex-direction: row-reverse;
  justify-content: flex-start;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}
.tooltip {
    width: 400px;
    margin-left: 12px;
    & ul {
      margin: 8px 0;
      & li {
        display: inline-block;
      }
      & li:first-child {
        width: 120px;
      }
    }
  }
</style>
