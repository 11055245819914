<template lang="pug">
v-select(
  :items="items"
  outlined
  dense
  :rules="rules"
  v-bind="$attrs"
  v-on="$listeners"
  :menu-props="{ bottom: true, offsetY: true }"
)
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import * as ConditionalRulesTYPES from '@/@types/conditional-rules';

@Component
export default class ConditionalRulesConditionOperatorInput extends Vue {
  @Prop({
 type: String,
default() {
    return ConditionalRulesTYPES.ConditionalRulesTypes.COLUMN;
  },
}) readonly typology!: ConditionalRulesTYPES.ConditionalRulesTypes;

  @Prop({ type: Object }) readonly field!: ConditionalRulesTYPES.ConditionalRulesField;

  get entries() {
    if (this.typology === ConditionalRulesTYPES.ConditionalRulesTypes.COLUMN) {
      switch (true) {
        case ConditionalRulesTYPES.isNumericType(this.field.type):
          return Object.entries(ConditionalRulesTYPES.ConditionalRulesColumnNumberOperators);
        case ConditionalRulesTYPES.isBooleanType(this.field.type):
          return Object.entries(ConditionalRulesTYPES.ConditionalRulesColumnBooleanOperators);
        case ConditionalRulesTYPES.isDateType(this.field.type):
          return Object.entries(ConditionalRulesTYPES.ConditionalRulesColumnDateOperators);
        case ConditionalRulesTYPES.isTimeType(this.field.type):
          return Object.entries(ConditionalRulesTYPES.ConditionalRulesColumnDateOperators);
        case ConditionalRulesTYPES.isDateTimeType(this.field.type):
          return Object.entries(ConditionalRulesTYPES.ConditionalRulesColumnDateOperators);
        default:
          return Object.entries(ConditionalRulesTYPES.ConditionalRulesColumnStringOperators);
      }
    }
    if (this.typology === ConditionalRulesTYPES.ConditionalRulesTypes.VALUE) {
      switch (true) {
        case ConditionalRulesTYPES.isNumericType(this.field.type):
          return Object.entries(ConditionalRulesTYPES.ConditionalRulesValueNumberOperators);
        case ConditionalRulesTYPES.isBooleanType(this.field.type):
          return Object.entries(ConditionalRulesTYPES.ConditionalRulesValueBooleanOperators);
        case ConditionalRulesTYPES.isDateType(this.field.type):
          return Object.entries(ConditionalRulesTYPES.ConditionalRulesValueDateOperators);
        case ConditionalRulesTYPES.isTimeType(this.field.type):
          return Object.entries(ConditionalRulesTYPES.ConditionalRulesValueDateOperators);
        case ConditionalRulesTYPES.isDateTimeType(this.field.type):
          return Object.entries(ConditionalRulesTYPES.ConditionalRulesValueDateOperators);
        default:
          return Object.entries(ConditionalRulesTYPES.ConditionalRulesValueStringOperators);
      }
    }
    if (this.typology === ConditionalRulesTYPES.ConditionalRulesTypes.TIME_PERIOD) {
      return Object.entries(ConditionalRulesTYPES.ConditionalRulesTimePeriodOperators);
    }
    return [];
  }

  rules = [
    (v: any) => !!v || 'Operator is required',
  ];

  get items(): { text: string, value: string; }[] {
    return this.entries.map(([value, text]) => ({
      text, value,
    }));
  }
}
</script>
