<script lang="ts" setup>import { ref as _ref, computed as _computed } from 'vue';
const asset = __MACROS_toRef(__props, "value");

import { toRef as __MACROS_toRef } from "vue";
defineProps({
  value: null
})
const __MACROS_emit = defineEmits(["input"]);
import { getModule } from 'vuex-module-decorators';
import router from '@/router';
import type { AuthorizedAssetHeaderDto } from '@/api';
import authModule from '@/store/modules/auth';
import store from '@/store';
import copyToClipboard from '@/utils/copyToClipboard';
import i18n from '@/i18n';



const auth = getModule(authModule, store);

const canCreateNewMonitor = _ref(auth.userActions['monitoring.monitor.write']);
const hasAsset = _computed(() => __props.value?.name);
const hasExternalLink = _computed(() => 'externalLink' in __props.value);
const externalLink = _computed(() => ('externalLink' in __props.value ? __props.value.externalLink : ''));
const hasQuality = _computed(() => __props.value.hasQuality);
const lineagePlatformCta = _computed(() => {
  const { lineagePlatformLabel } = __props.value;
  if (!lineagePlatformLabel) return '';
  return i18n.t('assets.view_in', { datasource: lineagePlatformLabel });
});

const bookmarkData = _computed(() => ({
  entityId: __props.value.urn,
  entityType: __props.value.entityType,
}));

const createNewMonitor = () => {
  router.push({
    name: 'monitors.rule.new',
    query: {
      urn: __props.value.urn,
    },
  });
};

const copyAssetIDToClipboard = () => {
  copyToClipboard(__props.value.id, `Data Asset ID copied to clipboard <br> ${__props.value.id}`);
};

const copyAssetURIToClipboard = () => {
  copyToClipboard(__props.value.uri!, `Data Asset URI copied to clipboard <br> ${__props.value.uri}`);
};

</script>

<template lang="pug">

.asset-overview-info-actions.ml-12

  .d-flex.flex-nowrap( v-if="hasAsset" )

    Bookmark.mr-2( :bookmark="bookmarkData" )

    v-btn.mr-2(
      v-if="hasQuality"
      color="primary"
      class="custom-primary"
      @click="createNewMonitor"
      :disabled='!canCreateNewMonitor'
      )
      v-icon( left ) icon-add
      | {{ $t('monitors.new_monitor') }}

    v-btn.mr-2(
      v-if="hasExternalLink"
      color="primary"
      depressed
      :href="externalLink" target="_blank")
      v-icon( :left="!!asset.lineagePlatform" ) icon-new-window
      | {{ lineagePlatformCta }}

    SThreeDotMenu
      SMenuButton(
        v-if="asset.uri"
        @click="copyAssetURIToClipboard"
        icon="icon-copy"
        :text="$t('data-catalog.copy_uri')")
      SMenuButton(
        @click="copyAssetIDToClipboard"
        icon="icon-copy"
        :text="$t('data-catalog.copy_id')")

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    type="actions"
    width="500" )

</template>
