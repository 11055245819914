/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EventPayload } from './EventPayload';

export type IncidentSeverityUpdatePayload = (EventPayload & {
    newSeverity?: IncidentSeverityUpdatePayload.newSeverity;
    oldSeverity?: IncidentSeverityUpdatePayload.oldSeverity;
} & {
    newSeverity: IncidentSeverityUpdatePayload.newSeverity;
    oldSeverity: IncidentSeverityUpdatePayload.oldSeverity;
});

export namespace IncidentSeverityUpdatePayload {

    export enum newSeverity {
        LOW = 'Low',
        MODERATE = 'Moderate',
        HIGH = 'High',
        CRITICAL = 'Critical',
    }

    export enum oldSeverity {
        LOW = 'Low',
        MODERATE = 'Moderate',
        HIGH = 'High',
        CRITICAL = 'Critical',
    }


}
