import type { DataOptions } from 'vuetify';

type SortValues = { sortBy?: DataOptions['sortBy'], sortDesc?: DataOptions['sortDesc'] };

/**
 * Composes sort values based on the given sortBy and sortDesc arrays.
 *
 * @param sortBy - An array of properties to sort by.
 * @param sortDesc - An array of booleans indicating whether to sort in descending order for each property.
 * @returns An array of composed sort values in the format ['prop1,ASC', 'prop2,DESC'].
 */
const composeSortValues = ({ sortBy, sortDesc }: SortValues) => {
  // from sortBy [prop1, prop2] and sortDesc [false, true]
  // to ['prop1,ASC', 'prop2,DESC'];
  if (!sortBy || !sortDesc) return [];
  return sortBy.map((prop, index) => `${prop},${sortDesc[index] ? 'DESC' : 'ASC'}`);
};

/**
 * Decomposes an array of sort values into an object with separate sortBy and sortDesc arrays.
 * @param sort - The array of sort values in the format ['prop1,ASC', 'prop2,DESC'].
 * @returns An object with sortBy and sortDesc arrays.
 */
const decomposeSortValues = (sort: string[] | undefined): SortValues => {
  // from ['prop1,ASC', 'prop2,DESC']
  // to { sortBy: ['prop1', 'prop2'], sortDesc: [false, true] }
  const sorting: SortValues = { sortBy: [], sortDesc: [] };
  if (sort?.length) {
    for (const sortValue of sort) {
      const [sortBy, sortDesc] = sortValue.split(',');
      sorting.sortBy!.push(sortBy);
      sorting.sortDesc!.push(sortDesc === 'DESC');
    }
  }
  return sorting;
};

export {
  composeSortValues,
  decomposeSortValues,
};
