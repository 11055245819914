<template lang="pug">
div
  .d-flex( v-if="hasLoaded" )

    .d-flex.flex-column.flex-grow-1( v-if="canHaveTimeWindow" )
      .d-flex
        template( v-if="isNotSet" )
          .grey--text.mr-2 {{ $t('common.words.none') }}

        template( v-if="hasTimeWindow" )
          .d-flex
            .grey--text.mr-3 {{ timeWindowField }}

        template( v-if="hasTimeWindow" )
          .d-flex.flex-grow.align-center.mr-2
            v-icon( small ).mr-1 icon-clock
            span.grey--text.mr-1 {{ timeWindow.timeWindowLast }}
            span.grey--text.mr-1 {{ timeWindow.timeWindowUnit }}
            span.grey--text.mr-1 {{ $t('assets.time_window').toLowerCase() }}

        template( v-if="hasOffset" )
          .d-flex.flex-grow.align-center.mr-2
            v-icon( small ).mr-1 icon-calendar-clock
            span.grey--text.mr-1 {{ timeWindow.offsetValue }}
            span.grey--text.mr-1 {{ timeWindow.offsetUnit }}
            span.grey--text.mr-1 {{ $t('assets.offset') }}

        v-tooltip( top :disabled="canEdit" )
          template(v-slot:activator='{ on }')
            .ml-2( v-on="on" )
              v-btn.mr-2( v-if="!editMode" :disabled="!canEdit" icon x-small @click="enterEditMode" )
                v-icon( small ) icon-edit
          span {{ $t('app.rights.suggestions_no_rights') }}

      .d-flex( v-if="editMode" )
        v-form.flex-grow-1.ma-2( ref="form" v-model='valid' )

          v-row( dense )
            v-col( cols="12" )
              v-autocomplete(
                v-model='timeWindowModel.timeWindowField'
                @input="setTimeWindowFieldType"
                :items='timeFields'
                :rules='timeWindowFieldRules'
                label='Time window Field'
                item-text='uniqueName'
                item-value='uniqueName'
                hint='Time window field'
                dense outlined persistent-hint clearable required)

          v-row( dense )
            v-col( cols="4" )
              v-checkbox( dense v-model="timeWindowModel.hasTimeWindow" label="Add time window" )

            v-col( cols="4" )
              v-text-field(
                v-if="timeWindowModel.hasTimeWindow"
                v-model='timeWindowModel.timeWindowLast'
                :rules='lastRules'
                type="number"
                label='Last'
                dense outlined )

            v-col( cols="4" )
              v-select(
                v-if="timeWindowModel.hasTimeWindow"
                v-model='timeWindowModel.timeWindowUnit'
                :items="['minutes','hours','days']"
                :rules='timeWindowUnitRules'
                label='Time Unit'
                hint='Time unit'
                dense mandatory outlined persistent-hint required)

          v-row( dense )
            v-col( cols="4" )
              v-checkbox( dense v-model="timeWindowModel.hasOffset" label="Add offset" )

            v-col( cols="4" )
              v-text-field(
                v-if="timeWindowModel.hasOffset"
                v-model='timeWindowModel.offsetValue'
                :rules='lastRules'
                type="number"
                label='Last'
                dense required outlined mandatory )

            v-col( cols="4" )
              v-select(
                v-if="timeWindowModel.hasOffset"
                v-model='timeWindowModel.offsetUnit'
                :items="['hours','days']"
                :rules='timeWindowUnitRules'
                label='Time Unit'
                hint='Time unit'
                dense mandatory outlined persistent-hint required)

          v-row( dense )
            v-col( cols="12" )
              .d-flex
                v-btn( small :disabled="!canDelete" class="custom-alert" color='alert' text outlined @click="deleteTimeWindow" ) {{ $t('common.words.delete') }}
                v-btn.ml-auto( small class="custom-secondary" color='secondary' text outlined @click="exitEditMode" ) {{ $t('common.words.cancel') }}
                v-btn.ml-2( small :disabled="disabled" class="custom-primary" color='primary'  @click="save" ) {{ $t('common.words.save') }}

    .d-flex.flex-column( v-else )
      .grey--text {{ $t('no_data.no_temporal') }}

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override mb-5"
    type="list-item-three-line" )

</template>

<script lang="ts">
// eslint-disable-next-line
// @ts-nocheck
import { TimeWindow, FieldSearchResultDto, AssetService } from '@/api';
import {
  Vue, Component, Emit, VModel, Prop, Ref, Watch,
} from 'vue-property-decorator';
import { createDatasetParamsDto } from '@/api/patches';
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';

@Component
export default class InputTimeWindowEdit extends Vue {
  @VModel({ type: Object, default: null }) timeWindow!: TimeWindow | null;

  @Prop({ type: String, required: true }) urn!: string;

  @Prop({ type: Boolean, required: false, default: false }) canEdit!: string;

  @Ref('form') form!: any;

  editMode = false;

  timeWindowModel: TimeWindow | null = {};

  valid = true;

  fields: FieldSearchResultDto[] = [];

  timeFields: FieldSearchResultDto[] = [];

  timeWindowUnitRules = [(v: string) => isConvertibleToNotBlankString(v) || 'Time unit is required'];

  lastRules = [
    (v: string) => isConvertibleToNotBlankString(v) || 'Last is required',
    (v: number) => v > 0 || 'Must be > 0',
    (v: string) => (v && /^\d+$/.test(v)) || 'Must be an integer',
  ];

  timeWindowFieldRules = [(v: string) => isConvertibleToNotBlankString(v) || 'Time field is required'];

  get hasLoaded() {
    return this.timeWindow !== null;
  }

  get canHaveTimeWindow() {
    return this.timeFields.length > 0;
  }

  get disabled() {
    return !this.valid
    || !this.validTimeWindow
    || !this.validOffset
    || !this.hasAddSomething
    || !this.timeWindowModel?.timeWindowField;
  }

  get isNotSet() {
    return !this.timeWindow?.hasOffset && !this.timeWindow?.hasTimeWindow && !this.editMode;
  }

  get hasTimeWindow() {
    return this.timeWindow?.hasTimeWindow && !this.editMode;
  }

  get hasOffset() {
    return this.timeWindow?.hasOffset && !this.editMode;
  }

  get hasAddSomething() {
    return this.timeWindowModel?.hasTimeWindow || this.timeWindowModel?.hasOffset;
  }

  get timeWindowField() {
    return `\`${this.timeWindow?.timeWindowField}\``;
  }

  get validTimeWindow() {
    if (!this.timeWindowModel?.hasTimeWindow) {
      return true;
    }
    return Boolean(this.timeWindowModel?.timeWindowLast)
        && Boolean(this.timeWindowModel?.timeWindowUnit);
  }

  get validOffset() {
    if (!this.timeWindowModel?.hasOffset) {
      return true;
    }
    return Boolean(this.timeWindowModel?.offsetValue)
      && Boolean(this.timeWindowModel?.offsetUnit);
  }

  get canDelete() {
    return this.timeWindow && this.timeWindow.timeWindowField !== null;
  }

  get timeWindowText() {
    return `${this.timeWindow?.timeWindowField}  ${this.timeWindow?.timeWindowLast} ${this.timeWindow?.timeWindowUnit} based on <b></b>`;
  }

  get offsetText() {
    return `Offset ${this.timeWindow?.offsetValue} ${this.timeWindow?.offsetUnit}`;
  }

  @Watch('urn')
  onSelectedChange() {
    this.editMode = false;
  }

  @Watch('timeWindowModel', { deep: true })
  validateTimeWindow() {
    this.$nextTick(() => {
      this.form?.validate();
    });
  }

  setTimeWindowFieldType(fieldName: FieldSearchResultDto['uniqueName']) {
    const field = this.fields.find((f) => f.uniqueName === fieldName);
    if (this.timeWindowModel) this.timeWindowModel.timeWindowFieldType = field?.type;
  }

  async getTemporalFields() {
    this.timeFields = await AssetService.getAssetFieldsWithTypeOption({
      urn: this.urn,
      type: 'temporal',
    });
  }

  enterEditMode() {
    this.editMode = true;
    this.timeWindowModel = { ...this.timeWindow };
  }

  exitEditMode() {
    this.editMode = false;
  }

  @Emit('update')
  deleteTimeWindow() {
    this.exitEditMode();
    return createDatasetParamsDto({ params: null });
  }

  @Emit('update')
  save() {
    this.exitEditMode();
    return createDatasetParamsDto({ params: this.timeWindowModel });
  }

  async mounted() {
    await this.getTemporalFields();
  }
}
</script>
