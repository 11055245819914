import { type MonitorSettingsOptions } from './common/types';
import { dynamicSettings, staticSettings, exactSettings } from './common/thresholdSettings';
import { interlinkedThresholdToLegacy } from './common/legacyConverter';

const SiffletInterlinkedMetricsRule: MonitorSettingsOptions = {
    thresholdSettings: [
        {
            ...dynamicSettings,
            description: 'monitor_wizard.threshold_settings.dynamic_alternate_description',
            hasAnomalyConditions: false,
            convertThresholdToLegacy: interlinkedThresholdToLegacy,
        },
        {
            ...staticSettings,
            description: 'monitor_wizard.threshold_settings.static_alternate_description',
            convertThresholdToLegacy: interlinkedThresholdToLegacy,
        },
        {
            ...exactSettings,
            convertThresholdToLegacy: interlinkedThresholdToLegacy,
        },
    ],
    canClone: false,
};

export default SiffletInterlinkedMetricsRule;
