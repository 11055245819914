<template lang="pug">
.input-smart-regex.my-4

  .d-flex.align-center.mb-4
    v-switch.mt-0(
      v-model="aiToggle"
      color="iconAi"
      inset dense hide-details)
    v-icon( color="iconAi" ) icon-sparkles
    span {{ $t('inputs.smart_regex.text') }}
    v-chip.ml-2( small outlined ) {{ $t('common.words.beta') }}
    v-tooltip( top max-width="300")
      template(v-slot:activator='{ on }')
        v-icon.ml-2( v-on="on" color="iconNeutral" size="20" ) icon-info-circle-outline
      HtmlView( :html="$t('monitors.smart_regex_tooltip')" )

  .d-flex.mb-4( v-if="aiToggle"  )
    v-text-field(
      v-model="input"
      hide-details
      :label="$t('monitors.natural_language_input')"
      dense clearable outlined required  )

    SButton.ml-4(
      :disabled="disabled"
      :text="$t('monitors.generate_regex')"
      color="secondary"
      class="custom-secondary"
      variant="outlined"
      :async-action="generateRegex")

  .d-flex
    v-text-field(
      :label="$t('monitors.regex_syntax')"
      v-model="regex"
      :required="required"
      :rules="rules"
      dense clearable outlined )

</template>

<script lang="ts">
import {
  Vue, Component, VModel, Prop,
} from 'vue-property-decorator';
import { RuleDto, TextToRegexService } from '@/api';

@Component
export default class InputSmartRegex extends Vue {
  @VModel({ type: String }) regex!: string;

  @Prop({ type: Boolean, default: false }) required!: any;

  @Prop({ type: Array, required: true }) rules!: any;

  @Prop({ type: Object, required: true }) rule!: RuleDto;

  aiToggle = false;

  input = '';

  get disabled() {
    return !this.input;
  }

  get datasetsIds() {
    return this.rule.datasets.map(({ id }) => id);
  }

  async generateRegex() {
    const response = await TextToRegexService.textToRegex({ requestBody: { text: this.input, datasetIds: this.datasetsIds } });
    this.regex = response.regexPrediction || '';
  }
}
</script>
