<template lang="pug">
v-card( outlined id="dashboardSlackIntegration" ).pa-3.py-3
  v-data-iterator(
    :items="structuredItems"
    :page="options.page"
    :items-per-page="options.itemsPerPage"
    hide-default-footer
  )
    template( v-slot:default="props" )
      DashboardDataStackIntegrationRow( v-for="(item, index) in props.items" :key="index" :item="item" )
    template(v-slot:no-data)
      NoDataAvailablePlaceholder( :text="$t('dashboard.no_integration_source')")
    template(v-slot:footer="footer")
      // eslint-disable-next-line vue/valid-v-bind-sync
      SiffletifyDataFooter( :footer.sync="footer" :scroll-to="'dashboardSlackIntegration'" @update="updateOptions($event)" )

</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import dashboardModule from '@/store/modules/dashboard';
import store from '@/store';
import src from '@/utils/filters/src';
import { StatisticsService, DataStackSummaryDto, DataStackGroupSummaryDto } from '@/api';
import SiffletifyDataFooter
  from '@/components/siffletify/siffletify-data-footer/siffletify-data-footer.vue';
import { DataOptions } from 'vuetify';

const auth = getModule(authModule, store);
const dashboard = getModule(dashboardModule, store);

@Component({
  components: { SiffletifyDataFooter },
})
export default class DashboardDataStackIntegration extends Vue {
  dataStackSummaryDto: DataStackSummaryDto = {};

  structuredItems: any = [];

  options = {
    page: 1,
    itemsPerPage: 10,
  };

  get image() {
    return src('dbt', 'datasources');
  }

  get domain() {
    return auth.selectedDomain?.name;
  }

  async structureData() {
    this.structuredItems = Object.entries(this.dataStackSummaryDto!.dataStackGroupByDatasourceType || {} as DataStackGroupSummaryDto).map((entry) => entry);
  }

  updateOptions(options: Partial<DataOptions>) {
    const {
      itemsPerPage,
    } = options;
    this.options.page = options.itemsPerPage !== this.options.itemsPerPage ? 1 : options.page!;
    this.options.itemsPerPage = itemsPerPage!;
  }

  get tags() {
    return dashboard.getSelectedTags;
  }

  @Watch('tags', { deep: true })
  async onTagsChanged() {
    await this.fetchDataStack();
  }

  async fetchDataStack() {
    this.dataStackSummaryDto = await StatisticsService.getDataStack({ domain: this.domain, tag: this.tags });
    await this.structureData();
  }

  async mounted() {
    await this.fetchDataStack();
  }
}
</script>
