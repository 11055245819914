/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserPermissionAssignmentDto } from './UserPermissionAssignmentDto';

export type UpdateUserUiRequestDto = {
    domains: Array<UserPermissionAssignmentDto>;
    isActive: boolean;
    name: string;
    role: UpdateUserUiRequestDto.role;
};

export namespace UpdateUserUiRequestDto {

    export enum role {
        ADMIN = 'ADMIN',
        SYSTEM_EDITOR = 'SYSTEM_EDITOR',
        SYSTEM_VIEWER = 'SYSTEM_VIEWER',
    }


}
