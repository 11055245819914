/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicPageDtoPublicUserGetDto } from '../models/PublicPageDtoPublicUserGetDto';
import type { PublicUserCreateDto } from '../models/PublicUserCreateDto';
import type { PublicUserGetDto } from '../models/PublicUserGetDto';
import type { PublicUserResetPasswordDto } from '../models/PublicUserResetPasswordDto';
import type { PublicUserUpdateDto } from '../models/PublicUserUpdateDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UsersService {

    /**
     * Get list of users
     * @returns PublicPageDtoPublicUserGetDto Users retrieved
     * @throws ApiError
     */
    public static publicGetUsers({
        page,
        itemsPerPage,
    }: {
        /**
         * The page number to retrieve. Starts at 0.
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page.
         */
        itemsPerPage?: number,
    }): CancelablePromise<PublicPageDtoPublicUserGetDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users',
            query: {
                'page': page,
                'itemsPerPage': itemsPerPage,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a user
     * @returns PublicUserGetDto User created
     * @throws ApiError
     */
    public static publicCreateUser({
        requestBody,
    }: {
        requestBody: PublicUserCreateDto,
    }): CancelablePromise<PublicUserGetDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a user
     * @returns void
     * @throws ApiError
     */
    public static publicDeleteUser({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a user by id
     * @returns PublicUserGetDto User retrieved
     * @throws ApiError
     */
    public static publicGetUser({
        id,
    }: {
        id: string,
    }): CancelablePromise<PublicUserGetDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a user
     * @returns PublicUserGetDto User updated
     * @throws ApiError
     */
    public static publicUpdateUser({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: PublicUserUpdateDto,
    }): CancelablePromise<PublicUserGetDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Reset a user password
     * @returns PublicUserResetPasswordDto User password reset
     * @throws ApiError
     */
    public static publicResetUserPassword({
        id,
    }: {
        id: string,
    }): CancelablePromise<PublicUserResetPasswordDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/users/{id}/reset-password',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                500: `Internal server error`,
            },
        });
    }

}
