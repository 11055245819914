<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="principalRules"
    label='Principal'
    data-cy="principal"
    dense clearable outlined)

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';

@Component
export default class InputName extends Vue {
    principalRules = [
        (v: string) => isConvertibleToNotBlankString(v) || 'Principal is required',
    ];
}
</script>
