<script setup lang="ts">import { computed as _computed } from 'vue';

interface IngestionTypeIconProps {
  ingestionId: 'DECLARATIVE' | 'SIFFLET_SOURCED';
}
const props =  defineProps({
  ingestionId: null
});

const icon = _computed(() => {
  switch (props.ingestionId) {
    case 'DECLARATIVE':
      return 'icon-data-source-declarative';
    default:
      return 'icon-data-source';
  }
});
</script>

<template lang="pug">
v-icon( size="20" ) {{ icon }}
</template>
