/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AssetOverview } from './AssetOverview';

export type DagAssetOverview = (AssetOverview & {
    type?: DagAssetOverview.type;
});

export namespace DagAssetOverview {

    export enum type {
        AIRFLOW_DAG = 'AIRFLOW_DAG',
        AIRFLOW_DAG_REF = 'AIRFLOW_DAG_REF',
        OTHER_REF = 'OTHER_REF',
    }


}
