<template lang="pug">
v-row
  v-col( cols="12" )
    .mb-2
      .font-weight-medium {{ $t('conditional-rules.join_type') }}
      .font-weight-normal {{ $t('conditional-rules.data_can_be_joined') }}
    JoinSelector( v-model="joinList[0].joinType" )
  v-col.mb-2( cols="12" )
    v-card( class="d-inline-flex pa-2" outlined tile style="border:0;" )
      ConditionalRulesDataset( :dataset="dataset1" :datasets="datasets" )
      ConditionalRulesJoinTypeIcon( :color1="color1" :color2="color2" :joinType="joinList[0].joinType" height="100" width="150" )
      ConditionalRulesDataset( :dataset="dataset2" :datasets="datasets" )
</template>

<script lang="ts">
import * as ConditionalRulesTYPES from '@/@types/conditional-rules';
import {
  Vue, Component, VModel, Prop,
} from 'vue-property-decorator';
import { DatasetLightDto } from '@/api';
import colors from '@/plugins/colors';

@Component
export default class ConditionalRulesJoinType extends Vue {
  @VModel({ type: Array }) readonly joinList!: ConditionalRulesTYPES.JoinItem[];

  @Prop({ type: Array, required: true }) datasets!: DatasetLightDto[];

  get dataset1() {
    return this.datasets[0];
  }

  get dataset2() {
    return this.datasets[1];
  }

  get squareColors() {
    return Object.values(colors.colorList);
  }

  get color1() {
    return this.squareColors[0];
  }

  get color2() {
    return this.squareColors[1];
  }
}
</script>

<style scoped>

</style>
