<template lang="pug">
div
  v-row.py-3
    v-col( cols="4" md="2")
      v-avatar.mr-3(tile size="25" class="mr-1")
        img(:src="image" :alt="name")
      span {{ formatName(name) }}
      v-chip.ml-1.font-weight-bold.beta-chip(v-if="isBeta" x-small outlined) {{ $t('common.words.beta') }}
    v-col( cols="4" md="2" v-for="(value, text, index) in quantityByType" :key="index" )
      span {{ formatValue(value) }} {{ formatText(text, value) }}
  v-divider
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';
import { isSourceBeta } from '@/utils/betaComparator';
import { getProviderIconSrc } from '@/utils/provider-icons';

const auth = getModule(authModule, store);

@Component
export default class DashboardDataStackIntegrationRow extends Vue {
  @Prop({ type: Array }) readonly item!: any;

  BETA_SOURCES = [
    'athena',
    'hive',
    'oracle',
    'airflow',
    'fivetran',
    'synapse',
    'microstrategy',
  ];

  get name() {
    return this.item[0] as string;
  }

  get isBeta() {
    return isSourceBeta(this.name);
  }

  get quantityByType() {
    return this.item[1].quantityByType as Record<string, number>;
  }

  get image() {
    return getProviderIconSrc(this.item[1].lineagePlatform);
  }

  formatValue(value: number) {
    return new Intl.NumberFormat(this.$i18n.locale, { maximumSignificantDigits: 2 }).format(value);
  }

  formatName(text: string) {
    return this.$te(`datasource_types.${text}`) ? this.$t(`datasource_types.${text}`) : text;
  }

  formatText(text: string, value: number) {
    return this.$te(`asset_types.${text}`) ? this.$tc(`asset_types.${text}`, value) : text;
  }
}
</script>

<style lang="scss" scoped>
.beta-chip {
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: 700;
}
</style>
