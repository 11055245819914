import colors from 'vuetify/es5/util/colors';

export const VERTICAL_SPACE_PER_NODE = 40;
export const HORIZONTAL_SPACE_PER_LAYER = 500;
export const DEFAULT_NODE_WIDTH = 240;
export const DEFAULT_HEAD_NODE_HEIGHT = 108;
export const DEFAULT_ATTACHED_ENTITY_HEIGHT = 70;
export const LAYER_LEFT_MARGIN = 100;
export const DEFAULT_FIELD_NODE_HEIGHT = 42;
export const DEFAULT_FIELD_NODE_WIDTH = 240;
export const PNG_EXPORT_SCALE_FACTOR = 3;
export const MAX_DEEP_LEVEL = 2;
export const ZOOM_MIN = 0.5;
export const ZOOM_MAX = 1.9;
export const SCALE_PADDING = 100;
export const ANIMATION_DURATION = 300;
export const DEPLOY_FIRST_LEVEL = true;
export const BUTTON_EXPAND_COLOR = colors.shades.white;
export const BUTTON_BORDER_COLOR = colors.grey.lighten5;
export const BUTTON_COLLAPSE_COLOR = colors.shades.white;
export const DASH_SIZE = 4;
