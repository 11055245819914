<template lang="pug">
v-row( v-if="hasModel" )
  v-col( cols="12" v-if="hasMultipleDatasets" )
    ConditionalRulesJoinType( v-if="model" v-model="model.joinList" :datasets="rule.datasets" )
    ConditionalRulesJoinKeyField( v-if="model" v-model="model.joinList" :datasets="rule.datasets" )
  v-col( cols="12")
    span(v-if="hasMultipleDatasets" ).font-weight-medium {{ $t('conditional-rules.monitor_anomalies') }}
    span(v-else ).font-weight-medium {{ $t('conditional-rules.monitor_anomaly') }}
  v-col( cols="12")
    ConditionalRulesConditionGroup(
      v-for="(conditionGroup, index) of model.conditionsGroup"
      :key="index"
      :index="index"
      :conditionGroup="conditionGroup"
      :logicalOperator="model.logicalOperator"
      @removeConditionGroup="removeConditionGroup"
      @addCondition="addCondition"
      @removeCondition="removeCondition"
      @updateOperator="updateOperator"
      @updateDateTime="updateDateTime")
  v-col( cols="12" class="mb-6")
    v-btn(depressed @click="addConditionGroup")
      v-icon(small) icon-add
      span {{ $t('conditional-rules.add_condition_group') }}

</template>

<script lang="ts">
import {
  Vue, Component, ProvideReactive, Prop,
} from 'vue-property-decorator';
import * as ConditionalRulesTYPES from '@/@types/conditional-rules';
import { RuleDto } from '@/api';

@Component
export default class ConditionalRules extends Vue {
  @Prop({ type: Object, required: true }) rule!: RuleDto;

  @Prop({ type: Object, default: {} }) value!: ConditionalRulesTYPES.ConditionalRules;

  @Prop({ type: Object, required: true }) options!: any;

  @Prop({ type: Object, required: true }) schema!: any;

  @Prop({ type: Object, required: true }) fullSchema!: any;

  @Prop({ type: String, required: true }) fullKey!: any;

  @Prop({ type: String, default: '' }) label!: any;

  @Prop({ type: String, default: '' }) htmlDescription!: any;

  @Prop({ type: Boolean, default: false }) disabled!: any;

  @Prop({ type: Boolean, default: false }) required!: any;

  @Prop({ type: Array, required: true }) rules!: any;

  @Prop({ type: Object, required: true }) on!: any;

  emptyModel: ConditionalRulesTYPES.ConditionalRules = {
    joinList: [],
    logicalOperator: ConditionalRulesTYPES.ConditionalRulesLogicalOperator.AND,
    conditionsGroup: [{
      logicalOperator: ConditionalRulesTYPES.ConditionalRulesLogicalOperator.AND,
      conditions: [
        {
          field: {
            id: '',
            name: '',
            type: '',
          },
          operator: '',
          value: '',
          typology: '',
        },
      ],
    }],
  };

  get hasMultipleDatasets() {
    return this.rule.datasets!.length > 1;
  }

  get model() {
    return this.value;
  }

  set model(value) {
    this.on.input(value);
  }

  @ProvideReactive()
  get datasets() {
    return this.rule.datasets!;
  }

  get hasModel() {
    return this.model?.conditionsGroup?.length > 0;
  }

  addConditionGroup() {
    this.model.conditionsGroup.push({
      logicalOperator: ConditionalRulesTYPES.ConditionalRulesLogicalOperator.AND,
      conditions: [
        {
          field: {
            name: '',
            type: '',
            id: '',
          },
          operator: '',
          value: '',
          typology: '',
        },

      ],
    });
  }

  removeConditionGroup(index: number) {
    this.model.conditionsGroup.splice(index, 1);
  }

  addCondition(index: number) {
    this.model.conditionsGroup[index].conditions.push({
      field: {
        id: '',
        name: '',
        type: '',
      },
      operator: '',
      value: '',
      typology: '',
    });
  }

  removeCondition(args: { conditionGroupIndex: number, conditionIndex: number; }) {
    this.model.conditionsGroup[args.conditionGroupIndex].conditions.splice(args.conditionIndex, 1);
  }

  updateOperator(args: any) {
    if (args.level === 1) {
      this.model.conditionsGroup[args.index].logicalOperator = args.value;
    } else {
      this.model.logicalOperator = args.value;
    }
  }

  updateDateTime(args: any) {
    this.model.conditionsGroup[args.groupIndex].conditions[args.conditionIndex].value = args.dateTime;
  }

  init() {
    if (this.hasMultipleDatasets) {
        this.emptyModel.joinList.push({
          joinType: ConditionalRulesTYPES.ConditionalRulesJoinType.INNER,
          joinKeyFields: [{
            id: '',
            name: '',
            type: '',
          }, {
            id: '',
            name: '',
            type: '',
          }],
      });
    }

    this.model = this.emptyModel;
  }

  mounted() {
    if (!this.model) {
      this.init();
    }
  }
}
</script>
