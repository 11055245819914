/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MonitoringSearchDtoV2 } from '../models/MonitoringSearchDtoV2';
import type { MonitorSearchCriteria } from '../models/MonitorSearchCriteria';
import type { RuleRunStatusSummaryDto } from '../models/RuleRunStatusSummaryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MonitorService {

    /**
     * Get summary for monitor status
     * @returns RuleRunStatusSummaryDto Successfully fetch summary for monitor status
     * @throws ApiError
     */
    public static getMonitorsStatusSummary({
        domain,
        tag,
    }: {
        /**
         * Domain used for stats
         */
        domain?: string,
        /**
         * UUIDs of Tags used for stats
         */
        tag?: Array<string>,
    }): CancelablePromise<RuleRunStatusSummaryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/monitors/status-summary',
            query: {
                'domain': domain,
                'tag': tag,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all Sifflet monitors
     * @returns MonitoringSearchDtoV2 Successfully fetch monitor results
     * @throws ApiError
     */
    public static getAllMonitor({
        requestBody,
    }: {
        requestBody: MonitorSearchCriteria,
    }): CancelablePromise<MonitoringSearchDtoV2> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/monitors',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
