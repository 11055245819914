<template lang="pug">
v-form( ref='form' v-model='isValid' )
    v-row
      v-col
        InputCron( v-model="datasource.cronExpression" is-datasource )

</template>

<script lang="ts">
import {
  Vue, Component, VModel, Watch,
} from 'vue-property-decorator';
import { DatasourceDto } from '@/api';

@Component
export default class DatasourceFrequency extends Vue {
  @VModel({ type: Object }) datasource!: DatasourceDto;

  isValid = false;

  @Watch('isValid', { immediate: true })
  emitValid(validity: boolean) {
    this.$emit('validate', validity);
  }
}
</script>
