/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AssetOverview } from './AssetOverview';
import type { TransformationAssetDto } from './TransformationAssetDto';

export type TransformationAssetOverview = (AssetOverview & {
    transformationAssetDto?: TransformationAssetDto;
    type?: TransformationAssetOverview.type;
});

export namespace TransformationAssetOverview {

    export enum type {
        FIVETRAN_CONNECTOR = 'FIVETRAN_CONNECTOR',
    }


}
