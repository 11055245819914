<template lang="pug">
  v-row
    v-col( cols="12" )
      v-tabs( :value="activeTab" :id="scrollTo")
        v-tab(
          v-for="tab in tabs"
          :key="tab.id"
          @click="selectTab(tab.id)"
        ) {{ tab.name }}
      v-divider
    v-col( cols="12" )
      v-card.pa-2( outlined min-width="100%" )
        LegacyIncidentsResults( dashboard :scrollTo="scrollTo" )
</template>

<script lang="ts">
import {
 Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import authModule from '@/store/modules/auth';
import incidentsModule from '@/store/modules/incidents';

let auth :authModule;
let incidents: incidentsModule;

@Component
export default class DashboardIncidentsAssigned extends Vue {
  @Prop({ type: Boolean }) dashboard!: boolean;

  get userId() {
    return auth?.user?.id;
  }

  activeTab = 0;

  get tabs() {
    return [{
      id: 0,
      name: this.$t('incidents.assigned_to_me'),
      status: '',
      scrollTo: 'incidentAssigned',
    }, {
      id: 1,
      name: this.$t('incidents.recent_incidents'),
      status: '',
      scrollTo: 'incidentRecent',
    },
    ];
  }

  get selectedTab() {
    return this.tabs[this.activeTab];
  }

  get scrollTo() {
    return this.selectedTab.scrollTo || '';
  }

  selectTab(id: number) {
    this.activeTab = id;
  }

  @Watch('activeTab')
  updateIssues() {
    incidents.resetSearchParameters();
    incidents.setOptions({ itemsPerPage: 5, page: 1, sort: ['createdDate,DESC'] });
    if (this.activeTab === 0 && this.userId) {
      incidents.setUsers([this.userId]);
    }
  }

  beforeCreate() {
    auth = getModule(authModule, store);
    incidents = getModule(incidentsModule, store);
  }

  beforeMount() {
    incidents.resetState();
  }

  async mounted() {
    incidents.resetSearchParameters();
    if (this.userId) incidents.setUsers([this.userId]);
    incidents.setOptions({ itemsPerPage: 5, page: 1, sort: ['createdDate,DESC'] });
  }
}
</script>
