<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { Vue } from 'vue-property-decorator';
import { IncidentLightDto, IncidentService } from '@/api';
import SModal from '@/components/SModal.vue';
import i18n from '@/i18n';

interface Props {
  mergeableIncidents: IncidentLightDto[];
}
const props = defineProps({
  mergeableIncidents: { default: () => [] }
});

const modal = _ref<InstanceType<typeof SModal> | null>(null);
const ruleMergeIncidentTarget = (value: IncidentLightDto) => !!value;
const isValid = _ref(false);
let processing = _ref(false);
// Single incident for all incidents to be merged into
let mergeIncidentTarget = _ref<IncidentLightDto | null>(null);
// Return all props.mergeableIncidents except the target incident
const mergeIncidentsSources = _computed((): IncidentLightDto[] => props.mergeableIncidents.filter((incident) => incident.id !== mergeIncidentTarget.value?.id));
const selectableMergeableIncidents = _computed(() => props.mergeableIncidents.map((incident) => ({
  text: incident.name,
  value: incident,
})));

type IncidentMergeFormEmits = {
  (event: 'onMerge'): void,
  (event: 'onMergeError'): void,
}
const emit = defineEmits(["onMerge", "onMergeError"]);

/**
 * Handle form closing
 */
 const handleCancel = () => {
  modal.value?.closeModal();
};

/**
 * Reset form values
 */
const resetForm = () => {
  mergeIncidentTarget.value = null;
};

/**
 * Merge creation success handler
 */
const mergeIncidentsSuccess = () => {
  if (mergeIncidentTarget.value) {
    Vue.notify({
      title: i18n.t('incidents.edit.merge_success_title'),
      text: i18n.tc('incidents.edit.merge_success_text', props.mergeableIncidents.length - 1, { incident: mergeIncidentTarget.value.name }),
      type: 'success',
    });
    emit('onMerge');
  }
};

/**
 * Merge creation error handler
 */
const mergeIncidentsError = () => {
  Vue.notify({
    title: i18n.t('incidents.edit.merge_error_title'),
    text: i18n.t('incidents.edit.merge_error_text'),
    type: 'error',
  });
};

/**
 * Merge creation submission
 */
 const handleMerge = () => {
  processing.value = true;
  if (mergeIncidentTarget.value) {
    IncidentService.mergeIncidents({
      requestBody: {
        incidentIds: props.mergeableIncidents.map((incident) => incident.id),
        mergeToIncidentId: mergeIncidentTarget.value.id,
      },
    }).then(() => {
      mergeIncidentsSuccess();
    }).catch(() => {
      mergeIncidentsError();
    }).finally(() => {
      processing.value = false;
    });
  }
};

/**
 * Handle form opening.
 * Publicly exposed method.
 * @param
 */
const handleIncidentsMerging = () => {
  resetForm();
  modal.value?.openModal();
};

defineExpose({
  handleIncidentsMerging,
});
</script>

<template lang="pug">
div
  v-form(
    ref='form'
    v-model='isValid'
  )
    SModal(
    ref="modal"
    :title="$tc('incidents.edit.merge_form_title', mergeableIncidents.length)"
    :on-save="handleMerge"
    :on-cancel="handleCancel"
    :on-close="handleCancel"
    :can-save="isValid && !processing"
    remove-dividers
    width="600"
    data-cy="incident-merge-form-modal-content")
      template(#subtitle)
        span.grey--text.mr-auto.text-subtitle-1 {{ $t('incidents.edit.merge_form_description') }}

      SLabel {{ $t('incidents.edit.merge_target_incident_label') }}

      v-select(
        :items="selectableMergeableIncidents"
        v-model="mergeIncidentTarget"
        :placeholder="$t('incidents.edit.merge_target_incident_placeholder')"
        :menu-props="{ bottom: true, offsetY: true }"
        :rules="[ruleMergeIncidentTarget]"
        name="incident-target"
        clearable
        dense
        outlined
        data-cy-select="incident-target")

        template(#item="{ item }")
          .d-flex(data-cy="incident-item")
            v-icon.mr-1( small color="iconDanger" ) icon-flag
            span {{item.text }}

        template(#selection="{ item }")
          .d-flex(data-cy="incident-item")
            v-icon.mr-1( small color="iconDanger" ) icon-flag
            span {{item.text }}

      SLabel {{ $t('incidents.edit.merge_source_incidents_label') }}

      p(v-if="!mergeIncidentTarget") {{ $t('incidents.edit.merge_target_incident_notice')}}
      div(v-else)
        ul.list-unstyled
          li.mb-1(v-for="incident in mergeIncidentsSources" :key="incident.id")
            .d-flex(data-cy="incident-item")
              v-icon.mr-1( small color="iconNeutral" ) icon-flag
              span {{ incident.name }}

</template>

<style lang="scss" scoped>
.text-subtitle-1 {
  word-break: normal;
}
</style>
