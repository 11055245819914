import { LineageEntityDto } from '@/api/models/LineageEntityDto';
import {
DEFAULT_ATTACHED_ENTITY_HEIGHT,
  DEFAULT_FIELD_NODE_HEIGHT,
  DEFAULT_HEAD_NODE_HEIGHT,
  DEFAULT_NODE_WIDTH,
  HORIZONTAL_SPACE_PER_LAYER,
  LAYER_LEFT_MARGIN,
  VERTICAL_SPACE_PER_NODE,
} from './constants';
import Point from './Point';
import Node from './Node';
import Dimension from './Dimension';
import { Canvas, indexes } from './Canvas';

export default class Layer {
    readonly id: number;

    public location: Point;

    private dimension: Dimension;

    private _nodes: Map<string, Node> = new Map();

    constructor(
      number: number,
    ) {
      this.id = number;
      const x = number * HORIZONTAL_SPACE_PER_LAYER + LAYER_LEFT_MARGIN;
      const y = 0;
      this.location = new Point(x, y);
      this.dimension = new Dimension(DEFAULT_NODE_WIDTH, 0);
    }

    public nodes(): Node[] {
      return Array.from(this._nodes.values());
    }

    public addStartNode(nodeData: LineageEntityDto) {
      const addedNode = this.addNode(nodeData, true);
      return addedNode ? [addedNode] : [];
    }

    private addNode(nodeData: LineageEntityDto, isStartNode = false) {
      const { urn } = nodeData;
      if (!this._nodes.has(urn)) {
        const node = new Node({
          location: new Point(this.location.x, 0),
          dimension: new Dimension(DEFAULT_NODE_WIDTH, DEFAULT_HEAD_NODE_HEIGHT
                    + (nodeData.children ? nodeData.children.length * DEFAULT_FIELD_NODE_HEIGHT : 0)),
          data: nodeData,
          headerHeight: DEFAULT_HEAD_NODE_HEIGHT,
          isStartNode,
          attachedEntityHeight: nodeData.attachedEntity ? DEFAULT_ATTACHED_ENTITY_HEIGHT : 0,
          index: indexes.nodesIndex,
        });
        this._nodes.set(urn, node);
        indexes.nodesIndex.set(urn, node);
        return node;
      }
      return null;
    }

    public layout(canvas: Canvas) {
      this.recalculateY(canvas);
      this.recalculateNodePositions();
    }

    public addNodes(nodeDatas: LineageEntityDto[]) {
      const addedNodes: Node[] = [];
      nodeDatas.forEach((nodeData) => {
        const addedNode = this.addNode(nodeData);
        if (addedNode) {
          addedNodes.push(addedNode);
        }
      });
      return addedNodes;
    }

    public deleteNodes(nodes: LineageEntityDto[]) {
      const toBeDeletedUrns = nodes.map((node) => node.urn);
      this._nodes.forEach(
          (_, urn) => {
            if (toBeDeletedUrns.includes(urn)) {
              this._nodes.delete(urn);
            }
          },
        );
    }

    public containsNode(urn: string): boolean {
      return this._nodes.has(urn);
    }

    private recalculateNodePositions() {
      let offsetY = this.location.y;
      Array.from(this._nodes.values()).sort((a: Node, b: Node) => a.balanceLocation.value() - b.balanceLocation.value()).forEach((node, index) => {
        // eslint-disable-next-line no-param-reassign
        node.positionInTheLayer = index;
        offsetY = node.recalculateYForIndex(index, offsetY, VERTICAL_SPACE_PER_NODE);
      });
    }

    private recalculateY(canvas: Canvas) {
      this.recalculateHeight();
      this.location.y = (canvas.height / 2) - (this.dimension.height / 2);
    }

    private recalculateHeight() {
      this.dimension.height = this.calculateTotalHeightOfNodes() + ((this._nodes.size - 1) * VERTICAL_SPACE_PER_NODE);
    }

    public calculateTotalHeightOfNodes() {
      return Array.from(this._nodes.values()).map((node) => node.height).reduce((pv, cv) => pv + cv, 0);
    }
}
