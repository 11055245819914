<template lang="pug">
v-row

  v-col( cols="6")
    InputName( v-model.trim='datasource.name' label='Shared Prefix Name' )

  v-col( cols="6" )
    InputProjectId( v-model.trim='datasource.params.projectId' )

  v-col( cols="6" class="border")
    InputBillingProjectId(  v-model.trim='datasource.params.billingProjectId' )

  v-col( cols="6" v-if="modifyExisting" class="border")
    InputDatasetId(  v-model.trim='datasource.params.datasetId' )

  v-col( cols="6" v-else class="border")
    SchemaSelector(
      type="dataset IDs"
      tooltip-list-schema="The ProjectId and Secret must be configured"
      :is-scan-active="isScanActive"
      v-model.trim='datasource.params.datasetId'
      :datasource="datasource")

  v-col( cols="6" )
    CredentialsSelector( v-model.trim='datasource.secretId' )

  </template>

<script lang="ts">
import {
  Vue, Component, VModel, Watch,
} from 'vue-property-decorator';
import { BigQueryParams, DatasourceDto } from '@/api';

type BigQuerySourceDto = DatasourceDto & { params: BigQueryParams; };

@Component
export default class BigQueryConnection extends Vue {
  @VModel({ type: Object }) datasource!: BigQuerySourceDto;

  get modifyExisting() {
    return this.datasource.id;
  }

  get isScanActive() {
    let projectId = true;
    if ('projectId' in this.datasource.params) {
      projectId = !!this.datasource.params.projectId;
    }
    return !(projectId && this.datasource.secretId);
  }
}
</script>
