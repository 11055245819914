/**
 * Creates initials from a specified name
 * @param {string | undefined} value - The name to transform into initials
 * @return string - Returns the initials of the specified name or an empty string if
 *                  the specified name was empty or undefined.
 */
export default function stringToInitials(value: string | undefined) {
  // if string is empty return empty string
  if (!value) return '';

  // remove all non-alphanumeric characters but spaces
  const str = value.replace(/[^a-zA-Z0-9 ]/g, '').toUpperCase();
  const words = str.split(' ');

  // if string is only one word return the first letter
  if (words.length === 1) return words[0].slice(0, 2);

  // return the first letter of each word but limit to two letters
  return words.map((word) => word.charAt(0)).join('').slice(0, 2);
}

declare module 'vue/types/vue' {
  interface Vue {
    initials: (value: string | undefined) => string;
  }
}
