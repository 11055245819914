<template lang="pug">

.rule-overview-info-actions

  .d-flex.flex-column.flex-nowrap( v-if="hasRule" )

    b {{ $t('sources.schedule') }}
    span( class="text-capitalize" :class="classes" ) {{ schedule }}

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    width="145"
    type="list-item-two-line" )

</template>

<script lang="ts">
import { Vue, Component, VModel } from 'vue-property-decorator';
import { RuleInfoDto } from '@/api';

@Component
export default class RuleOverviewInfoSchedule extends Vue {
  @VModel({ type: Object }) rule!: RuleInfoDto;

  get hasRule() {
    return this.rule !== null;
  }

  get schedule() {
    if (!this.rule.canBeScheduled) {
      return this.$t('common.words.unavailable');
    }

    return this.rule?.schedule?.replace('@', '') || this.$t('common.words.not_scheduled');
  }

  get classes() {
    return this.rule.schedule ? '' : 'grey--text';
  }
}
</script>
