<template lang="pug">
.incident-overview-timeline-event

  v-timeline-item( small v-if="isCreated" icon-color="white" color="transparent" icon="icon-flag" )
    v-sheet.pa-1
      .d-flex.align-center.mb-2
        span.mr-1 {{ $t('timeline.triggered') }}
        DateTooltip( :value="date" )
      IncidentStatus.mr-2( small :status="openStatus" )

  v-timeline-item( small v-if="isNewFailure" icon-color="white" color="transparent" icon="icon-flag" )
    v-sheet.pa-1
      .d-flex.align-center.mb-2
        span.mr-1 {{ $t('timeline.new_failure') }}
        DateTooltip( :value="date" )
      IncidentStatus.mr-2( small :status="openStatus" )

  v-timeline-item( small v-if="isNotificationSent" icon-color="white" color="transparent" icon="icon-mail" )
    v-sheet.pa-1
      .d-flex.align-center.mb-2
        span.mr-1 {{ $t('timeline.notification_sent') }}
        DateTooltip( :value="date" )
      IncidentStatus.mr-2( small :status="openStatus" )

  v-timeline-item( small v-if="isAssigned" icon-color="white" color="transparent" icon="icon-user" )
    v-sheet.pa-1
      .d-flex.align-center.mb-2
        b.mr-1 {{ value.createdBy }}
        DateTooltip( :value="date" )

      .d-flex.align-center.mb-2( v-if="hasOwners" )
        span.mr-2 {{ $t('timeline.assigned_to') }}
        AvatarList( :value="newOwners" size="25" )

      .d-flex.align-center.mb-2( v-else )
        span {{ $t('timeline.open') }}

  v-timeline-item( small v-if="isUpdated" icon-color="white" color="transparent" icon="icon-arrow-right" )
    v-sheet.pa-1
      .d-flex.align-center.mb-2
        b.mr-1 {{ value.createdBy }}
        DateTooltip( :value="date" )

      span.mr-2 {{ $t('timeline.changed_issue_from') }}
      IncidentStatus.mr-2( small :status="oldStatus" )
      span.mr-2 {{ $t('timeline.to') }}
      IncidentStatus.mr-2( small :status="newStatus" :qualification="newQualification" )

  v-timeline-item( small v-if="isComment" icon-color="white" color="transparent" icon="icon-chat"  )
    v-sheet.pa-1
      .d-flex.align-center.mb-2
        b.mr-1 {{ value.createdBy }}
        DateTooltip( :value="date" )
      v-sheet.pa-3( rounded )
        span {{ value.payload.comment }}

</template>

<script lang="ts">
// eslint-disable-next-line
// @ts-nocheck
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  EventDto, IncidentDetailDto, IncidentStatusUpdatePayload, UserService, UserDto,
} from '@/api';
import colors from '@/plugins/colors';

@Component
export default class TimelineEvent extends Vue {
  @Prop({ type: Object }) value!: EventDto;

  newOwners: UserDto[] = [];

  get iconColor() {
    return colors.grey.lighten2;
  }

  get date() {
    return +this.value.createdDate! - 500;
  }

  get hasOwners() {
    return this.newOwners.length > 0;
  }

  get isComment() {
    return this.value.type === EventDto.type.COMMENT_CREATED;
  }

  get isAssigned() {
    return this.value.type === EventDto.type.OWNERS_ASSIGNED;
  }

  get isCreated() {
    return this.value.type === EventDto.type.INCIDENT_CREATED;
  }

  get isUpdated() {
    return this.value.type === EventDto.type.STATUS_UPDATED;
  }

  get isNewFailure() {
    return this.value.type === EventDto.type.NEW_FAILURE;
  }

  get isNotificationSent() {
    return this.value.type === EventDto.type.NOTIFICATION_SENT;
  }

  get color() {
    switch (this.value.type) {
      case EventDto.type.INCIDENT_CREATED:
        return 'red';
      case EventDto.type.COMMENT_CREATED:
        return 'primary';
      case EventDto.type.STATUS_UPDATED:
        return 'warning';
      case EventDto.type.OWNERS_ASSIGNED:
        return 'green';
      default:
        return 'grey';
    }
  }

  get closedStatus() {
    return IncidentDetailDto.status.CLOSED;
  }

  get openStatus() {
    return IncidentDetailDto.status.OPEN;
  }

  get inProgressStatus() {
    return IncidentDetailDto.status.IN_PROGRESS;
  }

  get oldStatus() {
    const payload = this.value.payload as IncidentStatusUpdatePayload;
    return payload.oldStatus;
  }

  get newStatus() {
    const payload = this.value.payload as IncidentStatusUpdatePayload;
    return payload.newStatus;
  }

  get newQualification() {
    const payload = this.value.payload as IncidentStatusUpdatePayload;
    return payload.newQualification;
  }

  get style() {
    return {
      backgroundColor: colors.blue.base,
    };
  }

  async getNewOwners() {
    if (this.value.payload && 'newOwnerIds' in this.value.payload) {
      const users = this.value.payload.newOwnerIds;
      if (users && users.length > 0) {
        this.newOwners = await Promise.all(users.map(async (userId) => {
          const user = await UserService.getUserById({ id: userId });

          return {
            id: user.id,
            name: user.name,
            login: user.email,
          };
        }));
      }
    }
  }

  mounted() {
    this.getNewOwners();
  }
}
</script>
