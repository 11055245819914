/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UsagePerDatasourceUserDto } from './UsagePerDatasourceUserDto';

export type AssetUsageDto = {
    assetId: string;
    calculationDate: string;
    countRead: number;
    endDay: string;
    percentile: number;
    qualification: AssetUsageDto.qualification;
    readType: AssetUsageDto.readType;
    startDay: string;
    usagePerDatasourceUsers: Array<UsagePerDatasourceUserDto>;
};

export namespace AssetUsageDto {

    export enum qualification {
        UNSUPPORTED = 'UNSUPPORTED',
        LOW = 'LOW',
        MEDIUM = 'MEDIUM',
        HIGH = 'HIGH',
    }

    export enum readType {
        QUERIES = 'QUERIES',
        VIEWS = 'VIEWS',
    }


}
