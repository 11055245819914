<template lang="pug">
v-row
  v-col( cols="6")
    InputName( v-model.trim='datasource.name' label='Shared Prefix Name' )

  v-col( cols="6")
    InputHost( v-model.trim='datasource.params.host' )

  v-col( cols="6")
    InputPort( v-model.trim='datasource.params.port' )

  v-col( cols='12' sm='6')
    InputHttpPath( v-model.trim='datasource.params.httpPath' )

  v-col( cols="6")
    InputCatalog( v-model.trim='datasource.params.catalog' )

  v-col( cols="6" v-if="modifyExisting"  class="border")
    InputSchema( v-model.trim='datasource.params.schema' )

  v-col( cols="6" v-else class="border")
    SchemaSelector(
      type="schemas"
      :tooltip-list-schema="tooltipListSchema"
      :is-scan-active="isScanActive"
      v-model.trim='datasource.params.schema'
      :datasource="datasource")

  v-col( cols="6")
    CredentialsSelector( v-model.trim='datasource.secretId' )

</template>

<script lang="ts">
import {
  Vue, Component, VModel, Watch,
} from 'vue-property-decorator';
import { DatabricksParams, DatasourceDto } from '@/api';

type DatabricksSourceDto = DatasourceDto & { params: DatabricksParams; };

@Component
export default class DatabricksConnection extends Vue {
  @VModel({ type: Object }) datasource!: DatabricksSourceDto;

  get modifyExisting() {
    return this.datasource.id;
  }

  get tooltipListSchema() {
    let tooltipValue = '';
    if ('host' in this.datasource.params) {
      tooltipValue += 'The Host';
    }
    if ('port' in this.datasource.params) {
      tooltipValue += ', Port';
    }
    if ('catalog' in this.datasource.params) {
      tooltipValue += ', Catalog name';
    }
    if ('httpPath' in this.datasource.params) {
      tooltipValue += ', HTTP path';
    }
    tooltipValue += ' and Secret';
    tooltipValue += ' must be configured';
    return tooltipValue;
  }

  get isScanActive() {
    let host = true;
    if ('host' in this.datasource.params) {
      host = !!this.datasource.params.host;
    }
    let port = true;
    if ('port' in this.datasource.params) {
      port = !!this.datasource.params.port;
    }
    let catalog = true;
    if ('catalog' in this.datasource.params) {
      catalog = !!this.datasource.params.catalog;
    }
    let httpPath = true;
    if ('httpPath' in this.datasource.params) {
      httpPath = !!this.datasource.params.httpPath;
    }
    return !(host && catalog && port && httpPath && this.datasource.secretId);
  }
}
</script>
