/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RuleGeneratedTextRequestDto } from '../models/RuleGeneratedTextRequestDto';
import type { RuleGeneratedTextResponseDto } from '../models/RuleGeneratedTextResponseDto';
import type { SqlQueryCorrectionRequestDto } from '../models/SqlQueryCorrectionRequestDto';
import type { SqlQueryCorrectionResponseDto } from '../models/SqlQueryCorrectionResponseDto';
import type { TextToSqlRequestDto } from '../models/TextToSqlRequestDto';
import type { TextToSqlResponseDto } from '../models/TextToSqlResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AiPredictionsService {

    /**
     * Creates a name and a description for a given monitor
     * @returns RuleGeneratedTextResponseDto Text for rule successfully generated
     * @throws ApiError
     */
    public static generateTextForRule({
        requestBody,
    }: {
        requestBody: RuleGeneratedTextRequestDto,
    }): CancelablePromise<RuleGeneratedTextResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/ai-predictions/_generate-text-for-rule',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Suggests a correction for a SQL query which is computed by ML model
     * @returns SqlQueryCorrectionResponseDto Sql query correction successfully generated
     * @throws ApiError
     */
    public static sqlQueryCorrection({
        requestBody,
    }: {
        requestBody: SqlQueryCorrectionRequestDto,
    }): CancelablePromise<SqlQueryCorrectionResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/ai-predictions/_sql-query-correction',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Creates a SQL query based on input text in natural language and context
     * @returns TextToSqlResponseDto Sql query successfully generated
     * @throws ApiError
     */
    public static textToSql({
        requestBody,
    }: {
        requestBody: TextToSqlRequestDto,
    }): CancelablePromise<TextToSqlResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/ai-predictions/_text-to-sql',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
