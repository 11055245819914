import { render, staticRenderFns } from "./siffletify-data-footer.vue?vue&type=template&id=f1bdb608&lang=pug"
import script from "./siffletify-data-footer.vue?vue&type=script&lang=ts"
export * from "./siffletify-data-footer.vue?vue&type=script&lang=ts"
import style0 from "./siffletify-data-footer.vue?vue&type=style&index=0&id=f1bdb608&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports