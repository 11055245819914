<template lang="pug">
v-card( outlined class="my-4 pa-4" )
  v-row
    v-col( cols="12" )
      .text-h6.font-weight-medium {{ $t('assets.data_quality') }}
  v-row
    v-col( cols="4" )
      span {{ $t('assets.status') }}
    v-col( cols="8" )
      QualityStatus( :value="qualityStatusValue" )
  v-row
    v-col( cols="4" )
      span {{ $t('assets.incidents') }}
    v-col( cols="8" )
      .flex-column(v-for="(value, key, index ) in incidents" :key="index")
        span {{ $tc(`assets.${key}`, value) }}
      router-link.link( v-if="hasIncidents" :to="to('0')" ) {{ $t('assets.see_details') }}
      .ml-0(v-else) -
  v-row
    v-col( cols="4" )
      span {{ $t('assets.monitors') }}
    v-col( cols="8" )
      .flex-column(v-for="(value, key, index ) in rules" :key="index")
        span {{ value }} {{ key }}
      router-link.link( v-if="hasMonitors" :to="to('1')" ) {{ $t('assets.see_details') }}
      .ml-0(v-else) -

</template>

<script lang="ts">
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import { AssetDataQualityDto, AssetDto } from '@/api';
import { Location } from 'vue-router';

@Component
export default class AssetOverviewDataQuality extends Vue {
  @Prop(String) readonly urn!: AssetDto['urn'];

  @Prop({ type: Object }) dataQuality!: AssetDataQualityDto;

  get qualityStatusValue() {
    return this.dataQuality?.qualityStatus;
  }

  get incidents() {
    return this.dataQuality?.incidents;
  }

  get hasIncidents() {
    return this.incidents && Object.values(this.incidents).length > 0;
  }

  get hasMonitors() {
    return this.rules && Object.values(this.rules).length > 0;
  }

  get rules() {
    return this.dataQuality?.rules;
  }

  get id() {
    return this.urn.split(':')[1];
  }

  to(tab: string): Location {
    return {
      name: 'data-catalog.asset.quality',
      params: {
        urn: this.urn,
        tab,
      },
      query: {
        dataset: this.id,
      },
    };
  }
}
</script>
