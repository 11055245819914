<template lang="pug">
div.mt-9.d-flex.flex-column.align-center.justify-center.full-width
  span.icon-container
    v-icon.icon.pa-3(large color="iconNeutral") {{ icon }}
  span.font-weight-medium.mt-2 {{ title }}
  span.mt-1.grey--text {{ description }}
  .d-flex.my-2
    v-icon( color="iconInfo" size="14" ).mr-2 icon-new-window
    a( v-if="link" :href="link" target="_blank"  ) {{ linkText }}
  slot
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class EmptyDataPlaceholder extends Vue {
  @Prop({ type: String, default: 'icon-sparkles' }) readonly icon!: string;

  @Prop({ type: String, default: 'No AI Assistant suggestions for now' }) readonly title!: string;

  @Prop({ type: String, default: 'All of your monitors are running efficiently' }) readonly description!: string;

  @Prop({ type: String }) readonly link!: string;

  @Prop({ type: String, default: 'Learn more' }) readonly linkText!: string;
}
</script>

<style lang="scss" scoped>
.icon-container {
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--v-bgTertiary-base);
}
</style>
