import Vue from 'vue';
import '@/api/axios';
import router from '@/router';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  LineChart, EffectScatterChart, ScatterChart, PieChart,
} from 'echarts/charts';
import Notifications from 'vue-notification';
import VJsf from '@koumoul/vjsf/lib/VJsf';
import '@koumoul/vjsf/lib/deps/third-party';
import '@koumoul/vjsf/lib/VJsf.css';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import daysjsDuration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone';
import './utils/echarts-themes';
import {
  GridComponent,
  TooltipComponent,
  ToolboxComponent,
  LegendComponent,
  TitleComponent,
  MarkAreaComponent,
  VisualMapComponent,
  DataZoomComponent,
  MarkLineComponent,
} from 'echarts/components';
import GlobalEvents from 'vue-global-events';
import * as Sentry from '@sentry/vue';
import vuetify from '@/plugins/vuetify';
import store from '@/store';
import getEnv from '@/utils/env';
import highlight from './utils/filters/highlight';
import formatdate from './utils/filters/formatDate';
import duration from './utils/filters/duration';
import fromnow from './utils/filters/fromNow';
import titleCase from './utils/filters/titleCase';
import upperCase from './utils/filters/upperCase';
import src from './utils/filters/src';
import RootLayout from './layouts/RootLayout.vue';
import i18n from './i18n';
import featureFlag from './plugins/feature-flag';

// Import global components
import '@/components/_components';
import '@/components/_inputs';

// eslint-disable-next-line import/no-extraneous-dependencies
import 'highlight.js/styles/stackoverflow-light.css';
import '@/sass/sifflet.scss';
import '@/sass/btn.scss';
import '@/sass/pagination.scss';
import '@/sass/richTextEditor.scss';

dayjs.extend(relativeTime);
dayjs.extend(daysjsDuration);
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

use([
  TitleComponent,
  TooltipComponent,
  VisualMapComponent,
  GridComponent,
  MarkAreaComponent,
  ToolboxComponent,
  LineChart,
  DataZoomComponent,
  LegendComponent,
  CanvasRenderer,
  ScatterChart,
  EffectScatterChart,
  MarkLineComponent,
  PieChart,
]);

Vue.use(Notifications);

Vue.use(featureFlag, {
  url: getEnv('VUE_APP_UNLEASH_URL'),
  clientKey: getEnv('VUE_APP_UNLEASH_CLIENT'),
  appName: getEnv('VUE_APP_UNLEASH_ENVIRONMENT'),
  environment: getEnv('VUE_APP_UNLEASH_ENVIRONMENT'),
  disableRefresh: true,
  bootstrap: [
    {
      enabled: false,
      name: 'metadata-asset-preview-disabled',
    },
    {
      enabled: false,
      name: 'feature-microstrategy',
    },
    {
      enabled: false,
      name: 'feature-jira-integration',
    },
  ],
});

Vue.prototype.$features.setContextField('userId', getEnv('VUE_APP_TENANT_NAME'));

Vue.config.productionTip = false;

if (getEnv('VUE_APP_SENTRY_ENABLED') === 'true') {
  Sentry.init({
    Vue,
    dsn: 'https://fd89c4f71e914bf09bb261f96a5d13a6@o4504043276926976.ingest.sentry.io/4504043487232000',
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
      Sentry.replayIntegration({
        // These classes are used by Sentry session replay to mask sensitive data (or unmask it if needed)
        // Note that, by default, all data is masked
        mask: ['.sentry-mask'],
        unmask: ['.sentry-unmask'],
      }),
      Sentry.feedbackIntegration({ autoInject: false }),
    ],
    tracesSampleRate: 1,
    replaysOnErrorSampleRate: 0.001, // Mainly for feedback integration
    ignoreErrors: [
      'Internal Server Error',
      'Internal server error',
      'Not Found',
      'Bad Request',
      'timeout of 60000ms exceeded',
      'CancelError: Request aborted',
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      'Unauthorized',
      'ChunkLoadError',
    ],
    denyUrls: [
      /localhost:3333/i,
      /127\.0\.0\.1:3333/i,
    ],
    environment: getEnv('VUE_APP_SENTRY_ENVIRONMENT') || 'unknown',
    initialScope: {
      tags: {
        tenant: getEnv('VUE_APP_TENANT_NAME'),
      },
    },
  });
}

// Filters
Vue.filter('highlight', highlight);
Vue.filter('formatdate', formatdate);
Vue.filter('fromnow', fromnow);
Vue.filter('src', src);
Vue.filter('duration', duration);
Vue.filter('titlecase', titleCase);
Vue.filter('uppercase', upperCase);

// Components
Vue.component('VJsf', VJsf);
Vue.component('GlobalEvents', GlobalEvents);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(RootLayout),
}).$mount('#app');
