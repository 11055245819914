<template lang="pug">
v-row.border-bottom( @click="openAsset" class="pointer" )
  v-col
    component( :is="is" :entity="entity" )

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import AssetPreviewCard from '@/components/cards/asset-preview-card/Asset-Preview-Card.vue';
import MonitorPreviewCard from '@/components/cards/monitor-preview-card/Monitor-Preview-Card.vue';
import { AssetSummaryDto, MonitorSummaryDto } from '@/api';

@Component
export default class EntityPreviewCard extends Vue {
  @Prop({ type: Object, required: true }) entity!: AssetSummaryDto | MonitorSummaryDto;

  get is() {
    switch (this.entity.displayedType) {
      case AssetSummaryDto.displayedType.DASHBOARD:
      case AssetSummaryDto.displayedType.MODEL:
      case AssetSummaryDto.displayedType.VIEW:
      case AssetSummaryDto.displayedType.TABLE:
      case AssetSummaryDto.displayedType.EXTERNAL_TABLE:
      case AssetSummaryDto.displayedType.DAG:
      case AssetSummaryDto.displayedType.ORCHESTRATOR:
      case AssetSummaryDto.displayedType.ML_MODEL:
      case AssetSummaryDto.displayedType.NONE:
        return AssetPreviewCard;
      case AssetSummaryDto.displayedType.MONITOR:
        return MonitorPreviewCard;
      default:
        return null;
    }
  }

  get routeName() {
    switch (this.entity.displayedType) {
      case AssetSummaryDto.displayedType.DASHBOARD:
      case AssetSummaryDto.displayedType.MODEL:
      case AssetSummaryDto.displayedType.VIEW:
      case AssetSummaryDto.displayedType.TABLE:
      case AssetSummaryDto.displayedType.EXTERNAL_TABLE:
      case AssetSummaryDto.displayedType.DAG:
      case AssetSummaryDto.displayedType.ORCHESTRATOR:
      case AssetSummaryDto.displayedType.ML_MODEL:
      case AssetSummaryDto.displayedType.NONE:
        return 'data-catalog.asset.overview';
      case AssetSummaryDto.displayedType.MONITOR:
        return 'monitors.rule.overview';
      default:
        return '';
    }
  }

  get params() {
    const params: any = {};
    switch (this.entity.displayedType) {
      case AssetSummaryDto.displayedType.DASHBOARD:
      case AssetSummaryDto.displayedType.MODEL:
      case AssetSummaryDto.displayedType.VIEW:
      case AssetSummaryDto.displayedType.TABLE:
      case AssetSummaryDto.displayedType.EXTERNAL_TABLE:
      case AssetSummaryDto.displayedType.DAG:
      case AssetSummaryDto.displayedType.ORCHESTRATOR:
      case AssetSummaryDto.displayedType.ML_MODEL:
      case AssetSummaryDto.displayedType.NONE:
        params.urn = this.entity.urn!;
        break;
      case AssetSummaryDto.displayedType.MONITOR:
        params.id = this.entity.urn?.replace('sifflet-rule:', '')!;
        break;
      default:
        break;
    }
    return params;
  }

  get assetLink() {
    return { name: this.routeName, params: this.params };
  }

  openAsset() {
    this.$router.push(this.assetLink);
  }
}
</script>
