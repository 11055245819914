import { render, staticRenderFns } from "./Ingestion-Type-Icon.vue?vue&type=template&id=5f6f7fea&lang=pug"
import script from "./Ingestion-Type-Icon.vue?vue&type=script&setup=true&lang=ts"
export * from "./Ingestion-Type-Icon.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports