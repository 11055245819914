import { MAIN_ROUTES, SUB_ROUTES } from '@/router/components';

export default {
  path: '/design-system',
  meta: {
    requiresAuth: false,
    breadCrumb: 'Design System',
  },
  component: MAIN_ROUTES.DESIGN_SYSTEM,
  children: [
    {
      path: '',
      name: 'DesignSystem',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Main',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM,
    },
    {
      path: 'buttons',
      name: 'design_system.buttons',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Buttons',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_BUTTONS,
    },
    {
      path: 's-button',
      name: 'design_system.s-button',
      meta: {
        requiresAuth: true,
        breadCrumb: 'S-Button',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_S_BUTTON,
    },
    {
      path: 'pagination',
      name: 'design_system.pagination',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Pagination',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_PAGINATION,
    },
    {
      path: 'data-footer',
      name: 'design_system.data-footer',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Data footer',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_DATA_FOOTER,
    },
    {
      path: 'data-table',
      name: 'design_system.data-table',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Data table',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_DATA_TABLE,
    },
    {
      path: 'data-iterator',
      name: 'design_system.data-iterator',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Data iterator',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_DATA_ITERATOR,
    },
    {
      path: 'selectors',
      name: 'design_system.selectors',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Selectors',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_SELECTORS,
    },
    {
      path: 'test',
      name: 'design_system.test',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Test page',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_TEST,
    },
    {
      path: 'colors',
      name: 'design_system.colors',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Colors',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_COLORS,
    },
    {
      path: 'nested-tables',
      name: 'design_system.nested_tables',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Nested Tables',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_NESTED_TABLES,
    }, {
      path: 'nested-schema',
      name: 'design_system.nested_schema',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Nested Schema',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_NESTED_SCHEMA,
    }, {
      path: 'input-code',
      name: 'design_system.input_code',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Input Code',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_INPUT_CODE,
    }, {
      path: 'custom-code',
      name: 'design_system.custom_code',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Custom Code',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_CUSTOM_CODE,
    }, {
      path: 'smart-regex',
      name: 'design_system.smart_regex',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Smart Regex',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_SMART_REGEX,
    }, {
      path: 'custom-icons',
      name: 'design_system.custom_icons',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Custom Icons',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_CUSTOM_ICONS,
    },
    {
      path: 'v-jsf-custom',
      name: 'design_system.v_jsf_custom',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Custom Rules',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_CUSTOM_V_JSF_CUSTOM_CODE,
    },
    {
      path: 'label',
      name: 'design_system.label',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Label',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_LABEL,
    },
    {
      path: 'v-jsf-smart-regex',
      name: 'design_system.v_jsf_smart_regex',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Custom Rules',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_CUSTOM_V_JSF_SMART_REGEX,
    },
    {
      path: 'feature_flags',
      name: 'design_system.feature_flags',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Feature Flags',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_SMART_FEATURE_FLAGS,
    },
    {
      path: 'vue_macros',
      name: 'design_system.vue_macros',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Vue Macros',
      },
      component: SUB_ROUTES.DESIGN_SYSTEM_VUE_MACROS,
    },
  ],
};
