<template lang="pug">

ModalDialog(ref="modalDialog" :title="$t('charts.qualify_the_datapoint')")

  template( v-slot:body )

    .d-block.text-body-2.grey--text.text--darken-4
      | {{ $t('charts.by_providing_feedback') }}
      a.link.ml-1( :href="externaLinks.feedback_loop_external_link" target="_blank") {{ $t('charts.learn_more') }}

      v-card.px-2.mt-2(v-if="tooltipInfos.length" outlined max-width="50%")
        div.list-item.text-caption(v-for="info in tooltipInfos" :key="`${info.label}${info.value}`")
          span.mr-1 {{ info.label }}
          span(:class="{'red--text': info.anomaly, 'grey--text':  !info.anomaly}") {{ info.value }}
            span(v-if="anomalyLabel(info)").ml-1 {{ anomalyLabel(info) }}

    SLabel.d-block.mt-6.text-body-2.grey--text.text--darken-4 {{$t('charts.qualification_dialog.select_label')}}
    v-tooltip(top :disabled="!hasExcludedDates")
      template(v-slot:activator="{ on }")
        div(v-on="on")
          v-select.mt-1(
            slot="input"
            :placeholder="$t('charts.qualification_dialog.select_placeholder')"
            :value="hasExcludedDates ? null : payload.qualification"
            @change="value => !hasExcludedDates && (payload.qualification = value)"
            :items="qualificationOptions"
            :menu-props="{contentClass: 'mt-1', closeOnClick: true, offsetY: true}"
            :disabled="hasExcludedDates"
            outlined
            hide-details
            dense
            clearable
          )
            template(#selection="{ item }")
              .select-item
                v-icon(:color="getColor(item.value)" size="20" ) {{ getIcon(item.value) }}
                span.ml-1 {{ $t(item.title) }}
            template(#item="{ item }")
              .d-flex.flex-column.py-1
                .select-item.align-center
                  v-icon(:color="getColor(item.value)" size="20" ) {{ getIcon(item.value) }}
                  span.ml-1 {{ $t(item.title) }}
                .d-block.ml-6
                  span.text-caption.grey--text {{ $t(item.subtitle) }}
      | {{ $t('charts.qualification_dialog.disabled_by_excluded_date') }}

    SLabel.d-block.mt-6.text-body-2.grey--text.text--darken-4 {{$t('charts.qualification_dialog.message_label')}}
    v-textarea.mt-1(
      slot="input"
      v-model="payload.comment"
      :placeholder="$t('charts.qualification_dialog.message_placeholder')"
      rows="3"
      :rules="[limitRule]"
      counter
      full-width
      outlined
      dense
    )

  template( v-slot:actions )
    v-btn(:disabled="isDisabled" color="primary" @click="sendQualification"  depressed) {{ $t('charts.save') }}

</template>

<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

// eslint-disable-next-line
// @ts-nocheck
import { inject } from 'vue';
import { getModule } from 'vuex-module-decorators';
import {
  type GroupIdentifierDto,
  DatapointQualificationDto,
  DatapointQualificationService,
} from '@/api';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import authModule from '@/store/modules/auth';
import store from '@/store';
import {
  QUALIFICATION_ICONS, QUALIFICATION_COLORS, type TooltipInfo,
} from '@/@types/chart';
import externaLinks from '@/external-links.json';
import SLabel from '@/components/SLabel.vue';
import i18n from '@/i18n';

const emit = defineEmits(["update"]);

const providedGroup = inject<GroupIdentifierDto>('providedGroup');

const auth = getModule(authModule, store);

const QUALIFICATION_OPTIONS = [
  { title: 'charts.qualifications.false_positive.title', subtitle: 'charts.qualifications.false_positive.subtitle', value: DatapointQualificationDto.qualification.FALSE_POSITIVE },
  { title: 'charts.qualifications.fixed.title', subtitle: 'charts.qualifications.fixed.subtitle', value: DatapointQualificationDto.qualification.FIXED },
  { title: 'charts.qualifications.expected.title', subtitle: 'charts.qualifications.expected.subtitle', value: DatapointQualificationDto.qualification.EXPECTED },
  { title: 'charts.qualifications.known_error.title', subtitle: 'charts.qualifications.known_error.subtitle', value: DatapointQualificationDto.qualification.KNOWN_ERROR },
  { title: 'charts.qualifications.false_negative.title', subtitle: 'charts.qualifications.false_negative.subtitle', value: DatapointQualificationDto.qualification.FALSE_NEGATIVE },
];

let tooltipInfos = _ref<TooltipInfo[]>([]);

let hasRun = _ref(false);

let hasExcludedDates = _ref(false);

let payload = _ref<DatapointQualificationDto>({
  id: '',
  checkType: '',
  value: 0,
  date: 0,
  qualification: DatapointQualificationDto.qualification.NO_QUALIFICATION,
  comment: '',
  ruleRunDto: {
    id: '',
    debuggable: false,
    hasGroupBy: false,
    hasGraph: false,
    canShowFailingRows: false,
  },
});

const getIcon = (qualification: DatapointQualificationDto.qualification) => QUALIFICATION_ICONS[qualification];

const getColor = (qualification: DatapointQualificationDto.qualification) => QUALIFICATION_COLORS[qualification];

const limitRule = _computed(() => (value: string) => {
    // Should not display error if no value is set
    if (!value) return true;

    return value.length <= 1000 || i18n.t('charts.qualification_dialog.max_comment_size');
  });

const isDisabled = _computed(() => !hasRun.value);

const qualificationOptions = _computed(() => QUALIFICATION_OPTIONS);

const canQualify = _computed(() => auth?.userActions['monitoring.monitor.write']);

const modalDialog = _ref<InstanceType<typeof ModalDialog> | null>(null);

const anomalyLabel = (item: TooltipInfo) => {
  switch (item.anomaly) {
    case false:
      return i18n.t('charts.tooltip.expected');
    case true:
      return i18n.t('charts.tooltip.anomaly');
    default: return '';
  }
};

const sendQualification = async () => {
  payload.value.group = providedGroup;

  // Set qualification to NO_QUALIFICATION if not set (will be the case if the user clears the selection)
  payload.value.qualification ??= DatapointQualificationDto.qualification.NO_QUALIFICATION;

  const result = await DatapointQualificationService.createQualification({ requestBody: payload.value });
  modalDialog.value?.closeDialog();
  emit('update', result);
};

const openDialog = (newTooltipInfos: TooltipInfo[], newPayload: DatapointQualificationDto, newHasExcludedDates: boolean) => {
  if (canQualify.value) {
    tooltipInfos.value = newTooltipInfos;
    payload.value = { ...payload.value, ...newPayload };
    hasExcludedDates.value = newHasExcludedDates;
    hasRun.value = true;
    modalDialog.value?.openDialog();
  }
};

defineExpose({
  openDialog,
});
</script>

<style lang="scss" scoped>
.select-item {
  display: flex;
  line-height: normal;
  width: 100%;
}

.list-item {
  margin: 8px 0;
  display: grid;
  grid-template-columns: 40% 60%;
  gap: 0 8px;
  width: 100%;
}
</style>
