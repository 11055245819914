<template lang="pug">
.d-flex.grey--text( v-if="usageData" )
  DataUsage( :value="usageData.qualification" )
  span.mx-2( ) {{ $tc('assets.usage', usageData.percentile) }}
  component(
    v-if="hasCountRead"
    :is="usageComponent" :class="classes" @click.prevent="viewUsageChart()" :title="$t('assets.see_details')") {{ countRead }}  {{ $t(readTypeLabel) }}  {{ $t(readIntervalLabel) }}

  ModalDialog(
    ref="modalDialogRef" :title="$t('assets.data_asset_user')")
    template( v-slot:body )
      Usage( class="modal" :usage-data="usageData")

</template>

<script lang="ts">
import {
 Vue, Component, VModel, Ref,
} from 'vue-property-decorator';
import { AssetUsageDto } from '@/api';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import dayjs from 'dayjs';

@Component
export default class DataUsageExtended extends Vue {
  @VModel({ type: Object }) usageData!: AssetUsageDto;

  get hasCountRead() {
    return this.countRead !== undefined;
  }

  get readIntervalLabel() {
    const diff = dayjs.unix(parseInt(this.usageData.endDay, 10) / 1000).diff(dayjs.unix(parseInt(this.usageData.startDay, 10) / 1000));
    const interval = dayjs.duration(diff);
    if (interval.asWeeks() === 1) {
      return 'assets.usage_read_interval_last_week';
    }
    return 'assets.usage_read_interval_last_2_weeks';
  }

  get readTypeLabel() {
    switch (this.usageData.readType) {
      case AssetUsageDto.readType.QUERIES:
        return 'assets.usage_read_type_queries';
      case AssetUsageDto.readType.VIEWS:
        return 'assets.usage_read_type_times_viewed_in';
      default: return '';
    }
  }

  get hasUsagePerDatasourceUsers() {
    return this.usageData.usagePerDatasourceUsers && this.usageData.usagePerDatasourceUsers.length > 0;
  }

  get countRead() {
    return this.usageData?.countRead;
  }

  get usageComponent() {
    return this.countRead && this.countRead > 0 && this.hasUsagePerDatasourceUsers ? 'a' : 'span';
  }

  get classes() {
    return this.countRead && this.countRead > 0 ? 'text-decoration-underline' : '';
  }

  @Ref() modalDialogRef!: ModalDialog;

  viewUsageChart() {
    if (this.hasUsagePerDatasourceUsers) {
      this.modalDialogRef.openDialog();
    }
  }
}
</script>
