/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type MessageReportDto = {
    content?: string;
    lineNumbers?: Array<number>;
    type?: MessageReportDto.type;
};

export namespace MessageReportDto {

    export enum type {
        INFO = 'INFO',
        WARNING = 'WARNING',
        ERROR = 'ERROR',
    }


}
