/**
 * Transforms a string into TitleCase
 * @param {string} string - The string to format
 * @return string - Returns the formatted string or empty string if initial
 *                  param was empty
 */
export default (string: string | undefined) => {
  if (!string) return '';
  const splitString = string.match(/[-_.\s]+/)
      ? string.split(/[-_.\s]+/)
      : string.split(/(?=[A-Z][a-z])+/);

  return splitString.reduce((acc, currentWord, currentIndex) => {
    if (currentIndex === 0) {
      return `${currentWord.charAt(0).toUpperCase() + currentWord.slice(1).toLowerCase()}`;
    }

    return `${acc} ${currentWord.charAt(0).toUpperCase() + currentWord.slice(1).toLowerCase()}`;
  }, '');
};

declare module 'vue/types/vue' {
  interface Vue {
    titlecase: (value: string | undefined) => string;
  }
}
