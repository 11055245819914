<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import type { QueryType } from '@/utils/query';

interface CatalogSearchProps {
  shouldSearchInFields: boolean,
  textSearch: string,
  sort: string[]
}

type CatalogSearchEmits = {
  (event: 'onFilterChange', filter: { [key: string]: QueryType }): void
}

const props = defineProps({
  shouldSearchInFields: { type: Boolean, default: false },
  textSearch: { default: '' },
  sort: null
});

const emit = defineEmits(["onFilterChange"]);

const shouldSearchInFields = ref(props.shouldSearchInFields);
const textSearch = ref<string>(props.textSearch);
const searchInputRef = ref<HTMLInputElement | null>(null);

const handleSearchKey = () => {
  emit('onFilterChange', { textSearch: textSearch.value });
};

const handleSearchClear = () => {
  textSearch.value = '';
  handleSearchKey();
};

const handleSort = (value: { [key: string]: QueryType}) => {
  emit('onFilterChange', { ...value });
};

onMounted(() => {
  searchInputRef.value!.focus();
});

watch(() => shouldSearchInFields.value, () => {
  emit('onFilterChange', { searchInFieldsAndDescriptions: String(shouldSearchInFields.value) });
});
</script>

<template lang="pug">
v-row( no-gutters )
  v-col
    v-text-field.mr-4(
      ref="searchInputRef"
      data-cy="catalog-search"
      v-model.trim="textSearch"
      @click:clear="handleSearchClear"
      @keyup.enter="handleSearchKey"
      prepend-inner-icon="icon-search"
      :placeholder="$t('data-catalog.search_placeholder')"
      hide-details dense outlined clearable label persistent-hint )
  v-col( cols="auto" )
    v-checkbox.mt-0.mr-12( v-model="shouldSearchInFields" dense hide-details label="Search on fields" )
      template( v-slot:label )
        span {{ $t('data-catalog.search_on_fields') }}
        v-tooltip( top )
          template(v-slot:activator="{ on }")
            v-icon.ml-2( small v-on="on" ) icon-question-circle-outline
          span {{ $t('data-catalog.search_on_fields_names_and_descriptions') }}
          div.tooltip-arrow-bottom
  v-col( style="min-width: 250px; max-width: 250px;" )
    CatalogSort(@onSortingChange="handleSort" :sort="sort")
</template>
