/* eslint-disable camelcase */
import { ApiError, ConfigService, SystemAlertConfigDto } from '@/api';
import {
  Action, Module, Mutation, VuexModule, config,
} from 'vuex-module-decorators';

config.rawError = true;

@Module({ namespaced: true, name: 'config' })
export default class ConfigModule extends VuexModule {
  systemAlertSlackTokenEnabled = false;

  systemAlertSlackBotConnected = false;

  systemAlertMsTeamsWebhookEnabled = false;

  get getSystemAlertSlackTokenEnabled() { return this.systemAlertSlackTokenEnabled; }

  get getSystemAlertSlackBotConnected() { return this.systemAlertSlackBotConnected; }

  get getSystemAlertMsTeamsWebhookEnabled() { return this.systemAlertMsTeamsWebhookEnabled; }

  @Mutation
  SET_SYSTEM_ALERT_SLACK_BOT_CONNECTED(value: boolean) {
    this.systemAlertSlackBotConnected = value;
  }

  @Mutation
  SET_SYSTEM_ALERT_MS_TEAMS_WEBHOOK_ENABLED(value: boolean) {
    this.systemAlertMsTeamsWebhookEnabled = value;
  }

  @Mutation
  RESET_STATE() {
    this.systemAlertSlackBotConnected = false;
    this.systemAlertMsTeamsWebhookEnabled = false;
  }

  @Action
  get_system_alert_slack_bot_connected() {
    const prms = ConfigService.getSystemAlertConfig({ systemAlertConfigName: 'SLACK_BOT_CONNECTED' });
    prms.then((data) => {
      this.SET_SYSTEM_ALERT_SLACK_BOT_CONNECTED(data.value === 'true');
    });
    return prms;
  }

  @Action
  get_system_alert_ms_teams_webhook_enabled() {
    const prms = ConfigService.getSystemAlertConfig({ systemAlertConfigName: 'MS_TEAMS_WEBHOOK_ENABLED' });
    prms.then((data) => {
      this.SET_SYSTEM_ALERT_MS_TEAMS_WEBHOOK_ENABLED(data.value === 'true');
    });
    return prms;
  }

  @Action
  setSystemAlertSlackBotConnected(value: boolean) {
    const prms = ConfigService.createOrUpdateSystemAlertConfig({
      systemAlertConfigName: 'SLACK_BOT_CONNECTED',
      requestBody: { value: value.toString() },
    });
    prms.then(async (data) => {
      await this.get_system_alert_slack_bot_connected();
    });
    return prms;
  }

  @Action
  setSystemAlertMsTeamsWebhookEnabled(value: boolean) {
    const prms = ConfigService.createOrUpdateSystemAlertConfig({
      systemAlertConfigName: 'MS_TEAMS_WEBHOOK_ENABLED',
      requestBody: { value: value.toString() },
    });
    prms.then(async (data) => {
      await this.get_system_alert_ms_teams_webhook_enabled();
    });
    return prms;
  }
}
