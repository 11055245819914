<template lang="pug">

.datasource-overview-info-actions

  .d-flex.flex-column.flex-nowrap( v-if="hasDatasource" )

    b {{ $t('sources.next_execution') }}
    DateTooltip( :value="datasource.nextExecution" )

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    width="145"
    type="list-item-two-line" )

</template>

<script lang="ts">
import { Vue, Component, VModel } from 'vue-property-decorator';
import { DatasourceDto } from '@/api';

@Component
export default class DatasourceOverviewInfoLastRun extends Vue {
  @VModel({ type: Object }) datasource!: DatasourceDto;

  get hasDatasource() {
    return this.datasource !== null;
  }
}
</script>
