<template lang="pug">
v-textarea(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="krb5Rules"
    label='krb5 Configuration File Content'
    data-cy="krb5"
    dense outlined)

</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputName extends Vue {
    krb5Rules = [
        (v: string) => isConvertibleToNotBlankString(v) || 'krb5 configuration file content is required',
    ];
}
</script>
