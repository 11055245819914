/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConnectionTestDto } from '../models/ConnectionTestDto';
import type { CreateDatasourceBatchDto } from '../models/CreateDatasourceBatchDto';
import type { DatasourceDto } from '../models/DatasourceDto';
import type { GitConnection } from '../models/GitConnection';
import type { GitConnectionTestDto } from '../models/GitConnectionTestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DatasourceConnectionService {

    /**
     * Test a batch of connection with given datasource params
     * @returns ConnectionTestDto Connection test result
     * @throws ApiError
     */
    public static testDatasourceBatchConnection({
        requestBody,
    }: {
        requestBody: CreateDatasourceBatchDto,
    }): CancelablePromise<Array<ConnectionTestDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/datasources/_batch-test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Test connection with given datasource params
     * @returns ConnectionTestDto Connection test result
     * @throws ApiError
     */
    public static testDatasourceConnection({
        requestBody,
    }: {
        requestBody: DatasourceDto,
    }): CancelablePromise<ConnectionTestDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/datasources/_test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Test connection with given git params
     * @returns GitConnectionTestDto Git connection test result
     * @throws ApiError
     */
    public static testGitConnection({
        requestBody,
    }: {
        requestBody: GitConnection,
    }): CancelablePromise<GitConnectionTestDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/datasources/_test-git-connection',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
