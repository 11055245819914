/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetCollaborationToolItemDto } from './GetCollaborationToolItemDto';
import type { UserDto } from './UserDto';

export type IssueDetailsDto = {
    collaborationToolItems?: Array<GetCollaborationToolItemDto>;
    createdBy?: string;
    createdDate?: string;
    criticality?: IssueDetailsDto.criticality;
    id: string;
    issueNo?: number;
    lastModifiedDate?: string;
    modifiedBy?: string;
    name?: string;
    owners?: Array<UserDto>;
    qualification?: IssueDetailsDto.qualification;
    status?: IssueDetailsDto.status;
};

export namespace IssueDetailsDto {

    export enum criticality {
        CRITICAL = 'CRITICAL',
        HIGH = 'HIGH',
        MODERATE = 'MODERATE',
        LOW = 'LOW',
    }

    export enum qualification {
        FIXED = 'FIXED',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        EXPECTED = 'EXPECTED',
        KNOWN_ERROR = 'KNOWN_ERROR',
        DUPLICATE = 'DUPLICATE',
    }

    export enum status {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }


}
