<template lang="pug">
ChartTimeSeries(
    :graph="graph" :id="id" :runId="runId" :qualifications="qualifications" @update="update"
    :title="$t('charts.graph_type.METADATA_FRESHNESS.title')"
    :valuesSeriesName="$t('charts.graph_type.METADATA_FRESHNESS.value_series_title')"
    :formatValue="formatValue")
</template>

<script setup lang="ts">
// eslint-disable-next-line
// @ts-nocheck
import ChartTimeSeries from '@/components/charts/Chart-Time-Series.vue';
import type { DatapointQualificationDto, TimeSeriesGraphDto } from '@/api';
import { duration } from 'dayjs';

interface Props {
  graph: TimeSeriesGraphDto;
  id: string;
  runId: string;
  qualifications: DatapointQualificationDto[];
}
defineProps({
  graph: null,
  id: null,
  runId: null,
  qualifications: null
})

const formatValue = (value: number) => {
  if (value === 0) {
    return '0';
  }

  const d = duration(value, 'seconds');
  return d.humanize();
};
</script>
