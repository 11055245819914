/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationAvailabilityDto } from '../models/NotificationAvailabilityDto';
import type { StringPayload } from '../models/StringPayload';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NotificationService {

    /**
     * Get availability information for notifications systems
     * @returns NotificationAvailabilityDto Successfully fetch notification availability
     * @throws ApiError
     */
    public static getNotificationAvailability(): CancelablePromise<NotificationAvailabilityDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/notifications/availability',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Test MS Teams integration with greetings message to given channels
     * @returns void
     * @throws ApiError
     */
    public static testTeamsIntegration({
        requestBody,
    }: {
        requestBody: StringPayload,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/notifications/teams/_test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
