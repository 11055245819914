<template lang="pug">
v-dialog(
  v-bind="$attrs"
  v-on="$listeners"
  v-model='dialog'
  max-width='500px'
  overlay-color="black"
  overlay-opacity="0.60"
  @keydown.esc="closeDialog()"
  scrollable
  transition='dialog-top-transition')

  v-card
    v-card-title.py-5
      .d-flex.align-center( style="width: 100%;" )
        b.text-h5.text-truncate.mr-auto {{ title }}
        v-spacer
        v-btn.mr-n2( ref="closeButton" icon data-cy="modal-dialog-dismiss-button" )
          v-icon icon-dismiss

    v-card-text.py-5( data-cy="modal-dialog-body")
      .text-body-2.grey--text.text--darken-4 {{ body }}
      slot( name="body" )

    v-card-actions.py-5
      v-spacer
      v-btn.mr-2(
        ref="cancelButton"
        color="secondary"
        class="custom-secondary"
        data-cy="cancel-button"
        text outlined  ) {{ $t('common.words.cancel') }}
      v-btn(
        v-if="isDelete"
        ref="deleteButton"
        class="custom-alert"
        data-cy="delete-button"
        color='alert')
        v-icon(left) icon-trash
        | {{ deleteButtonText }}
      v-btn(
        v-if="isConfirm"
        ref="confirmButton"
        class="custom-confirm"
        data-cy="confirm-button"
        color='primary') {{ confirmButtonText }}
      v-btn(
        v-if="isDisable"
        ref="disableButton"
        class="custom-alert"
        data-cy="disable-button"
        color='alert')
        v-icon(left) icon-dismiss-circle-outline
        | {{ disableButtonText }}

  </template>

  <script lang="ts">
import {
 Vue, Component, Ref,
} from 'vue-property-decorator';
import { VBtn } from 'vuetify/lib';

  @Component
  export default class ModalDialog extends Vue {
    @Ref('confirmButton') readonly confirmButtonRef!: typeof VBtn & { $el: HTMLElement };

    @Ref('deleteButton') readonly deleteButtonRef!: typeof VBtn & { $el: HTMLElement };

    @Ref('disableButton') readonly disableButtonRef!: typeof VBtn & { $el: HTMLElement };

    @Ref('cancelButton') readonly cancelButtonRef!: typeof VBtn & { $el: HTMLElement };

    @Ref('closeButton') readonly closeButton!: typeof VBtn & { $el: HTMLElement };

    type: 'delete' | 'confirm' | 'disable' = 'delete';

    title = '';

    body = '';

    dialog = false;

    deleteButtonText = this.$t('common.words.delete');

    disableButtonText = this.$t('common.words.disable');

    confirmButtonText = this.$t('common.words.confirm');

    get isDelete() {
      return this.type === 'delete';
    }

    get isDisable() {
      return this.type === 'disable';
    }

    get isConfirm() {
      return this.type === 'confirm';
    }

    openDialog() {
      this.dialog = true;
    }

    setType(type: 'delete' | 'confirm' | 'disable') {
      this.type = type;
      return this;
    }

    setTitle(title: string) {
      this.title = title;
      return this;
    }

    setConfirmButtonText(text: string) {
      this.confirmButtonText = text;
      return this;
    }

    setBody(body: string) {
      this.body = body;
      return this;
    }

    /**
     * Waits for user action.
     * @param {boolean} letOpen - Whether to let the dialog open or not.
     * @returns {Promise} - A promise that resolves when the user takes an action.
     */
    async waitForUserAction(letOpen = false) {
      this.openDialog();
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          // resolve the promise when the user clicks on the delete button
          this.deleteButtonRef && this.deleteButtonRef.$el.addEventListener('click', () => {
            if (!letOpen) this.closeDialog();
            resolve('confirm');
            this.deleteButtonRef.$el.removeEventListener('click', () => {});
          });

          // resolve the promise when the user clicks on the disable button
          this.disableButtonRef && this.disableButtonRef.$el.addEventListener('click', () => {
            if (!letOpen) this.closeDialog();
            resolve('confirm');
            this.disableButtonRef.$el.removeEventListener('click', () => {});
          });

          // resolve the promise when the user clicks on the confirm button
          this.confirmButtonRef && this.confirmButtonRef.$el.addEventListener('click', () => {
            if (!letOpen) this.closeDialog();
            resolve('confirm');
            this.confirmButtonRef.$el.removeEventListener('click', () => {});
          });

          // reject the promise when the user clicks on the cancel button
          this.cancelButtonRef.$el.addEventListener('click', () => {
            this.closeDialog();
            reject();
            this.cancelButtonRef.$el.removeEventListener('click', () => {});
          });

          // reject the promise when the user clicks on the close button
          this.closeButton.$el.addEventListener('click', () => {
            this.closeDialog();
            reject();
            this.closeButton.$el.removeEventListener('click', () => {});
          });
        });
      });
    }

    closeDialog() {
      this.dialog = false;
      setTimeout(() => {
        // wait for animation to finish
        this.$emit('close');
      }, 300);
    }
  }
  </script>
