<template lang="pug">
#datasets-selector
  div( v-for="(dataset, i) of datasets" :key="i" )
    .d-flex
      DatasetSelector.mr-2(
        :value="dataset?.id"
        :disabled="disabled"
        :index="i"
        @input="value => datasetChangeHandler(value, i)" return-object )

      v-btn.px-2.mr-2(
        v-if="canAdd(i)"
        :disabled="disabled"
        color="primary" class="custom-primary icon-only" @click="addDataset()" )
        v-icon( small ) icon-add
      v-btn.px-2.mr-2(
        v-if="canRemove(i)"
        :disabled="disabled"
        color="alert" text outlined @click="removeDataset(i)" class="custom-alert icon-only" )
        v-icon( small ) icon-trash

</template>

<script lang="ts">
// eslint-disable-next-line
// @ts-nocheck
import { DatasetLightDto, RuleDto } from '@/api';
import {
  Vue, Component, VModel, Prop,
} from 'vue-property-decorator';

@Component
export default class DatasetsSelector extends Vue {
  @VModel({ type: Array }) datasets!: DatasetLightDto[];

  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  datasetChangeHandler(value: DatasetLightDto, index: number) {
    Vue.set(this.datasets, index, value);
    this.$emit('input', this.datasets);
  }

  addDataset() {
    this.datasets.push({
      id: '',
      urn: '',
      name: '',
      datasourceId: '',
      datasourceName: '',
      datasourceType: '',
      isView: false,
    });
    this.$emit('input', this.datasets);
  }

  removeDataset(i: number) {
    this.datasets.splice(i, 1);
    this.$emit('input', this.datasets);
  }

  canAdd(i: number) {
    return i === this.datasets.length - 1;
  }

  canRemove(i: number) {
    return i > 0 || this.datasets.length > 1;
  }
}
</script>
