/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SearchCollectionTimeZoneDto } from '../models/SearchCollectionTimeZoneDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TimeZoneService {

    /**
     * Fetch timezones list
     * @returns SearchCollectionTimeZoneDto Successfully fetch all timezones items
     * @throws ApiError
     */
    public static getAllTimeZone({
        textSearch,
    }: {
        /**
         * Global text search
         */
        textSearch?: string,
    }): CancelablePromise<SearchCollectionTimeZoneDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/timezones',
            query: {
                'textSearch': textSearch,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
