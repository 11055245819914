/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeCalendarReferenceDto } from './AsCodeCalendarReferenceDto';
import type { NumberOrPercentageDto } from './NumberOrPercentageDto';

export type AsCodeStaticThresholdWithComparisonModeDto = {
    comparisonMode?: AsCodeStaticThresholdWithComparisonModeDto.comparisonMode;
    excludedDates?: Array<AsCodeCalendarReferenceDto>;
    isMaxInclusive?: boolean;
    isMinInclusive?: boolean;
    max?: NumberOrPercentageDto;
    min?: NumberOrPercentageDto;
};

export namespace AsCodeStaticThresholdWithComparisonModeDto {

    export enum comparisonMode {
        ABSOLUTE = 'Absolute',
        DIFFERENCE = 'Difference',
        PERCENTAGE_DIFFERENCE = 'PercentageDifference',
    }


}
