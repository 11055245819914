<template lang="pug">
v-dialog(
  v-bind="$attrs"
  v-on="$listeners"
  v-model='dialog'
  :max-width='maxWidth'
  overlay-color="black"
  overlay-opacity="0.60"
  @keydown.esc="closeDialog()"
  @click:outside="closeDialog()"
  scrollable)

  v-card
    v-card-title.pb-0
      .d-flex.align-center( style="width: 100%;" )
        b.text-h5.text-truncate.mr-auto( data-cy="modal-dialog-title" ) {{ _title }}
        v-chip.ml-2( small outlined v-if="beta" data-cy="modal-dialog-beta-chip" ) {{ $t('common.words.beta') }}
        v-spacer
        v-btn.mr-n2( @click="closeDialog" icon data-cy="modal-dialog-dismiss-button" )
          v-icon icon-dismiss

      .d-flex.align-center( v-if="_subtitle" style="width: 100%;" )
        span.grey--text.text-truncate.mr-auto( data-cy="modal-dialog-sub-title" ) {{ _subtitle }}

    v-card-text.py-4( data-cy="modal-dialog-body" )
      slot( name="body" )

    v-card-actions.py-5.px-6
      v-spacer
      slot( name="pre-actions" )
      v-btn.mr-3(
        v-if="!disableCancel"
        :color="cancelButtonColor"
        :class="cancelButtonClass"
        :text="cancelButtonIsText"
        :outlined="cancelButtonOutlined" @click='closeDialog' data-cy="modal-close-button") {{ $t('common.words.cancel') }}
      slot( name="actions" )

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ModalDialog extends Vue {
  @Prop({ type: Boolean }) readonly disableCancel!: boolean;

  @Prop({ type: Boolean, default: false }) readonly beta!: boolean;

  @Prop({ type: String, default: '600px' }) readonly width!: string;

  dialog = false;

  get _title() {
    return this.$slots?.title || this.$attrs?.title || 'Modal Dialog';
  }

  get _subtitle() {
    return this.$attrs?.subtitle;
  }

  get maxWidth() {
    return 'full' in this.$attrs ? undefined : this.width;
  }

  get cancelButtonColor() {
    return this.$slots?.actions ? 'secondary' : 'primary';
  }

  get cancelButtonOutlined() {
    return !!this.$slots?.actions;
  }

  get cancelButtonIsText() {
    return !!this.$slots?.actions;
  }

  get cancelButtonClass() {
    return this.$slots?.actions ? 'custom-secondary' : 'custom-primary';
  }

  openDialog() {
    this.dialog = true;
  }

  closeDialog() {
    this.dialog = false;
    setTimeout(() => {
      // wait for animation to finish
      this.$emit('close');
    }, 300);
  }
}
</script>
