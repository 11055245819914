<template lang="pug">
v-card( outlined class="my-4 pa-4" min-height="300px" )
  v-row
    v-col( cols="8" )
      .text-h6.font-weight-medium {{ $t('assets.columns') }} ({{ nbFields }})
    v-col( cols="4" class="text-right" )
      router-link.link( :to="to" ) {{ $t('assets.see_details') }}
  v-row( v-for="(field, index) in fields" :key="index" )
    v-col( cols="8" )
      span {{ field.name }}
    v-col( cols="4" class="text-right")
      span.text-caption.mono.grey--text {{ field.type.toLowerCase() }}

</template>

<script lang="ts">
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import {
  AssetDto,
  AssetSchemaDto,
} from '@/api';

@Component
export default class AssetOverviewSchema extends Vue {
  @Prop({ type: Object }) schema!: AssetSchemaDto;

  @Prop(String) readonly urn!: AssetDto['urn'];

  get fields() {
    return this.schema?.fields?.sort((a: any, b: any) => a.name.localeCompare(b.name));
  }

  get nbFields() {
    return this.schema?.fields?.length ?? 0;
  }

  get to() {
    return {
      name: 'data-catalog.asset.schema',
      params: { urn: this.urn },
    };
  }
}
</script>
