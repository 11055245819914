/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AlertingHookDto } from '../models/AlertingHookDto';
import type { SlackAddChannelDto } from '../models/SlackAddChannelDto';
import type { SlackChannelDto } from '../models/SlackChannelDto';
import type { SlackConnectDto } from '../models/SlackConnectDto';
import type { SlackConnectionStatusDto } from '../models/SlackConnectionStatusDto';
import type { SlackFetchChannelsDto } from '../models/SlackFetchChannelsDto';
import type { SlackOAuthConnectDto } from '../models/SlackOAuthConnectDto';
import type { SlackRemoveChannelDto } from '../models/SlackRemoveChannelDto';
import type { StringPayload } from '../models/StringPayload';
import type { UnlinkingResultDto } from '../models/UnlinkingResultDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SlackService {

    /**
     * Add a new alerting hook
     * @returns AlertingHookDto Successfully added alerting hook
     * @throws ApiError
     */
    public static addAlertingHook({
        requestBody,
    }: {
        requestBody: SlackAddChannelDto,
    }): CancelablePromise<AlertingHookDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/slack/_add-alerting-hook',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Remove an alerting hook and unlink monitors
     * @returns UnlinkingResultDto Successfully removed alerting hook
     * @throws ApiError
     */
    public static removeAlertingHook({
        requestBody,
        dryRun = false,
    }: {
        requestBody: SlackRemoveChannelDto,
        dryRun?: boolean,
    }): CancelablePromise<UnlinkingResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/slack/_remove-alerting-hook',
            query: {
                'dryRun': dryRun,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Remove Slack workspace and unlink channels and monitors
     * @returns UnlinkingResultDto Successfully removed Slack workspace
     * @throws ApiError
     */
    public static removeSlackWorkspace({
        dryRun = false,
    }: {
        dryRun?: boolean,
    }): CancelablePromise<UnlinkingResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/slack/_remove-slack-workspace',
            query: {
                'dryRun': dryRun,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Connect Sifflet Slack app to your slack workspace
     * @returns SlackConnectDto Successfully connect app
     * @throws ApiError
     */
    public static connectApp({
        requestBody,
    }: {
        requestBody: SlackOAuthConnectDto,
    }): CancelablePromise<SlackConnectDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/slack/app/_connect',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Best effort fetch Slack channels on the connected Slack workspace
     * @returns SlackFetchChannelsDto Successfully fetched channels
     * @throws ApiError
     */
    public static fetchChannels(): CancelablePromise<SlackFetchChannelsDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/slack/app/_fetch-channels',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get connection status Slack
     * @returns SlackConnectionStatusDto Success
     * @throws ApiError
     */
    public static getConnectionStatus(): CancelablePromise<SlackConnectionStatusDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/slack/app/connection-status',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get Slack button uri
     * @returns StringPayload Success
     * @throws ApiError
     */
    public static getSlackButtonUri(): CancelablePromise<StringPayload> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/slack/app/slack-button-uri',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Test Sifflet Slack app by sending a test message to given channels
     * @returns void
     * @throws ApiError
     */
    public static testChannels({
        requestBody,
    }: {
        requestBody: Array<SlackChannelDto>,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/slack/channels/_test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
