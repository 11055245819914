/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeCalendarReferenceDto } from './AsCodeCalendarReferenceDto';

export type AsCodeFreshnessThresholdDto = {
    excludedDates?: Array<AsCodeCalendarReferenceDto>;
    kind: AsCodeFreshnessThresholdDto.kind;
};

export namespace AsCodeFreshnessThresholdDto {

    export enum kind {
        DYNAMIC = 'Dynamic',
        STATIC = 'Static',
    }


}
