<template lang="pug">
v-slide-x-transition( hide-on-leave )
  v-card( v-if="inSelectMode" width="100%" tile )
    .pa-3
      v-row( align-content="space-between" )
        v-col( cols="auto" align-self="center" )
          v-icon.mr-2( @click="selectAllHandler" :color="color" ) {{ icon }}
          b {{ $t('monitors.select_all') }}

      v-spacer
    v-divider

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class MonitorsResultSelectAll extends Vue {
  @Prop(Boolean) readonly selected!: boolean;

  @Prop(Boolean) readonly inSelectMode!: boolean;

  get color(): string {
    return this.selected ? 'primary' : '';
  }

  get icon(): string {
    return this.selected ? 'icon-check-square-fill' : 'icon-check-square-outline-empty';
  }

  selectAllHandler() {
    this.$emit('select-all');
  }
}
</script>
