/**
 * Returns a string with the highlighted part as an HTML tag with CSS
 * @param {string} value - The string to highlight
 * @param {string} query - The regex pattern used to highlight
 * @return string - The same string with the highlighted part between HTML tags
 */
export default (value: string, query: string) => {
  if (!value) return '';
  if (!query) return value;
  const escapedQuery = query
    ? query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    : query;
  const re = new RegExp(`(${escapedQuery})`, 'gi');
  return value.replace(
    re,
    "<b class='yellow lighten-4 secondary--text rounded' style='padding: 1px'>$1</b>",
  );
};

declare module 'vue/types/vue' {
  interface Vue {
    highlight: (value: string, query: string) => string;
  }
}
