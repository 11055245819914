import { MonitorSettingsOptions } from './common/types';
import { dynamicSettings } from './common/thresholdSettings';

const SiffletMetadataFreshnessRule: MonitorSettingsOptions = {
  thresholdSettings: [{
    ...dynamicSettings,
    hasAnomalyConditions: false,
    convertThresholdToLegacy: (legacyParams) => ({
        sensitivity: legacyParams.sensitivity,
      }),
}],
};

export default SiffletMetadataFreshnessRule;
