import { TagDto, TagService } from '@/api';
import {
  Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';

type SearchParameters = Parameters<typeof TagService.getAllTag>[0];

@Module({ namespaced: true, name: 'tags' })
export default class TagsModule extends VuexModule {
    searchParameters: SearchParameters = {
      page: 1,
      itemsPerPage: 10,
      textSearch: '',
      sort: [],
      type: [],
    };

    tags: TagDto[] = [];

    selectedTag: TagDto | null = null;

    loading = false;

    count = 100;

    get options() {
      return {
        itemsPerPage: this.searchParameters.itemsPerPage,
        page: this.searchParameters.page,
        sort: this.searchParameters.sort,
      };
    }

    get getSort() {
      return this.searchParameters.sort;
    }

    get searchString(): string | undefined { return this.searchParameters.textSearch; }

    get type(): string[] | undefined { return this.searchParameters.type; }

    get getTags(): TagDto[] { return this.tags; }

    get getLoading(): boolean { return this.loading; }

    get getCount(): number { return this.count; }

    @Mutation
    removeSelectedTag() {
      this.selectedTag = null;
    }

    @Mutation
    setSelectedTag(tag: TagDto) {
      this.selectedTag = tag;
    }

    @Mutation
    setSort(sort: string[] | undefined) {
      this.searchParameters.sort = sort;
    }

    @Mutation
    setOptions({ page, itemsPerPage, sort }: {
      page: number | undefined,
      itemsPerPage: number | undefined
      sort: string[] | undefined }) {
      this.searchParameters.page = page;
      this.searchParameters.itemsPerPage = itemsPerPage;
      this.searchParameters.sort = sort;
    }

    @Mutation
    setSearchString(searchString: string | undefined) {
      this.searchParameters.textSearch = searchString;
    }

    @Mutation
      setType(_type: ('GENERIC' | 'HIDDEN_DATA_CLASSIFICATION' | 'VISIBLE_DATA_CLASSIFICATION' | 'TERM' | 'BIGQUERY_EXTERNAL' | 'SNOWFLAKE_EXTERNAL')[] | undefined) {
      this.searchParameters.type = _type;
    }

    @Mutation
    setTags(tags: TagDto[]) {
      this.tags = tags;
    }

    @Mutation
    setLoading(loading: boolean) {
      this.loading = loading;
    }

    @Mutation
    setCount(count: number) {
      this.count = count;
    }

    @Mutation
    resetState() {
      this.count = 0;
      this.tags = [];
    }

    @Action
    async updateSearch() {
      this.searchParameters.page = 1;
      await this.searchTags();
    }

    @Action
    async searchTags() {
      this.setLoading(true);
      const { data, totalElements } = await TagService.getAllTag(this.searchParameters);
      this.setCount(totalElements!);
      this.setTags(data!);
      this.setLoading(false);
    }
}
