<script setup lang="ts">import { ref as _ref } from 'vue';

import type { QueryType } from '@/utils/query';
import { watch } from 'vue';

interface IncidentsSearchProps {
  search: string
}

type IncidentsSearchEmits = {
  (event: 'onFilterChange', filter: { [key: string]: QueryType}): void
}

const emit = defineEmits(["onFilterChange"]);

const props = defineProps({
  search: null
});

let textSearch = _ref<string>(props.search);

const clearSearchHandler = () => {
  textSearch.value = '';
  emit('onFilterChange', { textSearch: '' });
};

const searchHandler = () => {
  emit('onFilterChange', { textSearch: textSearch.value });
};

watch(() => props.search, () => {
  textSearch.value = props.search;
});
</script>

<template lang="pug">
v-text-field.mr-4(
  v-model.trim="textSearch"
  @click:clear="clearSearchHandler"
  @keyup.enter="searchHandler"
  prepend-inner-icon="icon-search"
  :placeholder="$t('incidents.search_incidents')"
  hide-details dense outlined clearable label persistent-hint )
</template>
