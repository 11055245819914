/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DatapointDto } from './DatapointDto';
import type { SpecialDate } from './SpecialDate';

export type ScalarExpectationDatapointDto = (DatapointDto & {
    anomalousValuePosition?: ScalarExpectationDatapointDto.anomalousValuePosition;
    anomalyImportance?: number;
    expectedValue?: number;
    expectedValueMax?: number;
    expectedValueMin?: number;
    specialDates?: Array<SpecialDate>;
} & {
    specialDates: Array<SpecialDate>;
});

export namespace ScalarExpectationDatapointDto {

    export enum anomalousValuePosition {
        ABOVE_CONFIDENCE_INTERVAL = 'ABOVE_CONFIDENCE_INTERVAL',
        BELOW_CONFIDENCE_INTERVAL = 'BELOW_CONFIDENCE_INTERVAL',
    }


}
