<template lang="pug">
  v-card.py-5.px-6.d-flex.flex-column.justify-center( outlined style="height: 100%;" )
    h5.font-weight-medium.text-h6.mb-3 {{ $t('dashboard.tabs.unresolved_incidents') }} ({{ totalElements }})
    v-row( justify="center" align="center" )
      v-col( cols="6" align="center" )
        DashboardIncidentsOpenCard( :summary="open" :status="$t('incidents.statuses.OPEN')" )
      v-col( cols="6" align="center" )
        DashboardIncidentsOpenCard( :summary="inProgress" :status="$t('incidents.statuses.IN_PROGRESS')" )
      v-col( cols="6" align="center" )
        DashboardIncidentsOpenCard( :averageResponseRate="averageResponseRate" :rateComparisonLastWeek="rateComparisonLastWeek" :status="$t('dashboard.average_response_rate')" )
</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  IncidentSummaryDto,
  StatisticsService,
} from '@/api';
import authModule from '@/store/modules/auth';
import dashboardModule from '@/store/modules/dashboard';
import store from '@/store';

const auth = getModule(authModule, store);
const dashboard = getModule(dashboardModule, store);

@Component
export default class DashboardIncidentsOpen extends Vue {
  incidentSummary: IncidentSummaryDto | null = null;

  get domain() {
    return auth.selectedDomain?.name;
  }

  get open() {
    return this.incidentSummary?.unresolvedIncidentsByStatus?.OPEN;
  }

  get inProgress() {
    return this.incidentSummary?.unresolvedIncidentsByStatus?.IN_PROGRESS;
  }

  get totalElements() {
    return this.incidentSummary?.totalElements;
  }

  get averageResponseRate() {
    return this.incidentSummary?.averageResponseRate;
  }

  get rateComparisonLastWeek() {
    return this.incidentSummary?.rateComparisonLastWeek;
  }

  get tags() {
    return dashboard.getSelectedTags;
  }

  @Watch('tags', { deep: true, immediate: true })
  async fetchIncidentsSummary() {
    this.incidentSummary = await StatisticsService.getIncidentsSummary({ domain: this.domain, tag: this.tags });
  }

  async mounted() {
   this.fetchIncidentsSummary();
  }
}
</script>
