import getters from './getters';

export default {
  RESET_STATE(state) {
    Object.assign(state, getters.getDefaultState());
  },
  SET_SECRETS(state, secrets) {
    state.secrets = secrets;
  },
};
