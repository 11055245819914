<template lang="pug">
.chart-tooltip
  ul.px-2
    li.list-item(v-for="item in items" :key="`${item.label}${item.value}`")
      span.mr-1 {{ item.label }}
      span(:class="{'red--text': item.anomaly, 'grey--text':  !item.anomaly}") {{ item.value }}
        span(v-if="anomalyLabel(item)").ml-1 {{ anomalyLabel(item) }}

  v-divider.mx-2.mb-1(v-if="specialDate || hasQualificationType || qualification.comment")

  ul.px-2(v-if="specialDate")
    li.list-item
      span.mr-1 {{ $t('charts.tooltip.excluded_date') }}
      span.grey--text
        | {{ specialDate.calendarName ? specialDate.calendarName : $t('charts.tooltip.unknown_calendar') }}
    li.list-item(v-if="specialDate.dateDescription")
      span.mr-1 {{ $t('charts.tooltip.description') }}
      span.grey--text
        | {{ specialDate.dateDescription }}

  ul.px-2(v-if="qualification.provider && qualification.comment || (hasQualificationType && !specialDate)")
    li.list-item
      span.mr-1 {{ $t('charts.tooltip.last_edit') }}
      span.qualification.grey--text
        Avatar(:user="transformProviderToUser(qualification.provider)" :size="20" override-font-size)
        span.ml-1 - {{ qualification.createdDate }}

  ul.px-2(v-if="hasQualificationType && !specialDate")
    li.list-item
      span.mr-1 {{ $t('charts.tooltip.qualification') }}
      span.qualification.grey--text
        v-icon.mr-1(:color="QUALIFICATION_COLORS[qualification.type]" :size="24") {{ QUALIFICATION_ICONS[qualification.type] }}
        | {{ QUALIFICATION_LABELS[qualification.type] }}

  ul.px-2(v-if="qualification.comment")
    li.list-item.align-start
      span.mr-1 {{ $t('charts.tooltip.note') }}
      span.qualification.grey--text {{ qualification.comment }}

</template>

<script setup lang="ts">import { computed as _computed } from 'vue';

// eslint-disable-next-line
// @ts-nocheck
import {
  QUALIFICATION_ICONS, QUALIFICATION_COLORS, QUALIFICATION_LABELS, type TooltipInfo, type TooltipQualificationInfo,
} from '@/@types/chart';
import {
 DatapointQualificationDto, ProviderDto, type GenericProviderDto, type UserProviderDto,
} from '@/api';
import i18n from '@/i18n';

interface Props {
  items: TooltipInfo[];
  qualification: TooltipQualificationInfo;
}
const props = defineProps({
  items: null,
  qualification: null
});

const hasQualificationType = _computed(() => (props.qualification.type !== DatapointQualificationDto.qualification.NO_QUALIFICATION && props.qualification.type != null));

const specialDate = _computed(() => {
  const { specialDates } = props.qualification;
  if (!specialDates?.length) return null;

  // We only show a single special date. And we try to show one with both a name and a description.
  return specialDates.find((sd) => sd.calendarName && sd.calendarDescription)
  ?? specialDates.find((sd) => sd.calendarName);
});

const transformProviderToUser = (provider: ProviderDto): { name: string } => {
  if (provider.type === ProviderDto.type.GENERIC) {
    let { createdBy } = provider as GenericProviderDto;
    if (createdBy === 'sifflet') createdBy = 'Sifflet';
    return { name: createdBy };
  }
  return { name: (provider as UserProviderDto).name };
};

const anomalyLabel = (item: TooltipInfo) => {
  switch (item.anomaly) {
    case false:
      return i18n.t('charts.tooltip.expected');
    case true:
      return i18n.t('charts.tooltip.anomaly');
    default: return '';
  }
};
</script>

<style lang="scss" scoped>
.chart-tooltip {
  min-width: 400px;
  max-width: 600px;
  margin: 0;
}

.qualification {
  display: flex;
  align-items: center;
  line-height: normal;
  width: 100%;
  text-wrap: wrap;
}

.list-item {
  margin: 8px 0;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 0 8px;
  width: 100%;
  align-items: center;
}
</style>
