<template lang="pug">
  div.chart-container
    v-chart( v-if="hasDtos"
      class="chart"
      ref="chart"
      :option="options"
      :autoresize="true"
      :theme="$vuetify.theme.dark ? 'dark' : 'light'" )
    span( v-else class="grey--text" ) {{ $t('rules.no_data_for_rule') }}
    v-btn(text outlined color="secondary" class="custom-secondary btn-reset" small @click="restore")
      v-icon(left) icon-arrow-refresh
      .ml-1 {{ $t('charts.reset') }}
    v-btn(color="secondary" class="custom-secondary btn-image" text outlined small @click="downloadImage")
      v-icon(left) icon-arrow-download
      .ml-1 {{ $t('charts.download') }}
</template>

<script lang="ts">
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import VChart from 'vue-echarts';
import dayjs from 'dayjs';
import {
  MultiMetricsGraphDto,
} from '@/api';
import formatNumber from '@/utils/formatNumber';
import ChartConfig, { axisLabelStyle, axisPointerLabelStyle } from './charts.utils';

@Component({
  components: {
    VChart,
  },
})

export default class MetricsGlobal extends Vue {
  @Prop({ type: Object, required: true }) graph!: MultiMetricsGraphDto;

  @Prop({ type: String, required: true }) id!: string;

  @Prop({ type: String, required: true }) runId!: string;

  @Prop({ type: Number }) savedZoomStart!: number;

  @Prop({ type: Number }) savedZoomEnd!: number;

  get graphPoints() {
    return this.graph?.graphPoints;
  }

  get metricNames() {
    return this.graph?.metricNames?.map((str) => (str && str.length >= 50 ? `${str.substring(0, 50)}...` : str));
  }

  get xAxisData() {
    return this.graphPoints?.map((point) => point.date);
  }

  get hasDtos() {
    return !!this.graph?.graphPoints?.length;
  }

  get start() {
    return (100 - (100 / this.graphPoints!.length) * 100) >= 0 ? (100 - (100 / this.graphPoints!.length) * 100) : 0;
  }

  get end() {
    return 100;
  }

  get options() {
    return {
      animation: true,
      textStyle: ChartConfig.textStyleConfig(),
      title: ChartConfig.titleConfig({ title: this.$t('charts.graph_type.MULTI_METRICS_GLOBAL_TIME_SERIES.title') }),
      grid: ChartConfig.gridConfig(),
      xAxis: ChartConfig.xAxisConfig(),
      yAxis: ChartConfig.yAxisConfig({ formatValue: (value: number) => formatNumber(value) }),
      dataZoom: ChartConfig.dataZoomConfig({ start: this.start, end: this.end }),
      toolbox: ChartConfig.toolboxConfig(),
      dataset: this.metricNames?.map((metric, index) => ({
        source: this.graphPoints?.map((point) => ({
          date: point.date,
          value: point.metricValues![index],
        })),
      })),
      tooltip: {
        padding: 2,
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          animation: false,
          label: {
            backgroundColor: '#fff',
            borderColor: 'grey',
            borderWidth: 1,
            shadowBlur: 0,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            color: 'grey',
            fontSize: '12px',
            borderRadius: 20,
          },
        },
        formatter: (series: any) => {
          const date = dayjs(series[0].axisValue).tz().format('MMM D, YYYY hh:mm A');
          let values = '';
          series.forEach((serie: any) => {
            values += `
            <ul>
                <li class="text-truncate">${serie?.marker} ${serie?.seriesName}</li>
                <li class="text-truncate">${serie?.value.value != null ? formatNumber(serie.value.value) : '-'}</li>
            </ul>
          `;
          });
          return `
          <div class="tooltip">
              <ul>
                  <li class="secondary--text">Date</li>
                  <li class="text-truncate">${date}</li>
              </ul>
              ${values}
          </div>`;
        },
      },
      legend: {
        orient: 'horizontal',
        type: 'scroll',
        data: this.metricNames as Array<string>,
        top: '45px',
        textStyle: {
          fontWeight: 'normal',
          fontSize: 12,
        },
      },

      series: this.metricNames?.map((metric, index) => ({
        name: metric,
        type: 'line',
        id: index,
        datasetIndex: index,
        symbol: 'circle',
        dimensions: ['date', 'value'],
        connectNulls: true,
      })),
    };
  }

  restore() {
    const { chart }: any = this.$refs;
    chart.dispatchAction({ type: 'restore' });
  }

  downloadImage() {
    const { chart }: any = this.$refs;
    const a = document.createElement('a');
    a.href = chart.getDataURL({
      pixelRatio: 2,
      backgroundColor: '#fff',
    });
    a.download = 'chart.png';
    a.click();
  }
}
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
}

.chart {
  height: 390px;
}
.tooltip {
  width: 400px;
  margin-left: 12px;
  & ul {
    margin: 8px 0;
    & li {
      display: inline-block;
    }
    & li:first-child {
      width: 120px;
    }
  }
}
.btn-reset {
  position:absolute;
  right: 192px;
  top: 10px;
  border: 1px solid black;
}
.btn-image {
  position:absolute;
  right: 30px;
  top: 10px;
  border: 1px solid black;
}
@media screen and (max-width: 790px) {
  .btn-reset, .btn-image {
    top: -30px;
  }
}
</style>
