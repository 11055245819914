/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AsCodeWorkspaceDto = {
    description?: string;
    id?: string;
    kind?: AsCodeWorkspaceDto.kind;
    name: string;
    version?: number;
};

export namespace AsCodeWorkspaceDto {

    export enum kind {
        WORKSPACE = 'Workspace',
        MONITOR = 'Monitor',
        ASSET = 'Asset',
        SOURCE = 'Source',
        LINEAGE = 'Lineage',
    }


}
