/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DataQualityRuleSummaryDto } from '../models/DataQualityRuleSummaryDto';
import type { DataStackSummaryDto } from '../models/DataStackSummaryDto';
import type { IncidentHeatMapDto } from '../models/IncidentHeatMapDto';
import type { IncidentSummaryDto } from '../models/IncidentSummaryDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class StatisticsService {

    /**
     * Fetch data stack info
     * @returns DataStackSummaryDto Successfully fetch data stack info
     * @throws ApiError
     */
    public static getDataStack({
        domain,
        tag,
    }: {
        /**
         * Domain used for stats
         */
        domain?: string,
        /**
         * UUIDs of Tags used for stats
         */
        tag?: Array<string>,
    }): CancelablePromise<DataStackSummaryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/statistics/data-stack',
            query: {
                'domain': domain,
                'tag': tag,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Fetch incidents heatmap
     * @returns IncidentHeatMapDto Successfully fetch incidents heatmap
     * @throws ApiError
     */
    public static getIncidentsHeatmap({
        historyDays = 90,
        domain,
        tag,
    }: {
        /**
         * Historical depth for stats
         */
        historyDays?: number,
        /**
         * Domain used for stats
         */
        domain?: string,
        /**
         * UUIDs of Tags used for stats
         */
        tag?: Array<string>,
    }): CancelablePromise<IncidentHeatMapDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/statistics/incidents-heatmap',
            query: {
                'historyDays': historyDays,
                'domain': domain,
                'tag': tag,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Fetch incidents summary
     * @returns IncidentSummaryDto Successfully fetch incidents summary
     * @throws ApiError
     */
    public static getIncidentsSummary({
        domain,
        tag,
    }: {
        /**
         * Domain used for stats
         */
        domain?: string,
        /**
         * UUIDs of Tags used for stats
         */
        tag?: Array<string>,
    }): CancelablePromise<IncidentSummaryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/statistics/incidents-summary',
            query: {
                'domain': domain,
                'tag': tag,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Fetch rules summary
     * @returns DataQualityRuleSummaryDto Successfully fetch rules summary
     * @throws ApiError
     */
    public static getRulesSummary({
        domain,
        tag,
    }: {
        /**
         * Domain used for stats
         */
        domain?: string,
        /**
         * UUIDs of Tags used for stats
         */
        tag?: Array<string>,
    }): CancelablePromise<DataQualityRuleSummaryDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/statistics/rules-summary',
            query: {
                'domain': domain,
                'tag': tag,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
