/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DatasetFieldPatchDto } from '../models/DatasetFieldPatchDto';
import type { DescriptionPredictionFeedbackDto } from '../models/DescriptionPredictionFeedbackDto';
import type { FieldDto } from '../models/FieldDto';
import type { TagPredictionFeedbackDto } from '../models/TagPredictionFeedbackDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DatasetFieldService {

    /**
     * @returns FieldDto Successfully update field
     * @throws ApiError
     */
    public static updateDatasetField({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: DatasetFieldPatchDto,
    }): CancelablePromise<FieldDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/ui/v1/dataset-fields/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static sendDescriptionPredictionFeedback({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: DescriptionPredictionFeedbackDto,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/dataset-fields/{id}/_send-description-prediction-feedback',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public static sendTagPredictionFeedback({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: TagPredictionFeedbackDto,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/dataset-fields/{id}/_send-tag-prediction-feedback',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
