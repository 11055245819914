<template lang="pug">
v-autocomplete(
    v-bind="$attrs"
    v-on="$listeners"
    :items='secrets'
    :rules='secretRules'
    item-text='name'
    label='Credentials'
    item-value='name'
    dense outlined clearable required )

</template>

<script lang="ts">
import { SecretDto, SecretService } from '@/api';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputSchema extends Vue {
  secrets: SecretDto[] = [];

  secretRules = [(v: string) => !!v || this.$t('selectors.secret_required')];

  async mounted() {
    this.secrets = await SecretService.getAllSecrets();
  }
}
</script>
