<template lang="pug">
v-container( fluid ).pl-0
  v-row
    v-col( cols="12" )
      .font-weight-medium {{ $t('conditional-rules.join_keys') }}
      .font-weight-normal {{ $t('conditional-rules.select_field') }}
  v-row.mt-0
    v-col( cols="5" md="4" )
      ConditionalRulesDataset.mb-0.py-2( :dataset="dataset1" :datasets="datasets" )
      ConditionalRulesConditionFieldInput( v-model="joinList[0].joinKeyFields[0]" :dataset="dataset1" :datasets="[dataset1]" :id="0" )
    v-col( cols="1" style="font-size: 40px;" ).d-flex.equal.align-center.justify-center {{ $t('conditional-rules.equal') }}
    v-col( cols="5" md="4" )
      ConditionalRulesDataset.mb-0.py-2( :dataset="dataset2" :datasets="datasets"  )
      ConditionalRulesConditionFieldInput( v-model="joinList[0].joinKeyFields[1]" :dataset="dataset1" :datasets="[dataset2]" :id="1")
</template>

<script lang="ts">
import * as ConditionalRulesTYPES from '@/@types/conditional-rules';
import {
  Vue, Component, VModel, Prop,
} from 'vue-property-decorator';
import { DatasetLightDto } from '@/api';

@Component
export default class ConditionalRulesJoinKeyField extends Vue {
  @VModel({ type: Array }) readonly joinList!: ConditionalRulesTYPES.JoinItem[];

  @Prop({ type: Array, required: true }) datasets!: DatasetLightDto[];

  get dataset1() {
    return this.datasets[0];
  }

  get dataset2() {
    return this.datasets[1];
  }
}
</script>

<style scoped>

</style>
