<template lang="pug">
v-autocomplete(
  :class="{'tag-selector': !isDashboardFilter, 'dashboard-tag-selector': isDashboardFilter}"
  ref="tagSelector"
  v-bind='$attrs'
  v-on='$listeners'
  :loading="loading"
  :items="tags"
  :search-input.sync="searchString"
  :hint="hint"
  :return-object="_returnObject"
  :append-icon="appendIcon"
  :prepend-inner-icon="getInnerIcon()"
  @change="searchString=''"
  @click="closeMenu"
  @click:append="clear"
  placeholder="Select a tag"
  item-value="id"
  label=""
  item-text="name"
  data-cy="tag-selector"
  cache-items dense outlined
  hide-details
  hide-no-data persistent-hint
  multiple)

  template(v-if="canRemove" v-slot:prepend-item)
    v-list-item( ripple @mousedown.prevent @click='toggleNull' )
      v-list-item-action
        v-icon {{ icon }}
      v-list-item-content
        v-list-item-title {{ $t('selectors.remove_existing') }}
    v-divider.mt-2

  template(v-slot:item="{ item, attrs }")
    v-list-item(v-bind="attrs" data-cy="tag-selector-item")
      v-list-item-action
        v-icon {{ customItemIcon(attrs.inputValue) }}
      v-list-item-content.d-inline-block
        Tag.my-1( :tag="item" :key="item.id" small) {{ item.name }}
    v-divider.mt-2

  template(v-slot:selection="{ item }")
    Tag.my-1( v-if="!isDashboardFilter" :tag="item" :key="item.id" small) {{ item.name }}
    v-tooltip( v-else-if="shouldShowTooltip(item.name)" top )
      template(v-slot:activator='{ on }')
        Tag.mb-1( v-on="on" :tag="item" :key="item.id" small style="max-width: 165px !important") {{ item.name }}
      span {{ item.name }}
      div.tooltip-arrow-bottom
    Tag.mb-1( v-else :tag="item" :key="item.id" small style="max-width: 165px !important") {{ item.name }})

</template>

<script lang="ts">
import {
  Vue, Component, Watch, Prop, Ref,
} from 'vue-property-decorator';
import { TagDto, TagService } from '@/api';
import Tag from '@/components/tags/Tag.vue';

@Component({
  components: {
    Tag,
  },
})
export default class TagSelector extends Vue {
  @Prop({ default: 'Select one or multiple tags' })
  readonly hint!: string;

  @Prop({ type: Boolean, default: false }) readonly dashboardFilter!: boolean;

  searchString = '';

  tags: TagDto[] = [];

  loading = false;

  @Ref()
  private readonly tagSelector!: any;

  get canRemove(): boolean {
    return 'can-remove' in this.$attrs;
  }

  get searchOption(): any {
    return this.searchString === null ? '' : this.searchString;
  }

  get isNull(): boolean {
    return this.$attrs.value === null;
  }

  get icon(): string {
    return this.isNull ? 'icon-check-square-fill' : 'icon-check-square-outline-empty';
  }

  customItemIcon(inputValue: boolean): string {
    return inputValue ? 'icon-check-square-fill' : 'icon-check-square-outline-empty';
  }

  get label(): string {
    return this.isNull ? 'Remove existing' : 'Tags';
  }

  get labelClass(): string {
    return this.isNull ? 'warning--text' : '';
  }

  get color(): string {
    return this.isNull ? 'warning' : '';
  }

  get appendIcon(): string {
    return this.isNull || this.$attrs.value?.length ? 'icon-dismiss' : '';
  }

  get _returnObject(): any {
    return this.isDashboardFilter ? false : (this.$attrs['return-object'] as unknown as boolean) !== false;
  }

  get isDashboardFilter(): boolean {
    return this.dashboardFilter;
  }

  getInnerIcon() {
    return this.dashboardFilter ? 'icon-tag' : '';
  }

  shouldShowTooltip(item: string) {
    return item.length >= 20;
  }

  clear() {
    this.tags = [];
    this.$emit('input', this.tags);
    this.closeMenu();
  }

  closeMenu() {
    if (this.tagSelector.isMenuActive) setTimeout(this.tagSelector.blur, 0);
  }

  @Watch('searchString')
  searchHandler() {
    this.fetchTags();
  }

  async fetchTags() {
    this.loading = true;
    const { data } = await TagService.getAllTag({ textSearch: this.searchString, itemsPerPage: -1 });
    this.tags = data!;
    this.loading = false;
  }

  toggleNull() {
    if (!this.isNull) this.$emit('input', null);
    else this.$emit('input', []);
    this.closeMenu();
  }
}
</script>

<style lang="scss">
.resetLineHeight {
  line-height: initial;
}

.tag-selector {
  .v-select__slot {
    padding: 3px 0px 3px;
  }

  .v-input__append-inner {
    margin-top: 5px !important;
  }
}

.dashboard-tag-selector {
  background-color: var(--v-bgPrimary-base) !important;
  width: 250px;
  margin-top: 10px;

  .v-select__selections {
    margin-top: 4px;
  }

  .v-input__slot {
    opacity: 0.7;
  }

  .v-label {
    margin-top: -2px;

    &.v-label--active {
      margin-top: 0px;
    }
  }

  .v-input__icon {
    &.v-input__icon--prepend-inner {
      margin-top: 2px;
      opacity: 0.5;
    }

    &.v-input__icon--append {
      margin-top: 2px;
      opacity: 0.5;
    }

    &.v-input__icon--clear {
      opacity: 0.5;
    }
  }
}</style>
