/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RuleTemplateDto } from '../models/RuleTemplateDto';
import type { RuleTemplateIndexDto } from '../models/RuleTemplateIndexDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RuleTemplateService {

    /**
     * Fetch Rule Template by given name
     * @returns RuleTemplateDto Successfully fetch RuleTemplate
     * @throws ApiError
     */
    public static getRuleTemplateByName({
        dataset,
        rule,
        ruleTemplateName,
    }: {
        /**
         * Set dataset ids
         */
        dataset?: Array<string>,
        /**
         * Set rule id if available
         */
        rule?: string,
        /**
         * Set rule template name
         */
        ruleTemplateName?: string,
    }): CancelablePromise<RuleTemplateDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/rule-templates',
            query: {
                'dataset': dataset,
                'rule': rule,
                'ruleTemplateName': ruleTemplateName,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Fetch Rule Template index
     * @returns RuleTemplateIndexDto Successfully fetch RuleTemplate index
     * @throws ApiError
     */
    public static getRuleTemplateIndex(): CancelablePromise<RuleTemplateIndexDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/rule-templates/index',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
