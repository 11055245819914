<template lang="pug">
div( class="background" )
  span
  span
  span
  span
  span
  span
  span
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AnimatedBackground extends Vue {
}
</script>

<style scoped>
@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}
.background {
  position: fixed;
  z-index: 0;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: #000713;
  overflow: hidden;
}
.background span {
  width: 50vmin;
  height: 50vmin;
  border-radius: 50vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 12s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.background span:nth-child(0) {
  color: #0a3e50;
  top: 63%;
  left: 37%;
  animation-duration: 28s;
  animation-delay: -378s;
  transform-origin: 16vw 18vh;
  box-shadow: -100vmin 0 12.514664846968405vmin currentColor;
}
.background span:nth-child(1) {
  color: #0a3e50;
  top: 17%;
  left: 33%;
  animation-duration: 99s;
  animation-delay: -246s;
  transform-origin: 12vw 24vh;
  box-shadow: -100vmin 0 12.58107291305549vmin currentColor;
}
.background span:nth-child(2) {
  color: #0a3e50;
  top: 21%;
  left: 96%;
  animation-duration: 180s;
  animation-delay: -135s;
  transform-origin: 1vw -12vh;
  box-shadow: 100vmin 0 12.956974237648955vmin currentColor;
}
.background span:nth-child(3) {
  color: #0a3e50;
  top: 57%;
  left: 55%;
  animation-duration: 36s;
  animation-delay: -137s;
  transform-origin: -22vw 20vh;
  box-shadow: 100vmin 0 13.063518375182685vmin currentColor;
}
.background span:nth-child(4) {
  color: #0a3e50;
  top: 29%;
  left: 50%;
  animation-duration: 149s;
  animation-delay: -380s;
  transform-origin: -18vw 4vh;
  box-shadow: -100vmin 0 12.565287593589325vmin currentColor;
}
.background span:nth-child(5) {
  color: #0a3e50;
  top: 67%;
  left: 4%;
  animation-duration: 64s;
  animation-delay: -247s;
  transform-origin: -19vw -2vh;
  box-shadow: 100vmin 0 13.389737753624928vmin currentColor;
}
.background span:nth-child(6) {
  color: #0a3e50;
  top: 55%;
  left: 43%;
  animation-duration: 346s;
  animation-delay: -357s;
  transform-origin: -21vw -18vh;
  box-shadow: 100vmin 0 13.398294386909118vmin currentColor;
}
.background span:nth-child(7) {
  color: #0a3e50;
  top: 58%;
  left: 43%;
  animation-duration: 114s;
  animation-delay: -309s;
  transform-origin: 1vw -14vh;
  box-shadow: 100vmin 0 13.273280431665246vmin currentColor;
}
.background span:nth-child(8) {
  color: #0a3e50;
  top: 38%;
  left: 58%;
  animation-duration: 134s;
  animation-delay: -329s;
  transform-origin: -17vw -4vh;
  box-shadow: -100vmin 0 13.030514640161917vmin currentColor;
}
.background span:nth-child(9) {
  color: #0a3e50;
  top: 58%;
  left: 9%;
  animation-duration: 160s;
  animation-delay: -63s;
  transform-origin: -12vw -9vh;
  box-shadow: 100vmin 0 12.779558594782014vmin currentColor;
}
.background span:nth-child(10) {
  color: #0a3e50;
  top: 88%;
  left: 8%;
  animation-duration: 63s;
  animation-delay: -83s;
  transform-origin: 14vw 13vh;
  box-shadow: -100vmin 0 12.578727809982412vmin currentColor;
}
.background span:nth-child(11) {
  color: #0a3e50;
  top: 71%;
  left: 15%;
  animation-duration: 218s;
  animation-delay: -78s;
  transform-origin: 16vw 12vh;
  box-shadow: -100vmin 0 12.578689979342448vmin currentColor;
}
.background span:nth-child(12) {
  color: #0a3e50;
  top: 48%;
  left: 41%;
  animation-duration: 249s;
  animation-delay: -60s;
  transform-origin: -5vw -5vh;
  box-shadow: 100vmin 0 13.031269511214228vmin currentColor;
}
.background span:nth-child(13) {
  color: #0a3e50;
  top: 56%;
  left: 69%;
  animation-duration: 354s;
  animation-delay: -40s;
  transform-origin: 19vw 8vh;
  box-shadow: -100vmin 0 13.281022369080338vmin currentColor;
}
.background span:nth-child(14) {
  color: #0a3e50;
  top: 61%;
  left: 73%;
  animation-duration: 212s;
  animation-delay: -7s;
  transform-origin: -12vw 3vh;
  box-shadow: -100vmin 0 13.267002012868915vmin currentColor;
}
.background span:nth-child(15) {
  color: #0a3e50;
  top: 51%;
  left: 63%;
  animation-duration: 38s;
  animation-delay: -372s;
  transform-origin: 18vw -2vh;
  box-shadow: -100vmin 0 13.175418986285258vmin currentColor;
}
.background span:nth-child(16) {
  color: #0a3e50;
  top: 33%;
  left: 21%;
  animation-duration: 79s;
  animation-delay: -365s;
  transform-origin: 2vw -4vh;
  box-shadow: -100vmin 0 12.72628937849088vmin currentColor;
}
.background span:nth-child(17) {
  color: #0a3e50;
  top: 54%;
  left: 16%;
  animation-duration: 262s;
  animation-delay: -83s;
  transform-origin: 19vw -9vh;
  box-shadow: -100vmin 0 13.469815341881647vmin currentColor;
}
.background span:nth-child(18) {
  color: #0a3e50;
  top: 33%;
  left: 84%;
  animation-duration: 96s;
  animation-delay: -150s;
  transform-origin: -10vw -17vh;
  box-shadow: 100vmin 0 12.718560732673641vmin currentColor;
}
.background span:nth-child(19) {
  color: #0a3e50;
  top: 11%;
  left: 50%;
  animation-duration: 75s;
  animation-delay: -355s;
  transform-origin: -20vw -4vh;
  box-shadow: -100vmin 0 12.933000161263642vmin currentColor;
}
.background span:nth-child(20) {
  color: #0a3e50;
  top: 20%;
  left: 32%;
  animation-duration: 91s;
  animation-delay: -189s;
  transform-origin: 0 1vh;
  box-shadow: 100vmin 0 12.589577679244375vmin currentColor;
}
.background span:nth-child(21) {
  color: #0a3e50;
  top: 99%;
  left: 68%;
  animation-duration: 218s;
  animation-delay: -227s;
  transform-origin: -14vw 17vh;
  box-shadow: -100vmin 0 12.839814249811798vmin currentColor;
}
.background span:nth-child(22) {
  color: #0a3e50;
  top: 15%;
  left: 50%;
  animation-duration: 28s;
  animation-delay: -100s;
  transform-origin: -12vw -16vh;
  box-shadow: -100vmin 0 13.194825272605755vmin currentColor;
}
.background span:nth-child(23) {
  color: #0a3e50;
  top: 33%;
  left: 6%;
  animation-duration: 65s;
  animation-delay: -239s;
  transform-origin: 18vw 0;
  box-shadow: -100vmin 0 13.199615798941242vmin currentColor;
}
.background span:nth-child(24) {
  color: #0a3e50;
  top: 20%;
  left: 23%;
  animation-duration: 161s;
  animation-delay: -154s;
  transform-origin: 12vw 6vh;
  box-shadow: -100vmin 0 12.78046253230901vmin currentColor;
}
.background span:nth-child(25) {
  color: #0a3e50;
  top: 50%;
  left: 7%;
  animation-duration: 159s;
  animation-delay: -108s;
  transform-origin: 25vw 18vh;
  box-shadow: -100vmin 0 13.241069685304321vmin currentColor;
}
.background span:nth-child(26) {
  color: #0a3e50;
  top: 46%;
  left: 56%;
  animation-duration: 316s;
  animation-delay: -219s;
  transform-origin: 1vw 16vh;
  box-shadow: 100vmin 0 12.846890276754813vmin currentColor;
}
.background span:nth-child(27) {
  color: #0a3e50;
  top: 29%;
  left: 10%;
  animation-duration: 90s;
  animation-delay: -311s;
  transform-origin: -23vw 2vh;
  box-shadow: 100vmin 0 13.29477545725765vmin currentColor;
}
.background span:nth-child(28) {
  color: #0a3e50;
  top: 5%;
  left: 30%;
  animation-duration: 103s;
  animation-delay: -95s;
  transform-origin: 17vw 14vh;
  box-shadow: 100vmin 0 12.892235319894484vmin currentColor;
}
.background span:nth-child(29) {
  color: #0a3e50;
  top: 39%;
  left: 66%;
  animation-duration: 41s;
  animation-delay: -88s;
  transform-origin: -20vw -20vh;
  box-shadow: -100vmin 0 12.553122729044826vmin currentColor;
}
.background span:nth-child(30) {
  color: #0a3e50;
  top: 92%;
  left: 82%;
  animation-duration: 231s;
  animation-delay: -194s;
  transform-origin: -20vw -8vh;
  box-shadow: -100vmin 0 12.896711907491126vmin currentColor;
}
.background span:nth-child(31) {
  color: #0a3e50;
  top: 44%;
  left: 14%;
  animation-duration: 363s;
  animation-delay: -31s;
  transform-origin: 8vw 6vh;
  box-shadow: -100vmin 0 13.087124727988238vmin currentColor;
}
.background span:nth-child(32) {
  color: #0a3e50;
  top: 54%;
  left: 33%;
  animation-duration: 158s;
  animation-delay: -338s;
  transform-origin: 14vw -24vh;
  box-shadow: -100vmin 0 13.335092025438604vmin currentColor;
}
.background span:nth-child(33) {
  color: #0a3e50;
  top: 52%;
  left: 95%;
  animation-duration: 217s;
  animation-delay: -344s;
  transform-origin: 22vw -9vh;
  box-shadow: -100vmin 0 12.645787557100702vmin currentColor;
}
.background span:nth-child(34) {
  color: #0a3e50;
  top: 89%;
  left: 13%;
  animation-duration: 158s;
  animation-delay: -48s;
  transform-origin: -1vw 19vh;
  box-shadow: -100vmin 0 12.53348560283288vmin currentColor;
}
</style>
