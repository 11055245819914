<script setup lang="ts">
import {
  Vue,
} from 'vue-property-decorator';
import { ref, watch } from 'vue';
import { getModule } from 'vuex-module-decorators';
import i18n from '@/i18n';
import { AssetService, MessageReportDto } from '@/api';
import authModule from '@/store/modules/auth';
import store from '@/store';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';

interface CatalogImportProps {
  searchParams: Parameters<typeof AssetService.getAll>[0]
}

const auth = getModule(authModule, store);

const props = defineProps({
  searchParams: null
});

const file = ref<File | null>(null);
const id = ref('');
const messageReportsDto = ref<MessageReportDto[]>([]);
const isSuccessful = ref(false);
const isLoading = ref(false);
const modalDialog = ref<ModalDialog | null>(null);
const preview = ref([
  {
    test: 'test',
  },
]);

const handleClear = () => {
  id.value = '';
  isSuccessful.value = false;
  messageReportsDto.value = [];
  isLoading.value = false;
};

const openDialog = () => {
  modalDialog.value!.openDialog();
};

const preImport = async () => {
  // Get text content from csv file
  const content = await file.value?.text();
  const requestBody = { content };
  isLoading.value = true;
  const {
    id: newId,
    success,
    messageReportsDto: newMessageReportsDto,
  } = await AssetService.preImportAssetDescriptions({ requestBody });
  id.value = newId!;
  isSuccessful.value = success!;
  messageReportsDto.value = newMessageReportsDto!;
  isLoading.value = false;
};

const apply = async () => {
  const report = await AssetService.confirmImportAssetDescriptions({
    importId: id.value,
  });
  Vue.notify({
    title: i18n.t('assets.notification') as string,
    text: report.messageReportsDto![0].content,
    type: report.success ? 'success' : 'error',
  });
  modalDialog.value!.closeDialog();
};

const downloadCatalog = async () => {
  const { content } = await AssetService.exportAssetDescriptions({ ...props.searchParams, domain: auth.selectedDomain?.name });

  // Create csv file from content
  if (content) {
    const blob = new Blob([content], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'catalog.csv');
    document.body.appendChild(link);
    link.click();
  }
};

const downloadTemplate = async () => {
  const { content } = await AssetService.exportAssetDescriptionsTemplate();

  // Create csv file from content
  if (content) {
    const blob = new Blob([content], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'template.csv');
    document.body.appendChild(link);
    link.click();
  }
};

watch(() => file.value, () => {
  if (file.value) {
    preImport();
  }
});

defineExpose({
  openDialog,
});
</script>

<template lang="pug">
  ModalDialog( beta ref="modalDialog" :title="$t('data-catalog.catalog_import')" )
    template( v-slot:body )
      v-stepper(
        class="elevation-0"
        :value="1"
        non-linear
        vertical)

        // Download File
        v-stepper-step( step="1" complete color="secondary" complete-icon="icon-check") {{ $t('assets.download_catalog') }}
        v-stepper-content( step="1" )
          v-row
            v-col
              v-card.py-2
                span.mr-2 {{ $t('data-catalog.download') }}
                SButton.mr-2(
                  icon="icon-arrow-download"
                  :text="$t('assets.CATALOG_CSV')"
                  color="primary"
                  size="small"
                  :async-action="downloadCatalog")
                span {{ $t('assets.or') }}
              v-card.py-2
                span.mr-2 {{ $t('assets.download_a') }}
                SButton.mr-2(
                  icon="icon-arrow-download"
                  :text="$t('assets.TEMPLATE_CSV')"
                  color="primary"
                  size="small"
                  :async-action="downloadTemplate")
                span  {{ $t('assets.with_prefiled_values') }}

        // Select File
        v-stepper-step( step="1" complete color="secondary" complete-icon="icon-check") {{ $t('assets.upload_file') }}
        v-stepper-content( step="1" )
          v-row
            v-col
              v-file-input(
                class="pt-1"
                v-model="file"
                accept=".csv"
                color="primary"
                label="File input"
                placeholder="Select your file"
                prepend-icon="icon-paperclip"
                outlined counter dense
                @click:clear="handleClear"
                :show-size="1000")
                template(v-slot:selection="{ text }")
                  v-chip( color="primary" label small)
                    .textOnsurface--text {{ text }}

        // Select File
        v-stepper-step( step="1" complete color="secondary" complete-icon="icon-check") {{ $t('assets.preview') }}
        v-stepper-content( step="1" )
          v-row
            v-col
              v-alert( v-for="(message, i) in messageReportsDto" :key="i" text dense icon="icon-error-circle-fill" border="left" :type="isSuccessful ? 'success' : 'error'" )
                div {{ message.content }}
                div( v-if="message.lineNumbers?.length" ) {{ $t('assets.lines') }}: {{ message.lineNumbers }}
    template( v-slot:actions )
      v-btn( :loading="isLoading" :disabled="!isSuccessful" color="primary" class="custom-primary" @click='apply' type='button') {{ $t('assets.apply') }}
</template>
