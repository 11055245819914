/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DomainUiLightDto = {
    id: string;
    isAllDomain: boolean;
    name: string;
    role: DomainUiLightDto.role;
};

export namespace DomainUiLightDto {

    export enum role {
        EDITOR = 'EDITOR',
        VIEWER = 'VIEWER',
    }


}
