<template lang="pug">
v-card#sp-config(:disabled="disabled")
  v-row
    v-col
      .text-h6.font-weight-medium {{ $t('sso.sifflet_configuration') }}
  v-row(v-for="(value, label) in spConfig" :key="value")
    v-col(md="2" sm="3" cols="3")
      .font-weight-medium.align-baseline {{ label  }}
    v-col.py-1.px-3( md="6" sm="9" cols="9" )
      v-text-field(
        :disabled="true"
        :value="value"
        :label="label"
        :key="label"
        dense readonly outlined required)
        template(v-slot:append-outer)
          v-btn( color="secondary" class="custom-secondary icon-only" text outlined @click="handleCopyToClipboard({ value, label })" )
            v-icon $content_copy
</template>

<script lang="ts">
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import { IdpService } from '@/api';
import copyToClipboard from '@/utils/copyToClipboard';

@Component
export default class SPConfig extends Vue {
  @Prop(Object) readonly config!: { signInMethod: string, registrationId: string };

  @Prop({ type: Boolean }) readonly disabled!: boolean;

  spConfig: Record<string, string> = {};

  hasConfig = false;

  handleCopyToClipboard({ value, label }: { value: string, label: string }) {
    copyToClipboard(value, `${label} value copied to clipboard`);
  }

  @Watch('config', { immediate: false })
  async onConfigChange() {
    if (this.config.signInMethod && this.config.registrationId && !this.hasConfig) {
      this.hasConfig = true;
      this.spConfig = await IdpService.getSpConfig({
        signInMethod: this.config.signInMethod,
        registrationId: this.config.registrationId,
      });
    }
  }
}
</script>

<style lang="scss">
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-outer {
  margin-top: 0;
}
</style>
