<template lang="pug">

.d-flex.flex-column.mb-3
  .d-flex( v-if="hasLoaded" )
    b.mr-2 {{ $t('common.names.tags') }}
    v-btn( v-if="canEdit" icon x-small @click="enterEditMode" )
      v-icon( small ) icon-edit

  template( v-if="hasNoTags" )
    .grey--text {{ $t('common.words.none') }}

  template( v-if="hasTags" )
    Tags( :tags="tags" small )

  .ma-2( v-if="editMode" )
    v-form( lazy-validation v-model='valid' )
      TagSelector( v-model="tagsModel" )
      .d-flex
        v-btn.ml-auto( class="custom-secondary" color='secondary' text outlined @click="exitEditMode" ) {{ $t('common.words.cancel') }}
        v-btn.ml-2( :disabled="disabled" class="custom-primary" color='primary'  @click="save" ) {{ $t('common.words.save') }}

  v-skeleton-loader(
    v-if="!hasLoaded"
    class="v-skeleton-loader-override"
    width="300"
    type="list-item-three-line" )

</template>

<script lang="ts">
import { TagDto } from '@/api';
import {
  Vue, Component, Emit, VModel, Prop,
} from 'vue-property-decorator';

@Component
export default class InputTagsEdit extends Vue {
  @VModel({ type: Array, default: null }) tags!: TagDto[] | null;

  @Prop({ type: Boolean, default: true }) readonly readonly!: boolean;

  editMode = false;

  tagsModel: TagDto[] | null = [];

  valid = true;

  get canEdit() {
    return !this.readonly && !this.editMode;
  }

  get hasLoaded() {
    return this.tags !== null && !this.tags !== undefined;
  }

  get disabled() {
    return !this.valid;
  }

  get hasNoTags() {
    return this.hasLoaded && !this.tags?.length && !this.editMode;
  }

  get hasTags() {
    return this.hasLoaded && this.tags?.length && !this.editMode;
  }

  enterEditMode() {
    this.editMode = true;
    this.tagsModel = this.tags;
  }

  exitEditMode() {
    this.editMode = false;
  }

  @Emit('save')
  save() {
    this.exitEditMode();
    return this.tagsModel;
  }
}
</script>
