<template lang="pug">
v-row( no-gutters )
  v-col( cols="12" ).my-1
    v-btn-toggle( v-model="assetFilterType" dense mandatory )
      template( v-for="button in buttons" )
        v-btn.text-body-2(
          color="secondary" class="custom-secondary"
          text outlined
          :value="button.value"
          :key="button.value")
          v-icon(v-if="button.icon" small) {{ button.icon }}
          span(v-else) {{ button.text }}

  v-col( cols="12" ).mt-6

    v-data-iterator(
      return-object
      :items="items"
      :server-items-length="count"
      :options.sync="options"
      :footer-props="{ 'items-per-page-options': [10, 25] }"
      :loading="loading"
      :hide-default-footer="disablePagination")

      template(v-slot:loading)
        MonitorsResultsPlaceholder

      template(v-slot:no-data)
        NoDataAvailablePlaceholder( :text="`${$t('glossary.no_result')}`" )

      template(v-slot:item="itemProps")
        EntityPreviewCard( :entity="itemProps.item" )

</template>

<script lang="ts">
// eslint-disable-next-line
// @ts-nocheck
import {
  SearchCollectionAssetSummaryDto,
} from '@/api';
import {
  Vue, Component, Prop, VModel, Watch,
} from 'vue-property-decorator';
import { DataPagination } from 'vuetify';

@Component
export default class BusinessGlossaryDrawerLinked extends Vue {
  @Prop({ type: Object, required: true }) assets!: SearchCollectionAssetSummaryDto;

  @Prop({ type: Boolean, default: false }) loading!: boolean;

  @VModel({ type: Object, required: true })
  readonly options!: DataPagination;

  assetFilterType = '';

  buttons = {
    All: {
      text: this.$t('common.words.All'),
      value: '',
    },
    TABLE_AND_VIEW: {
      text: this.$tc('glossary.tables_and_views'),
      value: 'TABLE_AND_VIEW',
    },
    DASHBOARD: {
      text: this.$tc('glossary.dashboards'),
      value: 'DASHBOARD',
    },
    PIPELINE: {
      text: this.$tc('glossary.pipelines'),
      value: 'PIPELINE',
    },
    MONITOR: {
      text: this.$tc('glossary.monitors'),
      value: 'MONITOR',
    },
    GENERIC: {
      icon: 'icon-menu-dots-horizontal',
      value: 'GENERIC',
    },
  };

  get count() {
    return this.assets?.totalElements || 0;
  }

  get items() {
    return this.assets?.data || [];
  }

  get disablePagination() {
    return this.count <= this.options.itemsPerPage;
  }

  @Watch('assetFilterType')
  onFilterChange() {
    this.$emit('update-filter', this.assetFilterType);
  }
}
</script>

<style lang="scss">
.v-btn-toggle {
  .v-btn.v-btn--has-bg {
    background-color: var(--v-bgPrimary-base) !important;
  }
}
</style>
