<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    label='Job Definition Id'
    data-cy="job-definition-id"
    dense clearable outlined)

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputJobDefinitionId extends Vue {

}
</script>
