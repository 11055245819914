<template lang="pug">
v-list-item(@click='toggleTheme')
  v-list-item-icon
    v-icon {{ icon }}
  v-list-item-title {{ text }}
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import uiModule from '@/store/modules/ui';
import store from '@/store';

const ui = getModule(uiModule, store);

@Component
export default class ThemeToggle extends Vue {
  toggleTheme() {
    ui.toggleTheme();
  }

  get icon() {
    return ui.icon;
  }

  get text() {
    return ui.text;
  }
}
</script>

<style lang="scss" scoped>
.v-icon {
  color: var(--v-secondary-base);
}
</style>
