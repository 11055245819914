<template lang="pug">
div
  // Logical Operator
  v-row( v-if="index > 0" dense)
    v-col(cols="12" md="3")
      ConditionalRulesConditionAndOrInput(
        v-model="logicalOperator"
        :level=1 :index="index" @updateOperator="updateOperator" )

  v-row( dense )
    // Field
    v-col(cols="12" md="3")
      ConditionalRulesConditionFieldInput(
          v-model="condition.field" :datasets="datasets" )

    // Typology
    v-col(cols="12" md="2")
      ConditionalRulesConditionTypologyInput(
          v-model="condition.typology"
          :field="condition.field" )

    // Operator
    v-col(cols="12" md="2")
      ConditionalRulesConditionOperatorInput(
          v-model="condition.operator"
          :typology="condition.typology"
          :field="condition.field" )

    // Value
    v-col(cols="12" md="4")
      ConditionalRulesConditionValueInput(
          v-model="condition.value"
          :datasets="datasets"
          :condition="condition"
          @updateDateTime="updateDateTime" )

    // Remove
    v-col(cols="1")
      v-btn(class="float-right" depressed @click="removeCondition" style="min-width:30px; width: 30px;")
        v-icon(small) icon-trash

</template>

<script lang="ts">
import {
 Vue, Component, Prop, InjectReactive,
} from 'vue-property-decorator';
import * as ConditionalRulesTYPES from '@/@types/conditional-rules';
import { DatasetLightDto } from '@/api';

@Component
export default class ConditionalRulesCondition extends Vue {
  @InjectReactive() datasets!: DatasetLightDto[];

  @Prop({ type: String }) readonly logicalOperator!: ConditionalRulesTYPES.ConditionalRulesColumnOperators;

  @Prop({ type: Object }) readonly condition!: ConditionalRulesTYPES.ConditionalRulesCondition;

  @Prop({ type: Number }) readonly index!: number;

  removeCondition() {
    this.$emit('removeCondition', this.index);
  }

  updateOperator(args: any) {
    this.$emit('updateOperator', args);
  }

  updateDateTime(args: any) {
    this.$emit('updateDateTime', { ...args, conditionIndex: this.index });
  }
}
</script>

<style scoped>

</style>
