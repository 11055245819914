<template lang="pug">
v-text-field(
  full-width
  v-bind="$attrs"
  v-on="$listeners"
  :rules="rules"
  outlined dense
  :placeholder="$t('conditional-rules.put_value')" )
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ConditionalRulesConditionValueInputString extends Vue {
  rules = [
    (v: any) => !!v || 'Value is required',
  ];
}
</script>
