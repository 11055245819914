<template lang="pug">
div
    v-menu( :close-on-content-click="false" )
      template(v-slot:activator="{ on, attrs }")
        v-text-field(
          v-model="date"
          label="Select Date"
          readonly
          outlined
          dense
          :rules="rules"
          v-bind="attrs"
          v-on="on" )
      v-date-picker(
          v-model="date"
          min="1950-01-01"
          flat
          active-picker="false"
        )

    v-menu( :close-on-content-click="false" )
      template( v-slot:activator="{ on, attrs }" )
        v-text-field( v-model="time"
          label="Select Time"
          readonly
          outlined
          dense
          :rules="rules"
          v-bind="attrs"
          v-on="on" )
      v-time-picker( v-model="time" flat active-picker="false" )

</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';

@Component
export default class ConditionalRulesConditionValueInputDateTime extends Vue {
  date = this.$attrs.value ? dayjs(this.$attrs.value).tz().format('YYYY-MM-DD') : '';

  time = this.$attrs.value ? dayjs(this.$attrs.value).tz().format('HH:mm') : '00:00';

  rules = [
    (v: any) => !!v || 'Value is required',
  ];

  @Watch('date')
  @Watch('time')
  updateDateTime() {
    this.$emit('updateDateTime', { dateTime: `${this.date} ${this.time}` });
  }
}
</script>
