/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AsCodePartitionClauseDto = {
    kind?: AsCodePartitionClauseDto.kind;
};

export namespace AsCodePartitionClauseDto {

    export enum kind {
        INGESTION_TIME = 'IngestionTime',
        INTEGER_RANGE = 'IntegerRange',
        TIME_UNIT_COLUMN = 'TimeUnitColumn',
    }


}
