<template lang="pug">
  div
    v-chart( class="chart"
      :option="options"
      :autoresize="true"
      :theme="$vuetify.theme.dark ? 'dark' : 'light'" )
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import VChart from 'vue-echarts';
import { AssetUsageDto } from '@/api';
import dayjs from 'dayjs';

@Component({
  components: {
    VChart,
  },
})
export default class Usage extends Vue {
  @Prop({ type: Object, required: true }) usageData!: AssetUsageDto;

  get usagePerPersons() {
    return this.usageData.usagePerDatasourceUsers.map(
      (usagePErPerson) => ({
        value: usagePErPerson.countRead,
        name: usagePErPerson.userName,
      }),
    );
  }

  get options() {
    return {
      title: {
        text: `Between ${this.toFormatedDate(this.usageData.startDay)} and ${this.toFormatedDate(
          this.usageData.endDay,
        )}`,
      },
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          name: 'Usage per user',
          type: 'pie',
          radius: ['0%', '70%'],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 14,
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
            },
          },
          labelLine: {
            show: true,
          },
          data: this.usagePerPersons,
        },
      ],
    };
  }

  toFormatedDate(aTimestampDay: string) {
    return dayjs(aTimestampDay).tz().format('YYYY-MM-DD');
  }
}
</script>

<style lang="css" scoped>
.chart {
  height: 390px;
}
</style>
