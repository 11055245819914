<template lang="pug">
v-row
  v-col( cols="6")
    InputName( v-model.trim='datasource.name' label='Name' )

  v-col( cols="6")
    InputHost( v-model.trim='datasource.params.host' )

  v-col( cols="6")
    CredentialsSelector( v-model.trim='datasource.secretId' )

  v-col( cols="12")
    InputGitConnections( v-model="datasource.params.gitConnections" )

</template>

<script lang="ts">
import {
  Vue, Component, VModel,
} from 'vue-property-decorator';
import { DatasourceDto, LookerParams, MysqlParams } from '@/api';

type LookerSourceDto = DatasourceDto & { params: LookerParams; };

@Component
export default class MySqlConnection extends Vue {
  @VModel({ type: Object }) datasource!: LookerSourceDto;
}
</script>
