<template lang="pug">

.rule-overview-info-actions.ml-12

  MonitorsYamlModal( ref="MonitorsYamlModalRef" )

  IncidentsForm( ref="incidentsFormRef" )

  MonitorsIncidentLinkForm(
    ref="monitorsIncidentLinkFormRef"
    @onLink="onLinkIncident")

  .d-flex.flex-nowrap( v-if="hasRule" )

    SButton.ml-2(
      :disabled="!canEdit"
      icon="icon-edit"
      :text="editLabel"
      :tooltip="rule.readOnly ? $t('monitors.rule_cant_be_edited') : null"
      @click="editRule"
      color="secondary"
      variant="outlined"
    )

    SButton.ml-2(
      v-if="hasIncident"
      icon="icon-flag"
      :text="$t('monitors.edit.view_incident')"
      @click="viewIncident"
      color="secondary"
      variant="outlined"
    )

    SButton.ml-2(
      :disabled="!canRun"
      icon="icon-play-circle"
      :text="$t('monitors.edit.run')"
      :tooltip="buttonMessage"
      :async-action="manualRun"
      color="primary"
    )

    SThreeDotMenu.ml-2
      SMenuButton(
        @click="copyIDToClipboard"
        icon="icon-copy"
        :text="$t('rules.copy_id')" )

      //- SMenuButton(
      //-   :disabled="!canClone"
      //-   @click="cloneMonitor"
      //-   icon="icon-copy-add"
      //-   :text="$t('rules.clone')" )

      SMenuButton(
        @click="linkIncident"
        data-cy="monitors-result-card-menu-link-incident"
        icon="icon-flag-add"
        :text="$t('monitors.edit.link_existing_incident')")

      SMenuButton(
        @click="createSingleIncident"
        icon="icon-add"
        :text="$t('monitors.create_incident')")

      SMenuButton(
        v-if="canGenerateYaml"
        @click="generateYaml"
        icon="icon-code-brackets"
        :text="$t('monitors.show_as_yaml_code')" )

      Bookmark( :bookmark="bookmark" is-menu )

      SMenuButton(
        :disabled="!canDelete"
        icon="icon-trash"
        :text="$t('monitors.delete')"
        @click="deleteRule"
        color="danger"
        variant="outlined")

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    type="actions"
    width="500" )

</template>

<script lang="ts">
import {
  Vue, Component, VModel, Emit, Ref,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { RuleInfoDto, RuleService, WorkspaceService } from '@/api';
import store from '@/store';
import authModule from '@/store/modules/auth';
import eventBus, { EventType } from '@/utils/eventBus';
import MonitorsYamlModal from '@/components/monitors/Monitors-Yaml-Modal.vue';
import copyToClipboard from '@/utils/copyToClipboard';
import getSettingFromMonitorType from '@/components/monitor-wizard/composables/monitors/monitor-settings';
import type { MonitorSchema } from '@/components/monitor-wizard/composables/monitor-schema';
import IncidentsForm from '@/components/incidents/Incidents-Form.vue';
import MonitorsIncidentLinkForm from '@/components/monitors/MonitorsIncidentLinkForm.vue';

const auth = getModule(authModule, store);

@Component({
  components: { IncidentsForm, MonitorsIncidentLinkForm },
})
export default class RuleOverviewInfoActions extends Vue {
  @VModel({ type: Object }) rule!: RuleInfoDto;

  @Ref() MonitorsYamlModalRef!: MonitorsYamlModal;

  // because can't get type from script setup like this InstanceType<typeof IncidentsForm>;
  // momentary until moved to script setup
  @Ref() incidentsFormRef! : any;

  @Ref() monitorsIncidentLinkFormRef! : any; // = $ref<InstanceType<typeof MonitorsIncidentLinkForm> | null>(null);

  get canDelete() {
    return auth.userActions['monitoring.monitor.write'] && !this.isReadOnly;
  }

  get canEdit() {
    return auth.userActions['monitoring.monitor.write'] && !this.isReadOnly;
  }

  get canRun() {
    return auth.userActions['monitoring.monitor.write'] && this.canManuallyRun && !this.isRunning;
  }

  get editLabel() {
    return this.canEdit ? this.$t('monitors.edit.edit') : this.$t('monitors.rule_cant_be_edited_action');
  }

  get isReadOnly() {
    return this.rule.readOnly;
  }

  get hasIncident() {
    return !!this.rule.lastUnresolvedIncident;
  }

  get canManuallyRun() {
    return this.rule.canManuallyRun;
  }

  get hasRule() {
    return this.rule !== null;
  }

  get isRunning() {
    return this.rule?.lastRunStatus === RuleInfoDto.lastRunStatus.PENDING || this.rule?.lastRunStatus === RuleInfoDto.lastRunStatus.RUNNING;
  }

  get buttonMessage() {
    if (this.isRunning) return this.$t('monitors.rule_is_running');
    if (!this.canManuallyRun) return this.$t('monitors.rule_cant_run');
    return '';
  }

  get hasMessage() {
    return this.buttonMessage !== '';
  }

  get bookmark() {
    return {
      entityId: this.rule.id,
      entityType: 'SIFFLET_RULE',
    };
  }

  get canClone() {
    const canClone = getSettingFromMonitorType(this.rule.ruleTemplateName as MonitorSchema['ruleTemplateName'], 'canClone');
    return canClone !== false;
  }

  get monitorCloneLink() {
    return {
      name: 'monitors.rule.clone',
      params: {
        id: this.rule.id,
      },
    };
  }

  get canGenerateYaml() {
    return this.rule.supportAsCodeYAMLConversion;
  }

  async deleteRule() {
    if (window.confirm('Are you sure you want to delete this rule')) {
      await RuleService.deleteSiffletRuleById({ id: this.rule.id });
      this.$notify({
        text: `Rule ${this.rule.name} has been deleted!`,
      });
      this.$router.push({
        path: '/monitors',
      });
    }
  }

  editRule() {
    this.$router.push({
      path: `/monitors/rule/${this.rule.id}/edit`,
    });
  }

  viewIncident() {
    this.$router.push({
      path: `/incidents/issue/${this.rule.lastUnresolvedIncident!.issueNo}/overview`,
    });
  }

  copyIDToClipboard() {
    copyToClipboard(this.rule.id, `Monitor ID copied to clipboard <br> ${this.rule.id}`);
  }

  cloneMonitor() {
    this.$router.push(this.monitorCloneLink);
  }

  async generateYaml() {
    const rule = await RuleService.getSiffletRuleById({ id: this.rule.id });
    const yamlCode = await WorkspaceService.convertMonitorToCode({ requestBody: rule }) as any;
    this.MonitorsYamlModalRef.setCode(yamlCode);
    this.MonitorsYamlModalRef.open();
  }

  createSingleIncident() {
    this.incidentsFormRef?.handleSingleIncidentCreation(this.rule);
  }

  linkIncident() {
    this.monitorsIncidentLinkFormRef?.handleMonitorsIncidentLinking([this.rule]);
  }

  @Emit('update')
  onLinkIncident() {
    //
  }

  @Emit('action')
  async manualRun() {
    this.$notify({
      text: 'Manual Run has started!',
    });
    await RuleService.siffletRuleManualRun({ id: this.rule.id });
    eventBus.$emit(EventType.REFRESH_RULE_RUNS);
  }
}
</script>
