<template lang="pug">

.datasource-overview-info-actions

  .d-flex.flex-column.flex-nowrap( v-if="hasDatasource" )

    b {{ $t('sources.last_run') }}
    DateTooltip( v-if="hasLastRun" :value="lastRun" )
    .grey--text( v-else ) {{ $t('sources.none') }}

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    width="145"
    type="list-item-two-line" )

</template>

<script lang="ts">
import { Vue, Component, VModel } from 'vue-property-decorator';
import { DatasourceDto } from '@/api';

@Component
export default class DatasourceOverviewInfoLastRun extends Vue {
  @VModel({ type: Object }) datasource!: DatasourceDto;

  get hasDatasource() {
    return this.datasource !== null;
  }

  get hasLastRun() {
    return this.lastRun !== null;
  }

  get lastRun() {
    return this.datasource?.lastIngestionStatus ? this.datasource?.lastIngestionStatus.timestamp : null;
  }
}
</script>
