import {
  Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import Vue from 'vue';
import {
 CreateUserUiRequestDto, UpdateUserUiRequestDto, UserService, UserUiDto, UsersService,
} from '@/api';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import i18n from '@/i18n';
import type ConfirmationDialog from '@/components/confirmation-dialog/Confirmation-Dialog.vue';

let getAllUsers: ReturnType<typeof UserService.getAllUsers> | null = null;

@Module({ namespaced: true, name: 'users' })
export default class UsersModule extends VuexModule {
  loading = false;

  editedUser: UserUiDto = {
    createdBy: '',
    domains: [],
    email: '',
    id: '',
    isActive: false,
    lastLoginDate: '',
    name: '',
    resetPassword: false,
    role: UserUiDto.role.SYSTEM_VIEWER,
    timeZoneData: {
      timezone: '',
      utcOffset: '',
    },
  };

  users: UserUiDto[] = [];

  selectedUsers: UserUiDto[] = [];

  count = 0;

  searchString = '';

  modalDialogRef: ModalDialog | null = null;

  confirmationDialog: ConfirmationDialog | null = null;

  userForm: HTMLFormElement | null = null;

  editedUserPassword = '';

  newUserPassword = '';

  get selectedUsersCount() {
    return this.selectedUsers.length;
  }

  get selectedUser() {
    return this.selectedUsers[0];
  }

  get modifyExistingUser() {
    return this.editedUser.id !== '';
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  setEditedUser(user: UserUiDto) {
    this.editedUser = user;
  }

  @Mutation
  setUsers(users: UserUiDto[]) {
    this.users = users;
  }

  @Mutation
  setCount(count: number) {
    this.count = count;
  }

  @Mutation
  setSelectedUsers(users: UserUiDto[]) {
    this.selectedUsers = users;
  }

  @Mutation
  setSearchString(searchString: string) {
    this.searchString = searchString;
  }

  @Mutation
  setModal(dialog: ModalDialog | null) {
    this.modalDialogRef = dialog;
  }

  @Mutation
  setConfirmationDialog(dialog: ConfirmationDialog | null) {
    this.confirmationDialog = dialog;
  }

  @Mutation
  setUserForm(form: HTMLFormElement | null) {
    this.userForm = form;
  }

  @Mutation
  setNewUserPassword(password: string) {
    this.newUserPassword = password;
  }

  @Mutation
  setEditedUserPassword(password: string) {
    this.editedUserPassword = password;
  }

  @Mutation
  initEditedUser() {
    this.editedUser = {
      createdBy: '',
      domains: [],
      email: '',
      id: '',
      isActive: false,
      lastLoginDate: '',
      name: '',
      resetPassword: false,
      role: UserUiDto.role.SYSTEM_VIEWER,
      timeZoneData: {
        timezone: '',
        utcOffset: '',
      },
    };
  }

  @Mutation
  filterBySearchString() {
    if (this.searchString === '') {
      this.setUsers(this.users);
      return;
    }
    const filteredUsers = this.users.filter((user) => user.name.toLowerCase().includes(this.searchString.toLowerCase()));
    this.setUsers(filteredUsers);
  }

  @Action
  addNewUser() {
    this.setSelectedUsers([]);
    this.userForm?.reset();
    this.setEditedUserPassword('');
    this.initEditedUser();
    this.modalDialogRef?.openDialog();
  }

  @Action
  async editSingleUser(id: UserUiDto['id']) {
    const user = await UserService.getUserById({ id });
    this.setEditedUser(user);
    this.modalDialogRef?.openDialog();
  }

  @Action
  async searchUsers() {
    if (this.loading && getAllUsers) getAllUsers.cancel();
    getAllUsers = UserService.getAllUsers();
    this.setLoading(true);
    const { data, totalElements } = await getAllUsers;
    this.setCount(totalElements!);
    this.setUsers(data!);
    this.setLoading(false);
  }

  @Action
  async deleteSingleUser(user: UserUiDto) {
    const { name, id } = user;
    if (this.confirmationDialog) {
      try {
        await this.confirmationDialog
          .setType('delete')
          .setConfirmButtonText(i18n.t('users.reset_password_generate_password'))
          .setTitle(i18n.t('users.delete_user', { user: name }))
          .setBody(i18n.tc('users.delete_user_confirm', 1))
          .waitForUserAction();

        await UserService.deleteUser({ id });
        Vue.notify({
          text: i18n.t('users.delete_users_success', { name }),
        });
        this.searchUsers();
        this.setSelectedUsers(this.selectedUsers.filter((selectedUser) => selectedUser.id !== id));
      } catch (error) {
        if (error instanceof Error) {
          Vue.notify({
            text: error.message,
          });
        }
      }
    }
  }

  @Action
  async deleteMultipleUsers() {
    if (this.confirmationDialog) {
      try {
        await this.confirmationDialog
          ?.setType('delete')
          .setConfirmButtonText(i18n.t('users.reset_password_generate_password'))
          .setTitle(i18n.t('users.delete_user_many'))
          .setBody(i18n.tc('users.delete_user_confirm', this.selectedUsersCount))
          .waitForUserAction();
        await Promise.all(this.selectedUsers.map((user) => UserService.deleteUser({ id: user.id })));
        Vue.notify({
          text: i18n.tc('users.delete_users_success_many', this.selectedUsers.length),
        });
        this.searchUsers();
        this.setSelectedUsers([]);
      } catch (error) {
        if (error instanceof Error) {
          Vue.notify({
            text: error.message,
          });
        }
      }
    }
  }

  @Action
  async deleteUsers() {
    this.selectedUsers.length === 1 ? this.deleteSingleUser(this.selectedUsers[0]) : this.deleteMultipleUsers();
  }

  @Action
  async enableUser(user: UserUiDto) {
    const requestBody: UpdateUserUiRequestDto = {
      ...user,
      isActive: true,
    };
    await UserService.updateUserUi({
      id: user.id,
      requestBody,
    }).then(() => {
      Vue.notify({
        text: i18n.t('users.enable_user_success'),
      });
      this.searchUsers();
    });
  }

  @Action
  async enableUsers() {
    const usersToEnable = this.selectedUsers.map((user) => ({
      ...user,
      isActive: true,
    }));
    await Promise.all(usersToEnable.map((user) => UserService.updateUserUi({
      id: user.id,
      requestBody: user,
    })));
    Vue.notify({
      text: i18n.tc('users.enable_user_success_many', this.selectedUsersCount),
    });
    this.searchUsers();
    this.setSelectedUsers([]);
  }

  @Action
  async disableSingleUser(user: UserUiDto) {
    const requestBody: UpdateUserUiRequestDto = {
      ...user,
      isActive: false,
    };
    if (this.confirmationDialog) {
      try {
        await this.confirmationDialog
          .setType('disable')
          .setConfirmButtonText(i18n.t('users.reset_password_generate_password'))
          .setTitle(i18n.t('users.disable_user', { user: user.name }))
          .setBody(i18n.tc('users.disable_user_confirm', 1))
          .waitForUserAction();

          await UserService.updateUserUi({
              id: user.id,
              requestBody,
            });
            Vue.notify({
              text: i18n.tc('users.disable_user_success'),
            });
              this.searchUsers();
              this.setSelectedUsers([]);
      } catch (error) {
        if (error instanceof Error) {
          Vue.notify({
            text: error.message,
          });
        }
      }
    }
  }

  @Action
  async disableMultipleUsers() {
    const usersToDisable = this.selectedUsers.map((user) => ({
      ...user,
      isActive: false,
    }));
    if (this.confirmationDialog) {
      try {
        await this.confirmationDialog.setType('disable')
          .setConfirmButtonText(i18n.t('users.reset_password_generate_password'))
          .setTitle(i18n.t('users.disable_user_many'))
          .setBody(i18n.tc('users.disable_user_confirm', this.selectedUsersCount))
          .waitForUserAction();
        await Promise.all(usersToDisable.map((user) => UserService.updateUserUi({
          id: user.id,
          requestBody: user,
        })));
        Vue.notify({
          text: i18n.tc('users.disable_users_success_many', this.selectedUsersCount),
        });
        this.searchUsers();
        this.setSelectedUsers([]);
      } catch (error) {
        if (error instanceof Error) {
          Vue.notify({
            text: error.message,
          });
        }
      }
    }
  }

  @Action
  async disableUsers() {
    this.selectedUsers.length === 1 ? this.disableSingleUser(this.selectedUsers[0]) : this.disableMultipleUsers();
  }

  @Action
  updateUser() {
    const requestBody: UpdateUserUiRequestDto = {
      domains: this.editedUser.domains,
      name: this.editedUser.name,
      role: this.editedUser.role,
      isActive: this.editedUser.isActive,
    };
    return UserService.updateUserUi({ id: this.editedUser.id, requestBody });
  }

  @Action
  createUser() {
    const requestBody: CreateUserUiRequestDto = {
      domains: this.editedUser.domains,
      email: this.editedUser.email,
      name: this.editedUser.name,
      password: this.editedUserPassword,
      role: this.editedUser.role,
    };
    return UserService.createUserUi({ requestBody });
  }

  @Action
  async resetPassword(value?: UserUiDto | null) {
    const user = value || this.selectedUser;
    const { name, id } = user;
      try {
        await this.confirmationDialog
          ?.setType('confirm')
          .setConfirmButtonText(i18n.t('users.reset_password_generate_password'))
          .setTitle(i18n.t('users.reset_password_title', { name }))
          .setBody(i18n.t('users.reset_password_body'))
          .waitForUserAction(true);

        const { password } = await UsersService.publicResetUserPassword({ id });
        this.setNewUserPassword(password);

        await this.confirmationDialog
          ?.setType('confirm')
          .setConfirmButtonText(i18n.t('users.done'))
          .setTitle(i18n.t('users.reset_password_title', { name }))
          .setBody(i18n.t('users.reset_password_body'))
          .waitForUserAction();
      } catch (error) {
        this.setNewUserPassword('');
      } finally {
        this.setNewUserPassword('');
      }
  }
}
