export default function makeSafeForCSS(inputString: string | number): string {
  const uniqueSpecialChars: string[] = [];
  let uniqueCounter = 1;
  const input = String(inputString);
  const replacedString = input.replace(/\W/g, (match) => {
    const index = uniqueSpecialChars.indexOf(match);
    if (index === -1) {
      uniqueSpecialChars.push(match);
      return `__${uniqueCounter += 1}`;
    }
    return `__${index + 1}`;
  });

  return replacedString.replace(/^\d/, '__1');
}
