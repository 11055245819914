<template lang="pug">

.d-flex.flex-column

  .d-flex.align-start.mt-1( v-if="hasMatchedFieldNames" )
      v-icon( small ).mr-1 icon-data-view
      HtmlView.caption.grey--text( :html="matchedFieldNames" )

  .d-flex.align-start.mt-1( v-if="matchedFieldDescriptions" )
      v-icon( small ).mr-1 icon-data-view
      HtmlView.caption.grey--text( :html="matchedFieldDescriptions" )

</template>

<script lang="ts">
import { SearchCollectionFieldLevelSearchFieldDto } from '@/api';
import {
  Vue, Component, Prop, VModel,
} from 'vue-property-decorator';
import highlight from '@/utils/filters/highlight';

@Component
export default class FieldsSearchMatch extends Vue {
  @VModel({ type: Object }) fields!: SearchCollectionFieldLevelSearchFieldDto;

  @Prop({ type: String, default: '' }) search!: string;

  matchedFieldNames = '';

  matchedFieldDescriptions = '';

  setMatchedFieldNames() {
    if (!this.fields.totalElements) {
      return;
    }

    const data = this.fields.data || [];

    let matchedFieldNames = this.$tc('data-catalog.matches_field', this.fields.totalElements!);
    let matchedFieldDescriptions = '';

    data?.forEach((field) => {
      matchedFieldNames += ` <b>${highlight(field.name, this.search || '')}</b>`;

      if (field !== data[data.length - 1]) {
        matchedFieldNames += ` ${this.$t('data-catalog.and')}`;
      }

      // find if search matches field description
      if (field.description && field.description.toLowerCase().includes(this.search.toLowerCase())) {
        const highlightedName = `<b>${highlight(field.name, this.search || '')}</b>`;
        const highlightedDescription = highlight(field.description, this.search || '');
        matchedFieldDescriptions = `${this.$tc('data-catalog.matches_field', 1)} ${highlightedName} ${this.$t('data-catalog.description')} “${highlightedDescription}”`;
      } else if (field.externalDescriptions?.some((externalDescription) => externalDescription.description?.toLowerCase().includes(this.search.toLowerCase()))) {
        // find if search matches on of the external description
        const highlightedName = `<b>${highlight(field.name, this.search || '')}</b>`;
        const highlightedDescription = highlight(field.externalDescriptions?.find((externalDescription) => externalDescription.description?.toLowerCase().includes(this.search.toLowerCase()))?.description || '', this.search || '');
        matchedFieldDescriptions = `${this.$tc('data-catalog.matches_field', 1)} ${highlightedName} ${this.$t('data-catalog.description')} “${highlightedDescription}”`;
      }
    });

    if (this.fields.totalElements! > 2) {
      matchedFieldNames += ` and ${this.fields.totalElements! - 2} more`;
    }

    this.matchedFieldNames = matchedFieldNames;
    this.matchedFieldDescriptions = matchedFieldDescriptions;
  }

  get hasMatchedFieldNames() {
    // we don't need to show the field names if we have a match on the field description
    return this.matchedFieldNames && !this.matchedFieldDescriptions;
  }

  mounted() {
    this.setMatchedFieldNames();
  }
}
</script>
