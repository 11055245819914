<template lang="pug">
v-textarea(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="descriptionRules"
    label='Description'
    counter='counter'
    data-cy="description"
    dense outlined)

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { Inputs } from '@/@types/types';

@Component
export default class InputHost extends Vue {
  counter = Inputs.INPUT_HIGH_CHARS;

  descriptionRules = [
    (v: string) => {
      if (v) return v.length <= this.counter || `Max ${this.counter} characters`;
      return true;
    },
  ];
}
</script>
