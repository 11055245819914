/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetListDto } from '../models/AssetListDto';
import type { SearchCollectionUserDto } from '../models/SearchCollectionUserDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OwnerService {

    /**
     * Get all possible owners
     * @returns SearchCollectionUserDto List of all possible Owners
     * @throws ApiError
     */
    public static getAllPossibleOwnersUi({
        requestBody,
    }: {
        requestBody: AssetListDto,
    }): CancelablePromise<SearchCollectionUserDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/users/owners',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
