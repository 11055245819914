/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CalendarTimeslot } from './CalendarTimeslot';

export type PublicCalendarGetDto = {
    description?: string | null;
    editable?: boolean;
    id: string;
    name: string;
    standardCalendar?: PublicCalendarGetDto.standardCalendar | null;
    timeslots: Array<CalendarTimeslot>;
};

export namespace PublicCalendarGetDto {

    export enum standardCalendar {
        US_PUBLIC_HOLIDAYS = 'US_PUBLIC_HOLIDAYS',
        FRANCE_PUBLIC_HOLIDAYS = 'FRANCE_PUBLIC_HOLIDAYS',
        UK_PUBLIC_HOLIDAYS = 'UK_PUBLIC_HOLIDAYS',
        BELGIUM_PUBLIC_HOLIDAYS = 'BELGIUM_PUBLIC_HOLIDAYS',
        SPAIN_PUBLIC_HOLIDAYS = 'SPAIN_PUBLIC_HOLIDAYS',
        GERMANY_PUBLIC_HOLIDAYS = 'GERMANY_PUBLIC_HOLIDAYS',
        NETHERLANDS_PUBLIC_HOLIDAYS = 'NETHERLANDS_PUBLIC_HOLIDAYS',
        SUNDAYS = 'SUNDAYS',
        WEEKENDS = 'WEEKENDS',
    }


}
