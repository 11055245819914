/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GitConnection = {
    authType: GitConnection.authType;
    branch?: string;
    secretId: string;
    url: string;
};

export namespace GitConnection {

    export enum authType {
        HTTP_AUTHORIZATION_HEADER = 'HTTP_AUTHORIZATION_HEADER',
        USER_PASSWORD = 'USER_PASSWORD',
        SSH = 'SSH',
    }


}
