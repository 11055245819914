import * as d3 from 'd3';
import lineageModule from '@/store/modules/lineage';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';

const highlightConnectedNeighbors = (event: any, connectedElementsUrnData: string[]) => {
  const _store = getModule(lineageModule, store);
  if (event.ctrlKey || _store.lineageState.highlight) {
    const cssSelectorExp = connectedElementsUrnData
      .map((urn) => `g[data-urn="${urn}"] [highlightable="true"]`)
      .join(', ');
    d3.selectAll('g[data-urn] [highlightable="true"]').classed('lowlighted', true);
    d3.selectAll(cssSelectorExp).classed('highlighted', true).classed('lowlighted', false);
  }
};

export default highlightConnectedNeighbors;
