<template lang="pug">
v-list-item-content( v-if="dataset" )
  .d-flex.flex-grow-1.flex-shrink-0( style="width: 100%" )
    PositionDot( :index="index" class="mr-1" )
    v-avatar(tile size="20" class="mr-1")
      img( :src="image(dataset.datasourceType)" :alt="dataset.datasourceType" )
    .text-truncate.mr-1 {{ dataset.name }}
</template>

<script lang="ts">
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';
import {
  DatasetLightDto,
  RuleDto,
} from '@/api';
import src from '@/utils/filters/src';

@Component
export default class ConditionalRulesDataset extends Vue {
  @Prop({ type: Object, required: true }) dataset!: DatasetLightDto;

  @Prop({ type: Array, required: true }) datasets!: RuleDto['datasets'];

  get index() {
    return this.datasets!.findIndex((id) => id === this.dataset);
  }

  image(datasourceType: any) {
    return src(datasourceType, 'datasources');
  }
}
</script>

<style scoped>

</style>
