<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { watch } from 'vue';
import { Vue } from 'vue-property-decorator';
import {
  type LinkedIncidentRulesDto,
  IncidentLightDto,
  IncidentService,
  RuleCatalogAssetDtoV2,
} from '@/api';
import i18n from '@/i18n';
import SModal from '@/components/SModal.vue';

let processing = _ref(false);
let selectedIncident = _ref<IncidentLightDto | null>(null);
let fetchedIncidents = _ref<IncidentLightDto[]>([]);
const fetchedIncidentsSelectable = _computed(() => fetchedIncidents.value.map((incident) => ({
  text: incident.name,
  value: incident,
})));
let linkableMonitors: RuleCatalogAssetDtoV2[] = _ref([]);
const modal = _ref<InstanceType<typeof SModal> | null>(null);
let textSearch = _ref('');
const ruleSelectedIncident = (value: IncidentLightDto) => !!value;
const isValid = _ref(false);

type MonitorsIncidentLinkFormEmits = {
  (event: 'onLink'): void,
  (event: 'onLinkError'): void,
}
const emit = defineEmits(["onLink", "onLinkError"]);

/**
 * Fetch incidents,
 * with optional textSearch provided by autocomplete
 */
type IncidentSearchParams = {
  textSearch?: string,
}
const fetchIncidents = (searchParams: IncidentSearchParams = {}) => IncidentService.getAllIncident({ requestBody: searchParams })
  .then((response) => {
    fetchedIncidents.value = response.searchIncidents.data;
});

/**
 * Watch changes on searched value, and trigger API call
 */
watch(() => textSearch.value, () => {
  fetchIncidents({
    textSearch: textSearch.value,
  });
});

/**
 * Handle form closing
 */
 const handleCancel = () => {
  modal.value?.closeModal();
};

/**
 * Reset form values
 */
const resetForm = () => {
  selectedIncident.value = null;
  textSearch.value = '';
};

/**
 * Link creation success handler
 */
const createLinkSuccess = (result: LinkedIncidentRulesDto) => {
  modal.value?.closeModal();
  if (linkableMonitors.value.length && selectedIncident.value) {
    const mainText = result.linkedRules.length ? i18n.tc('monitors.edit.link_incident_success_text', result.linkedRules.length, { incident: selectedIncident.value.name }) : '';
    const nonLinkedNotice = result.nonLinkedRules.length ? i18n.t('monitors.edit.link_incident_success_nonlinked_notice') : '';
    const text = [mainText, nonLinkedNotice].filter(Boolean).join('<br />');

    Vue.notify({
      title: i18n.t('monitors.edit.link_incident_success_title'),
      text,
      type: 'success',
    });
    emit('onLink');
  }
  resetForm();
};

/**
 * Link creation error handler
 */
const createLinkError = (error: any) => {
  Vue.notify({
    title: i18n.t('monitors.edit.link_incident_error_title'),
    text: i18n.t('monitors.edit.link_incident_error_text'),
    type: 'error',
  });
  emit('onLinkError');
};

/**
 * Link creation submission
 */
const handleCreateLink = () => {
  // Make sure linkable monitor & selected incident are set, for TS validation...
  if (linkableMonitors.value.length && selectedIncident.value) {
    processing.value = true;
    IncidentService.linkRulesToIncident({
      id: selectedIncident.value.id,
      requestBody: {
        ruleIds: linkableMonitors.value.map((monitor) => monitor.id),
      },
    })
    .then((result: LinkedIncidentRulesDto) => {
      createLinkSuccess(result);
    })
    .catch((error) => {
      createLinkError(error);
    })
    .finally(() => {
      processing.value = false;
    });
  }
  //
};

/**
 * Handle form opening.
 * Publicly exposed method.
 * @param monitors monitors to be linked to incident
 */
const handleMonitorsIncidentLinking = (monitors: RuleCatalogAssetDtoV2[]) => {
  resetForm();
  linkableMonitors.value = monitors;
  fetchIncidents();
  modal.value?.openModal();
};

defineExpose({
  handleMonitorsIncidentLinking,
});

</script>

<template lang="pug">
div
  v-form(
    ref='form'
    v-model='isValid'
  )
    SModal(
    ref="modal"
    :title="$tc('monitors.edit.link_existing_incident_form_title', linkableMonitors.length)"
    :on-save="handleCreateLink"
    :on-cancel="handleCancel"
    :on-close="handleCancel"
    :can-save="isValid && !processing"
    remove-dividers
    width="600"
    data-cy="monitors-incident-link-form-modal-content")
      v-autocomplete(
        v-model="selectedIncident"
        :search-input.sync="textSearch"
        :items="fetchedIncidentsSelectable"
        :loading="false"
        :label="$t('common.names.incidents')"
        :multiple="false"
        :closable-chips="false"
        :clearable="true"
        :rules="[ruleSelectedIncident]"
        hide-no-data
        hide-details
        outlined
        dense
        data-cy-input="incident-autocomplete")

        template(#item="{ item }")
          .d-flex(data-cy="incident-item")
            v-icon.mr-1( small color="iconDanger" ) icon-flag
            span {{item.text }}

        template(#selection)
          .d-flex
            v-icon.mr-1( small color="iconDanger" ) icon-flag
            span {{selectedIncident?.name}}
</template>
