/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UserPermissionAssignmentDto = {
    id: string;
    role: UserPermissionAssignmentDto.role;
};

export namespace UserPermissionAssignmentDto {

    export enum role {
        EDITOR = 'EDITOR',
        VIEWER = 'VIEWER',
    }


}
