import * as d3 from 'd3';
import colors from '@/plugins/colors';
import { getProviderIconSrc } from '@/utils/provider-icons';
import Node from './Node';
import * as CONSTANTS from './constants';
import Lineage, { InteractionEvents } from './Lineage';
import lineageCreateQuality from './lineage-create-quality';
import lineageCreateLockIcons from './lineage-create-lock-icon';
import { lineageCreateShowFieldsIcons, lineageCreateShowFieldsIconsUpdate } from './lineage-create-show-fields-icon';
import lineageCreatePlusIcons from './lineage-create-plus-icons';
import highlightConnectedNeighbors from './highlightConnectedNeighbors';
import resetHighlighting from './resetHighlighting';
import cardTitle from './cardTitle';

const lineageCreateNodes = (
  cardsGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  data: Node[],
  instance: Lineage,
) => {
  const node = cardsGroup.selectAll('.card').data(data, (d: any) => d.id);

  const card = node
    .enter()
    .append('g')
    .attr('class', 'card')
    .attr('data-urn', (d) => d.data.urn)
    .attr('data-entity-type', (d) => d.data.entityType)
    .attr('data-type', (d) => d.data.type)
    .attr('transform', (d) => `translate(${d.location.x}, ${d.location.y})`);

  card.append('title').text((d) => cardTitle(d.data));

  card
    .attr('opacity', 0)
    .transition()
    .duration(CONSTANTS.ANIMATION_DURATION)
    .ease(d3.easeLinear)
    .attr('opacity', 1);

  const cardBody = card
    .append('g')
    .attr('class', 'card_body')
    .attr('highlightable', true)
    .attr('cursor', (d) => (d.data.hasInformation ? 'pointer' : 'default'))
    .on('mouseenter', (event, _node: Node) => {
      highlightConnectedNeighbors(event, [
        ..._node.getDownstreamElementUrns(),
        ..._node.getUpstreamElementUrns(),
      ]);
    })
    .on('mouseleave', () => resetHighlighting())
    .on('click', (e: Event, d) => {
      if (!d.data.hasInformation) return;
      e.stopPropagation();
      instance.emit(InteractionEvents.INFO_CLICKED, d);
      instance.resetAllSelectedNodeCssClasses();
      const selectedNode = cardBody.filter((svg) => svg.data.urn === d.data.urn);
      instance.setSelectedNodeCssClass(selectedNode);
    });

  cardBody
    .append('rect')
    .attr('class', 'card_body_rect')
    .attr('width', CONSTANTS.DEFAULT_NODE_WIDTH)
    .attr('height', CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT)
    .attr('highlightable', true)
    .attr('stroke', (_node: Node) => (_node.isStartNode ? colors.grey.darken4 : colors.grey.lighten3))
    .attr('stroke-width', (_node: Node) => (_node.isStartNode ? '1' : '1'))
    .attr('fill', '#FFFFFF')
    .attr('transform', (d) => (d.data.attachedEntity
      ? `translate(0, ${CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT})`
      : 'translate(0, 0)'))
    .attr('cursor', (d) => (d.data.hasInformation ? 'pointer' : 'default'));

  cardBody.each(function test(_node: Node) {
    const body = d3.select(this);
    const { data: _data } = _node;

    if (_data.attachedEntity) {
      body
        .append('rect')
        .attr('class', 'card_body_rect')
        .attr('width', CONSTANTS.DEFAULT_NODE_WIDTH)
        .attr('height', CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT)
        .attr('highlightable', true)
        .attr('stroke', _node.isStartNode ? colors.grey.darken4 : colors.grey.lighten2)
        .attr('stroke-width', _node.isStartNode ? '1' : '1')
        .attr('fill', '#FFFFFF');

      body
        .append('image')
        .attr('class', 'platform_image')
        .attr(
          'href',
          `/next/images/datasources/light/${_data.attachedEntity?.datasourceType.toLowerCase()}.svg`,
        )
        .attr('width', '24')
        .attr('height', '24')
        .attr('transform', 'translate(14, 14)');

      body
        .append('text')
        .attr('class', 'text_title sans-serif')
        .text(_data.attachedEntity?.name.replace(/(.{17})..+/, '$1…'))
        .attr('transform', 'translate(52, 44)')
        .attr('font-size', '14px')
        .attr('line-height', '20px')
        .attr('fill', colors.grey.darken4)
        .attr('font-weight', 500);

      body
        .append('text')
        .attr('class', 'text_data_type sans-serif')
        .text(_data.attachedEntity.type)
        .attr('transform', 'translate(52, 24)')
        .attr('font-size', '10px')
        .attr('line-height', '12px')
        .attr('fill', colors.grey.darken1)
        .attr('font-weight', 700);
    }
  });

  lineageCreateQuality(cardBody);
  lineageCreateLockIcons(cardBody);
  lineageCreateShowFieldsIcons(cardBody, instance);

  cardBody
    .append('text')
    .attr('class', 'text_title sans-serif')
    .text((d) => d.data.title.replace(/(.{17})..+/, '$1…'))
    .attr('transform', (d) => (d.data.attachedEntity
      ? `translate(52, ${CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT + 44})`
      : 'translate(52, 44)'))
    .attr('font-size', '14px')
    .attr('line-height', '20px')
    .attr('fill', colors.grey.darken4)
    .attr('font-weight', 500);

  cardBody
    .append('text')
    .attr('class', 'text_data_type sans-serif')
    .text((d) => (!d.data.qualifiedNamePrefix ? d.data.type : `${d.data.type} - ${d.data.qualifiedNamePrefix}`).toUpperCase().replace(/(.{28})..+/, '$1…'))
    .attr('transform', (d) => (d.data.attachedEntity
      ? `translate(52, ${CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT + 24})`
      : 'translate(52, 24)'))
    .attr('font-size', '10px')
    .attr('line-height', '12px')
    .attr('fill', colors.grey.darken1)
    .attr('font-weight', 700);

  cardBody
    .append('image')
    .attr('class', 'upstream_info_image')
    .attr('visibility', (d) => (d.data.upstreams.length ? 'visible' : 'hidden'))
    .attr('opacity', (d) => (d.allUpstreamNodesWereDeployed() ? 0.2 : 1))
    .attr('href', '/next/icons/svg/arrow-left.svg')
    .attr('width', '14')
    .attr('height', '14')
    .attr('transform', (d) => (d.data.attachedEntity
      ? `translate(52, ${CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT + 85})`
      : 'translate(52, 85)'));

  cardBody
    .append('image')
    .attr('class', 'downstream_info_image')
    .attr('visibility', (d) => (d.data.downstreams.length ? 'visible' : 'hidden'))
    .attr('opacity', (d) => (d.allDownstreamNodesWereDeployed() ? 0.2 : 1))
    .attr('href', '/next/icons/svg/arrow-right.svg')
    .attr('width', '14')
    .attr('height', '14')
    .attr('transform', (d) => (d.data.attachedEntity
      ? `translate(90, ${CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT + 85})`
      : 'translate(90, 85)'));

  cardBody
    .append('text')
    .attr('class', 'upstream_info_text')
    .attr('visibility', (d) => (d.data.upstreams.length ? 'visible' : 'hidden'))
    .attr('opacity', (d) => (d.allUpstreamNodesWereDeployed() ? 0.2 : 1))
    .attr('fill', colors.grey.base)
    .text((d) => d.data.upstreams.length)
    .attr('font-size', '12px')
    .attr('font-weight', 'bold')
    .attr('transform', (d) => (d.data.attachedEntity
      ? `translate(72, ${CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT + 96})`
      : 'translate(72, 96)'));

  cardBody
    .append('text')
    .attr('class', 'downstream_info_text')
    .attr('visibility', (d) => (d.data.downstreams.length ? 'visible' : 'hidden'))
    .attr('opacity', (d) => (d.allDownstreamNodesWereDeployed() ? 0.2 : 1))
    .attr('fill', colors.grey.base)
    .text((d) => d.data.downstreams.length)
    .attr('font-size', '12px')
    .attr('font-weight', 'bold')
    .attr('transform', (d) => (d.data.attachedEntity
      ? `translate(108, ${CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT + 96})`
      : 'translate(108, 96)'));

  cardBody
    .append('image')
    .attr('class', 'platform_image')
    .attr('href', (d) => getProviderIconSrc(d.data.platformEnum))
    .attr('width', (d) => (d.data.attachedEntity ? '24' : '32'))
    .attr('height', (d) => (d.data.attachedEntity ? '24' : '32'))
    .attr('transform', (d) => (d.data.attachedEntity
      ? `translate(14, ${CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT + 14})`
      : 'translate(14, 14)'));

  lineageCreatePlusIcons(cardBody, instance);

  node.transition().attr('transform', (d) => `translate(${d.location.x}, ${d.location.y})`);

  node
    .select('.plus-downstreams')
    .attr('visibility', (d) => (d.allDownstreamNodesWereDeployed() ? 'hidden' : 'visible'));

  node
    .select('.plus-upstreams')
    .attr('visibility', (d) => (d.allUpstreamNodesWereDeployed() ? 'hidden' : 'visible'));

  node
    .select('.upstream_info_image')
    .attr('opacity', (d) => (d.allUpstreamNodesWereDeployed() ? 0.2 : 1));

  node
    .select('.downstream_info_image')
    .attr('opacity', (d) => (d.allDownstreamNodesWereDeployed() ? 0.2 : 1));

  node
    .select('.upstream_info_text')
    .attr('opacity', (d) => (d.allUpstreamNodesWereDeployed() ? 0.2 : 1));

  node
    .select('.downstream_info_text')
    .attr('opacity', (d) => (d.allDownstreamNodesWereDeployed() ? 0.2 : 1));

  lineageCreateShowFieldsIconsUpdate(node);

  node.exit().remove();
};

export default lineageCreateNodes;
