/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LogDto = {
    level?: LogDto.level;
    message?: string;
};

export namespace LogDto {

    export enum level {
        INFO = 'Info',
        WARNING = 'Warning',
        ERROR = 'Error',
        FATAL = 'Fatal',
    }


}
