<template lang="pug">
.d-flex.access-denied.justify-center.align-center.flex-column
  .circle
    v-icon.tertiary--text( size="24" medium color="iconNeutral") icon-lock-closed
  b.mt-2.block {{ $t('assets.access_denied_title') }}
  span.grey--text.mt-2.text-center( style="max-width: 350px;" ) {{ $t('assets.access_denied_message') }}
  span.caption.mt-2.grey--text.text-center( v-if="hasDomains" style="max-width: 350px;" ) {{ $t('assets.domains_with_access', { domains }) }}

</template>

<script lang="ts">
import { UnauthorizedAssetHeaderDto } from '@/api';
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';

@Component
export default class AccessDenied extends Vue {
  @Prop({ type: Array, required: true }) associatedDomainsNames!: UnauthorizedAssetHeaderDto['associatedDomainsNames'];

  get hasDomains() {
    return this.associatedDomainsNames.length;
  }

  get domains() {
    return this.associatedDomainsNames.join(', ');
  }
}
</script>

<style lang="scss">
.access-denied {
  height: calc(100vh - 190px);

  &-center {
    width: 300px;
    height: 300px;
  }

  .circle {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--v-bgTertiary-base);
  }
}
</style>
