<template lang="pug">
v-container( fluid )
  v-row
    v-col.mx-0.px-0( v-if="hasBookmarks && !loading" cols="12" )
      v-tabs( :value="activeTab")
        v-tab(
          v-for="bookmarkType in bookmarkTypes"
          :key="bookmarkType.id"
          @click="selectType(bookmarkType.id)"
        ) {{ bookmarkType.name }}
      v-divider
      .mb-3
  v-row()
    v-col.mx-0.px-0( cols="12" )
      v-row( align="start" justify="start" style="margin: -20px" )
        v-slide-group.mx-0.px-0(multiple show-arrows="always" v-if="hasBookmarks && !loading")
          v-slide-item( v-for="bookmark in filteredBookmarks" :key="bookmark.entityId" )
            DashboardPinnedCard.mr-3(
              :tile="true"
              :title="bookmark.value.name"
              :image="image(bookmark)"
              :icon="icon(bookmark)"
              :link="link(bookmark)"
            )
              template(v-slot:subtitle1-content)
                span {{subtitle1(bookmark)}}
              template(v-slot:subtitle2-content)
                span.break-spaces {{ subtitle2(bookmark) }}
              template(v-slot:card-status)
                v-icon( color="iconDanger") arrow_right
        v-skeleton-loader(
          v-else-if="loading"
          class="v-skeleton-loader-override"
          type="list-item-three-line"
          width="500"
          style="margin: 20px")
        NoDataAvailablePlaceholder( v-else :text="$t('dashboard.no_pinned')" height="20vh")
</template>

<script lang="ts">
// eslint-disable-next-line
// @ts-nocheck
import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';
import {
  AssetDto,
  AssetHeaderDto,
  AssetService,
  IncidentService,
  IssueDetailsDto,
  RuleInfoDto,
  RuleService,
  UserAssetBookmarkDto,
  UserService,
} from '@/api';
import fromNow from '@/utils/filters/fromNow';
import ProviderIcons, { getProviderIconSrc } from '@/utils/provider-icons';

type AssetBookmark = UserAssetBookmarkDto & { value: AssetDto };
type RuleBookmark = UserAssetBookmarkDto & { value: RuleInfoDto };
type IssueBookmark = UserAssetBookmarkDto & { value: IssueDetailsDto };
type NoBookmark = UserAssetBookmarkDto & { value: null };
type AugmentedUserAssetBookmarkDto = (AssetBookmark | RuleBookmark | IssueBookmark | NoBookmark);

const auth = getModule(authModule, store);
@Component
export default class DashboardPinned extends Vue {
  bookmarks: UserAssetBookmarkDto[] = [];

  augmentedBookmarks: AugmentedUserAssetBookmarkDto[] = [];

  bookmarkType = 0;

  loading = false;

  get filteredBookmarks() {
    switch (this.bookmarkType) {
      case 1:
        return this.assetBookmarks;
      case 2:
        return this.ruleBookmarks;
      case 3:
        return this.issueBookmarks;
      default:
        return this.augmentedBookmarks;
    }
  }

  get hasAssetBookmarks() {
    return this.assetBookmarks.length;
  }

  get hasRuleBookmarks() {
    return this.ruleBookmarks.length;
  }

  get hasIssueBookmarks() {
    return this.issueBookmarks.length;
  }

  get hasBookmarks() {
    return this.filteredBookmarks.length;
  }

  get activeTab() {
    return '/';
  }

  get userName() {
    return auth.user?.name;
  }

  get bookmarkTypes() {
    const list = [
      {
        id: 0,
        name: this.$t('dashboard.tabs.recent'),
      },
    ];
    if (this.hasAssetBookmarks) {
      list.push({
        id: 1,
        name: this.$t('dashboard.tabs.data'),
      });
    }
    if (this.hasRuleBookmarks) {
      list.push({
        id: 2,
        name: this.$t('dashboard.tabs.monitors'),
      });
    }
    if (this.hasIssueBookmarks) {
      list.push({
        id: 3,
        name: this.$t('dashboard.tabs.incidents'),
      });
    }

    return list;
  }

  selectType(id: number) {
    this.bookmarkType = id;
  }

  isAsset(bookmark: AugmentedUserAssetBookmarkDto) {
    switch (bookmark.entityType) {
      case AssetHeaderDto.entityType.DATASOURCE:
      case AssetHeaderDto.entityType.DATASET:
      case AssetHeaderDto.entityType.DASHBOARD:
      case AssetHeaderDto.entityType.CHART:
      case AssetHeaderDto.entityType.COLLECTION:
      case AssetHeaderDto.entityType.DATASET_FIELD:
      case AssetHeaderDto.entityType.DAG:
      case AssetHeaderDto.entityType.TRANSFORMATION:
      case AssetHeaderDto.entityType.DECLARED_ASSET:
        return true;
      default:
        return false;
    }
  }

  isRule(bookmark: AugmentedUserAssetBookmarkDto) {
    return bookmark.entityType === 'SIFFLET_RULE';
  }

  isIssue(bookmark: AugmentedUserAssetBookmarkDto) {
    return bookmark.entityType === 'INCIDENT';
  }

  link(bookmark: AugmentedUserAssetBookmarkDto) {
    switch (true) {
      case this.isAsset(bookmark):
        return this.assetLink(bookmark as AssetBookmark);
      case this.isRule(bookmark):
        return this.ruleLink(bookmark as RuleBookmark);
      case this.isIssue(bookmark):
        return this.issueLink(bookmark as IssueBookmark);
      default:
        return null;
    }
  }

  assetLink(bookmark: AssetBookmark) {
    return {
      name: 'data-catalog.asset.overview',
      params: { urn: bookmark.value.urn },
      query: {
        name: bookmark.value.name,
      },
    };
  }

  ruleLink(bookmark: RuleBookmark) {
    return {
      name: 'monitors.rule.overview',
      params: { id: bookmark.value.id },
      query: {
        name: bookmark.value.name,
      },
    };
  }

  issueLink(bookmark: IssueBookmark) {
    return {
      name: 'incidents.incident.incident_overview',
      params: { issue: String(bookmark.value.issueNo) },
      query: {
        name: bookmark.value.name,
      },
    };
  }

  assetImage(bookmark: AssetBookmark) {
    return getProviderIconSrc(bookmark.value.lineagePlatform || ProviderIcons.UNKNOWN);
  }

  image(bookmark: AugmentedUserAssetBookmarkDto) {
    switch (true) {
      case this.isAsset(bookmark):
        return this.assetImage(bookmark as AssetBookmark);
      default:
        return null;
    }
  }

  icon(bookmark: AugmentedUserAssetBookmarkDto) {
    switch (true) {
      case this.isRule(bookmark):
        return 'icon-monitor-square';
      case this.isIssue(bookmark):
        return 'icon-flag';
      default:
        return null;
    }
  }

  subtitle1(bookmark: any) {
    switch (true) {
      case this.isAsset(bookmark):
        return bookmark.value.displayedType;
      case this.isRule(bookmark):
        return 'Monitor';
      case this.isIssue(bookmark):
        return 'Incident';
      default:
        return null;
    }
  }

  subtitle2(bookmark: any) {
    switch (true) {
      case this.isAsset(bookmark):
        return bookmark.value.datasourceName;
      case this.isRule(bookmark):
        return '\xa0';
      case this.isIssue(bookmark):
        return fromNow(bookmark.value.createdDate);
      default:
        return null;
    }
  }

  async getBookmarks() {
    this.bookmarks = await UserService.getCurrentUserAssetBookmarks();
  }

  get assetBookmarks() {
    const augmentedBookmarks = this.augmentedBookmarks as AssetBookmark[];
    return augmentedBookmarks.filter(({ entityType }) => {
      switch (entityType) {
        case AssetHeaderDto.entityType.DATASOURCE:
        case AssetHeaderDto.entityType.DATASET:
        case AssetHeaderDto.entityType.DASHBOARD:
        case AssetHeaderDto.entityType.CHART:
        case AssetHeaderDto.entityType.COLLECTION:
        case AssetHeaderDto.entityType.DATASET_FIELD:
        case AssetHeaderDto.entityType.DAG:
        case AssetHeaderDto.entityType.TRANSFORMATION:
        case AssetHeaderDto.entityType.DECLARED_ASSET:
          return true;
        default:
          return false;
      }
    });
  }

  get ruleBookmarks() {
    const augmentedBookmarks = this.augmentedBookmarks as RuleBookmark[];
    return augmentedBookmarks.filter(({ entityType }) => {
      switch (entityType) {
        case 'SIFFLET_RULE':
          return true;
        default:
          return false;
      }
    });
  }

  get issueBookmarks() {
    const augmentedBookmarks = this.augmentedBookmarks as IssueBookmark[];
    return augmentedBookmarks.filter(({ entityType }) => {
      switch (entityType) {
        case 'INCIDENT':
          return true;
        default:
          return false;
      }
    });
  }

  async augmentBookmarks() {
    const augmentedBookmarks = [];
    for (const bookmark of this.bookmarks) {
      let augmentedBookmark: Promise<AugmentedUserAssetBookmarkDto>;
      switch (bookmark.entityType) {
        case AssetHeaderDto.entityType.DATASOURCE:
        case AssetHeaderDto.entityType.DATASET:
        case AssetHeaderDto.entityType.DASHBOARD:
        case AssetHeaderDto.entityType.CHART:
        case AssetHeaderDto.entityType.COLLECTION:
        case AssetHeaderDto.entityType.DATASET_FIELD:
        case AssetHeaderDto.entityType.DAG:
        case AssetHeaderDto.entityType.TRANSFORMATION:
        case AssetHeaderDto.entityType.DECLARED_ASSET:
          augmentedBookmark = AssetService
            .getAssetByUrn({ urn: bookmark.entityId })
            .then((value) => ({ ...bookmark, value }));
          break;
        case 'SIFFLET_RULE':
          augmentedBookmark = RuleService
            .getSiffletRuleInfo({ id: bookmark.entityId })
            .then((value) => ({ ...bookmark, value }));
          break;
        case 'INCIDENT':
          augmentedBookmark = IncidentService
            .getIncidentByIssueNumber({ issueNo: Number(bookmark.entityId) })
            .then((value) => ({ ...bookmark, value }));
          break;
        default:
          augmentedBookmark = Promise.resolve({ ...bookmark, value: null });
          break;
      }
      augmentedBookmarks.push(augmentedBookmark);
    }
    this.augmentedBookmarks = await Promise.all(augmentedBookmarks);
  }

  async mounted() {
    this.loading = true;
    await this.getBookmarks();
    await this.augmentBookmarks();
    this.loading = false;
  }

  fromNow(string: string | undefined) {
    return fromNow(string!);
  }
}
</script>
