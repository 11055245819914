<template lang="pug">
div
  v-sheet.pa-2
    v-text-field(
      v-model="textSearch"
      :placeholder="$t('rules.type_here_to_search')"
      append-icon="icon-search"
      @click:append="getItems"
      @click:clear="clear"
      @keyup.enter="getItems"
      dense hide-details outlined clearable label rounded single-line persistent-hint )
  v-data-table(
    :headers="headers"
    :items="items"
    :loading="loadingItems"
    :server-items-length="count"
    :options.sync="options"
    :expanded="expanded"
    item-key="group.hash"
    single-expand
    hide-default-footer)

    template(v-slot:footer="footer")
      // eslint-disable-next-line vue/valid-v-bind-sync
      SiffletifyDataFooter(:footer.sync="footer.props" :items-per-page-options="[5, 10, 25, 50, 100]" @update="updateOptions($event)")

    template(v-slot:loading)
      DataTableGenericPlaceholder

    template(v-slot:no-data)
      NoDataAvailablePlaceholder

    template(v-slot:item.status="{ item }")
      HealthDot( :health="item.status" )

    template(v-slot:item.actions='{ item }')

      .d-flex

        SButton.mr-1.ml-auto(
          :disabled="!hasFailingRows(item) || !canReadFailingRows"
          icon="icon-data-row-search"
          color="secondary"
          variant="text"
          :async-action="async () => await showFailingRows(item)")
          template(#tooltip)
            HtmlView( :html="!canReadFailingRows ? $t('app.rights.suggestions_no_rights') : hasFailingRows(item) ? $t('rules.show_failing_rows') : $t('rules.show_failing_rows_na')")

        SButton.mr-1(
          :disabled="!hasSlq(item)"
          icon="icon-code-brackets"
          color="secondary"
          variant="text"
          @click="showSql(item)")
          template(#tooltip)
            HtmlView( :html="hasSlq(item) ? $t('rules.show_sql') : $t('rules.show_sql_na')")

        SButton.mr-1(
          :disabled="!canExpandRow(item)"
          icon="icon-chart-bar"
          color="secondary"
          variant="text"
          @click="expandRow(item)")
          template(#tooltip)
            HtmlView( :html="canExpandRow(item) ? $t('rules.show_details') : $t('rules.show_details_na')")

    template(v-slot:expanded-item="{ item }")

      td( colspan="100%" class="py-3")

        RuleGraph( :id="id" :runId="runId" :group="item.group" :key="item.group.hash" )

  SqlDialog( ref="sqlDialogRef" :sql-statement="sqlStatement")

  FailingRowsDialog(
    ref="failingRowsDialogRef"
    :id="id"
    :run-id="runId"
    :group="group"
    :failing-rows="failingRows" )

  </template>

  <script lang="ts">
  import {
    Vue, Component, Prop, Watch, Ref,
  } from 'vue-property-decorator';
  import { DataOptions } from 'vuetify';
  import { getModule } from 'vuex-module-decorators';
  import {
    RuleRunDetailsByGroupDto, RuleRunService, RuleInfoDto, RuleRunDto, RuleRunDebugDto, GroupIdentifierDto,
  } from '@/api';
  import { composeSortValues, decomposeSortValues } from '@/utils/sort';
  import SqlDialog from '@/components/sql-dialog/Sql-Dialog.vue';
  import FailingRowsDialog from '@/components/failing-rows-dialog/Failing-Rows-Dialog.vue';
  import authModule from '@/store/modules/auth';
  import store from '@/store';
  import SiffletifyDataFooter
    from '@/components/siffletify/siffletify-data-footer/siffletify-data-footer.vue';

  const auth = getModule(authModule, store);

  @Component({
    components: { SiffletifyDataFooter },
  })
  export default class RuleAggregatedDetails extends Vue {
    @Prop({ type: String, required: true }) id!: RuleInfoDto['id'];

    @Prop({ type: String, required: true }) runId!: RuleRunDto['id'];

    items: RuleRunDetailsByGroupDto[] = [];

    count = 0;

    textSearch = '';

    page = 1;

    itemsPerPage = 5;

    sort: string[] = [];

    sqlStatement = '';

    failingRows: RuleRunDebugDto = {};

    expanded: RuleRunDetailsByGroupDto[] = [];

    loadingItems = false;

    group: GroupIdentifierDto | null = null;

    @Ref() sqlDialogRef!: SqlDialog;

    @Ref() failingRowsDialogRef!: FailingRowsDialog;

    headers = [{
      text: 'Status',
      value: 'status',
      align: 'center',
      sortable: false,
      width: 80,
    }, {
      text: 'Group',
      value: 'dimension',
      sortable: false,
    }, {
      text: 'Result',
      value: 'result',
      sortable: false,
    }, {
      text: '',
      value: 'actions',
      sortable: false,
      width: 150,
    }];

    get canReadFailingRows() {
      return auth.userActions['monitoring.monitor.write'];
    }

    calc(data: any) {
      return data;
    }

    get forceExpand() {
      return 'force-expand' in this.$attrs;
    }

    get options() {
      const { page, itemsPerPage, sort } = this;
      const { sortBy, sortDesc } = decomposeSortValues(sort);
      return {
        page, itemsPerPage, sortBy, sortDesc,
      };
    }

    set options({
      page, itemsPerPage, sortBy, sortDesc,
    }: Partial<DataOptions>) {
      const sort = composeSortValues({ sortBy, sortDesc });
      this.page = page!;
      this.itemsPerPage = itemsPerPage!;
      this.sort = sort!;
    }

    get searchParameters() {
      return {
        id: this.id,
        runId: this.runId,
        textSearch: this.textSearch,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
        sort: this.sort,
      };
    }

    get failingKey() {
      return JSON.stringify(this.failingRows);
    }

    updateOptions(options: Partial<DataOptions>) {
      this.page = options.page!;
      this.page = options.itemsPerPage !== this.options.itemsPerPage ? 1 : options.page!;
      this.itemsPerPage = options.itemsPerPage!;
    }

    canExpandRow(run: RuleRunDetailsByGroupDto) {
      return run.hasGraph;
    }

    expandRow(item: RuleRunDetailsByGroupDto) {
      this.expanded = this.expanded?.[0] === item ? [] : [item];
    }

    @Watch('options')
    onSearchParametersChanged() {
      this.getItems();
    }

    hasFailingRows(run: RuleRunDetailsByGroupDto) {
      return run.canShowFailingRows;
    }

    async showFailingRows(run: RuleRunDetailsByGroupDto) {
      this.group = run.group!;
      this.failingRows = await RuleRunService.siffletRuleRunDebug({
        id: this.id,
        runId: this.runId,
        requestBody: { group: run.group },
      });
      this.failingRowsDialogRef?.openDialog?.();
    }

    hasSlq(run: RuleRunDetailsByGroupDto) {
      return run.debugSql?.query;
    }

    showSql(run: RuleRunDetailsByGroupDto) {
      this.sqlStatement = run.debugSql!.query!;
      this.sqlDialogRef.open();
    }

    clear() {
      this.textSearch = '';
      this.getItems();
    }

    public async getItems() {
      this.loadingItems = true;
      const { data, totalElements } = await RuleRunService.getSiffletRuleRunDetails({ ...this.searchParameters });
      this.items = data!;
      this.count = totalElements!;
      this.loadingItems = false;
    }

    mounted() {
      if (this.forceExpand) {
        this.expandRow(this.items[0]);
      }
    }
  }
  </script>
