<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="s3OutputLocationRules"
    label='S3 Output location'
    data-cy="s3-output-location"
    dense clearable outlined required)

</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputS3OutputLocation extends Vue {
  s3OutputLocationRules = [(v: string) => v.startsWith('s3://') || "S3 Output location must be prefixed by 's3://'",
    (v: string) => isConvertibleToNotBlankString(v) || 'S3 Output location is required',
    (v: string) => {
      const regex = /^s3:\/\/([a-z0-9.-]{3,63})(\/.*)?$/;
      return regex.test(v) || 'Invalid S3 Output location';
      }];
}
</script>
