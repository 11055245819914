/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PublicUserPermissionAssignmentDto } from './PublicUserPermissionAssignmentDto';

export type PublicUserCreateDto = {
    email: string;
    name: string;
    permissions: Array<PublicUserPermissionAssignmentDto>;
    role: PublicUserCreateDto.role;
};

export namespace PublicUserCreateDto {

    export enum role {
        ADMIN = 'ADMIN',
        EDITOR = 'EDITOR',
        VIEWER = 'VIEWER',
    }


}
