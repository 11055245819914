import * as d3 from 'd3';
import colors from '@/plugins/colors';
import Node from './Node';
import * as CONSTANTS from './constants';
import highlightConnectedNeighbors from './highlightConnectedNeighbors';
import resetHighlighting from './resetHighlighting';

const lineageCreateFields = (
  cardsGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  fields: Node[],
) => {
  const field = cardsGroup.selectAll('.field').data(fields, (d: any) => d.id);

  const enterField = field
    .enter()
    .append('g')
    .attr('class', 'field')
    .attr('data-urn', (d) => d.data.urn)
    .attr('width', (d) => d.width)
    .attr('height', (d) => d.height)
    .attr('transform', (d) => `translate(${d.location.x}, ${d.location.y})`)
    .attr('cursor', 'pointer')
    .on('mouseenter', (event, node: Node) => highlightConnectedNeighbors(event, [
      ...node.getDownstreamElementUrns(),
      ...node.getUpstreamElementUrns(),
    ]))
    .on('mouseleave', () => resetHighlighting());

  enterField
    .attr('opacity', 0)
    .transition()
    .duration(CONSTANTS.ANIMATION_DURATION)
    .ease(d3.easeLinear)
    .attr('opacity', 1);

  enterField.append('title').text((d) => d.data.title);

  enterField
    .append('rect')
    .attr('class', 'border')
    .attr('width', (d) => d.width)
    .attr('height', (d) => d.height)
    .attr('highlightable', true)
    .attr('fill', colors.grey.lighten5)
    .attr('stroke', (_node: Node) => (_node.isStartNode ? colors.grey.darken4 : colors.grey.lighten3))
    .attr('stroke-width', 1)
    .attr('stroke-linecap', 'square');

  enterField
    .append('text')
    .append('tspan')
    .attr('class', 'node-field-title sans-serif')
    .attr('highlightable', true)
    .attr('font-size', '12')
    .attr('fill', colors.grey.darken4)
    .attr('x', '10')
    .attr('y', '25')
    .text((d) => d.data.title.replace(/(.{20})..+/, '$1…'));

    enterField
    .append('text')
    .append('tspan')
    .attr('class', 'nodel-field-value mono')
    .attr('font-size', '12')
    .attr('font-weight', '400')
    .attr('fill', colors.grey.base)
    .attr('x', CONSTANTS.DEFAULT_FIELD_NODE_WIDTH - 10)
    .attr('y', '25')
    .attr('text-anchor', 'end')
    .text((d) => d.data.type);

  field.transition()
    .attr('transform', (d) => `translate(${d.location.x}, ${d.location.y})`);

  field
    .exit()
    .transition()
    .duration(CONSTANTS.ANIMATION_DURATION)
    .ease(d3.easeLinear)
    .attr('opacity', 0)
    .remove();
};

export default lineageCreateFields;
