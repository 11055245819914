<template lang="pug">
v-menu( :close-on-content-click="false" )
  template( v-slot:activator="{ on, attrs }" )
    v-text-field( v-model="time"
      label="Select Time"
      readonly
      outlined
      dense
      :rules="rules"
      v-bind="attrs"
      v-on="on" )
  v-time-picker( v-model="time" flat active-picker="false" v-bind="$attrs"
    v-on="$listeners" )

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ConditionalRulesConditionValueInputTime extends Vue {
  get time() {
    return this.$attrs.value;
  }

  set time(time: string) {
    this.$attrs.value = time;
  }

  rules = [
    (v: any) => !!v || 'Value is required',
  ];
}
</script>
