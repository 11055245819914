<template lang="pug">
v-container( fluid data-cy="catalog-asset-overview" )
  v-row

    v-col( cols="12" :md="mdLeftCols" )
      AssetOverviewDetail( v-if="!displayDeclaredAssetOverview" :overview-data="overviewData" :urn="urn" @update="updateHandler" )
      DeclaredAssetOverviewDetail( v-if="displayDeclaredAssetOverview" :overview-data="overviewData" :urn="urn" :source-id="overviewData?.details?.datasourceId")
      AssetOverviewDataQuality( v-if="hasDataQuality" :dataQuality="dataQuality" :urn="urn" )

    v-col( v-if="hasSchema" cols="12" :md="mdRightCols" )
      AssetOverviewSchema( :schema="schema" :urn="urn")

    v-col( v-if="hasTransformation" cols="12" :md="mdRightCols" )
      AssetOverviewTransformation( :transformation="transformation" :urn="urn")

</template>

<script lang="ts">
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import {
  AssetDto,
  AssetHeaderDto,
  AssetService,
  DagAssetOverview,
  DashboardAssetOverview,
  DatasetAssetOverview,
  DeclaredAssetOverview,
  TransformationAssetOverview,
} from '@/api';
import AssetOverviewDataQuality from '@/components/asset/overview/Asset-Overview-Data-Quality.vue';
import DeclaredAssetOverviewDetail from '@/components/asset/overview/DeclaredAssetOverviewDetail.vue';

@Component({
  components: { AssetOverviewDataQuality, DeclaredAssetOverviewDetail },
})
export default class AssetTabOverview extends Vue {
  @Prop(String) readonly urn!: AssetDto['urn'];

  @Prop(String) readonly entityType!: AssetHeaderDto['entityType'];

  @Prop({ type: Boolean, default: () => true }) readonly showSql!: boolean;

  overviewData: DagAssetOverview | DashboardAssetOverview | DatasetAssetOverview | DeclaredAssetOverview | TransformationAssetOverview | null = null;

  get mdLeftCols() {
    if (this.hasSchema) {
      return 8;
    }
    if (this.hasTransformation) {
      return 6;
    }
    return 12;
  }

  get mdRightCols() {
    if (this.hasSchema) {
      return 4;
    }
    if (this.hasTransformation) {
      return 6;
    }
    return 0;
  }

  get details() {
    return this.overviewData?.details;
  }

  get hasDataQuality() {
    return this.dataQuality !== undefined;
  }

  get dataQuality() {
    const datasetAssetOverview = this.overviewData as DatasetAssetOverview;
    return datasetAssetOverview?.dataQuality;
  }

  get hasSchema() {
    return this.schema !== undefined;
  }

  get hasTransformation() {
    return this.transformation !== undefined;
  }

  get transformation() {
    const transformationAssetOverview = this.overviewData as TransformationAssetOverview;
    return transformationAssetOverview?.transformationAssetDto;
  }

  get displayDeclaredAssetOverview() {
    return this.entityType === AssetHeaderDto.entityType.DECLARED_ASSET;
  }

  get schema() {
    const datasetAssetOverview = this.overviewData as DatasetAssetOverview;
    return datasetAssetOverview?.schema;
  }

  async getAssetOverview() {
    this.overviewData = await AssetService.getAssetOverviewByUrn({ urn: this.urn });
  }

  mounted() {
    this.getAssetOverview();
  }

  @Watch('urn', { immediate: true })
  async onUrnChange() {
    await this.getAssetOverview();
  }

  updateHandler() {
    this.getAssetOverview();
  }
}
</script>
