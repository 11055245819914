import dayjs from 'dayjs';

declare module 'vue/types/vue' {
  interface Vue {
    timeTo: (value: string | number) => undefined | string;
  }
}

/**
 * Returns the diff between a specified future date and today without suffix
 * @param {string} value - A date or a timestamp
 * @return string - The difference between the specified date and today
 */
export default (value: string | number) => {
  if (value) {
    return dayjs().to(value, true);
  }

  return '';
};
