<script lang="ts">
import { VNode, h } from 'vue';
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

const parseHTML = (html: string) => {
  const template = document.createElement('template');
  template.innerHTML = html;
  return template.content;
};

/**
 *
 * @param {Node} node
 * @param {String[]} tags
 */
const renderNode = (node: HTMLElement, tags: string[]) => {
  const { nodeType } = node;
  if (nodeType === 1) {
    const nodeName = node.nodeName.toLocaleLowerCase();
    if (tags.includes(nodeName)) {
      const children: (string | VNode | null)[] = [];
      node.childNodes.forEach((childNode) => {
        children.push(renderNode(childNode as HTMLElement, tags));
      });
      const style = node.style.cssText;
      const classes = `${node.classList.value} break-spaces`;
      const href = node.getAttribute('href');
      const attrs = href ? { href } : {};
      return h(nodeName, { style, class: classes, attrs }, children);
    }
  }
  return node.textContent;
};

@Component
export default class HtmlView extends Vue {
  @Prop({ type: String, required: true }) html!: string;

  @Prop({ type: Array, default: () => ['b', 'strong', 'br', 'a'] }) tags!: string[];

  render() {
    const html = parseHTML(this.html);
    const output: (string | VNode | null)[] = [];
    html.childNodes.forEach((childNode) => {
      output.push(renderNode(childNode as HTMLElement, this.tags));
    });
    return h('div', output);
  }
}

</script>
