<template lang="pug">
v-card
  v-container( fluid style="min-height: 500px" )
    v-row(fill-height)
      v-col( cols="3" class="border-right" )
        .font-weight-medium {{ $tc('common.words.filter', 2) }}
        v-autocomplete.mt-2(
          v-model='limit'
          :items='LIMIT_ITEMS'
          dense outlined
          label='Limit')
        .d-flex.mb-2
          v-simple-checkbox(v-model="mustShowAll" color="primary")
          span.pointer(@click="handleSelectAllClick") {{ $tc('assets.show_all_columns', fields.length) }}
        TreeNestedSelector( v-if="fields.length" v-model="selectedFields" :options="fields" :disabled="mustShowAll")
      v-col.text-right(cols='9')
        NoDataAvailablePlaceholder( v-if="noSelection && !mustShowAll" :text="$t('no_data.no_preview_data')" :secondaryText="$t('no_data.select_a_field')")
        v-skeleton-loader.mt-16(
            v-for="index in 3" :key="index"
            v-else-if="previewLoading"
            class="v-skeleton-loader-override"
            width="500"
            type="list-item-three-line" )
        NestedSheet( v-else :items="items" :schema="schema" :limit="limit" :previewLoading="previewLoading"  )
</template>

<script setup lang="ts">import { ref as _ref } from 'vue';

import {
  Vue,
} from 'vue-property-decorator';
import { AssetService } from '@/api';
import type { AssetDto, FieldDto } from '@/api';
import {
  computed, onMounted, ref, watch,
} from 'vue';

interface AssetPreviewProps {
  urn: AssetDto['urn'];
}

const LIMIT_ITEMS = [
  { text: '10 rows', value: 10 },
  { text: '20 rows', value: 20 },
  { text: '30 rows', value: 30 },
  { text: '40 rows', value: 40 },
  { text: '50 rows', value: 50 },
  { text: '100 rows', value: 100 },
  { text: '150 rows', value: 150 },
  { text: '200 rows', value: 200 },
];

const props = defineProps({
  urn: null
});

const selectedFields = ref<FieldDto[]>([]);
const limit = _ref(10);
let mustShowAll = _ref(false);
let fields = _ref<FieldDto[]>([]);
let schema = _ref<FieldDto[]>([]);
let previewLoading = _ref(false);
let items = _ref<Record<string, any>[]>([]);

const noSelection = computed(() => selectedFields.value.length === 0);
const requestBody = computed(() => ({
  fieldIds: selectedFields.value.map((f) => f.id!),
  limit: limit.value,
}));
const allFieldIds = computed(() => fields.value.map((field) => field.id));

const notifyError = (description?: string) => {
  Vue.notify({
    type: 'error',
    text: description || '',
  });
};

const handleSelectAllClick = () => {
  mustShowAll.value = !mustShowAll.value;
};

onMounted(async () => {
  fields.value = await AssetService.getAssetSchemaByUrnAndVersion({ urn: props.urn, version: 'latest' });
});

watch(() => [selectedFields.value, limit.value], async () => {
  if (selectedFields.value.length) {
    previewLoading.value = true;
    schema.value = selectedFields.value;

    await AssetService.getAssetPreviewByUrn({ urn: props.urn, requestBody: { ...requestBody.value, ...(mustShowAll.value && { fieldIds: allFieldIds.value }) } })
      .then((res) => {
        if (!res.success) {
          notifyError(res.description);
        }
        items.value = res.results;
      })
    .finally(() => previewLoading.value = false);
  }
});

watch(() => [mustShowAll.value, limit.value], async () => {
  if (mustShowAll.value) {
    selectedFields.value = [];
    schema.value = fields.value;
    previewLoading.value = true;

    await AssetService.getAssetPreviewByUrn({ urn: props.urn, requestBody: { ...requestBody.value, ...(mustShowAll.value && { fieldIds: allFieldIds.value }) } })
    .then((res) => {
      if (!res.success) {
        notifyError(res.description);
      }
      items.value = res.results;
    })
    .finally(() => previewLoading.value = false);
  }
});
</script>
