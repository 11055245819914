/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IDPJsonSchemaParamsDto } from '../models/IDPJsonSchemaParamsDto';
import type { SAML2AuthNConfigDto } from '../models/SAML2AuthNConfigDto';
import type { Saml2ConfigDto } from '../models/Saml2ConfigDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IdpService {

    /**
     * Create a IDP
     * @returns any Successfully create IDP
     * @throws ApiError
     */
    public static createIdp({
        requestBody,
    }: {
        requestBody: Saml2ConfigDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/idps',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Test saml config
     * @returns SAML2AuthNConfigDto Test saml2AuthNUrl
     * @throws ApiError
     */
    public static testSamlConfig({
        requestBody,
    }: {
        requestBody: Saml2ConfigDto,
    }): CancelablePromise<SAML2AuthNConfigDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/idps/_test',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Map of IDP json schema params
     * @returns IDPJsonSchemaParamsDto Successfully fetch IDP json schema params
     * @throws ApiError
     */
    public static getAllIdpJsonSchemaParams(): CancelablePromise<Record<string, IDPJsonSchemaParamsDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/idps/params',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get service provider config
     * @returns string Successfully fetch service provider (SP) config.
     * @throws ApiError
     */
    public static getSpConfig({
        signInMethod,
        registrationId,
    }: {
        signInMethod: string,
        registrationId: string,
    }): CancelablePromise<Record<string, string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/idps/spconfig',
            query: {
                'signInMethod': signInMethod,
                'registrationId': registrationId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single idp (Saml2 Config)
     * @returns Saml2ConfigDto Successfully fetch idp (Saml2 Config)
     * @throws ApiError
     */
    public static getByRegistrationId({
        registrationId,
    }: {
        registrationId: string,
    }): CancelablePromise<Saml2ConfigDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/idps/{registrationId}',
            path: {
                'registrationId': registrationId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update an IDP
     * @returns any Successfully update IDP
     * @throws ApiError
     */
    public static updateIdp({
        registrationId,
        requestBody,
    }: {
        registrationId: string,
        requestBody: Saml2ConfigDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ui/v1/idps/{registrationId}',
            path: {
                'registrationId': registrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
