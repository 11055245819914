<template lang="pug">

div(class="d-flex flex-nowrap" )
  v-icon(small) icon-lock-closed
  v-img(:src="image" width=16 height=16 class="flex-grow-0 flex-shrink-0 mr-1 align-self-center")
  span(class="text--info" :inner-html.prop="value?.details?.description")

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
 DagAssetOverview, DashboardAssetOverview, DatasetAssetOverview, DeclaredAssetOverview,
} from '@/api';
import src from '@/utils/filters/src';

@Component
export default class SourceDescription extends Vue {
  @Prop({ type: Object, default: null }) value!: DagAssetOverview | DashboardAssetOverview | DatasetAssetOverview | DeclaredAssetOverview | null;

  get image() {
    return src(this.value?.details?.lineagePlatform, 'datasources');
  }
}
</script>
