<template lang="pug">
ChartTimeSeries(
    :graph="graph" :id="id" :runId="runId" :qualifications="qualifications"
    :title="$t('charts.graph_type.NULL_PERCENTAGE.title')"
    :valuesSeriesName="$t('charts.graph_type.NULL_PERCENTAGE.value_series_title')"
    :formatValue="formatValue")
</template>

<script setup lang="ts">
import ChartTimeSeries from '@/components/charts/Chart-Time-Series.vue';
import type { DatapointQualificationDto, TimeSeriesGraphDto } from '@/api';
import formatNumber from '@/utils/formatNumber';

interface Props {
  graph: TimeSeriesGraphDto;
  id: string;
  runId: string;
  qualifications: DatapointQualificationDto[];
}
defineProps({
  graph: null,
  id: null,
  runId: null,
  qualifications: null
})

const formatValue = (value: number) => `${formatNumber(value)}%`;
</script>
