<template lang="pug">
div
  template( v-if="isValue" )
    table
      tr
        td
          div.field-box
            .mt-1.ml-2.text-truncate {{ nullToString(value) }}

  template( v-if="isArrayOfString" )
    table
      tr( data-type="array-of-strings" v-for="(item, i) in value" :key="i" )
        td
          div.field-box
            .mt-1.ml-2.text-truncate {{ nullToString(item) }}

  template( v-if="isArrayOfObject" )
    tr( data-type="array-of-objects" v-for="(item, i) in value" :key="i" :class="{odd: isOdd(i)}" )
      NestedSheetSimpleTable( :value="item"  )

  template( v-if="isObject" )
    table
      tr
        td( data-type="object" v-for="(item, i) in value" :key="i" )
          NestedSheetSimpleTable( :value="item" )

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class CreateCell extends Vue {
  @Prop() value!: any[];

  get isArrayOfString() {
    return Array.isArray(this.value) && (typeof this.value[0] === 'string' || this.value[0] === null || typeof this.value[0] === 'number');
  }

  get isArrayOfObject() {
    return Array.isArray(this.value) && typeof this.value[0] === 'object';
  }

  get isValue() {
    return typeof this.value === 'number' || typeof this.value === 'string' || this.value === null;
  }

  get isObject() {
    return typeof this.value === 'object' && !this.isValue && !Array.isArray(this.value);
  }

  nullToString(value: any) {
    return value === null ? 'null' : value;
  }

  isOdd(i: number) {
    return i % 2 === 0;
  }
}
</script>

<style lang="scss">
.odd {
  background-color: var(--v-bgPageTertiarybase);
}

.field-box {
  max-width: 130px;
}
</style>
