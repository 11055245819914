/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LineageUrnDto = {
    creationMethod: LineageUrnDto.creationMethod;
    urn: string;
};

export namespace LineageUrnDto {

    export enum creationMethod {
        BARRACUDA_LINEAGE_EXTRACTION = 'BARRACUDA_LINEAGE_EXTRACTION',
        DECLARATIVE_LINEAGE_API = 'DECLARATIVE_LINEAGE_API',
    }


}
