<script setup lang="ts">import { computed as _computed } from 'vue';

import { DescriptionDto } from '@/api/';

interface ExternalDescriptionsProps {
  descriptions: DescriptionDto[];
  textSearch?: string;
  asCard?: boolean;
  shouldShowRichtext?: boolean;
}

const props = defineProps({
  descriptions: null,
  textSearch: { default: '' },
  asCard: { type: Boolean, default: false },
  shouldShowRichtext: { type: Boolean, default: false }
});

const outlined = _computed(() => props.asCard);
const backgroundColor = _computed(() => (props.asCard ? 'var(--v-bgPageSecondary-base)' : 'transparent'));
</script>

<template lang="pug">
v-card( v-if="descriptions.length" :class="{ 'pa-2': asCard, 'my-1': true }" :outlined="outlined" :style="{ backgroundColor }" )
  ExternalDescription(
    v-for="(description, i) in descriptions"
    :description="description"
    :search="textSearch" :key="i" :should-show-richtext="shouldShowRichtext")
</template>
