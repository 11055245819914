<template lang="pug">
TreeSelect(
  v-bind="$attrs"
  v-on="$listeners"
  :defaultExpandLevel="Infinity"
  :normalizer="normalizer"
  :show-count="true"
  :flat="true"
  :value-consists-of="valueConsistsOf"
  multiple
  valueFormat="object"
  )

  template( v-slot:option-label="{ node, shouldShowCount, count, labelClassName, countClassName }" )
    div( :class="labelClassName")
      span {{ node.label }}
      span( v-if="shouldShowCount" :class="countClassName" ) ({{ count }})

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class TreeNestedSelector extends Vue {
  valueConsistsOf = 'ALL';

  normalizer(node: any) {
    return {
      id: node.id,
      label: node.name,
      isDefaultExpanded: false,
      isDisabled: 'parentDatasetFieldId' in node,
      children: node.subfields.length > 0 ? node.subfields : false,
    };
  }
}
</script>

<style lang="scss">
.vue-treeselect__option {
  background-color: var(--v-secondaryBackground-base) !important;
}

.vue-treeselect__placeholder {
  color: var(--v-secondaryText-base) !important;
}

.vue-treeselect>div {
  min-height: 40px;
}

.vue-treeselect__multi-value-item {
  background: #113e60 !important;
  color: var(--v-textPrimaryInverse-base) !important;
  border: 1px solid #113e60 !important;
  border-radius: 10px !important;
}

.vue-treeselect__value-remove {
  color: var(--v-textPrimaryInverse-base) !important;
}
</style>
