<template lang="pug">
div.chart-container
  v-chart( v-if="hasDtos"
    class="chart"
    ref="chart"
    :autoresize="true"
    :theme="$vuetify.theme.dark ? 'dark' : 'light'"
    @click="handleClick"
    @datazoom="handleDatazoom" )
  span( v-else class="grey--text" ) {{ $t('rules.no_data_for_rule') }}
  ChartQualification( v-if="hasDtos" ref="chartQualification" :tooltipInfos="tooltipInfos" @update="updateDatapointQualification" )
  v-btn(text outlined color="secondary" class="custom-secondary btn-reset" small @click="restore")
    v-icon(left) icon-arrow-refresh
    .ml-1 {{ $t('charts.reset') }}
  v-btn(color="secondary" class="custom-secondary btn-image" text outlined small @click="downloadImage")
    v-icon(left) icon-arrow-download
    .ml-1 {{ $t('charts.download') }}
</template>

<script setup lang="ts">import { computed as _computed, ref as _ref } from 'vue';

// eslint-disable-next-line
// @ts-nocheck
import { watchEffect } from 'vue';
import VChart from 'vue-echarts';
import dayjs from 'dayjs';
import i18n from '@/i18n';
import ChartQualification from '@/components/charts/Chart-Qualification.vue';
import { DatapointQualificationDto } from '@/api';
import type { GraphPointSpecialDate, TimeSeriesGraphDto, TimeSeriesPoint } from '@/api';
import {
  QUALIFICATION_SYMBOLS,
  QUALIFICATION_SYMBOLS_WITH_NOTE,
  type TooltipInfo,
  type TooltipQualificationInfo,
} from '@/@types/chart';

import formatNumber from '@/utils/formatNumber';
import ChartConfig, {
  getHtmlTooltip, ANOMALY_SYMBOL_MIN_SIZE, ANOMALY_SYMBOL_MAX_SIZE, HOVER_SYMBOL_SIZE, getRoundedAxis,
} from './charts.utils';

interface Props {
  graph: TimeSeriesGraphDto;
  id: string;
  runId: string;
  qualifications: DatapointQualificationDto[];
  valuesSeriesName: string;
  title: string;
  formatValue?: (value: number) => string;
  yMin?: number,
  yMax?: number,
  yInterval?: number;
}
const props = defineProps({
  graph: null,
  id: null,
  runId: null,
  qualifications: null,
  valuesSeriesName: null,
  title: null,
  formatValue: { type: Function, default: (value: number) => formatNumber(value) },
  yMin: null,
  yMax: null,
  yInterval: null
});

type RenderedQualification = {
  date: number,
  value: number,
  qualification?: DatapointQualificationDto,
  specialDates: GraphPointSpecialDate[]
};

const graphPoints = _computed(() => props.graph?.graphPoints);
const hasDtos = _computed(() => !!graphPoints.value?.length);
const checkDate = _computed(() => {
  const x = graphPoints.value?.find((point) => point.isCheckDate);
  return x?.date ?? '';
});

const chart = _ref<InstanceType<typeof VChart> | null>(null);
const chartQualification = _ref<InstanceType<typeof ChartQualification> | null>(null);
let tooltipInfos: TooltipInfo[] = _ref<TooltipInfo[]>([]);

let datapointQualifications = _ref<DatapointQualificationDto[]>([]);
type XAxisZoom = { min: Date, max: Date };
let defaultXAxisZoom = _ref<XAxisZoom>({ min: new Date(), max: new Date() });
let xAxisZoom = _ref<XAxisZoom>({ min: new Date(), max: new Date() });

const getTooltipInfos = (date: any, datapoint: TimeSeriesPoint): TooltipInfo[] => {
  const hasDatapointValue = datapoint.y != null;

  let dateValue = dayjs(date).tz().format('MMM D, YYYY hh:mm A');
  if (datapoint.specialDates?.length) dateValue += ` (${i18n.t('charts.tooltip.excluded_date')})`;

  let { anomaly } = datapoint;
  if (datapoint.specialDates?.length) anomaly = undefined;

  let expectedRangeValue: string;
  if (datapoint.ymin == null && datapoint.ymax == null) {
      expectedRangeValue = '-';
  } else if (datapoint.ymin == null) {
      expectedRangeValue = `≤ ${props.formatValue(datapoint.ymax!)}`;
  } else if (datapoint.ymax == null) {
      expectedRangeValue = `≥ ${props.formatValue(datapoint.ymin)}`;
  } else if (props.formatValue(datapoint.ymin) === props.formatValue(datapoint.ymax)) {
    expectedRangeValue = props.formatValue(datapoint.ymin);
  } else {
    expectedRangeValue = i18n.t('charts.tooltip.range_values', { lower: props.formatValue(datapoint.ymin), upper: props.formatValue(datapoint.ymax) });
  }
  return [
    { label: i18n.t('charts.tooltip.date'), value: dateValue },
    { label: props.valuesSeriesName, value: hasDatapointValue ? props.formatValue(datapoint.y!) : '-', anomaly },
    { label: i18n.t('charts.tooltip.range'), value: expectedRangeValue },
  ];
};

const getTooltipFormat = (series: ({ seriesId: 'values', data: TimeSeriesPoint } | { seriesId: 'qualifications', data: RenderedQualification })[]) => {
  const datapoint = series.find(({ seriesId }) => seriesId === 'values')?.data;
  if (!datapoint) return null;
  const renderedQualification = series.find(({ seriesId }) => seriesId === 'qualifications')?.data;
  const datapointQualification = renderedQualification?.qualification;
  const formattedQualificationDate = datapointQualification && dayjs(datapointQualification.createdDate).format('MMM D, YYYY hh:mm A');
  const qualificationInfos: TooltipQualificationInfo = {
    type: datapointQualification?.qualification,
    createdDate: formattedQualificationDate ?? '',
    provider: datapointQualification?.provider!,
    comment: datapointQualification?.comment,
    specialDates: datapoint.specialDates ?? [],
  };
  return getHtmlTooltip({
    items: getTooltipInfos(datapoint.date, datapoint),
    qualification: qualificationInfos,
  });
};

const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max);

const getSymbolSize = (point: TimeSeriesPoint) => clamp(Math.floor(point.importance! * ANOMALY_SYMBOL_MAX_SIZE), ANOMALY_SYMBOL_MIN_SIZE, ANOMALY_SYMBOL_MAX_SIZE);

watchEffect(() => {
  // Update graph with datapoint qualifications and anomalies
  if (!graphPoints.value) return;

  const allQualifications = new Map(datapointQualifications.value.map((q) => [q.date, q]));

  const renderedQualifications: RenderedQualification[] = [];
  const anomalies: TimeSeriesPoint[] = [];
  for (const point of graphPoints.value) {
    const qualification = allQualifications.get(point.date);
    if (point.y != null && (qualification || point.specialDates?.length)) {
      renderedQualifications.push({
        date: point.date,
        value: point.y,
        qualification,
        specialDates: point.specialDates ?? [],
      });
    } else if (point.anomaly) {
      anomalies.push(point);
    }
  }

  chart.value?.setOption({
    animation: false,
    dataset: [
      {
      },
      { source: renderedQualifications },
      { source: anomalies },
    ],
  }, { lazyUpdate: true });
  chart.value?.setOption({ animation: true }, { lazyUpdate: true });
});

watchEffect(() => {
  datapointQualifications.value = props.qualifications;
});

watchEffect(() => {
  if (!graphPoints.value?.length || !chart.value) {
    return;
  }

  // Update global graph
  const allValues = graphPoints.value.flatMap((point) => [point.ymin, point.ymax, point.y]).filter(Number.isFinite) as number[] | undefined;
  const globalMin = allValues && allValues.length ? Math.min(...allValues) : 0;
  const globalMax = allValues && allValues.length ? Math.max(...allValues) : 100;
  const globalDisplayMin = globalMin !== globalMax ? globalMin - (globalMax - globalMin) : globalMin - 50;
  const globalDisplayMax = globalMin !== globalMax ? globalMax + (globalMax - globalMin) : globalMax + 50;
  const convertDisplayBound = (ymin: number | undefined, ymax: number | undefined) => {
    if (ymin == null && ymax == null) {
      return { displayYmin: null, displayYmax: null };
    }
    if (ymin == null) {
      return { displayYmin: globalDisplayMin, displayYmax: ymax! };
    }
    if (ymax == null) {
      return { displayYmin: ymin, displayYmax: globalDisplayMax };
    }
    return { displayYmin: ymin, displayYmax: ymax };
  };

  const checkDateIndex = graphPoints.value.findIndex((point) => point.isCheckDate);
  const zoomStartingPoint = graphPoints.value[Math.max(checkDateIndex - 60, 0)];
  const zoomEndingPoint = graphPoints.value[Math.min(checkDateIndex + 3, graphPoints.value.length - 1)];
  defaultXAxisZoom.value = { min: new Date(zoomStartingPoint.date), max: new Date(zoomEndingPoint.date) };
  xAxisZoom.value = defaultXAxisZoom.value;

  chart.value.setOption({
    animation: true,
    textStyle: ChartConfig.textStyleConfig(),
    title: ChartConfig.titleConfig({ title: props.title, subtitle: i18n.t('charts.confidence_band') }),
    grid: ChartConfig.gridConfig(),
    xAxis: ChartConfig.xAxisConfig(),
    yAxis: ChartConfig.yAxisConfig({ formatValue: props.formatValue }),
    dataZoom: ChartConfig.dataZoomConfig({ startValue: defaultXAxisZoom.value.min, endValue: defaultXAxisZoom.value.max }),
    toolbox: ChartConfig.toolboxConfig(),
    tooltip: ChartConfig.tooltipConfig(getTooltipFormat),
    visualMap: [
      ChartConfig.visualMapConfigForAnomalies(2, graphPoints.value ?? []),
      {
        show: false,
        seriesIndex: 3,
        dimension: 2,
        min: 0,
        max: 1,
        type: 'continuous',
        inRange: {
          color: ['#f8da84', '#e95c3b', '#761428'],
        },
      },
    ],
    dataset: [
      {
        source: graphPoints.value?.map((point) => ({
          ...point,
          ...convertDisplayBound(point.ymin, point.ymax),
        })).map((point) => ({
          ...point,
          confidenceWidth: (point.displayYmax == null && point.displayYmin == null) ? null : point.displayYmax - point.displayYmin,
        })),
      },
      { /* Datapoint qualifications */ },
      { /* Anomalies */ },
    ],
    series: [{
      id: 'lower-bound',
      type: 'line',
      z: -2,
      stack: 'confidence-band',
      datasetIndex: 0,
      dimensions: ['date', 'displayYmin'],
      lineStyle: {
        width: 7,
        color: '#C8E8DD',
      },
      silent: true,
      emphasis: {
        disabled: true,
      },
      symbol: 'none',
    },
    {
      id: 'upper-bound',
      type: 'line',
      z: -2,
      stack: 'confidence-band',
      stackStrategy: 'all',
      dimensions: ['date', 'confidenceWidth'],
      lineStyle: {
        width: 7,
        color: '#C8E8DD',
      },
      areaStyle: {
        color: '#C8E8DD',
        opacity: 1,
        origin: 'auto',
      },
      silent: true,
      emphasis: {
        disabled: true,
      },
      symbol: 'none',
    },
    {
      id: 'values',
      type: 'line',
      connectNulls: true,
      z: 3,
      datasetIndex: 0,
      dimensions: ['date', 'y'],
      symbolSize: HOVER_SYMBOL_SIZE,
      lineStyle: {
        width: 1.5,
      },
      showSymbol: false,
      markLine: {
        data: [
          {
            name: 'Date monitored',
            xAxis: checkDate.value,
          },
        ],
        label: {
          formatter: '{b}',
          position: 'start',
          distance: 22,
        },
        lineStyle: {
          type: 'solid',
          color: '#667085',
        },
        symbol: ['roundRect', 'none'],
        silent: true,
      },
    },
    {
      id: 'anomalies',
      type: 'effectScatter',
      z: 5,
      datasetIndex: 2,
      dimensions: ['date', 'y', 'importance'],
      itemStyle: {
        color: '#ff9f59',
        opacity: 1,
      },
      silent: true,
      animation: false,
      symbolSize: getSymbolSize,
    },
    {
      id: 'qualifications',
      type: 'line', // Set to line instead of scatter to avoid misalignment when moving/zooming the chart between the qualification points and the data line (probably caused by the use of different throttling algorithms applied on line vs scatter)
      z: 4,
      datasetIndex: 1,
      dimensions: ['date', 'value'],
      symbolSize: ANOMALY_SYMBOL_MAX_SIZE,
      symbolOffset: [0, 0],
      itemStyle: { opacity: 1 },
      lineStyle: { opacity: 0, width: 0 },
      symbol(data: RenderedQualification) {
        const { specialDates } = data;
        let type: DatapointQualificationDto.qualification | 'SPECIAL_DATE' = data.qualification?.qualification ?? DatapointQualificationDto.qualification.NO_QUALIFICATION;
        if (specialDates.length) type = 'SPECIAL_DATE'; // If there are special dates, we hide all manual qualifications
        const comment = data.qualification?.comment;

        if (comment && type) return QUALIFICATION_SYMBOLS_WITH_NOTE[type];
        if (type) return QUALIFICATION_SYMBOLS[type];
        return null;
      },
    },
    ],
  }, { lazyUpdate: true });
});

watchEffect(() => {
  if (!graphPoints.value?.length || !chart.value) {
    return;
  }
  let yAxis = { min: props.yMin, max: props.yMax, interval: props.yInterval };

  if (!props.yMin && !props.yMax) {
    const xMin = xAxisZoom.value.min.getTime();
    const xMax = xAxisZoom.value.max.getTime();
    const allValues = graphPoints.value
      .filter((point) => point.date >= xMin && point.date <= xMax)
      .flatMap((point) => [point.y, point.ymin, point.ymax])
      .filter((value) => value != null) as number[];

    yAxis = getRoundedAxis(
      props.yMin == null ? Math.min(...allValues) : props.yMin,
      props.yMax == null ? Math.max(...allValues) : props.yMax,
      5,
    );
  }

  chart.value.setOption({
    yAxis: {
      ...yAxis,
      formatNumber: props.formatValue,
    },
    dataZoom: [{ startValue: xAxisZoom.value.min, endValue: xAxisZoom.value.max }],
  }, { lazyUpdate: true });
});

const handleClick = (payload: any) => {
  const date: number | undefined = payload.value?.date;
  if (!date) return;
  const chartPoint = graphPoints.value?.find((point) => point.date === date);
  if (!chartPoint) return;
  const qualificationPoint = props.qualifications?.find((point) => point.date === date);
  tooltipInfos.value = getTooltipInfos(date, chartPoint);
  const hasExcludedDates = !!chartPoint.specialDates?.length;
  chartQualification.value!.openDialog(
    tooltipInfos.value,
    {
      id: props.id,
      checkType: props.graph?.graphType!,
      value: chartPoint!.y!,
      date,
      comment: qualificationPoint?.comment,
      ruleRunDto: {
        id: props.runId,
        debuggable: false,
        hasGroupBy: false,
        hasGraph: false,
        canShowFailingRows: false,
      },
      qualification: qualificationPoint?.qualification ?? DatapointQualificationDto.qualification.NO_QUALIFICATION,
    },
    hasExcludedDates,
  );
};

const updateDatapointQualification = (qualification: DatapointQualificationDto) => {
  const index = datapointQualifications.value.findIndex((point) => point.date === qualification.date);
  if (index === -1) {
    datapointQualifications.value.push(qualification);
  } else if (qualification.qualification === DatapointQualificationDto.qualification.NO_QUALIFICATION && !qualification.comment) {
    datapointQualifications.value.splice(index, 1);
  } else {
    // Using splice to trigger Vue reactivity
    datapointQualifications.value.splice(index, 1, qualification);
  }
};

type DatazoomConfig = {
  startValue: number;
  endValue: number;
  start: number;
  end: number;
};
const handleDatazoom = (payload: DatazoomConfig & { batch: DatazoomConfig[] }) => {
  let {
    startValue, endValue, start, end,
  } = payload;
  if (payload.batch) {
    [{
      startValue, endValue, start, end,
    }] = payload.batch;
  }
  if (!graphPoints.value) return;

  const minDate = Math.min(...graphPoints.value.map((point) => point.date));
  const maxDate = Math.max(...graphPoints.value.map((point) => point.date));
  let minValue: number;
  let maxValue: number;
  if (startValue) {
    minValue = startValue;
  } else if (start) {
    minValue = minDate + (maxDate - minDate) * (start / 100);
  } else {
    minValue = minDate;
  }
  if (endValue) {
    maxValue = endValue;
  } else if (end) {
    maxValue = minDate + (maxDate - minDate) * (end / 100);
  } else {
    maxValue = maxDate;
  }

  xAxisZoom.value = { min: new Date(minValue), max: new Date(maxValue) };
};

const restore = () => {
  xAxisZoom.value = defaultXAxisZoom.value;
};

const downloadImage = () => {
  const a = document.createElement('a');
  a.href = chart.value?.getDataURL({
    pixelRatio: 2,
    backgroundColor: '#fff',
  });
  a.download = 'chart.png';
  a.click();
};
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
}

.chart {
  height: 390px;
}

.btn-reset {
  position: absolute;
  right: 192px;
  top: 10px;
  border: 1px solid black;
}

.btn-image {
  position: absolute;
  right: 30px;
  top: 10px;
  border: 1px solid black;
}

@media screen and (max-width: 790px) {

  .btn-reset,
  .btn-image {
    top: -30px;
  }
}</style>
