<script setup lang="ts">import { computed as _computed } from 'vue';

import type { DatasetBriefDto } from '@/api';
import src from '@/utils/filters/src';

interface TableIconProps {
  dataset: DatasetBriefDto
}

defineProps({
  dataset: null
})

const image = _computed(() => src(__props.dataset.datasourceType, 'datasources'));
const alt = _computed(() => __props.dataset.datasourceType);
const to = _computed(() => ({
    name: 'data-catalog.asset.overview',
    params: { urn: __props.dataset?.urn },
  }));
</script>

<template lang="pug">
.table-icon
  v-tooltip( top )
    template( v-slot:activator="{ on }" )
      .d-flex.fit-content( v-on="on" )
        v-avatar(tile size="20" class="flex-grow-0 flex-shrink-0 mr-1" )
          img( :src="image" :alt="alt" )
        router-link.link( :to="to" class="text-truncate" ) {{ dataset.name }}
    span {{ dataset.datasourceName }}
</template>

<style lang="scss" scoped>
.fit-content {
  width: fit-content;
}
</style>
