/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccessTokenCreationResultDto } from '../models/AccessTokenCreationResultDto';
import type { AccessTokenDto } from '../models/AccessTokenDto';
import type { CreateAccessTokenRequest } from '../models/CreateAccessTokenRequest';
import type { SearchCollectionAccessTokenDto } from '../models/SearchCollectionAccessTokenDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccessTokenService {

    /**
     * Fetch all access token
     * @returns SearchCollectionAccessTokenDto Successfully fetch list of access token
     * @throws ApiError
     */
    public static getAccessTokens(): CancelablePromise<SearchCollectionAccessTokenDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/auth/access-tokens',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Request an access token creation
     * @returns AccessTokenCreationResultDto Successfully create access token
     * @throws ApiError
     */
    public static createAccessToken({
        requestBody,
    }: {
        requestBody: CreateAccessTokenRequest,
    }): CancelablePromise<AccessTokenCreationResultDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/auth/access-tokens',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete an access token by given name
     * @returns void
     * @throws ApiError
     */
    public static deleteAccessToken({
        name,
    }: {
        name: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/auth/access-tokens/{name}',
            path: {
                'name': name,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Access token validity
     * @returns AccessTokenDto Successfully fetch access token status
     * @throws ApiError
     */
    public static accessTokenValidity({
        authorization,
    }: {
        authorization: string,
    }): CancelablePromise<AccessTokenDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/auth/access-tokens/validity',
            headers: {
                'Authorization': authorization,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
