/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GroupsInfo } from './GroupsInfo';
import type { RuleResultDatapointDto } from './RuleResultDatapointDto';
import type { ScalarDatapointDto } from './ScalarDatapointDto';
import type { ScalarExpectationDatapointDto } from './ScalarExpectationDatapointDto';

export type AppendRuleMetricDatapointsDto = {
    datapoints: Array<(RuleResultDatapointDto | ScalarDatapointDto | ScalarExpectationDatapointDto)>;
    datasetFieldId?: string;
    datasetId?: string;
    groupsInfo: GroupsInfo;
    metricType: AppendRuleMetricDatapointsDto.metricType;
};

export namespace AppendRuleMetricDatapointsDto {

    export enum metricType {
        VALUE = 'VALUE',
        AVERAGE = 'AVERAGE',
        NORMALIZED_AVERAGE = 'NORMALIZED_AVERAGE',
        STANDARD_DEVIATION = 'STANDARD_DEVIATION',
        COUNT = 'COUNT',
        DISTINCT_COUNT = 'DISTINCT_COUNT',
        SUM = 'SUM',
        MIN = 'MIN',
        MAX = 'MAX',
        VARIANCE = 'VARIANCE',
        FRESHNESS = 'FRESHNESS',
        METADATA_FRESHNESS = 'METADATA_FRESHNESS',
        TIME_SERIES_QUANTILE = 'TIME_SERIES_QUANTILE',
        CUSTOM_USER_QUERY = 'CUSTOM_USER_QUERY',
        INTERLINKED_RULE_MAXIMUM_VALUES_DIFFERENCE = 'INTERLINKED_RULE_MAXIMUM_VALUES_DIFFERENCE',
        ANOMALIES = 'ANOMALIES',
        SCALAR_PREDICTION = 'SCALAR_PREDICTION',
    }


}
