import { DomainDto, TagService, DomainService } from '@/api';
import {
  Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';

type SearchParameters = Parameters<typeof DomainService.getAllDomains>[0];

@Module({ namespaced: true, name: 'domains' })
export default class DomainsModule extends VuexModule {
    searchParameters: SearchParameters = {
      page: 1,
      itemsPerPage: 10,
      textSearch: '',
      sort: [],
    };

    domains: DomainDto[] = [];

    selectedDomain: DomainDto | null = null;

    loading = false;

    count = 100;

    get options() {
      return {
        itemsPerPage: this.searchParameters.itemsPerPage,
        page: this.searchParameters.page,
        sort: this.searchParameters.sort,
      };
    }

    get getSort() {
      return this.searchParameters.sort;
    }

    get searchString(): string | undefined { return this.searchParameters.textSearch; }

    get getDomains(): DomainDto[] { return this.domains; }

    get getLoading(): boolean { return this.loading; }

    get getCount(): number { return this.count; }

    @Mutation
    setSort(sort: string[] | undefined) {
      this.searchParameters.sort = sort;
    }

    @Mutation
    setOptions({ page, itemsPerPage, sort }: {
      page: number | undefined,
      itemsPerPage: number | undefined
      sort: string[] | undefined }) {
      this.searchParameters.page = page;
      this.searchParameters.itemsPerPage = itemsPerPage;
      this.searchParameters.sort = sort;
    }

    @Mutation
    removeSelectedDomain() {
      this.selectedDomain = null;
    }

    @Mutation
    setSelectedDomain(domain: DomainDto) {
      this.selectedDomain = domain;
    }

    @Mutation
    setSearchString(searchString: string | undefined) {
      this.searchParameters.textSearch = searchString;
    }

    @Mutation
    setDomains(domains: DomainDto[]) {
      this.domains = domains;
    }

    @Mutation
    setLoading(loading: boolean) {
      this.loading = loading;
    }

    @Mutation
    setCount(count: number) {
      this.count = count;
    }

    @Mutation
    resetState() {
      this.count = 0;
      this.domains = [];
    }

    @Action
    async updateSearch() {
      this.searchParameters.page = 1;
      await this.searchDomains();
    }

    @Action
    async searchDomains() {
      this.setLoading(true);
      const { data, totalElements } = await DomainService.getAllDomains(this.searchParameters);
      this.setCount(totalElements!);
      this.setDomains(data!);
      this.setLoading(false);
    }
}
