<script setup lang="ts">import { computed as _computed } from 'vue';

import type { Component } from 'vue';
import { computed } from 'vue';
import i18n from '@/i18n';
import { AssetDetailsDto } from '@/api';
import type { DeclaredAssetOverview, DeclaredAssetDetailsDto, AccessTokenProviderDto } from '@/api';
import { getProviderIconSrc } from '@/utils/provider-icons';

import formatDate from '@/utils/filters/formatDate';
import Owners from '@/components/owners/Owners.vue';
import Tags from '@/components/tags/Tags.vue';
import Terms from '@/components/terms/Terms.vue';
import copyToClipboard from '@/utils/copyToClipboard';

interface DetailComponent {
  tag?: string | Component;
  label: string;
  value?: string;
  attributes?: Record<string, any>;
  icon?: string;
  image?: string;
  copy?: string;
  shouldDisplay?: any;
}

interface AssetProps {
  overviewData: DeclaredAssetOverview;
  urn: string;
  sourceUri?: string;
  sourceId?: string;
  id?: string;
  isSmall?: boolean;
}
const props =  defineProps({
  overviewData: null,
  urn: null,
  sourceUri: null,
  sourceId: null,
  id: null,
  isSmall: { type: Boolean }
});

const assetOverview = _computed(() => props.overviewData || {});

const isDeclaredSource = _computed(() => assetOverview.value.details?.sourceCreationType === AssetDetailsDto.sourceCreationType.DECLARED);

const overviewLocation = _computed(() => ({
  name: 'data-catalog.asset.overview',
  params: {
    urn: props.urn,
  },
}));

const sourceLocation = _computed(() => {
  if (!props.sourceId) return {};

  return {
    name: 'sources.source.overview',
    params: {
      id: props.sourceId,
    },
  };
});

const sourceNameComponent = computed<DetailComponent>(() => ({
  tag: 'router-link',
  label: isDeclaredSource.value ? i18n.t('assets.declarative_source') : i18n.t('assets.source'),
  value: assetOverview.value.details?.datasourceName,
  attributes: { to: sourceLocation.value },
  shouldDisplay: !isDeclaredSource.value,
  image: getProviderIconSrc(assetOverview.value.details?.lineagePlatform),
}));

const detailsDisplay = computed<DetailComponent[]>(() => {
  const assetDetails: Partial<DeclaredAssetDetailsDto> = assetOverview.value.details || {};
  const createdBy = assetDetails.createdBy as AccessTokenProviderDto;
  const lastModifiedBy = assetDetails?.lastModifiedBy as AccessTokenProviderDto;

  return [
    {
      label: i18n.t('assets.ingestion_type'),
      value: 'Declarative asset',
      icon: 'icon-arrow-right-circle-fill',
    },
    sourceNameComponent.value,
    {
      label: i18n.t('assets.description'),
      value: assetDetails.description,
    },
    {
      tag: Tags,
      label: i18n.t('assets.tags'),
      attributes: { tags: assetDetails.tags },
      shouldDisplay: !!assetDetails.tags?.length,
    },
    {
      tag: Terms,
      label: i18n.t('assets.business_terms'),
      attributes: { terms: assetDetails.terms },
      shouldDisplay: !!assetDetails.terms?.length,
    },
    {
      tag: Owners,
      label: i18n.t('assets.owners'),
      attributes: { owners: assetDetails.owners, isOverview: true },
      shouldDisplay: !!assetDetails.owners?.length,
    },
    {
      label: i18n.t('assets.created_by'),
      value: createdBy?.name,
      icon: 'icon-key',
    },
    {
      label: i18n.t('assets.created_date'),
      value: formatDate(assetDetails.creationDate || ''),
      icon: 'icon-calendar',
    },
    {
      label: i18n.t('assets.edit_by'),
      value: lastModifiedBy?.name,
      icon: 'icon-key',
    },
    {
      label: i18n.t('assets.edit_date'),
      value: formatDate(assetDetails.lastModifiedDate || ''),
      icon: 'icon-calendar',
    },
  ];
});

const handleCopyToClipboard = (value: string) => {
  copyToClipboard(value, i18n.t('assets.copied_to_clipboard', { value }));
};

</script>

<template lang="pug">
v-card.my-4.py-4.px-4( :outlined="!isSmall" )
  .d-flex.justify-space-between
    .text-h6.font-weight-medium.mb-6(v-if="!isSmall") {{ $t('assets.details') }}
    .btn-container( v-if="isSmall" )
      .d-flex
        SButton.btn.mr-2( :to="overviewLocation" icon="icon-book" :text="$t('assets.details')"  variant="outlined" color="secondary" )
        VMenu(
          content-class="border-around"
          nudge-bottom="5" nudge-right="36" min-width="200"
          transition="slide-x-reverse-transition"
          offset-x offset-y bottom left )

          template(v-slot:activator='{ on, $attrs }')
            SButton(
              :attrs="$attrs"
              v-on="on"
              class="action-button custom-secondary icon-only"
              icon="icon-menu-dots-vertical"

              variant="outlined"
              color="secondary" )

          VList( dense class="py-0" )
            VListItemGroup
              VListItem( v-if="sourceUri" @click="handleCopyToClipboard(sourceUri)" )
                VListItemIcon
                  VIcon icon-copy
                VListItemTitle {{ $t('data-catalog.copy_uri') }}
              VListItem( v-if="id" @click="handleCopyToClipboard(id)" )
                VListItemIcon
                  VIcon icon-copy
                VListItemTitle {{ $t('data-catalog.copy_id') }}

      v-divider.divider.mt-6.mb-6

  ul.list-unstyled.grid( :class="{ 'small-grid': isSmall }" )
    template( v-for="(detail, index) in detailsDisplay" )
      li.list-unstyled( :key="detail.label")
        span.font-weight-medium {{ detail.label }}
      li.mb-5.list-unstyled( :key="`${detail.label}${index}`" )
        Component.d-flex.align-center.textSecondary--text( v-if="detail.value || (detail.attributes && detail.shouldDisplay)" :is="detail.tag || 'span'" v-bind="detail.attributes" )
          v-icon.mr-1( v-if="detail.icon" size=20 color="iconNeutral" ) {{ detail.icon }}
          img.mr-1( v-if="detail.image" :src="detail.image" width='20' :alt="detail.value" )
          | {{ detail.value }}
          v-icon.copy.ml-2( v-if="detail.copy" small @click="handleCopyToClipboard(detail.copy)") icon-copy
        span( v-else ) -
</template>

<style lang="scss" scoped>
.grid {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0 20%;
  list-style: none;

  &.small-grid {
    grid-template-columns: 100%;
    gap: 4px;
  }
}

.btn-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.btn {
  width: fit-content;
}

.divider {
  width: 100%;
}

.copy {
  cursor: pointer;

  &.v-icon:focus::after {
    opacity: 0 !important;
  }
}

@media screen and (max-width: 790px) {
  .grid {
    grid-template-columns: 100%;
    gap: 4px;
  }

}
</style>
