<script setup lang="ts">import { ref as _ref } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
defineProps({
  value: null
});
defineEmits(["input"]);
import { onMounted } from 'vue';

const { value: searchString } = __MACROS_useVModel(["value", undefined, "input"]);

const searchInput = _ref<HTMLInputElement | null>(null);

onMounted(() => {
  searchInput.value?.focus();
});

</script>

<template lang="pug">
v-text-field(
  ref="searchInput"
  v-model.trim="searchString"
  :placeholder="$t('common.words.type_to_search')"
  dense outlined clearable
  hide-details rounded
  label persistent-hint )

</template>
