<template lang="pug">
div( v-if="qualification" )
  v-icon( class="mb-1 mr-1" size="20") {{ icon }}
  span( v-bind="$attrs" ) {{ $t(`common.words.${qualification}`) }} {{ $t('common.words.data_usage') }}
</template>

<script lang="ts">
import { Vue, Component, VModel } from 'vue-property-decorator';
import { AssetUsageDto } from '@/api';

@Component
export default class DataUsage extends Vue {
  @VModel({ type: String }) qualification!: AssetUsageDto.qualification;

  get icon() {
    switch (this.qualification) {
      case AssetUsageDto.qualification.HIGH:
        return 'icon-usage-high';
      case AssetUsageDto.qualification.MEDIUM:
        return 'icon-usage-mid';
      case AssetUsageDto.qualification.LOW:
        return 'icon-usage-low';
      case AssetUsageDto.qualification.UNSUPPORTED:
        return 'icon-usage-unknown';
      default:
        return 'icon-usage-unknown';
    }
  }
}
</script>
