import { DomainUiLightDto, UserUiDto } from '@/api';

export type Actions = (
  | 'navigate'
  | 'monitoring.monitor.read'
  | 'monitoring.monitor.write'
  | 'monitoring.incident.read'
  | 'monitoring.incident.write'
  | 'metadata.asset.read'
  | 'metadata.asset.write'
  | 'metadata.lineage.read'
  | 'metadata.lineage.write'
  | 'metadata.term.read'
  | 'metadata.term.write'
  | 'integration.datasource.read'
  | 'integration.datasource.write'
  | 'integration.secrets.read'
  | 'integration.secrets.write'
  | 'setting.user.read'
  | 'setting.user.write'
  | 'setting.sso.read'
  | 'setting.sso.write'
  | 'setting.domain.read'
  | 'setting.domain.write'
  | 'setting.tag.read'
  | 'setting.tag.write'
  | 'setting.alert.read'
  | 'setting.alert.write'
  | 'setting.token.read'
  | 'setting.token.write'
);

/**
 * Checks if a user has the right to perform a specific action based on their system role and domain role.
 * As specified in this notion https://www.notion.so/sifflet/Actions-48b5284499bd4ae397635b86b0114768
 * @param action - The action to check for.
 * @param systemRole - The system role of the user. Defaults to UserUiDto.role.SYSTEM_VIEWER.
 * @param domainRole - The domain role of the user. Defaults to DomainUiLightDto.role.VIEWER.
 * @returns True if the user has the right to perform the action, false otherwise.
 */
export function hasRightTo(
  action: Actions,
  systemRole: UserUiDto.role = UserUiDto.role.SYSTEM_VIEWER,
  domainRole: DomainUiLightDto.role = DomainUiLightDto.role.VIEWER,
  ) {
  switch (action) {
    // NONE
    case 'navigate':
      return true;

    // MONITORING
    case 'monitoring.monitor.write':
    case 'monitoring.incident.write':
      return domainRole === DomainUiLightDto.role.EDITOR;

    case 'monitoring.monitor.read':
    case 'monitoring.incident.read':
      return domainRole === DomainUiLightDto.role.EDITOR
      || domainRole === DomainUiLightDto.role.VIEWER;

    // METADATA
    case 'metadata.asset.write':
    case 'metadata.lineage.write':
      return domainRole === DomainUiLightDto.role.EDITOR;

    case 'metadata.asset.read':
    case 'metadata.lineage.read':
      return domainRole === DomainUiLightDto.role.EDITOR
      || domainRole === DomainUiLightDto.role.VIEWER;

    // INTEGRATION
    case 'integration.datasource.read':
    case 'integration.datasource.write':
      return systemRole === UserUiDto.role.ADMIN
      || systemRole === UserUiDto.role.SYSTEM_EDITOR;

    case 'integration.secrets.write':
      return systemRole === UserUiDto.role.ADMIN;

    case 'integration.secrets.read':
      return systemRole === UserUiDto.role.ADMIN
      || systemRole === UserUiDto.role.SYSTEM_EDITOR;

    // SETTING
    case 'setting.user.read':
    case 'setting.user.write':
    case 'setting.sso.read':
    case 'setting.sso.write':
    case 'setting.domain.read':
    case 'setting.domain.write':
    case 'setting.alert.read':
    case 'setting.alert.write':
    case 'setting.token.read':
    case 'setting.token.write':
      return systemRole === UserUiDto.role.ADMIN;

    case 'setting.tag.read':
      return systemRole === UserUiDto.role.ADMIN
      || systemRole === UserUiDto.role.SYSTEM_EDITOR
      || systemRole === UserUiDto.role.SYSTEM_VIEWER;

    case 'setting.tag.write':
      return systemRole === UserUiDto.role.ADMIN
      || systemRole === UserUiDto.role.SYSTEM_EDITOR;

    case 'metadata.term.read':
      return systemRole === UserUiDto.role.ADMIN
      || systemRole === UserUiDto.role.SYSTEM_EDITOR
      || systemRole === UserUiDto.role.SYSTEM_VIEWER;

    case 'metadata.term.write':
      return systemRole === UserUiDto.role.ADMIN
      || systemRole === UserUiDto.role.SYSTEM_EDITOR;

    default:
      return false;
  }
}
