import { Vue } from 'vue-property-decorator';

/**
 *
 * @param text The text to copy
 * @param successMessage The message toast
 */
const copyToClipboard = (text: string, successMessage: string) => {
  navigator.clipboard.writeText(text).then(() => {
    Vue.notify({
      text: successMessage,
      type: 'success',
    });
  });
};

export default copyToClipboard;
