/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AsCodeFieldFormatValidationClauseDto = {
    kind: AsCodeFieldFormatValidationClauseDto.kind;
};

export namespace AsCodeFieldFormatValidationClauseDto {

    export enum kind {
        EMAIL = 'Email',
        PHONE = 'Phone',
        UUID = 'UUID',
        REGEX = 'Regex',
    }


}
