<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

// eslint-disable-next-line
// @ts-nocheck
import {
  Vue,
} from 'vue-property-decorator';
import { onBeforeUnmount, onMounted, watch } from 'vue';
import src from '@/utils/filters/src';
import { isSourceBeta } from '@/utils/betaComparator';
import type { QueryType } from '@/utils/query';
import IncidentStatus from '@/components/incidents/Incident-Status.vue';

type Child = {
  name: string;
  id: string;
  results: number;
  children: Child[];
}

type MultiCheckboxDescriptor = {
  name: string;
  query: string;
  children: Child[];
}

type TreeCheckboxSelectorEmits = {
  (event: 'input', filter: { [key: string]: QueryType | number }): void
}

interface TreeCheckboxSelectProps {
  filter?: MultiCheckboxDescriptor,
  hasSearch?: boolean
}

const props = defineProps({
  filter: { default: () => ({}) },
  hasSearch: { type: Boolean, default: false }
});

const emit = defineEmits(["input"]);

const treeview = _ref<HTMLElement>();
const selection = _ref([]);
const search = _ref('');

const items = _computed(() => props.filter?.children);
const name = _computed(() => props.filter?.name);
const isCriticality = _computed(() => props.filter?.query === 'criticality');
const isStatus = _computed(() => props.filter?.query === 'status');
const isUsage = _computed(() => props.filter?.query === 'usage');
const isOwner = _computed(() => props.filter?.query === 'assignment');
const hasResults = _computed(() => !!items.value?.length);

const isBeta = (sourceType: string) => isSourceBeta(sourceType);
const hasIcon = (item: Child) => props.filter?.query === 'datasources' && 'children' in item;
const getImage = (id: string) => src(id, 'datasources');

const getHealth = (value: string) => {
  switch (value) {
    case 'PENDING':
      return '';
    case 'RUNNING':
      return '';
    default:
      return value;
  }
};

const usageIcon = (usage: string) => {
  switch (usage.toLowerCase()) {
    case 'high':
      return 'icon-usage-high';
    case 'medium':
      return 'icon-usage-mid';
    case 'low':
      return 'icon-usage-low';
    case 'not available':
      return '';
    default:
      return null;
  }
};

const criticalityId = (criticality: string) => {
  switch (criticality.toLowerCase()) {
    case 'critical':
      return 0;
    case 'high':
      return 1;
    case 'moderate':
      return 2;
    case 'low':
      return 3;
    default:
      return null;
  }
};

const removeWrap = (e: MouseEvent) => {
  const target = e.target as HTMLElement;
  if (target.className === 'v-treeview-node__root') {
    target
      .querySelector('.v-treeview-node__content > *')!
      .classList.add('remove-wrap');
  }
};

const addWrap = (e: MouseEvent) => {
  const target = e.target as HTMLElement;
  if (target.className === 'v-treeview-node__root') {
    target
      .querySelector('.v-treeview-node__content > *')!
      .classList.remove('remove-wrap');
  }
};

onMounted(() => {
  Vue.nextTick(() => {
    if (!treeview.value) return;
    treeview.value.$el.addEventListener('mouseenter', removeWrap, true);
    treeview.value.$el.addEventListener('mouseleave', addWrap, true);
  });
});

onBeforeUnmount(() => {
  treeview.value.$el.removeEventListener('mouseenter', removeWrap);
  treeview.value.$el.removeEventListener('mouseleave', addWrap);
});

watch(() => selection.value, () => {
  emit('input', { [props.filter.query]: selection.value });
});
</script>

<template lang="pug">
.tree-box-selector
  v-expansion-panels( class="mt-1" flat :value="0"  )
    v-expansion-panel
      v-expansion-panel-header
        b.body-2(v-if="name") {{ $t(`common.filters.${name}`) }}

      v-expansion-panel-content
        template(v-if="hasResults")
          v-text-field.mb-2.mt-1.caption.treebox-textfield(
            v-if="hasSearch"
            v-model="search"
            height="24px"
            outlined clearable hide-details)
            template(#prepend-inner)
              v-icon( color="grey" small) icon-search
          v-treeview(
            v-bind='$attrs'
            v-on='$listeners'
            :items="items"
            :search="search"
            selected-color="primary"
            selection-type="leaf"
            :class="{ fixOverflow: isCriticality, 'owner': isOwner }"
            ref="treeview"
            selectable dense)
            template( v-slot:label="{ item }" )
              img( v-if="hasIcon(item)" class="mb-n1 mr-2" :src='getImage(item.id)' width='20' :alt="item.name" )
              Severity.ml-1.mt-0( v-if="isCriticality" :value="criticalityId(item.name)" is-filter class="mr-2")
              IncidentStatus( v-else-if="isStatus" :status="item.id" )
              AvatarList(v-if="isOwner" :value="[item]" name filter size="20")
              v-icon( v-else-if="isUsage" small class="mb-1 mr-1") {{ usageIcon(item.name) }}
              span( v-if="!isCriticality && !isOwner && !isStatus" :title="item.name" ) {{ item.name }}
              v-chip.ml-1.font-weight-bold.beta-chip(v-if="isBeta(item.name)" x-small outlined color="black") {{ $t('common.words.beta') }}
        template(v-else)
          v-progress-circular.mr-2(indeterminate size="15")
</template>

<style lang="scss" scoped>
::v-deep.v-treeview-node__label {
  &.remove-wrap {
    white-space: nowrap;
    overflow: visible;
    text-overflow: unset;
    z-index: 10;
  }
}

.v-treeview-node__label {
  &.remove-wrap {
    background-color: var(--v-bgPrimary-base);
  }
}

::v-deep .v-text-field .v-input__control .v-input__slot {
  min-height: auto;
  display: flex;
  align-items: center;
  padding: 0 8px !important;
}

::v-deep .v-input__prepend-inner {
  margin-top: 4px;
}

::v-deep .v-input__append-inner {
  margin-top: 2px;
}

::v-deep.v-treeview--dense .v-treeview-node__root {
  margin-left: -15px;
}

::v-deep .fixOverflow .v-treeview-node__label {
  overflow: visible;
}

.beta-chip {
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: 700;
}

.owner {
  max-height: 200px;
  overflow: auto;
}

.treebox-textfield {
  min-height: 24px;
  height: 24px;
}
</style>
