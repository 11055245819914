// giving a string and a number, return a string with a maximum length of the number plus three dots
export default function shortString(str: string, length: number): string {
  if (!length) {
    return '';
  }
  if (str.length > length) {
    return `${str.slice(0, length)}...`;
  }
  return str;
}
