import { type MonitorSettingsOptions } from './common/types';
import { dynamicSettings, staticSettings } from './common/thresholdSettings';

const SiffletFreshnessRule: MonitorSettingsOptions = {
    thresholdSettings: [
        {
            ...dynamicSettings,
            hasAnomalyConditions: false,
            convertThresholdToLegacy: (thresholdParams) => ({
                sensitivity: thresholdParams.sensitivity,
                comparisonType: 'dynamic',
            }),
        },
        {
            ...staticSettings,
            hasAnomalyConditions: false,
            convertThresholdToLegacy: () => ({
                sensitivity: null,
                comparisonType: 'static',
            }),
        },
    ],
};

export default SiffletFreshnessRule;
