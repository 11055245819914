/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateCollaborationToolConfigDto } from '../models/CreateCollaborationToolConfigDto';
import type { GetCollaborationToolConfigDto } from '../models/GetCollaborationToolConfigDto';
import type { PatchCollaborationToolConfigDto } from '../models/PatchCollaborationToolConfigDto';
import type { SearchCollectionGetCollaborationToolConfigDto } from '../models/SearchCollectionGetCollaborationToolConfigDto';
import type { SearchCollectionJiraProjectDto } from '../models/SearchCollectionJiraProjectDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CollaborationToolService {

    /**
     * Get all Collaboration Tool configs
     * @returns SearchCollectionGetCollaborationToolConfigDto Successfully retrieved Collaboration Tool configs
     * @throws ApiError
     */
    public static getAllCollaborationToolConfigs(): CancelablePromise<SearchCollectionGetCollaborationToolConfigDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/configs',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Connect your Collaboration Tool to Sifflet
     * @returns GetCollaborationToolConfigDto Successfully connected Collaboration Tool
     * @throws ApiError
     */
    public static connectCollaborationTool({
        requestBody,
    }: {
        requestBody: CreateCollaborationToolConfigDto,
    }): CancelablePromise<GetCollaborationToolConfigDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/collaboration-tools/configs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete Collaboration Tool config by id
     * @returns void
     * @throws ApiError
     */
    public static deleteCollaborationToolConfigById({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/collaboration-tools/configs/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Patch Collaboration Tool config by id
     * @returns GetCollaborationToolConfigDto Successfully deleted Collaboration Tool config
     * @throws ApiError
     */
    public static patchCollaborationToolConfigById({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: PatchCollaborationToolConfigDto,
    }): CancelablePromise<GetCollaborationToolConfigDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/ui/v1/collaboration-tools/configs/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all Projects related to configured Jira instance
     * @returns SearchCollectionJiraProjectDto Successfully retrieved Jira projects
     * @throws ApiError
     */
    public static getAllJiraProjects(): CancelablePromise<SearchCollectionJiraProjectDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/collaboration-tools/jira/projects',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
