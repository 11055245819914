<template lang="pug">
v-card.datasource-result-card( width="100%" tile :to="to" :style="cursor" )
  .pa-3.mb-2
    v-row( align-content="space-between" )

      v-slide-x-transition( hide-on-leave )
        v-col( v-if="inSelectMode && !isDeclarativeDatasource" cols="auto" align-self="center" )
          v-icon(:color="iconColor") {{ icon }}

      v-col( cols="8" )
        v-row.mb-2( no-gutters dense )

          v-col( cols="12" )
            .text-h6.text-truncate
              span {{ datasource.name }}

          v-col.pt-1.textSecondary--text( cols="12" )
            .d-flex.align-center.flex-wrap
              // Type
              .d-flex.mr-5
                DatasourceIcon( :datasource="datasource" )
              // Ingestion source
              .d-flex.mr-5(v-if="isDeclarativeDatasource")
                v-icon.mr-1( small color="iconNeutral" ) icon-data-source-declarative
                span {{ $tc('assets.declared_source') }}
              template(v-if="!isDeclarativeDatasource")
                // Timezone
                .d-flex.mr-5
                  TimezoneIcon( color="iconNeutral" :timezone-data="datasource.params.timezoneData" )
                // Last Run
                .d-flex.mr-5.flex-shrink-0( v-if="lastRunStatus" )
                  v-icon.mr-1( small color="iconNeutral" ) icon-clock
                  DateTooltip( :value="lastRunStatus.timestamp" )
                // Schedule
                .d-flex.mr-5.flex-shrink-0
                  v-icon.mr-1( small color="iconNeutral" ) icon-calendar
                  span( v-if="datasource.cronExpression" ) {{ datasource.cronExpression }}
                  span.grey--text( v-else ) {{ $t('monitors.no_schedule') }}

          v-col.pt-1( cols="12" )
            Tags( small :tags="datasource.tags" )

      v-col.pt-md-8( v-if="!isDeclarativeDatasource" cols="auto" align-self="start" class="d-flex flex-grow-1" )
        v-spacer
        .d-flex
          .d-flex.flex-column.align-end.mr-6
            b {{ $t('sources.last_runs') }}
            HealthDots( :runs="datasource.lastWeekStatuses" )

          .d-flex.align-end.justify-end
            SButton.mr-2(
              :disabled="!canWrite"
              icon="icon-trash"
              :tooltip="$t('sources.delete_source')"
              color="danger"
              size="small"
              variant="text"
              :async-action="deleteSource")
              v-icon( small ) icon-trash

            SButton.mr-2(
              :disabled="!canWrite"
              icon="icon-edit"
              :tooltip="$t('sources.edit_source')"
              color="secondary"
              size="small"
              variant="text"
              @click="editSource")
              v-icon( small ) icon-edit

            SButton.mr-2(
              :disabled="!canWrite"
              icon="icon-copy"
              :tooltip="$t('sources.copy_source_settings')"
              color="secondary"
              size="small"
              variant="text"
              @click.prevent="copySource")

            SButton(
              :disabled="!canWrite"
              icon="icon-play-circle"
              :tooltip="$t('sources.run_source')"
              color="secondary"
              size="small"
              variant="text"
              :async-action="manualRun")

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  DatasourceCatalogAssetDto,
  DatasourceService,
} from '@/api';
import store from '@/store';
import authModule from '@/store/modules/auth';

const DECLARATIVE_SOURCE_TYPE = 'declarative';

const auth = getModule(authModule, store);

@Component
export default class DatasourceResultCard extends Vue {
  @Prop(Object) readonly datasource!: DatasourceCatalogAssetDto;

  @Prop(Boolean) readonly selected!: boolean;

  @Prop(Boolean) readonly inSelectMode!: boolean;

  get to() {
    if (this.inSelectMode) return undefined;
    return {
      name: 'sources.source.overview',
      params: { id: this.datasource.id, name: this.datasource.name },
    };
  }

  get canWrite() {
    return !this.inSelectMode && auth.userActions['integration.datasource.write'];
  }

  get cursor() {
    if (this.inSelectMode) return { cursor: 'pointer' };
    return undefined;
  }

  get runIsDisabled() {
    return this.inSelectMode;
  }

  get lastRunStatus() {
    return this.datasource.lastWeekStatuses?.[0];
  }

  get isDeclarativeDatasource() {
    return this.datasource.type === DECLARATIVE_SOURCE_TYPE;
  }

  async deleteSource() {
    this.$emit('delete', [this.datasource]);
  }

  editSource() {
    this.$router.push({
      name: 'sources.source.edit',
      params: {
        id: this.datasource.id,
      },
    });
  }

  copySource() {
    this.$router.push({
      name: 'sources.source.copy',
      params: {
        id: this.datasource.id,
      },
    });
  }

  async manualRun() {
    await DatasourceService.datasourceIngestionManualRun({ id: this.datasource.id });
    this.$notify({
      text: this.$t('sources.run_source_success', { name: this.datasource.name }) as string,
    });
  }

  get color(): string {
    return this.selected ? 'primary' : '';
  }

  get icon(): string {
    return this.selected ? 'icon-check-square-fill' : 'icon-check-square-outline-empty';
  }

  get iconColor(): string {
    return this.selected ? 'iconInfo' : 'iconNeutral';
  }
}
</script>

<style lang="scss">
.datasource-result-card {
  border-bottom: 1px solid;
  border-color: var(--v-borderPrimary-base) !important;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    background-color: var(--v-bgPrimaryHover-base);
  }
}
</style>
