/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PublicDeclarativeLineageListDto } from './PublicDeclarativeLineageListDto';
import type { PublicReferenceByIdOrEmailDto } from './PublicReferenceByIdOrEmailDto';
import type { PublicReferenceByIdOrNameDto } from './PublicReferenceByIdOrNameDto';
import type { PublicTagReferenceDto } from './PublicTagReferenceDto';

/**
 * Use this parameter to declare assets and their associated links. It is recommended to use the `lineages` object of the `assets` array of objects rather than the `lineages` array of objects for cases where you want to declare assets and their corresponding lineage links.
 */
export type PublicDeclarativeAssetDto = {
    /**
     * Description of the declared asset
     */
    description?: string;
    /**
     * External link to be associated with the declared asset (e.g. link to your actual BI dashboard, ML model, etc.). This link will show up as a button on your asset page.
     */
    href?: string;
    lineages?: PublicDeclarativeLineageListDto;
    /**
     * Display name of the declared asset
     */
    name?: string;
    /**
     * Owners to be associated with the declared asset
     */
    owners?: Array<PublicReferenceByIdOrEmailDto>;
    /**
     * Secondary type of the declared asset. Shows up in the Data Catalog as an additional metadata giving more details about the asset type (e.g. "type": "Dataset", "subType": "View"). "type": "Generic" declared assets will have their subType value added to the Asset type filter category of the Data Catalog.
     */
    subType?: string;
    /**
     * Tags to be associated with the declared asset
     */
    tags?: Array<PublicTagReferenceDto>;
    /**
     * Business terms to be associated with the declared asset. Business terms can be referenced either through their `id`, their `name`, or both.
     */
    terms?: Array<PublicReferenceByIdOrNameDto>;
    /**
     * Primary type of the declared asset, indicating the overall category the asset falls in.
     * Utilized to know which value the declared asset should be nested in in the Asset type filter category of the Data Catalog. Selecting Generic will result in the subType value being added as a value of this Asset type filter category.
     */
    type: PublicDeclarativeAssetDto.type;
    /**
     * URI string identifying the declared asset. <a href="https://docs.siffletdata.com/docs/uris">[Read more about URIs]</a>
     */
    uri: string;
};

export namespace PublicDeclarativeAssetDto {

    /**
     * Primary type of the declared asset, indicating the overall category the asset falls in.
     * Utilized to know which value the declared asset should be nested in in the Asset type filter category of the Data Catalog. Selecting Generic will result in the subType value being added as a value of this Asset type filter category.
     */
    export enum type {
        DATASET = 'Dataset',
        DASHBOARD = 'Dashboard',
        PIPELINE = 'Pipeline',
        ML_MODEL = 'MlModel',
        GENERIC = 'Generic',
    }


}
