import { AnomalyConditionsEnum, MonitorSettingsOptions } from '@/components/monitor-wizard/composables/monitors/common/types';
import { staticSettings } from './common/thresholdSettings';

const SiffletPercentageNullStaticRule: MonitorSettingsOptions = {
  thresholdSettings: [
    {
      ...staticSettings,
      hasSensitivity: false,
      hasAnomalyConditions: true,
      anomalyConditions: [
        {
          text: 'monitor_wizard.anomaly_conditions.single_threshold',
          value: AnomalyConditionsEnum.SINGLE_VALUE,
          isPercentage: true,
          min: 0,
          max: 100,
        },
      ],
      convertThresholdToLegacy: (thresholdParams) => ({
        threshold: thresholdParams.anomalyConditions.singleValue || 0,
      }),
    },
  ],
};

export default SiffletPercentageNullStaticRule;
