export default class Dimension {
    public width: number;

    public height: number;

    constructor(
      width: number,
      height: number,
    ) {
      this.width = width;
      this.height = height;
    }
}
