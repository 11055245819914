<template lang="pug">
v-row
  v-col( cols="6")
    InputName( v-model.trim='datasource.name' label='Name' )

  v-col( cols="6" )
    InputAccountId( v-model.trim='datasource.params.accountId' )

  v-col( cols="6" )
    InputAwsRegion( v-model.trim='datasource.params.awsRegion' )

  v-col( cols="6" )
    InputRoleArn( v-model.trim='datasource.params.roleArn' )

</template>

<script lang="ts">
import {
  Vue, Component, VModel,
} from 'vue-property-decorator';
import { DatasourceDto, QuickSightParams } from '@/api';

type QuickSightSourceDto = DatasourceDto & { params: QuickSightParams; };

@Component
export default class QuickSightConnection extends Vue {
  @VModel({ type: Object }) datasource!: QuickSightSourceDto;
}
</script>
