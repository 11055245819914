<template lang="pug">
v-select(
  v-model="typology"
  :items="items"
  outlined
  dense
  :rules="rules"
  :item-text="(item) => showName(item.text)"
  :menu-props="{ bottom: true, offsetY: true }")
</template>

<script lang="ts">
// eslint-disable-next-line
// @ts-nocheck
import {
  Vue, Component, VModel, Prop,
} from 'vue-property-decorator';
import * as ConditionalRulesTYPES from '@/@types/conditional-rules';
import titleCase from '@/utils/filters/titleCase';

@Component
export default class ConditionalRulesConditionTypologyInput extends Vue {
  @VModel({ type: String }) readonly typology!: string;

  @Prop({ type: Object }) readonly field!: ConditionalRulesTYPES.ConditionalRulesField;

  get items(): { text: string, value: string; }[] {
    if (!this.isTimeType) {
      return Object.entries(ConditionalRulesTYPES.ConditionalRulesTypes).map(([value, text]) => ({
        text, value,
      })).filter((item) => item.value !== ConditionalRulesTYPES.ConditionalRulesTypes.TIME_PERIOD);
    }
    return Object.entries(ConditionalRulesTYPES.ConditionalRulesTypes).map(([value, text]) => ({
      text, value,
    }));
  }

  rules = [
    (v: any) => !!v || 'Type is required',
  ];

  get isTimeType() {
    return ConditionalRulesTYPES.isTimeType(this.field.type)
      || ConditionalRulesTYPES.isDateTimeType(this.field.type)
      || ConditionalRulesTYPES.isDateType(this.field.type);
  }

  showName(name: string) {
    return titleCase(name);
  }
}
</script>
