<template lang="pug">
v-menu( :close-on-content-click="false" )
  template(v-slot:activator="{ on, attrs }")
    v-text-field(
      v-model="date"
      label="Select Date"
      readonly
      outlined
      dense
      :rules="rules"
      v-bind="attrs"
      v-on="on" )
  v-date-picker(
    v-model="date"
    flat
    active-picker="false"
    min="1950-01-01"
    v-bind="$attrs"
    v-on="$listeners")
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ConditionalRulesConditionValueInputDate extends Vue {
  get date() {
    return this.$attrs.value;
  }

  set date(date: string) {
    this.$attrs.value = date;
  }

  rules = [
    (v: any) => !!v || 'Value is required',
  ];
}
</script>
