import {
 CatalogFilterDto, IncidentLightDto, IncidentService,
} from '@/api';
import {
  Action, Module, Mutation, VuexModule, config,
} from 'vuex-module-decorators';
import AuthModule from './auth';

type SearchParameters = Parameters<typeof IncidentService.getAllIncident>[0]['requestBody'];

config.rawError = true;

@Module({ namespaced: true, name: 'incidents' })
export default class IncidentsModule extends VuexModule {
  count = 100;

  incidents: IncidentLightDto[] = [];

  selectedIncidents: IncidentLightDto[] = [];

  loading = false;

  filters: CatalogFilterDto[] = [];

  savedSearch = '';

  tags: string[] = [];

  searchParameters: SearchParameters = {
    page: 1,
    itemsPerPage: 10,
    textSearch: '',
    criticality: [],
    user: [],
    status: [],
    sort: ['createdDate,DESC'],
    dataset: [],
  };

  sortConfiguration = [
    { value: 'createdDate', text: 'Creation date' },
    { value: 'lastModifiedDate', text: 'Last failure date' },
    { value: 'numberOfFailures', text: 'Number of failures' },
  ];

  get searchParametersWithDomain() {
    return {
      ...this.searchParameters,
      domain: this.selectedDomain?.name,
      tag: this.tags,
    };
  }

  get selectedDomain() {
    return this.context.rootState.auth.selectedDomain as AuthModule['selectedDomain'];
  }

  get getCount() { return this.count; }

  get getLoading() { return this.loading; }

  get getIncidents() { return this.incidents; }

  get searchString() { return this.searchParameters.textSearch; }

  get criticality() { return this.searchParameters.criticality; }

  get status() { return this.searchParameters.status; }

  get users() {
    return this.searchParameters.user;
  }

  get options() {
    return {
      itemsPerPage: this.searchParameters.itemsPerPage,
      page: this.searchParameters.page,
      sort: this.searchParameters.sort,
    };
  }

  get getSort() {
    return this.searchParameters.sort;
  }

  @Mutation
  setSort(sort: string[] | undefined) {
    this.searchParameters.page = 1;
    this.searchParameters.sort = sort;
  }

  @Mutation
  setOptions({ page, itemsPerPage, sort }: {
    page: number | undefined,
    itemsPerPage: number | undefined
    sort: string[] | undefined }) {
    this.searchParameters.page = page;
    this.searchParameters.itemsPerPage = itemsPerPage;
    this.searchParameters.sort = sort;
  }

  @Mutation
  setSearchString(search: string | undefined) {
    this.searchParameters.textSearch = search || '';
  }

  @Mutation
  setCriticality(criticality: number[] | undefined) {
    this.searchParameters.criticality = criticality as number[];
  }

  @Mutation
  setUsers(users: string[]) {
    this.searchParameters.user = users;
  }

  @Mutation
  setStatus(status: ('OPEN' | 'IN_PROGRESS' | 'CLOSED')[] | undefined) {
    this.searchParameters.status = status;
  }

  @Mutation
  setSelectedIncidents(incidents: IncidentLightDto[]) {
    this.selectedIncidents = incidents;
  }

  @Mutation
  resetSearchParameters() {
    this.searchParameters = {
      page: 1,
      itemsPerPage: 10,
      textSearch: '',
      criticality: [],
      user: [],
      status: [],
      sort: ['createdDate,DESC'],
      dataset: [],
    };
  }

  @Mutation
  setFilters(filters: CatalogFilterDto[]) {
    this.filters = filters;
  }

  @Mutation
  resetFilters() {
    this.searchParameters.criticality = [];
    this.searchParameters.status = [];
  }

  @Mutation
  resetState() {
    this.count = 0;
    this.incidents = [];
  }

  @Mutation
  setCount(count: number) {
    this.count = count;
  }

  @Mutation
  setIncidents(incidents: IncidentLightDto[]) {
    this.incidents = incidents;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  saveSearchParameters() {
    this.savedSearch = JSON.stringify(this.searchParameters);
  }

  @Mutation
  loadSearchParameters() {
    if (this.savedSearch) {
      const searchParameters = JSON.parse(this.savedSearch);
      this.searchParameters = searchParameters;
    }
  }

  @Mutation
  setTags(tags: string[]) {
    this.tags = tags;
  }

  @Action
  async updateSearch() {
    this.searchParameters.page = 1;
    await this.searchIncidents();
  }

  @Action
  async searchIncidents() {
    this.setLoading(true);
    this.setCount(0);
    this.setIncidents([]);
    const { catalogFilters, searchIncidents } = await IncidentService.getAllIncident({ requestBody: this.searchParametersWithDomain });
    this.setCount(searchIncidents.totalElements!);
    this.setIncidents(searchIncidents.data!);
    this.setFilters(catalogFilters);
    this.setLoading(false);
  }
}
