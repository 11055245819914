<template lang="pug">
v-item-group( mandatory :value="position" @change="changeHandler" )
  v-row
    v-col(
      v-for="params in datasourceParams"
      xl="2" lg='2' md='3' sm="4" cols="4"
      class="d-flex"
      :key="params.type")
      v-item( v-slot="{ active, toggle }" )
        DatasourceCard(
          :active="active"
          :disabled="disabled"
          :params="params"
          :is-beta="isBeta(params.type)"
          @click.native="toggle")
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DatasourceDto } from '@/api';
import { isSourceBeta } from '@/utils/betaComparator';

@Component
export default class ConnectorsList extends Vue {
  @Prop({ type: Object }) readonly value!: DatasourceDto;

  @Prop({ type: Boolean }) readonly disabled!: boolean;

  @Prop({ type: Array }) readonly datasourceParams!: DatasourceDto['params'][];

  BETA_SOURCES = [
      'athena',
      'hive',
      'oracle',
      'airflow',
      'fivetran',
      'synapse',
      'microstrategy',
  ];

  get position() {
    return this.datasourceParams.findIndex((d) => d.type === this.value.type);
  }

  isBeta(sourceType: string) {
    return isSourceBeta(sourceType);
  }

  changeHandler(value: number) {
    const params = this.datasourceParams[value];
    this.$emit('input', {
      ...this.value,
      type: params.type,
      params,
    });
  }
}
</script>
