/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMetadataJobRequestDto } from '../models/CreateMetadataJobRequestDto';
import type { CreateMetadataJobResponseDto } from '../models/CreateMetadataJobResponseDto';
import type { MetadataJobsSettingsDto } from '../models/MetadataJobsSettingsDto';
import type { SearchCollectionMetadataJobStatusDto } from '../models/SearchCollectionMetadataJobStatusDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MetadataJobsService {

    /**
     * Fetch the last job status of a metadata job for a given dataset
     * @returns SearchCollectionMetadataJobStatusDto Successfully fetched job status
     * @throws ApiError
     */
    public static getMetadataJobsStatuses({
        datasetUrn,
        metadataJobType,
        page,
        itemsPerPage = 1,
        sort = 'startDate,DESC',
    }: {
        datasetUrn: string,
        metadataJobType: 'CROCODILE',
        page?: number,
        itemsPerPage?: number,
        sort?: string,
    }): CancelablePromise<SearchCollectionMetadataJobStatusDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/metadata-jobs',
            query: {
                'datasetUrn': datasetUrn,
                'metadataJobType': metadataJobType,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a metadata generation job
     * @returns CreateMetadataJobResponseDto Metadata job created successfully
     * @throws ApiError
     */
    public static createMetadataJobs({
        requestBody,
    }: {
        requestBody: CreateMetadataJobRequestDto,
    }): CancelablePromise<CreateMetadataJobResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/metadata-jobs',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Fetch the value of a metadata jobs settings
     * @returns MetadataJobsSettingsDto Successfully fetched metadata jobs settings
     * @throws ApiError
     */
    public static getMetadataJobsSettings(): CancelablePromise<MetadataJobsSettingsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/metadata-jobs/settings',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
