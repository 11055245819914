<template lang="pug">
v-row( no-gutters data-cy="monitors-search" )
  v-col
    v-text-field.mr-4(
      ref="searchInput"
      v-model.trim="search"
      @click:clear="clearSearchHandler"
      @keyup.enter="searchHandler"
      prepend-inner-icon="icon-search"
      :placeholder="$t('monitors.search_placeholder')"
      data-cy="monitors-search-field"
      hide-details dense outlined clearable label persistent-hint )
  v-col( style="min-width: 250px; max-width: 250px;" )
    MonitorsSort

</template>

<script lang="ts">
import {
 Vue, Component, Ref, Watch,
} from 'vue-property-decorator';
import monitorsgModule from '@/store/modules/monitors';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';

const monitors = getModule(monitorsgModule, store);

@Component
export default class MonitorsSearch extends Vue {
  @Ref() searchInput!: HTMLInputElement;

  search = '';

  get searchValue() {
    return monitors.textSearch;
  }

  @Watch('searchValue')
  onSearchValueChange() {
    if (this.searchValue !== this.search) {
      this.search = this.searchValue!;
    }
  }

  clearSearchHandler() {
    this.search = '';
    this.searchHandler();
  }

  searchHandler() {
    monitors.setValues({ textSearch: this.search });
  }

  mounted() {
    this.search = monitors.textSearch ?? '';
    this.searchInput.focus();
  }
}
</script>
