<script setup lang="ts">import { ref as _ref } from 'vue';

// https://nhn.github.io/tui.editor/latest/ToastUIEditorViewer
// https://github.com/nhn/tui.editor/blob/master/docs/en/viewer.md
import { Viewer } from '@toast-ui/vue-editor';
import { watch } from 'vue';

interface SRichTextViewerProps {
  content?: string;
  height?: string;
  overflow?: 'hidden' | 'scroll' | 'auto';
}

const props = defineProps({
  content: { default: '' },
  height: { default: 'auto' },
  overflow: { default: 'auto' }
});

const viewer = _ref<Viewer>();

watch(() => props.content, () => {
  viewer.value?.invoke('setMarkdown', props.content);
});
</script>

<template lang="pug">
// TUI doesn't provide enough customization, I'm sorry for the inline style
Viewer.TuiViewer(
  ref="viewer"
  :style="`height: ${height}; overflow: ${overflow};`"
  :initialValue="content"
)
</template>
