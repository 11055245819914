<template lang="pug">
v-row
  v-col( cols="6")
    InputName( v-model.trim='datasource.name' label='Name' )

  v-col( cols="6" )
    InputWorkspaceId( v-model.trim='datasource.params.workspaceId' )

  v-col( cols="6" )
    InputTenantId( v-model.trim='datasource.params.tenantId' )

  v-col( cols="6" )
    InputClientId( v-model.trim='datasource.params.clientId' )

  v-col( cols="6")
    CredentialsSelector( v-model.trim='datasource.secretId' )

</template>

<script lang="ts">
import {
  Vue, Component, VModel,
} from 'vue-property-decorator';
import { DatasourceDto, PowerBiParams } from '@/api';

type PowerBiSourceDto = DatasourceDto & { params: PowerBiParams; };

@Component
export default class PowerBiConnection extends Vue {
  @VModel({ type: Object }) datasource!: PowerBiSourceDto;
}
</script>
