<template lang="pug">
InputSmartRegex( v-model="model"
  style="width: 600px;"
  :required="required"
  :rules="rules"
  :rule="rule")

</template>

<script lang="ts">
import { RuleDto } from '@/api/models/RuleDto';
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class SmartRegex extends Vue {
  @Prop({ type: String, default: {} }) value!: string;

  @Prop({ type: Object, required: true }) options!: any;

  @Prop({ type: Object, required: true }) schema!: any;

  @Prop({ type: Object, required: true }) fullSchema!: any;

  @Prop({ type: String, required: true }) fullKey!: any;

  @Prop({ type: String, default: '' }) label!: any;

  @Prop({ type: String, default: '' }) htmlDescription!: any;

  @Prop({ type: Boolean, default: false }) disabled!: any;

  @Prop({ type: Boolean, default: false }) required!: any;

  @Prop({ type: Array, required: true }) rules!: any;

  @Prop({ type: Object, required: true }) on!: any;

  @Prop({ type: Object, required: true }) rule!: RuleDto;

  get model() {
    return this.value;
  }

  set model(value) {
    this.on.input(value);
  }
}
</script>
