<template lang="pug">

div.d-flex.mt-2
  v-simple-checkbox(
      v-bind="$attrs"
      v-on="$listeners"
      data-cy="ssl"
      color="primary")
  span.grey--text {{ $t('sources.edit.enable_ssl') }}

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputSslFlag extends Vue {
}
</script>
