import colors from '@/plugins/colors';

export default function initialsToColor(initials: string) {
  if (!initials) return '';
  const avatarsColors = Object.values(colors.colorList);
  let sum = 0;
  for (let i = 0; i < initials.length; i += 1) {
    sum += initials.charCodeAt(i);
  }
  return avatarsColors[sum % avatarsColors.length];
}
