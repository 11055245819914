/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GroupIdentifierDto } from './GroupIdentifierDto';
import type { ParameterizedQueryDto } from './ParameterizedQueryDto';

export type RuleRunDetailsByGroupDto = {
    canShowFailingRows: boolean;
    debugSql?: ParameterizedQueryDto;
    dimension: string;
    group: GroupIdentifierDto;
    hasGraph: boolean;
    result?: string;
    status?: RuleRunDetailsByGroupDto.status;
};

export namespace RuleRunDetailsByGroupDto {

    export enum status {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        FAILED = 'FAILED',
        TECHNICAL_ERROR = 'TECHNICAL_ERROR',
        REQUIRES_YOUR_ATTENTION = 'REQUIRES_YOUR_ATTENTION',
    }


}
