import {
 UnleashClient, IMutableContext, IConfig, IVariant, IToggle,
} from 'unleash-proxy-client';
import Vue, { reactive } from 'vue';

let sharedProxy: {
    isReady: boolean;
    lastUpdate: number;
    client: UnleashClient;
    isEnabled(toggleName: string): boolean;
    getVariant(variant: string): IVariant;
    getAllToggles(): IToggle[];
    getContext(): IMutableContext;
    updateContext(context: IMutableContext): void;
    setContextField(field: string, value: string): void;
};

export function createClientProxy(client: UnleashClient) {
    sharedProxy = {
        isReady: false,
        lastUpdate: 0,
        client,
        isEnabled(toggleName: string) {
            return client.isEnabled(toggleName);
        },
        getVariant(variant: string) {
            return client.getVariant(variant);
        },
        getAllToggles() {
            return client.getAllToggles();
        },
        getContext() {
            return client.getContext();
        },
        updateContext(context: IMutableContext) {
            client.updateContext(context);
        },
        setContextField(field: string, value: string) {
            client.setContextField(field, value);
        },
    };
    return Vue.observable(sharedProxy);
}

export default {
    install(_: any, options: IConfig) {
        const client = new UnleashClient(options);

        const proxy = createClientProxy(client);

        client.on('ready', () => {
            proxy.isReady = true;
            proxy.lastUpdate = new Date().getTime();
        });

        client.on('update', () => {
            if (proxy.isReady) {
                proxy.lastUpdate = new Date().getTime();
            }
        });

        Vue.prototype.$features = proxy;

        client.start().then(() => client.emit('ready'));
    },
};

export function useFeatures() {
    const features = reactive(sharedProxy);

    return {
        features,
    };
}
