/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AsCodeMonitorDto } from '../models/AsCodeMonitorDto';
import type { AsCodeWorkspaceDto } from '../models/AsCodeWorkspaceDto';
import type { LightWorkspaceApplyRequestDto } from '../models/LightWorkspaceApplyRequestDto';
import type { RuleDto } from '../models/RuleDto';
import type { WorkspaceApplyResponseDto } from '../models/WorkspaceApplyResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class WorkspaceService {

    /**
     * Convert a monitor definition to it's as-code equivalent
     * @returns AsCodeMonitorDto Successfully converted monitor
     * @throws ApiError
     */
    public static convertMonitorToCode({
        requestBody,
        mode = 'RELAXED',
    }: {
        requestBody: RuleDto,
        mode?: 'RELAXED' | 'STRICT' | 'EXPANDED',
    }): CancelablePromise<AsCodeMonitorDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/rules/_convert-to-code',
            query: {
                'mode': mode,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                426: `Upgrade Required`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * List workspaces
     * @returns AsCodeWorkspaceDto Successfully fetch workspaces
     * @throws ApiError
     */
    public static listWorkspaces(): CancelablePromise<Array<AsCodeWorkspaceDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/workspaces',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete workspace
     * @returns WorkspaceApplyResponseDto Successfully fetch workspace
     * @throws ApiError
     */
    public static deleteWorkspace({
        id,
        cascadeDelete = 'ALL',
        dryRun = false,
    }: {
        id: string,
        cascadeDelete?: 'ALL' | 'MONITORS' | 'NONE',
        dryRun?: boolean,
    }): CancelablePromise<WorkspaceApplyResponseDto> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/workspaces/{id}',
            path: {
                'id': id,
            },
            query: {
                'cascadeDelete': cascadeDelete,
                'dryRun': dryRun,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Deploy workspace
     * @returns WorkspaceApplyResponseDto Successfully apply workspace state
     * @throws ApiError
     */
    public static deployWorkspace({
        id,
        requestBody,
        errorAction = 'ERROR',
        objectTrackAction = 'ERROR',
        objectMoveAction = 'ERROR',
        objectUntrackAction = 'ERROR',
        dryRun = false,
    }: {
        id: string,
        requestBody: LightWorkspaceApplyRequestDto,
        errorAction?: 'ERROR' | 'FATAL',
        objectTrackAction?: 'ERROR' | 'IGNORE' | 'TRACK',
        objectMoveAction?: 'ERROR' | 'IGNORE' | 'MOVE',
        objectUntrackAction?: 'ERROR' | 'IGNORE' | 'UNTRACK' | 'DELETE',
        dryRun?: boolean,
    }): CancelablePromise<WorkspaceApplyResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/workspaces/{id}',
            path: {
                'id': id,
            },
            query: {
                'errorAction': errorAction,
                'objectTrackAction': objectTrackAction,
                'objectMoveAction': objectMoveAction,
                'objectUntrackAction': objectUntrackAction,
                'dryRun': dryRun,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
