<template lang="pug">

ModalDialog( ref="modalDialog" :title="$t('users.set_timezone')" )

  template( v-slot:body )
    v-col(cols="12")
      v-checkbox( v-model="useBrowserTimezone" :label="label" )
      TimezoneSelector( :disabled="useBrowserTimezone" v-model="timezone" :key="timezone.timezone" )

  template( v-slot:actions )
    v-btn( color="primary" @click="update" depressed) {{ $t('common.words.save') }}

  </template>

<script lang="ts">
import {
  Vue, Component, Ref, Watch,
} from 'vue-property-decorator';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import authModule from '@/store/modules/auth';
import store from '@/store';

import { getModule } from 'vuex-module-decorators';
import { TimeZoneDto } from '@/api';
import dayjs from 'dayjs';

let auth: authModule;

@Component
export default class UserSetTimezone extends Vue {
  timezone: TimeZoneDto = {
    timezone: '',
    utcOffset: '',
  };

  userGuessedTimezone: TimeZoneDto = {
    timezone: dayjs.tz.guess(),
    utcOffset: dayjs().tz(dayjs.tz.guess()).format('Z'),
  };

  useBrowserTimezone = false;

  @Ref() modalDialog!: ModalDialog;

  @Watch('useBrowserTimezone')
  onUseBrowserTimezoneChange() {
    if (this.useBrowserTimezone) {
      this.timezone = { ...this.userGuessedTimezone };
    }
  }

  get userUseBrowserTimezone() {
    return this.timezone.timezone === this.userGuessedTimezone.timezone;
  }

  get label() {
    return `Use browser timezone (${this.userGuessedTimezone.utcOffset} ${this.userGuessedTimezone.timezone})`;
  }

  open() {
    this.timezone = auth.userTimezoneData!;
    if (this.userUseBrowserTimezone) {
      this.useBrowserTimezone = true;
    }
    this.modalDialog.openDialog();
  }

  close() {
    this.modalDialog.closeDialog();
  }

  async update() {
    if (this.useBrowserTimezone) {
      await auth.setUserTimezone();
    } else {
      await auth.setUserTimezone(this.timezone);
    }
    window.location.reload();
  }

  beforeCreate() {
    auth = getModule(authModule, store);
  }
}
</script>
