<template lang="pug">
notifications( width='400' :duration="5000")
  template( v-slot:body='{ item, close }')
    v-card(
      outlined
      class="sifflet-notification d-flex elevation-2 ma-3 pa-2")
      div( :class="`sifflet-notification-bar sifflet-notification-bar--${item.type || 'blue'}`" )
      div( :class="`sifflet-notification-background sifflet-notification-background--${item.type || 'blue'}`" )

      div( class="icon ml-1 mr-3 d-flex align-start justify-center" )
        v-icon( class="flex" :color="item.type || 'iconInfo'" ) {{ icon(item.type || 'blue') }}

      div.d-flex.flex-column.mt-0
        span( :class="`sifflet-notification-title--${item.type || 'blue'}`").font-weight-medium {{ item.title }}
        div( class="mr-6" style="z-index: 1")
          HtmlView( :html="item.text" class="text-subtitle-2 text-break" :class="`${item.type}--text`" )

      v-btn(class="sifflet-notification--close-btn" x-small icon @click="close" )
        v-icon.font-weight-medium(small) icon-dismiss

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class VNotification extends Vue {
  icon(type: string) {
    switch (type) {
      case 'error':
        return 'icon-error-circle-fill';
      case 'warning':
        return 'icon-warning-fill';
      case 'blue':
        return 'icon-info-circle-outline';
      case 'success':
        return 'icon-check-circle-outline';
      case 'purple':
        return 'icon-sparkles';
      default:
        return 'icon-info-circle-outline';
    }
  }
}
</script>

<style lang="scss" scoped>
// style of the notification itself
.sifflet-notification {
  border-radius: $border-radius-root;
  border: none;
  overflow: auto;
  position: relative;
  background: var(--v-bgPrimary-base);

  &-bar,
  &-background {
    &--blue {
      background-color: var(--v-bgAccent-base);
    }

    &--purple {
      background-color: var(--v-bgAiPrimary-base);
    }

    &--secondary {
      background-color: map-deep-get($grey, 'lighten-3');
    }

    &--success {
      background-color: var(--v-success-base);
    }

    &--warning {
      background-color: var(--v-warning-base);
    }

    &--error {
      background-color: var(--v-error-base);
    }
  }

  &-title {
    &--blue {
      color: map-deep-get($blue, 'base');
    }

    &--purple {
      color: map-deep-get($purple, 'base');
    }

    &--secondary {
      color: map-deep-get($grey, 'base');
    }

    &--success {
      color: map-deep-get($green, 'darken-1');
    }

    &--warning {
      color: map-deep-get($yellow, 'base');
    }

    &--error {
      color: map-deep-get($red, 'base');
    }
  }

  &-background {
    opacity: 0.2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &-bar {
    opacity: 0.4;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 8px;
    z-index: 1;
  }

  &--close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.vue-notification-group {
  top: 60px !important;
}
</style>
