/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AssetDataQualityDto } from './AssetDataQualityDto';
import type { AssetOverview } from './AssetOverview';
import type { AssetSchemaDto } from './AssetSchemaDto';

export type DatasetAssetOverview = (AssetOverview & {
    dataQuality?: AssetDataQualityDto;
    schema?: AssetSchemaDto;
    type?: DatasetAssetOverview.type;
});

export namespace DatasetAssetOverview {

    export enum type {
        JDBC_TABLE = 'JDBC_TABLE',
        JDBC_EXTERNAL_TABLE = 'JDBC_EXTERNAL_TABLE',
        JDBC_VIEW = 'JDBC_VIEW',
        JDBC_DATASET = 'JDBC_DATASET',
        JDBC_SNOWFLAKE_STREAM = 'JDBC_SNOWFLAKE_STREAM',
        JDBC_DATASET_REF = 'JDBC_DATASET_REF',
        STORAGE_BUCKET_S3 = 'STORAGE_BUCKET_S3',
        BIGQUERY_TABLE = 'BIGQUERY_TABLE',
        BIGQUERY_VIEW = 'BIGQUERY_VIEW',
        BIGQUERY_EXTERNAL_TABLE = 'BIGQUERY_EXTERNAL_TABLE',
        BIGQUERY_MATERIALIZED_VIEW = 'BIGQUERY_MATERIALIZED_VIEW',
        BIGQUERY_DATASET_REF = 'BIGQUERY_DATASET_REF',
        STORAGE_BUCKET_GCP = 'STORAGE_BUCKET_GCP',
        STORAGE_GOOGLE_DRIVE = 'STORAGE_GOOGLE_DRIVE',
        LOOKER_QUERY = 'LOOKER_QUERY',
        LOOKER_VIEW = 'LOOKER_VIEW',
        LOOKER_SQL_TABLE = 'LOOKER_SQL_TABLE',
        MICROSTRATEGY_DATASET_REF = 'MICROSTRATEGY_DATASET_REF',
        TABLEAU_QUERY = 'TABLEAU_QUERY',
        TABLEAU_DATABASE_TABLE = 'TABLEAU_DATABASE_TABLE',
        DBT_TABLE_REF = 'DBT_TABLE_REF',
        DBT_SOURCE_DATASET_REF = 'DBT_SOURCE_DATASET_REF',
        DBT_EPHEMERAL_REF = 'DBT_EPHEMERAL_REF',
        DBT_VIEW_REF = 'DBT_VIEW_REF',
        DBT_SEED = 'DBT_SEED',
        DBT_UNKNOWN = 'DBT_UNKNOWN',
        DBT_MODEL = 'DBT_MODEL',
        FIVETRAN_DATASET_REF = 'FIVETRAN_DATASET_REF',
        POWERBI_TABLE_REF = 'POWERBI_TABLE_REF',
        POWERBI_SEMANTIC_MODEL = 'POWERBI_SEMANTIC_MODEL',
        QUICKSIGHT_TABLE_REF = 'QUICKSIGHT_TABLE_REF',
        DATABRICKS_STREAMING_TABLE = 'DATABRICKS_STREAMING_TABLE',
        DATABRICKS_UNKNOWN = 'DATABRICKS_UNKNOWN',
        HIVE_MANAGED_TABLE = 'HIVE_MANAGED_TABLE',
        HIVE_VIRTUAL_VIEW = 'HIVE_VIRTUAL_VIEW',
        HIVE_EXTERNAL_TABLE = 'HIVE_EXTERNAL_TABLE',
        HIVE_DATASET_REF = 'HIVE_DATASET_REF',
        HIVE_HIVE_PROCESS = 'HIVE_HIVE_PROCESS',
        HIVE_SPARK_PROCESS = 'HIVE_SPARK_PROCESS',
        HIVE_UNKNOWN_PROCESS = 'HIVE_UNKNOWN_PROCESS',
        HIVE_HDFS_STORAGE = 'HIVE_HDFS_STORAGE',
        HIVE_UNKNOWN = 'HIVE_UNKNOWN',
        GENERIC_DROPPED_TABLE = 'GENERIC_DROPPED_TABLE',
        GENERIC_TEMP_TABLE = 'GENERIC_TEMP_TABLE',
    }


}
