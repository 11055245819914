/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProviderDto = {
    type?: ProviderDto.type;
};

export namespace ProviderDto {

    export enum type {
        GENERIC = 'GENERIC',
        DATASOURCE = 'DATASOURCE',
        USER = 'USER',
        ACCESS_TOKEN = 'ACCESS_TOKEN',
    }


}
