<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { watch } from 'vue';
import { VSelect } from 'vuetify/lib/components';
import { PublicCalendarGetDto } from '@/api';
import i18n from '@/i18n';

interface Props {
  items?: PublicCalendarGetDto[],
  loading?: boolean,
  value?: string | string[],
  placeholder?: string,
  label?: string,
  multiple?: boolean,
  rules?: (((input: any) => boolean | string) | boolean | string)[],
}
const props = defineProps({
  items: { default: () => [] },
  loading: { type: Boolean, default: false },
  value: null,
  placeholder: null,
  label: null,
  multiple: { type: Boolean, default: false },
  rules: { default: () => [] }
});
interface Emit {
  (event: 'input', value: string): void
}
const emit = defineEmits(["input"]);

const selectRef = _ref<(InstanceType<typeof VSelect> & { validate: () => boolean }) | null>(null);

const daysOfTheWeekCalendar = [PublicCalendarGetDto.standardCalendar.SUNDAYS, PublicCalendarGetDto.standardCalendar.WEEKENDS];

const allItems = _computed(() => {
  // VJSF automatically adds the current value into the list of possible items, which is not what we want
  const items = props.items.filter((t) => t.id);

  const result: { text?: string, value?: string, divider?: boolean, header?: string }[] = [];
  result.push({ header: i18n.t('monitor_wizard.monitor_settings.calendars.public_holidays') });
  for (const item of items.filter((c) => c.standardCalendar && !daysOfTheWeekCalendar.includes(c.standardCalendar))) {
    result.push({ text: item.name, value: item.id });
  }
  result.push({ header: i18n.t('monitor_wizard.monitor_settings.calendars.days_of_the_week') });
  for (const item of items.filter((c) => c.standardCalendar && daysOfTheWeekCalendar.includes(c.standardCalendar))) {
    result.push({ text: item.name, value: item.id });
  }
  result.push({ header: i18n.t('monitor_wizard.monitor_settings.calendars.custom_calendars') });
  for (const item of items.filter((c) => !c.standardCalendar)) {
    result.push({ text: item.name, value: item.id });
  }

  return result;
});

const allRules = _computed(() => [...props.rules, (value: string | string[]) => {
  const allowedValues = allItems.value.map((item) => item.value);
  if (Array.isArray(value)) {
    return value.every((val) => allowedValues.includes(val));
  }
  return allowedValues.includes(value);
}]);

watch(() => allItems.value, () => {
  // Validation is not done by default on allItems update
  selectRef.value?.validate();
});
</script>

<template lang="pug">
v-select(
  ref="selectRef"
  :placeholder="placeholder"
  :rules="allRules"
  :label="label"
  :items="allItems"
  :loading="loading"
  :multiple="multiple"
  :value="value"
  @input="emit('input', $event)"
  outlined
  dense
  menu-props="offset-y")
</template>

<style lang="scss" scoped>
:deep(.v-subheader) {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: var(--v-textPrimary-base) !important;
}
</style>
