<template lang="pug">
.nested-sheet
  table.header
    thead
      tr
        th.text-left.text-truncate( v-for="th in header" :key="th.text" :title="th.text")
          .text-no-wrap.subtitle-1.font-weight-medium.text--darken-1 {{ capitalize(th.text) }}
          .d-block.text-caption.mono.grey--text {{ capitalize(th.type) }}
  table.content( :style="{ width }")
    tbody
      NestedSheetSimpleTable( :value="body" )
</template>

<script lang="ts">
import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';
import CreateTable from '@/utils/previewTable';
import capitalizeFirst from '@/utils/filters/capitalizeFirst';

@Component
export default class NestedSheet extends Vue {
  @Prop() items!: any[];

  @Prop() schema!: any;

  header: any[] = [];

  body: any[] = [];

  get width() {
    return `${this.header.length * 150}px`;
  }

  capitalize(string: string) {
    return capitalizeFirst(string);
  }

  @Watch('items')
  @Watch('schema')
  create() {
    const table = new CreateTable(this.items, this.schema);
    this.header = [...table.header.values()];
    this.body = [...table.body.values()];
  }

  mounted() {
    this.create();
  }
}
</script>

<style lang="scss">
table {
  margin: 0 !important;
  border-collapse: collapse !important;
}

.nested-sheet {
  overflow: scroll;
  // height: 500px;
  position: relative;

  table.header {
    border: 0;
    background-color: var(--v-bgPrimary-base);
    position: sticky;
    top: 0;
    z-index: 3;
    border-collapse: collapse;

    thead {
      th {
        min-width: 150px;
        max-width: 150px;
        background-color: var(--v-bgPrimary-base);
        border-bottom: 1px solid var(--v-borderPrimary-base);
        padding-left: 5px;
      }
    }
  }

  table.content {
    background-color: var(--v-bgPrimary-base);
    background-image: url("/public/next/images/cell.png");
    background-size: 150px 30px;
    background-repeat: repeat;
    // position: absolute;
    top: 45px;
    z-index: 2;
    border-collapse: collapse;

    table {
      // position: relative;
    }

    tbody {
      tr {}

      th,
      td {
        vertical-align: top;
        min-width: 150px;
        height: 30px;
        margin: 0;
        text-align: left;
      }
    }
  }
}
</style>
