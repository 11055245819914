<template lang="pug">
ModalDialog(
  ref="modalDialogRef"
  content-class="monitors-yaml-modal"
  :title="$t('monitors.yaml_monitor_code')"
  :subtitle="subtitle"
  @close="clearCode")

  template( v-slot:body )
    v-row( no-gutters )
      v-col(cols="12")
        InputCode(
          v-if="yamlCode"
          :value="yamlCode"
          language="yaml"
          resize-behavior="none"
          read-only )

  template( v-slot:actions )
    v-btn( depressed color="primary" @click="copyYaml")
      v-icon( left ) $content_copy
      | {{ $t('common.words.copy_to_clipboard') }}

</template>

<script lang="ts">
import {
  Component, Vue, Ref,
} from 'vue-property-decorator';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import InputCode from '@/components/inputs/Input-Code.vue';
import titleCase from '@/utils/filters/titleCase';
import copyToClipboard from '@/utils/copyToClipboard';

@Component({
  components: { InputCode },
})
export default class MonitorsYamlModal extends Vue {
  yamlCode = '';

  @Ref() modalDialogRef! : ModalDialog;

  public setCode(code: string) {
    this.yamlCode = code;
  }

  get subtitle() {
    // find line with template attribute and extract template name
    const name = this.yamlCode.split('\n').find((line) => line.includes('template:'))?.split(':')[1].trim();
    return titleCase(name || '');
  }

  clearCode() {
    this.yamlCode = '';
  }

  open() {
    this.modalDialogRef.openDialog();
  }

  copyYaml() {
    if (this.yamlCode) {
      copyToClipboard(this.yamlCode, this.$t('common.words.copied_to_clipboard') as string);
    }
  }
}
</script>
