<script setup lang="ts">import { computed as _computed } from 'vue';

import type { Location } from 'vue-router';
import { getModule } from 'vuex-module-decorators';
import { computed, ref } from 'vue';
import i18n from '@/i18n';
import {
  DagAssetOverview,
  DashboardAssetOverview,
  DatasetAssetOverview,
  AssetService,
  CancelablePromise,
} from '@/api';
import type {
  DatasetDetailsDto,
  DatasetParamsDto,
  AssetPatchDto,
  DagDetailsDto,
  FieldDto,
  TransformationAssetOverview,
} from '@/api';
import SqlDialog from '@/components/sql-dialog/Sql-Dialog.vue';
import fromNow from '@/utils/filters/fromNow';
import timeTo from '@/utils/filters/timeTo';
import src from '@/utils/filters/src';
import type EditDialog from '@/components/edit-dialog/Edit-Dialog.vue';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import authModule from '@/store/modules/auth';
import store from '@/store';
import { useFeatures } from '@/plugins/feature-flag';
import SRichTextViewer from '@/components/SRichTextViewer.vue';

interface AssetOverviewDetailProps {
  urn: string;
  overviewData: DagAssetOverview | DashboardAssetOverview | DatasetAssetOverview | TransformationAssetOverview | undefined;
  isSmall?: boolean;
}

type AssetOverviewDetailEmits = {
  (event: 'update'): void
}

const { features } = useFeatures();
const auth = getModule(authModule, store);
const emit = defineEmits(["update"]);
const props = defineProps({
  urn: null,
  overviewData: null,
  isSmall: { type: Boolean, default: false }
});

const sqlDialogRef = ref<SqlDialog | null>(null);
const modalDialogRef = ref<ModalDialog | null>(null);
const editDialogRef = ref<InstanceType<typeof EditDialog> | null>(null);

const openPreview = () => modalDialogRef!.value!.openDialog();
const closePreview = () => modalDialogRef!.value!.closeDialog();

const getImage = (platform: string) => src(platform, 'datasources');

const canPreview = computed(() => auth.userActions['metadata.asset.write']);
const canEdit = computed(() => auth.userActions['metadata.asset.write']);
const cols = computed(() => (props.isSmall ? 12 : 6));
const md4 = computed(() => (props.isSmall ? 12 : 4));
const md8 = computed(() => (props.isSmall ? 12 : 8));

const datasourceRoute = computed<Location>(() => ({
  name: 'sources.source.overview',
  params: { id: props.overviewData?.details?.datasourceId! ?? 'null' },
}));

const timeWindow = computed(() => {
  if (!props.overviewData) return null;
  return ('timeWindow' in props.overviewData.details!)
    ? props.overviewData.details!.timeWindow : null;
});

const details = computed(() => props.overviewData?.details);
const datasourceName = computed(() => (details?.value?.datasourceName ?? ''));
const lineagePlatform = computed(() => (details?.value?.lineagePlatform ?? ''));
const lastRefresh = computed(() => fromNow(details?.value?.lastRefresh as string));
const nextRefresh = computed(() => (timeTo(details?.value?.nextRefresh as string) ? `${i18n.t('assets.metadata_refresh_value', { value: timeTo(details?.value?.nextRefresh as string) })}` : '-'));

const size = computed(() => {
  const d = details as any;
  return d?.size! ?? {};
});

const usage = _computed(() => details?.value?.usage);
const tagsValue = computed(() => details?.value?.tags ?? []);
const termsValue = computed(() => details?.value?.terms ?? []);
const ownersValue = computed(() => details?.value?.owners ?? []);
const descriptionValue = computed(() => details?.value?.description ?? '-');

const externalDescriptionsValue = computed(() => {
  if (!props.overviewData) return null;
  return ('externalDescriptions' in props.overviewData.details!)
    ? props.overviewData.details!.externalDescriptions : null;
});

const transformation = _computed(() => {
  const d: any = details.value as any;
  return d?.transformation ?? null;
});

const hasTransformation = computed(() => Boolean(transformation.value));

const hasPreview = computed(() => {
  // By default, the preview feature is enabled, some clients wants to disable it
  const shouldHidePreview = features.isEnabled('metadata-asset-preview-disabled');

  return props.overviewData?.details?.hasPreview && !shouldHidePreview;
});

const actionable = computed(() => props.overviewData?.details?.actionable);

const hasUsage = computed(() => usage.value && 'qualification' in usage.value);

const routeToOverview = _computed<Location>(() => ({
  name: 'data-catalog.asset.overview',
  params: { urn: props.urn },
}));

const editAsset = () => {
  if (details.value) {
    const {
      tags,
      terms,
      owners,
      description,
    } = details.value as DatasetDetailsDto | DagDetailsDto;
    const { urn } = props;
    editDialogRef.value!.setEntities([{
      urn, tags, terms, description, owners,
    }]);
  }
};

const editDialogUpdateHandler = async ({ entities, patches }: { entities: string[], patches: { description?: string, tags?: string[], owners?: string[] } | { description?: string, tags?: string[], owners?: [] }[] }) => {
  let promises: CancelablePromise<FieldDto>[];
  if (Array.isArray(entities) && Array.isArray(patches)) {
    promises = entities.map((urn, index) => {
      const entityPatch = patches[index];
      return AssetService.patchAsset({ urn, requestBody: entityPatch });
    });
  } else {
    const requestBody = patches as AssetPatchDto;
    promises = entities.map((urn) => AssetService.patchAsset({ urn, requestBody }));
  }
  await Promise.all(promises);
  emit('update');
};

const updateTimeWindow = async (requestBody: DatasetParamsDto) => {
  await AssetService.patchAssetParams({
    urn: props.urn,
    requestBody,
  });
  emit('update');
};

</script>

<template lang="pug">
v-card( :outlined="!isSmall" class="my-4 py-4 px-4" min-height="300px")
  v-row
    v-col( v-if="!isSmall" cols="12" md="auto" )
      .text-h6.font-weight-medium {{ $t('assets.details') }}
    v-spacer

    v-col( md="auto" )
      .d-flex.justify-end

        SButton(
          v-if="!isSmall && actionable"
          icon="icon-edit"
          :text="$t('assets.edit')"
          @click="editAsset"
          :disabled="!canEdit"
          color="secondary"
          variant="outlined")
          template(#tooltip v-if="!canEdit")
            | {{ $t('assets.edit') }}

        SButton(
          v-else-if="actionable"
          icon="icon-book"
          :text="$t('assets.view_details')"
          :tooltip="canEdit ? null : $t('app.rights.suggestions_no_rights')"
          :to="routeToOverview"
          color="secondary"
          variant="outlined"
          size="small")

        SButton.ml-2(
          v-if="actionable"
          icon="icon-code-brackets"
          :text="$t('assets.show_sql')"
          :size="isSmall ? 'small' : 'default'"
          :disabled="!hasTransformation"
          :tooltip="hasTransformation ? null : $t('assets.sql_not_available')"
          @click="sqlDialogRef?.open()"
          color="secondary"
          variant="outlined")

        SButton.ml-2(
          v-if="hasPreview && actionable"
          icon="icon-eye"
          :text="$t('assets.preview_data')"
          :size="isSmall ? 'small' : 'default'"
          :disabled="!canPreview || !hasPreview"
          :tooltip="canPreview ? null : $t('app.rights.suggestions_no_rights')"
          @click="openPreview"
          color="secondary"
          variant="outlined")

  v-divider( v-if="isSmall" class="my-4" )

  v-row( :no-gutters="isSmall" :class="{ 'mb-2': isSmall }" )
    v-col(  :cols="cols" :md="md4" )
      span {{ $t('assets.source') }}
    v-col( :cols="cols" :md="md8" )
      span.d-flex
        v-img( :src="getImage(lineagePlatform)" width=16 height=16 class="flex-grow-0 flex-shrink-0 mr-1 align-self-center")
        RouterLink.link.ml-1( :to="datasourceRoute" class="text-truncate" ) {{ datasourceName }}

  v-row( :no-gutters="isSmall" :class="{ 'mb-2': isSmall }" )
    v-col(  :cols="cols" :md="md4" )
      span {{ $t('assets.description') }}
    v-col( :cols="cols" :md="md8" )

      ExternalDescriptions.mb-2(
        v-if="externalDescriptionsValue"
        :descriptions="externalDescriptionsValue" should-show-richtext)
      SRichTextViewer.grey--text.viewer(:content="descriptionValue")

  v-row( :no-gutters="isSmall" :class="{ 'mb-2': isSmall }" )
    v-col(  :cols="cols" :md="md4" )
      span {{ $t('assets.last_metadata_refresh') }}
    v-col( :cols="cols" :md="md8" )
      span.grey--text {{ lastRefresh }}

  v-row( :no-gutters="isSmall" :class="{ 'mb-2': isSmall }" )
    v-col(  :cols="cols" :md="md4" )
      span {{ $t('assets.next_metadata_refresh') }}
    v-col( :cols="cols" :md="md8" )
      span.grey--text {{ nextRefresh }}

  v-row( v-if="hasUsage" :no-gutters="isSmall" :class="{ 'mb-2': isSmall }" )
    v-col(  :cols="cols" :md="md4" )
      .d-inline-flex {{ $t('assets.data_usage') }}
      .d-inline-flex
        v-tooltip( top max-width="200" )
          template(v-slot:activator='{ on }')
            v-icon.ml-2( small v-on="on" ) icon-question-circle-outline
          span {{ $t('data-catalog.usage_tooltip') }}
          div.tooltip-arrow-bottom
    v-col( :cols="cols" :md="md8" )
      .d-inline-block
        DataUsageExtended( :value="usage" )

  v-row( v-if="timeWindow" :no-gutters="isSmall" :class="{ 'mb-2': isSmall }" )
    v-col(  :cols="cols" :md="md4" )
      span {{ $t('assets.time_window_offset') }}
    v-col( :cols="cols" :md="md8" )
      InputTimeWindowEdit(
        :can-edit="canEdit"
        :value="timeWindow"
        :urn="urn"
        @update="updateTimeWindow" )

  v-row( :no-gutters="isSmall" :class="{ 'mb-2': isSmall }" )
    v-col(  :cols="cols" :md="md4" )
      span {{ $t('assets.tags') }}
    v-col( :cols="cols" :md="md8" )
      Tags( v-if="tagsValue.length" :tags="tagsValue" )
      span( v-else ) -

  v-row( :no-gutters="isSmall" :class="{ 'mb-2': isSmall }" )
    v-col(  :cols="cols" :md="md4" )
      span {{ $t('assets.business_terms') }}
    v-col( :cols="cols" :md="md8" )
      Terms( v-if="termsValue.length" :terms="termsValue" )
      span( v-else ) -

  v-row( :no-gutters="isSmall" :class="{ 'mb-2': isSmall }" )
    v-col(  :cols="cols" :md="md4" )
      span {{ $t('assets.owners') }}
    v-col( :cols="cols" :md="md8" )
      Owners( v-if="ownersValue.length" :owners="ownersValue" isOverview )
      span( v-else ) -

  EditDialog(
    :fields="['tags', 'terms', 'description', 'owners']"
    ref="editDialogRef"
    @update="editDialogUpdateHandler"
    has-rich-text
  )

  SqlDialog( v-if="hasTransformation" ref="sqlDialogRef" :sql-statement="transformation")

  ModalDialog(
    ref="modalDialogRef"
    :title="$t('common.words.preview')"
    :disableCancel="true" full )

    template( v-slot:body )
      AssetPreview(:urn="urn")

    template( v-slot:actions )
      div.text-end
        v-btn(
          color='primary'
          depressed
          @click='closePreview') {{ $t('common.words.close') }}

</template>

<style lang="scss">
.viewer {
  max-height: 400px;
}
</style>
