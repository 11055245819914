/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AlertingHookDto = {
    createdBy?: string;
    createdDate?: string;
    externalHook: string;
    id: string;
    lastModifiedDate?: string;
    modifiedBy?: string;
    name: string;
    type: AlertingHookDto.type;
};

export namespace AlertingHookDto {

    export enum type {
        SLACK = 'SLACK',
        MAIL = 'MAIL',
        MS_TEAMS = 'MS_TEAMS',
    }


}
