import { LineageEntityDto } from '@/api';
import colors from '@/plugins/colors';
import * as d3 from 'd3';
import i18n from '@/i18n';
import Node from './Node';
import * as CONSTANTS from './constants';

const createCriticalIcon = (qualityIcon: d3.Selection<SVGGElement, unknown, null, undefined>) => {
  const rect = qualityIcon
    .append('g')
    .attr('transform', 'scale(0.75) translate(8, 1.5)')
    .attr('width', '24px')
    .attr('height', '24px');

  rect
    .append('path')
    .attr(
      'd',
      'M12 2C17.523 2 22 6.478 22 12C22 17.522 17.523 22 12 22C6.477 22 2 17.522 2 12C2 6.478 6.477 2 12 2ZM12.0018 15.0037C11.4503 15.0037 11.0031 15.4508 11.0031 16.0024C11.0031 16.5539 11.4503 17.001 12.0018 17.001C12.5533 17.001 13.0005 16.5539 13.0005 16.0024C13.0005 15.4508 12.5533 15.0037 12.0018 15.0037ZM11.9996 7C11.4868 7.00018 11.0643 7.38638 11.0067 7.88374L11 8.00036L11.0018 13.0012L11.0086 13.1179C11.0665 13.6152 11.4893 14.0011 12.0022 14.0009C12.515 14.0007 12.9375 13.6145 12.9951 13.1171L13.0018 13.0005L13 7.99964L12.9932 7.88302C12.9353 7.3857 12.5125 6.99982 11.9996 7Z',
    )
    .attr('fill', colors.red.base);

  qualityIcon
    .append('text')
    .attr('x', 30)
    .attr('y', 14)
    .attr('fill', colors.red.darken1)
    .attr('font-size', '12px')
    .attr('font-weight', '500')
    .attr('font-family', '"DM Sans"')
    .text(i18n.tc('qualityStatus.critical'));
};

// create at risk icon
const createAtRiskIcon = (qualityIcon: d3.Selection<SVGGElement, unknown, null, undefined>) => {
  const rect = qualityIcon
    .append('g')
    .attr('transform', 'scale(0.75) translate(8, 1.5)')
    .attr('width', '24px')
    .attr('height', '24px');

  rect
    .append('path')
    .attr(
      'd',
      'M10.0297 3.65893C10.8859 2.1111 13.1111 2.11108 13.9673 3.65888L21.7131 17.6603C22.5427 19.16 21.4581 20.9995 19.7443 20.9995H4.25323C2.53945 20.9995 1.45483 19.16 2.28438 17.6604L10.0297 3.65893ZM12.9973 17.0009C12.9973 16.4494 12.5502 16.0022 11.9987 16.0022C11.4471 16.0022 11 16.4494 11 17.0009C11 17.5524 11.4471 17.9996 11.9987 17.9996C12.5502 17.9996 12.9973 17.5524 12.9973 17.0009ZM12.7381 9.14764C12.6882 8.7816 12.3742 8.4997 11.9945 8.5C11.5802 8.50033 11.2447 8.83639 11.2451 9.2506L11.2487 13.7522L11.2556 13.854C11.3055 14.22 11.6196 14.5019 11.9993 14.5016C12.4135 14.5013 12.749 14.1652 12.7487 13.751L12.7451 9.2494L12.7381 9.14764Z',
    )
    .attr('fill', colors.yellow.base);

  qualityIcon
    .append('text')
    .attr('x', 30)
    .attr('y', 14)
    .attr('fill', colors.yellow.darken1)
    .attr('font-size', '12px')
    .attr('font-weight', '500')
    .attr('font-family', '"DM Sans"')
    .text(i18n.tc('qualityStatus.at_risk'));
};

// create healthy icon
const createHealthyIcon = (qualityIcon: d3.Selection<SVGGElement, unknown, null, undefined>) => {
  const rect = qualityIcon
    .append('g')
    .attr('transform', 'scale(0.75) translate(8, 1.5)')
    .attr('width', '24px')
    .attr('height', '24px');

    rect
    .append('path')
    .attr(
      'd',
      'M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM15.2197 8.96967L10.75 13.4393L8.78033 11.4697C8.48744 11.1768 8.01256 11.1768 7.71967 11.4697C7.42678 11.7626 7.42678 12.2374 7.71967 12.5303L10.2197 15.0303C10.5126 15.3232 10.9874 15.3232 11.2803 15.0303L16.2803 10.0303C16.5732 9.73744 16.5732 9.26256 16.2803 8.96967C15.9874 8.67678 15.5126 8.67678 15.2197 8.96967Z',
    )
    .attr('fill', colors.green.lighten1);

  qualityIcon
    .append('text')
    .attr('x', 30)
    .attr('y', 14)
    .attr('fill', colors.green.darken1)
    .attr('font-size', '12px')
    .attr('font-weight', '500')
    .attr('font-family', '"DM Sans"')
    .text(i18n.tc('qualityStatus.healthy'));
};

// create unmonitored icon
const createUnmonitoredIcon = (qualityIcon: d3.Selection<SVGGElement, unknown, null, undefined>) => {
  const rect = qualityIcon
    .append('g')
    .attr('transform', 'scale(0.75) translate(8, 1.5)')
    .attr('width', '24px')
    .attr('height', '24px');

    rect
    .append('path')
    .attr(
      'd',
      'M20 12C20 13.3912 19.6449 14.6994 19.0204 15.839L20.4815 17.3001C21.4437 15.7635 22 13.9467 22 12C22 6.47715 17.5228 2 12 2C10.0533 2 8.23659 2.55623 6.70003 3.51843L8.1611 4.97954C9.30068 4.35507 10.6089 4 12 4C16.4183 4 20 7.58172 20 12Z',
    )
    .attr('fill', colors.grey.base);

  rect
    .append('path')
    .attr(
      'd',
      'M4.41832 5.47914C2.91111 7.22995 2 9.50857 2 12C2 17.5228 6.47715 22 12 22C14.4914 22 16.7699 21.0889 18.5207 19.5818L20.7194 21.7806C21.0123 22.0735 21.4872 22.0735 21.7801 21.7806C22.073 21.4877 22.073 21.0128 21.7801 20.7199L3.28034 2.21979C2.98745 1.92689 2.51257 1.92689 2.21968 2.21978C1.92678 2.51267 1.92677 2.98754 2.21966 3.28044L4.41832 5.47914ZM5.83749 6.89834L17.1015 18.1626C15.7168 19.3102 13.939 20 12 20C7.58172 20 4 16.4183 4 12C4 10.061 4.68984 8.28308 5.83749 6.89834Z',
    )
    .attr('fill', colors.grey.base);

  qualityIcon
    .append('text')
    .attr('x', 30)
    .attr('y', 14)
    .attr('fill', colors.grey.base)
    .attr('font-size', '12px')
    .attr('font-weight', '500')
    .attr('font-family', '"DM Sans"')
    .text(i18n.tc('qualityStatus.unmonitored'));
};

const lineageCreateQuality = (cardBody: d3.Selection<SVGGElement, Node, SVGGElement, unknown>) => {
  const qualityIcon = cardBody
  .append('g')
  .attr('transform', (d) => (d.data.attachedEntity
    ? `translate(${CONSTANTS.DEFAULT_FIELD_NODE_WIDTH - 188}, ${CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT + 56})`
    : `translate(${CONSTANTS.DEFAULT_FIELD_NODE_WIDTH - 188}, 56)`));

  qualityIcon
    .append('rect')
    // eslint-disable-next-line no-confusing-arrow
    .attr('visibility', (_node: Node) => _node.data.health === LineageEntityDto.health.NOT_SUPPORTED ? 'hidden' : 'visible')
    .attr('width', (_node: Node) => {
      switch (_node.data.health) {
        case LineageEntityDto.health.CRITICAL:
          return '130px';
        case LineageEntityDto.health.AT_RISK:
          return '141px';
        case LineageEntityDto.health.HEALTHY:
          return '108px';
        case LineageEntityDto.health.UNMONITORED:
          return '119px';
        case LineageEntityDto.health.NOT_SUPPORTED:
          return '0px';
        default:
          return '0px';
      }
    })
    .attr('height', '20px')
    .attr('rx', 10)
    .attr('stroke', colors.grey.darken4)
    .attr('fill', 'transparent')
    .attr('stroke-width', '1')
    .attr('stroke', (_node: Node) => {
      switch (_node.data.health) {
        case LineageEntityDto.health.CRITICAL:
          return colors.red.lighten2;
        case LineageEntityDto.health.AT_RISK:
          return colors.yellow.lighten1;
        case LineageEntityDto.health.HEALTHY:
          return colors.green.lighten2;
        case LineageEntityDto.health.UNMONITORED:
          return colors.grey.lighten3;
        case LineageEntityDto.health.NOT_SUPPORTED:
          return 'transparent';
        default:
          return 'transparent';
      }
    });

  qualityIcon.each(function test(_node: Node) {
    const body = d3.select(this);
    const { data: _data } = _node;

    switch (_node.data.health) {
      case LineageEntityDto.health.CRITICAL:
        createCriticalIcon(body);
        break;
      case LineageEntityDto.health.AT_RISK:
        createAtRiskIcon(body);
        break;
      case LineageEntityDto.health.HEALTHY:
        createHealthyIcon(body);
        break;
      case LineageEntityDto.health.UNMONITORED:
        createUnmonitoredIcon(body);
        break;
      case LineageEntityDto.health.NOT_SUPPORTED:
        break;
      default:
        break;
    }
  });
};

export default lineageCreateQuality;
