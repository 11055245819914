/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AssignOwnersPayload } from './AssignOwnersPayload';
import type { CollaborationToolDisconnectedEventPayload } from './CollaborationToolDisconnectedEventPayload';
import type { CollaborationToolItemLinkedEventPayload } from './CollaborationToolItemLinkedEventPayload';
import type { CollaborationToolItemUnlinkedEventPayload } from './CollaborationToolItemUnlinkedEventPayload';
import type { CreateCommentPayload } from './CreateCommentPayload';
import type { EntityUrn } from './EntityUrn';
import type { IncidentCreatedByRuleRunPayload } from './IncidentCreatedByRuleRunPayload';
import type { IncidentNameUpdatePayload } from './IncidentNameUpdatePayload';
import type { IncidentNewFailurePayload } from './IncidentNewFailurePayload';
import type { IncidentRuleMovedPayload } from './IncidentRuleMovedPayload';
import type { IncidentSeverityUpdatePayload } from './IncidentSeverityUpdatePayload';
import type { IncidentStatusUpdatePayload } from './IncidentStatusUpdatePayload';

export type EventDto = {
    createdBy?: string;
    createdDate?: string;
    id?: string;
    payload?: (AssignOwnersPayload | CollaborationToolDisconnectedEventPayload | CollaborationToolItemLinkedEventPayload | CollaborationToolItemUnlinkedEventPayload | CreateCommentPayload | IncidentCreatedByRuleRunPayload | IncidentNameUpdatePayload | IncidentNewFailurePayload | IncidentRuleMovedPayload | IncidentSeverityUpdatePayload | IncidentStatusUpdatePayload);
    publisherUrn?: EntityUrn;
    resourceUrn?: EntityUrn;
    type?: EventDto.type;
};

export namespace EventDto {

    export enum type {
        INCIDENT_CREATED = 'INCIDENT_CREATED',
        COMMENT_CREATED = 'COMMENT_CREATED',
        STATUS_UPDATED = 'STATUS_UPDATED',
        OWNERS_ASSIGNED = 'OWNERS_ASSIGNED',
        NEW_FAILURE = 'NEW_FAILURE',
        NAME_UPDATED = 'NAME_UPDATED',
        SEVERITY_UPDATED = 'SEVERITY_UPDATED',
        RULE_MOVED = 'RULE_MOVED',
        COLLABORATION_TOOL_ITEM_LINKED = 'COLLABORATION_TOOL_ITEM_LINKED',
        COLLABORATION_TOOL_ITEM_UNLINKED = 'COLLABORATION_TOOL_ITEM_UNLINKED',
        COLLABORATION_TOOL_DISCONNECTED = 'COLLABORATION_TOOL_DISCONNECTED',
    }


}
