/**
 * Formats an enum string into a readable format
 * @param {string} word - A string that supposely has underscores
 * @return string - Return a readable capitalized string with spaces instead of underscores
 * @example - enum_john_doe => Enum John Doe
 */
export default (word: string) => {
  if (!word) return '';
  return word.split('_')
  .map((_word) => _word[0].toUpperCase() + _word.slice(1).toLowerCase())
  .join(' ');
};
