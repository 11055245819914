/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TextToRegexPredictionRequestDto } from '../models/TextToRegexPredictionRequestDto';
import type { TextToRegexPredictionResponseDto } from '../models/TextToRegexPredictionResponseDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TextToRegexService {

    /**
     * Suggests a regular expression that corresponds to the text input computed by ML model
     * @returns TextToRegexPredictionResponseDto Regex successfully generated
     * @throws ApiError
     */
    public static textToRegex({
        requestBody,
    }: {
        requestBody: TextToRegexPredictionRequestDto,
    }): CancelablePromise<TextToRegexPredictionResponseDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/ai-predictions/_text-to-regex',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
