<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    label='Atlas Principal'
    data-cy="atlasPrincipal"
    dense clearable outlined required)
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputAtlasPrincipal extends Vue {}
</script>
