/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetCollaborationToolItemDto } from './GetCollaborationToolItemDto';
import type { UserDto } from './UserDto';

export type IncidentDetailDto = {
    collaborationToolItems: Array<GetCollaborationToolItemDto>;
    createdBy?: string;
    createdDate?: string;
    criticality: number;
    id: string;
    issueNo: number;
    lastModifiedDate?: string;
    lastOccurredDate: string;
    modifiedBy?: string;
    name: string;
    numberOfFailures: number;
    owners: Array<UserDto>;
    qualification?: IncidentDetailDto.qualification;
    status: IncidentDetailDto.status;
};

export namespace IncidentDetailDto {

    export enum qualification {
        FIXED = 'FIXED',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        EXPECTED = 'EXPECTED',
        KNOWN_ERROR = 'KNOWN_ERROR',
        DUPLICATE = 'DUPLICATE',
    }

    export enum status {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }


}
