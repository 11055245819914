<template lang="pug">

.rule-overview-info-icon-name

  .d-flex( v-if="hasRule" )

    v-avatar.mr-2(:tile="false" size="40" color="bgAccentSecondary")
      img.dbt-monitor-icon( v-if="shouldShowDbtLogo" src="/next/icons/svg/monitor-square-dbt-test.svg" :alt="$t('datasource_types.DBT')")
      v-icon(v-else color="iconInfo" ) icon-monitor-square

    .d-flex.flex-column
      .d-flex.align-start
        h2.text-h4.font-weight-medium  {{ rule.name }}

      .d-flex
        b.mr-1.font-weight-medium {{ $t('monitors.type') }}:
        img.dbt-logo.ml-2.mr-1( v-if="shouldShowDbtLogo" :src="image('dbt')" :alt="$t('datasource_types.DBT')" )
        span {{ rule.ruleLabel }}

      .d-flex
        b.mr-1.font-weight-medium {{ $t('monitors.tables') }}:
        .mr-2( v-for="(dataset, index) in orderedDatasets" :key="index" )
          v-tooltip( top )
            template( v-slot:activator="{ on }" )
              v-avatar.ml-1(tile size="20" class="flex-grow-0 flex-shrink-0 mr-1" v-on="on")
                img( :src="image(dataset.datasourceType)" :alt="dataset.datasourceType" )
            span {{ dataset.datasourceName }}

          router-link.link( :to="assetLink(dataset.urn)" ) {{ dataset.name }}

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    type="list-item-avatar-two-line"
    width="500" )

</template>

<script lang="ts">
import { RuleInfoDto } from '@/api';
import { Vue, Component, VModel } from 'vue-property-decorator';
import src from '@/utils/filters/src';

const DBT_SOURCEPLATFORM_NAME = 'DBT';

@Component
export default class RuleOverviewInfoIconName extends Vue {
  @VModel({ type: Object }) rule!: RuleInfoDto;

  get hasRule() {
    return this.rule !== null;
  }

  get orderedDatasets() {
    return this.rule?.datasets!.sort((a, b) => a.datasourceName.localeCompare(b.datasourceName));
  }

  // TODO: refactor to handle multiple platform logos
  get shouldShowDbtLogo() {
    return this.rule?.sourcePlatform === DBT_SOURCEPLATFORM_NAME;
  }

  assetLink(urn: string | undefined) {
    return { path: `/data-catalog/asset/${urn}/overview` };
  }

  image(datasourceType: string) {
    return src(datasourceType, 'datasources');
  }
}
</script>

<style lang="scss" scoped>
.dbt-monitor-icon {
  width: 24px;
}

.dbt-logo {
  width: 20px;
  height: 20px;
}
</style>
