<template lang="pug">
v-autocomplete.term-selector(
    ref="termSelector"
    v-bind='$attrs'
    v-on='$listeners'
    :loading="loading"
    :items="terms"
    :search-input.sync="searchString"
    :hint="hint"
    :return-object="_returnObject"
    :append-icon="appendIcon"
    @change="searchString=''"
    @click="closeMenu"
    @click:append="clear"
    placeholder="Select a term"
    label=""
    item-value="id"
    item-text="name"
    data-cy="term-selector"
    cache-items dense outlined
    hide-details
    hide-no-data persistent-hint
    multiple )

    template(v-if="canRemove" v-slot:prepend-item)
      v-list-item( ripple @mousedown.prevent @click='toggleNull' )
        v-list-item-action
          v-icon {{ icon }}
        v-list-item-content
          v-list-item-title {{ $t('selectors.remove_existing') }}
      v-divider.mt-2

    template(v-slot:item="{ item, attrs }")
      v-list-item(v-bind="attrs" data-cy="term-selector-item")
        v-list-item-action
          v-icon {{ customItemIcon(attrs.inputValue) }}
        v-list-item-content.d-inline-block
          Term.my-1( :term="item" :key="item.id" small) {{ item.name }}
      v-divider.mt-2

    template(v-slot:selection="{ item }")
      Term.my-1( :term="item" :key="item.id" small) {{ item.name }}

</template>

<script lang="ts">
import {
  Vue, Component, Watch, Prop, Ref,
} from 'vue-property-decorator';
import { TagDto, TermService } from '@/api';
import Term from '@/components/terms/Term.vue';

@Component({
  components: {
    Term,
  },
})
export default class TermSelector extends Vue {
  @Prop({ default: 'Select one or multiple terms' })
  readonly hint!: string;

  searchString = '';

  terms: TagDto[] = [];

  loading = false;

  @Ref()
  private readonly termSelector!: any;

  get canRemove(): boolean {
    return 'can-remove' in this.$attrs;
  }

  get searchOption(): any {
    return this.searchString === null ? '' : this.searchString;
  }

  get isNull(): boolean {
    return this.$attrs.value === null;
  }

  get icon(): string {
    return this.isNull ? '$check_box' : '$check_box_outline_blank';
  }

  get label(): string {
    return this.isNull ? 'Remove existing' : 'Business Terms';
  }

  get labelClass(): string {
    return this.isNull ? 'warning--text' : '';
  }

  get color(): string {
    return this.isNull ? 'warning' : '';
  }

  get appendIcon(): string {
    return this.isNull || this.$attrs.value?.length ? '$close' : '';
  }

  get _returnObject(): any {
    return (this.$attrs['return-object'] as unknown as boolean) !== false;
  }

  clear() {
    this.terms = [];
    this.$emit('input', this.terms);
    this.closeMenu();
  }

  closeMenu() {
    if (this.termSelector.isMenuActive) setTimeout(this.termSelector.blur, 0);
  }

  @Watch('searchString')
  searchHandler() {
    this.fetchTerms();
  }

  async fetchTerms() {
    this.loading = true;
    const { data } = await TermService.getAllTerm({
      textSearch: this.searchString,
      itemsPerPage: -1,
    });
    this.terms = data!;
    this.loading = false;
  }

  toggleNull() {
    if (!this.isNull) this.$emit('input', null);
    else this.$emit('input', []);
    this.closeMenu();
  }

  customItemIcon(inputValue: boolean): string {
    return inputValue ? 'icon-check-square-fill' : 'icon-check-square-outline-empty';
  }
}
</script>

<style lang="scss">
.term-selector {
  .v-select__slot {
    padding: 3px 0px 3px;
  }
  .v-input__append-inner {
    margin-top: 5px !important;
  }
}

</style>
