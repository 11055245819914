<script setup lang="ts">
import { Vue } from 'vue-property-decorator';
import { DataItemProps } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import {
  ref, computed, onMounted, onBeforeUnmount,
} from 'vue';
import { IncidentLightDto, UserAssetBookmarkDto, UserService } from '@/api';
import src from '@/utils/filters/src';
import fromNow from '@/utils/filters/fromNow';
import incidentModule from '@/store/modules/incidents';
import store from '@/store';
import makeSafeForCSS from '@/utils/makeSafeCss';
import IncidentAssignModal from '@/components/issue/info/Issue-Overview-Info-Assign-Modal.vue';
import authModule from '@/store/modules/auth';
import router from '@/router';
import i18n from '@/i18n';
import IncidentStatus from '@/components/incidents/Incident-Status.vue';
import CompactStatusSelector from '@/components/selectors/Compact-Status-Selector.vue';

interface AssetDataQualityIncidentsCardProps {
  itemProps: Omit<DataItemProps, 'item'> & { item: IncidentLightDto };
  selected?: boolean;
  incident?: IncidentLightDto;
}

type AssetDataQualityIncidentsCardEmits = {
  (event: 'update', ids: string[]): void,
  (event: 'updateOwners', ids: string[]): void,
}
const emit = defineEmits(["update", "updateOwners"]);
const props = defineProps({
  itemProps: null,
  selected: { type: Boolean },
  incident: null
});
const auth = getModule(authModule, store);

const card = ref<HTMLElement | null>(null);
const incidentAssignModalRef = ref<IncidentAssignModal | null>(null);
const menuIsOpen = ref(false);

const item = computed(() => props.itemProps.item);
const disabled = computed(() => !auth.userActions['monitoring.incident.write']);
const urn = computed(() => item.value.datasets![0].urn);
const id = computed(() => makeSafeForCSS(`${item.value.name}_${item.value.id}`));
const datasourceType = computed(() => item.value?.datasets![0]?.datasourceType ?? '');
const datasourceName = computed(() => item.value?.datasets![0]?.datasourceName ?? '');
const imgsrc = computed(() => src(datasourceType.value, 'datasources'));
const alt = computed(() => datasourceType.value);
const hasOwner = computed(() => !!item.value!.owners!.length);
const owner = computed(() => item.value!.owners![0]!.name ?? '');
const lastModified = computed(() => fromNow(item.value?.lastModifiedDate as string));
const compromisedAssets = computed(() => item.value?.compromisedAssets);
const hasCompromisedAssets = computed(() => item.value?.compromisedAssets != null);
const color = computed(() => (props.selected ? 'primary' : ''));
const name = computed(() => item.value.name);
const assetName = computed(() => item.value.datasets![0].datasourceName);
const icon = computed(() => (props.selected ? 'icon-check-square-fill' : 'icon-check-square-outline-empty'));
const selectedClass = computed(() => (props.itemProps.isSelected ? 'selected' : ''));
const assetLink = computed(() => ({ name: 'data-catalog.asset.overview', params: { urn: urn.value } }));

const goTo = (e: any) => {
  e.preventDefault();
  return router.push({
    name: 'incidents.incident.incident_overview',
    params: {
      issue: item.value.issueNo!.toString(),
    },
  });
};

const toAsset = () => ({
  name: 'data-catalog.asset',
  params: {
    urn: urn.value,
  },
});

const openModal = () => {
  incidentAssignModalRef.value?.open();
};

const pinIncident = async () => {
  const notExist = await UserService.getCurrentUserAssetBookmark({ entityId: item.value.issueNo as unknown as UserAssetBookmarkDto['entityId'] }).then((myres: any) => (typeof myres === 'string'));
  if (notExist) {
    const requestBody = {
      entityId: item.value.issueNo as unknown as UserAssetBookmarkDto['entityId'],
      entityType: UserAssetBookmarkDto.entityType.INCIDENT,
    };
    UserService.createUserAssetBookmark({ requestBody }).then(() => {
      Vue.notify({
        type: 'success',
        text: i18n.t('assets.pin_success') as string,
      });
    });
  }
};

const closeMenu = () => {
  menuIsOpen.value = false;
};

const update = (incidentIds: string[]) => {
  emit('update', incidentIds);
};

const updateOwners = (incidentIds: string[]) => {
  emit('updateOwners', incidentIds);
};

onMounted(() => {
  if (card.value) card.value.addEventListener('mouseleave', closeMenu, false);
});

onBeforeUnmount(() => {
  card.value?.removeEventListener('mouseleave', closeMenu);
});
</script>

<template lang="pug">
v-row.py-3.incident-card.border-bottom.actionable( :class="selectedClass" @click="goTo" ref="card" :id="id" no-gutters )
  v-col.mr-1( cols="auto" )
    v-simple-checkbox.ml-2( color="primary" :value="itemProps.isSelected" @input="itemProps.select" )

  v-col.mr-2( cols="auto" )
    v-avatar(size="32" color="bgDangerSecondary" :alt="alt")
      v-icon( color="iconDanger" ) icon-flag

  v-col
    span.overline.grey--text.d-block {{ name }}
    HtmlView.text-h6.font-weight-medium.mt-1( :html="name" )

    v-row( no-gutters )
      v-col( cols="auto" ).mt-2
        .d-inline-flex.align-center
          IncidentStatus.ml-0(:status="item.status")
          .d-inline-flex.ml-6
            v-avatar(tile size="20")
              img( :src="imgsrc" :alt="alt")
            router-link.link.ml-1( :to="assetLink" style="max-width: 160px" class="text-truncate" ) {{ assetName }}
          Severity.ml-6(:value="item.criticality")
          .d-inline-flex.ml-6
            v-icon(small) icon-clock
            .ml-1 {{ lastModified }}
          .d-inline-flex.ml-6(v-if="hasOwner")
            v-icon(small) icon-user
            .ml-1 {{ owner }}
          .d-inline-flex.ml-6( v-if="hasCompromisedAssets" )
            v-icon(small) icon-data-source
            .ml-1 {{ compromisedAssets }} {{ $t('assets.compromised_assets') }}

  .d-flex.justify-end.align-center.contextual-actions.mr-2
    span.mr-2
      CompactStatusSelector( v-if="!disabled" :incidents="[item]" @update="update" variant="outlined" color="secondary")
    SThreeDotMenu(variant="text")
      SMenuButton(
        @click="pinIncident"
        icon="icon-thumbtack"
        :text="$t('assets.pin')")
      SMenuButton(
        :disabled="disabled"
        @click="openModal"
        icon="icon-user-arrow-right"
        :text="$t('assets.assign')")

  IncidentAssignModal(
    ref="incidentAssignModalRef"
    :value="[item]"
    @update="updateOwners"
  )
</template>

<style lang="scss">
.incident-card {
  position: relative;
  min-height: 100px;
  cursor: pointer;
  transition: background-color .3s linear;

  .theme--light & {
    .router-link {
      text-decoration: none;
      color: map-get($grey, 'darken-4');

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .theme--dark & {
    .router-link {
      text-decoration: none;
      color: map-get($grey, 'lighten-4');

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.selected {
    &:hover {
      background-color: var(--v-bgAccentPrimary-base);
    }
  }

  &:hover {
    background-color: var(--v-bgPrimaryHover-base);
  }
}
</style>
