import * as d3 from 'd3';
import { Canvg } from 'canvg';
import { getModule } from 'vuex-module-decorators';
import { LineageEntityDto } from '@/api/models/LineageEntityDto';
import { LineageService } from '@/api';
import colors from '@/plugins/colors';
import lineageModule from '@/store/modules/lineage';
import store from '@/store';
import * as CONSTANTS from './constants';
import Dimension from './Dimension';
import { Canvas, Link, Node } from './index';
import lineageCreateNodes from './lineage-create-nodes';
import lineageCreateLinks from './lineage-create-links';
import lineageCreateFields from './lineage-create-fields';
import lineageCreateFieldLinks from './lineage-create-field-links';

export enum InteractionEvents {
  CANVAS_CLICKED = 'canvas-clicked',
  INFO_CLICKED = 'info-clicked',
  FIELD_EXPAND_CLICKED = 'field-expand-clicked',
}

export default class Lineage {
  private el: HTMLElement;

  store: lineageModule;

  private urn: string;

  public canvas: Canvas;

  private node!: LineageEntityDto;

  private data!: Node[];

  private container!: d3.Selection<SVGGElement, unknown, null, undefined>;

  private cardsGroup!: d3.Selection<SVGGElement, unknown, null, undefined>;

  private linksGroup!: d3.Selection<SVGGElement, unknown, null, undefined>;

  private links!: Link[];

  private svg!: d3.Selection<SVGSVGElement, unknown, null, undefined>;

  private fields!: Node[];

  public spreadAll = false;

  private fieldLinks!: Link[];

  private zoom!: d3.ZoomBehavior<Element, unknown>;

  private get fileNameExport() {
    return `Lineage of ${this.node.datasourceName}`;
  }

  constructor({ el, urn }: { el: HTMLElement; urn: string; }) {
    this.store = getModule(lineageModule, store);

    this.el = el;
    this.urn = urn;
    this.canvas = new Canvas({ width: this.el.clientWidth, height: this.el.clientHeight });
    this.el.addEventListener('click', this.canvasClicked.bind(this));

    this.svg = d3
      .select(this.el)
      .append('svg')
      .attr('id', 'lineage-svg')
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('width', this.el.clientWidth)
      .attr('height', this.el.clientHeight)
      .attr('font-family', 'sans-serif')
      .attr('viewBox', `0 0 ${this.el.clientWidth} ${this.el.clientHeight}`);

    this.svg.append('style').text(`

        .sans-serif {
          font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        }

      `);

    this.svg.append('rect').attr('width', '100%').attr('height', '100%').attr('fill', colors.grey.lighten5);

    this.container = this.svg
      .append('g')
      .attr('id', 'container');

    this.linksGroup = this.container.append('g').attr('id', 'links-group');

    this.cardsGroup = this.container.append('g').attr('id', 'cards-group');

    const zoomed = ({ transform }: any) => {
      if (Number.isNaN(transform.x)) return;
      this.container.attr('transform', transform);
    };

    this.zoom = d3
      .zoom()
      .extent([
        [0, 0],
        [this.el.clientWidth, this.el.clientHeight],
      ])
      .scaleExtent([CONSTANTS.ZOOM_MIN, CONSTANTS.ZOOM_MAX])
      .on('zoom', zoomed);

    this.svg.call(this.zoom as any);

    new ResizeObserver(() => {
      this.resize();
    }).observe(this.el);
  }

  public async init() {
    this.node = await LineageService.getLineageByUrn({ urn: this.urn });
    this.canvas.addStartNode(this.node);
    if (CONSTANTS.DEPLOY_FIRST_LEVEL) {
      await this.getDownstreams(this.node);
      await this.getUpstreams(this.node);
    }

    this.store.setIsInitialized(true);
    this.store.setUndoState(false);
    this.update();
  }

  public async refresh() {
    this.canvas.reset();
    if (CONSTANTS.DEPLOY_FIRST_LEVEL) {
      await this.getDownstreams(this.node);
      await this.getUpstreams(this.node);
    }
    this.update();
  }

  public undo() {
    this.canvas.undoAddNodesAction();
    this.update();
  }

  public redo() {
    this.update();
  }

  private toBoundingBox(W: number, H: number, center: { x: number, y: number }, w: number, h: number, margin: number) {
    // from the site https://bl.ocks.org/fabiovalse/b9224bfd64ca96c47f8cdcb57b35b8e2

    const kw = (W - margin) / w;
    const kh = (H - margin) / h;
    const k = d3.min([kw, kh])!;
    let s = k < CONSTANTS.ZOOM_MAX ? k : CONSTANTS.ZOOM_MAX;
    s = s > CONSTANTS.ZOOM_MIN ? s : CONSTANTS.ZOOM_MIN;
    const xx = W / 2 - center.x * s;
    const yy = H / 2 - center.y * s;
    return d3.zoomIdentity.translate(xx, yy).scale(s);
  }

  public center() {
    if (this.data) {
      setTimeout(() => {
        const {
          x, y, width, height,
        } = this.container.node()!.getBBox();
        const center = { x: x + width / 2, y: y + height / 2 };
        const transform = this.toBoundingBox(this.el.clientWidth, this.el.clientHeight, center, width, height, CONSTANTS.SCALE_PADDING);
        this.svg.transition().duration(CONSTANTS.ANIMATION_DURATION).call(this.zoom.transform as any, transform);
      }, CONSTANTS.ANIMATION_DURATION);
    }
  }

  public async exportToPng() {
    const width = this.el.clientWidth * CONSTANTS.PNG_EXPORT_SCALE_FACTOR;
    const height = this.el.clientHeight * CONSTANTS.PNG_EXPORT_SCALE_FACTOR;

    const svgEl = document.getElementById('lineage-svg')!.outerHTML;
    const canvas = new OffscreenCanvas(width, height);
    const ctx = canvas.getContext('2d')!;
    const canvg = Canvg.fromString(ctx, svgEl, {
      scaleWidth: width,
      scaleHeight: height,
    });
    canvg.resize(width, height, 'xMidYMid meet');

    await canvg.render();
    const blob = await canvas.convertToBlob();
    this.download(blob, 'png');
  }

  private download(blob: Blob, type: string) {
    const pngUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = pngUrl;
    a.download = `${this.fileNameExport}.${type}`;
    a.click();
  }

  public async exportToCsv() {
    const requestBody = {
      urns: this.canvas.nodes().map((node) => node.data.urn),
      fileName: this.fileNameExport,
    };
    const blob = new Blob([await LineageService.exportAsCsv({ requestBody })]);
    this.download(blob, 'csv');
  }

  public toggleExpandFields() {
    this.canvas.toggleFieldsExpanded();
    this.update();
  }

  public filter() {
    const linkState = this.store.lineageState.filters.fields.includes('with-links');
    this.canvas.setOnlyFieldsWithLinks(linkState);
    this.update();
  }

  public shouldSpreadAllStreams(value: boolean) {
    this.spreadAll = value;
    this.changeCircleColors();
  }

  private changeCircleColors() {
    d3
      .selectAll('.circle_plus')
      .attr('fill', this.spreadAll ? colors.blue.lighten4 : colors.shades.white)
      .attr('stroke', this.spreadAll ? colors.blue.lighten4 : colors.grey.lighten2);
  }

  async getDownstreams(node: LineageEntityDto, deepLevel: 'one' | 'all' = 'one') {
    if (deepLevel === 'all') {
      this.canvas.startRcordingLastAddedNodes();
    }
    await this.getDownstreamsData(node, deepLevel);
    if (deepLevel === 'all') {
      this.canvas.saveRcordedLastAddedNodes();
    }
  }

  private async getDownstreamsData(node: LineageEntityDto, deepLevel: 'one' | 'all' = 'one', actualDeepLevel = 1) {
    let response: any;
    const { urn, downstreams } = node;
    if (downstreams.length) {
      const nodes = await LineageService.getLineageDownstreamsByUrn({ urn });
      this.canvas.deployDownstreamNodes(urn, nodes);
      if (deepLevel === 'all' && actualDeepLevel < CONSTANTS.MAX_DEEP_LEVEL) {
        response = await Promise.all(nodes.map((_node) => this.getDownstreamsData(_node, deepLevel, actualDeepLevel + 1)));
      }
    }
    return response;
  }

  async getUpstreams(node: LineageEntityDto, deepLevel: 'one' | 'all' = 'one') {
    if (deepLevel === 'all') {
      this.canvas.startRcordingLastAddedNodes();
    }
    await this.getUpstreamsData(node, deepLevel);
    if (deepLevel === 'all') {
      this.canvas.saveRcordedLastAddedNodes();
    }
  }

  private async getUpstreamsData(node: LineageEntityDto, deepLevel: 'one' | 'all' = 'one', actualDeepLevel = 1) {
    let response: any;
    const { urn, upstreams } = node;
    if (upstreams.length) {
      const nodes = await LineageService.getLineageUpstreamsByUrn({ urn });
      this.canvas.deployUpstreamNodes(urn, nodes);
      if (deepLevel === 'all' && actualDeepLevel < CONSTANTS.MAX_DEEP_LEVEL) {
        response = await Promise.all(nodes.map((_node) => this.getUpstreamsData(_node, deepLevel, actualDeepLevel + 1)));
      }
    }
    return response;
  }

  async emit(event: string, node?: Node) {
    if (event === InteractionEvents.INFO_CLICKED && node) {
      this.store.lineageState.selectedNode = node;
    }

    if (event === InteractionEvents.FIELD_EXPAND_CLICKED && node) {
      this.canvas.toggleFieldsOfNode(node.data.urn);
      this.update();
    }
  }

  canvasClicked() {
    this.emit(InteractionEvents.CANVAS_CLICKED);
  }

  public update(center = true) {
    this.data = this.canvas.nodes();
    this.fields = this.canvas.fieldNodes();
    this.links = this.canvas.links();
    this.fieldLinks = this.canvas.fieldLinks();
    this.updateLinks();
    this.updateFieldLinks();
    this.updateNodes();
    this.updateFields();
    this.changeCircleColors();
    if (center) this.center();
  }

  public setSelectedNodeCssClass(el: d3.Selection<SVGGElement, Node, SVGGElement, unknown>) {
    el.classed('selected-node', true);
  }

  public resetAllSelectedNodeCssClasses() {
    d3.selectAll('.selected-node').classed('selected-node', false);
  }

  public resize() {
    const newDimension = new Dimension(this.el.clientWidth, this.el.clientHeight);

    this.svg
      .attr('width', newDimension.width)
      .attr('height', newDimension.height)
      .attr('viewBox', `0 0 ${newDimension.width} ${newDimension.height}`);
    this.center();
  }

  private updateNodes() {
    lineageCreateNodes(this.cardsGroup, this.data, this);
  }

  private updateFields() {
    lineageCreateFields(this.cardsGroup, this.fields);
  }

  private updateLinks() {
      lineageCreateLinks(this.linksGroup, this.links);
  }

  private updateFieldLinks() {
    lineageCreateFieldLinks(this.linksGroup, this.fieldLinks);
  }
}
