<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { SORT_CONFIGURATION } from '@/components/catalog/Catalog';
import type { QueryType } from '@/utils/query';

interface CatalogSortProps {
  sort: string[]
}

type CatalogSortEmits = {
  (event: 'onSortingChange', sort: { [key: string]: QueryType }): void
}

const props = defineProps({
  sort: null
});

const emit = defineEmits(["onSortingChange"]);

const sortString = ref(props.sort[0].split(',')[0]);
const sortDirection = ref(props.sort[0].split(',')[1]);

const icon = computed(() => (sortDirection.value === 'ASC' ? 'icon-sort-text-az' : 'icon-sort-text-za'));

const handleSortDirection = () => {
  sortDirection.value = sortDirection.value === 'ASC' ? 'DESC' : 'ASC';
};

watch(() => [sortString.value, sortDirection.value], () => {
  emit('onSortingChange', { sort: [`${sortString.value},${sortDirection.value}`] });
});
</script>

<template lang="pug">
.d-flex
  v-select(
    v-model="sortString"
    :items="SORT_CONFIGURATION"
    style="width: 250px;"
    :placeholder="$t('common.words.sort_by')"
    :menu-props="{ bottom: true, offsetY: true }"
    hide-details
    dense
    outlined )
  SButton.ml-2(
    :icon="icon"
    color="secondary"
    variant="outlined"
    @click="handleSortDirection")
</template>
