export const SYMBOLS = ' !@#$%^&*(),.?":{}[]\\|<>\'+-/;=^_`~';

/**
 * Checks if the password is not empty
 * @param {string} password - The user input for password
 * @return {string | boolean} - Returns true if password is not empty or an error message if it's the case
 */
export function required(password?: string) {
  if (password) return true;
  return 'This field is required.';
}

/**
 * Checks if the password has the minimal amount of characters required
 * @param {string} password - The user input for password
 * @return {string | boolean} - Returns true if password is longer than 12 chars or an error message if it's not the case
 */
export function min12(password?: string) {
  if (password && password.length >= 12) return true;
  return 'Password should have more than 12 characters.';
}

/**
 * Checks if the password has at least a symbol in it
 * @param {string} password - The user input for password
 * @return {string | boolean} - Returns true if password is has a symbol or an error message if it's not the case
 */
export function mustHaveSpecialCharacters(password?: string) {
  const hasSpecialCharacters = password?.split('').some((char) => SYMBOLS.includes(char));
  if (hasSpecialCharacters) return true;
  return 'Password should have at least one symbol.';
}

/**
 * Checks if the password has at least a number in it
 * @param {string} password - The user input for password
 * @return {string | boolean} - Returns true if password is has a number or an error message if it's not the case
 */
export function mustHaveNumbers(password?: string) {
  const regex = /[0-9]/g;
  if (regex.test(password ?? '')) return true;
  return 'Password should have at least one number.';
}

/**
 * Checks if the password and the confirmation are matching
 * @param {string} password - The user input for password
 * @param {string} password1 - The user confirmation for its password
 * @return {string | boolean} - Returns true if password is has a number or an error message if it's not the case
 */
export function matchingPasswords(password?: string, password1?: string) {
  if (password === password1) return true;
  return 'Passwords does not match.';
}

export default [
  required,
  min12,
  mustHaveSpecialCharacters,
  mustHaveNumbers,
];
