<script setup lang="ts">import { computed as _computed } from 'vue';


interface SCardProps {
  title?: string
  subtitle?: string
  icon?: string
  disabled?: boolean
}
const props =  defineProps({
  title: null,
  subtitle: null,
  icon: null,
  disabled: { type: Boolean }
});

const hasTitleContent = _computed(() => !!props.title || !!props.subtitle || !!props.icon);

</script>

<template lang="pug">
v-card.pa-4( v-bind="$attrs" :disabled="disabled" outlined )
  .d-flex.justify-space-between.align-center( v-if="hasTitleContent" :class="{'pb-4': $slots.default}" )
    .d-flex
      SIcon.mr-2( v-if="icon" :icon="icon" size="large")
      div
        v-card-title.pa-0.ma-0.textPrimary--text( v-if="title" ) {{ title }}
          slot( name="status")
        v-card-subtitle.ma-0.pa-0.textSecondary--text( v-if="subtitle" ) {{ subtitle }}
    div( v-if="$slots.action" )
      slot( name="action" )
  v-card-text.pa-0.textPrimary--text(v-if="$slots.default")
    slot

</template>
