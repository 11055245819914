<template lang="pug">
v-chip.run-status( v-if="hasRunStatus" outlined small data-cy="run-status" :color="status.color" )
  v-progress-circular.mr-1( v-if="isRunning" size="12" width="1" :color="iconColor" indeterminate )
  v-icon.mr-1( v-else size="16" :color="iconColor"  ) {{ status.icon }}
  b.grey--text.text--darken-4 {{ status.text }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { createColor } from '@/utils/filters/health';
import { LastRunStatusDto, RuleCatalogAssetDto } from '@/api';

@Component
export default class RunStatus extends Vue {
  @Prop(Object) readonly lastRunStatus!: RuleCatalogAssetDto['lastRunStatus'];

  get isRunning() {
    return this.lastRunStatus?.status === LastRunStatusDto.status.RUNNING;
  }

  get hasRunStatus() {
    return this.lastRunStatus?.status;
  }

  get iconColor() {
    return createColor(this.lastRunStatus?.status!);
  }

  get status() {
    const status = this.lastRunStatus?.status;
    const info = {
      [LastRunStatusDto.status.PENDING]: {
        icon: 'icon-clock',
        color: 'iconNeutral',
        text: this.$t('monitors.run_status_pending'),
      },
      [LastRunStatusDto.status.RUNNING]: {
        icon: 'icon-circle-loading',
        color: 'iconNeutral',
        text: this.$t('monitors.run_status_running'),
      },
      [LastRunStatusDto.status.SUCCESS]: {
        icon: 'icon-check-circle-fill',
        color: 'iconSuccess',
        text: this.$t('monitors.run_status_success'),
      },
      [LastRunStatusDto.status.FAILED]: {
        icon: 'icon-error-circle-fill',
        color: 'iconDanger',
        text: this.$t('monitors.run_status_failed'),
      },
      [LastRunStatusDto.status.TECHNICAL_ERROR]: {
        icon: 'icon-tool-fill',
        color: 'iconWarning',
        text: this.$t('monitors.run_status_technical_error'),
      },
      [LastRunStatusDto.status.REQUIRES_YOUR_ATTENTION]: {
        icon: 'icon-warning-fill',
        color: 'iconAttention',
        text: this.$t('monitors.run_status_requires_your_attention'),
      },
      default: {
        icon: 'icon-circle-fill',
        color: 'iconNeutral',
        text: '',
      },
    };

    return status ? info[status] : info.default;
  }
}
</script>

<style lang="scss">
.v-chip.run-status.v-chip--outlined.v-chip--outlined {
  background-color: var(--v-bgPrimary-base) !important;
}
</style>
