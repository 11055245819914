/* eslint-disable key-spacing */
import colors from 'vuetify/es5/util/colors';

export default {
      ...colors,
      grey: {
        lighten5: '#F7F8FA',
        lighten4: '#EDEEF0',
        lighten3: '#C7CBD1',
        lighten2: '#B6BABF',
        lighten1: '#8B919A',
        base: '#676C74',
        darken1: '#575E67',
        darken2: '#3B414A',
        darken3: '#232931',
        darken4: '#1D2028',
      },

      red: {
        lighten5: '#FDF2F1',
        lighten4: '#F9C7C5',
        lighten3: '#F4A3A0',
        lighten2: '#E97B77',
        lighten1: '#CF6866',
        base: '#C5423F',
        darken1: '#A4322F',
        darken2: '#86312E',
        darken3: '#441211',
        darken4: '#350202',
      },

      orange: {
        lighten5: '#FFEAD5',
        lighten4: '#FDDCAB',
        lighten3: '#FEB273',
        lighten2: '#FFA947',
        lighten1: '#FF8936',
        base: '#E66B2F',
        darken1: '#b54b1d',
        darken2: '#8C3D10',
        darken3: '#7E2410',
        darken4: '#33130B',
      },

      yellow: {
        lighten5: '#FCF8EA',
        lighten4: '#FFECB3',
        lighten3: '#FFE082',
        lighten2: '#FFDC6D',
        lighten1: '#EECC62',
        base: '#DDB745',
        darken1: '#8A6F11',
        darken2: '#7E5B00',
        darken3: '#6E4E03',
        darken4: '#402600',
      },

      green: {
        lighten5: '#EDFFF6',
        lighten4: '#A7F3D0',
        lighten3: '#8EE5C3',
        lighten2: '#42CA98',
        lighten1: '#10B981',
        base: '#1C6E51',
        darken1: '#047857',
        darken2: '#0B694F',
        darken3: '#08513C',
        darken4: '#0c392b',
      },

      blue: {
        lighten5: '#EDF6FF',
        lighten4: '#B2DDFF',
        lighten3: '#95B3EF',
        lighten2: '#7197E4',
        lighten1: '#5082E5',
        base: '#3668CC',
        darken1: '#32569C',
        darken2: '#253F7C',
        darken3: '#162C61',
        darken4: '#0D1B3F',
      },

      purple: {
        lighten5: '#F5EDFF',
        lighten4: '#E7D3FF',
        lighten3: '#D5B8FB',
        lighten2: '#B68BEE',
        lighten1: '#A369EE',
        base: '#884FD1',
        darken1: '#703AB5',
        darken2: '#572D8C',
        darken3: '#381D5B',
        darken4: '#1B0F2C',
      },

      colorList: {
        color_1: '#2C6B81',
        color_2: '#BC273F',
        color_3: '#5E54B1',
        color_4: '#578125',
        color_5: '#927127',
        color_6: '#1F4D87',
        color_7: '#B7336C',
        color_8: '#797574',
        color_9: '#3C6D5A',
        color_10: '#7D2393',
        color_11: '#714A2A',
        color_12: '#54764B',
        color_13: '#C1423F',
        color_14: '#6C797D',
        color_15: '#398186',
        color_16: '#398186',
        color_17: '#8265b3',
        color_18: '#687614',
        color_19: '#995E96',
        color_20: '#736158',
      },
    };
