/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BaseSearchFilterElementDto } from './BaseSearchFilterElementDto';

export type HealthStatusSearchFilterElementDto = (BaseSearchFilterElementDto & {
    id?: HealthStatusSearchFilterElementDto.id;
});

export namespace HealthStatusSearchFilterElementDto {

    export enum id {
        CRITICAL = 'CRITICAL',
        AT_RISK = 'AT_RISK',
        HEALTHY = 'HEALTHY',
        UNMONITORED = 'UNMONITORED',
        NOT_SUPPORTED = 'NOT_SUPPORTED',
    }


}
