<template lang="pug">
GlobalEvents( @keydown="shortcutsHandler" @keyup="shortcutsHandler" )
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import eventBus, { ShortcutsEventType } from '@/utils/eventBus';

@Component
export default class Shortcuts extends Vue {
  shortcutsHandler(event: KeyboardEvent) {
    if (event.repeat === false && event.type === 'keydown') {
      switch (event.key) {
        case 'Shift':
          eventBus.$emit(ShortcutsEventType.KEYDOWN_SHIFT);
          break;
        default:
          break;
      }
    }
    if (event.repeat === false && event.type === 'keyup') {
      switch (event.key) {
        case 'Shift':
          eventBus.$emit(ShortcutsEventType.KEYUP_SHIFT);
          break;
        default:
          break;
      }
    }
  }
}
</script>
