/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SlackConnectionStatusDto = {
    status: SlackConnectionStatusDto.status;
};

export namespace SlackConnectionStatusDto {

    export enum status {
        CONNECTED = 'CONNECTED',
        DISCONNECTED = 'DISCONNECTED',
        REMOVED = 'REMOVED',
    }


}
