import { render, staticRenderFns } from "./Catalog-Filters.vue?vue&type=template&id=7a6ed04a&lang=pug"
import script from "./Catalog-Filters.vue?vue&type=script&setup=true&lang=ts"
export * from "./Catalog-Filters.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports