export type QueryType = string | (string | null)[];

/**
 * Removes null values from a string or an array of strings.
 * @param val The value to remove nulls from. It can be a string or an array of strings.
 * @returns An array of strings without null values, or undefined if the input value is not a string or an array.
 */
export const queryToArray = (val: QueryType): (string[]) => {
  if (typeof val === 'string') return [val];
  if (Array.isArray(val)) return val.map((d) => d || '');
  return [];
};

/**
 * Converts a query value to a boolean.
 * @param val The query value to convert.
 * @returns The boolean representation of the query value.
 */
export const queryToBoolean = (val: QueryType) => (typeof val === 'string' ? val === 'true' : false);

/**
 * Converts a query value to a string.
 * @param val - The query value to convert.
 * @returns The converted query value as a string.
 */
export const queryToString = (val: QueryType) => (typeof val === 'string' ? val : '');

/**
 * Converts a value to a number.
 * @param val - The value to convert.
 * @param fallbackValue - The fallback value to use if the conversion fails. Default is 0.
 * @returns The converted number value or the fallback value if the conversion fails.
 */
export const queryToNumber = (val: QueryType, fallbackValue = 0) => (typeof val === 'string' ? parseInt(val, 10) : fallbackValue);

/**
 * Narrows down an array of strings to a union type based on the provided types.
 *
 * @template U - The resulting union type.
 * @param arr - The array of strings to narrow down.
 * @param types - The types to filter the array with.
 * @returns The narrowed down array as the specified union type.
 */
export function narrowArrayToUnion<U extends(string[] | undefined)>(arr: string[], types: U) {
  const filtered = arr.filter((d) => types?.includes(d));
  return filtered as U; // now we know that filtered must be of type U
}
