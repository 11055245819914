enum ProviderIcons {
  METABASE = '/next/images/logomarks/metabase.svg',
  METABASE_LOCK = '/next/images/logomarks/metabase-lock.svg',
  AMAZON_SAGEMAKER = '/next/images/logomarks/amazon-sagemaker.svg',
  AIRBYTE = '/next/images/logomarks/airbyte.svg',
  AIRBYTE_LOCK = '/next/images/logomarks/airbyte-lock.svg',
  CENSUS = '/next/images/logomarks/census.svg',
  CENSUS_LOCK = '/next/images/logomarks/census-lock.svg',
  HIGH_TOUCH = '/next/images/logomarks/hightouch.svg',
  HIGH_TOUCH_LOCK = '/next/images/logomarks/hightouch-lock.svg',
  SALESFORCE = '/next/images/logomarks/salesforce.svg',
  SEGMENT = '/next/images/logomarks/segment.svg',
  EXCEL = '/next/images/logomarks/excel.svg',
  GOOGLE_ANALYTICS = '/next/images/logomarks/google-analytics.svg',
  BIGQUERY = '/next/images/datasources/light/bigquery.svg',
  DBTCLOUD = '/next/images/datasources/light/dbtcloud.svg',
  MYSQL_LOCK = '/next/images/datasources/light/mysql-lock.svg',
  POWER_BI = '/next/images/datasources/light/powerbi.svg',
  POWER_BI_LOCK = '/next/images/datasources/light/powerbi-lock.svg',
  SLACK = '/next/images/datasources/light/slack.svg',
  TABLEAU = '/next/images/datasources/light/tableau.svg',
  CENSUS_LOCK_COLOR = '/next/images/datasources/light/census-lock-color.svg',
  FIREBOLT_LOCK_COLOR = '/next/images/datasources/light/firebolt-lock-color.svg',
  HDFS_STORAGE = '/next/images/datasources/light/hdfs-storage.svg',
  MYSQL = '/next/images/datasources/light/mysql.svg',
  H2 = '/next/images/datasources/light/mysql.svg',
  PREFECT = '/next/images/datasources/light/prefect.svg',
  PREFECT_LOCK = '/next/images/datasources/light/prefect-lock.svg',
  SNOWFLAKE_LOCK = '/next/images/datasources/light/snowflake-lock.svg',
  AIRFLOW_LOCK = '/next/images/datasources/light/airflow-lock.svg',
  DAGSTER = '/next/images/datasources/light/dagster.svg',
  DAGSTER_LOCK = '/next/images/datasources/light/dagster-lock.svg',
  FIREBOLT_LOCK = '/next/images/datasources/light/firebolt-lock.svg',
  HIGHTOUCH_LOCK = '/next/images/datasources/light/hightouch-lock.svg',
  ORACLE = '/next/images/datasources/light/oracle.svg',
  PRESTO = '/next/images/datasources/light/presto.svg',
  PRESTO_LOCK = '/next/images/datasources/light/presto-lock.svg',
  SNOWFLAKE = '/next/images/datasources/light/snowflake.svg',
  AIRFLOW = '/next/images/datasources/light/airflow.svg',
  DATABRICKS_LOCK = '/next/images/datasources/light/databricks-lock.svg',
  FIREBOLT = '/next/images/datasources/light/firebolt.svg',
  HIVE_PROCESS = '/next/images/datasources/light/hive-process.svg',
  POSTGRES_LOCK = '/next/images/datasources/light/postgres-lock.svg',
  QUICKSIGHT_LOCK = '/next/images/datasources/light/quicksight-lock.svg',
  SPARK_PROCESS = '/next/images/datasources/light/spark-process.svg',
  ATHENA_LOCK = '/next/images/datasources/light/athena-lock.svg',
  DATABRICKS = '/next/images/datasources/light/databricks.svg',
  FIVETRAN_LOCK = '/next/images/datasources/light/fivetran-lock.svg',
  HIVE = '/next/images/datasources/light/hive.svg',
  POSTGRES = '/next/images/datasources/light/postgres.svg',
  QUICKSIGHT = '/next/images/datasources/light/quicksight.svg',
  SQLSERVER_LOCK = '/next/images/datasources/light/sqlserver-lock.svg',
  ATHENA = '/next/images/datasources/light/athena.svg',
  DBT_LOCK = '/next/images/datasources/light/dbt-lock.svg',
  FIVETRAN = '/next/images/datasources/light/fivetran.svg',
  LOOKER_LOCK = '/next/images/datasources/light/looker-lock.svg',
  REDSHIFT_LOCK = '/next/images/datasources/light/redshift-lock.svg',
  STITCH = '/next/images/logomarks/stitch.svg',
  STITCH_LOCK = '/next/images/logomarks/stitch-lock.svg',
  AZURE = '/next/images/logomarks/microsoft-azure.svg',
  AZURE_LOCK = '/next/images/logomarks/microsoft-azure-lock.svg',
  DBT = '/next/images/datasources/light/dbt.svg',
  GOOGLE_CLOUD_STORAGE = '/next/images/datasources/light/google-cloud-storage.svg',
  LOOKER = '/next/images/datasources/light/looker.svg',
  REDSHIFT = '/next/images/datasources/light/redshift.svg',
  SYNAPSE = '/next/images/datasources/light/synapse.svg',
  BIGQUERY_LOCK = '/next/images/datasources/light/bigquery-lock.svg',
  DBTCLOUD_LOCK = '/next/images/datasources/light/dbtcloud-lock.svg',
  GOOGLE_DRIVE = '/next/images/datasources/light/google-drive.svg',
  MSSQL = '/next/images/datasources/light/mssql.svg',
  S3 = '/next/images/datasources/light/s3.svg',
  TABLEAU_LOCK = '/next/images/datasources/light/tableau-lock.svg',
  DATAIKU = '/next/images/logomarks/dataiku.svg',
  HUBSPOT = '/next/images/logomarks/hubspot.svg',
  INFORMATICA = '/next/images/logomarks/informatica.svg',
  LIGHTDASH = '/next/images/logomarks/lightdash.svg',
  MICROSOFT_AZURE_ML = '/next/images/logomarks/microsoft-azure-ml.svg',
  MULESOFT = '/next/images/logomarks/mulesoft.svg',
  QLIK = '/next/images/logomarks/qlik.svg',
  SIGMA = '/next/images/logomarks/sigma.svg',
  TALEND = '/next/images/logomarks/talend.svg',
  GOOGLE_SHEETS = '/next/images/logomarks/google-sheets.svg',
  GITHUB = '/next/images/logomarks/github.svg',
  UNKNOWN = '/next/images/logomarks/unknown.svg',
  SIXSENSE = '/next/images/logomarks/6sense.svg',
  FIFTEENFIVE = '/next/images/logomarks/15five.svg',
  THREESIXTYLEARNING = '/next/images/logomarks/360learning.svg',
  ACCELDATA = '/next/images/logomarks/acceldata.svg',
  ACRYL_DATA = '/next/images/logomarks/acryl-data.svg',
  ACTIAN_VECTOR = '/next/images/logomarks/actian-vector.svg',
  ACTIVE_CAMPAIGN = '/next/images/logomarks/activecampaign.svg',
  ADA = '/next/images/logomarks/ada.svg',
  ADJUST = '/next/images/logomarks/adjust.svg',
  ADOBE = '/next/images/logomarks/adobe.svg',
  ADP_WFN = '/next/images/logomarks/adp-workforce-now.svg',
  ADROLL = '/next/images/logomarks/adroll.svg',
  AFFINITY = '/next/images/logomarks/affinity.svg',
  AIRCALL = '/next/images/logomarks/aircall.svg',
  AIRTABLE = '/next/images/logomarks/airtable.svg',
  ALATION = '/next/images/logomarks/alation.svg',
  ALCHEMER = '/next/images/logomarks/alchemer.svg',
  ALVIN_AI = '/next/images/logomarks/alvin-ai.svg',
  AMAZON = '/next/images/logomarks/amazon.svg',
  AMAZON_CLOUDFRONT = '/next/images/logomarks/amazon-cloudfront.svg',
  AMAZON_DOCUMENTDB = '/next/images/logomarks/amazon-document.svg',
  AMAZON_DYNAMODB = '/next/images/logomarks/amazon-dynamodb.svg',
  AMAZON_FIREHOSE = '/next/images/logomarks/amazon-kinesis-firehose.svg',
  AMAZON_SELLING_PARTNER = '/next/images/logomarks/amazon-selling-partner.svg',
  AMPLITUDE = '/next/images/logomarks/amplitude.svg',
  ANAPLAN = '/next/images/logomarks/anaplan.svg',
  ANOMALO = '/next/images/logomarks/anomalo.svg',
  APACHE_KAFKA = '/next/images/logomarks/apache-kafka.svg',
  APACHE_SPARK = '/next/images/logomarks/apache-spark.svg',
  APPCUES = '/next/images/logomarks/appcues.svg',
  APPFIGURES = '/next/images/logomarks/appfigures.svg',
  APPLE = '/next/images/logomarks/apple.svg',
  APPLE_APP_STORE = '/next/images/logomarks/apple-app-store.svg',
  APPSFLYER = '/next/images/logomarks/appsflyer.svg',
  ASANA = '/next/images/logomarks/asana.svg',
  ASSEMBLED = '/next/images/logomarks/assembled.svg',
  ATLAN = '/next/images/logomarks/atlan.svg',
  ATLASSIAN_CONFLUENCE = '/next/images/logomarks/atlassian-confluence.svg',
  ATLASSIAN_JIRA = '/next/images/logomarks/atlassian-jira.svg',
  ATLASSIAN_JIRA_WHITE = '/next/images/logomarks/atlassian-jira-white.svg',
  ATLASSIAN_OPSGENIE = '/next/images/logomarks/atlassian-opsgenie.svg',
  AUTH0 = '/next/images/logomarks/auth0.svg',
  AUTODESK_BIM_360 = '/next/images/logomarks/autodesk-bim360.svg',
  AWIN = '/next/images/logomarks/awin.svg',
  AWS_CLOUDTRAIL = '/next/images/logomarks/aws-cloudtrail.svg',
  AWS_COST_EXPLORER = '/next/images/logomarks/aws-cost-explorer.svg',
  AWS_INVENTORY = '/next/images/logomarks/aws-inventory.svg',
  AWS_LAMBDA = '/next/images/logomarks/aws-lambda.svg',
  BALLOT_READY = '/next/images/logomarks/ballotready.svg',
  BAMBOOHR = '/next/images/logomarks/bamboohr.svg',
  BIGCOMMERCE = '/next/images/logomarks/bigcommerce.svg',
  BIGEYE = '/next/images/logomarks/bigeye.svg',
  BILLINGPLATFORM = '/next/images/logomarks/billing-platform.svg',
  BIRDEYE = '/next/images/logomarks/birdeye.svg',
  BLACKBOARD = '/next/images/logomarks/blackboard.svg',
  BOX = '/next/images/logomarks/box.svg',
  BRAINTREE = '/next/images/logomarks/braintree.svg',
  BRAZE = '/next/images/logomarks/braze.svg',
  BREX = '/next/images/logomarks/brex.svg',
  BREVO = '/next/images/logomarks/brevo.svg',
  BUILDKITE = '/next/images/logomarks/buildkite.svg',
  BUZZSPROUT = '/next/images/logomarks/buzzsrpout.svg',
  CALENDLY = '/next/images/logomarks/calendly.svg',
  CAMPAIGN_MONITOR = '/next/images/logomarks/campaign-monitor.svg',
  CANNY = '/next/images/logomarks/canny.svg',
  CANVAS = '/next/images/logomarks/canvas.svg',
  CAPTERRA = '/next/images/logomarks/capterra.svg',
  CASTOR_DOC = '/next/images/logomarks/castor.svg',
  CERTINIA = '/next/images/logomarks/certinia.svg',
  CHAMELEON = '/next/images/logomarks/chameleon.svg',
  CHANNEL_ADVISOR = '/next/images/logomarks/channeladvisor.svg',
  CHARGEBEE = '/next/images/logomarks/chargebee.svg',
  CHARGEDESK = '/next/images/logomarks/chargedesk.svg',
  CHARTMOGUL = '/next/images/logomarks/chartmogul.svg',
  CHORUS_AI = '/next/images/logomarks/chorus.ai.svg',
  CHURNKEY = '/next/images/logomarks/churnkey.svg',
  CHURNZERO = '/next/images/logomarks/churnzero.svg',
  CIMIS = '/next/images/logomarks/cimis.svg',
  CIN7 = '/next/images/logomarks/cin7.svg',
  CIRCLE_CI = '/next/images/logomarks/circleci.svg',
  CLARI = '/next/images/logomarks/clari.svg',
  CLICKUP = '/next/images/logomarks/clickup.svg',
  CLIFF_AI = '/next/images/logomarks/cliff-ai.svg',
  CLOSE = '/next/images/logomarks/close.svg',
  COLLIBRA = '/next/images/logomarks/collibra.svg',
  COMMERCETOOLS = '/next/images/logomarks/commercetools.svg',
  CONFLUENT_CLOUD = '/next/images/logomarks/confluent-cloud.svg',
  CONTRAST_SECURITY = '/next/images/logomarks/contrast-security.svg',
  COUPA = '/next/images/logomarks/coupa.svg',
  CRITEO = '/next/images/logomarks/criteo.svg',
  CROSSBEAM = '/next/images/logomarks/crossbeam.svg',
  CRUNCHBASE = '/next/images/logomarks/crunchbase.svg',
  CUSTOMER_IO = '/next/images/logomarks/customer.io.svg',
  D2L_BRIGHTSPACE = '/next/images/logomarks/d2l-brightspace.svg',
  DATA_WORLD = '/next/images/logomarks/data-world.svg',
  DATABAND = '/next/images/logomarks/databand.svg',
  DATADOG = '/next/images/logomarks/datadog.svg',
  DATAEDO = '/next/images/logomarks/dataedo.svg',
  DATAFOLD = '/next/images/logomarks/datafold.svg',
  DATAGALAXY = '/next/images/logomarks/datagalaxy.svg',
  DATAHUB = '/next/images/logomarks/datahub.svg',
  DB2 = '/next/images/logomarks/db2.svg',
  DCL_LOGISTICS = '/next/images/logomarks/dcl-logistics.svg',
  DEAR = '/next/images/logomarks/dear.svg',
  DECUBE = '/next/images/logomarks/decube.svg',
  DELIGHTED = '/next/images/logomarks/delighted.svg',
  DEPUTY = '/next/images/logomarks/deputy.svg',
  DESTINI = '/next/images/logomarks/destini.svg',
  DIXA = '/next/images/logomarks/dixa.svg',
  DOCEBO = '/next/images/logomarks/docebo.svg',
  DRATA = '/next/images/logomarks/drata.svg',
  DRIFT = '/next/images/logomarks/drift.svg',
  DRIP = '/next/images/logomarks/drip.svg',
  DROPBOX = '/next/images/logomarks/dropbox.svg',
  DUOPLANE = '/next/images/logomarks/duoplane.svg',
  E_CONOMIC = '/next/images/logomarks/e-conomic.svg',
  EASY_POST = '/next/images/logomarks/easypost.svg',
  ELASTICSEARCH = '/next/images/logomarks/elasticsearch.svg',
  ELEMENTARY = '/next/images/logomarks/elementary.svg',
  ELLUCIAN = '/next/images/logomarks/ellucian-banner.svg',
  ELOQUA = '/next/images/logomarks/eloqua.svg',
  EMAIL = '/next/images/logomarks/email.svg',
  EMPLOYMENT_HERO = '/next/images/logomarks/employment-hero.svg',
  EPICOR_PROPHET_21 = '/next/images/logomarks/epicor-prophet-21.svg',
  EVENTBRITE = '/next/images/logomarks/eventbrite.svg',
  EVERHOUR = '/next/images/logomarks/everhour.svg',
  EXPENSIFY = '/next/images/logomarks/expensify.svg',
  FACEBOOK = '/next/images/logomarks/facebook.svg',
  FIGMA = '/next/images/logomarks/figma.svg',
  FIREBASE = '/next/images/logomarks/firebase.svg',
  FLEXPORT = '/next/images/logomarks/flexport.svg',
  FORJ = '/next/images/logomarks/forj-community.svg',
  FOUNTAIN = '/next/images/logomarks/fountain.svg',
  FREIGHTVIEW = '/next/images/logomarks/freightview.svg',
  FRESHBOOKS = '/next/images/logomarks/freshbooks.svg',
  FRESHCHAT = '/next/images/logomarks/freshchat.svg',
  FRESHDESK = '/next/images/logomarks/freshdesk.svg',
  FRESHDESK_CONTACT_CENTER = '/next/images/logomarks/freshdesk-contact-center.svg',
  FRESHSERVICE = '/next/images/logomarks/freshservice.svg',
  FRESHSUCCESS = '/next/images/logomarks/freshsuccess.svg',
  FRESHTEAM = '/next/images/logomarks/freshteam.svg',
  FRIENDBUY = '/next/images/logomarks/freshbuy.svg',
  FRONT = '/next/images/logomarks/front.svg',
  FTP = '/next/images/logomarks/ftp.svg',
  FULLSTORY = '/next/images/logomarks/fullstory.svg',
  G2 = '/next/images/logomarks/g2.svg',
  GAINSIGHT = '/next/images/logomarks/gainsight-customer-success.svg',
  GEM = '/next/images/logomarks/gem.svg',
  GIT = '/next/images/logomarks/git.svg',
  GITLAB = '/next/images/logomarks/gitlab.svg',
  GLADLY = '/next/images/logomarks/gladly.svg',
  GOCARDLESS = '/next/images/logomarks/gocardless.svg',
  GONG = '/next/images/logomarks/gong.io.svg',
  GOOGLE = '/next/images/logomarks/google.svg',
  GOOGLE_AD_MANAGER = '/next/images/logomarks/google-ad-manager.svg',
  GOOGLE_ADS = '/next/images/logomarks/google-ads.svg',
  GOOGLE_CALENDAR = '/next/images/logomarks/google-calendar.svg',
  GOOGLE_CAMPAIGN_MANAGER = '/next/images/logomarks/google-campaign-manager.svg',
  GOOGLE_CHROME = '/next/images/logomarks/google-chrome.svg',
  GOOGLE_CLOUD = '/next/images/logomarks/google-cloud.svg',
  GOOGLE_DISPLAY = '/next/images/logomarks/google-display.svg',
  GOOGLE_PLAY = '/next/images/logomarks/google-play.svg',
  GOOGLE_SEARCH_ADS_360 = '/next/images/logomarks/google-search-ads-360.svg',
  GOOGLE_SEARCH_CONSOLE = '/next/images/logomarks/google-search-console.svg',
  GOOGLE_TASKS = '/next/images/logomarks/google-tasks.svg',
  GORGIAS = '/next/images/logomarks/gorgias.svg',
  GREENHOUSE = '/next/images/logomarks/grennhouse.svg',
  GREENPLUM = '/next/images/logomarks/greenplum.svg',
  GREEN_POWER_MONITOR = '/next/images/logomarks/greenpowermonitor.svg',
  GREPSR = '/next/images/logomarks/grespr.svg',
  GRIN = '/next/images/logomarks/grin.svg',
  GURU = '/next/images/logomarks/guru.svg',
  HARVEST = '/next/images/logomarks/harvest.svg',
  HDFS = '/next/images/logomarks/hdfs.svg',
  HEAP = '/next/images/logomarks/heap.svg',
  HEIGHT = '/next/images/logomarks/height.svg',
  HELP_SCOUT = '/next/images/logomarks/help-scout.svg',
  HELPSHIFT = '/next/images/logomarks/helpshift.svg',
  HEROKU_KAFKA = '/next/images/logomarks/heroku-kafka.svg',
  HIBOB = '/next/images/logomarks/hibob.svg',
  HOPIN = '/next/images/logomarks/hopin.svg',
  IMPACT = '/next/images/logomarks/impact.svg',
  INCIDENT_IO = '/next/images/logomarks/incident.io.svg',
  INFOBIP = '/next/images/logomarks/infobip.svg',
  INSIGHTLY = '/next/images/logomarks/insightly.svg',
  INSTAGRAM = '/next/images/logomarks/instagram.svg',
  INTERCOM = '/next/images/logomarks/intercom.svg',
  INVESTOPEDIA = '/next/images/logomarks/investopedia.svg',
  IRONCLAD = '/next/images/logomarks/ironclad.svg',
  IRONSOURCE = '/next/images/logomarks/ironsource.svg',
  ITERABLE = '/next/images/logomarks/iterable.svg',
  JAVA = '/next/images/logomarks/java.svg',
  JENKINS = '/next/images/logomarks/jenkins.svg',
  JUSTCALL = '/next/images/logomarks/justcall.svg',
  KANDJI = '/next/images/logomarks/kandji.svg',
  KENSU = '/next/images/logomarks/kensu.svg',
  KHOROS_CARE = '/next/images/logomarks/khoros-care.svg',
  KISSMETRICS = '/next/images/logomarks/kissmetrics.svg',
  KLAVIYO = '/next/images/logomarks/klavio.svg',
  KNOWBE4 = '/next/images/logomarks/knowbe4.svg',
  KONNECT_INSIGHTS = '/next/images/logomarks/konnect-insights.svg',
  KUSTOMER = '/next/images/logomarks/kustomer.svg',
  LARIAT_DATA = '/next/images/logomarks/lariat-data.svg',
  LATTICE = '/next/images/logomarks/lattica.svg',
  LAUNCH_DARKLY = '/next/images/logomarks/launchdarkly.svg',
  LEARN_UPON = '/next/images/logomarks/learnupon.svg',
  LESSONLY = '/next/images/logomarks/lessonly.svg',
  LEVER = '/next/images/logomarks/lever.svg',
  LIFTOFF = '/next/images/logomarks/liftoff.svg',
  LIGHTSPEED = '/next/images/logomarks/lightspeed-retail.svg',
  LIGHTSUP = '/next/images/logomarks/lightsup.svg',
  LINEAR = '/next/images/logomarks/linear.svg',
  LINKEDIN = '/next/images/logomarks/linkedin.svg',
  LIVECHAT = '/next/images/logomarks/livechat.svg',
  LOADED_COMMERCE = '/next/images/logomarks/loaded-commerce.svg',
  LOB = '/next/images/logomarks/lob.svg',
  LOOP = '/next/images/logomarks/loop.svg',
  LOOPIO = '/next/images/logomarks/loopio.svg',
  LUMA = '/next/images/logomarks/luma.svg',
  MAGENTO = '/next/images/logomarks/magento.svg',
  MAILCHIMP = '/next/images/logomarks/mailchimp.svg',
  MAILGUN = '/next/images/logomarks/mailgun.svg',
  MANDRILL = '/next/images/logomarks/mandrill.svg',
  MANTA = '/next/images/logomarks/manta.svg',
  MARIADB = '/next/images/logomarks/mariadb.svg',
  MARIN_SOFTWARE = '/next/images/logomarks/marin-software.svg',
  MARKETO = '/next/images/logomarks/marketo.svg',
  MATTERMOST = '/next/images/logomarks/mattermost.svg',
  MAVENLINK = '/next/images/logomarks/mavenlink.svg',
  MAXIO = '/next/images/logomarks/maxio.svg',
  MEDALLIA = '/next/images/logomarks/medallia.svg',
  META = '/next/images/logomarks/meta.svg',
  METAPHOR = '/next/images/logomarks/metaphor.svg',
  METAPLANE = '/next/images/logomarks/metaplane.svg',
  MICROSTRATEGY = '/next/images/logomarks/microstrategy.svg',
  MICROSOFT = '/next/images/logomarks/microsoft.svg',
  MICROSOFT_AZURE_BLOB_STORAGE = '/next/images/logomarks/microsoft-azure-blob-storage.svg',
  MICROSOFT_DYNAMICS = '/next/images/logomarks/microsoft-dynamics.svg',
  MICROSOFT_ONEDRIVE = '/next/images/logomarks/microsoft-onedrive.svg',
  MICROSOFT_PURVIEW = '/next/images/logomarks/microsoft-purview.svg',
  MICROSOFT_TEAMS = '/next/images/logomarks/microsoft-teams.svg',
  MIXMAX = '/next/images/logomarks/mixmax.svg',
  MIXPANEL = '/next/images/logomarks/mixpanel.svg',
  MONDAY_COM = '/next/images/logomarks/monday.com.svg',
  MONGODB = '/next/images/logomarks/mongodb.svg',
  MONTE_CARLO = '/next/images/logomarks/monte-carlo.svg',
  MOUNTAIN = '/next/images/logomarks/mountain.svg',
  MUX = '/next/images/logomarks/mux.svg',
  NAMELY = '/next/images/logomarks/namely.svg',
  NCINO = '/next/images/logomarks/ncino.svg',
  NETSUITE_ANALYTICS = '/next/images/logomarks/netsuite-suteanalytics.svg',
  NEW_RELIC = '/next/images/logomarks/new-relic.svg',
  NOTION = '/next/images/logomarks/notion.svg',
  NYLAS = '/next/images/logomarks/nylas.svg',
  OBSERVE = '/next/images/logomarks/observe.svg',
  OCTOPAI = '/next/images/logomarks/octopai.svg',
  OKTA = '/next/images/logomarks/okta.svg',
  OPEN_DATA_DC = '/next/images/logomarks/open-data-dc.svg',
  OPEN_EXCHANGES_RATES = '/next/images/logomarks/open-exchanges-rates.svg',
  OPEN_AI = '/next/images/logomarks/openai.svg',
  OPEN_SEARCH = '/next/images/logomarks/opensearch.svg',
  OPTIMIZELY = '/next/images/logomarks/optimizely.svg',
  ORBIT_COMMUNITY_PLATFORM = '/next/images/logomarks/orbit.svg',
  ORDWAY = '/next/images/logomarks/ordway.svg',
  ORTTO = '/next/images/logomarks/ortto.svg',
  OS_COMMERCE = '/next/images/logomarks/oscommerce.svg',
  OUTBRAIN = '/next/images/logomarks/outbrain.svg',
  OUTREACH = '/next/images/logomarks/outreach.svg',
  OVALEDGE = '/next/images/logomarks/ovaledge.svg',
  PAGERDUTY = '/next/images/logomarks/pagerduty.svg',
  PAGERSHIFT = '/next/images/logomarks/pagershift.svg',
  PAYPAL = '/next/images/logomarks/paypal.svg',
  PENDO = '/next/images/logomarks/pendo.svg',
  PINGDOM = '/next/images/logomarks/pingdom.svg',
  PINTEREST = '/next/images/logomarks/pinterest.svg',
  PIPE17 = '/next/images/logomarks/pipe17.svg',
  PIPEDRIVE = '/next/images/logomarks/pipedrive.svg',
  PIVOTAL_TRACKER = '/next/images/logomarks/pivotal-tracker.svg',
  PLAYVOX = '/next/images/logomarks/playvox.svg',
  POSTHOG = '/next/images/logomarks/posthog.svg',
  POWERSCHOOL = '/next/images/logomarks/powerschool.svg',
  PRESTASHOP = '/next/images/logomarks/prestashop.svg',
  PRODUCTBOARD = '/next/images/logomarks/productboard.svg',
  PYTHON = '/next/images/logomarks/python.svg',
  QUALAROO = '/next/images/logomarks/qualaroo.svg',
  QUALTRICS = '/next/images/logomarks/qualtrics.svg',
  QUICKBOOKS = '/next/images/logomarks/quickbooks.svg',
  RAMP = '/next/images/logomarks/ramp.svg',
  RARIBLE = '/next/images/logomarks/rarible.svg',
  RECHARGE = '/next/images/logomarks/recharge.svg',
  RECURLY = '/next/images/logomarks/recurly.svg',
  REDDIT = '/next/images/logomarks/reddit.svg',
  RELTIO = '/next/images/logomarks/reltio.svg',
  REPLY_IO = '/next/images/logomarks/reply.io.svg',
  RETAILNEXT = '/next/images/logomarks/retailnext.svg',
  RETENTLY = '/next/images/logomarks/retently.svg',
  REVEL_SYSTEMS = '/next/images/logomarks/revel.svg',
  RINGCENTRAL = '/next/images/logomarks/ringcentral.svg',
  RIPPLING = '/next/images/logomarks/rippling.svg',
  ROLLBAR = '/next/images/logomarks/rollbar.svg',
  ROOTLY = '/next/images/logomarks/rootly.svg',
  SAGE_HR = '/next/images/logomarks/sage-hr.svg',
  SAGE_INTACCT = '/next/images/logomarks/sage-intacct.svg',
  SAILTHRU = '/next/images/logomarks/sailthru.svg',
  SALESLOFT = '/next/images/logomarks/salesloft.svg',
  SALSIFY = '/next/images/logomarks/salsify.svg',
  SAMSARA = '/next/images/logomarks/samsara.svg',
  SAP = '/next/images/logomarks/sap.svg',
  SAP_CONCUR = '/next/images/logomarks/sap-concur.svg',
  SCORM = '/next/images/logomarks/scorm.svg',
  SECODA = '/next/images/logomarks/secoda.svg',
  SECURITY_JOURNEY = '/next/images/logomarks/security-journey.svg',
  SELECT_STAR = '/next/images/logomarks/select-star.svg',
  SENDGRID = '/next/images/logomarks/sendgrid.svg',
  SERVICE_NOW = '/next/images/logomarks/servicenow.svg',
  SFTP = '/next/images/logomarks/sftp.svg',
  SHAREPOINT = '/next/images/logomarks/sharepoint.svg',
  SHARETRIBE = '/next/images/logomarks/sharetribe.svg',
  SHIPHERO = '/next/images/logomarks/shiphero.svg',
  SHIPSTATION = '/next/images/logomarks/shipstation.svg',
  SHOPIFY = '/next/images/logomarks/shopify.svg',
  SHOPWARE = '/next/images/logomarks/shopware.svg',
  SHORT_IO = '/next/images/logomarks/short.io.svg',
  SIFFLET = '/next/images/logomarks/sifflet.svg',
  SIMPLECAST = '/next/images/logomarks/simplecast.svg',
  SIMPLESAT = '/next/images/logomarks/simplesat.svg',
  SINGLESTORE = '/next/images/logomarks/singlestore.svg',
  SISENSE = '/next/images/logomarks/sisens.svg',
  SKILLJAR = '/next/images/logomarks/skilljar.svg',
  SKUVAULT = '/next/images/logomarks/skuvault.svg',
  SLAB = '/next/images/logomarks/slab.svg',
  SMADEX = '/next/images/logomarks/smadex.svg',
  SNAPCHAT = '/next/images/logomarks/snapchat.svg',
  SNOWPLOW = '/next/images/logomarks/snowplow.svg',
  SODA = '/next/images/logomarks/soda.svg',
  SONARQUBE = '/next/images/logomarks/sonarqube.svg',
  SPLIT_IO = '/next/images/logomarks/split.io.svg',
  SPLUNK = '/next/images/logomarks/splunk.svg',
  SPOTIFY = '/next/images/logomarks/spotify.svg',
  SPREE_COMMERCE = '/next/images/logomarks/spree-commerce.svg',
  SPROUT_SOCIAL = '/next/images/logomarks/sprout-social.svg',
  SQUARE = '/next/images/logomarks/square.svg',
  SQUARESPACE = '/next/images/logomarks/squarespace.svg',
  STACK_ADAPT = '/next/images/logomarks/stackadapt.svg',
  STATSIG = '/next/images/logomarks/statsig.svg',
  STRIPE = '/next/images/logomarks/stripe.svg',
  STYLIGHT = '/next/images/logomarks/stylight.svg',
  SUBSCRIPT = '/next/images/logomarks/subscriipt.svg',
  TABOOLA = '/next/images/logomarks/taboola.svg',
  TALKDESK = '/next/images/logomarks/talkdesk.svg',
  TALKWALKER = '/next/images/logomarks/talkwalker.svg',
  TEAMWORK_COM = '/next/images/logomarks/teamwork.svg',
  TEMPO_IO = '/next/images/logomarks/tempo.io.svg',
  TERADATA = '/next/images/logomarks/teradata.svg',
  TERRAFORM = '/next/images/logomarks/terraform.svg',
  THE_TRADE_DESK = '/next/images/logomarks/the-trade-desk.svg',
  THOUGHT_SPOT = '/next/images/logomarks/thought-spot.svg',
  TIKTOK = '/next/images/logomarks/tiktok.svg',
  TOGGL = '/next/images/logomarks/toggl.svg',
  TRANSCEND = '/next/images/logomarks/transcend.svg',
  TRELLO = '/next/images/logomarks/trello.svg',
  TRISOLUTE = '/next/images/logomarks/trisolute.svg',
  TWILIO = '/next/images/logomarks/twilio.svg',
  TYPEFORM = '/next/images/logomarks/typeform.svg',
  UPLAND = '/next/images/logomarks/upland.svg',
  USERVOICE = '/next/images/logomarks/uservoice.svg',
  VALIDIO = '/next/images/logomarks/validio.svg',
  VEEVA = '/next/images/logomarks/veeva.svg',
  VERIZON_MEDIA = '/next/images/logomarks/verizon-media.svg',
  VITALLY = '/next/images/logomarks/vitally.svg',
  VONAGE = '/next/images/logomarks/vonage.svg',
  VUETIFY = '/next/images/logomarks/vuetify.svg',
  WEB_SCRAPER = '/next/images/logomarks/web-scraper.svg',
  WEBHOOKS = '/next/images/logomarks/webhooks.svg',
  WHEN_I_WORK = '/next/images/logomarks/when-i-work.svg',
  WHEREFOUR = '/next/images/logomarks/wherefour.svg',
  WIKIPEDIA = '/next/images/logomarks/wikipedia.svg',
  WOOCOMMERCE = '/next/images/logomarks/woocommerce.svg',
  WORDPRESS = '/next/images/logomarks/wordpress.svg',
  WORKABLE = '/next/images/logomarks/workable.svg',
  WORKDAY = '/next/images/logomarks/workday.svg',
  WORKFLOW_MAX = '/next/images/logomarks/workflowmax.svg',
  WRIKE = '/next/images/logomarks/wrike.svg',
  X = '/next/images/logomarks/x.svg',
  XERO = '/next/images/logomarks/xero.svg',
  XRAY = '/next/images/logomarks/xray.svg',
  YAHOO = '/next/images/logomarks/yahoo.svg',
  YOTPO = '/next/images/logomarks/yotpo.svg',
  YOUTUBE = '/next/images/logomarks/youtube.svg',
  ZEENEA = '/next/images/logomarks/zeenea.svg',
  ZEN_CART = '/next/images/logomarks/zen-cart.svg',
  ZENDESK_CHAT = '/next/images/logomarks/zendesk-chat.svg',
  ZENDESK_SELL = '/next/images/logomarks/zendesk-sell.svg',
  ZENDESK_SUNSHINE = '/next/images/logomarks/zendesk-sunshine.svg',
  ZENDESK_SUPPORT = '/next/images/logomarks/zendesk-support.svg',
  TRINET = '/next/images/logomarks/trinet.svg',
  ZINGTREE = '/next/images/logomarks/zingtree.svg',
  ZOHO_BOOKS = '/next/images/logomarks/zoho-books.svg',
  ZOHO_CAMPAIGNS = '/next/images/logomarks/zoho-campaigns.svg',
  ZOHO_CRM = '/next/images/logomarks/zoho-crm.svg',
  ZOOM = '/next/images/logomarks/zoom.svg',
  ZUORA = '/next/images/logomarks/zuora.svg',
}

export const getProviderIconSrc = (provider?: string): ProviderIcons => ProviderIcons[provider as keyof typeof ProviderIcons] || ProviderIcons.UNKNOWN;

export default ProviderIcons;
