import {
 MonitorSettingsOptions, LegacyThresholdSettings, ThresholdSettingsEnum, ThresholdSettingsCommon,
} from '@/components/monitor-wizard/composables/monitors/common/types';
import type { MonitorSchema } from '../monitor-schema';
import SiffletCompletenessRule from './SiffletCompletenessRule';
import SiffletCompletenessStaticRule from './SiffletCompletenessStaticRule';
import SiffletDuplicatesRule from './SiffletDuplicatesRule';
import SiffletFreshnessRule from './SiffletFreshnessRule';
import SiffletDistributionRule from './SiffletDistributionRule';
import SiffletAverageRule from './SiffletAverageRule';
import SiffletRangeRule from './SiffletRangeRule';
import SiffletVarianceRule from './SiffletVarianceRule';
import SiffletCountRule from './SiffletCountRule';
import SiffletStandardDeviationRule from './SiffletStandardDeviationRule';
import SiffletQuantileRule from './SiffletQuantileRule';
import SiffletSumRule from './SiffletSumRule';
import SiffletNumericalTransformationRule from './SiffletNumericalTransformationRule';
import SiffletCustomMetricsRule from './SiffletCustomMetricsRule';
import SiffletMetadataFreshnessRule from './SiffletMetadataFreshnessRule';
import SiffletInterlinkedMetricsRule from './SiffletInterlinkedMetricsRule';
import SiffletNullRule from './SiffletNullRule';
import SiffletStaticCountDuplicateRule from './SiffletStaticCountDuplicateRule';
import SiffletDuplicateRule from './SiffletDuplicateRule';
import SiffletCountDuplicateRule from './SiffletCountDuplicateRule';
import SiffletPercentageNullRowsRule from './SiffletPercentageNullRowsRule';
import SiffletPercentageNullStaticRule from './SiffletPercentageNullStaticRule';
import SiffletPercentageDuplicatesRule from './SiffletPercentageDuplicatesRule';
import SiffletCountNullRowsRule from './SiffletCountNullRowsRule';
import SiffletNoCodeRule from './SiffletNoCodeRule';

const monitorSettings: {
  [key in MonitorSchema['ruleTemplateName']]?: MonitorSettingsOptions
} = {
  SiffletCompletenessRule,
  SiffletFreshnessRule,
  SiffletCompletenessStaticRule,
  SiffletMetadataFreshnessRule,
  SiffletDuplicatesRule,
  // Metrics
  SiffletAverageRule,
  SiffletRangeRule,
  SiffletVarianceRule,
  SiffletCountRule,
  SiffletStandardDeviationRule,
  SiffletQuantileRule,
  SiffletSumRule,
  // Smart Metrics
  SiffletNumericalTransformationRule,
  SiffletCustomMetricsRule,
  SiffletInterlinkedMetricsRule,
  // Field Profiling
  SiffletDistributionRule,
  SiffletCountDuplicateRule,
  SiffletPercentageNullRowsRule,
  SiffletStaticCountDuplicateRule,
  SiffletPercentageDuplicatesRule,
  SiffletPercentageNullStaticRule,
  SiffletDuplicateRule,
  SiffletCountNullRowsRule,
  SiffletNullRule,
  // Custom
  SiffletNoCodeRule,
};

export default function getSettingFromMonitorType(ruleTemplateName: MonitorSchema['ruleTemplateName'] | undefined, settingKey: 'thresholdSettings' | 'canClone'): MonitorSettingsOptions['thresholdSettings'] | MonitorSettingsOptions['canClone'] | null {
  if (!ruleTemplateName) return null;
  if (!monitorSettings[ruleTemplateName]) return null;
  return monitorSettings[ruleTemplateName]?.[settingKey] ?? null;
}
