<script setup lang="ts">
import { computed } from 'vue';
import i18n from '@/i18n';

interface ConnectorStatusProps {
  connectorStatus: string | undefined,
}

const props = defineProps({
  connectorStatus: null
});

const status = computed(() => {
  switch (props.connectorStatus) {
    case 'active':
      return {
        icon: 'icon-check-circle-fill',
        color: 'iconSuccess',
        text: i18n.tc('common.words.active'),
      };
    case 'broken':
      return {
        icon: 'icon-error-circle-fill',
        color: 'iconDanger',
        text: i18n.tc('common.words.broken'),
      };
    case 'delayed':
      return {
        icon: 'icon-warning-fill',
        color: 'orange lighten-3',
        text: i18n.tc('common.words.delayed'),
      };
    case 'incomplete':
      return {
        icon: 'icon-warning-fill',
        color: 'orange lighten-3',
        text: i18n.tc('common.words.incomplete'),
      };
    case 'paused':
      return {
        icon: 'icon-pause-circle',
        color: 'grey lighten-3',
        text: i18n.tc('common.words.paused'),
      };
    default:
      return {
        icon: 'icon-circle-fill',
        color: 'grey lighten-3',
        text: i18n.tc('common.words.unknown'),
      };
  }
});
</script>

<template lang="pug">
span.d-flex.align-center
  v-icon.mr-1( size="16" :color="status.color" ) {{ status.icon }}
  span.grey--text {{ status.text }}
</template>
