<template lang="pug">

v-item-group( :value="categoryIndex" @change="change" )
  v-row
    v-col(
      v-for="item in items"
      cols="6"
      md="3"
      class="d-flex"
      :key="item.text")
      v-item( :disabled="item.disabled" v-slot="{ active, toggle }" )
        RuleCategoryCard(
          :active="active"
          :disabled="item.disabled"
          :value="item.value"
          @click.native="toggleCheck(toggle, active)")

</template>

<script lang="ts">
import { RuleDto } from '@/api';
import {
  Vue, Component, Model, Prop,
} from 'vue-property-decorator';
import RuleCategoryCard from '@/components/cards/rule-category-card/Rule-Category-Card.vue';

@Component({
  components: {
    RuleCategoryCard,
  },
})
export default class RuleCategorySelector extends Vue {
  @Prop({ type: Array }) items!: [{ value: string, text: string, disabled: boolean }];

  @Model('input', String) category!: RuleDto['category'];

  get categoryIndex() {
    return this.items!.findIndex((c) => c.value === this.category);
  }

  change(value: number) {
    const selectedCategory = this.items[value];
    if (selectedCategory) this.$emit('input', selectedCategory.value);
  }

  toggleCheck(toggleCallback: () => void, isActive: boolean) {
    if (!isActive) {
      toggleCallback();
    }
  }
}
</script>
