<template lang="pug">
component(
  :is="component"
  :height="height"
  :width="width"
  :color1="color1"
  :color2="color2"
  :fill1="fill1"
  :fill2="fill2"
  :between="between" )
</template>

<script lang="ts">
import {
 Vue, Component, Prop,
} from 'vue-property-decorator';
import ConditionalRulesJoinTypeIconLeft from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Join-Type-Icon-Svg/Conditional-Rules-Join-Type-Icon-Left.vue';
import ConditionalRulesJoinTypeIconRight from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Join-Type-Icon-Svg/Conditional-Rules-Join-Type-Icon-Right.vue';
import ConditionalRulesJoinTypeIconInner from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Join-Type-Icon-Svg/Conditional-Rules-Join-Type-Icon-Inner.vue';
import ConditionalRulesJoinTypeIconOuter from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Join-Type-Icon-Svg/Conditional-Rules-Join-Type-Icon-Outer.vue';
import * as ConditionalRulesTYPES from '@/@types/conditional-rules';

@Component
export default class ConditionalRulesJoinTypeIcon extends Vue {
  @Prop({ type: String }) readonly joinType!: ConditionalRulesTYPES.ConditionalRulesJoinType;

  @Prop({ type: String }) readonly color1!: string;

  @Prop({ type: String }) readonly color2!: string;

  @Prop({ type: String }) readonly width!: string;

  @Prop({ type: String }) readonly height!: string;

  get component() {
    switch (this.joinType as ConditionalRulesTYPES.ConditionalRulesJoinType) {
      case 'LEFT':
        return ConditionalRulesJoinTypeIconLeft;
      case 'RIGHT':
        return ConditionalRulesJoinTypeIconRight;
      case 'INNER':
        return ConditionalRulesJoinTypeIconInner;
      case 'OUTER':
        return ConditionalRulesJoinTypeIconOuter;
      default:
        return null;
    }
  }

  get fill1() {
    switch (this.joinType as ConditionalRulesTYPES.ConditionalRulesJoinType) {
      case 'LEFT':
        return this.color1;
      case 'RIGHT':
        return 'transparent';
      case 'INNER':
        return 'transparent';
      case 'OUTER':
        return this.color1;
      default:
        return 'transparent';
    }
  }

  get fill2() {
    switch (this.joinType as ConditionalRulesTYPES.ConditionalRulesJoinType) {
      case 'LEFT':
        return 'transparent';
      case 'RIGHT':
        return this.color2;
      case 'INNER':
        return 'transparent';
      case 'OUTER':
        return this.color2;
      default:
        return 'transparent';
    }
  }

  get between() {
    switch (this.joinType as ConditionalRulesTYPES.ConditionalRulesJoinType) {
      case 'LEFT':
        return 'transparent';
      case 'RIGHT':
        return 'transparent';
      case 'INNER':
        return '#d3d3d3';
      case 'OUTER':
        return '#d3d3d3';
      default:
        return 'transparent';
    }
  }
}
</script>

<style scoped>

</style>
