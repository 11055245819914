<template lang="pug">
v-row
    v-col( cols="6")
        InputName( v-model.trim='datasource.name' label='Name' )

    v-col( cols="6")
        InputHost( v-model.trim='datasource.params.host' )

    v-col( cols="6")
        InputPort( v-model.trim='datasource.params.port' )

    v-col( cols="6")
        InputDatabase( v-model.trim='datasource.params.database' )

    v-col( cols="6" v-if="modifyExisting"  class="border")
        InputSchema( v-model.trim='datasource.params.schema' )

    v-col( cols="6" v-else class="border")
        SchemaSelector(
            type="schemas"
            :tooltip-list-schema="tooltipListSchema"
            :is-scan-active="isScanActive"
            v-model.trim='datasource.params.schema'
            :datasource="datasource")

    v-col( cols="6")
        CredentialsSelector( v-model.trim='datasource.secretId' )

</template>

<script lang="ts">
import {
    Vue, Component, VModel, Watch,
} from 'vue-property-decorator';
import { DatasourceDto, OracleParams } from '@/api';

type OracleSourceDto = DatasourceDto & { params: OracleParams; };

@Component
export default class OracleConnection extends Vue {
    @VModel({ type: Object }) datasource!: OracleSourceDto;

    get modifyExisting() {
        return this.datasource.id;
    }

    get tooltipListSchema() {
        let tooltipValue = '';
        if ('host' in this.datasource.params) {
            tooltipValue += 'The Host';
        }
        if ('port' in this.datasource.params) {
            tooltipValue += ', Port';
        }
        if ('database' in this.datasource.params) {
            tooltipValue += ', Database';
        }
        tooltipValue += ' and Secret';
        tooltipValue += ' must be configured';
        return tooltipValue;
    }

    get isScanActive() {
        let host = true;
        if ('host' in this.datasource.params) {
            host = !!this.datasource.params.host;
        }
        let port = true;
        if ('port' in this.datasource.params) {
            port = !!this.datasource.params.port;
        }
        let database = true;
        if ('database' in this.datasource.params) {
            database = !!this.datasource.params.database;
        }
        return !(host && port && database && this.datasource.secretId);
    }
}
</script>
