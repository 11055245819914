<template lang="pug">
v-card( color="bgTertiary" :to="link" )
  v-list-item(three-line)

    v-list-item-avatar(:tile="tile" size="60")
      v-img( :src="image" )

    v-list-item-content
      v-list-item-title
        v-tooltip(top)
          template(v-slot:activator="{ on, attrs }")
            span.text-h3(v-bind="attrs" v-on="on") {{ title }}
          span {{ title }}
      v-list-item-subtitle
        b.text--primary {{ subtitle1 }}
        span.ml-1.text--primary
          slot(name="subtitle1-content")
      v-list-item-subtitle
        b.text--primary {{ subtitle2 }}
        span.ml-1.text--primary
          slot(name="subtitle2-content")

    v-list-item-icon(v-if="hasStatus")
      slot(name="card-status")

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class BookmarkCard extends Vue {
  @Prop({ type: Boolean, default: false }) readonly tile!: boolean;

  @Prop({ type: Boolean, default: false }) readonly hasStatus!: boolean;

  @Prop({ type: String }) readonly image!: string;

  @Prop({ type: Object }) readonly link!: any;

  @Prop({ type: String }) readonly title!: string;

  @Prop({ type: String }) readonly subtitle1!: string;

  @Prop({ type: String }) readonly subtitle2!: string;
}
</script>
