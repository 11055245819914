/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TagCreateDto = {
    description?: string;
    name: string;
    type: TagCreateDto.type;
};

export namespace TagCreateDto {

    export enum type {
        GENERIC = 'GENERIC',
        HIDDEN_DATA_CLASSIFICATION = 'HIDDEN_DATA_CLASSIFICATION',
        VISIBLE_DATA_CLASSIFICATION = 'VISIBLE_DATA_CLASSIFICATION',
        TERM = 'TERM',
        BIGQUERY_EXTERNAL = 'BIGQUERY_EXTERNAL',
        SNOWFLAKE_EXTERNAL = 'SNOWFLAKE_EXTERNAL',
    }


}
