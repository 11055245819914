<script setup lang="ts">
import { computed } from 'vue';

interface FilterResetProps {
  filterQuantity: number
}

const props = defineProps({
  filterQuantity: null
});

const classes = computed(() => ({
    'secondary--text': props.filterQuantity,
    '': props.filterQuantity === 0,
}));
</script>

<template lang="pug">
.mb-2
  .d-flex.justify-space-between.mb-3.align-center( style="height: 36px;" )
    .d-flex.align-center
      v-icon(class="mr-1" ) icon-filter
      b.body-1( class="classes" ) {{ $tc('common.words.filter', 2) }}
    v-btn( small color="secondary" v-if="filterQuantity" @click="$emit('reset')" text
    data-cy="filter-reset-button" )
      span.text-capitalize.text-decoration-underline {{ $t('common.words.clear') }}
</template>
