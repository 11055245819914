/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AsCodeMonitorParamsDto = {
    kind?: AsCodeMonitorParamsDto.kind;
};

export namespace AsCodeMonitorParamsDto {

    export enum kind {
        DYNAMIC_METRICS = 'DynamicMetrics',
        COMPLETENESS = 'Completeness',
        STATIC_COMPLETENESS = 'StaticCompleteness',
        FRESHNESS = 'Freshness',
        DUPLICATES = 'Duplicates',
        FIELD_UNIQUENESS = 'FieldUniqueness',
        SCHEMA_CHANGE = 'SchemaChange',
        SQL = 'Sql',
        NO_CODE = 'NoCode',
        INTERLINKED_METRICS = 'InterlinkedMetrics',
        DISTRIBUTION = 'Distribution',
        STATIC_FIELD_PROFILING = 'StaticFieldProfiling',
        DYNAMIC_FIELD_PROFILING = 'DynamicFieldProfiling',
        STATIC_METRICS = 'StaticMetrics',
        CUSTOM_METRICS = 'CustomMetrics',
        FIELD_FORMAT = 'FieldFormat',
        FIELD_IN_LIST = 'FieldInList',
        METADATA_FRESHNESS = 'MetadataFreshness',
        REFERENTIAL_INTEGRITY = 'ReferentialIntegrity',
    }


}
