/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EventPayload } from './EventPayload';

export type IncidentStatusUpdatePayload = (EventPayload & {
    newQualification?: IncidentStatusUpdatePayload.newQualification;
    newStatus?: IncidentStatusUpdatePayload.newStatus;
    oldQualification?: IncidentStatusUpdatePayload.oldQualification;
    oldStatus?: IncidentStatusUpdatePayload.oldStatus;
} & {
    newStatus: IncidentStatusUpdatePayload.newStatus;
    oldStatus: IncidentStatusUpdatePayload.oldStatus;
});

export namespace IncidentStatusUpdatePayload {

    export enum newQualification {
        FIXED = 'FIXED',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        EXPECTED = 'EXPECTED',
        KNOWN_ERROR = 'KNOWN_ERROR',
        DUPLICATE = 'DUPLICATE',
    }

    export enum newStatus {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }

    export enum oldQualification {
        FIXED = 'FIXED',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        EXPECTED = 'EXPECTED',
        KNOWN_ERROR = 'KNOWN_ERROR',
        DUPLICATE = 'DUPLICATE',
    }

    export enum oldStatus {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }


}
