/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AsCodeAggregationClauseDto = {
    kind: AsCodeAggregationClauseDto.kind;
};

export namespace AsCodeAggregationClauseDto {

    export enum kind {
        AVERAGE = 'Average',
        NORMALIZED_AVERAGE = 'NormalizedAverage',
        COUNT = 'Count',
        DISTINCT_COUNT = 'DistinctCount',
        SUM = 'Sum',
        MAX = 'Max',
        MIN = 'Min',
        QUANTILE = 'Quantile',
        RANGE = 'Range',
        STANDARD_DEVIATION = 'StandardDeviation',
        VARIANCE = 'Variance',
    }


}
