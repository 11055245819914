<template lang="pug">
  v-card.py-5.px-6( outlined class="card-latest" fill-height style="height: 100%; min-height: 460px;" )
    ChartLatestIncidents
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class DashboardIncidentsLatest extends Vue {
}
</script>
