<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="rules"
    label='Account Id'
    data-cy="account-id"
    dense clearable outlined required)

</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputAccountId extends Vue {
  rules = [(v: string) => isConvertibleToNotBlankString(v) || 'Account Id is required'];
}
</script>
