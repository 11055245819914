/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Parameters of the source
 */
export type PublicSourceParametersDto = {
    type: PublicSourceParametersDto.type;
};

export namespace PublicSourceParametersDto {

    export enum type {
        ATHENA = 'ATHENA',
        BIGQUERY = 'BIGQUERY',
        REDSHIFT = 'REDSHIFT',
        SNOWFLAKE = 'SNOWFLAKE',
        DATABRICKS = 'DATABRICKS',
        HIVE = 'HIVE',
        MSSQL = 'MSSQL',
        MYSQL = 'MYSQL',
        POSTGRESQL = 'POSTGRESQL',
        ORACLE = 'ORACLE',
        SYNAPSE = 'SYNAPSE',
        LOOKER = 'LOOKER',
        TABLEAU = 'TABLEAU',
        QUICKSIGHT = 'QUICKSIGHT',
        AIRFLOW = 'AIRFLOW',
        DBT = 'DBT',
        DBT_CLOUD = 'DBT_CLOUD',
        FIVETRAN = 'FIVETRAN',
        POWER_BI = 'POWER_BI',
        DECLARATIVE = 'DECLARATIVE',
        _UNKNOWN_ = '_UNKNOWN_',
    }


}
