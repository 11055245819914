<template lang='pug'>
v-autocomplete(
    v-bind='$attrs'
    v-on='$listeners'
    :items='regions'
    label='AWS region'
    data-cy='region'
    :rules='regionRules'
    dense clearable outlined required)

</template>

<script lang='ts'>
import { Vue, Component } from 'vue-property-decorator';
import { AwsService } from '@/api';

@Component
export default class RegionSelector extends Vue {
  regionRules = [(v: string) => !!v || this.$t('selectors.region_required')];

  regions: string[] = [];

  mounted() {
    this.fetchRegions();
  }

  async fetchRegions() {
    const data = await AwsService.getListRegions();
    this.regions = data!;
  }
}
</script>
