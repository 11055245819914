/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LastIngestionStatusDto = {
    status: LastIngestionStatusDto.status;
    timestamp: string;
};

export namespace LastIngestionStatusDto {

    export enum status {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        FAILURE = 'FAILURE',
        SKIPPED_DATASOURCE_ALREADY_RUNNING = 'SKIPPED_DATASOURCE_ALREADY_RUNNING',
    }


}
