<template lang="pug">
v-row
    v-col( cols="6")
        InputName( v-model.trim='datasource.name' label='Name' )

    v-col( cols="6" )
        InputDatabase( v-model.trim='datasource.params.database' )

    v-col( cols="6" )
        InputJdbcUrl( v-model.trim='datasource.params.jdbcUrl' )

    v-col( cols="6" )
        InputPrincipal( v-model.trim='datasource.params.principal' )

    v-col( cols="6" )
        CredentialsSelector( v-model.trim='datasource.secretId' )

    v-col( cols="12" )
        InputKrb5( v-model.trim='datasource.params.krb5Conf' )

    v-col( cols="6" )
        InputAtlasBaseUrl( v-model.trim='datasource.params.atlasBaseUrl' )

    v-col( cols="6" )
        InputAtlasPrincipal( v-model.trim='datasource.params.atlasPrincipal' )
</template>

<script lang="ts">
import {
 Vue, Component, VModel,
} from 'vue-property-decorator';
import { HiveParams, DatasourceDto } from '@/api';

type HiveSourceDto = DatasourceDto & { params: HiveParams };

@Component
export default class HiveConnection extends Vue {
    @VModel({ type: Object }) datasource!: HiveSourceDto;
}
</script>
