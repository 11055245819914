export default class BalanceLocation {
    private count = 0;

    private totalForce = 0;

    public addForce(f:number) {
      this.count += 1;
      this.totalForce += f;
    }

    public value(): number {
      return this.count === 0 ? 0 : this.totalForce / this.count;
    }

    reset() {
      this.count = 0;
      this.totalForce = 0;
    }
}
