<template lang="pug">
v-card( :disabled="disabled" data-cy="domain-role-selector" )
  v-row( v-for="domain in sortedDomains" no-gutters :key="domain.id" )
    v-col( cols="8" )
      v-checkbox.mt-0(
        :input-value="isSelected(domain)"
        @change="change($event, domain)"
        :label="domain.name")
    v-col
      v-autocomplete(
        v-if="isSelected(domain)"
        :value="getRole(domain)"
        :items="roles"
        @change="changeRole($event, domain)"
        data-cy="domain-role-selector-autocomplete"
        required dense outlined
        hide-details hide-no-data persistent-hint )

        template(v-slot:item="{ item }")
          div {{ titleCase(item) }}

        template( v-slot:selection="{ item }")
          div {{ titleCase(item) }}

</template>

<script lang="ts">
import {
  Vue, Component, VModel, Prop,
} from 'vue-property-decorator';
import { DomainDto, DomainService, DomainUiLightDto } from '@/api';
import titleCase from '@/utils/filters/titleCase';

@Component
export default class DomainRoleSelector extends Vue {
  @VModel({ type: Array }) domains!: DomainUiLightDto[];

  @Prop({ type: Boolean, default: false }) disabled!: boolean;

  availableDomains: DomainDto[] = [];

  roles = Object.values(DomainUiLightDto.role);

  get sortedDomains() {
    return this.availableDomains.sort((domain, _) => (domain.name === 'All' ? -1 : 1));
  }

  get domainSelection() {
    return this.domains.map((d) => ({ id: d.id, role: d.role }));
  }

  async fetchDomains() {
    const { data } = await DomainService.getAllDomains({ textSearch: '', sort: ['isAllDomain,DESC', 'name,ASC'], itemsPerPage: -1 });
    this.availableDomains = data!;
  }

  isSelected(selectedDomain: DomainDto) {
    return this.domains.some((domain) => domain.id === selectedDomain.id);
  }

  checkAllDomainsAsEditor() {
    this.domains = this
      .availableDomains
      .map((d) => ({
        id: d.id,
        name: d.name,
        role: DomainUiLightDto.role.EDITOR,
        isAllDomain: d.isAllDomain,
      }));
  }

  change(checked: boolean, domain: DomainDto) {
    if (checked) {
      this.domains = [...this.domains, {
        id: domain.id,
        name: domain.name,
        role: DomainUiLightDto.role.VIEWER,
        isAllDomain: domain.isAllDomain,
      }];
    } else {
      this.domains = this.domains.filter((d) => d.id !== domain.id);
    }
  }

  getRole(domain: DomainDto) {
    const d = this.domains.find((_d) => _d.id === domain.id);
    if (d) {
      return d.role;
    }
    return '';
  }

  changeRole(role: DomainUiLightDto.role, domain: DomainDto) {
    const d = this.domains.find((_d) => _d.id === domain.id);
    if (d) {
      d.role = role;
    }
  }

  titleCase = titleCase;

  mounted() {
    this.fetchDomains();
  }
}
</script>
