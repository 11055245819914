<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';

import { DatasourceDto, DatasourceService } from '@/api';
import store from '@/store';
import authModule from '@/store/modules/auth';
import i18n from '@/i18n';
import type ConfirmationDialog from '@/components/confirmation-dialog/Confirmation-Dialog.vue';
import copyToClipboard from '@/utils/copyToClipboard';

interface DatasourceOverviewInfoActionsProps {
  datasource: DatasourceDto;
}
interface DatasourceOverviewInfoActionsEmits {
  (event: 'deleted'): void
}

const auth = getModule(authModule, store);

const props =  defineProps({
  datasource: null
});
const emits = defineEmits(["deleted"]);

const confirmationDialogRef = _ref<ConfirmationDialog | null>(null);

const canWrite = _computed(() => auth.userActions['integration.datasource.write']);
const hasDatasource = _computed(() => props.datasource !== null);
const isDeclarativeSource = _computed(() => props.datasource?.creationType === DatasourceDto.creationType.DECLARED);

const editSourceLocation = _computed(() => {
  if (!props.datasource) return null;

  return {
    name: 'sources.source.edit',
    params: {
      id: props.datasource.id,
    },
  };
});

const deleteSource = async () => {
  const bodyMessage = i18n.t('sources.delete_datasources_confirm');
  await confirmationDialogRef.value!
    .setTitle(i18n.tc('monitors.delete_rule', 1))
    .setBody(bodyMessage)
    .waitForUserAction();
  await DatasourceService.deleteDatasourceById({ id: props.datasource.id });
  Vue.notify({
    text: i18n.t('sources.delete_source_success', { name: props.datasource.name }) as string,
  });
  emits('deleted');
};

const copyIDToClipboard = () => {
  copyToClipboard(props.datasource.id!, i18n.t('assets.copied_to_clipboard', { value: props.datasource.id }));
};
const copyUriToClipboard = () => {
  copyToClipboard(props.datasource.sourceUri!, i18n.t('assets.copied_to_clipboard', { value: props.datasource.sourceUri }));
};

const manualRun = async () => {
  await DatasourceService.datasourceIngestionManualRun({ id: props.datasource.id });
  Vue.notify({
    text: i18n.t('sources.run_source_success', { name: props.datasource.name }) as string,
  });
};
</script>

<template lang="pug">

.datasource-overview-info-actions
  template(v-if="hasDatasource")
    .d-flex.flex-nowrap.justify-end( v-if="!isDeclarativeSource" )

      SButton.mr-3(
        v-if="canWrite"
        icon="icon-trash"
        :text="$t('cta.delete')"
        color="danger"
        variant="outlined"
        @click="deleteSource")

      SButton.mr-3(
        :disabled='!canWrite'
        icon="icon-edit"
        :text="$t('cta.edit')"
        :to="editSourceLocation"
        color="secondary"
        variant="outlined")

      SButton.mr-3(
        :disabled="!canWrite"
        icon="icon-play-circle"
        :text="$t('cta.run')"
        color='primary'
        :async-action='manualRun')

      SThreeDotMenu
        SMenuButton(
          @click="copyIDToClipboard"
          icon="icon-copy"
          :text="$t('sources.copy_id')")

    .d-flex.flex-nowrap.justify-end(v-else)
      SButton(
        icon="icon-copy"
        :text="$t('sources.copy_uri')"
        color="primary"
        variant="outlined"
        @click="copyUriToClipboard")

    ConfirmationDialog( content-class="delete-dialog" ref="confirmationDialogRef" )

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    type="actions"
    width="500" )

</template>
