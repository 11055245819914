<template lang="pug">

v-autocomplete.dataset-selector(
  ref="datasetSelector"
  v-bind='$attrs'
  v-on='$listeners'
  :items="items"
  :loading="loading"
  :search-input.sync="textSearch"
  @click:clear="clearHandler"
  @change="textSearch=''"
  @keydown="keyDownHandler"
  placeholder="Start typing to search"
  item-value="id"
  item-text="name"
  data-cy="dataset-selector"
  no-filter open-on-clear
  dense outlined persistent-hint clearable
  hide-no-data )

  template(v-slot:item="{ item, on, attrs }")
    v-list-item(
      data-cy="dataset-selector-item"
      :class="itemClass(attrs)" ripple @mousedown.prevent v-on="on" active )
      v-list-item-action
        v-icon( :color="color(attrs)" ) {{ icon(attrs) }}
      v-list-item-content
        .d-flex.flex-grow-1.flex-shrink-0( style="width: 100%" )
          v-avatar(tile size="20" class="mr-1")
            img( :src="image(item.datasourceType)" :alt="item.datasourceType" )
          span.mr-1.font-weight-light {{ item.datasourceName }}
          span.mr-1.font-weight-medium {{ item.name }}

  template( v-slot:selection="{ item }")
    .d-flex.pa-1.text-truncate
      PositionDot( v-if="hasPositionDot" :index="getIndex(item)" class="mr-1" )
      v-avatar(tile size="20" class="mr-1")
        img( :src="image(item.datasourceType)" :alt="item.datasourceType" )
      span.text-truncate.mr-1 {{ item.name }}

  template( v-slot:append-item )
    div( v-if="hasMoreItems" v-intersect="intersectOptions" class="pa-4 primary--text" ) {{ $t('common.words.loading_more_items') }}

</template>

<script lang="ts">
import {
  Vue, Component, Watch, Ref, Prop,
} from 'vue-property-decorator';
import { DatasetService, DatasetLightDto } from '@/api';
import src from '@/utils/filters/src';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';

const auth = getModule(authModule, store);

@Component
export default class DatasetSelector extends Vue {
  @Prop({ type: Number }) index!: number;

  searchString = '';

  textSearch = '';

  datasets: DatasetLightDto[] = [];

  prefetchDatasets: DatasetLightDto[] = [];

  loading = false;

  totalElements = 0;

  page = 1;

  timer: any;

  typingInterval = 500;

  @Ref('datasetSelector') datasetSelector: any;

  intersectOptions: any = {
    handler: this.onIntersect,
    options: {
      trackVisibility: true,
      delay: 300,
    },
  };

  get hasPositionDot(): boolean {
    return 'has-position-dot' in this.$attrs || Number.isInteger(this.index);
  }

  get items() {
    return [...new Set([
      ...this.prefetchDatasets,
      ...this.datasets])];
  }

  get hasMoreItems(): boolean {
    return this.items.length < this.totalElements;
  }

  icon(attrs: any): string {
    return attrs.inputValue ? 'icon-check-square-fill' : 'icon-check-square-outline-empty';
  }

  color(attrs: any): string {
    return attrs.inputValue ? 'iconInfo' : '';
  }

  itemClass(attrs: any): string {
    return attrs.inputValue ? 'primary--text v-list-item--active' : '';
  }

  image(datasourceType: any) {
    return src(datasourceType, 'datasources');
  }

  get internalValue(): any {
    return this.$attrs.value;
  }

  @Watch('internalValue')
  onValueChange() {
    this.prefetchModelData();
  }

  keyDownHandler() {
    clearTimeout(this.timer);
    this.timer = setTimeout(this.searchWhenTyping, this.typingInterval);
  }

  async searchWhenTyping() {
    this.page = 1;
    this.datasets = [];
    await this.fetchDatasources();
    await this.prefetchModelData();
  }

  clearHandler() {
    this.textSearch = '';
    this.page = 1;
    this.datasets = [];
    this.prefetchDatasets = [];
    this.fetchDatasources();
  }

  onIntersect(entries: any) {
    const { isIntersecting } = entries[0];
    if (isIntersecting) {
      this.page += 1;
      this.fetchDatasources();
    }
  }

  getIndex(item: DatasetLightDto): number | undefined {
    if (this.index) return this.index;
    const value = this.$attrs.value as unknown as null | string | DatasetLightDto['id'] | DatasetLightDto | DatasetLightDto['id'][] | DatasetLightDto[];
    if (!value) return undefined;
    if (typeof value === 'string') {
      return value === item.id ? 0 : undefined;
    }
    if (!Array.isArray(value) && typeof value !== 'string') {
      return value.id === item.id ? 0 : undefined;
    }
    if (Array.isArray(value)) {
      const type = typeof value[0];
      if (type === 'string') {
        return value.findIndex((v) => v === item.id);
      }
      if (type === 'object') {
        return value.findIndex((v: any) => v.id === item.id);
      }
    }
    return undefined;
  }

  async prefetchModelData() {
    // urn can be in the form of
    // String "ad2d8428-f61e-4f86-a81a-a000a2e49b65"
    // Array of strings ["ad2d8428-f61e-4f86-a81a-a000a2e49b65", ...]
    // Object { id: "ad2d8428-f61e-4f86-a81a-a000a2e49b65" }
    // Array of objects [{ id: "ad2d8428-f61e-4f86-a81a-a000a2e49b65" }, ...]
    const value = this.$attrs.value as unknown as null | string | DatasetLightDto['id'] | DatasetLightDto | DatasetLightDto['id'][] | DatasetLightDto[];
    if (!value) return;
    let urn: string[];
    if (typeof value === 'string') urn = [`dataset:${value}`];
    else if (!Array.isArray(value) && typeof value !== 'string') urn = [`dataset:${value.id}`];
    else if (Array.isArray(value)) {
      urn = value.map((v) => {
        if (typeof v === 'string') return `dataset:${v}`;
        if (typeof v === 'object') return `dataset:${v.id}`;
        return '';
      });
    } else urn = [];
    if (!urn.length) return;
    await DatasetService.getAllDataset({
      urn,
      domain: this.domain,
    }).then(({ data }) => {
      this.prefetchDatasets = data!;
    });
  }

  get domain() {
    return auth.selectedDomain?.name;
  }

  async fetchDatasources() {
    this.loading = true;
    return DatasetService.getAllDataset({
      textSearch: this.textSearch,
      page: this.page,
      domain: this.domain,
    }).then(({ data, totalElements }) => {
      this.datasets.push(...data!);
      this.totalElements = totalElements!;
      this.loading = false;
    });
  }

  async mounted() {
    await this.prefetchModelData();
    await this.fetchDatasources();
  }
}
</script>
