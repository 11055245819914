<template lang="pug">
  v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    label='VPC Url (optional)'
    data-cy="vpcUrl"
    dense clearable outlined required)

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputVpcUrl extends Vue {
}
</script>
