import { ThresholdSettingsEnum, AnomalyConditionsEnum } from './types';
import { dynamicItems, relativeItems, staticItems } from './anomalyConditions';
import { convertDynamicThresholdToLegacy, convertStaticThresholdToLegacy } from './legacyConverter';

const dynamicSettings = {
  type: ThresholdSettingsEnum.DYNAMIC,
  text: 'monitor_wizard.threshold_settings.dynamic',
  icon: 'icon-sparkles',
  description: 'monitor_wizard.threshold_settings.dynamic_description',
  disabled: false,
  hasSensitivity: true,
  hasAnomalyConditions: true,
  anomalyConditions: dynamicItems,
  convertThresholdToLegacy: convertDynamicThresholdToLegacy,
};

const staticSettings = {
  type: ThresholdSettingsEnum.STATIC,
  text: 'monitor_wizard.threshold_settings.static',
  icon: 'icon-arrow-sort',
  description: 'monitor_wizard.threshold_settings.static_description',
  disabled: false,
  hasSensitivity: false,
  hasAnomalyConditions: true,
  anomalyConditions: staticItems,
  convertThresholdToLegacy: convertStaticThresholdToLegacy,
};

const relativeSettings = {
  type: ThresholdSettingsEnum.RELATIVE,
  text: 'monitor_wizard.threshold_settings.relative',
  icon: 'icon-arrow-refresh-play',
  description: 'monitor_wizard.threshold_settings.relative_description',
  disabled: false,
  hasSensitivity: false,
  hasAnomalyConditions: true,
  anomalyConditions: relativeItems,
  convertThresholdToLegacy: convertStaticThresholdToLegacy,
};

const exactSettings = {
  type: ThresholdSettingsEnum.EXACT,
  text: 'monitor_wizard.threshold_settings.exact',
  icon: 'icon-target-arrow',
  description: 'monitor_wizard.threshold_settings.exact_description',
  disabled: false,
  hasSensitivity: false,
  hasAnomalyConditions: false,
  convertThresholdToLegacy: () => null,
};

export {
  dynamicSettings,
  staticSettings,
  relativeSettings,
  exactSettings,
};
