import dayjs from 'dayjs';
import Vue from 'vue';

import Vuetify from 'vuetify/lib';
import { PiecewiseVisualMapComponentOption } from 'echarts';
import {
  TooltipInfo, TooltipQualificationInfo,
} from '@/@types/chart';
import {
  TimeSeriesPoint,
} from '@/api';
import i18n from '@/i18n';
import icons from '@/plugins/icons';

import Tooltip from '@/components/charts/Tooltip.vue';

export const HOVER_SYMBOL_SIZE = 12;
export const ANOMALY_SYMBOL_MIN_SIZE = 12;
export const ANOMALY_SYMBOL_MAX_SIZE = 20;
export const LINE_COLOR_ANOMALY = '#C5423F';
export const LINE_COLOR_NO_ANOMALY = '#10B981';
export const LINE_COLOR_UNDETERMINED = 'black';

export const axisLabelStyle = {
  fontFamily: 'DM Sans',
  fontSize: '12px',
  fontWeight: '500',
  color: '#101828',
  overflow: 'truncate',
  hideOverlap: true,
};
export const axisPointerLabelStyle = {
  fontFamily: 'DM Sans',
  fontSize: '12px',
  fontWeight: '500',
  color: '#101828',
};

export const getHtmlTooltip = (props: { items: TooltipInfo[], qualification: TooltipQualificationInfo }) => {
  const TooltipComponent = Vue.extend(Tooltip);
  let vuetify: Vuetify | null = new Vuetify({ icons });
  const tooltip = new TooltipComponent({
    propsData: {
      items: props.items,
      qualification: props.qualification,
    },
    i18n,
    vuetify,
  });
  const tooltipHtml = tooltip.$mount().$el.outerHTML;

  // release memory ressources by closing instances of vue and
  tooltip.$destroy();
  vuetify = null;

  return tooltipHtml;
};

export const getRoundedAxis = (min: number, max: number, minIntervalCount: number) => {
  const interval = (max - min) / minIntervalCount;
  if (interval === 0) {
    return {
      min: min - Math.ceil(minIntervalCount / 2),
      max: max + Math.ceil(minIntervalCount / 2),
      interval: 1,
    };
  }
  const intervalPrecision = Math.ceil(Math.log10(Math.abs(interval))) - 1;
  let bestMinY = min;
  let bestMaxY = max;
  let bestRoundedInterval = 1;
  for (const scaledRoundedInterval of [1, 2, 5, 10]) {
    const roundedInterval = scaledRoundedInterval * Number((10 ** intervalPrecision).toPrecision(1));
    const newMinY = Math.floor(min / roundedInterval) * roundedInterval;
    const newMaxY = Math.ceil(max / roundedInterval) * roundedInterval;
    const intervalCount = (newMaxY - newMinY) / roundedInterval;
    if (intervalCount < minIntervalCount) {
      break;
    }
    bestMinY = newMinY;
    bestMaxY = newMaxY;
    bestRoundedInterval = roundedInterval;
  }

  return {
    min: bestMinY,
    max: bestMaxY,
    interval: bestRoundedInterval,
  };
};

export default class ChartConfig {
  static visualMapConfigForAnomalies(seriesIndex: number, points: TimeSeriesPoint[]) {
    // Possible configs: https://echarts.apache.org/en/option.html#visualMap-continuous
    type PieceType = Exclude<PiecewiseVisualMapComponentOption['pieces'], undefined>[number];
    const pieces: PieceType[] = [{
        color: LINE_COLOR_UNDETERMINED,
      },
      {
        // Required because of this issue: https://github.com/apache/echarts/issues/13347
        gte: -2,
        color: LINE_COLOR_UNDETERMINED,
      },
      {
        gte: -1,
        color: LINE_COLOR_UNDETERMINED,
      },
    ];

    let previousIsAnomaly: boolean | null = null;
    let previousDate = 1;
    for (const graphPoint of points) {
        const { date } = graphPoint;

        const isAnomaly = graphPoint.anomaly ?? null;

        if (previousIsAnomaly !== isAnomaly) {
            let newColor: string;
            let includePreviousLine: boolean;

            if (isAnomaly === null) {
                newColor = LINE_COLOR_UNDETERMINED;
                includePreviousLine = false;
            } else if (isAnomaly) {
                newColor = LINE_COLOR_ANOMALY;
                includePreviousLine = true;
            } else {
                newColor = LINE_COLOR_NO_ANOMALY;
                includePreviousLine = previousIsAnomaly === null;
            }

            if (includePreviousLine) {
                pieces.push({
                    gt: previousDate,
                    color: newColor,
                });
            } else {
                pieces.push({
                    gte: date,
                    color: newColor,
                });
            }
        }

        previousIsAnomaly = isAnomaly;
        previousDate = date;
    }

    for (let i = 0; i < pieces.length; i += 1) {
        if (pieces[i].gt !== undefined) {
            pieces[i - 1].lte = pieces[i].gt;
        }
        if (pieces[i].gte !== undefined) {
            pieces[i - 1].lt = pieces[i].gte;
        }
    }

    return {
      show: false,
      type: 'piecewise',
      dimension: 'date',
      seriesIndex,
      pieces,
    };
  }

  static titleConfig(params?: { title?: string, subtitle?: string}) {
    // Possible configs: https://echarts.apache.org/en/option.html#title
    return {
      text: params?.title,
      subtext: params?.subtitle,
      textStyle: {
        fontFamily: 'DM Sans',
        fontSize: '14px',
        fontWeight: '500',
        color: '#101828',
      },
      padding: 12,
      subtextStyle: {
        fontFamily: 'DM Sans',
        fontSize: '14px',
        fontWeight: '400',
        color: '#667085',
      },
    };
  }

  static tooltipConfig(tooltipFormatCallback: (payload: any[]) => string) {
    // Possible configs: https://echarts.apache.org/en/option.html#tooltip
    return {
      padding: 2,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        animation: true,
        label: {
          backgroundColor: '#fff',
          borderColor: 'grey',
          borderWidth: 1,
          shadowBlur: 0,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          color: 'grey',
          fontSize: '12px',
          borderRadius: 20,
        },
      },
      textStyle: {
        fontFamily: 'DM Sans',
        fontSize: '12px',
        fontWeight: '400',
        color: '#101828',
        interval: 0,
        hideOverlap: true,
        width: 100,
        overflow: 'truncate',
      },
      formatter: (series: any[]) => tooltipFormatCallback(series),
    };
  }

  static gridConfig() {
    // Possible configs: https://echarts.apache.org/en/option.html#grid
    return {
      left: '2%',
      right: '3%',
      top: '80px',
      bottom: '62px',
      containLabel: true,
    };
  }

  static xAxisConfig() {
    return {
      type: 'time',
      axisLabel: {
        width: 100,
        ...axisLabelStyle,
      },
      axisPointer: {
        label: {
          formatter: (params: any) => dayjs(params.value).tz().format('ll') || '',
          ...axisPointerLabelStyle,
        },
      },
    };
  }

  static yAxisConfig(params?: { min?: number, max?: number, interval?: number, formatValue?: (value: number) => string }) {
    return {
      min: params?.min,
      max: params?.max,
      interval: params?.interval,
      axisLabel: {
        formatter: params?.formatValue ? (value: number) => params.formatValue!(value) : null,
        width: 100,
        ...axisLabelStyle,
      },
      axisPointer: {
        label: {
          formatter: params?.formatValue ? (p: any) => params.formatValue!(p.value) : null,
          ...axisPointerLabelStyle,
        },
      },
      splitLine: {
        lineStyle: {
          color: '#eeeeee',
        },
      },
    };
  }

  static dataZoomConfig(params?: { startValue?: Date, endValue?: Date, start?: number, end?: number }) {
    // Possible configs: https://echarts.apache.org/en/option.html#dataZoom
    return [{
      type: 'slider',
      realtime: false,
      brushSelect: false,
      startValue: params?.startValue,
      endValue: params?.endValue,
      start: params?.start,
      end: params?.end,
      fillerColor: 'transparent',
      dataBackground: {
        lineStyle: {
          color: '#f2f4f7',
          opacity: '0.8',
          cap: 'square',
        },
        areaStyle: {
          color: '#f2f4f7',
          opacity: '0.8',
        },
      },
      selectedDataBackground: {
        lineStyle: {
          color: '#d0d5dd',
          opacity: '0.8',
          cap: 'square',
        },
        areaStyle: {
          color: '#d0d5dd',
          opacity: '0.8',
        },
      },
      labelFormatter: (value: number) => dayjs(value).tz().format('MMM D, YYYY hh:mm A'),
    },
    {
      type: 'inside',
      throttle: 0,
      animation: false,
      zoomOnMouseWheel: true,
      moveOnMouseMove: true,
    },
    ];
  }

  static textStyleConfig() {
    return {
      fontFamily: 'DM Sans',
    };
  }

  static toolboxConfig() {
    return {
      show: false,
    };
  }
}
