<template lang="pug">
.ai-assistant-suggestion-card(:style="`height: ${calculatedHeight}px`")
  div.ai-assistant-suggestion-card-container
    div.label.pl-1.pr-1
      v-icon.mr-1(small color="iconAi") icon-sparkles
      span {{ title }}
    div.mt-1.pa-3(:class="slotClasses")
      slot(name="content")

    div(v-if="canCollapse").d-flex.align-center.collapse-button
      div(@click.prevent="toggleCollapse").collapse-button-container.pr-1.pl-1
        span.mr-1 {{ collapseText }}
        v-icon(small).collapse-icon {{ collapseIcon }}
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

const CONTAINER_HEIGHT = 80;

@Component
export default class AiAssistantSuggestionCard extends Vue {
  @Prop(String) readonly title!: string;

  @Prop({ type: Boolean, default: false }) readonly canCollapse!: boolean;

  // Kinda a workaround due to the design
  @Prop({ type: Number, default: 0 }) readonly collapsedHeight!: number;

  isCollapsed = false;

  get calculatedHeight() {
    return this.isCollapsed ? this.collapsedHeight + CONTAINER_HEIGHT : CONTAINER_HEIGHT;
  }

  get slotClasses() {
    if (!this.canCollapse) {
      return '';
    }

    return this.isCollapsed ? 'collapsed' : 'not-collapsed';
  }

  get collapseText() {
    return this.isCollapsed ? this.$t('common.words.show_less') : this.$t('common.words.show_all');
  }

  get collapseIcon() {
    return this.isCollapsed ? 'icon-chevron-up' : 'icon-chevron-down';
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
}
</script>

<style lang="scss" scoped>
.ai-assistant-suggestion-card {
  width: 100%;
  position: relative;
  border-radius: 5px;
  background: linear-gradient(0deg, map-get($purple, 'darken-1'), map-get($purple, 'lighten-3'));
}

/* @TODO: Make sure we adapt this for darkmode */
.ai-assistant-suggestion-card-container {
  position: absolute;
  background: var(--v-bgPrimary-base);
  border-radius: 4px;
  width: calc(100% - 2px);
  height: calc(100% - 3px);
  top: 1px;
  left: 1px;
}

.label {
  display: flex;
  align-items: center;
  position: absolute;
  top: -7px;
  left: 8px;
  color: var(--v-textAi-base);
  background: var(--v-bgPrimary-base);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.8px;
}

.collapse-button {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: -10px;
  color: map-get($purple, 'base');
  cursor: pointer;
}

.collapse-button-container {
  background: var(--v-bgPrimary-base);
}

.collapse-icon {
  color: map-get($purple, 'base');
}

.not-collapsed {
  overflow: hidden;
  max-height: 65px;
}
</style>
