<template lang="pug">
  v-card( outlined @click="$router.push(link)" width="250px" class="border-all" )
    v-list-item(two-line)

      v-list-item-avatar(:tile="tile" size="40")
        v-img( v-if="image" :src="image" )
        v-icon( v-else-if="icon" large color="iconDanger" ) {{ icon }}

      v-list-item-content
        v-list-item-subtitle
          span.text--primary
            slot(name="subtitle1-content")
        v-list-item-title
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              span.font-weight-medium(v-bind="attrs" v-on="on") {{ title }}
            span {{ title }}
        v-list-item-subtitle
          span.text--primary.text-subtitle-2
            slot(name="subtitle2-content")

      v-list-item-icon(v-if="hasStatus")
        slot(name="card-status")

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class DashboardPinnedCard extends Vue {
  @Prop({ type: Boolean, default: () => false }) readonly tile!: boolean;

  @Prop({ type: Boolean, default: () => false }) readonly hasStatus!: boolean;

  @Prop({ type: String }) readonly image!: string;

  @Prop({ type: String }) readonly icon!: string;

  @Prop({ type: Object }) readonly link!: any;

  @Prop({ type: String }) readonly title!: string;

  @Prop({ type: String }) readonly subtitle1!: string;

  @Prop({ type: String }) readonly subtitle2!: string;

  get color() {
    return this.$vuetify.theme.dark ? '' : 'grey lighten-4';
  }
}
</script>
