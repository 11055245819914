/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateIncidentDto = {
    assignedUserIds: Array<string>;
    criticality: number;
    name: string;
    ruleIds: Array<string>;
    status: CreateIncidentDto.status;
};

export namespace CreateIncidentDto {

    export enum status {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }


}
