<template lang="pug">
ModalDialog(ref='modalDialog' :title="$t('common.words.reset_password')")

  template(v-slot:body)
    v-card
      v-container(fluid)

        v-row( v-if='isFirstLogin' align='center' justify='center')
          v-col(cols='12')
            v-alert( outlined type='warning' )
              span {{ $t('common.messages.REQUEST_RESET_PASSWORD') }}

        v-row
          v-col
            v-form( ref='form' v-model='valid' )
              v-row
                v-col( cols="12" )
                    v-text-field(
                      v-model.trim='oldPassword'
                      :append-icon="show ? 'icon-eye-off' : 'icon-eye'"
                      :type="show ? 'text' : 'password'"
                      @click:append='show = !show'
                      placeholder=" "
                      label='Old Password'
                      persistent-hint
                      dense outlined required)
                v-col( cols="12" )
                  v-text-field(
                    v-model.trim='newPassword'
                    :append-icon="show ? 'icon-eye-off' : 'icon-eye'"
                    :type="show ? 'text' : 'password'"
                    @click:append='show = !show'
                    placeholder=" "
                    :rules="rules"
                    autocomplete="current-password"
                    label='New Password'
                    :hint='`Minimum 12 chars with numbers and symbols (${symbols})`'
                    dense outlined
                    persistent-hint required)
                v-col( cols="12" )
                  v-text-field(
                    v-model.trim='passwordConfirm'
                    :append-icon="show ? 'icon-eye-off' : 'icon-eye'"
                    :type="show ? 'text' : 'password'"
                    :rules="[matchingPasswords, required]"
                    @click:append='show = !show'
                    placeholder=" "
                    autocomplete="current-password"
                    label='Confirm New Password'
                    persistent-hint
                    dense outlined required)

  template(v-slot:actions)
    v-btn(color='primary' class="custom-primary" :disabled='!valid' @click='save')  {{ $t('common.words.save') }}

</template>

<script lang="ts">
import { Component, Vue, Ref } from 'vue-property-decorator';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';
import { UserService } from '@/api';
import rules, { matchingPasswords, SYMBOLS, required } from '@/utils/passwordValidation';

let auth: authModule;

@Component
export default class ResetPassword extends Vue {
  show = false;

  newPassword = '';

  oldPassword = '';

  symbols = SYMBOLS;

  passwordConfirm = '';

  valid = false;

  rules = rules;

  required = required;

  matchingPasswords(passwordConfirm: string) {
    return matchingPasswords(this.newPassword, passwordConfirm);
  }

  get isFirstLogin() {
    return this.user?.resetPassword;
  }

  get user() {
    return auth.getUser!;
  }

  @Ref()
  modalDialog!: ModalDialog;

  open() {
    this.modalDialog.openDialog();
  }

  beforeCreate() {
    auth = getModule(authModule, store);
  }

  mounted() {
    if (this.isFirstLogin) {
      this.open();
    }
  }

  async save() {
    const requestBody = {
      newPassword: this.newPassword,
      oldPassword: this.oldPassword,
    };
    await UserService.changePassword({ id: this.user.id, requestBody })
      .then(() => {
        this.$notify({
          text: 'Your password was changed successfully',
        });
        this.modalDialog.closeDialog();
      });
  }
}

</script>
