/**
 * Transforms the string into uppercase
 * @param {string} string - String to transform in uppercase
 * @return {string} - Uppercased string
 */
export default (string: string | undefined) => string?.toUpperCase();

declare module 'vue/types/vue' {
  interface Vue {
    uppercase: (string: string | undefined) => undefined | string;
  }
}
