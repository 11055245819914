<template lang="pug">
.d-inline-flex
  v-combobox(
    ref="schemaSelector"
    v-bind='$attrs'
    v-on='$listeners'
    :loading="loading"
    :items="schemas"
    :search-input.sync="searchString"
    :rules="schemasRules"
    :hint="`Select one or multiple ${type}`"
    @click="clickHandler"
    @change="onChange"
    placeholder="Start typing to search"
    item-value="id"
    data-cy="schema-selector"
    dense outlined clearable return-object chips small-chips persistent-hint
    multiple deletable-chips)

    template(v-slot:no-data)
      v-list-item
        v-list-item-content
          v-list-item-title {{ $t('selectors.no_results', { string: searchString }) }}

    template(v-slot:prepend-item)
      v-list-item(ripple @mousedown.prevent @click='toggle')
        v-list-item-action
          v-icon(color='primary') {{ selectAllAutocompleteIcon }}
        v-list-item-content
          v-list-item-title {{ $t('selectors.select_all') }}
      v-divider.mt-2

    template(v-slot:selection="{ item }")
      v-chip.mt-1(x-small) {{ item }}

    template(v-slot:append-outer)
      v-btn( :loading="loading" color="secondary" class="custom-secondary" text outlined @click="scanHandler" )
        v-icon( left ) icon-data-row-search
        span {{ $t('selectors.list', {type: type}) }}

      v-tooltip(top)
        template(v-slot:activator="{ on, attrs }")
          v-icon.ml-1(v-bind="attrs" v-on="on" ) icon-info-circle-fill
        span {{ tooltipListSchema }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { DatasourceDto, DatasourceService } from '@/api';

@Component
export default class SchemaSelector extends Vue {
  @Prop({ type: Object }) datasource!: DatasourceDto;

  @Prop(String) type!: string;

  @Prop(String) tooltipListSchema!: string;

  @Prop(Boolean) isScanActive!: boolean;

  searchString = '';

  loading = false;

  schemas: string[] = [];

  schemasRules = [(v: string) => !!v.length || 'At least 1 schema is required'];

  clickHandler() {
    const { schemaSelector } = this.$refs as any;
    if (schemaSelector.isMenuActive) setTimeout(schemaSelector.blur, 0);
  }

  async scanHandler() {
    this.loading = true;
    this.schemas = [];
    try {
      this.schemas = await DatasourceService.getAllDatasourceSchemas({ requestBody: this.datasource });
    } finally {
      this.loading = false;
    }
  }

  get _schemas() {
    return this.$attrs.value as unknown as string[];
  }

  set _schemas(value: string[]) {
    this.$emit('input', value);
  }

  get selectAllFields() {
    return this.schemas.length === this._schemas.length;
  }

  get showAllFields() {
    return this._schemas.length === this.schemas.length;
  }

  get showSomeFields() {
    return this._schemas.length > 0 && !this.showAllFields;
  }

  get selectAllAutocompleteIcon() {
    if (this.showSomeFields) return 'icon-check-square-partial-fill';
    if (this.showAllFields) return 'icon-dismiss-circle-fill';
    return 'icon-check-square-outline-empty';
  }

  toggle() {
    this.$nextTick(() => {
      if (this.selectAllFields) {
        this._schemas = [];
      } else {
        this._schemas = this.schemas.slice();
      }
    });
  }

  onChange(schemasManual: string[]) {
    // add custom schema
    schemasManual.forEach((schemaManual) => {
      if (!this.schemas.includes(schemaManual)) {
        this.schemas = [schemaManual].concat(this.schemas);
      }
    });
  }
}
</script>
