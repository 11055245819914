/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RuleDryRunResultDto = {
    description: string;
    failingRows: Array<Record<string, Record<string, any>>>;
    status: RuleDryRunResultDto.status;
};

export namespace RuleDryRunResultDto {

    export enum status {
        PENDING = 'PENDING',
        RUNNING = 'RUNNING',
        SUCCESS = 'SUCCESS',
        FAILED = 'FAILED',
        TECHNICAL_ERROR = 'TECHNICAL_ERROR',
        REQUIRES_YOUR_ATTENTION = 'REQUIRES_YOUR_ATTENTION',
    }


}
