<script setup lang="ts">import { computed as _computed } from 'vue';

import { DataItemProps } from 'vuetify';
import type { IncidentLightDto } from '@/api';
import fromNow from '@/utils/filters/fromNow';
import IncidentStatus from '@/components/incidents/Incident-Status.vue';

interface IncidentResultsCardProps {
  itemProps: Omit<DataItemProps, 'item'> & { item: IncidentLightDto }
}

const props = defineProps({
  itemProps: null
});

const lastFailureLabel = _computed(() => fromNow(props.itemProps.item.lastOccurredDate));
const incidentLink = _computed(() => ({
  name: 'incidents.incident.incident_overview',
  params: {
    issue: String(props.itemProps.item.issueNo),
    name: String(props.itemProps.item.name),
  },
}));
const isClosed = _computed(() => props.itemProps.item.status === 'CLOSED');
const flagBackgroundColor = _computed(() => (isClosed.value ? 'grey lighten-4' : 'red lighten-4'));
const flagIconColor = _computed(() => (isClosed.value ? 'grey' : 'red'));
</script>

<template lang="pug">
router-link(
  :to="incidentLink"
  class="no-link"
)
  .d-flex.py-4.pl-2.border-bottom.incident-result-card
    v-simple-checkbox.mr-1.align-self-start(
      color="primary"
      @click.prevent
      @input="itemProps.select"
      :value="itemProps.isSelected"
    )
    v-avatar.mr-2(size="32" :color="flagBackgroundColor")
      v-icon(:color="flagIconColor") icon-flag
    .d-flex.flex-column
      span.text-h6.font-weight-medium.ml-1.mb-1 {{ itemProps.item.name }}
      .d-flex.flex-wrap
        TableIcon.mr-2.mb-1(:dataset="monitor" v-for="monitor in itemProps.item.datasets" :key="monitor.id")
      .d-flex.align-center.mt-2
        IncidentStatus.mr-4( :status="itemProps.item.status" )
        Owners.mr-4(v-if="itemProps.item.owners.length" :owners="itemProps.item.owners" facepile)
        Severity.mr-4(:value="itemProps.item.criticality")
        .d-flex.align-center.mr-4
          v-icon.mr-1(small color="grey") icon-chevron-right-sharp-equal
          span.grey--text.small-text {{ `${$t('incidents.last_failure')} ${lastFailureLabel}` }}
        .d-flex.align-center
          v-icon.mr-1(small color="grey") icon-data-warning
          span.grey--text.small-text {{ $tc('incidents.compromised_dashboards', itemProps.item.compromisedAssets)}}
</template>

<style lang="scss" scoped>
.small-text {
  font-size: 12px;
}

.incident-result-card {
  &:hover {
    background-color: var(--v-bgPrimaryHover-base);
  }
}
</style>
