/* eslint-disable no-bitwise */
/**
 * Checks if a value corresponds to a non-blank string.
 * @param {any} value - A standard string
 * @return {boolean} - Returns the result
 */
export default function isConvertibleToNotBlankString(value: string | number | null | undefined) {
  if (value === null || value === undefined) {
    return false;
  }
  return String(value).trim().length > 0;
}
