<template lang="pug">

.menu-item(
  v-if="isEnabled"
  :class="item.class"
  class="flex flex-grow-0")

  v-list-item(
    :to="item.to"
    link
    :data-cy="item.dataCy" )

    .d-flex.flex-fill

      v-list-item-icon
        v-icon.ml-1( size="25" ) {{ item.icon }}

      v-list-item-title
        .ml-1.d-flex.justify-space-between
          span.text.text-h6 {{ $t(item.title) }}
          v-chip( small outlined color="textOnsurface" v-if="item.beta" )
            .white--text {{ $t('common.words.beta') }}
          v-icon( small v-if="item.items" class="float-right" ) icon-chevron-right

  .menu-item-sub-menu( @click="hideElement" :class="item.itemsPosition" )
    v-card(
      v-if="item.items && item.itemsPosition"
      color="textPrimary"
      elevation="6"
      tile )
      NavigationDrawerItem(
        v-for='sub_item in item.items'
        :key="sub_item.title"
        :item="sub_item" )

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';
import { DrawerItem } from './Navigation-Drawer-Items';

let auth: authModule;

@Component
export default class NavigationDrawerItem extends Vue {
  @Prop(Object) readonly item!: DrawerItem;

  @Prop(Boolean) readonly isSub!: boolean;

  get color() {
    return this.$vuetify.theme.dark ? 'dark' : 'white';
  }

  size(prop?: { [key: string]: string }) {
    return {
      ...prop,
      width: 20,
      height: 20,
    };
  }

  base(icon: string) {
    return `url(/img/navigation/${this.$vuetify.theme.dark ? 'blue_40' : 'white'}/${icon}.svg)`;
  }

  hover(icon: string) {
    return `url(/img/navigation/${this.$vuetify.theme.dark ? 'blue_40' : 'pink'}/${icon}.svg)`;
  }

  active(icon: string) {
    return `url(/img/navigation/${this.$vuetify.theme.dark ? 'blue_40' : 'pink'}/${icon}.svg)`;
  }

  get isEnabled() {
    if (this.item.disabled) return false;
    return this.item.actions
      ?.map((action) => auth.userActions[action])
      .some((value) => value === true);
  }

  beforeCreate() {
    auth = getModule(authModule, store);
  }

  hideElement(e: MouseEvent) {
    const target = e.currentTarget as HTMLElement;
    target.style.display = 'none';
    setTimeout(() => {
      target.style.display = 'block';
    }, 100);
  }
}
</script>

<style lang="scss" >
.menu-item {

  position: relative;
  width: 100%;

  &:hover {
    .menu-item-sub-menu {
      opacity: 1;
      visibility: visible;
      transition: opacity .1s ease-in-out, visibility .1s ease-in-out;
    }
  }

  .v-list-item {
    min-height: 56px;
    height: 56px;
    padding: 12px;
    border-radius: 0 !important;
    transition: background-color .3s;
    background-color: transparent;

    &__icon {
      width: 32px;
      min-width: 32px;
      height: 32px;
      margin: 0;
    }

    &__icon:first-child {
      margin-right: 0;
    }

    &::before {
      border-radius: 0 !important;
    }

    &:not(:last-child):not(:only-child) {
      // display: none;
      margin-bottom: 0;
    }

    .text,
    .icon {
      color: var(--v-textTertiary-base);
      transition: color .3s;
    }

    &:hover {

      background-color: var(--v-bgNavSecondaryHover-base);

      .text,
      .icon {
        color: var(--v-textNavSecondaryPressed-base);
      }
    }

    &--active {

      background-color: var(--v-bgNavSecondaryPressed-base);

      .text {
        font-weight: 500;
      }

      .text,
      .icon {
        color: var(--v-textNavSecondaryPressed-base);
      }

      &:after {
        content: "";
        background-color: var(--v-bgPrimary-base);
        position: absolute;
        width: 3px;
        height: 30px;
        left: 0px
      }

      // &:hover {

      // }
    }

  }

  &-sub-menu {
    background-color: transparent !important;
    position: absolute;
    right: 0;
    left: 100%;
    min-width: 260px;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
    padding-left: 5px;

    &.top {
      top: 0;
    }

    &.bottom {
      bottom: 0;
    }
  }
}

.v-navigation-drawer--mini-variant {

  .v-list-item__title {
    visibility: hidden !important;
    display: none;
  }

  .menu-item {
    &:hover {
      .menu-item-sub-menu {
        .v-list-item__title {
          visibility: visible !important;
          display: block;
        }
      }
    }
  }
}
</style>
