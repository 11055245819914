/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type UpstreamOfFieldDto = {
    type?: UpstreamOfFieldDto.type;
};

export namespace UpstreamOfFieldDto {

    export enum type {
        FIELD = 'FIELD',
        FUNCTION = 'FUNCTION',
    }


}
