<template lang="pug">
v-form( ref='form' v-model='isValid' )

  component( :is="component" v-model="datasource" )

  v-row
    v-col( cols='12')
      TimezoneSelector( v-model="datasource.params.timezoneData" :key="datasource?.params?.timezoneData?.timezone" )

    v-col( cols="12")
      TagSelector( v-model.trim="datasource.tags" )

    v-col( cols="12")
      InputDescription( v-model.trim='datasource.description' )

</template>

<script lang="ts">
import {
  Vue, Component, VModel, Watch, Emit,
} from 'vue-property-decorator';
import { DatasourceDto } from '@/api';
import Athena from '@/components/datasource/connectors/Athena.vue';
import BigQuery from '@/components/datasource/connectors/BigQuery.vue';
import Snowflake from '@/components/datasource/connectors/Snowflake.vue';
import Redshift from '@/components/datasource/connectors/Redshift.vue';
import MySql from '@/components/datasource/connectors/MySql.vue';
import Oracle from '@/components/datasource/connectors/Oracle.vue';
import Postgres from '@/components/datasource/connectors/Postgres.vue';
import Hive from '@/components/datasource/connectors/Hive.vue';
import MsSql from '@/components/datasource/connectors/MsSql.vue';
import Looker from '@/components/datasource/connectors/Looker.vue';
import Tableau from '@/components/datasource/connectors/Tableau.vue';
import PowerBi from '@/components/datasource/connectors/PowerBi.vue';
import Quicksight from '@/components/datasource/connectors/Quicksight.vue';
import Airflow from '@/components/datasource/connectors/Airflow.vue';
import Dbt from '@/components/datasource/connectors/Dbt.vue';
import DbtCloud from '@/components/datasource/connectors/DbtCloud.vue';
import Databricks from '@/components/datasource/connectors/Databricks.vue';
import Fivetran from '@/components/datasource/connectors/Fivetran.vue';
import Synapse from '@/components/datasource/connectors/Synapse.vue';
import Microstrategy from '@/components/datasource/connectors/Microstrategy.vue';

@Component
export default class DatasourceForm extends Vue {
  @VModel({ type: Object }) datasource!: DatasourceDto;

  isValid = false;

  get component() {
    switch (this.datasource.type) {
      case 'athena':
        return Athena;
      case 'bigquery':
        return BigQuery;
      case 'snowflake':
        return Snowflake;
      case 'redshift':
        return Redshift;
      case 'mysql':
        return MySql;
      case 'postgres':
        return Postgres;
      case 'mssql':
        return MsSql;
      case 'looker':
        return Looker;
      case 'tableau':
        return Tableau;
      case 'powerbi':
        return PowerBi;
      case 'quicksight':
        return Quicksight;
      case 'airflow':
        return Airflow;
      case 'dbt':
        return Dbt;
      case 'dbtcloud':
        return DbtCloud;
      case 'databricks':
        return Databricks;
      case 'hive':
        return Hive;
      case 'oracle':
        return Oracle;
      case 'fivetran':
        return Fivetran;
      case 'synapse':
        return Synapse;
      case 'microstrategy':
        return Microstrategy;
      default:
        return null;
    }
  }

  @Watch('isValid', { immediate: true })
  emitValid(validity: boolean) {
    this.$emit('validate', validity);
  }
}
</script>
