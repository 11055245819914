import parser from 'cron-parser';
import cronValidate from 'cron-validate';
import { registerOptionPreset } from 'cron-validate/lib/option';

// Copied from default preset (https://github.com/Airfooox/cron-validate/blob/master/src/option.ts)
registerOptionPreset('sifflet', {
  presetId: 'sifflet',
  useSeconds: false,
  useYears: false,
  useAliases: false,
  useBlankDay: false,
  allowOnlyOneBlankDayField: false,
  mustHaveBlankDayField: false,
  useLastDayOfMonth: false,
  useLastDayOfWeek: false,
  useNearestWeekday: false,
  useNthWeekdayOfMonth: false,
  seconds: {
    minValue: 0,
    maxValue: 59,
  },
  minutes: {
    minValue: 0,
    maxValue: 59,
  },
  hours: {
    minValue: 0,
    maxValue: 23,
  },
  daysOfMonth: {
    minValue: 1, // Default is 0
    maxValue: 31,
  },
  months: {
    minValue: 1, // Default is 0
    maxValue: 12,
  },
  daysOfWeek: {
    minValue: 0,
    maxValue: 6, // Default is 7
  },
  years: {
    minValue: 1970,
    maxValue: 2099,
  },
});

const cronMap = {
  '@minutely': '* * * * *',
  '@hourly': '0 * * * *',
  '@daily': '0 0 * * *',
  '@midnight': '0 0 * * *',
  '@weekly': '0 0 * * 0',
  '@monthly': '0 0 1 * *',
  '@yearly': '0 0 1 1 *',
  '@annually': '0 0 1 1 *',
};

type CronMap = keyof typeof cronMap;

const transformCron = (v: CronMap) => {
  if (cronMap[v]) {
    return cronMap[v];
  }
  return v;
};

const validateMaxFrequency = (value: string | CronMap, minPeriodInSeconds: number, iterations = 100) => {
  let v = value;

  if (Object.keys(cronMap).includes(v)) {
    v = transformCron(v as CronMap);
  }

  const currentDate = new Date();
  const minPeriodInMilliseconds = minPeriodInSeconds * 1000;
  const parsed = parser.parseExpression(v, {
    currentDate,
    tz: 'UTC',
  });
  let currentDateInMilliseconds = parsed.next().getTime();

  for (let i = 0; i < iterations; i += 1) {
    try {
      const nextDate = parsed.next();
      const nextDateInMilliseconds = nextDate.getTime();
      const delta = nextDateInMilliseconds - currentDateInMilliseconds;
      const isValid = delta >= minPeriodInMilliseconds;
      currentDateInMilliseconds = nextDateInMilliseconds;
      if (!isValid) return false;
    } catch (error) {
      break;
    }
  }
  return true;
};

const validateCron = (v: string | CronMap, minPeriodInSeconds: number, iterations = 100) => {
  const isEmpty = (v === undefined || v === null || v === '');
  const isAlias = Object.keys(cronMap).includes(v?.trim());
  const trimmedInput = v?.trim() || '';

  if (!isEmpty && !isAlias) {
    try {
      parser.parseExpression(trimmedInput);
      if (cronValidate(trimmedInput, { preset: 'sifflet' }).isError()) return 'Invalid cron expression';
    } catch {
      return 'Invalid cron expression';
    }
  }

  if (!isEmpty || isAlias) {
    if (!validateMaxFrequency(trimmedInput, minPeriodInSeconds, iterations)) {
      return 'This frequency is not supported in your current plan. Please contact support for more information.';
    }
  }

  return true;
};

export {
  validateCron,
  validateMaxFrequency,
};
