<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="hostRules"
    label='Jdbc URL'
    data-cy="jdbcUrl"
    dense clearable outlined required)
</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputJdbcUrl extends Vue {
    hostRules = [(v: string) => isConvertibleToNotBlankString(v) || 'Jdbc URL is required'];
}
</script>
