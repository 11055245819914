<template lang="pug">
v-card.suggestions-card.border-dashed(
  :has-check-icon="false"
  :hide-overflow="false"
)
  div.label.d-flex.align-start.mb-2
    v-tooltip(top v-if="tooltipLabel")
      template(v-slot:activator="{ on, attrs }")
        v-icon.label-icon.font-weight-medium.mr-1(v-on="on" v-bind="attrs" small) {{ icon }}
      span {{ tooltipLabel }}
    v-icon.font-weight-medium.label-icon.mr-1(v-else small) {{ icon }}
    p.mb-0(v-if="label") {{ label }}
    slot
  div.d-flex.align-center.justify-end.mt-4
    slot(name="extra-actions")
    v-tooltip(top :disabled="!disableDismiss")
      template(v-slot:activator="{ on, attrs }")
        div(v-on="on" v-bind="attrs")
          v-btn(
            @click="dismissHandler"
            color="secondary"
            class="custom-secondary"
            :disabled="disableDismiss"
            text outlined
            small
          )
            span {{ $t('common.words.dismiss') }}
      span {{ dismissButtonTooltip }}
    v-tooltip(top :disabled="!disableAdd")
      template(v-slot:activator="{ on, attrs }")
        div(v-on="on" v-bind="attrs")
          v-btn.ml-2(
            @click="addHandler"
            color="primary"
            class="custom-primary"
            :disabled="disableAdd"
            text outlined
            small
          )
            span {{ $t('common.words.add') }}
      span {{ addButtonTooltip }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SuggestionsCard extends Vue {
  @Prop({ type: String, default: '' }) readonly icon!: string;

  @Prop({ type: String, default: '' }) readonly label!: string;

  @Prop({ type: Function, required: true }) readonly dismissHandler!: () => void;

  @Prop({ type: Function, required: true }) readonly addHandler!: () => void;

  @Prop({ type: String, default: '' }) readonly tooltipLabel!: string;

  @Prop({ type: Boolean, default: false }) readonly disableDismiss!: boolean;

  @Prop({ type: Boolean, default: false }) readonly disableAdd!: boolean;

  @Prop({ type: String, default: '' }) readonly dismissButtonTooltip!: string;

  @Prop({ type: String, default: '' }) readonly addButtonTooltip!: string;
}
</script>

<style lang="scss" scoped>
.suggestions-card {
  padding: 12px;
  border: 1px dashed;
  border-color: map-get($grey, 'lighten-2');

  .label {
    color: map-get($grey, 'base');
    font-size: 12px;

    &-icon {
      color: map-get($purple, 'base');
    }
  }
}
</style>
