import { MAIN_ROUTES, SUB_ROUTES } from '@/router/components';
import domains from '@/router/domains';
import { BackgroundColors } from '@/@types/types';
import { UserUiDto } from '@/api';

export default {
  path: '/settings',
  name: 'settings',
  redirect: { name: 'settings.tags' },
  meta: {
    requiresAuth: true,
    breadCrumb: 'Settings',
  },
  component: MAIN_ROUTES.SETTINGS,
  children: [
    {
      path: 'tags',
      name: 'settings.tags',
      meta: {
        requiresAuth: true,
        breadCrumb: 'Tags',
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.SETTINGS_TAGS,
    },
    domains,
    {
      path: 'users',
      name: 'settings.users',
      meta: {
        requiresAuth: true,
        roles: [UserUiDto.role.ADMIN],
        breadCrumb: 'Users',
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.SETTINGS_USERS,

    }, {
      path: 'access-tokens',
      name: 'settings.access-tokens',
      meta: {
        requiresAuth: true,
        roles: [UserUiDto.role.ADMIN],
        breadCrumb: 'Access Tokens',
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.SETTINGS_TOKENS,
    }, {
      path: 'sso',
      name: 'settings.sso',
      meta: {
        requiresAuth: true,
        roles: [UserUiDto.role.ADMIN],
        breadCrumb: 'SSO',
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.SETTINGS_SSO,
    },
    {
      path: 'alerts-destinations',
      name: 'settings.alerts-destinations',
      meta: {
        requiresAuth: true,
        roles: [UserUiDto.role.ADMIN],
        breadCrumb: 'Alert Destinations',
        background: BackgroundColors.PRIMARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.SETTINGS_TOOLS,
    },
  ],
};
