<template lang="pug">
v-select.rule-template-selector(
  v-bind='$attrs'
  v-on='$listeners'
  :label="$t('monitors.edit.select_monitor_template')"
  data-cy="rule-template-selector"
  item-value="name"
  item-text="label"
  dense outlined)

  template(v-slot:item="{ item }")
    .d-flex.pb-2
      v-icon( color="iconInfo" v-if="item.inputCardinality === 'MULTI'" ).mr-2 drag_indicator
      .d-flex.flex-column
        .font-weight-medium {{ item.label }}
        .font-weight-light {{ item.description }}

</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class RuleTemplateSelector extends Vue { }
</script>
