import { MonitorSchema } from '../../monitor-schema';
import {
  AnomalyConditionsEnum,
  ThresholdSettings,
  LegacyThresholdSettings,
  ThresholdSettingsEnum,
  LegacyDynamicThresholdSettings,
  LegacyFreshnessDynamicAndStaticThresholdSettings,
  LegacyStaticAndRelativeThresholdSettings,
  LegacyStaticThresholdSettings,
  LegacyFreshnessTimeGapThresholdSettings,
  LegacyDynamicStaticAndExactThresholdSettings,
  LegacyStaticDynamicThresholdSettings,
  LegacyStaticDuplicateThresholdSettings,
} from './types';

function minOperatorFromLegacy(val: '<=' | '<' | null): '<=' | '<' | null {
  // transform <= to >= and < to >
  if (!val) return null;
  // ≤ BECOMES < and < BECOMES <=
  return val === '<=' ? '<' : '<=';
}

function minOperatorToLegacy(val: '<=' | '<' | null): '<=' | '<' | null {
  // transform >= to <= and > to <
  if (!val) return null;
  // < BECOMES ≤ and <= BECOMES <
  return val === '<' ? '<=' : '<';
}

function maxOperatorFromLegacy(val: '<=' | '<' | null): '>=' | '>' | null {
  // transform <= to >= and < to >
  if (!val) return null;
  // ≤ BECOMES > and < BECOMES >=
  return val === '<=' ? '>' : '>=';
}

function maxOperatorToLegacy(val: '>=' | '>' | null): '<=' | '<' | null {
  // transform >= to <= and > to <
  if (!val) return null;
  // > BECOMES <= and >= BECOMES <
  return val === '>' ? '<=' : '<';
}

export const convertDynamicThresholdFromLegacy = (legacyParams: LegacyDynamicThresholdSettings): ThresholdSettings | null => {
  if (!legacyParams.ignoreOneBound) {
    return {
      thresholdMode: ThresholdSettingsEnum.DYNAMIC,
      sensitivity: legacyParams.sensitivity,
      anomalyConditions: {
        type: AnomalyConditionsEnum.UPPER_AND_LOWER_BOUND,
        singleOp: null,
        singleValue: null,
        compoundMinValue: null,
        compoundMaxValue: null,
        compoundMinOp: null,
        compoundMaxOp: null,
      },
    };
  }
  if (legacyParams.boundChoice === 'prediction is greater than the expected upper bound') {
    return {
      thresholdMode: ThresholdSettingsEnum.DYNAMIC,
      sensitivity: legacyParams.sensitivity,
      anomalyConditions: {
        type: AnomalyConditionsEnum.UPPER_BOUND,
        singleOp: null,
        singleValue: null,
        compoundMinValue: null,
        compoundMaxValue: null,
        compoundMinOp: null,
        compoundMaxOp: null,

      },
    };
  }
  return {
    thresholdMode: ThresholdSettingsEnum.DYNAMIC,
    sensitivity: legacyParams.sensitivity,
    anomalyConditions: {
      type: AnomalyConditionsEnum.LOWER_BOUND,
      singleOp: null,
      singleValue: null,
      compoundMinValue: null,
      compoundMaxValue: null,
      compoundMinOp: null,
      compoundMaxOp: null,

    },
  };
};

export const convertFreshnessDynamicAndStaticThresholdFromLegacy = (legacyParams: LegacyFreshnessDynamicAndStaticThresholdSettings): ThresholdSettings | null => {
  if (legacyParams.comparisonType === 'dynamic' && legacyParams.sensitivity !== undefined) {
    return {
      thresholdMode: ThresholdSettingsEnum.DYNAMIC,
      sensitivity: legacyParams.sensitivity,
      anomalyConditions: {
        type: null,
        singleOp: null,
        singleValue: null,
        compoundMinValue: null,
        compoundMaxValue: null,
        compoundMinOp: null,
        compoundMaxOp: null,
      },
    };
  }
  return {
    thresholdMode: ThresholdSettingsEnum.STATIC,
    sensitivity: 0,
    anomalyConditions: {
      type: null,
      singleOp: null,
      singleValue: null,
      compoundMinValue: null,
      compoundMaxValue: null,
      compoundMinOp: null,
      compoundMaxOp: null,
    },
  };
};

export const convertDynamicThresholdToLegacy = (thresholdParams: ThresholdSettings): LegacyDynamicThresholdSettings | null => {
  if (thresholdParams.anomalyConditions?.type === AnomalyConditionsEnum.UPPER_AND_LOWER_BOUND) {
    return {
      sensitivity: thresholdParams.sensitivity,
      ignoreOneBound: false,
      boundChoice: null,
    };
  }
  if (thresholdParams.anomalyConditions?.type === AnomalyConditionsEnum.UPPER_BOUND) {
    return {
      sensitivity: thresholdParams.sensitivity,
      ignoreOneBound: true,
      boundChoice: 'prediction is greater than the expected upper bound',
    };
  }
  if (thresholdParams.anomalyConditions?.type === AnomalyConditionsEnum.LOWER_BOUND) {
    return {
      sensitivity: thresholdParams.sensitivity,
      ignoreOneBound: true,
      boundChoice: 'prediction is less than the expected lower bound',
    };
  }
  return null;
};

export const convertStaticThresholdFromLegacy = (legacyParams: LegacyStaticThresholdSettings): ThresholdSettings | null => {
  if (legacyParams.schemaKey === 'minAndMax') {
    return {
      thresholdMode: ThresholdSettingsEnum.STATIC,
      sensitivity: null,
      anomalyConditions: {
        type: AnomalyConditionsEnum.COMPOUND_THRESHOLD,
        singleOp: null,
        singleValue: null,
        compoundMinValue: legacyParams.min,
        compoundMaxValue: legacyParams.max,
        compoundMinOp: minOperatorFromLegacy(legacyParams.minOp),
        compoundMaxOp: maxOperatorFromLegacy(legacyParams.maxOp),
      },
    };
  }
  if (legacyParams.schemaKey === 'onlyMin') {
    return {
      thresholdMode: ThresholdSettingsEnum.STATIC,
      sensitivity: null,
      anomalyConditions: {
        type: AnomalyConditionsEnum.SINGLE_THRESHOLD,
        singleOp: minOperatorFromLegacy(legacyParams.minOp),
        singleValue: legacyParams.min,
        compoundMinValue: null,
        compoundMaxValue: null,
        compoundMinOp: null,
        compoundMaxOp: null,
      },
    };
  }
  if (legacyParams.schemaKey === 'onlyMax') {
    return {
      thresholdMode: ThresholdSettingsEnum.STATIC,
      sensitivity: null,
      anomalyConditions: {
        type: AnomalyConditionsEnum.SINGLE_THRESHOLD,
        singleOp: maxOperatorFromLegacy(legacyParams.maxOp),
        singleValue: legacyParams.max,
        compoundMinValue: null,
        compoundMaxValue: null,
        compoundMinOp: null,
        compoundMaxOp: null,
      },
    };
  }
  return null;
};

export const convertStaticDuplicateThresholdFromLegacy = (legacyParams: LegacyStaticDuplicateThresholdSettings): ThresholdSettings | null => ({
  thresholdMode: ThresholdSettingsEnum.STATIC,
  sensitivity: null,
  anomalyConditions: {
    type: AnomalyConditionsEnum.SINGLE_VALUE,
    singleOp: null,
    singleValue: legacyParams.threshold,
    compoundMinValue: null,
    compoundMaxValue: null,
    compoundMinOp: null,
    compoundMaxOp: null,
  },
});

export const convertStaticAndRelativeThresholdFromLegacy = (legacyParams: LegacyStaticAndRelativeThresholdSettings): ThresholdSettings | null => {
  if (legacyParams.comparisonMode === 'ABSOLUTE' || !legacyParams.comparisonMode) {
    return convertStaticThresholdFromLegacy(legacyParams);
  }
  if (legacyParams.comparisonMode === 'DIFFERENCE') {
    if (legacyParams.schemaKey === 'minAndMax') {
      return {
        thresholdMode: ThresholdSettingsEnum.RELATIVE,
        sensitivity: null,
        anomalyConditions: {
          type: AnomalyConditionsEnum.COMPOUND_VALUE_THRESHOLD_COMPARED_TO_LAST_RUN,
          singleOp: null,
          singleValue: null,
          compoundMinValue: legacyParams.min,
          compoundMaxValue: legacyParams.max,
          compoundMinOp: minOperatorFromLegacy(legacyParams.minOp),
          compoundMaxOp: maxOperatorFromLegacy(legacyParams.maxOp),
        },
      };
    }
    if (legacyParams.schemaKey === 'onlyMin') {
      return {
        thresholdMode: ThresholdSettingsEnum.RELATIVE,
        sensitivity: null,
        anomalyConditions: {
          type: AnomalyConditionsEnum.SINGLE_VALUE_THRESHOLD_COMPARED_TO_LAST_RUN,
          singleOp: minOperatorFromLegacy(legacyParams.minOp),
          singleValue: legacyParams.min,
          compoundMinValue: null,
          compoundMaxValue: null,
          compoundMinOp: null,
          compoundMaxOp: null,

        },
      };
    }
    if (legacyParams.schemaKey === 'onlyMax') {
      return {
        thresholdMode: ThresholdSettingsEnum.RELATIVE,
        sensitivity: null,
        anomalyConditions: {
          type: AnomalyConditionsEnum.SINGLE_VALUE_THRESHOLD_COMPARED_TO_LAST_RUN,
          singleOp: maxOperatorFromLegacy(legacyParams.maxOp),
          singleValue: legacyParams.max,
          compoundMinValue: null,
          compoundMaxValue: null,
          compoundMinOp: null,
          compoundMaxOp: null,

        },
      };
    }
  }
  if (legacyParams.comparisonMode === 'PERCENTAGE_DIFFERENCE') {
    if (legacyParams.schemaKey === 'minAndMax') {
      return {
        thresholdMode: ThresholdSettingsEnum.RELATIVE,
        sensitivity: null,
        anomalyConditions: {
          type: AnomalyConditionsEnum.COMPOUND_PERCENTAGE_THRESHOLD_COMPARED_TO_LAST_RUN,
          singleOp: null,
          singleValue: null,
          compoundMinValue: legacyParams.min,
          compoundMaxValue: legacyParams.max,
          compoundMinOp: minOperatorFromLegacy(legacyParams.minOp),
          compoundMaxOp: maxOperatorFromLegacy(legacyParams.maxOp),
        },
      };
    }
    if (legacyParams.schemaKey === 'onlyMin') {
      return {
        thresholdMode: ThresholdSettingsEnum.RELATIVE,
        sensitivity: null,
        anomalyConditions: {
          type: AnomalyConditionsEnum.SINGLE_PERCENTAGE_THRESHOLD_COMPARED_TO_LAST_RUN,
          singleOp: minOperatorFromLegacy(legacyParams.minOp),
          singleValue: legacyParams.min,
          compoundMinValue: null,
          compoundMaxValue: null,
          compoundMinOp: null,
          compoundMaxOp: null,
        },
      };
    }
    if (legacyParams.schemaKey === 'onlyMax') {
      return {
        thresholdMode: ThresholdSettingsEnum.RELATIVE,
        sensitivity: null,
        anomalyConditions: {
          type: AnomalyConditionsEnum.SINGLE_PERCENTAGE_THRESHOLD_COMPARED_TO_LAST_RUN,
          singleOp: maxOperatorFromLegacy(legacyParams.maxOp),
          singleValue: legacyParams.max,
          compoundMinValue: null,
          compoundMaxValue: null,
          compoundMinOp: null,
          compoundMaxOp: null,
        },
      };
    }
  }
  return null;
};

export const convertStaticThresholdToLegacy = (thresholdParams: ThresholdSettings): LegacyStaticAndRelativeThresholdSettings | null => {
  // comparison mode is ABSOLUTE (cannot be not defined)
  if (thresholdParams.thresholdMode === ThresholdSettingsEnum.STATIC) {
    if (thresholdParams.anomalyConditions.type === AnomalyConditionsEnum.COMPOUND_THRESHOLD) {
      return {
        comparisonMode: 'ABSOLUTE',
        schemaKey: 'minAndMax',
        minOp: minOperatorToLegacy(thresholdParams.anomalyConditions.compoundMinOp),
        min: thresholdParams.anomalyConditions.compoundMinValue,
        maxOp: maxOperatorToLegacy(thresholdParams.anomalyConditions.compoundMaxOp),
        max: thresholdParams.anomalyConditions.compoundMaxValue,
      };
    }
    if (thresholdParams.anomalyConditions.type === AnomalyConditionsEnum.SINGLE_THRESHOLD) {
      if (thresholdParams.anomalyConditions.singleOp === '<' || thresholdParams.anomalyConditions.singleOp === '<=') {
        return {
          comparisonMode: 'ABSOLUTE',
          schemaKey: 'onlyMin',
          minOp: minOperatorToLegacy(thresholdParams.anomalyConditions.singleOp),
          min: thresholdParams.anomalyConditions.singleValue,
          maxOp: null,
          max: null,
        };
      }
      return {
        comparisonMode: 'ABSOLUTE',
        schemaKey: 'onlyMax',
        minOp: null,
        min: null,
        maxOp: maxOperatorToLegacy(thresholdParams.anomalyConditions.singleOp),
        max: thresholdParams.anomalyConditions.singleValue,
      };
    }
  }
  // comparison mode is DIFFERENCE or PERCENTAGE_DIFFERENCE
  if (thresholdParams.thresholdMode === ThresholdSettingsEnum.RELATIVE) {
    if (thresholdParams.anomalyConditions.type === AnomalyConditionsEnum.COMPOUND_VALUE_THRESHOLD_COMPARED_TO_LAST_RUN) {
      return {
        comparisonMode: 'DIFFERENCE',
        schemaKey: 'minAndMax',
        minOp: minOperatorToLegacy(thresholdParams.anomalyConditions.compoundMinOp),
        min: thresholdParams.anomalyConditions.compoundMinValue,
        maxOp: maxOperatorToLegacy(thresholdParams.anomalyConditions.compoundMaxOp),
        max: thresholdParams.anomalyConditions.compoundMaxValue,
      };
    }
    if (thresholdParams.anomalyConditions.type === AnomalyConditionsEnum.COMPOUND_PERCENTAGE_THRESHOLD_COMPARED_TO_LAST_RUN) {
      return {
        comparisonMode: 'PERCENTAGE_DIFFERENCE',
        schemaKey: 'minAndMax',
        minOp: minOperatorToLegacy(thresholdParams.anomalyConditions.compoundMinOp),
        min: thresholdParams.anomalyConditions.compoundMinValue,
        maxOp: maxOperatorToLegacy(thresholdParams.anomalyConditions.compoundMaxOp),
        max: thresholdParams.anomalyConditions.compoundMaxValue,
      };
    }
    if (thresholdParams.anomalyConditions.type === AnomalyConditionsEnum.SINGLE_VALUE_THRESHOLD_COMPARED_TO_LAST_RUN) {
      if (thresholdParams.anomalyConditions.singleOp === '<' || thresholdParams.anomalyConditions.singleOp === '<=') {
        return {
          comparisonMode: 'DIFFERENCE',
          schemaKey: 'onlyMin',
          minOp: minOperatorToLegacy(thresholdParams.anomalyConditions.singleOp),
          min: thresholdParams.anomalyConditions.singleValue,
          maxOp: null,
          max: null,
        };
      }
      return {
        comparisonMode: 'DIFFERENCE',
        schemaKey: 'onlyMax',
        minOp: null,
        min: null,
        maxOp: maxOperatorToLegacy(thresholdParams.anomalyConditions.singleOp),
        max: thresholdParams.anomalyConditions.singleValue,
      };
    }
    if (thresholdParams.anomalyConditions.type === AnomalyConditionsEnum.SINGLE_PERCENTAGE_THRESHOLD_COMPARED_TO_LAST_RUN) {
      if (thresholdParams.anomalyConditions.singleOp === '<' || thresholdParams.anomalyConditions.singleOp === '<=') {
        return {
          comparisonMode: 'PERCENTAGE_DIFFERENCE',
          schemaKey: 'onlyMin',
          minOp: minOperatorToLegacy(thresholdParams.anomalyConditions.singleOp),
          min: thresholdParams.anomalyConditions.singleValue,
          maxOp: null,
          max: null,
        };
      }
      return {
        comparisonMode: 'PERCENTAGE_DIFFERENCE',
        schemaKey: 'onlyMax',
        minOp: null,
        min: null,
        maxOp: maxOperatorToLegacy(thresholdParams.anomalyConditions.singleOp),
        max: thresholdParams.anomalyConditions.singleValue,
      };
    }
  }
  return null;
};

export const convertExactThresholdFromLegacy = (legacyParams: LegacyThresholdSettings): ThresholdSettings | null => ({
  thresholdMode: ThresholdSettingsEnum.EXACT,
  sensitivity: null,
  anomalyConditions: {
    type: null,
    singleOp: null,
    singleValue: null,
    compoundMinValue: null,
    compoundMaxValue: null,
    compoundMinOp: null,
    compoundMaxOp: null,
  },
});

export const interlinkedThresholdFromLegacy = (legacyParams: LegacyDynamicStaticAndExactThresholdSettings): ThresholdSettings | null => {
  if (legacyParams.comparisonType === 'static') {
    if (legacyParams.schemaKey === 'minAndMax') {
      return {
        thresholdMode: ThresholdSettingsEnum.STATIC,
        sensitivity: null,
        anomalyConditions: {
          type: AnomalyConditionsEnum.COMPOUND_THRESHOLD,
          singleOp: null,
          singleValue: null,
          compoundMinValue: legacyParams.min,
          compoundMaxValue: legacyParams.max,
          compoundMinOp: minOperatorFromLegacy(legacyParams.minOp),
          compoundMaxOp: maxOperatorFromLegacy(legacyParams.maxOp),
        },
      };
    }
    if (legacyParams.schemaKey === 'onlyMin') {
      return {
        thresholdMode: ThresholdSettingsEnum.STATIC,
        sensitivity: null,
        anomalyConditions: {
          type: AnomalyConditionsEnum.SINGLE_THRESHOLD,
          singleOp: minOperatorFromLegacy(legacyParams.minOp),
          singleValue: legacyParams.min,
          compoundMinValue: null,
          compoundMaxValue: null,
          compoundMinOp: null,
          compoundMaxOp: null,
        },
      };
    }
    if (legacyParams.schemaKey === 'onlyMax') {
      return {
        thresholdMode: ThresholdSettingsEnum.STATIC,
        sensitivity: null,
        anomalyConditions: {
          type: AnomalyConditionsEnum.SINGLE_THRESHOLD,
          singleOp: maxOperatorFromLegacy(legacyParams.maxOp),
          singleValue: legacyParams.max,
          compoundMinValue: null,
          compoundMaxValue: null,
          compoundMinOp: null,
          compoundMaxOp: null,
        },
      };
    }
  }
  if (legacyParams.comparisonType === 'dynamic') {
    return {
      thresholdMode: ThresholdSettingsEnum.DYNAMIC,
      sensitivity: legacyParams.sensitivity,
      anomalyConditions: {
        type: null,
        singleOp: null,
        singleValue: null,
        compoundMinValue: null,
        compoundMaxValue: null,
        compoundMinOp: null,
        compoundMaxOp: null,
      },
    };
  }
  if (legacyParams.comparisonType === 'exact') {
    return {
      thresholdMode: ThresholdSettingsEnum.EXACT,
      sensitivity: null,
      anomalyConditions: {
        type: null,
        singleOp: null,
        singleValue: null,
        compoundMinValue: null,
        compoundMaxValue: null,
        compoundMinOp: null,
        compoundMaxOp: null,
      },
    };
  }
  return null;
};

export const interlinkedThresholdToLegacy = (thresholdParams: ThresholdSettings): LegacyDynamicStaticAndExactThresholdSettings | null => {
  if (thresholdParams.thresholdMode === ThresholdSettingsEnum.DYNAMIC) {
    return {
      comparisonType: 'dynamic',
      sensitivity: thresholdParams.sensitivity,
      maxOp: null,
      minOp: null,
      schemaKey: null,
      min: null,
      max: null,
    };
  }
  if (thresholdParams.thresholdMode === ThresholdSettingsEnum.STATIC) {
    return {
      comparisonType: 'static',
      sensitivity: null,
      maxOp: maxOperatorToLegacy(thresholdParams.anomalyConditions.compoundMaxOp),
      minOp: minOperatorToLegacy(thresholdParams.anomalyConditions.compoundMinOp),
      schemaKey: 'minAndMax',
      min: thresholdParams.anomalyConditions.compoundMinValue,
      max: thresholdParams.anomalyConditions.compoundMaxValue,
    };
  }
  if (thresholdParams.thresholdMode === ThresholdSettingsEnum.EXACT) {
    return {
      comparisonType: 'exact',
      sensitivity: null,
      maxOp: null,
      minOp: null,
      schemaKey: null,
      min: null,
      max: null,
    };
  }
  return null;
};

const defaultDynamicThreshold = () => ({
  sensitivity: 25,
  anomalyConditions: {
    type: AnomalyConditionsEnum.UPPER_AND_LOWER_BOUND,
    singleOp: null,
    singleValue: null,
    compoundMinValue: null,
    compoundMaxValue: null,
    compoundMinOp: null,
    compoundMaxOp: null,
  },
});

const setDefaultStaticThreshold = () => ({
  sensitivity: null,
  anomalyConditions: {
    type: AnomalyConditionsEnum.SINGLE_THRESHOLD,
    singleOp: null,
    singleValue: null,
    compoundMinValue: 0,
    compoundMaxValue: 0,
    compoundMinOp: null,
    compoundMaxOp: null,
  },
});

const setDefaultFreshnessDynamicAndStaticThreshold = ($thresholdMode: ThresholdSettings['thresholdMode']) => {
  if ($thresholdMode === ThresholdSettingsEnum.DYNAMIC) {
    return {
      sensitivity: 25,
      anomalyConditions: {
        type: null,
        singleOp: null,
        singleValue: null,
        compoundMinValue: null,
        compoundMaxValue: null,
        compoundMinOp: null,
        compoundMaxOp: null,
      },
    };
  }
  return {
    sensitivity: 0,
    anomalyConditions: {
      type: null,
      singleOp: null,
      singleValue: null,
      compoundMinValue: null,
      compoundMaxValue: null,
      compoundMinOp: null,
      compoundMaxOp: null,
    },
  };
};

const setDefaultStaticDuplicateThreshold = () => ({
  sensitivity: 0,
  anomalyConditions: {
    type: AnomalyConditionsEnum.SINGLE_VALUE,
    singleOp: null,
    singleValue: 0,
    compoundMinValue: null,
    compoundMaxValue: null,
    compoundMinOp: null,
    compoundMaxOp: null,
  },
});

const setDefaultStaticAndRelativeThreshold = ($thresholdMode: ThresholdSettings['thresholdMode']) => {
  if ($thresholdMode === ThresholdSettingsEnum.RELATIVE) {
    return {
      sensitivity: null,
      anomalyConditions: {
        type: AnomalyConditionsEnum.SINGLE_VALUE_THRESHOLD_COMPARED_TO_LAST_RUN,
        singleOp: null,
        singleValue: null,
        compoundMinValue: 0,
        compoundMaxValue: 0,
        compoundMinOp: null,
        compoundMaxOp: null,
      },
    };
  }
  return {
    sensitivity: null,
    anomalyConditions: {
      type: AnomalyConditionsEnum.SINGLE_THRESHOLD,
      singleOp: null,
      singleValue: null,
      compoundMinValue: 0,
      compoundMaxValue: 0,
      compoundMinOp: null,
      compoundMaxOp: null,
    },
  };
};

export function getDefaultValues($thresholdMode: ThresholdSettings['thresholdMode'], ruleTemplateName: MonitorSchema['ruleTemplateName']): Omit<ThresholdSettings, 'thresholdMode'> | null {
  if (['SiffletCompletenessRule', 'SiffletDuplicatesRule', 'SiffletCountDuplicateRule', 'SiffletPercentageNullRowsRule', 'SiffletPercentageDuplicatesRule', 'SiffletCountNullRowsRule', 'SiffletNumericalTransformationRule', 'SiffletCustomMetricsRule'].includes(ruleTemplateName)) {
    return defaultDynamicThreshold();
  }
  if (['SiffletCompletenessStaticRule'].includes(ruleTemplateName)) {
    return setDefaultStaticAndRelativeThreshold($thresholdMode);
  }
  if (['SiffletAverageRule', 'SiffletRangeRule', 'SiffletVarianceRule', 'SiffletCountRule', 'SiffletStandardDeviationRule', 'SiffletQuantileRule', 'SiffletSumRule'].includes(ruleTemplateName)) {
    return setDefaultStaticThreshold();
  }
  if (['SiffletFreshnessRule'].includes(ruleTemplateName)) {
    return setDefaultFreshnessDynamicAndStaticThreshold($thresholdMode);
  }
  if (['SiffletNullRule', 'SiffletStaticCountDuplicateRule', 'SiffletPercentageNullStaticRule', 'SiffletDuplicateRule'].includes(ruleTemplateName)) {
    return setDefaultStaticDuplicateThreshold();
  }
  if (ruleTemplateName === 'SiffletMetadataFreshnessRule') {
    return defaultDynamicThreshold();
  }
  if (ruleTemplateName === 'SiffletInterlinkedMetricsRule') {
    return setDefaultFreshnessDynamicAndStaticThreshold($thresholdMode);
  }
  return null;
}

export default function convertThresholdFromLegacy(legacyParams: LegacyThresholdSettings, ruleTemplateName: MonitorSchema['ruleTemplateName']): ThresholdSettings | null {
  if (['SiffletCompletenessRule', 'SiffletDuplicatesRule', 'SiffletCountDuplicateRule', 'SiffletPercentageNullRowsRule', 'SiffletPercentageDuplicatesRule', 'SiffletCountNullRowsRule', 'SiffletNumericalTransformationRule', 'SiffletCustomMetricsRule'].includes(ruleTemplateName)) {
    return convertDynamicThresholdFromLegacy(legacyParams as LegacyDynamicThresholdSettings);
  }
  if (['SiffletCompletenessStaticRule'].includes(ruleTemplateName)) {
    return convertStaticAndRelativeThresholdFromLegacy(legacyParams as LegacyStaticAndRelativeThresholdSettings);
  }
  if (['SiffletAverageRule', 'SiffletRangeRule', 'SiffletVarianceRule', 'SiffletCountRule', 'SiffletStandardDeviationRule', 'SiffletQuantileRule', 'SiffletSumRule'].includes(ruleTemplateName)) {
    return convertStaticThresholdFromLegacy(legacyParams as LegacyStaticAndRelativeThresholdSettings);
  }
  if (['SiffletFreshnessRule'].includes(ruleTemplateName)) {
    return convertFreshnessDynamicAndStaticThresholdFromLegacy(legacyParams as LegacyFreshnessDynamicAndStaticThresholdSettings);
  }
  if (['SiffletNullRule', 'SiffletStaticCountDuplicateRule', 'SiffletPercentageNullStaticRule', 'SiffletDuplicateRule'].includes(ruleTemplateName)) {
    return convertStaticDuplicateThresholdFromLegacy(legacyParams as LegacyStaticDuplicateThresholdSettings);
  }
  if (ruleTemplateName === 'SiffletMetadataFreshnessRule') {
    return convertDynamicThresholdFromLegacy(legacyParams as LegacyDynamicThresholdSettings);
  }
  if (ruleTemplateName === 'SiffletInterlinkedMetricsRule') {
    return interlinkedThresholdFromLegacy(legacyParams as LegacyDynamicStaticAndExactThresholdSettings);
  }
  return null;
}
