/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SimpleDuration = {
    unit?: SimpleDuration.unit;
    value?: number;
};

export namespace SimpleDuration {

    export enum unit {
        SECONDS = 'SECONDS',
        MINUTES = 'MINUTES',
        HOURS = 'HOURS',
        DAYS = 'DAYS',
        WEEKS = 'WEEKS',
        MONTHS = 'MONTHS',
        YEARS = 'YEARS',
    }


}
