/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DomainUiLightDto } from './DomainUiLightDto';
import type { TimeZoneDto } from './TimeZoneDto';

export type UserUiDto = {
    createdBy?: string;
    domains: Array<DomainUiLightDto>;
    email: string;
    id: string;
    isActive: boolean;
    lastLoginDate?: string;
    name: string;
    resetPassword: boolean;
    role: UserUiDto.role;
    timeZoneData: TimeZoneDto;
};

export namespace UserUiDto {

    export enum role {
        ADMIN = 'ADMIN',
        SYSTEM_EDITOR = 'SYSTEM_EDITOR',
        SYSTEM_VIEWER = 'SYSTEM_VIEWER',
    }


}
