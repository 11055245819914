<template lang="pug">
  v-card.py-5.px-6( fill-height outlined style="height: 100%;" )
    h5.h5.font-weight-medium.text-h6 {{ $t('dashboard.tabs.quality_rules') }}
    ChartMonitors
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';

const auth = getModule(authModule, store);

@Component
export default class DashboardDataQualityRules extends Vue {
  mounted() {
  }
}
</script>
