<template lang="pug">
v-app
  v-notification
  router-view
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class RootLayout extends Vue {}

</script>
