import {
  Action, Module, Mutation, VuexModule, config,
} from 'vuex-module-decorators';
import Lineage from '@/components/lineage/lib/lineage/Lineage';
import eventBus, { ShortcutsEventType } from '@/utils/eventBus';
import { Node } from '@/components/lineage/lib/lineage';

config.rawError = true;

let lineageInstance: Lineage;

export type Filters = { fields: string[] };

export type CanvasState = {
  canUndo: boolean;
  canRedo: boolean;
  onlyFieldsWithLinks: boolean;
  allFieldsAreExpanded: boolean;
}

export type LineageState = {
  isInitialized: boolean;
  highlight: boolean;
  selectedNode: Node | null;
  filters: Filters;
}

@Module({ namespaced: true, name: 'lineage' })
export default class lineage extends VuexModule {
  canvasState: CanvasState = {
    canUndo: false,
    canRedo: false,
    onlyFieldsWithLinks: false,
    allFieldsAreExpanded: false,
  };

  lineageState: LineageState = {
    isInitialized: false,
    highlight: true,
    selectedNode: null,
    filters: {
      fields: [],
    },
  };

  get getCavasState() {
    return this.canvasState;
  }

  get getLineageState() {
    return this.lineageState;
  }

  @Mutation
  setIsInitialized(isInitialized: boolean) {
    this.lineageState.isInitialized = isInitialized;
  }

  @Mutation
  setOnlyFieldsWithLinks(onlyFieldsWithLinks: boolean) {
    this.canvasState.onlyFieldsWithLinks = onlyFieldsWithLinks;
  }

  @Mutation
  setFieldsExpanded(allFieldsAreExpanded: boolean) {
    this.canvasState.allFieldsAreExpanded = allFieldsAreExpanded;
  }

  @Mutation
  setLineageSelectedNode(node: any) {
    this.lineageState.selectedNode = node;
  }

  @Mutation
  setFilters(fields: string[]) {
    this.lineageState.filters.fields = fields;
    lineageInstance.filter();
  }

  @Mutation
  toggleHighlight() {
    this.lineageState.highlight = !this.lineageState.highlight;
  }

  @Mutation
  setUndoState(state: boolean) {
    this.canvasState.canUndo = state;
  }

  @Mutation
  resetStore() {
    this.canvasState.canUndo = false;
    this.canvasState.allFieldsAreExpanded = false;
    this.lineageState.isInitialized = false;
    this.lineageState.selectedNode = null;
  }

  @Action
  refresh() {
    lineageInstance.refresh();
  }

  @Action
  undo() {
    lineageInstance.undo();
  }

  @Action
  redo() {
    lineageInstance.redo();
  }

  @Action
  toggleExpandFields() {
    lineageInstance.toggleExpandFields();
  }

  @Action
  center() {
    lineageInstance.center();
  }

  @Action
  exportToPng() {
    lineageInstance.exportToPng();
  }

  @Action
  exportToCsv() {
    lineageInstance.exportToCsv();
  }

  @Action
  resetAllSelectedNodeCssClasses() {
    lineageInstance.resetAllSelectedNodeCssClasses();
  }

  @Action
  async createLineage({ el, urn }: { el: HTMLElement; urn: string }) {
    lineageInstance = new Lineage({ el, urn });
    await lineageInstance.init();

    eventBus.$on(ShortcutsEventType.KEYDOWN_SHIFT, () => {
      lineageInstance.shouldSpreadAllStreams(true);
    });

    eventBus.$on(ShortcutsEventType.KEYUP_SHIFT, () => {
      lineageInstance.shouldSpreadAllStreams(false);
    });
  }

  @Action
  async destroyLineage() {
    eventBus.$off(ShortcutsEventType.KEYDOWN_SHIFT);
    eventBus.$off(ShortcutsEventType.KEYUP_SHIFT);
    this.resetStore();
  }
}
