<template lang="pug">
v-chip( v-if="showStatus" small outlined :color="chip.color" )
  v-icon.mr-1( size="18" :color="chip.iconColor" ) {{ chip.icon }}
  b.text-truncate( :class="chip.textColor" ) {{ chip.text }}
</template>

<script lang="ts">
import { Vue, Component, VModel } from 'vue-property-decorator';
import { AssetHeaderDto } from '@/api';

@Component
export default class QualityStatus extends Vue {
  @VModel({ type: String }) qualityStatus!: AssetHeaderDto.qualityStatus;

  get showStatus() {
    return this.qualityStatus !== AssetHeaderDto.qualityStatus.NOT_SUPPORTED;
  }

  get chip() {
    switch (this.qualityStatus) {
      case AssetHeaderDto.qualityStatus.CRITICAL:
        return {
          text: this.$t('qualityStatus.critical'),
          color: 'iconDanger',
          textColor: 'textDanger--text',
          icon: 'icon-error-circle-fill',
          iconColor: 'iconDanger',
        };
      case AssetHeaderDto.qualityStatus.AT_RISK:
        return {
          text: this.$t('qualityStatus.at_risk'),
          color: 'iconWarning',
          textColor: 'textWarning--text',
          icon: 'icon-warning-fill',
          iconColor: 'iconWarning',
        };
      case AssetHeaderDto.qualityStatus.HEALTHY:
        return {
          text: this.$t('qualityStatus.healthy'),
          color: 'iconSuccess',
          textColor: 'textSuccess--text',
          icon: 'icon-check-circle-fill',
          iconColor: 'iconSuccess',
        };
      case AssetHeaderDto.qualityStatus.UNMONITORED:
        return {
          text: this.$t('qualityStatus.unmonitored'),
          color: 'borderPrimary',
          textColor: 'textSecondary--text',
          icon: 'icon-circle-slash',
          iconColor: 'iconNeutral',
        };
      case AssetHeaderDto.qualityStatus.NOT_SUPPORTED:
        return {
          text: this.$t('qualityStatus.not_supported'),
          color: 'borderPrimary',
          textColor: 'textSecondary--text',
          icon: 'icon-circle-slash',
          iconColor: 'iconNeutral',
        };
      default:
        return {
          text: this.$t('qualityStatus.not_supported'),
          color: 'borderPrimary',
          textColor: 'textSecondary--text',
          icon: 'icon-circle-slash',
          iconColor: 'iconNeutral',
        };
    }
  }
}
</script>
