/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeCalendarReferenceDto } from './AsCodeCalendarReferenceDto';

export type AsCodeDynamicFreshnessThresholdDto = {
    excludedDates?: Array<AsCodeCalendarReferenceDto>;
    kind: AsCodeDynamicFreshnessThresholdDto.kind;
    sensitivity?: number;
};

export namespace AsCodeDynamicFreshnessThresholdDto {

    export enum kind {
        DYNAMIC = 'Dynamic',
        STATIC = 'Static',
    }


}
