/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SlackConnectDto = {
    status: SlackConnectDto.status;
    workspaceName: string;
};

export namespace SlackConnectDto {

    export enum status {
        SUCCESS = 'SUCCESS',
        FAILURE = 'FAILURE',
    }


}
