export const BETA_SOURCES = [
  'athena',
  'hive',
  'oracle',
  'airflow',
  'fivetran',
  'synapse',
  'microstrategy',
];

export const isSourceBeta = (sourceName: string) => BETA_SOURCES.includes(sourceName.toLowerCase());
