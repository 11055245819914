<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="rules"
    label='AWS Region'
    data-cy="awsRegion"
    dense clearable outlined required)

</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputAwsRegion extends Vue {
  rules = [
    (v: string) => isConvertibleToNotBlankString(v) || 'AWS Region is required',
    (v: string) => (v && /(af|ap|ca|cn|eu|me|sa|us(-gov)?)-(central|north|(north(?:east|west))|south|south(?:east|west)|east|west)-\d+/.test(v)) || 'Must be a valid AWS Region',
  ];
}
</script>
