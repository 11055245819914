import { AssetService, BaseSearchFilterDto } from '@/api';
import { queryToArray, queryToBoolean, queryToNumber } from '@/utils/query';

export const INITIAL_SEARCH_PARAMS = {
  assetFilterType: [],
  datasource: [],
  healthStatus: [],
  usage: [],
  tag: [],
  term: [],
  owner: [],
  ingestion: [],
  searchInFieldsAndDescriptions: false,
  textSearch: '',
  itemsPerPage: 50,
  page: 1,
  sort: [
    'relevance,ASC',
  ],
  domain: 'All',
};

export const EMPTY_FILTER = {
  type: BaseSearchFilterDto.type.ASSET_TYPE,
  query: 'asset_type',
  children: [],
};

export const EMPTY_TERM_FILTER = {
  type: BaseSearchFilterDto.type.TERM,
  query: 'term',
  children: [],
};

export const EMPTY_TAG_FILTER = {
  type: BaseSearchFilterDto.type.TAG,
  query: 'tag',
  children: [],
};

export const EMPTY_OWNER_FILTER = {
  type: BaseSearchFilterDto.type.OWNER,
  query: 'owner',
  children: [],
};

export const EMPTY_ASSET_INGESTION_FILTER = {
  type: BaseSearchFilterDto.type.ASSET_INGESTION,
  query: 'asset_ingestion',
  children: [
    { results: 0, id: 'DECLARATIVE' },
    { results: 0, id: 'SIFFLET_SOURCED' },
  ],
};

export const SORT_CONFIGURATION = [
  { value: 'name', text: 'Name' },
  { value: 'relevance', text: 'Relevance' },
];

export const DEFAULT_OPTIONS = {
  itemsPerPage: 50,
  page: 1,
};

export const filtersValuesToQuery = (searchParams: Parameters<typeof AssetService.getAll>[0]) => {
  const {
    assetFilterType, datasource, healthStatus, usage, tag, term, owner, ingestion, textSearch, page, itemsPerPage, searchInFieldsAndDescriptions, sort,
  } = searchParams;

  return {
    assetFilterType: queryToArray(assetFilterType!),
    datasource: queryToArray(datasource!),
    healthStatus: queryToArray(healthStatus!) as ('CRITICAL' | 'AT_RISK' | 'HEALTHY' | 'UNMONITORED' | 'NOT_SUPPORTED')[],
    usage: queryToArray(usage!) as ('UNSUPPORTED' | 'LOW' | 'MEDIUM' | 'HIGH')[],
    tag: queryToArray(tag!),
    term: queryToArray(term!),
    owner: queryToArray(owner!),
    ingestion: queryToArray(ingestion!),
    textSearch: textSearch!,
    searchInFieldsAndDescriptions: queryToBoolean(searchInFieldsAndDescriptions!.toString()),
    sort: queryToArray(sort!),
    page: queryToNumber(page!.toString()),
    itemsPerPage: queryToNumber(itemsPerPage!.toString()),
  };
};
