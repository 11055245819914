<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="portRules"
    label='Port'
    data-cy="port"
    dense clearable outlined required)

</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputPort extends Vue {
  portRules = [
    (v: string) => isConvertibleToNotBlankString(v) || 'Port is required',
    (v: string) => (v && /^\d+$/.test(v)) || 'Must be a number',
  ];
}
</script>
