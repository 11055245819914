import Point from './Point';

export default class Port {
    readonly location: Point;

    readonly parentId: string;

    constructor(_:
        {
            location: Point,
            parentId: string
        }) {
      this.location = _.location;
      this.parentId = _.parentId;
    }
}
