<template lang="pug">
v-tooltip( top :disabled="allDomains.length === 0" )
  template( v-slot:activator='{ on }' )
    div.py-2.grey--text( v-on='on' class="domains-tooltip" )
      div.py-1(v-for="(dom, index) in firstDomains" :key="index") {{ dom.name }} - {{ titleCase(dom.role) }}
      div(v-if="allDomains.length > firstDomains.length") {{ $t('domains.dots') }}
  div(v-for="(domain, index) in allDomains" :key="index") {{ domain.name }} - {{ titleCase(domain.role) }}
</template>

<script lang="ts">
import { DomainUiLightDto } from '@/api';
import { Vue, Component, VModel } from 'vue-property-decorator';
import titleCase from '@/utils/filters/titleCase';

@Component
export default class DomainsTooltip extends Vue {
  @VModel({ type: Array }) readonly domains!: DomainUiLightDto[];

  titleCase = titleCase;

  get allDomains() {
    return this.domains;
  }

  get firstDomains() {
    return this.domains.slice(0, 3);
  }
}
</script>
