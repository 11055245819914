<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { getModule } from 'vuex-module-decorators';
import type { DataOptions } from 'vuetify';
import { computed, ref, watch } from 'vue';
import store from '@/store';
import type {
  AssetPatchDto,
  CancelablePromise,
  FieldDto,
} from '@/api';
import {
  FieldLevelSearchDto,
  AssetService,
} from '@/api';
import Essentials from '@/components/essentials/Essentials.vue';
import CatalogImport from '@/components/catalog-import/Catalog-Import.vue';
import EditDialog from '@/components/edit-dialog/Edit-Dialog.vue';
import authModule from '@/store/modules/auth';
import SiffletifyDataFooter
  from '@/components/siffletify/siffletify-data-footer/siffletify-data-footer.vue';
import i18n from '@/i18n';

interface CatalogResultsProps {
  assets: FieldLevelSearchDto[],
  assetsCount: number,
  hideActions?: boolean,
  shouldTargetBlank?: boolean,
  alreadySelectedAssets?: FieldLevelSearchDto[],
  isEditable?: boolean,
  isSelectable?: boolean,
  isLoading?: boolean,
  searchParams: Parameters<typeof AssetService.getAll>[0]
}

type CatalogResultsEmits = {
  (event: 'onFilterChange', option: Partial<DataOptions>): void
  (event: 'onSelectedChange', value: FieldLevelSearchDto[]): void
  (event: 'onRefreshAssets'): void
}

const auth = getModule(authModule, store);

const props = defineProps({
  assets: null,
  assetsCount: { default: 0 },
  hideActions: { type: Boolean, default: false },
  shouldTargetBlank: { type: Boolean, default: false },
  alreadySelectedAssets: { default: () => [] },
  isEditable: { type: Boolean, default: false },
  isSelectable: { type: Boolean, default: true },
  isLoading: { type: Boolean, default: false },
  searchParams: null
});

const emit = defineEmits(["onFilterChange", "onSelectedChange", "onRefreshAssets"]);

const editDialog = ref<InstanceType<typeof EditDialog> | null>(null);
const essentials = ref<Essentials | null>(null);
const catalogImport = ref<InstanceType<typeof CatalogImport> | null>(null);
let selectedAssets = _ref<FieldLevelSearchDto[]>(props.alreadySelectedAssets);

const notDatasetItems = (item: FieldLevelSearchDto): boolean => item.canDoAutoCoverage === false;

const filteredSelectedAssets = _computed(() => {
  if (props.isEditable) {
    return selectedAssets.value.filter((item: FieldLevelSearchDto) => item.entityType !== FieldLevelSearchDto.entityType.DECLARED_ASSET);
  }

  return selectedAssets.value;
});
const canEdit = computed(() => auth.userActions['metadata.asset.write']);
const hasNoResults = computed(() => props.assets.length === 0);
const options = computed(() => ({ itemsPerPage: props.searchParams.itemsPerPage, page: props.searchParams.page }));
const from = computed(() => ((options.value.page! - 1) * options.value.itemsPerPage!) + 1);
const to = computed(() => ((options.value.page! - 1) * options.value.itemsPerPage! + options.value.itemsPerPage! <= props.assetsCount ? (options.value.page! - 1) * options.value.itemsPerPage! + options.value.itemsPerPage! : props.assetsCount));
const resultsLabel = computed(() => `${from.value}-${to.value} ${i18n.tc('data-catalog.total_of', props.assetsCount)}`);
const selectedLabel = computed(() => `${filteredSelectedAssets.value.length} ${i18n.t('data-catalog.selected')}`);
const areAllChecked = computed(() => props.assets.every((asset: FieldLevelSearchDto) => filteredSelectedAssets.value.some((selectedAsset: FieldLevelSearchDto) => asset.id === selectedAsset.id)));
// if at least one asset is selected and not all of them are selected
const isIndeterminate = computed(() => props.assets.some((asset: FieldLevelSearchDto) => filteredSelectedAssets.value.some((selectedAsset: FieldLevelSearchDto) => asset.id === selectedAsset.id)) && !areAllChecked.value);
const hasSelectionFromOtherPages = computed(() => selectedAssets.value.some((selectedMonitor: FieldLevelSearchDto) => !props.assets.some((monitor: FieldLevelSearchDto) => monitor.id === selectedMonitor.id)));
const canDoCoverage = computed(() => selectedAssets.value.length >= 1 && selectedAssets.value.filter(notDatasetItems).length === 0 && canEdit.value);
const showEdit = computed(() => selectedAssets.value.length >= 1);
const computedAssets = computed(() => (props.isLoading ? [] : props.assets));
const canSelectAny = computed(() => {
  if (props.isEditable) {
    return props.assets.some((asset: FieldLevelSearchDto) => asset.entityType !== FieldLevelSearchDto.entityType.DECLARED_ASSET);
  }
  return props.isSelectable;
});

const clearSelection = () => {
  selectedAssets.value = [];
};

const updateOptions = (option: Partial<DataOptions>) => {
  emit('onFilterChange', { page: option.page, itemsPerPage: option.itemsPerPage });
};

const updateSelectedAssetsWithNewData = () => {
  selectedAssets.value = props.assets.filter((item: FieldLevelSearchDto) => selectedAssets.value.some((selectedItem: FieldLevelSearchDto) => item.id === selectedItem.id));
};

const handleSelect = () => {
  if (!areAllChecked.value || isIndeterminate.value) {
    if (hasSelectionFromOtherPages.value) {
      selectedAssets.value = selectedAssets.value.concat(props.assets);
    } else {
      selectedAssets.value = props.assets;
    }
    return;
  }

  if (areAllChecked.value) {
    selectedAssets.value = selectedAssets.value.filter((selectedMonitor: FieldLevelSearchDto) => !props.assets.some((monitor: FieldLevelSearchDto) => monitor.id === selectedMonitor.id));
  }
};

const isLast = (item: any) => props.assets.indexOf(item) >= options.value.itemsPerPage! - 1;

const coverage = (asset: FieldLevelSearchDto) => {
  essentials!.value!.open([asset]);
};

const editAsset = (asset: FieldLevelSearchDto) => {
  editDialog.value!.setEntities([asset]);
};

const editAssets = () => {
  updateSelectedAssetsWithNewData();
  editDialog.value!.setEntities(filteredSelectedAssets.value);
};

const essentialsHandler = () => {
  emit('onRefreshAssets');
  updateSelectedAssetsWithNewData();
  essentials.value!.open(selectedAssets.value);
};

const openCatalogImportDialog = () => {
  catalogImport.value!.openDialog();
};

const editDialogUpdateHandler = async ({ entities, patches }: { entities: string[], patches: { description?: string, tags?: string[] } | { description?: string, tags?: string[] }[] }) => {
  let promises: CancelablePromise<FieldDto>[];

  if (Array.isArray(entities) && Array.isArray(patches)) {
    promises = entities.map((urn, index) => {
      const entityPatch = patches[index];
      return AssetService.patchAsset({ urn, requestBody: entityPatch });
    });
  } else {
    const requestBody = patches as AssetPatchDto;
    promises = entities.map((urn) => AssetService.patchAsset({ urn, requestBody }));
  }

  await Promise.all(promises);
  await emit('onRefreshAssets');
  updateSelectedAssetsWithNewData();
};

watch(() => selectedAssets.value, () => {
  emit('onSelectedChange', selectedAssets.value);
});
</script>

<template lang="pug">
v-row.mt-1
  v-col( cols="12" )
    v-data-iterator(
      v-model="selectedAssets"
      return-object
      :items="computedAssets"
      :server-items-length="assetsCount"
      :options="options"
      :loading="isLoading"
      hide-default-footer
      data-cy="catalog-result-iterator")

      template(v-slot:loading)
        MonitorsResultsPlaceholder

      template(v-slot:no-data)
        NoDataAvailablePlaceholder(
          :text="`${$t('data-catalog.no_results')} “${searchParams.textSearch}”`"
          :secondary-text="`${$t('data-catalog.no_results_secondary')}`" )

      template(v-slot:header)
        v-row( no-gutters )
          v-col.mr-4( align-self="stretch" )
            .d-flex.align-center( v-if="!hasNoResults && canEdit" style="height: 100%;" )

              v-simple-checkbox.ml-2(
                v-if="isSelectable"
                :class="{ 'disabled': !canSelectAny }"
                color="primary"
                :disabled="!canSelectAny"
                data-cy="catalog-results-select-all"
                :value="areAllChecked"
                @input="handleSelect"
                :indeterminate="isIndeterminate" )
              .grey--text.font-weight-medium {{ resultsLabel }}

          v-col( cols="auto")
            .d-flex
              SButton.mr-3(
                v-if="showEdit"
                icon="icon-dismiss"
                :text="selectedLabel"
                @click="clearSelection"
                color="secondary"
                icon-alignment="right"
                variant="text")

              SButton(
                v-if="!hideActions && showEdit"
                icon="icon-edit"
                :text="$t('data-catalog.edit')"
                @click="editAssets"
                color="secondary"
                variant="outlined"
                :disabled="!canEdit"
                data-cy="catalog-results-edit")

              SButton.ml-3(
                v-if="!hideActions"
                icon="icon-magic-wand"
                :text="$t('data-catalog.auto_coverage')"
                :tooltip="$t('data-catalog.auto_coverage_tooltip')"
                @click="essentialsHandler"
                color="primary"
                :disabled="!canDoCoverage")

              v-divider.mx-3(vertical v-if="!hideActions")

              v-menu(
                v-if="!hideActions"
                :disabled="!canEdit"
                content-class="border-around"
                nudge-bottom="5" nudge-right="36" min-width="200"
                transition="slide-x-reverse-transition"
                offset-x offset-y bottom left )
                template(v-slot:activator='{ on, $attrs }')

                  v-btn(
                    :attrs="$attrs"
                    v-on="on"
                    :disabled="!canEdit"
                    class="action-button custom-secondary icon-only"
                    color="secondary" text outlined )
                    v-icon icon-menu-dots-vertical

                v-list( dense class="py-0" )
                  v-list-item-group
                    v-list-item( @click="openCatalogImportDialog" )
                      v-list-item-icon
                        v-icon( color="secondary" ) icon-arrow-import
                      v-list-item-title
                        span {{ $t('data-catalog.import') }}
                        v-chip( small outlined color="black" ).ml-2.black--text {{ $t('common.words.beta') }}

        v-row( no-gutters )
          v-col
            v-divider.mt-3

      template(v-slot:item="itemProps")
        CatalogResultsCard(
          :hide-actions="hideActions || itemProps.item.entityType === FieldLevelSearchDto.entityType.DECLARED_ASSET"
          :item-props="itemProps"
          :isLast="isLast(itemProps.item)"
          :shouldTargetBlank="shouldTargetBlank"
          :is-editable="isEditable"
          :is-selectable="isSelectable"
          :search-params="searchParams"
          @edit="editAsset"
          @coverage="coverage"
        )

      template(v-slot:footer="footer")
        // eslint-disable-next-line vue/valid-v-bind-sync
        SiffletifyDataFooter( :footer.sync="footer" :items-per-page-options="[25, 50, 100]" @update="updateOptions($event)" )

  Essentials( ref="essentials" )

  EditDialog(
    ref="editDialog"
    @update="editDialogUpdateHandler"
    :fields="['tags', 'terms', 'description', 'owners']"
    has-rich-text
    should-fetch-details
  )

  CatalogImport( ref="catalogImport" :search-params="searchParams")
</template>

<style lang="scss" scoped>
.disabled {
  cursor: not-allowed;
}

.v-list-item--active::before {
  opacity: 0;
}
</style>
