/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BaseSearchFilterElementDto } from './BaseSearchFilterElementDto';

export type CriticalitySearchFilterElementDto = (BaseSearchFilterElementDto & {
    id?: CriticalitySearchFilterElementDto.id;
});

export namespace CriticalitySearchFilterElementDto {

    export enum id {
        CRITICAL = 'CRITICAL',
        HIGH = 'HIGH',
        MODERATE = 'MODERATE',
        LOW = 'LOW',
    }


}
