/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AsCodeDistributionReferenceDto = {
    kind: AsCodeDistributionReferenceDto.kind;
};

export namespace AsCodeDistributionReferenceDto {

    export enum kind {
        ROLLING = 'Rolling',
        FIXED = 'Fixed',
    }


}
