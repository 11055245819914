<template lang="pug">

ModalDialog( ref="modalDialogRef" :title="$t('rules.failing_rows_limited')" id="dryRun")
  template( v-slot:body )
    v-container(fluid)
      v-row( align='center')
        v-col
          v-alert( dense outlined type="error" )
            HtmlView( :html="ruleTestResult.description" )
          v-data-table(
            :headers='headers'
            :items='ruleTestResult.failingRows'
            :options.sync="options"
            hide-default-footer)

            template( v-for="col in headers" v-slot:[`item.${col.value}`]="{ item }")
              pre( :key="col.value" ) {{ item[col.value] }}

            template(v-slot:loading)
              DataTableGenericPlaceholder

            template(v-slot:no-data)
              NoDataAvailablePlaceholder

            template(v-slot:footer="footer")
              // eslint-disable-next-line vue/valid-v-bind-sync
              SiffletifyDataFooter(:footer.sync="footer.props" :items-per-page-options="[10, 25, 50, 100]" @update="updateOptions($event)" scroll-to="dryRun" )

</template>

<script lang="ts">
import {
  Vue, Component, VModel, Ref,
} from 'vue-property-decorator';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import { RuleDryRunResultDto } from '@/api';
import SiffletifyDataFooter
  from '@/components/siffletify/siffletify-data-footer/siffletify-data-footer.vue';
import { DataOptions } from 'vuetify';

@Component({
  components: { SiffletifyDataFooter },
})
export default class RuleDryRun extends Vue {
  @VModel({ type: Object }) readonly ruleTestResult!: RuleDryRunResultDto;

  @Ref() modalDialogRef! : ModalDialog;

  options = {
    page: 1,
    itemsPerPage: 10,
  };

  updateOptions(options: Partial<DataOptions>) {
    this.options.page = options.page!;
    this.options.page = options.itemsPerPage !== this.options.itemsPerPage ? 1 : options.page!;
    this.options.itemsPerPage = options.itemsPerPage!;
  }

  open() {
    this.modalDialogRef.openDialog();
  }

  close() {
    this.modalDialogRef.closeDialog();
  }

  get headers() {
    const rows = this.ruleTestResult.failingRows;
    const schema = rows.length > 0 ? Object.keys(rows[0]) : [];
    return schema.map((s) => ({ text: s, value: s }));
  }
}
</script>
