/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AsCodeNotificationReferenceDto = {
    id?: string;
    kind?: AsCodeNotificationReferenceDto.kind;
    name?: string;
};

export namespace AsCodeNotificationReferenceDto {

    export enum kind {
        SLACK = 'Slack',
        EMAIL = 'Email',
        MICROSOFT_TEAMS = 'MicrosoftTeams',
    }


}
