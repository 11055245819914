import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import incidents from '@/store/modules/incidents';
import features from '@/store/modules/features';
import domains from '@/store/modules/domains';
import secret from '@/store/modules/secret';
import monitors from '@/store/modules/monitors';
import config from '@/store/modules/config';
import tags from '@/store/modules/tags';
import users from '@/store/modules/users';
import glossary from '@/store/modules/business-glossary';
import auth from '@/store/modules/auth';
import ui from '@/store/modules/ui';
import breadcrumb from '@/store/modules/breadcrumb';
import lineage from '@/store/modules/lineage';
import dashboard from '@/store/modules/dashboard';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['auth', 'ui', 'config'],
  })],
  modules: {
    domains,
    secret,
    incidents,
    features,
    monitors,
    config,
    tags,
    users,
    glossary,
    auth,
    ui,
    breadcrumb,
    lineage,
    dashboard,
  },
});
