/**
 * Fires a console log that only works in dev mode to avoid an ESLint Error
 * @param {string} message - The console log message
 * @param {any[]} args - Extra console log params
 */
const log = (message: string, ...args: any[]): void => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(message, ...args);
  }
};

export default log;
