<template lang="pug">
div
  v-row
    v-col( cols="6")
      InputName( v-model.trim='datasource.name' label='Name' )

    v-col( cols="6" )
      InputProjectName( v-model.trim='datasource.params.projectName' )

    v-col( cols="6" )
      InputTarget( v-model.trim='datasource.params.target' )

  v-row
    v-col( cols="12" )
      v-checkbox( v-model='datasource.params.createIncidentOnFail' :label='$t("monitor_wizard.notification_method.create_incident")' )

</template>

<script lang="ts">
import {
  Vue, Component, VModel,
} from 'vue-property-decorator';
import { DatasourceDto, DBTParams } from '@/api';

type DbtSourceDto = DatasourceDto & { params: DBTParams; };

@Component
export default class DbtConnection extends Vue {
  @VModel({ type: Object }) datasource!: DbtSourceDto;
}
</script>
