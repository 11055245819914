// Generated by https://quicktype.io

export enum ConditionalRulesColumnOperators {
  EQUALS = 'Equals',
  NOT_EQUALS = 'Not equals',
  GREATER_THAN = 'Greater than',
  GREATER_THAN_OR_EQUALS = 'Greater than or equal to',
  LESS_THAN = 'Less than',
  LESS_THAN_OR_EQUALS = 'Less than or equal to',
  IS_NULL = 'Is null',
  IS_NOT_NULL = 'Is not null',
  CONTAINS = 'Contains',
  NOT_CONTAINS = 'Not contains',
}

export enum ConditionalRulesValueOperators {
  EQUALS = 'Equals',
  NOT_EQUALS = 'Not equals',
  GREATER_THAN = 'Greater than',
  GREATER_THAN_OR_EQUALS = 'Greater than or equal to',
  LESS_THAN = 'Less than',
  LESS_THAN_OR_EQUALS = 'Less than or equal to',
  IS_NULL = 'Is null',
  IS_NOT_NULL = 'Is not null',
  CONTAINS = 'Contains',
  NOT_CONTAINS = 'Not contains',
  IS_TRUE = 'Is true',
  IS_FALSE = 'Is false',
}

export enum ConditionalRulesValueStringOperators {
  EQUALS = 'Equals',
  NOT_EQUALS = 'Not equals',
  IS_NULL = 'Is null',
  IS_NOT_NULL = 'Is not null',
  CONTAINS = 'Contains',
  NOT_CONTAINS = 'Not contains',
}

export enum ConditionalRulesValueNumberOperators {
  EQUALS = 'Equals',
  NOT_EQUALS = 'Not equals',
  GREATER_THAN = 'Greater than',
  GREATER_THAN_OR_EQUALS = 'Greater than or equal to',
  LESS_THAN = 'Less than',
  LESS_THAN_OR_EQUALS = 'Less than or equal to',
  IS_NULL = 'Is Null',
  IS_NOT_NULL = 'Is not null',
}

export enum ConditionalRulesValueDateOperators {
  EQUALS = 'Equals',
  NOT_EQUALS = 'Not equals',
  GREATER_THAN = 'Greater than',
  GREATER_THAN_OR_EQUALS = 'Greater than or equal to',
  LESS_THAN = 'Less than',
  LESS_THAN_OR_EQUALS = 'Less than or equal to',
  IS_NULL = 'Is null',
  IS_NOT_NULL = 'Is not null',
}

export enum ConditionalRulesValueBooleanOperators {
  IS_TRUE = 'Is true',
  IS_FALSE = 'Is false',
  IS_NULL = 'Is null',
  IS_NOT_NULL = 'Is not null',
}

export enum ConditionalRulesNullOperators {
  IS_NULL = 'Is null',
  IS_NOT_NULL = 'Is not null',
}

export enum ConditionalRulesColumnStringOperators {
  EQUALS = 'Equals',
  NOT_EQUALS = 'Not equals',
  CONTAINS = 'Contains',
  NOT_CONTAINS = 'Not contains',
}

export enum ConditionalRulesColumnNumberOperators {
  EQUALS = 'Equals',
  NOT_EQUALS = 'Not equals',
  GREATER_THAN = 'Greater than',
  GREATER_THAN_OR_EQUALS = 'Greater than or equal to',
  LESS_THAN = 'Less than',
  LESS_THAN_OR_EQUALS = 'Less than or equal to',
}

export enum ConditionalRulesColumnDateOperators {
  EQUALS = 'Equals',
  NOT_EQUALS = 'Not equals',
  GREATER_THAN = 'Greater than',
  GREATER_THAN_OR_EQUALS = 'Greater than or equal to',
  LESS_THAN = 'Less than',
  LESS_THAN_OR_EQUALS = 'Less than or equal to',
}

export enum ConditionalRulesTimePeriodOperators {
   GREATER_THAN = 'Greater than',
  LESS_THAN = 'Less than',
}

export enum ConditionalRulesColumnBooleanOperators {
  EQUALS = 'Equals',
  NOT_EQUALS = 'Not equals',
}

export enum ConditionalRulesTypologies {
  BOOL = 'BOOL',
  INT64 = 'INT64',
  FLOAT64 = 'FLOAT64',
  NUMERIC = 'NUMERIC',
  BIGNUMERIC = 'BIGNUMERIC',
  STRING = 'STRING',
  BYTES = 'BYTES',
  STRUCT = 'STRUCT',
  ARRAY = 'ARRAY',
  TIMESTAMP = 'TIMESTAMP',
  DATE = 'DATE',
  TIME = 'TIME',
  DATETIME = 'DATETIME',
  GEOGRAPHY = 'GEOGRAPHY',
  NUMBER= 'NUMBER',
  INT='INT',
  REAL='REAL',
  JSON = 'JSON',
  UNKNOWN = 'UNKNOWN',
  }

export enum ConditionalRulesNumericTypes {
  TINYINT = 'TINYINT',
  SMALLINT = 'SMALLINT',
  INTEGER = 'INTEGER',
  BIGINT = 'BIGINT',
  FLOAT = 'FLOAT',
  REAL = 'REAL',
  DOUBLE = 'DOUBLE',
  NUMERIC = 'NUMERIC',
  DECIMAL = 'DECIMAL',
  INT64 = 'INT64',
  FLOAT64 = 'FLOAT64',
  BIGNUMERIC = 'BIGNUMERIC',
  NUMBER= 'NUMBER',
  INT='INT',
}

export enum ConditionalRulesDateTimeTypes {
  DATETIME = 'DATETIME',
  TIMESTAMP = 'TIMESTAMP',
}

export enum ConditionalRulesBooleanTypes {
  BOOL = 'BOOL',
  BOOLEAN = 'BOOLEAN'
}

export enum ConditionalRulesTimeTypes {
  TIME = 'TIME',
}

export enum ConditionalRulesDateTypes {
  DATE = 'DATE',
}

export enum ConditionalRulesTimePeriodTypes {
  DATETIME = 'DATETIME',
  TIMESTAMP = 'TIMESTAMP',
  TIME = 'TIME',
  DATE = 'DATE',
}

export enum ConditionalRulesTimePeriod {
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
}

export enum ConditionalRulesLogicalOperator {
  AND = 'AND',
  OR = 'OR',
}

export enum ConditionalRulesTypes {
  VALUE = 'VALUE',
  COLUMN = 'COLUMN',
  TIME_PERIOD = 'TIME_PERIOD',
}

export interface ConditionalRulesField {
  type: string;
  name: string;
  id: string;
}

export interface ConditionalRulesTimeWindow {
  time: string;
  timeUnit: ConditionalRulesTimePeriod;
}

export interface ConditionalRulesCondition {
  field: ConditionalRulesField;
  operator: string;
  value: string | number | boolean | ConditionalRulesField | ConditionalRulesTimePeriod;
  typology: string;
}

export interface ConditionalRulesConditionsGroup {
  logicalOperator: ConditionalRulesLogicalOperator;
  conditions: ConditionalRulesCondition[];
}

export enum ConditionalRulesJoinType {
  INNER = 'INNER',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  OUTER = 'OUTER',
}

export interface JoinItem {
  joinType: ConditionalRulesJoinType;
  joinKeyFields: ConditionalRulesField[];
}
export interface ConditionalRules {
  joinList: JoinItem[];
  logicalOperator: ConditionalRulesLogicalOperator;
  conditionsGroup: ConditionalRulesConditionsGroup[];
}

export function isNumericType(type: string) {
  return Object.values(ConditionalRulesNumericTypes).includes(type as ConditionalRulesNumericTypes);
}

export function isDateType(type: string) {
  return Object.values(ConditionalRulesDateTypes).includes(type as ConditionalRulesDateTypes);
}

export function isTimeType(type: string) {
  return Object.values(ConditionalRulesTimeTypes).includes(type as ConditionalRulesTimeTypes);
}

export function isTimePeriod(operator: string) {
  return Object.values(ConditionalRulesTimePeriodOperators).includes(operator as ConditionalRulesTimePeriodOperators);
}

export function isDateTimeType(type: string) {
  return Object.values(ConditionalRulesDateTimeTypes).includes(type as ConditionalRulesDateTimeTypes);
}

export function isBooleanType(type: string) {
  return Object.values(ConditionalRulesBooleanTypes).includes(type as ConditionalRulesBooleanTypes);
}

export function isNullOperator(operator: string) {
  return Object.keys(ConditionalRulesNullOperators).includes(operator as ConditionalRulesNullOperators);
}
