<script setup lang="ts">import { computed as _computed } from 'vue';

import { DatasourceDto } from '@/api';

interface DatasourceOverviewInfoIconNameProps {
  datasource: DatasourceDto;
}
const props =  defineProps({
  datasource: null
});

const hasDatasource = _computed(() => props.datasource !== null);
const isDeclarativeSource = _computed(() => props.datasource?.creationType === DatasourceDto.creationType.DECLARED);

const icon = _computed(() => {
  if (isDeclarativeSource.value) {
    return 'icon-data-source-declarative';
  }
  return 'icon-data-source';
});
</script>

<template lang="pug">
.datasource-overview-info-icon-name

  .d-flex.align-baseline.flex-nowrap( v-if="hasDatasource" )

    v-avatar.mr-2(:tile="false" size="40" color="bgTertiary")
      v-icon(color="iconNeutral" ) {{ icon }}

    .d-flex.flex-column
      .d-flex.align-start
        h2.text-h4.text-break  {{ datasource.name }}

      .d-flex
        DatasourceIcon( :datasource="datasource" )

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    type="list-item-avatar-two-line"
    width="500" )

</template>
