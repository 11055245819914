<template lang="pug">

div(class="d-flex flex-nowrap")
  v-icon(small) icon-lock-closed
  v-img(:src="image" width=16 height=16 class="flex-grow-0 flex-shrink-0 mr-1 align-self-center")
  span(class="text--info" :inner-html.prop="description")

</template>

<script lang="ts">
import { Vue, Component, VModel } from 'vue-property-decorator';
import src from '@/utils/filters/src';

@Component
export default class DbtDescription extends Vue {
  @VModel({ type: String, default: null }) description!: string | null;

  get image() {
    return src('dbt', 'datasources');
  }
}
</script>
