/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type DescriptionPredictionFeedbackDto = {
    descriptionPredictionId: string;
    validationStatus: DescriptionPredictionFeedbackDto.validationStatus;
};

export namespace DescriptionPredictionFeedbackDto {

    export enum validationStatus {
        NO_FEEDBACK = 'NO_FEEDBACK',
        VALIDATED = 'VALIDATED',
        REJECTED = 'REJECTED',
    }


}
