<template lang="pug">

.d-flex.flex-column.mb-3
  .d-flex( v-if="hasLoaded" )
    b.mr-2 {{ $t('common.words.description') }}
    v-btn( v-if="canEdit" icon x-small @click="enterEditMode" )
      v-icon( small color="secondary" ) icon-edit

  template( v-if="hasNone" )
    .grey--text {{ $t('common.words.none') }}

  template( v-if="hasDescription" )
    span(style="white-space: pre-line") {{ description }}

  slot( name="descriptions" )

  .ma-2( v-if="editMode" )
    v-form( lazy-validation v-model='valid' )
      v-textarea(
        v-model="descModel"
        :rules="descriptionRules"
        label='Edit Description'
        :counter='counter'
        dense outlined)
      .d-flex
        v-btn( class="ml-auto" color='secondary' outlined depressed @click="exitEditMode" ) {{ $t('common.words.cancel') }}
        v-btn( :disabled="disabled" class="ml-2" color='primary' depressed @click="save" ) {{ $t('common.words.save') }}

  v-skeleton-loader(
    v-if="!hasLoaded"
    class="v-skeleton-loader-override mb-5"
    type="list-item-three-line" )

</template>

<script lang="ts">
import {
  Vue, Component, Emit, VModel, Watch, Prop,
} from 'vue-property-decorator';
import { Inputs } from '@/@types/types';

@Component
export default class InputDescriptionEdit extends Vue {
  @VModel({ type: String, default: null }) description!: string | null;

  @Prop({ type: String, required: false }) urn!: string;

  @Prop({ type: Boolean, default: true }) readonly!: boolean;

  counter = Inputs.INPUT_HIGH_CHARS;

  editMode = false;

  descModel: string | null = '';

  valid = true;

  descriptionRules = [
    (v: string) => {
      if (v) return v.length <= this.counter || `Max ${this.counter} characters`;
      return true;
    },
  ];

  get canEdit() {
    return !this.editMode && !this.readonly;
  }

  get hasNone() {
    if (this.hasSlot) return false;
    if (this.hasNoDescription) return true;
    return false;
  }

  get hasLoaded() {
    return this.description !== null;
  }

  get disabled() {
    return !this.valid;
  }

  get hasNoDescription() {
    return !this.description && !this.editMode;
  }

  get hasDescription() {
    return this.description && !this.editMode;
  }

  get hasSlot() {
    return Object.keys(this.$slots).length > 0;
  }

  enterEditMode() {
    this.editMode = true;
    this.descModel = this.description;
  }

  exitEditMode() {
    this.editMode = false;
  }

  @Watch('urn')
  onSelectedChange() {
    this.exitEditMode();
  }

  @Emit('save')
  save() {
    this.exitEditMode();
    return this.descModel;
  }
}
</script>
