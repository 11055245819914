import Vue from 'vue';
import VueI18n from 'vue-i18n';
import getEnv from '@/utils/env';
import en from '@/locales/en.json';
import fr from '@/locales/fr.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: getEnv('VUE_APP_I18N_LOCALE') || 'en',
  fallbackLocale: getEnv('VUE_APP_I18N_FALLBACK_LOCALE') || 'en',
  messages: { en, fr },
});
