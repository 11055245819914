export default class Point {
  copy(): Point {
    return new Point(this.x, this.y);
  }

    public x: number;

    public y: number;

    constructor(
      x: number,
      y: number,
    ) {
      this.x = x;
      this.y = y;
    }
}
