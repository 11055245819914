<script lang="ts">
const RICH_TEXT_CHAR_LIMIT = 15000000
const NORMAL_TEXT_CHAR_LIMIT = 1000
</script>
<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import {
  AssetService,
  OwnerService, TagDto,
} from '@/api';
import type {
  AssetListDto, UserDto, AssetPatchDto,
} from '@/api';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import { Inputs } from '@/@types/types';
import { createPatchDto } from '@/api/patches';
import i18n from '@/i18n';
import SRichTextEditor from '@/components/SRichTextEditor.vue';




interface EditDialogProps {
  fields?: ('tags' | 'terms' | 'description' | 'owners')[];
  customTitle?: string;
  saveAndAddMode?: boolean;
  hasRichText?: boolean;
  shouldFetchDetails?: boolean;
}

type EditableEntity = {
  name?: string;
  urn?: string;
  tags?: TagDto[];
  terms?: TagDto[];
  owners?: UserDto[];
  description?: string;
};

type AssetsEditEmits = {
  (event: 'update', data: { entities: any, patches: any }): void
}

const emit = defineEmits(["update"]);

const props = defineProps({
  fields: { default: () => [] },
  customTitle: { default: '' },
  saveAndAddMode: { type: Boolean, default: false },
  hasRichText: { type: Boolean, default: false },
  shouldFetchDetails: { type: Boolean, default: false }
});

const modalDialogRef = _ref<InstanceType<typeof ModalDialog> | null>(null);
const counter = _ref(Inputs.INPUT_HIGH_CHARS);
const richTextEditor = _ref<InstanceType<typeof SRichTextEditor> | null>(null);

let tags = _ref<TagDto[] | undefined>([]);
let terms = _ref<TagDto[] | undefined>([]);
let owners = _ref<UserDto[] | undefined>([]);
let possibleOwners = _ref<UserDto[]>([]);
let description: EditableEntity['description'] = _ref('');
let entityStates = _ref<Record<string, EditableEntity>>({});
let urn = _ref('');
let name = _ref('');
let entities = _ref<EditableEntity['urn'][]>([]);
let removeExistingTags = _ref(false);
let removeExistingTerms = _ref(false);
let removeExistingOwners = _ref(false);

const isSingleEdit = _computed(() => entities.value.length === 1);
const entitiesCount = _computed<number>(() => entities.value.length);
const hasTags = _computed(() => props.fields?.includes('tags') || false);
const hasTerms = _computed(() => props.fields?.includes('terms') || false);
const hasDescription = _computed(() => props.fields?.includes('description') || false);
const hasOwners = _computed(() => props.fields?.includes('owners') || false);
const saveButtonLabel = _computed(() => (props.saveAndAddMode ? 'edit-dialog.save_and_add' : 'edit-dialog.save'));
const shouldFetchOverview = _computed(() => props.shouldFetchDetails && props.hasRichText);
const charLimit = _computed(() => (props.hasRichText ? RICH_TEXT_CHAR_LIMIT : NORMAL_TEXT_CHAR_LIMIT));
const descriptionRules = _computed(() => [(v: string) => v?.length <= charLimit.value || `${charLimit.value} chars max`]);
const hasReachedLimit = _computed(() => description.value?.length! > charLimit.value);
const descriptionChars = _computed<string>(() => {
  if (description.value && description.value.length.toLocaleString()) {
    return `${description.value.length.toLocaleString()} / ${charLimit.value.toLocaleString()}`;
  }
  return `0 / ${charLimit.value.toLocaleString()}`;
});

const title = _computed<string>(() => {
  if (props.customTitle) {
    return props.customTitle;
  }
  return isSingleEdit.value ? `${i18n.t('edit-dialog.edit')} ${name.value}` : i18n.tc('edit-dialog.multiple_datasets', entitiesCount.value) as string;
});

const getPatch = (currentState: EditableEntity) => {
  let tagIds: any = [];
  let termIds: any = [];
  let ownerIds: any = [];
  let newDescription: string | undefined = '';

  props.fields.forEach((field) => {
    switch (field) {
      case 'tags':
        tagIds = createPatchDto({ tags: tags.value }, currentState.tags, removeExistingTags.value, isSingleEdit.value);
        break;
      case 'terms':
        termIds = createPatchDto({ terms: terms.value }, currentState.terms, removeExistingTerms.value, isSingleEdit.value);
        break;
      case 'owners':
        ownerIds = createPatchDto({ owners: owners.value }, currentState.owners, removeExistingOwners.value, isSingleEdit.value);
        break;
      case 'description':
        if (!isSingleEdit.value && !description.value) {
          // If the description is empty, we want to keep the current description
          newDescription = currentState.description;
        } else {
          newDescription = description.value;
        }
        break;
      default:
        break;
    }
  });

  return {
    tagIds,
    termIds,
    description: newDescription,
    ownerIds,
  };
};

const init = () => {
  entities.value = [];
  tags.value = [];
  terms.value = [];
  description.value = '';
  owners.value = [];
  possibleOwners.value = [];
  removeExistingTags.value = false;
  removeExistingTerms.value = false;
  removeExistingOwners.value = false;
  entityStates.value = {};
};

const open = () => modalDialogRef.value!.openDialog();
const close = () => modalDialogRef.value!.closeDialog();

const update = async () => {
  const patches: AssetPatchDto[] = [];
  entities.value.forEach((entity: any) => patches.push(getPatch(entityStates.value[entity])));
  emit('update', { entities: entities.value, patches });
  close();
};

const fetchPossibleOwners = async (assetUrns: string[]) => {
  const requestBody: AssetListDto = {
    assetIds: assetUrns.map((assetUrn) => assetUrn.split(':')[1]),
  };
  const { data } = await OwnerService.getAllPossibleOwnersUi({ requestBody });
  possibleOwners.value = data!;
};

const setEntities = async (newEntities: EditableEntity[]) => {
  init();
  entities.value = newEntities.map((entity) => entity.urn);

  open();

  if (isSingleEdit.value) {
    const entity = newEntities[0];

    if (shouldFetchOverview.value) {
      AssetService.getAssetOverviewByUrn({ urn: entity.urn! }).then((response) => {
        richTextEditor.value?.setHTMLValue(response.details?.description!);
      });
    }

    urn.value = entity.urn!;
    name.value = entity.name || '';
    if (entity.tags) tags.value = entity.tags;
    if (entity.terms) terms.value = entity.terms;
    if (entity.owners) owners.value = entity.owners;
    if (entity.description && !shouldFetchOverview.value) description.value = entity.description;
  }

  const assetUrns: string[] = [];
  newEntities.forEach((entity) => {
    const assetUrn = entity.urn || '';
    entityStates.value[assetUrn] = { ...entity, urn: urn.value }; // Update the current state for each entity
    assetUrns.push(assetUrn);
  });

  if (hasOwners.value) {
      await fetchPossibleOwners(assetUrns);
    }
};

const handleEditorChange = () => {
  description.value = richTextEditor.value?.getHTMLValue();
};

const resetEditor = () => {
  richTextEditor.value?.setHTMLValue('');
};

const handleClose = () => {
  if (shouldFetchOverview.value) {
    resetEditor();
  }
};

defineExpose({ setEntities });
</script>

<template lang="pug">

ModalDialog(
  ref="modalDialogRef"
  content-class="asset-edit-dialogue"
  @close="handleClose"
  :title="title" )

  template( v-slot:body )
    v-form( lazy-validation )
      v-row( no-gutters )
        v-col.mb-8( v-if="hasTags" cols="12" )
          TagSelector(
            v-model="tags"
            data-cy="annotate-dialog--tag-selector" )

          v-checkbox(
            v-if="!isSingleEdit"
            v-model="removeExistingTags"
            :label="$t('edit-dialog.remove_existing_tags')"
            class="asset-edit-dialog--remove-existing-tags"
            hide-details dense )

        v-col.mb-8( v-if="hasTerms" cols="12" )
          TermSelector(
            v-model="terms"
            data-cy="annotate-dialog--term-selector" )

          v-checkbox(
            v-if="!isSingleEdit"
            v-model="removeExistingTerms"
            :label="$t('edit-dialog.remove_existing_terms')"
            class="asset-edit-dialog--remove-existing-terms"
            hide-details dense )

        v-col.mb-8( v-if="hasDescription" cols="12" )
          SRichTextEditor(
            v-if="hasRichText"
            ref="richTextEditor"
            data-cy="annotate-dialog--textarea"
            :initial-value="description"
            @editorChanged="handleEditorChange"
          )
          v-textarea(
            v-else
            v-model="description"
            :counter="counter"
            :label="$t('edit-dialog.description')"
            :rules="descriptionRules"
            data-cy="annotate-dialog--textarea"
            dense outlined
            hide-details
            persistent-hint )
          .d-flex.mt-2
            span(v-if="!isSingleEdit")
              v-icon.mr-2( small ) icon-warning-outline
              span {{ $t('edit-dialog.override') }}
            v-spacer
            .d-flex.flex-column.text-right
              span.subtitle-2.textDanger--text(v-if="hasReachedLimit && hasRichText") {{ $t('edit-dialog.description_too_large') }}
              span(v-if="!hasRichText") {{ descriptionChars }}

        v-col.mb-8( v-if="hasOwners" cols="12")
          UserSelector(
            v-model="owners"
            :possibleOwners="possibleOwners"
            data-cy="annotate-dialog--owner-selector"
            multiple
            editDialog )

          v-checkbox(
            v-if="!isSingleEdit"
            v-model="removeExistingOwners"
            :label="$t('edit-dialog.remove_existing_owners')"
            hide-details dense )

  template( v-slot:actions )
    SButton(
      color="primary"
      :disabled="hasReachedLimit"
      :text="$t(saveButtonLabel)"
      data-cy="catalog-results-edit--save"
      :async-action="update")
</template>
