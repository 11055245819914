<script setup lang="ts">import { computed as _computed } from 'vue';

import {
  computed, onBeforeMount, ref,
} from 'vue';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';
import { IncidentService, UserService } from '@/api';
import type { UserUiDto, CatalogFilterDto } from '@/api';
import type { QueryType } from '@/utils/query';
import { filtersValuesToQuery, INITIAL_SEARCH_PARAMS } from './Incidents';

interface IncidentsFiltersProps {
  searchParams: Parameters<typeof IncidentService.getAllIncident>[0]['requestBody'],
  filters: CatalogFilterDto[]
}

type IncidentsFiltersEmits = {
  (event: 'onFilterChange', filter: { [key: string]: QueryType | number }): void
}

const auth = getModule(authModule, store);

const props = defineProps({
  searchParams: { default: undefined },
  filters: { default: () => [] }
});

const emit = defineEmits(["onFilterChange"]);

const users = ref<UserUiDto[]>([]);

const filterQuantity = computed<number>(() => {
  const {
    status,
    criticality,
    user,
  } = props.searchParams;

  const quantifiableFilters = {
    status,
    criticality,
    user,
  };

  return Object.values(quantifiableFilters).reduce((acc, curr) => acc + curr!.length, 0);
});

const filtersType = computed(() => ({
  status: props.filters.find((filter) => filter.id === 'status'),
  criticality: props.filters.find((filter) => filter.id === 'criticality'),
  user: {
    id: 'assignment',
    name: 'assignment',
    query: 'assignment',
    children: users.value.map((user) => ({
      name: user.name,
      id: user.id,
      results: 0,
      children: [],
    })),
  },
}));

const filtersValues = _computed(() => filtersValuesToQuery(props.searchParams));

const setValues = (filter: { [key: string]: QueryType }) => {
  emit('onFilterChange', filter);
};

const clearFilters = () => {
  emit('onFilterChange', { ...INITIAL_SEARCH_PARAMS, status: [] });
};

const fetchUsers = () => {
  UserService.getAllUsers().then(({ data }) => {
    users.value = [auth.getUser!, ...data.filter((user) => user.id !== auth.getUser!.id)];
  });
};

onBeforeMount(() => {
  fetchUsers();
});
</script>

<template lang="pug">
#incident-filters
  FilterReset(
    :filterQuantity="filterQuantity"
    @reset="clearFilters")

  v-divider.my-2

  TreeCheckboxSelector(
    :filter="filtersType.status"
    :value="filtersValues.status"
    @input="setValues({ status: $event })"
  )

  v-divider.my-2

  TreeCheckboxSelector(
    :filter="filtersType.criticality"
    :value="filtersValues.criticality"
    @input="setValues({ criticality: $event })"
  )

  v-divider.my-2

  TreeCheckboxSelector(
    :filter="filtersType.user"
    :value="filtersValues.user"
    @input="setValues({ user: $event })"
    has-search
  )
</template>
