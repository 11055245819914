import { AnomalyConditionsEnum, AnomalyConditionsSettings } from '@/components/monitor-wizard/composables/monitors/common/types';

const dynamicItems: AnomalyConditionsSettings = [
  {
    text: 'monitor_wizard.anomaly_conditions.upper_and_lower_bound',
    icon: 'icon-arrow-bidirectional',
    value: AnomalyConditionsEnum.UPPER_AND_LOWER_BOUND,
  },
  {
    text: 'monitor_wizard.anomaly_conditions.upper_bound',
    icon: 'icon-arrow-up',
    value: AnomalyConditionsEnum.UPPER_BOUND,
  },
  {
    text: 'monitor_wizard.anomaly_conditions.lower_bound',
    icon: 'icon-arrow-down',
    value: AnomalyConditionsEnum.LOWER_BOUND,
  },
];

const staticItems: AnomalyConditionsSettings = [
  {
    text: 'monitor_wizard.anomaly_conditions.single_threshold',
    value: AnomalyConditionsEnum.SINGLE_THRESHOLD,
  },
  {
    text: 'monitor_wizard.anomaly_conditions.compound_threshold',
    value: AnomalyConditionsEnum.COMPOUND_THRESHOLD,
  },
];

const relativeItems: AnomalyConditionsSettings = [
  {
    text: 'monitor_wizard.anomaly_conditions.single_threshold_value',
    value: AnomalyConditionsEnum.SINGLE_VALUE_THRESHOLD_COMPARED_TO_LAST_RUN,
    isInteger: true,
  },
  {
    text: 'monitor_wizard.anomaly_conditions.single_threshold_percentage',
    value: AnomalyConditionsEnum.SINGLE_PERCENTAGE_THRESHOLD_COMPARED_TO_LAST_RUN,
    isPercentage: true,
  },
  {
    text: 'monitor_wizard.anomaly_conditions.compound_threshold_value',
    value: AnomalyConditionsEnum.COMPOUND_VALUE_THRESHOLD_COMPARED_TO_LAST_RUN,
    isInteger: true,
  },
  {
    text: 'monitor_wizard.anomaly_conditions.compound_threshold_percentage',
    value: AnomalyConditionsEnum.COMPOUND_PERCENTAGE_THRESHOLD_COMPARED_TO_LAST_RUN,
    isPercentage: true,
  },
];

const compoundConditionsBelow = [
  {
    text: 'monitor_wizard.anomaly_conditions.less_than_or_equal_to',
    icon: 'icon-chevron-left-sharp-equal',
    value: '<=',
  },
  {
    text: 'monitor_wizard.anomaly_conditions.less_than',
    icon: 'icon-chevron-left-sharp',
    value: '<',
  },
];

const compoundConditionsAbove = [
  {
    text: 'monitor_wizard.anomaly_conditions.greater_than_or_equal_to',
    icon: 'icon-chevron-right-sharp-equal',
    value: '>=',
  },
  {
    text: 'monitor_wizard.anomaly_conditions.greater_than',
    icon: 'icon-chevron-right-sharp',
    value: '>',
  }];

const singleConditions = [
  ...compoundConditionsBelow,
  ...compoundConditionsAbove,
];

export {
  staticItems,
  relativeItems,
  dynamicItems,
  singleConditions,
};
