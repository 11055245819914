/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { IncidentEventDto } from './IncidentEventDto';
import type { UserDto } from './UserDto';

export type StatusChangeIncidentEventDto = (IncidentEventDto & {
    changedByUser?: UserDto;
    firstDate?: string;
    lastDate?: string;
    newQualification?: StatusChangeIncidentEventDto.newQualification | null;
    newStatus?: StatusChangeIncidentEventDto.newStatus;
    oldQualification?: StatusChangeIncidentEventDto.oldQualification | null;
    oldStatus?: StatusChangeIncidentEventDto.oldStatus;
} & {
    firstDate: string;
    lastDate: string;
    newStatus: StatusChangeIncidentEventDto.newStatus;
    oldStatus: StatusChangeIncidentEventDto.oldStatus;
});

export namespace StatusChangeIncidentEventDto {

    export enum newQualification {
        FIXED = 'FIXED',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        EXPECTED = 'EXPECTED',
        KNOWN_ERROR = 'KNOWN_ERROR',
        DUPLICATE = 'DUPLICATE',
    }

    export enum newStatus {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }

    export enum oldQualification {
        FIXED = 'FIXED',
        FALSE_POSITIVE = 'FALSE_POSITIVE',
        EXPECTED = 'EXPECTED',
        KNOWN_ERROR = 'KNOWN_ERROR',
        DUPLICATE = 'DUPLICATE',
    }

    export enum oldStatus {
        OPEN = 'OPEN',
        IN_PROGRESS = 'IN_PROGRESS',
        CLOSED = 'CLOSED',
    }


}
