<script lang="ts" setup>
import { DataItemProps } from 'vuetify';
import { TagDto } from '@/api';
import copyToClipboard from '@/utils/copyToClipboard';
import i18n from '@/i18n';

interface BusinessGlossaryResultCardProps {
  itemProps: Omit<DataItemProps, 'item'> & { item: TagDto };
}

type BusinessGlossaryResultCardEmits = {
  (event: 'edit', item: TagDto): void
  (event: 'delete', item: TagDto): void
}

const props = defineProps({
  itemProps: null
});
const emit = defineEmits(["edit", "delete"]);

const edit = () => {
  emit('edit', props.itemProps.item);
};
const deleteItem = () => {
  emit('delete', props.itemProps.item);
};
const copyIDToClipboard = async () => {
  copyToClipboard(props.itemProps.item.id, `${i18n.t('glossary.copy_id_success', { term: props.itemProps.item.name })}`);
};
</script>

<template lang="pug">
SThreeDotMenu(variant="text")
  SMenuButton(
    @click="edit"
    icon="icon-edit"
    :text="$t('glossary.edit')")
  SMenuButton(
    @click="copyIDToClipboard"
    icon="icon-copy"
    :text="$t('glossary.copy_id')")
  SMenuButton(
    @click="deleteItem"
    icon="$delete"
    color="danger"
    :text="$t('glossary.delete')")
  </template>
