<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="catalogRules"
    label='Catalog'
    data-cy="catalog"
    dense clearable outlined required)

</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputCatalog extends Vue {
  catalogRules = [(v: string) => isConvertibleToNotBlankString(v) || 'Catalog is required'];
}
</script>
