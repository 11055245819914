/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PublicSourceParametersDto } from './PublicSourceParametersDto';

export type PublicMysqlParametersDto = (PublicSourceParametersDto & {
    /**
     * Your database name
     */
    database?: string;
    /**
     * Your MySQL server hostname
     */
    host?: string;
    /**
     * The TLS version to use to connect to your MySQL server
     */
    mysqlTlsVersion?: PublicMysqlParametersDto.mysqlTlsVersion;
    /**
     * Your MySQL server port
     */
    port?: number;
} & {
    /**
     * Your database name
     */
    database: string;
    /**
     * Your MySQL server hostname
     */
    host: string;
    /**
     * The TLS version to use to connect to your MySQL server
     */
    mysqlTlsVersion: PublicMysqlParametersDto.mysqlTlsVersion;
    /**
     * Your MySQL server port
     */
    port: number;
});

export namespace PublicMysqlParametersDto {

    /**
     * The TLS version to use to connect to your MySQL server
     */
    export enum mysqlTlsVersion {
        TLS_V_1_2 = 'TLS_V_1_2',
        TLS_V_1_3 = 'TLS_V_1_3',
    }


}
