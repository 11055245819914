<template lang="pug">
v-select(
  :items="items"
  v-model="domain"
  item-text="name"
  item-value="name"
  hide-details
  dense
  outlined
  return-object
  style="max-width: 200px"
  :menu-props="{ bottom: true, offsetY: true, 'min-width': '240px', left: true, 'nudge-top':'-10' }")
  template( v-slot:selection="{ item }")
    .d-flex.align-center
      v-icon.mr-2( small) icon-building
      span.text-truncate( style="width: 120px;" :title="$tc('domains.all_datasets')" v-if="item.name==='All'") {{ $t('domains.all_datasets') }}
      span.text-truncate( style="width: 120px;" :title="item.name" v-else) {{ item.name }}

  template( v-slot:item="{ item }")
   v-row( no-gutters )
    v-col
      div.text-truncate( style="width: 120px;" :title="item.name" ) {{ item.name }}
    v-spacer
    v-col
      span.text-truncate( style="width: 60px;" :title="item.role" ) {{ capitalizeFirst(item.role) }}

</template>

<script lang="ts">
import {
  Vue, Component,
} from 'vue-property-decorator';
import store from '@/store';
import authModule from '@/store/modules/auth';
import { getModule } from 'vuex-module-decorators';
import capitalizeFirst from '@/utils/filters/capitalizeFirst';

const auth = getModule(authModule, store);

@Component({
  components: {},
})
export default class DomainSelector extends Vue {
  capitalizeFirst = capitalizeFirst;

  get items() {
    return auth.getUserDomains;
  }

  get domain() {
    return auth.getDomain;
  }

  set domain(domain) {
    auth.setDomain(domain);
  }
}
</script>
