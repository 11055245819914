<template lang="pug">
v-autocomplete(
    :items="mysqlTlsVersionItems"
    v-model="$attrs"
    v-bind="$attrs"
    v-on="$listeners"
    label="TLS version"
    :rules="tlsVersionRules"
    dense outlined required)
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import { MysqlParams } from '@/api';
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';

@Component
export default class InputMysqlTlsVersion extends Vue {
  tlsVersionRules = [(v: string) => isConvertibleToNotBlankString(v) || 'Tls version is required'];

  get mysqlTlsVersionItems() {
    const res: { text: string; value: MysqlParams.mysqlTlsVersion; }[] = [];
    Object.values(MysqlParams.mysqlTlsVersion).forEach((value) => {
      res.push({
        text: this.formatTls(value),
        value,
      });
    });
    return res;
  }

  formatTls(value: string): string {
    switch (value) {
      case MysqlParams.mysqlTlsVersion.TLS_V_1_2:
        return 'TLSv1.2';
      case MysqlParams.mysqlTlsVersion.TLS_V_1_3:
        return 'TLSv1.3';
      default:
        return '';
    }
  }
}
</script>
