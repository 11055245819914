/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetJiraConfigDto } from './GetJiraConfigDto';

export type GetCollaborationToolConfigDto = {
    config: GetJiraConfigDto;
    id: string;
    type: GetCollaborationToolConfigDto.type;
};

export namespace GetCollaborationToolConfigDto {

    export enum type {
        JIRA = 'JIRA',
    }


}
