<template lang="pug">
v-select(
    v-bind="$attrs"
    v-on="$listeners"
    :items='items'
    :rules="rules"
    label='Authentication Type'
    dense outlined clearable required )
</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class InputAuthenticationSelector extends Vue {
    @Prop([]) readonly items!: string[];

    rules = [(v: string) => isConvertibleToNotBlankString(v) || 'Authentication Type is required'];
}
</script>
