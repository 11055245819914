// Legacy
export type LegacyDynamicThresholdSettings = {
  sensitivity: number | null,
  ignoreOneBound?: boolean,
  boundChoice?: 'prediction is greater than the expected upper bound' | 'prediction is less than the expected lower bound' | null,
}

export type LegacyFreshnessDynamicAndStaticThresholdSettings = {
  comparisonType: 'dynamic' | 'static',
  sensitivity?: number | null,
}

export type LegacyStaticAndRelativeThresholdSettings = {
  comparisonMode: 'ABSOLUTE' | 'DIFFERENCE' | 'PERCENTAGE_DIFFERENCE',
  schemaKey: 'onlyMin' | 'minAndMax' | 'onlyMax' | null,
  minOp: '<=' | '<' | null,
  min: number | null,
  maxOp: '<=' | '<' | null
  max: number | null,
}

export type LegacyStaticThresholdSettings = {
  schemaKey: 'onlyMin' | 'minAndMax' | 'onlyMax' | null,
  minOp: '<=' | '<' | null,
  min: number | null,
  maxOp: '<=' | '<' | null
  max: number | null,
}

export type LegacyFreshnessTimeGapThresholdSettings = {
  sensitivity: number,
}

export type LegacyDynamicStaticAndExactThresholdSettings = {
  comparisonType: 'dynamic' | 'static' | 'exact',
  // dynamic
  sensitivity: number | null,
  ignoreOneBound?: false | null,
  // static
  schemaKey: 'onlyMin' | 'minAndMax' | 'onlyMax' | null,
  minOp: '<=' | '<' | null,
  min: number | null,
  maxOp: '<=' | '<' | null
  max: number | null,
  // exact
  // nothing
}

export type LegacyStaticDynamicThresholdSettings = {
  comparisonType: 'dynamic' | 'static',
  // dynamic
  sensitivity?: number | null,
  // static
  alertIfDeletedCategory?: boolean | null,
  alertIfNewCategory?: boolean | null,
  staticThreshold?: number | null,
}

export type LegacyStaticDuplicateThresholdSettings = {
  threshold: number,
}

export type LegacyThresholdSettings = (LegacyDynamicThresholdSettings | LegacyFreshnessDynamicAndStaticThresholdSettings | LegacyStaticAndRelativeThresholdSettings | LegacyStaticThresholdSettings | LegacyFreshnessTimeGapThresholdSettings | LegacyDynamicStaticAndExactThresholdSettings | LegacyStaticDynamicThresholdSettings | LegacyStaticDuplicateThresholdSettings)

// New
export enum AnomalyConditionsEnum {
  UPPER_BOUND = 'UPPER_BOUND',
  LOWER_BOUND = 'LOWER_BOUND',
  UPPER_AND_LOWER_BOUND = 'UPPER_AND_LOWER_BOUND',
  SINGLE_THRESHOLD = 'SINGLE_THRESHOLD',
  COMPOUND_THRESHOLD = 'COMPOUND_THRESHOLD',
  SINGLE_VALUE_THRESHOLD_COMPARED_TO_LAST_RUN = 'SINGLE_VALUE_THRESHOLD_COMPARED_TO_LAST_RUN',
  SINGLE_PERCENTAGE_THRESHOLD_COMPARED_TO_LAST_RUN = 'SINGLE_PERCENTAGE_THRESHOLD_COMPARED_TO_LAST_RUN',
  COMPOUND_VALUE_THRESHOLD_COMPARED_TO_LAST_RUN = 'COMPOUND_VALUE_THRESHOLD_COMPARED_TO_LAST_RUN',
  COMPOUND_PERCENTAGE_THRESHOLD_COMPARED_TO_LAST_RUN = 'COMPOUND_PERCENTAGE_THRESHOLD_COMPARED_TO_LAST_RUN',
  SINGLE_VALUE = 'SINGLE_VALUE',
}

export type AnomalyConditions = {
  type: AnomalyConditionsEnum | null;
  singleOp: '<=' | '<' | '>' | '>=' | null,
  singleValue: number | null;
  compoundMinValue: number | null;
  compoundMaxValue: number | null;
  compoundMinOp: '<=' | '<' | null,
  compoundMaxOp: '>=' | '>' | null
  alertIfNewCategory?: boolean | null; // ??
  alertIfDeletedCategory?: boolean | null; // ??
}

export enum ThresholdSettingsEnum {
  DYNAMIC = 'dynamic',
  STATIC = 'static',
  STATIC_DISTRIBUTION = 'staticDistribution',
  RELATIVE = 'relative',
  EXACT = 'exact',
}

export type ThresholdSettings = {
  thresholdMode: ThresholdSettingsEnum;
  sensitivity: number | null;
  anomalyConditions: AnomalyConditions;
}

export type AnomalyConditionsSettings = {
  text: string,
  value: AnomalyConditionsEnum,
  icon?: string,
  isPercentage?: boolean,
  isInteger?: boolean,
  min?: number,
  max?: number,
}[]

export type ThresholdCardSettings = {
  type: ThresholdSettingsEnum;
  text: string,
  description: string,
  icon: string,
  disabled: boolean,
}

export type ThresholdSettingsCommon = ThresholdCardSettings & {
  type: ThresholdSettingsEnum,
  hasSensitivity: boolean,
  hasAnomalyConditions: boolean,
  anomalyConditions?: AnomalyConditionsSettings,
  convertThresholdToLegacy?: (thresholdParams: ThresholdSettings) => LegacyThresholdSettings | null,
}

type DynamicThresholdSettingsOptions = ThresholdSettingsCommon & {
  type: ThresholdSettingsEnum.DYNAMIC,
  disallowCustomizeUpperLower?: boolean, // Default to false
}

type StaticThresholdSettingsOptions = ThresholdSettingsCommon & {
  type: ThresholdSettingsEnum.STATIC,
  disallowCustomizeBounds?: boolean, // Default to false
  isOnlyPositive?: boolean, // Default to false
  isInteger?: boolean, // Default to false
}

type StaticDistributionThresholdSettingsOptions = ThresholdSettingsCommon & {
  type: ThresholdSettingsEnum.STATIC_DISTRIBUTION,
}

type RelativeThresholdSettingsOptions = ThresholdSettingsCommon & {
  type: ThresholdSettingsEnum.RELATIVE,
}

type ExactThresholdSettingsOptions = ThresholdSettingsCommon & {
  type: ThresholdSettingsEnum.EXACT,
}

export type MonitorSettingsOptions = {
  thresholdSettings?: (DynamicThresholdSettingsOptions | StaticThresholdSettingsOptions | StaticDistributionThresholdSettingsOptions | RelativeThresholdSettingsOptions | ExactThresholdSettingsOptions)[]
  canClone?: boolean,
}
