/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AsCodeIncidentDto = {
    createOnFailure?: boolean;
    message?: string;
    severity: AsCodeIncidentDto.severity;
};

export namespace AsCodeIncidentDto {

    export enum severity {
        LOW = 'Low',
        MODERATE = 'Moderate',
        HIGH = 'High',
        CRITICAL = 'Critical',
    }


}
