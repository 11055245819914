<template lang="pug">
v-row

  v-col( cols="6")
      InputName( v-model.trim='datasource.name' label='Name' )

  v-col( cols='6')
      RegionSelector( v-model.trim='datasource.params.region' )

  v-col( cols='6')
    InputDatasource( v-model.trim='datasource.params.datasource' )

  v-col( cols="6" v-if="modifyExisting" )
    InputDatabase( v-model.trim='datasource.params.database' )

  v-col( cols="6" v-else class="border")
    SchemaSelector(
      type="databases"
      :tooltip-list-schema="tooltipListSchema"
      :is-scan-active="isScanActive"
      v-model.trim='datasource.params.database'
      :datasource="datasource")

  v-col( cols='6')
    InputS3OutputLocation( v-model.trim='datasource.params.s3OutputLocation' )

  v-col( cols='6')
    InputWorkgroup( v-model.trim='datasource.params.workgroup' )

  v-col( cols="6" )
    InputRoleArn( v-model.trim='datasource.params.roleArn' )

  v-col( cols='6')
    InputVpcUrl( v-model.trim='datasource.params.vpcUrl' )

</template>

<script lang="ts">
import {
 Vue, Component, VModel, Watch,
} from 'vue-property-decorator';
import { AthenaParams, DatasourceDto } from '@/api';

type AthenaSourceDto = DatasourceDto & { params: AthenaParams };

@Component
export default class AthenaConnection extends Vue {
  @VModel({ type: Object }) datasource!: AthenaSourceDto;

  get modifyExisting() {
    return this.datasource.id;
  }

  get tooltipListSchema() {
    let tooltipValue = '';
    if ('region' in this.datasource.params) {
      tooltipValue += 'The Region';
    }
    if ('datasource' in this.datasource.params) {
      tooltipValue += ', Datasource';
    }
    if ('s3OutputLocation' in this.datasource.params) {
      tooltipValue += ', s3OutputLocation';
    }
    if ('workgroup' in this.datasource.params) {
      tooltipValue += ', Workgroup';
    }
    if ('roleArn' in this.datasource.params) {
      tooltipValue += ', RoleArn';
    }
    tooltipValue += ' must be configured';
    return tooltipValue;
  }

  get isScanActive() {
    let host = true;
    if ('host' in this.datasource.params) {
      host = !!this.datasource.params.host;
    }
    let region = true;
    if ('region' in this.datasource.params) {
      region = !!this.datasource.params.region;
    }
    let datasource = true;
    if ('datasource' in this.datasource.params) {
      datasource = !!this.datasource.params.datasource;
    }
    let s3OutputLocation = true;
    if ('s3OutputLocation' in this.datasource.params) {
      s3OutputLocation = !!this.datasource.params.s3OutputLocation;
    }
    let workgroup = true;
    if ('workgroup' in this.datasource.params) {
      workgroup = !!this.datasource.params.workgroup;
    }
    let roleArn = true;
    if ('roleArn' in this.datasource.params) {
      roleArn = !!this.datasource.params.roleArn;
    }
    return !(host && region && datasource && s3OutputLocation && workgroup && roleArn);
  }
}
</script>
