<template lang="pug">
.input-cron
  v-text-field(
    ref="inputCron"
    v-bind="$attrs"
    v-on="$listeners"
    :rules="cronRules"
    :items="validCronItems"
    :return-object="false"
    :append-icon="appendIcon"
    @click:append-outer="openDialog"
    @click="closeMenu"
    @click:append="clear"
    :hint="hintLabel"
    append-outer-icon="icon-question-circle-outline"
    data-cy="cron"
    item-text="text" dense persistent-hint outlined)

    template(v-slot:label)
      .d-flex
        v-icon( class="mr-1" v-if="isNull" color="iconWarning" small ) icon-warning-fill
        span( :class="labelClass" ) {{ label }}

    template(v-if="canRemove" v-slot:prepend-item)
      v-list-item( ripple @mousedown.prevent @click='toggleNull' )
        v-list-item-content
          v-list-item-title {{ $t('cron.remove_existing') }}
      v-divider.mt-2

  ModalDialog( ref="modalDialog" :title="$t('cron.cron_syntax')" )

    template( v-slot:body )
      .mt-2 {{ $t('cron.a_cron_expression') }}
      pre.mt-2.pre {{ $t('cron.cron-text1') }}
      .mt-2 {{ $t('cron.for_example') }}
        code {{ $t('cron.cron-text2') }}
      .mt-2 {{ $t('cron.there_are_also_predefined_cron') }}
        v-data-table(dense :headers="headers" :items="items" item-key='name' hide-default-footer)
      .mt-2
        | {{ $t('cron.to_generate_cronjob') }}
        a(href='https://crontab.guru/' target='_blank')  {{ $t('cron.crontab_guru') }}

</template>

<script lang="ts">
import {
  Vue, Component, Prop, Ref,
} from 'vue-property-decorator';
import getEnv, { AppConfig } from '@/utils/env';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import { validateCron } from '@/utils/cron-validators';

@Component
export default class InputCron extends Vue {
  @Prop({ type: Boolean, default: false }) readonly isDatasource!: boolean;

  @Prop({ type: String, default: '' }) readonly hint!: string;

  cronHelp = false;

  cronItems = [
    { text: '@minutely', duration: 60 },
    { text: '@hourly', duration: 3600 },
    { text: '@daily', duration: 86400 },
    { text: '@weekly', duration: 604800 },
    { text: '@monthly', duration: 2419200 },
  ];

  get cronRules() {
    return [
      (v: string) => validateCron(v, this.minPeriod),
    ];
  }

  get hintLabel() {
    return this.hint || this.$t('cron.input_hint');
  }

  headers = [{ text: 'Entry', value: 'entry' }, { text: 'Description', value: 'desc' }, { text: 'Equivalent to', value: 'eqt' }];

  items = [{ entry: '@yearly (or @annually)', desc: this.$t('cron.run_once_a_year'), eqt: '0 0 1 1 *' },
  { entry: ' @monthly', desc: this.$t('cron.run_once_a_month'), eqt: '0 0 1 * *' },
  { entry: '@weekly', desc: this.$t('cron.run_once_a_week'), eqt: '0 0 * * 0' },
  { entry: '@daily (or @midnight)', desc: this.$t('cron.run_once_a_day'), eqt: '0 0 * * *' },
  { entry: '@hourly', desc: this.$t('cron.run_once_an_hour'), eqt: '0 * * * *' },
  { entry: '@minutely', desc: this.$t('cron.run_every_minute'), eqt: '0 * * * *' },
  ];

  get validCronItems() {
    return this.cronItems.filter((item) => item.duration >= this.minPeriod);
  }

  @Ref() modalDialog!: ModalDialog;

  @Ref()
  private readonly inputCron!: any;

  openDialog() {
    this.modalDialog.openDialog();
  }

  get isNull(): boolean {
    return this.$attrs.value === null;
  }

  get canRemove() {
    return 'can-remove' in this.$attrs;
  }

  get label(): string {
    return this.isNull ? 'Remove existing' : 'Schedule';
  }

  get labelClass(): string {
    return this.isNull ? 'warning--text' : '';
  }

  get appendIcon(): string {
    return this.isNull || this.$attrs.value?.length ? 'icon-dismiss' : '';
  }

  get minPeriod() {
    return this.isDatasource ? this.minDatasourcePeriod : this.minDefaultPeriod;
  }

  get minDefaultPeriod() {
    return Number(getEnv('VUE_APP_MIN_PERIOD') || '3600' as AppConfig['VUE_APP_MIN_PERIOD']);
  }

  get minDatasourcePeriod() {
    return Number(getEnv('VUE_APP_MIN_DATASOURCE_PERIOD') || '86400' as AppConfig['VUE_APP_MIN_DATASOURCE_PERIOD']);
  }

  clear() {
    this.$emit('input', undefined);
    this.closeMenu();
  }

  closeMenu() {
    if (this.inputCron.isMenuActive) setTimeout(this.inputCron.blur, 0);
  }

  toggleNull() {
    if (!this.isNull) this.$emit('input', null);
    else this.$emit('input', []);
    this.closeMenu();
  }
}
</script>

<style lang="scss" scoped>
::v-deep.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined {
  .v-input__append-outer {
    margin-top: 7px !important;
  }
}

.pre {
  background-color: var(--v-bgSecondary-base);
  padding: 1rem;
  border-radius: 0.25rem;
}
</style>
