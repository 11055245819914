import colors from '@/plugins/colors';
import Lineage, { InteractionEvents } from './Lineage';
import Node from './Node';
import * as CONSTANTS from './constants';

const lineageCreateShowFieldsIcons = (
  cardBody: d3.Selection<SVGGElement, Node, SVGGElement, unknown>,
  instance: Lineage,
) => {
  const showFields = cardBody
    .append('g')
    .attr('class', 'show_fields')
    .attr('transform', (d) => (d.data.attachedEntity
        ? `translate(210, ${
            CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT + CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT - 30
          })`
        : `translate(210, ${CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT - 30})`))

    .attr('cursor', 'pointer')
    .attr('visibility', (d) => {
      if (!d.data.accessAuthorized) return 'hidden';
      return d.hasChildren ? 'visible' : 'hidden';
    })
    .on('click', (e: Event, d) => {
      e.stopPropagation();
      instance.emit(InteractionEvents.FIELD_EXPAND_CLICKED, d);
    });

  showFields
    .append('circle')
    .attr('class', 'circle_show_fields')
    .attr('cx', 8)
    .attr('cy', 8)
    .attr('r', 8)
    .attr('fill', colors.grey.base);

  showFields
    .append('image')
    .attr('class', 'image_show_fields')
    .attr('x', 2)
    .attr('y', 2)
    .attr('color', colors.red.base)
    .attr('style', '--color_fill: #000')
    .attr('href', (d) => (d.childrenAreExpanded
        ? '/next/images/lineage/chevron-up.svg'
        : '/next/images/lineage/chevron-down.svg'))
    .attr('width', '12')
    .attr('height', '12');
  // .attr('transform', 'translate(100, 85)');
};

const lineageCreateShowFieldsIconsUpdate = (node: d3.Selection<d3.BaseType, Node, SVGGElement, unknown>) => {
  node
  .select('.image_show_fields')
  .attr('href', (d) => (d.childrenAreExpanded
      ? '/next/images/lineage/chevron-up.svg'
      : '/next/images/lineage/chevron-down.svg'));
  };

export {
  lineageCreateShowFieldsIcons,
  lineageCreateShowFieldsIconsUpdate,
};
