<template lang="pug">
div.asset-type
  v-icon.asset-type-icon( size="20" class="mr-1") {{ icon }}
  span( v-bind="$attrs" ) {{ facet.name }}
  </template>

<script lang="ts">
import {
  Vue, Component, VModel,
} from 'vue-property-decorator';
import { AssetSearchFilterElementDto } from '@/api';

@Component
export default class AssetTypeIcon extends Vue {
  @VModel({ type: Object }) facet!: AssetSearchFilterElementDto;

  get icon() {
    switch (this.facet.icon) {
      case AssetSearchFilterElementDto.icon.TABLE_AND_VIEW:
        return 'icon-data-table';
      case AssetSearchFilterElementDto.icon.DASHBOARD:
        return 'icon-chart-multiple';
      case AssetSearchFilterElementDto.icon.PIPELINE:
        return 'icon-pipeline-orchestrator';
      case AssetSearchFilterElementDto.icon.ML_MODEL:
        return 'icon-brain-circuit';
      case AssetSearchFilterElementDto.icon.GENERIC:
        return 'icon-grid';
      default:
        return null;
    }
  }
}
</script>

<style lang="scss" scoped>
.asset-type {
  margin-top: 2px;
}

.asset-type-icon {
  margin-bottom: 3px;
}
</style>
