/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BaseSearchFilterElementDto } from './BaseSearchFilterElementDto';

export type UsageSearchFilterElementDto = (BaseSearchFilterElementDto & {
    id?: UsageSearchFilterElementDto.id;
});

export namespace UsageSearchFilterElementDto {

    export enum id {
        UNSUPPORTED = 'UNSUPPORTED',
        LOW = 'LOW',
        MEDIUM = 'MEDIUM',
        HIGH = 'HIGH',
    }


}
