/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AlertingHookReferenceDto = {
    externalHook?: string;
    id: string;
    name: string;
    type: AlertingHookReferenceDto.type;
};

export namespace AlertingHookReferenceDto {

    export enum type {
        SLACK = 'SLACK',
        MAIL = 'MAIL',
        MS_TEAMS = 'MS_TEAMS',
    }


}
