/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type RuleTemplateDto = {
    description?: string;
    input?: Record<string, any>;
    label?: string;
    name: string;
    supportAsCodeYAMLConversion: boolean;
    target: RuleTemplateDto.target;
    testable: boolean;
    version: string;
};

export namespace RuleTemplateDto {

    export enum target {
        FIELD = 'FIELD',
        DATASET = 'DATASET',
        MULTIFIELD = 'MULTIFIELD',
    }


}
