/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GraphDto = {
    graphType?: GraphDto.graphType;
};

export namespace GraphDto {

    export enum graphType {
        DUPLICATE = 'DUPLICATE',
        DUPLICATE_COUNT = 'DUPLICATE_COUNT',
        DUPLICATE_PERCENTAGE = 'DUPLICATE_PERCENTAGE',
        NULL_COUNT = 'NULL_COUNT',
        NULL_PERCENTAGE = 'NULL_PERCENTAGE',
        COMPLETENESS = 'COMPLETENESS',
        FRESHNESS = 'FRESHNESS',
        METADATA_FRESHNESS = 'METADATA_FRESHNESS',
        NUMERICAL_TRANSFORMATION = 'NUMERICAL_TRANSFORMATION',
        DISTRIBUTION = 'DISTRIBUTION',
        MULTI_METRICS_GLOBAL_TIME_SERIES = 'MULTI_METRICS_GLOBAL_TIME_SERIES',
        MULTI_METRICS_DIFFERENCE_TIME_SERIES = 'MULTI_METRICS_DIFFERENCE_TIME_SERIES',
    }


}
