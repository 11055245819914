/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AssetDataQualityDto = {
    incidents?: Record<string, number>;
    qualityStatus: AssetDataQualityDto.qualityStatus;
    rules?: Record<string, number>;
};

export namespace AssetDataQualityDto {

    export enum qualityStatus {
        CRITICAL = 'CRITICAL',
        AT_RISK = 'AT_RISK',
        HEALTHY = 'HEALTHY',
        UNMONITORED = 'UNMONITORED',
        NOT_SUPPORTED = 'NOT_SUPPORTED',
    }


}
