<template lang="pug">
div
  span( v-if="isDisabled" ) {{ operator }}
  v-select( v-else :items="items" v-model="operator" :disabled="isDisabled" hide-details outlined dense v-bind="$attrs"
    v-on="$listeners" :menu-props="{ bottom: true, offsetY: true }"
  )
</template>

<script lang="ts">
import {
  Vue,
  Component,
  Model,
  Prop,
} from 'vue-property-decorator';

@Component
export default class ConditionalRulesConditionValueInput extends Vue {
  @Model('logicalOperator', String) logicalOperator!: string;

  @Prop({ type: Number, required: true }) level!: number;

  @Prop({ type: Number, required: true }) index!: number;

  get operator() {
    return this.isWhere ? 'Where' : this.logicalOperator;
  }

  set operator(value: string) {
    this.$emit('updateOperator', { level: this.level, value });
  }

  get isDisabled() {
    return this.index > 1 || (this.level === 0 && this.index === 0);
  }

  get isWhere() {
    return this.level === 0 && this.index === 0;
  }

  choices: any[] = [
    { text: 'And', value: 'AND' },
    { text: 'Or', value: 'OR' },
  ];

  where: any[] = [
    { text: 'Where', value: 'WHERE' },
  ];

  get items() {
    return this.isWhere ? this.where : this.choices;
  }
}
</script>
