<template lang="pug">
  v-item-group(mandatory)
      v-row
        v-col( cols="12" )
          v-select(
            :items="methodsArray"
            item-text="title"
            item-value="title"
            :menu-props="{ bottom: true, offsetY: true }"
            :value="selectedMethod" @change="change"
            :disabled="disabled"
            outlined dense
          )
</template>

<script lang="ts">
import { IDPJsonSchemaParamsDto } from '@/api';
import {
  Vue, Component, Model, Prop,
} from 'vue-property-decorator';

@Component
export default class RuleCategorySelector extends Vue {
  @Prop({ type: Object }) methods!: IDPJsonSchemaParamsDto['signInMethods'];

  @Model('input', String) selectedMethod!: string;

  @Prop({ type: Boolean }) readonly disabled!: boolean;

  get methodsArray() {
    return Object.keys(this.methods).map((key) => this.methods[key]);
  }

  change(value: number) {
    this.$emit('input', value);
  }
}
</script>
