/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeCompletenessMonitorParamsDto } from './AsCodeCompletenessMonitorParamsDto';
import type { AsCodeCustomMetricsMonitorParamsDto } from './AsCodeCustomMetricsMonitorParamsDto';
import type { AsCodeDatasetReferenceDto } from './AsCodeDatasetReferenceDto';
import type { AsCodeDistributionMonitorParamsDto } from './AsCodeDistributionMonitorParamsDto';
import type { AsCodeDuplicatesMonitorParamsDto } from './AsCodeDuplicatesMonitorParamsDto';
import type { AsCodeDynamicFieldProfilingMonitorParamsDto } from './AsCodeDynamicFieldProfilingMonitorParamsDto';
import type { AsCodeDynamicMetricMonitorParamsDto } from './AsCodeDynamicMetricMonitorParamsDto';
import type { AsCodeFieldFormatMonitorParamsDto } from './AsCodeFieldFormatMonitorParamsDto';
import type { AsCodeFieldInListConstraintMonitorParamsDto } from './AsCodeFieldInListConstraintMonitorParamsDto';
import type { AsCodeFieldUniquenessMonitorParamsDto } from './AsCodeFieldUniquenessMonitorParamsDto';
import type { AsCodeFreshnessMonitorParamsDto } from './AsCodeFreshnessMonitorParamsDto';
import type { AsCodeIncidentDto } from './AsCodeIncidentDto';
import type { AsCodeMetadataFreshnessMonitorParamsDto } from './AsCodeMetadataFreshnessMonitorParamsDto';
import type { AsCodeNotificationReferenceDto } from './AsCodeNotificationReferenceDto';
import type { AsCodeReferenceByIdOrNameDto } from './AsCodeReferenceByIdOrNameDto';
import type { AsCodeReferentialIntegrityMonitorParamsDto } from './AsCodeReferentialIntegrityMonitorParamsDto';
import type { AsCodeSchemaChangeMonitorParamsDto } from './AsCodeSchemaChangeMonitorParamsDto';
import type { AsCodeSqlMonitorParamsDto } from './AsCodeSqlMonitorParamsDto';
import type { AsCodeStaticCompletenessMonitorParamsDto } from './AsCodeStaticCompletenessMonitorParamsDto';
import type { AsCodeStaticFieldProfilingMonitorParamsDto } from './AsCodeStaticFieldProfilingMonitorParamsDto';
import type { AsCodeStaticMetricMonitorParamsDto } from './AsCodeStaticMetricMonitorParamsDto';
import type { AsCodeTagReferenceDto } from './AsCodeTagReferenceDto';

export type AsCodeMonitorDto = {
    datasets?: Array<AsCodeDatasetReferenceDto>;
    description?: string;
    friendlyId?: string;
    id?: string;
    incident: AsCodeIncidentDto;
    kind?: AsCodeMonitorDto.kind;
    name: string;
    notifications?: Array<AsCodeNotificationReferenceDto>;
    parameters: (AsCodeCompletenessMonitorParamsDto | AsCodeCustomMetricsMonitorParamsDto | AsCodeDistributionMonitorParamsDto | AsCodeDuplicatesMonitorParamsDto | AsCodeDynamicFieldProfilingMonitorParamsDto | AsCodeDynamicMetricMonitorParamsDto | AsCodeFieldFormatMonitorParamsDto | AsCodeFieldInListConstraintMonitorParamsDto | AsCodeFieldUniquenessMonitorParamsDto | AsCodeFreshnessMonitorParamsDto | AsCodeMetadataFreshnessMonitorParamsDto | AsCodeReferentialIntegrityMonitorParamsDto | AsCodeSchemaChangeMonitorParamsDto | AsCodeSqlMonitorParamsDto | AsCodeStaticCompletenessMonitorParamsDto | AsCodeStaticFieldProfilingMonitorParamsDto | AsCodeStaticMetricMonitorParamsDto);
    schedule?: string;
    scheduleTimezone?: string;
    tags?: Array<AsCodeTagReferenceDto>;
    terms?: Array<AsCodeReferenceByIdOrNameDto>;
    version?: number;
};

export namespace AsCodeMonitorDto {

    export enum kind {
        WORKSPACE = 'Workspace',
        MONITOR = 'Monitor',
        ASSET = 'Asset',
        SOURCE = 'Source',
        LINEAGE = 'Lineage',
    }


}
