import {
 Module, Mutation, VuexModule, config, Action,
} from 'vuex-module-decorators';
import { Route } from 'vue-router';

config.rawError = true;

export type Breadcrumb = {
  to: string;
  exact: boolean;
  replace: boolean;
  text: string;
};

@Module({ namespaced: true, name: 'breadcrumb' })
export default class uiModule extends VuexModule {
  breadcrumbs: Breadcrumb[] = [];

  get getBreadcrumbs() {
    return this.breadcrumbs;
  }

  @Mutation
  SET_BREADCRUMBS(breadcrumbs: Breadcrumb[]) {
    this.breadcrumbs = breadcrumbs;
  }

  @Mutation
  REPLACE(text: string) {
    const array: any = this.breadcrumbs;
    array[array.length - 1].text = text;
    this.breadcrumbs = array;
  }

  @Mutation
  RESET() {
    this.breadcrumbs = [];
  }

  @Action
  createBreadcrumbs({ to, from }: { to: Route; from: Route }) {
    const breadcrumbItems: Breadcrumb[] = [];

    const routes = to.matched.filter((_route) => !_route.meta.hideBreadCrumb);

    routes.forEach((_route, index) => {
      let text;
      if (_route.meta.breadCrumbFromParamName && _route.meta.params) {
        text = to.params.name ?? '';
      }
      let routePath: string;

      if (_route.meta.params && index === routes.length - 1) {
        routePath = to.path.replace(`:${_route.meta.params[0]}`, to.params[_route.meta.params[0]]);
      } else if (index === 0 && _route.path === '/dashboard') {
        routePath = '';
      } else if (index === 0 && _route.path === '/settings') {
        routePath = '';
      } else {
        routePath = _route.path;
      }
      breadcrumbItems.push({
        to: routePath,
        exact: index === routes.length - 1,
        replace: true,
        text: text ?? _route.meta.breadCrumb,
      });
    });

    // if we come from data-catalog and we go to data-catalog/assets/*
    // then we need to repplace the breadcrumb[0] with the full path
    // in order to be able to go back to data-catalog with all the query params
    if (from?.name === 'data-catalog' && to.name?.includes('data-catalog.asset')) {
      breadcrumbItems[0].to = from.fullPath;
    }

    // if we come from monitors and we go to monitors/rule/*
    // then we need to repplace the breadcrumb[0] with the full path
    // in order to be able to go back to monitors with all the query params
    if (from?.name === 'monitors' && to.name?.includes('monitors.rule')) {
      breadcrumbItems[0].to = from.fullPath;
    }

    this.SET_BREADCRUMBS(breadcrumbItems);
  }
}
