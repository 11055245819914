/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeMonitorParamsDto } from './AsCodeMonitorParamsDto';
import type { AsCodeReferentialIntegrityLeftDto } from './AsCodeReferentialIntegrityLeftDto';
import type { AsCodeReferentialIntegrityRightDto } from './AsCodeReferentialIntegrityRightDto';

export type AsCodeReferentialIntegrityMonitorParamsDto = (AsCodeMonitorParamsDto & {
    left?: AsCodeReferentialIntegrityLeftDto;
    matchType?: AsCodeReferentialIntegrityMonitorParamsDto.matchType;
    right?: AsCodeReferentialIntegrityRightDto;
} & {
    left: AsCodeReferentialIntegrityLeftDto;
    matchType: AsCodeReferentialIntegrityMonitorParamsDto.matchType;
    right: AsCodeReferentialIntegrityRightDto;
});

export namespace AsCodeReferentialIntegrityMonitorParamsDto {

    export enum matchType {
        FULL = 'Full',
        LEFT_ON_RIGHT = 'LeftOnRight',
        RIGHT_ON_LEFT = 'RightOnLeft',
    }


}
