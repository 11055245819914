<template lang="pug">
component(:is="component" v-bind="$attrs" v-on="$listeners" :datasets="datasets" )
</template>

<script lang="ts">
import ConditionalRulesConditionFieldInput from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Field-Input.vue';
import ConditionalRulesConditionValueInputString from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Value-Input/Conditional-Rules-Condition-Value-Input-String.vue';
import ConditionalRulesConditionValueInputNumber from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Value-Input/Conditional-Rules-Condition-Value-Input-Number.vue';
import ConditionalRulesConditionValueInputDate from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Value-Input/Conditional-Rules-Condition-Value-Input-Date.vue';
import ConditionalRulesConditionValueInputTime from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Value-Input/Conditional-Rules-Condition-Value-Input-Time.vue';
import ConditionalRulesConditionValueInputDateTime from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Value-Input/Conditional-Rules-Condition-Value-Input-DateTime.vue';
import ConditionalRulesConditionValueInputTimeWindow from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Value-Input/Conditional-Rules-Condition-Value-Input-Time-Window.vue';
import {
  Vue,
  Component,
  Prop,
Watch,
} from 'vue-property-decorator';
import * as ConditionalRules from '@/@types/conditional-rules';
import { RuleDto } from '@/api';
import log from '@/utils/console';

@Component
export default class ConditionalRulesConditionValueInput extends Vue {
  @Prop({ type: Object, required: true }) condition!: ConditionalRules.ConditionalRulesCondition;

  @Prop({ type: Array, required: true }) datasets!: RuleDto['datasets'];

  @Watch('condition.typology')
  // we have to initialize the value of the condition if the typology is changed
  // because it can be a string or an object, and as object it takes different shapes
  initializeFormat(typology: ConditionalRules.ConditionalRulesCondition['typology']) {
    log('typology', typology);
    if (typology === ConditionalRules.ConditionalRulesTypes.COLUMN) {
      if (typeof this.condition.value !== 'object') {
        log('was not object', typology);
        this.$emit('input', {
          type: '',
          name: '',
          id: '',
        });
        return;
      }
      if (!('type' in (this.condition.value as any))) {
        log('was wrong object', typology);
        this.$emit('input', {
          type: '',
          name: '',
          id: '',
        });
        return;
      }
    }
    if (typology === ConditionalRules.ConditionalRulesTypes.TIME_PERIOD) {
      if (typeof this.condition.value !== 'object') {
        log('was not object', typology);
        this.$emit('input', {
          time: '',
          timeUnit: '',
        });
        return;
      }
      if (!('time' in (this.condition.value as any))) {
        log('was wrong object', typology);
        this.$emit('input', {
          time: '',
          timeUnit: '',
        });
        return;
      }
    }
    if (typology === ConditionalRules.ConditionalRulesTypes.VALUE && typeof this.condition.value === 'object') {
      log('typology value', typology);
      this.$emit('input', '');
    }
  }

  get component() {
    switch (true) {
      case ConditionalRules.isNullOperator(this.condition.operator):
        return null;
      case this.condition.typology === ConditionalRules.ConditionalRulesTypes.TIME_PERIOD:
        return ConditionalRulesConditionValueInputTimeWindow;
      case this.condition.typology === ConditionalRules.ConditionalRulesTypes.COLUMN:
        return ConditionalRulesConditionFieldInput;
      case ConditionalRules.isBooleanType(this.condition.field.type):
        return null;
      case ConditionalRules.isNumericType(this.condition.field.type):
        return ConditionalRulesConditionValueInputNumber;
      case ConditionalRules.isDateTimeType(this.condition.field.type):
        return ConditionalRulesConditionValueInputDateTime;
      case ConditionalRules.isDateType(this.condition.field.type):
        return ConditionalRulesConditionValueInputDate;
      case ConditionalRules.isTimeType(this.condition.field.type):
        return ConditionalRulesConditionValueInputTime;
      default:
        return ConditionalRulesConditionValueInputString;
    }
  }
}
</script>
