<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { watch } from 'vue';
import { VSelect } from 'vuetify/lib/components';
import { FieldSearchResultDto } from '@/api/models/FieldSearchResultDto';

interface Props {
  items?: FieldSearchResultDto[],
  loading?: boolean,
  value?: string | string[],
  placeholder?: string,
  label?: string,
  allowAuto?: boolean,
  multiple?: boolean,
  rules?: (((input: any) => boolean | string) | boolean | string)[],
}
const props = defineProps({
  items: { default: () => [] },
  loading: { type: Boolean, default: false },
  value: null,
  placeholder: null,
  label: null,
  allowAuto: { type: Boolean, default: false },
  multiple: { type: Boolean, default: false },
  rules: { default: () => [] }
});
interface Emit {
  (event: 'input', value: string): void
}
const emit = defineEmits(["input"]);

const selectRef = _ref<(InstanceType<typeof VSelect> & { validate: () => boolean }) | null>(null);

const getIcon = (typeCategory: FieldSearchResultDto.typeCategory | undefined) => {
  switch (typeCategory) {
    case FieldSearchResultDto.typeCategory.INTEGER:
      return 'icon-number-symbol';
    case FieldSearchResultDto.typeCategory.FLOAT:
      return 'icon-number-float';
    case FieldSearchResultDto.typeCategory.DATE:
      return 'icon-calendar';
    case FieldSearchResultDto.typeCategory.TIME:
      return 'icon-clock';
    case FieldSearchResultDto.typeCategory.DATETIME:
      return 'icon-calendar-clock';
    case FieldSearchResultDto.typeCategory.STRING:
      return 'icon-text';
    case FieldSearchResultDto.typeCategory.ARRAY:
      return 'icon-blocks';
    case FieldSearchResultDto.typeCategory.JSON:
      return 'icon-braces';
    case FieldSearchResultDto.typeCategory.BOOLEAN:
      return 'icon-toggle';
    case FieldSearchResultDto.typeCategory.STRUCT:
      return 'icon-data-schema';
    case FieldSearchResultDto.typeCategory.BINARY:
      return 'icon-document-binary';
    case FieldSearchResultDto.typeCategory.MAP:
      return 'icon-braces';
    default:
      return null;
  }
};

const allItems = _computed(() => {
  let result: { text?: string, value?: string, divider?: boolean, icon?: string | null }[] = props.items
    .filter((t) => t.id) // VJSF automatically adds the current value into the list of possible items, which is not what we want at all
    .map((item) => ({ text: item.monitoringName, value: item.monitoringName, icon: getIcon(item.typeCategory) }));

  if (props.allowAuto) result = [{ text: 'Auto-detected', value: 'auto' }, { divider: true }, ...result];
  return result;
});

const allRules = _computed(() => [...props.rules, (value: string | string[]) => {
  const allowedValues = allItems.value.map((item) => item.value);
  if (Array.isArray(value)) {
    return value.every((val) => allowedValues.includes(val));
  }
  return allowedValues.includes(value);
}]);

watch(() => allItems.value, () => {
  // Validation is not done by default on allItems update
  selectRef.value?.validate();
});
</script>

<template lang="pug">
v-select(ref="selectRef" :placeholder="placeholder" :rules="allRules" :label="label" :items="allItems" :loading="loading" :multiple="multiple" :value="value" @input="emit('input', $event)" outlined dense menu-props="offset-y")
  template(#item="{ item, attrs, on }")
    .d-flex.align-center
      v-simple-checkbox(v-if="multiple" :value="attrs.inputValue" @click="on.click")
      SIcon.mr-1(v-if="item.icon" :icon="item.icon")
      | {{ item.text }}
  template(#selection="{ item }")
    .d-flex.align-center
      SIcon.mr-1(v-if="item.icon" :icon="item.icon")
      .mr-2 {{ item.text }}
</template>
