/**
 * Capitalizes the string
 * @param {string} string - String to capitalize
 * @return {string} - Capitalized string
 */
export default (string: string | undefined) => (string ? `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}` : '');

declare module 'vue/types/vue' {
  interface Vue {
    capitalizeFirst: (string: string | undefined) => undefined | string;
  }
}
