<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="baseUrlRules"
    label='Base Url'
    data-cy="baseUrl"
    dense clearable outlined required)

</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputBaseUrl extends Vue {
  baseUrlRules = [(v: string) => isConvertibleToNotBlankString(v) || 'Base Url is required'];
}
</script>
