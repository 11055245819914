import { TagDto, TagService, TermService } from '@/api';
import {
  Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';

type SearchParameters = Parameters<typeof TagService.getAllTag>[0];

@Module({ namespaced: true, name: 'glossary' })
export default class BusinessGlossaryModule extends VuexModule {
    searchParameters: SearchParameters = {
      page: 1,
      itemsPerPage: 10,
      textSearch: '',
      sort: [],
      type: ['TERM'],
    };

    terms: TagDto[] = [];

    selectedTerm: TagDto | null = null;

    loading = false;

    count = 100;

    overallCount = 100;

    get options() {
      return {
        itemsPerPage: this.searchParameters.itemsPerPage,
        page: this.searchParameters.page,
        sort: this.searchParameters.sort,
      };
    }

    get getSort() {
      return this.searchParameters.sort;
    }

    get searchString(): string | undefined { return this.searchParameters.textSearch; }

    get type(): string[] | undefined { return this.searchParameters.type; }

    get getTerms(): TagDto[] { return this.terms; }

    get getLoading(): boolean { return this.loading; }

    get getCount(): number { return this.count; }

    get getOverallCount(): number { return this.overallCount; }

    @Mutation
    setSort(sort: string[] | undefined) {
      this.searchParameters.sort = sort;
    }

    @Mutation
    setOptions({ page, itemsPerPage, sort }: {
      page: number | undefined,
      itemsPerPage: number | undefined
      sort: string[] | undefined}) {
      this.searchParameters.page = page;
      this.searchParameters.itemsPerPage = itemsPerPage;
      this.searchParameters.sort = sort;
    }

    @Mutation
    removeSelectedTerm() {
      this.selectedTerm = null;
    }

    @Mutation
    setSelectedTerm(term: TagDto) {
      this.selectedTerm = term;
    }

    @Mutation
    setSearchString(searchString: string | undefined) {
      this.searchParameters.textSearch = searchString;
    }

    @Mutation
    setType(_type: ('GENERIC' | 'HIDDEN_DATA_CLASSIFICATION' | 'VISIBLE_DATA_CLASSIFICATION' | 'TERM' | 'BIGQUERY_EXTERNAL' | 'SNOWFLAKE_EXTERNAL')[] | undefined) {
      this.searchParameters.type = _type;
    }

    @Mutation
    setTerms(terms: TagDto[]) {
      this.terms = terms;
    }

    @Mutation
    setLoading(loading: boolean) {
      this.loading = loading;
    }

    @Mutation
    setCount(count: number) {
      this.count = count;
    }

    @Mutation
    setOverallCount(overallElements: number) {
      this.overallCount = overallElements;
    }

    @Mutation
    resetState() {
      this.count = 0;
      this.terms = [];
    }

    @Action
    async updateSearch() {
      this.searchParameters.page = 1;
      await this.searchTerms();
    }

    @Action
    async searchTerms() {
      this.setLoading(true);
      const { data, filteredElements, overallElements } = await TermService.getAllTermsWithCountEnhanced(this.searchParameters);
      this.setCount(filteredElements!);
      this.setOverallCount(overallElements!);
      this.setTerms(data!);
      this.setLoading(false);
    }
}
