<template lang="pug">
v-select.mb-0(
  :items="items"
  v-model="model"
  outlined
  dense
  :rules="rules"
  return-object
  item-value="id"
  item-text="uniqueName"
  :menu-props="{ bottom: true, offsetY: true }")

  template(v-slot:item="{ item }")
    .d-flex.pa-0
      PositionDot( :index="getIndex(item)" class="mr-1" )
      span {{ item.uniqueName }}

</template>

<script lang="ts">
import {
  Vue, Component, Prop, VModel,
} from 'vue-property-decorator';
import { DatasetService, FieldSearchResultDto, RuleDto } from '@/api';
import * as ConditionalRulesTypes from '@/@types/conditional-rules';

@Component
export default class NoCodeConditionFieldInput extends Vue {
  @VModel({ type: Object }) field!: ConditionalRulesTypes.ConditionalRulesField;

  @Prop({ type: Array, required: true }) datasets!: RuleDto['datasets'];

  @Prop({ type: Number, required: false }) id!: number;

  items: FieldSearchResultDto[] = [];

  get model() {
    return this.field;
  }

  set model(value: Pick<FieldSearchResultDto, 'id' | 'uniqueName' | 'displayType'>) {
    this.field = {
      id: value.id!,
      name: value.uniqueName!,
      type: value.displayType!,
    };
  }

  rules = [
    (v: any) => !!v.name || 'Field is required',
  ];

  getIndex(item: FieldSearchResultDto) {
    return this.datasets!.length > 1 ? this.datasets!.findIndex(({ id }) => id === item.datasetId) : this.id;
  }

  async mounted() {
    if (!this.datasets) return;
    const promises = this.datasets.map(({ id }) => DatasetService.getDatasetFields({
      id,
      type: 'any',
    }));
    const results = await Promise.all(promises);
    this.items = results.flat();
  }
}
</script>
