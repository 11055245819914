<template lang="pug">
  v-select(
    v-bind="$attrs"
    :items="historyDaysList"
    v-model="historyDays"
    outlined
    dense
    :menu-props="{ bottom: true, offsetY: true }"
    class="days-selector"
  )
</template>

<script lang="ts">
import { Vue, Component, VModel } from 'vue-property-decorator';

@Component
export default class DaysSelector extends Vue {
  @VModel({ type: Number }) historyDays!: number;

  historyDaysList = [
    { value: 7, text: `7 ${this.$t('dashboard.days')}` },
    { value: 14, text: `14 ${this.$t('dashboard.days')}` },
    { value: 30, text: `30 ${this.$t('dashboard.days')}` },
    { value: 90, text: `90 ${this.$t('dashboard.days')}` },
  ];
}
</script>

<style scoped>
  .days-selector {
    width: 130px;
    position: absolute;
    top: 0;
    right:10%;
    z-index:1;
  }
</style>
