import api from '@/api/axios';

const findAllSecrets = ({ commit, dispatch }) => {
  api
    .get('/secrets', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      },
    })
    .then((response) => {
      commit('secret/SET_SECRETS', response.data, {
        root: true,
      });
    })
    .catch(() => {
    });
};

const createSecret = ({ dispatch }, secret) => {
  api
    .post('/secret', secret, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      },
    })
    .then(() => {
      setTimeout(() => {
        dispatch('secret/findAllSecrets', null, { root: true });
      }, 2000);
    })
    .catch(() => {
    });
};

const updateSecret = ({ dispatch }, secret) => {
  api
    .put(`/secret?name=${encodeURIComponent(secret.name)}`, secret, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      },
    })
    .then(() => {
      setTimeout(() => {
        dispatch('secret/findAllSecrets', null, { root: true });
      }, 2000);
    })
    .catch(() => {
    });
};

const getSecretValue = ({ dispatch }, secret) => {
  api
    .get(`/secret?name=${encodeURIComponent(secret.name)}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      },
    })
    .then((result) => {
      // eslint-disable-next-line no-param-reassign
      secret.value = result.data.value;
    })
    .catch(() => {
    });
};

const deleteSecret = ({ dispatch }, secret) => {
  api
    .delete(`/secret?name=${encodeURIComponent(secret.name)}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      },
    })
    .then(() => {
      setTimeout(() => {
        dispatch('secret/findAllSecrets', null, { root: true });
      }, 2000);
    })
    .catch(() => {
    });
};

export default {
  findAllSecrets,
  createSecret,
  updateSecret,
  deleteSecret,
  getSecretValue,
};
