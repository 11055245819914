<template lang="pug">
div.chart-container
  v-chart( v-if="hasDtos"
    class="chart"
    ref="chart"
    :option="options"
    :autoresize="true"
    :theme="$vuetify.theme.dark ? 'dark' : 'light'" )
  span( v-else class="grey--text" ) {{ $t('rules.no_data_for_rule') }}
  v-btn(text outlined color="secondary" class="custom-secondary btn-reset" small @click="restore")
    v-icon(left) icon-arrow-refresh
    .ml-1 {{ $t('charts.reset') }}
  v-btn(color="secondary" class="custom-secondary btn-image" text outlined small @click="downloadImage")
    v-icon(left) icon-arrow-download
    .ml-1 {{ $t('charts.download') }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { use } from 'echarts/core';
import { BarChart } from 'echarts/charts';
import VChart from 'vue-echarts';
import { HistogramGraphDto } from '@/api';

use([
  BarChart,
]);

@Component({
  components: {
    VChart,
  },
})
export default class Distribution extends Vue {
  @Prop({ type: Object, required: true }) graph!: HistogramGraphDto;

  @Prop({ type: String, required: true }) id!: string;

  get hasDtos() {
    return !!this.graph?.graphPoints?.length;
  }

  get xAxis() {
    return this.graph?.graphPoints?.map((point: any) => point.xaxisName);
  }

  get previousPoints() {
    return this.graph?.graphPoints?.map((point: any) => point.yvalues[1]);
  }

  get currentPoints() {
    return this.graph?.graphPoints?.map((point: any) => point.yvalues[0]);
  }

  get highlightedPoints() {
    return this.graph?.graphPoints?.map((point: any) => point.ychange);
  }

  get anomalyLabels() {
    return this.graph?.graphPoints?.map((point: any) => point.yanomaly);
  }

  cutString(str: string | undefined) {
    return str && str.length >= 50 ? `${str.substring(0, 50)}...` : str;
  }

  get currentLabel() {
    return this.cutString(this.graph?.currentLabel) ?? 'Current : -';
  }

  get previousLabel() {
    return this.cutString(this.graph?.previousLabel) ?? 'Previous: -';
  }

  get legendData() {
    return [this.previousLabel, this.currentLabel];
  }

  get options() {
    return {
      textStyle: {
        fontFamily: 'DM Sans',
      },
      title: {
        text: 'Distribution',
        subtext: 'Comparing the last and previous distribution',
        textStyle: {
          fontFamily: 'DM Sans',
          fontSize: '14px',
          fontWeight: '500',
          color: '#101828',
        },
        padding: 12,
        subtextStyle: {
          fontFamily: 'DM Sans',
          fontSize: '14px',
          fontWeight: '400',
          color: '#667085',
        },
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          label: {
            show: true,
          },
        },
      },
      grid: {
        left: '2%',
        right: '3%',
        top: '80px',
        bottom: '62px',
        containLabel: true,
      },
      legend: {
        data: this.legendData,
        orient: 'horizontal',
        itemGap: 20,
        textStyle: {
          fontWeight: 'normal',
          fontSize: 12,
        },
      },
      xAxis: {
        type: 'category',
        data: this.xAxis,
        axisLabel: {
          interval: 0,
          hideOverlap: true,
          width: 100,
          overflow: 'truncate',
          fontFamily: 'DM Sans',
          fontSize: '12px',
          fontWeight: '500',
          color: '#101828',
        },
        axisPointer: {
          label: {
            formatter: '{value}',
            fontFamily: 'DM Sans',
            fontSize: '12px',
            fontWeight: '500',
            color: '#101828',
          },
        },
      },
      yAxis: {
        axisLabel: {
          interval: 0,
          hideOverlap: true,
          width: 100,
          overflow: 'truncate',
          fontFamily: 'DM Sans',
          fontSize: '12px',
          fontWeight: '500',
          color: '#101828',
          formatter: '{value}',
        },
        axisPointer: {
          label: {
            formatter: '{value}',
          },
        },
      },
      toolbox: {
        show: false,
        right: 16,
        padding: 12,
        feature: {
          restore: {},
          saveAsImage: {
            name: 'distribution',
          },
        },
      },
      dataZoom: [
        {
          realtime: false,
          brushSelect: false,
          show: true,
          start: 0,
          end: 100,
          fillerColor: 'transparent',
          dataBackground: {
            lineStyle: {
              color: '#f2f4f7',
              opacity: '0.8',
              cap: 'square',
            },
            areaStyle: {
              color: '#f2f4f7',
              opacity: '0.8',
            },
          },
          selectedDataBackground: {
            lineStyle: {
              color: '#d0d5dd',
              opacity: '0.8',
              cap: 'square',
            },
            areaStyle: {
              color: '#d0d5dd',
              opacity: '0.8',
              shadowColor: {
                color: 'red',
              },
            },
          },
        },
        {
          type: 'inside',
          zoomLock: false,
          start: 94,
          end: 100,
          animation: false,
          zoomOnMouseWheel: true,
          moveOnMouseMove: true,
          dataBackground: {
            lineStyle: {
              color: '#f2f4f7',
              opacity: '0.8',
              cap: 'square',
            },
            areaStyle: {
              color: '#f2f4f7',
              opacity: '0.8',
            },
          },
          selectedDataBackground: {
            lineStyle: {
              color: '#d0d5dd',
              opacity: '0.8',
              cap: 'square',
            },
            areaStyle: {
              color: '#d0d5dd',
              opacity: '0.8',
              shadowColor: {
                color: 'red',
              },
            },
          },
        },
      ],
      series: [
        {
          name: this.previousLabel,
          type: 'bar',
          data: this.previousPoints,
          label: {
            show: true,
            rotate: 90,
          },
          labelLayout: {
            hideOverlap: true,
          },
          itemStyle: {
            color: '#C0CEEB',
          },
        },
        {
          name: this.currentLabel,
          type: 'bar',
          data: this.currentPoints,
          label: {
            show: true,
            rotate: 90,
          },
          labelLayout: {
            hideOverlap: true,
          },
         itemStyle: {
            color: '#113E60',
          },
        },
        {
          name: 'Distribution deviation',
          type: 'scatter',
          data: this.anomalyLabels,
          itemStyle: {
            color: '#ff9f59',
            opacity: 1,
          },
          tooltip: {
            show: false,
          },
          symbol: 'image:///img/icons/material-icons/error_red_24dp.svg',
          symbolOffset: [0, -100],
          symbolSize: 30,
        },
        {
          name: 'Anomaly',
          type: 'scatter',
          data: this.anomalyLabels,
          itemStyle: {
            color: '#ff0000',
            opacity: 1,
          },
        },
      ],
    };
  }

  restore() {
    const { chart }: any = this.$refs;
    chart.dispatchAction({ type: 'restore' });
  }

  downloadImage() {
    const { chart }: any = this.$refs;
    const a = document.createElement('a');
    a.href = chart.getDataURL({
      pixelRatio: 2,
      backgroundColor: '#fff',
    });
    a.download = 'chart.png';
    a.click();
  }
}
</script>

<style lang="scss" scoped>
.chart-container {
  position: relative;
}

.chart {
  height: 390px;
}

.btn-reset {
  position:absolute;
  right: 192px;
  top: 10px;
  border: 1px solid black;
}
.btn-image {
  position:absolute;
  right: 30px;
  top: 10px;
  border: 1px solid black;
}
@media screen and (max-width: 790px) {
  .btn-reset, .btn-image {
    top: -30px;
  }
}
</style>
