<script setup lang="ts">import { computed as _computed } from 'vue';

import __MACROS_useVModel from "/vue-macros/define-models/use-vmodel";
import { BaseSearchFilterDto } from '@/api';

interface Facets {
  tag?: string[];
  ingestion?: string[];
  technology?: string[];
}
interface DatasourcesSearchFilterProps {
  facets: BaseSearchFilterDto[]
}
interface DatasourcesSearchFilterEmits {
  (event: 'resetFilters'): void
}

const { value: filterValues } = __MACROS_useVModel(["value", undefined, "input"]);
const props = defineProps({
  facets: null,
  value: null
});
const emits = defineEmits(["resetFilters", "input"]);

const hasFilters = _computed(() => filterValues.value && Object.values(filterValues.value).flat().length);

const resetSearchParameters = () => {
  emits('resetFilters');
};

const input = (query: string | undefined, value: string[]) => {
  if (!query) return;
  filterValues.value[query as keyof Facets] = value;
};

</script>

<template lang="pug">
#datasources-search-filter

  FilterReset( :filterQuantity="hasFilters" @reset="resetSearchParameters" )

  TreeCheckboxSelectorNext(:filter="props.facets[0]" :value="filterValues.technology" @input="input(props.facets[0].query, $event)" )

  v-divider.my-4
  b( class="d-block mb-2" )
    v-icon(class="mr-1") icon-filter
    span.text-capitalize {{ $t('common.words.tags') }}
  TagSelector.mb-4( v-model="filterValues.tag" :return-object="false")

  TreeCheckboxSelectorNext( :filter="props.facets[1]" :value="filterValues.ingestion" @input="input(props.facets[1].query, $event)" )

</template>

<style lang="scss" scoped>
#monitors-search-filter {
  position: sticky;
  top: 100px;
}
</style>
