/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Tags of the source. A tag can either be referenced by its id or its name or its name and kind.
 */
export type PublicTagReferenceDto = {
    /**
     * Id of the referenced object
     */
    id?: string;
    /**
     * Type of the referenced tag
     */
    kind?: PublicTagReferenceDto.kind;
    /**
     * Name of the referenced object
     */
    name?: string;
};

export namespace PublicTagReferenceDto {

    /**
     * Type of the referenced tag
     */
    export enum kind {
        TAG = 'Tag',
        CLASSIFICATION = 'Classification',
    }


}
