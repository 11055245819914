import * as d3 from 'd3';
import colors from '@/plugins/colors';
import { LineageUrnDto } from '@/api';
import Link from './Link';
import * as CONSTANTS from './constants';
import highlightConnectedNeighbors from './highlightConnectedNeighbors';
import resetHighlighting from './resetHighlighting';
import createLinkDataUrn from './createLinkDataUrn';
import buildLink from './buildLink';

const lineageCreateFieldLinks = (
  linksGroup: d3.Selection<SVGGElement, unknown, null, undefined>,
  fieldLinks: Link[],
) => {
  const _links = linksGroup.selectAll('.field-link').data(fieldLinks, (d: any) => d.id);

  const nodeEnter = _links
    .enter()
    .append('g')
    .attr('class', 'field-link')
    .attr('data-urn', (link: Link) => createLinkDataUrn(link));

  const pathsEnter = nodeEnter
    .append('path')
    .attr('highlightable', true)
    .attr('d', (link: Link) => buildLink(link))
    .on('mouseover', (event, link: Link) => highlightConnectedNeighbors(event, link.connectedElementsUrnData))
    .on('mouseleave', () => resetHighlighting());

  pathsEnter.each((link: Link, i, _node) => {
    const path = d3.select(_node[i]);
    const totalLength = path.node()!.getTotalLength();

    if (link.creationMethod === LineageUrnDto.creationMethod.BARRACUDA_LINEAGE_EXTRACTION) {
      path
        .attr('fill', 'transparent')
        .attr('stroke-dasharray', `${totalLength} ${totalLength}`)
        .attr('stroke-dashoffset', totalLength)
        .attr('stroke', colors.grey.lighten3)
        .attr('stroke-width', 0)
        .transition()
        .duration(CONSTANTS.ANIMATION_DURATION)
        .ease(d3.easeLinear)
        .attr('stroke-width', 1)
        .attr('stroke-dashoffset', 0);
    } else {
      // just dashed line
      path
        .attr('fill', 'transparent')
        .attr('stroke-dasharray', CONSTANTS.DASH_SIZE)
        .attr('stroke-dashoffset', 'none')
        .attr('stroke', colors.grey.lighten3)
        .attr('stroke-width', 1)
        .transition()
        .duration(CONSTANTS.ANIMATION_DURATION)
        .ease(d3.easeLinear)
        .attr('stroke-dashoffset', 0);
    }
  });

  _links
    .transition()
    .select('path')
    .attr('d', (link: Link) => buildLink(link))
    .attr('stroke-dasharray', (link: Link) => (link.creationMethod === LineageUrnDto.creationMethod.BARRACUDA_LINEAGE_EXTRACTION ? 'none' : CONSTANTS.DASH_SIZE))
    .attr('stroke-dashoffset', 'none');

    _links.exit().remove();
};

export default lineageCreateFieldLinks;
