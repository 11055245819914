/* eslint-disable vue/multi-word-component-names */
import Vue from 'vue';

// COMMON
import TreeSelect from '@riophae/vue-treeselect';
import VjsfMonitoringDatasetFieldSelector from '@/components/VjsfMonitoringDatasetFieldSelector.vue';
import VjsfMonitoringCalendarSelector from '@/components/VjsfMonitoringCalendarSelector.vue';
import SButton from '@/components/SButton.vue';
import SSplitButton from '@/components/SSplitButton.vue';
import SIcon from '@/components/SIcon.vue';
import SLabel from '@/components/SLabel.vue';
import SCard from '@/components/SCard.vue';
import SModal from '@/components/SModal.vue';
import SMenuButton from '@/components/SMenuButton.vue';
import SThreeDotMenu from '@/components/SThreeDotMenu.vue';
import HtmlView from '@/components/html-view/Html-View.vue';
import Usage from '@/components/charts/Chart-Usage.vue';
import Tag from '@/components/tags/Tag.vue';
import Tags from '@/components/tags/Tags.vue';
import Term from '@/components/terms/Term.vue';
import Terms from '@/components/terms/Terms.vue';
import Owner from '@/components/owners/Owner.vue';
import Owners from '@/components/owners/Owners.vue';
import HealthDot from '@/components/icons/Health-Dot.vue';
import HealthDots from '@/components/icons/Health-Dots.vue';
import QualityStatus from '@/components/quality-status/Quality-Status.vue';
import DataUsage from '@/components/usage/Usage.vue';
import DataUsageExtended from '@/components/usage/Usage-Extended.vue';
import Severity from '@/components/severity/Severity.vue';
import PlatformIcon from '@/components/icons/Platform-Icon.vue';
import DateTooltip from '@/components/date-tooltip/Date-Tooltip.vue';
import DomainsTooltip from '@/components/domains-tooltip/Domains-Tooltip.vue';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import ConfirmationDialog from '@/components/confirmation-dialog/Confirmation-Dialog.vue';
import Avatar from '@/components/avatar/Avatar.vue';
import AvatarList from '@/components/avatar-list/Avatar-List.vue';
import EditDialog from '@/components/edit-dialog/Edit-Dialog.vue';
import SqlDialog from '@/components/sql-dialog/Sql-Dialog.vue';
import DbtDescription from '@/components/texts/Dbt-Description.vue';
import SourceDescription from '@/components/texts/Source-Description.vue';
import EntityPreviewCard from '@/components/cards/entity-preview-card/Entity-Preview-Card.vue';
import FailingRowsDialog from '@/components/failing-rows-dialog/Failing-Rows-Dialog.vue';
import Sso from '@/components/sso/Sso.vue';
import RuleCategoryCard from '@/components/cards/rule-category-card/Rule-Category-Card.vue';
import SpConfig from '@/components/sp-config/SP-Config.vue';
import VNotification from '@/components/notification/Notification.vue';
import DataTableGenericPlaceholder from '@/components/placeholders/Data-Table-Generic-Placeholder.vue';
import NoDataAvailablePlaceholder from '@/components/placeholders/No-Data-Available-Placeholder.vue';
import Bookmark from '@/components/bookmark/Bookmark.vue';
import DataSourcePlaceholder from '@/components/placeholders/Data-Source-Placeholder.vue';
import UserSetTimezone from '@/components/user-set-timezone/User-Set-Timezone.vue';
import FilterReset from '@/components/filter-reset/Filter-Reset.vue';
import NavigationDrawerItem from '@/components/navigation-drawer/Navigation-Drawer-Item.vue';
import Logo from '@/components/logo/Logo.vue';
import AssetTypeIcon from '@/components/icons/Asset-Type-Icon.vue';
import TableIcon from '@/components/icons/Table-Icon.vue';
import DatasourceIcon from '@/components/icons/Datasource-Icon.vue';
import IngestionTypeIcon from '@/components/icons/Ingestion-Type-Icon.vue';
import TimezoneIcon from '@/components/icons/Timezone-Icon.vue';
import Shortcuts from '@/components/shortcuts/Shortcuts.vue';
import PositionDot from '@/components/icons/Position-Dot.vue';
import RunStatus from '@/components/icons/Run-Status.vue';
import ExternalDescription from '@/components/external-descriptions/External-Description.vue';
import ExternalDescriptions from '@/components/external-descriptions/External-Descriptions.vue';
import FieldsSearchMatch from '@/components/fields-search-match/Fields-Search-Match.vue';
import EmptyDataPlaceholder from '@/components/placeholders/Empty-Data-Placeholder.vue';

// NESTED FIELDS
import NestedSheet from '@/components/nested-sheet/Nested-Sheet.vue';
import NestedSheetSimpleTable from '@/components/nested-sheet/Nested-Sheet-Simple-Table.vue';
import TreeNestedSelector from '@/components/selectors/Tree-Nested-Selector/Tree-Nested-Selector.vue';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import NestedSchema from '@/components/nested-schema/Nested-Schema.vue';
import NestedSchemaItem from '@/components/nested-schema/Nested-Schema-Item.vue';

// APP
import ThemeToggle from '@/components/theme-toggle/Theme-Toggle.vue';
import SearchBar from '@/components/search-bar/Search-Bar.vue';
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import ResetPassword from '@/components/reset-password/Reset-Password.vue';

// DESIGN
import AnimatedBackground from '@/components/animated-background/AnimatedBackground.vue';

// DASHBOARD NEXT
import DashboardIncidentsAssigned from '@/components/dashboard/dashboard-incidents/Dashboard-Incidents-Assigned.vue';
import DashboardIncidentsOpen from '@/components/dashboard/dashboard-incidents/Dashboard-Incidents-Open.vue';
import DashboardIncidentsOpenCard from '@/components/dashboard/dashboard-incidents/Dashboard-Incidents-Open-Card.vue';
import DashboardIncidentsLatest from '@/components/dashboard/dashboard-incidents/Dashboard-Incidents-Latest.vue';
import DashboardDataQualityRules from '@/components/dashboard/dashboard-data/Dashboard-Data-Quality-Rules.vue';
import DashboardDataRuleActivity from '@/components/dashboard/dashboard-data/Dashboard-Data-Rule-Activity.vue';
import DashboardDataRuleActivityCard from '@/components/dashboard/dashboard-data/Dashboard-Data-Rule-Activity-Card.vue';
import DashboardDataStackIntegration from '@/components/dashboard/dashboard-data/Dashboard-Data-Stack-Integration.vue';
import DashboardDataStackIntegrationRow from '@/components/dashboard/dashboard-data/Dashboard-Data-Stack-Integration-Row.vue';

import DashboardPinned from '@/components/dashboard/Dashboard-Pinned/Dashboard-Pinned.vue';
import DashboardPinnedCard from '@/components/dashboard/Dashboard-Pinned/Dashboard-Pinned-Card.vue';
import DashboardTitle from '@/components/dashboard/Dashboard-Title.vue';

import DashboardAiAssistantMonitors
  from '@/components/dashboard/Dashboard-AI-Assistant/Dashboard-AI-Assistant-Monitors.vue';
import DashboardAiAssistantMonitorsCard
  from '@/components/dashboard/Dashboard-AI-Assistant/Dashboard-AI-Assistant-Monitors-Card.vue';

import BookmarkCard from '@/components/cards/bookmark-card/Bookmark-Card.vue';

import DashboardFilters from '@/components/dashboard/Dashboard-Filters.vue';

// CHARTS
import ChartLatestIncidents from '@/components/charts/Chart-Latest-Incidents.vue';
import ChartMonitors from '@/components/charts/Chart-Monitors.vue';

// CATALOG
import CatalogSearch from '@/components/catalog/Catalog-Search.vue';
import Essentials from '@/components/essentials/Essentials.vue';
import CatalogImport from '@/components/catalog-import/Catalog-Import.vue';
import CatalogSort from '@/components/catalog/Catalog-Sort.vue';
import CatalogResults from '@/components/catalog/Catalog-Results.vue';
import CatalogFilters from '@/components/catalog/Catalog-Filters.vue';
import CatalogResultsCard from '@/components/catalog/Catalog-Results-Card.vue';
import AssetTabOverview from '@/components/asset/tabs/Asset-Tab-Overview.vue';

// ASSETS
import AssetIconName from '@/components/asset/info/Asset-Overview-Info-Icon-Name.vue';
import AssetInfoActions from '@/components/asset/info/Asset-Overview-Info-Actions.vue';
import AssetOverviewDetail from '@/components/asset/overview/Asset-Overview-Detail.vue';
import AssetDataQualityIncidents from '@/components/asset/data-quality/data-quality-incidents/Asset-Data-Quality-Incidents.vue';
import AssetDataQualityMonitors from '@/components/asset/data-quality/data-quality-monitors/Asset-Data-Quality-Monitors.vue';
import AssetOverviewDataQuality from '@/components/asset/overview/Asset-Overview-Data-Quality.vue';
import AssetOverviewTransformation from '@/components/asset/overview/Asset-Overview-Transformation.vue';
import AssetOverviewSchema from '@/components/asset/overview/Asset-Overview-Schema.vue';
import AssetOverviewOrchestrator from '@/components/asset/overview/Asset-Overview-Orchestrator.vue';
import AssetPreview from '@/components/asset/preview/Asset-Preview.vue';
import AssetDataQualityIncidentsCard from '@/components/asset/data-quality/data-quality-incidents/Asset-Data-Quality-Incidents-Card.vue';

// MONITORS
import MonitorsSummary from '@/components/monitors/Monitors-Summary.vue';
import MonitorsResults from '@/components/monitors/Monitors-Results.vue';
import MonitorsResultsPlaceholder from '@/components/monitors/Monitors-Results-Placeholder.vue';
import MonitorsSearch from '@/components/monitors/Monitors-Search.vue';
import MonitorsSort from '@/components/monitors/Monitors-Sort.vue';
import MonitorsTopFilters from '@/components/monitors/Monitors-Top-Filters.vue';
import MonitorsLeftFilters from '@/components/monitors/Monitors-Left-Filters.vue';
import MonitorStatus from '@/components/icons/Monitor-Status.vue';
import MonitorsEdit from '@/components/monitors/Monitors-Edit.vue';
import MonitorsYamlModal from '@/components/monitors/Monitors-Yaml-Modal.vue';

// RULE
import RuleInfoActions from '@/components/rule/info/Rule-Overview-Info-Actions.vue';
import RuleAlertDestination from '@/components/rule/info/Rule-Overview-Info-Alert-Destination.vue';
import RuleIconName from '@/components/rule/info/Rule-Overview-Info-Icon-Name.vue';
import RuleLastRun from '@/components/rule/info/Rule-Overview-Info-Last-Run.vue';
import RuleSchedule from '@/components/rule/info/Rule-Overview-Info-Schedule.vue';
import RuleStatus from '@/components/rule/info/Rule-Overview-Info-Status.vue';
import RunAggregatedDetails from '@/components/rule/run/Run-Aggregated-Details.vue';
import RuleGraph from '@/components/rule/graph/Rule-Graph.vue';
import RuleDryRun from '@/components/rule/add-edit/Rule-Dry-Run.vue';
import RunDetailedInfo from '@/components/rule/run/Run-Detailed-Info.vue';

// INCIDENTS / ISSUE
import IncidentStatus from '@/components/incidents/Incident-Status.vue';
import Timeline from '@/components/timeline/Timeline.vue';
import TimelineEvent from '@/components/timeline/Timeline-Event.vue';
import IncidentsFilters from '@/components/incidents/Incidents-Filters.vue';
import IncidentsResults from '@/components/incidents/Incidents-Results.vue';
import LegacyIncidentsResults from '@/components/incidents/incidents-results/Incidents-Results.vue';
import IncidentsResultsCard from '@/components/incidents/Incidents-Results-Card.vue';
import IncidentsSort from '@/components/incidents/Incidents-Sort.vue';
import IncidentsSearch from '@/components/incidents/Incidents-Search.vue';

// DATASOURCES
import DatasourcesSearch from '@/components/datasources/datasources-search/Datasources-Search.vue';
import DatasourcesSearchFilter from '@/components/datasources/datasources-search/Datasources-Search-Filter.vue';
import DatasourcesResults from '@/components/datasources/datasources-results/Datasources-Results.vue';
import DatasourceCard from '@/components/cards/datasource-card/Datasource-Card.vue';
import ConnectorsList from '@/components/datasource/connectors/Connectors-List.vue';
import DatasourceForm from '@/components/datasource/Datasource-Form.vue';
import DatasourceFrequency from '@/components/datasource/Datasource-Frequency.vue';
import DatasourceRunSummaryDialog from '@/components/datasource/Datasource-Run-Summary-Dialog.vue';
import DatasourcesResultCard from '@/components/datasources/datasources-results/Datasources-Result-Card.vue';
import DatasourcesResultSelectAll from '@/components/datasources/datasources-results/Datasources-Result-Select-All.vue';
import DatasourcesResultPlaceholder from '@/components/datasources/datasources-results/Datasources-Result-Placeholder.vue';
import DatasourceIconName from '@/components/datasource/info/Datasource-Info-Icon-Name.vue';
import DatasourceInfoActions from '@/components/datasource/info/Datasource-Info-Actions.vue';
import DatasourceInfoSchedule from '@/components/datasource/info/Datasource-Info-Schedule.vue';
import DatasourceInfoLastRun from '@/components/datasource/info/Datasource-Info-Last-Run.vue';
import DatasourceInfoNextExecution from '@/components/datasource/info/Datasource-Info-Next-Execution.vue';
import DatasourceInfoTimezone from '@/components/datasource/info/Datasource-Info-Timezone.vue';
import DatasourcesSort from '@/components/datasources/datasources-sort/Datasources-Sort.vue';

// TAGS
import TagsEdit from '@/components/tag/Tags-Edit.vue';
import TagsResultCard from '@/components/tag/Tags-Result-Card.vue';

// BUSINESS GLOSSARY
import BusinessGlossaryResultCard from '@/components/business-glossary/Business-Glossary-Result-Card.vue';
import BusinessGlossaryTermsEdit from '@/components/business-glossary/Business-Glossary-Terms-Edit.vue';

// USERS
import UsersResultCard from '@/components/users/Users-Results-Card.vue';

// LINEAGE
import Lineage from '@/components/lineage/Lineage.vue';
import LineageFilters from '@/components/lineage/Lineage-Filters.vue';
import LineagePanel from '@/components/lineage/Lineage-Panel.vue';

// CONDITIONAL RULES
import ConditionalRules from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules.vue';
import ConditionalRulesConditionGroup from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition-Group.vue';
import ConditionalRulesCondition from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Condition.vue';
import ConditionalRulesJoinType from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Join-Type.vue';
import ConditionalRulesJoinTypeIcon from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Join-Type-Icon.vue';
import ConditionalRulesJoinKeyField from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Join-Key-Field.vue';
import ConditionalRulesDataset from '@/components/v-jsf-custom/conditional-rules/Conditional-Rules-Dataset.vue';
import SuggestionsCard from '@/components/cards/suggestions-card/Suggestions-Card.vue';
import SmartRegex from '@/components/v-jsf-custom/smart-regex/Smart-Regex.vue';
import AiAssistantSuggestionCard from '@/components/cards/suggestions-card/Ai-Assistant-Suggestion-Card.vue';

// SMART REGEX

Vue.component('VjsfMonitoringDatasetFieldSelector', VjsfMonitoringDatasetFieldSelector);
Vue.component('VjsfMonitoringCalendarSelector', VjsfMonitoringCalendarSelector);
Vue.component('SButton', SButton);
Vue.component('SSplitButton', SSplitButton);
Vue.component('SIcon', SIcon);
Vue.component('SLabel', SLabel);
Vue.component('SCard', SCard);
Vue.component('SModal', SModal);
Vue.component('SMenuButton', SMenuButton);
Vue.component('SThreeDotMenu', SThreeDotMenu);
Vue.component('HtmlView', HtmlView);
Vue.component('DataSourcePlaceholder', DataSourcePlaceholder);
Vue.component('EmptyDataPlaceholder', EmptyDataPlaceholder);
Vue.component('AssetTypeIcon', AssetTypeIcon);
Vue.component('TableIcon', TableIcon);
Vue.component('AssetTabOverview', AssetTabOverview);
Vue.component('DatasourceIcon', DatasourceIcon);
Vue.component('IngestionTypeIcon', IngestionTypeIcon);
Vue.component('TimezoneIcon', TimezoneIcon);
Vue.component('NavigationDrawerItem', NavigationDrawerItem);
Vue.component('Logo', Logo);
Vue.component('UserSetTimezone', UserSetTimezone);
Vue.component('ThemeToggle', ThemeToggle);
Vue.component('FilterReset', FilterReset);
Vue.component('SearchBar', SearchBar);
Vue.component('DataUsage', DataUsage);
Vue.component('DataUsageExtended', DataUsageExtended);
Vue.component('Breadcrumb', Breadcrumb);
Vue.component('ResetPassword', ResetPassword);
Vue.component('RuleInfoActions', RuleInfoActions);
Vue.component('RuleAlertDestination', RuleAlertDestination);
Vue.component('RuleIconName', RuleIconName);
Vue.component('RuleLastRun', RuleLastRun);
Vue.component('RuleSchedule', RuleSchedule);
Vue.component('RuleStatus', RuleStatus);
Vue.component('TreeNestedSelector', TreeNestedSelector);
Vue.component('TreeSelect', TreeSelect);
Vue.component('NestedSheet', NestedSheet);
Vue.component('NestedSheetSimpleTable', NestedSheetSimpleTable);
Vue.component('NestedSchema', NestedSchema);
Vue.component('NestedSchemaItem', NestedSchemaItem);
Vue.component('Tag', Tag);
Vue.component('Tags', Tags);
Vue.component('Term', Term);
Vue.component('Terms', Terms);
Vue.component('Owner', Owner);
Vue.component('Owners', Owners);
Vue.component('HealthDot', HealthDot);
Vue.component('HealthDots', HealthDots);
Vue.component('EntityPreviewCard', EntityPreviewCard);
Vue.component('QualityStatus', QualityStatus);
Vue.component('Severity', Severity);
Vue.component('PlatformIcon', PlatformIcon);
Vue.component('DateTooltip', DateTooltip);
Vue.component('DomainsTooltip', DomainsTooltip);
Vue.component('ModalDialog', ModalDialog);
Vue.component('ConfirmationDialog', ConfirmationDialog);
Vue.component('Avatar', Avatar);
Vue.component('AvatarList', AvatarList);
Vue.component('TagsResultCard', TagsResultCard);
Vue.component('TagsEdit', TagsEdit);
Vue.component('BusinessGlossaryResultCard', BusinessGlossaryResultCard);
Vue.component('BusinessGlossaryTermsEdit', BusinessGlossaryTermsEdit);
Vue.component('EditDialog', EditDialog);
Vue.component('AssetIconName', AssetIconName);
Vue.component('AssetInfoActions', AssetInfoActions);
Vue.component('SqlDialog', SqlDialog);
Vue.component('DbtDescription', DbtDescription);
Vue.component('SourceDescription', SourceDescription);
Vue.component('Lineage', Lineage);
Vue.component('Usage', Usage);
Vue.component('CatalogSearch', CatalogSearch);
Vue.component('Essentials', Essentials);
Vue.component('CatalogImport', CatalogImport);
Vue.component('CatalogSort', CatalogSort);
Vue.component('CatalogResults', CatalogResults);
Vue.component('CatalogFilters', CatalogFilters);
Vue.component('DatasourcesSearch', DatasourcesSearch);
Vue.component('DatasourcesSearchFilter', DatasourcesSearchFilter);
Vue.component('DatasourcesResults', DatasourcesResults);
Vue.component('DatasourceCard', DatasourceCard);
Vue.component('DatasourceForm', DatasourceForm);
Vue.component('DatasourceFrequency', DatasourceFrequency);
Vue.component('DatasourcesResultCard', DatasourcesResultCard);
Vue.component('DatasourcesResultSelectAll', DatasourcesResultSelectAll);
Vue.component('DatasourcesResultPlaceholder', DatasourcesResultPlaceholder);
Vue.component('DatasourceIconName', DatasourceIconName);
Vue.component('DatasourceInfoActions', DatasourceInfoActions);
Vue.component('DatasourceInfoNextExecution', DatasourceInfoNextExecution);
Vue.component('DatasourceInfoTimezone', DatasourceInfoTimezone);
Vue.component('DatasourcesSort', DatasourcesSort);
Vue.component('ConnectorsList', ConnectorsList);
Vue.component('DatasourceRunSummaryDialog', DatasourceRunSummaryDialog);
Vue.component('IncidentsFilters', IncidentsFilters);
Vue.component('IncidentsResults', IncidentsResults);
Vue.component('LegacyIncidentsResults', LegacyIncidentsResults);
Vue.component('IncidentsResultsCard', IncidentsResultsCard);
Vue.component('IncidentsSort', IncidentsSort);
Vue.component('IncidentsSearch', IncidentsSearch);
Vue.component('IncidentStatus', IncidentStatus);
Vue.component('Timeline', Timeline);
Vue.component('TimelineEvent', TimelineEvent);
Vue.component('LineageFilters', LineageFilters);
Vue.component('LineagePanel', LineagePanel);
Vue.component('MonitorsSummary', MonitorsSummary);
Vue.component('MonitorsResults', MonitorsResults);
Vue.component('MonitorsResultsPlaceholder', MonitorsResultsPlaceholder);
Vue.component('MonitorsSearch', MonitorsSearch);
Vue.component('MonitorsSort', MonitorsSort);
Vue.component('MonitorsTopFilters', MonitorsTopFilters);
Vue.component('MonitorsLeftFilters', MonitorsLeftFilters);
Vue.component('MonitorStatus', MonitorStatus);
Vue.component('MonitorsEdit', MonitorsEdit);
Vue.component('MonitorsYamlModal', MonitorsYamlModal);
Vue.component('FailingRowsDialog', FailingRowsDialog);
Vue.component('DatasourceInfoSchedule', DatasourceInfoSchedule);
Vue.component('DatasourceInfoLastRun', DatasourceInfoLastRun);
Vue.component('PositionDot', PositionDot);
Vue.component('RunStatus', RunStatus);
Vue.component('ExternalDescription', ExternalDescription);
Vue.component('ExternalDescriptions', ExternalDescriptions);
Vue.component('FieldsSearchMatch', FieldsSearchMatch);
Vue.component('RunDetailedInfo', RunDetailedInfo);
Vue.component('RuleGraph', RuleGraph);
Vue.component('RunAggregatedDetails', RunAggregatedDetails);
Vue.component('RuleDryRun', RuleDryRun);
Vue.component('CatalogResultsCard', CatalogResultsCard);
Vue.component('Sso', Sso);
Vue.component('RuleCategoryCard', RuleCategoryCard);
Vue.component('SpConfig', SpConfig);
Vue.component('VNotification', VNotification);
Vue.component('DataTableGenericPlaceholder', DataTableGenericPlaceholder);
Vue.component('NoDataAvailablePlaceholder', NoDataAvailablePlaceholder);
Vue.component('Bookmark', Bookmark);
Vue.component('BookmarkCard', BookmarkCard);
Vue.component('Shortcuts', Shortcuts);
Vue.component('ConditionalRules', ConditionalRules);
Vue.component('ConditionalRulesConditionGroup', ConditionalRulesConditionGroup);
Vue.component('ConditionalRulesCondition', ConditionalRulesCondition);
Vue.component('ConditionalRulesJoinType', ConditionalRulesJoinType);
Vue.component('ConditionalRulesJoinTypeIcon', ConditionalRulesJoinTypeIcon);
Vue.component('ConditionalRulesJoinKeyField', ConditionalRulesJoinKeyField);
Vue.component('ConditionalRulesDataset', ConditionalRulesDataset);
Vue.component('SmartRegex', SmartRegex);
Vue.component('AnimatedBackground', AnimatedBackground);
Vue.component('DashboardIncidentsAssigned', DashboardIncidentsAssigned);
Vue.component('DashboardIncidentsOpen', DashboardIncidentsOpen);
Vue.component('DashboardIncidentsOpenCard', DashboardIncidentsOpenCard);
Vue.component('DashboardIncidentsLatest', DashboardIncidentsLatest);
Vue.component('DashboardDataQualityRules', DashboardDataQualityRules);
Vue.component('DashboardDataRuleActivity', DashboardDataRuleActivity);
Vue.component('DashboardDataRuleActivityCard', DashboardDataRuleActivityCard);
Vue.component('DashboardDataStackIntegration', DashboardDataStackIntegration);
Vue.component('DashboardDataStackIntegrationRow', DashboardDataStackIntegrationRow);
Vue.component('DashboardPinned', DashboardPinned);
Vue.component('DashboardPinnedCard', DashboardPinnedCard);
Vue.component('DashboardTitle', DashboardTitle);
Vue.component('DashboardAiAssistantMonitors', DashboardAiAssistantMonitors);
Vue.component('DashboardAiAssistantMonitorsCard', DashboardAiAssistantMonitorsCard);
Vue.component('ChartLatestIncidents', ChartLatestIncidents);
Vue.component('ChartMonitors', ChartMonitors);
Vue.component('AssetOverviewDetail', AssetOverviewDetail);
Vue.component('AssetOverviewDataQuality', AssetOverviewDataQuality);
Vue.component('AssetOverviewTransformation', AssetOverviewTransformation);
Vue.component('AssetOverviewSchema', AssetOverviewSchema);
Vue.component('AssetOverviewOrchestrator', AssetOverviewOrchestrator);
Vue.component('AssetDataQualityIncidents', AssetDataQualityIncidents);
Vue.component('AssetDataQualityMonitors', AssetDataQualityMonitors);
Vue.component('AssetDataQualityIncidentsCard', AssetDataQualityIncidentsCard);
Vue.component('AssetPreview', AssetPreview);
Vue.component('UsersResultCard', UsersResultCard);
Vue.component('SuggestionsCard', SuggestionsCard);
Vue.component('AiAssistantSuggestionCard', AiAssistantSuggestionCard);
Vue.component('DashboardFilters', DashboardFilters);
