/* eslint-disable no-param-reassign */
import { FieldDto } from '@/api';
import log from '@/utils/console';

const DEBUG = false;

export default class CreateTable {
  private initialCollection: any[] = [];

  public header: Map<
    string,
    {
      text: string;
      type: string;
    }
  > = new Map();

  public body: any[] = [];

  private schema: FieldDto[];

  constructor(collection: any[], schema: FieldDto[]) {
    this.initialCollection = collection;
    this.schema = schema;
    this.createTable();
  }

  private createTable() {
    this.createHeader(this.schema);
    this.createItems();
  }

  private createHeader(schema: FieldDto[], keyName = '') {
    schema.forEach((field) => {
      if (field.subfields?.length) {
        this.createHeader(field.subfields, `${keyName + field.name}.`);
      } else {
        this.header.set(keyName + field.name!, {
          text: keyName + field.name!,
          type: field.type ?? '',
        });
      }
    });
  }

  private createObjectFromFieldSchema(fieldSchema: FieldDto, originalItem: any, newItem: any) {
    const {
      name, type, subfields, repeated,
    } = fieldSchema;

    if (!repeated) {
      // this is a non repeated field could be an object or a string

      // it is a string
      if (type !== 'STRUCT') {
        // if the field is not present in the original item, set it to null
        if (!originalItem) {
          if (DEBUG) log((`Field ${name} not present in the original item`));
          newItem[name!] = null;
          return;
        }
        if (DEBUG) log(`Field ${name} is a string`, JSON.stringify(originalItem[name!]));

        newItem[name!] = originalItem[name!] ?? null;
      }

      // it is an object
      if (type === 'STRUCT') {
        if (DEBUG) log(`Field ${name} is an object`, JSON.stringify(originalItem?.[name!]));
        const newSubItem = {};
        subfields?.forEach((subfield) => {
          this.createObjectFromFieldSchema(subfield, originalItem?.[name!], newSubItem);
        });
        newItem[name!] = newSubItem;
      }
    } else {
      // this is a repeated field, it could be an array of objects or an array of strings

      // it is a string
      if (type !== 'STRUCT') {
        if (DEBUG) log(`Field ${name} is a repeated string`, JSON.stringify(originalItem?.[name!]));
        newItem[name!] = originalItem?.[name!] ?? null;
      }

      // it is an object
      if (type === 'STRUCT') {
        if (DEBUG) log(`Field ${name} is a repeated object`, JSON.stringify(originalItem?.[name!]));
        const hasItems = originalItem?.[name!]?.length > 0;

        if (hasItems) {
          // we  must create an array of objects with the subfields
          const newSubItems: any = [];
          originalItem[name!].forEach((subItem: any) => {
            const newSubItem = {};
            subfields?.forEach((subfield) => {
              this.createObjectFromFieldSchema(subfield, subItem, newSubItem);
            });
            newSubItems.push(newSubItem);
          });
          newItem[name!] = newSubItems;
        } else {
          // we  must create an object with the subfields
          const newSubItem = {};
          subfields?.forEach((subfield) => {
            this.createObjectFromFieldSchema(subfield, null, newSubItem);
          });
          newItem[name!] = [newSubItem];
        }
      }
    }
  }

  private createItems() {
    // create rows

    this.initialCollection.forEach((item) => {
      // reorganize data

      const newItem: any = {};

      this.schema.forEach((fieldSchema) => {
        this.createObjectFromFieldSchema(fieldSchema, item, newItem);
      });

      this.body.push(newItem);
    });
  }
}
