<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import {
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { DataOptions } from 'vuetify';
import monitorsModule from '@/store/modules/monitors';
import store from '@/store';
import {
  IncidentLightDto,
  MonitorService,
  RuleCatalogAssetDtoV2,
  RuleService,
  UserAssetBookmarkDto,
  UserService,
  WorkspaceService,
} from '@/api';
import authModule from '@/store/modules/auth';
import type EditDialog from '@/components/monitors/Monitors-Edit.vue';
import type ConfirmationDialog from '@/components/confirmation-dialog/Confirmation-Dialog.vue';
import type MonitorsYamlModal from '@/components/monitors/Monitors-Yaml-Modal.vue';
import SiffletifyDataFooter
  from '@/components/siffletify/siffletify-data-footer/siffletify-data-footer.vue';
import i18n from '@/i18n';
import { computed } from 'vue';
import copyToClipboard from '@/utils/copyToClipboard';
import SResultCard from '@/components/SResultCard.vue';
import IncidentsForm from '@/components/incidents/Incidents-Form.vue';
import MonitorsIncidentLinkForm from '@/components/monitors/MonitorsIncidentLinkForm.vue';
import SiffletButtonIconAlignment from '@/components/SButton.vue';
import MonitorsResultsCardContent from './Monitors-Results-Card-Content.vue';
import MonitorsResultsCardActions from './Monitors-Results-Card-Actions.vue';

interface MonitorsResultsProps {
  hideRunButton?: boolean
}

const props = defineProps({
  hideRunButton: { type: Boolean, default: true }
});

const authStore = getModule(authModule, store);
const monitorStore = getModule(monitorsModule, store);

let selectedMonitors = _ref<RuleCatalogAssetDtoV2[]>([]);
const editDialogRef = _ref<InstanceType<typeof EditDialog> | null>(null);
const confirmationDialogRef = _ref<ConfirmationDialog | null>(null);
const monitorsYamlModalRef = _ref<MonitorsYamlModal | null>(null);
const incidentsFormRef = _ref<InstanceType<typeof IncidentsForm> | null>(null);
const monitorsIncidentLinkFormRef = _ref<InstanceType<typeof MonitorsIncidentLinkForm> | null>(null);

const domain = _computed(() => authStore.selectedDomain?.name);
const monitors = _computed(() => monitorStore.getMonitors);
const count = _computed(() => monitorStore.getCount);
const options = _computed(() => monitorStore.options);
const textSearch = _computed(() => monitorStore.textSearch);
const loading = _computed(() => monitorStore.loading);
const firstOfPage = _computed(() => (options.value!.page! - 1) * options.value!.itemsPerPage!);
const lastOfPage = _computed(() => (count.value < options.value!.itemsPerPage! ? firstOfPage.value + count.value : firstOfPage.value + options.value!.itemsPerPage!));
const monitorsOfTotal = _computed(() => `${firstOfPage.value + 1} - ${lastOfPage.value} ${i18n.tc('monitors.of_total_monitors', count.value)}`);
const someAreSelected = _computed(() => !!selectedMonitors.value.length);
const canRun = _computed(() => !props.hideRunButton && authStore.userActions['monitoring.monitor.write'] && someAreSelected.value);
const selectedMonitorsCount = computed(() => selectedMonitors.value.length);
const selectedSingleMonitor = computed(() => selectedMonitors.value.length === 1);
const showEdit = _computed(() => selectedMonitors.value.length >= 1);
const hasNoResults = _computed(() => !monitors.value.length);
const gotReadOnlyMonitors = _computed(() => selectedMonitors.value.some((monitor: RuleCatalogAssetDtoV2) => monitor.readOnly));
const canEdit = _computed(() => authStore.userActions['monitoring.monitor.write'] && someAreSelected.value);
const canDelete = _computed(() => authStore.userActions['monitoring.monitor.write'] && someAreSelected.value);
const selected = _computed(() => `${selectedMonitors.value.length} ${i18n.t('monitors.selected')}`);
const results = _computed(() => `${i18n.tc('monitors.monitors', monitors.value.length)}`);
const isChecked = _computed(() => monitors.value.every((monitor: RuleCatalogAssetDtoV2) => selectedMonitors.value.some((selectedMonitor: RuleCatalogAssetDtoV2) => monitor.id === selectedMonitor.id)));
const isIndeterminate = _computed(() => monitors.value.some((monitor: RuleCatalogAssetDtoV2) => selectedMonitors.value.some((selectedMonitor: RuleCatalogAssetDtoV2) => monitor.id === selectedMonitor.id)) && !isChecked.value);
const hasSelectionFromOtherPages = _computed(() => selectedMonitors.value.some((selectedMonitor: RuleCatalogAssetDtoV2) => !monitors.value.some((monitor: RuleCatalogAssetDtoV2) => monitor.id === selectedMonitor.id)));
const canCreateIncidents = _computed(() => authStore.userActions['monitoring.incident.write'] && someAreSelected.value);

const isLast = (item: any) => monitors.value.indexOf(item) >= (options.value.itemsPerPage! - 1);
const clearSelection = () => {
  selectedMonitors.value = [];
};

const runMultipleMonitors = async (ids: RuleCatalogAssetDtoV2['id'][]) => Promise.all(ids.map((id) => RuleService.siffletRuleManualRun({ id: id! })));
const runMonitors = async () => {
  const ids = selectedMonitors.value.map((monitor) => monitor.id);
  await runMultipleMonitors(ids).then(() => {
    for (const selectedMonitor of selectedMonitors.value) {
      Vue.notify({
        text: i18n.t('monitors.run_rule_success', { name: selectedMonitor.name }) as string,
      });
    }
  });
  clearSelection();
  await monitorStore.searchMonitors();
};

const updateOptions = (newOptions: Partial<DataOptions>) => {
  monitorStore.setValues({ page: newOptions.page?.toString()!, itemsPerPage: newOptions.itemsPerPage?.toString()! });
};

const editMonitor = (monitor: RuleCatalogAssetDtoV2) => {
  editDialogRef.value!.setMonitors([monitor]);
};

const editMonitors = () => {
  MonitorService.getAllMonitor({
    requestBody: {
      ...monitorStore.searchParametersWithDomain,
      itemsPerPage: -1,
      monitor: selectedMonitors.value.map((selectedMonitor) => selectedMonitor.id),
    },
  }).then(({ searchRules: { data } }) => {
    editDialogRef.value!.setMonitors(data!);
  });
};

const selectionHandler = () => {
  if (!isChecked.value || isIndeterminate.value) {
    if (hasSelectionFromOtherPages.value) {
      selectedMonitors.value = selectedMonitors.value.concat(monitors.value);
    } else {
      selectedMonitors.value = [...monitors.value];
    }
    return;
  }

  // Remove monitors from the selection
  if (isChecked.value) {
    selectedMonitors.value = selectedMonitors.value.filter((selectedMonitor: RuleCatalogAssetDtoV2) => !monitors.value.some((monitor: RuleCatalogAssetDtoV2) => monitor.id === selectedMonitor.id));
  }
};

const editDialogUpdateHandler = async () => {
  monitorStore.searchMonitors();
};

const saveBookmarks = async () => {
  const promises = selectedMonitors.value.map(async (item) => {
    const notExist = await UserService.getCurrentUserAssetBookmark({ entityId: item.id as unknown as UserAssetBookmarkDto['entityId'] }).then((myres: any) => (typeof myres === 'string' ? item : false));
    return notExist;
  });
  const toBookmark = await Promise.all(promises);
  const promises2 = toBookmark.map(async (item2) => {
    if (item2) {
      const requestBody = {
        entityId: item2.id as unknown as UserAssetBookmarkDto['entityId'],
        entityType: UserAssetBookmarkDto.entityType.SIFFLET_RULE,
      };
      return UserService.createUserAssetBookmark({ requestBody }).then(() => Vue.notify({
        type: 'success',
        text: i18n.t('monitors.pin_success') as string,
      }));
    }
    return false;
  });
  await Promise.all(promises2);
};

const deleteMultipleMonitors = async (ids: RuleCatalogAssetDtoV2['id'][]) => Promise.all(ids.map((id) => RuleService.deleteSiffletRuleById({ id: id! })));

const deleteMonitors = async () => {
  const ids = selectedMonitors.value.map((monitor) => monitor.id);
  const bodyMessage = i18n.tc('monitors.delete_rules_confirm', ids.length, { count: ids.length }) as string;
  confirmationDialogRef.value!
    .setTitle(i18n.tc('monitors.delete_rule', selectedMonitorsCount.value))
    .setBody(bodyMessage)
    .waitForUserAction()
    .then(() => {
      deleteMultipleMonitors(ids).then(() => {
        for (const selectedMonitor of selectedMonitors.value) {
          Vue.notify({
            text: i18n.t('monitors.delete_rules_success', { name: selectedMonitor.name }) as string,
          });
        }
        monitorStore.searchMonitors();
        selectedMonitors.value = selectedMonitors.value.filter((mntr) => !ids.includes(mntr.id));
      });
    })
    .catch(() => { });
};

const deleteMonitor = async (monitor: RuleCatalogAssetDtoV2) => {
  const bodyMessage = i18n.tc('monitors.delete_rules_confirm', 1, { count: 1 }) as string;
  confirmationDialogRef.value!
    .setTitle(i18n.tc('monitors.delete_rule', 1))
    .setBody(bodyMessage)
    .waitForUserAction()
    .then(() => {
      RuleService.deleteSiffletRuleById({ id: monitor.id }).then(() => {
        Vue.notify({
          text: i18n.t('monitors.delete_rules_success', { name: monitor.name }) as string,
        });
        monitorStore.searchMonitors();
        selectedMonitors.value = selectedMonitors.value.filter((mntr) => mntr.id !== monitor.id);
      });
    })
    .catch(() => { });
};

const generateYaml = async (newMonitor: RuleCatalogAssetDtoV2) => {
  const rule = await RuleService.getSiffletRuleById({ id: newMonitor.id });
  const yamlCode = await WorkspaceService.convertMonitorToCode({ requestBody: rule }) as any;
  monitorsYamlModalRef.value!.setCode(yamlCode);
  monitorsYamlModalRef.value!.open();
};

const createSingleIncident = async (monitor: RuleCatalogAssetDtoV2) => {
  incidentsFormRef.value?.handleSingleIncidentCreation(monitor);
};

const createIncident = () => {
  incidentsFormRef.value?.handleIncidentsCreation();
  if (selectedMonitors.value.length === 1) incidentsFormRef.value?.prePopulateForm(selectedMonitors.value[0]);
};

const linkIncident = (linkableMonitors: RuleCatalogAssetDtoV2[]) => {
  monitorsIncidentLinkFormRef.value?.handleMonitorsIncidentLinking(linkableMonitors);
};

const onIncidentLinked = () => {
  monitorStore.searchMonitors();
};

const copyIDToClipboard = (monitor: RuleCatalogAssetDtoV2) => {
  copyToClipboard(monitor.id, i18n.t('monitors.copy_id_success', { name: monitor.name }));
};

const updateMonitor = () => {
  // TODO: implement this using the id from the event
  // and retrieve only the monitor that was updated
  // RuleService.getAllRule({ id })
  // then update the monitor in the list
};
</script>

<template lang="pug">
v-row.mt-1
  v-col( cols="12" )

    v-data-iterator(
      v-model="selectedMonitors"
      return-object
      :items="monitors"
      :server-items-length="count"
      :options="options"
      :loading="loading"
      data-cy="monitors-results-iterator"
      hide-default-footer)

      template(v-slot:loading)
        MonitorsResultsPlaceholder( data-cy="monitors-results-placeholder" )

      template(v-slot:no-data)
        NoDataAvailablePlaceholder( data-cy="monitors-results-no-data" )

      template(v-slot:header)
        v-row( no-gutters )
          v-col( align-self="stretch" )
            .d-flex.align-center.flex-wrap( v-if="!hasNoResults" style="min-height: 36px;" )

              v-simple-checkbox.ml-2(
                color="primary"
                data-cy="monitors-results-select-all"
                :value="isChecked"
                @input="selectionHandler"
                :indeterminate="isIndeterminate" )

              b {{ monitorsOfTotal }}
              v-spacer
              template( v-if="selectedMonitors.length" )
                v-btn.mr-2(
                  @click="clearSelection"
                  data-cy="monitors-results-clear-selection"
                  color="secondary" class="custom-secondary" text )
                  |  {{ selected }}
                  v-icon( right ) icon-dismiss

                v-btn.mr-2(
                  v-if="canRun"
                  color="secondary"
                  class="custom-secondary"
                  text outlined @click="runMonitors" )
                  v-icon(left) icon-play-circle
                  | {{ $tc('assets.run_monitors', selectedMonitorsCount) }}

                v-btn.mr-2(
                  :disabled="!canEdit"
                  v-show="showEdit"
                  @click="editMonitors"
                  data-cy="monitors-results-edit"
                  color="secondary"
                  class="custom-secondary"
                  text outlined)
                  v-icon( left ) icon-edit
                  span {{ $t('monitors.edit.edit') }}

                v-btn.mr-2(
                  :disabled="!canCreateIncidents"
                  @click="createIncident"
                  data-cy="monitors-results-incident-create"
                  color="secondary"
                  class="custom-secondary"
                  text outlined)
                  v-icon( left ) icon-add
                  span {{ $t('rules.create_incident') }}

                SThreeDotMenu( data-cy="monitors-results-three-dot-menu" )
                  SMenuButton(
                    @click="linkIncident(selectedMonitors)"
                    data-cy="monitors-results-incident-link"
                    icon="icon-flag-add"
                    color="secondary"
                    variant="outlined"
                    :text="$t('monitors.edit.link_existing_incident')")
                  SMenuButton(
                    v-if="selectedSingleMonitor"
                    @click="generateYaml(selectedMonitors[0])"
                    data-cy="monitors-results-generate-yaml"
                    icon="icon-code-brackets"
                    :text="$t('monitors.show_as_yaml_code')")
                  SMenuButton(
                    v-if="selectedSingleMonitor"
                    @click="copyIDToClipboard(selectedMonitors[0])"
                    data-cy="monitors-results-copy-id"
                    icon="icon-copy"
                    :text="$t('rules.copy_id')")
                  SMenuButton(
                    @click="saveBookmarks"
                    data-cy="monitors-results-pin"
                    icon="icon-thumbtack"
                    :text="$t('bookmark.pin')" )
                  SMenuButton(
                    v-if="canDelete"
                    :disabled="gotReadOnlyMonitors"
                    @click="deleteMonitors"
                    data-cy="monitors-results-delete"
                    icon="$delete"
                    color="danger"
                    :text="$t('monitors.delete')" )

        MonitorsIncidentLinkForm(
          ref="monitorsIncidentLinkFormRef"
          @onLink="onIncidentLinked")

        v-row( no-gutters )
          v-col
            v-divider.mt-3

      template(v-slot:item="itemProps")
        SResultCard(
          data-cy="monitors-result-card"
          :to="{ name: 'monitors.rule.overview', params: { id: itemProps.item.id }}"
          :isSelected="itemProps.isSelected"
          :isLast="isLast(itemProps.item)"
          :select="itemProps.select" )

          template( #default )
            MonitorsResultsCardContent( :monitor="itemProps.item" )

          template( #contextual-actions )
            MonitorsResultsCardActions(
              :itemProps="itemProps"
              @edit="editMonitor"
              @run="updateMonitor"
              @show-yaml="generateYaml"
              @copy-id="copyIDToClipboard"
              @createIncident="createSingleIncident"
              @linkIncident="linkIncident([itemProps.item])"
              @delete="deleteMonitor" )

      template(v-slot:footer="footer")
        // eslint-disable-next-line vue/valid-v-bind-sync
        SiffletifyDataFooter( :footer.sync="footer"
          :items-per-page-options="[10, 25, 50, 100]"
          @update="updateOptions($event)" )

  MonitorsEdit( ref="editDialogRef" @update="editDialogUpdateHandler" )

  ConfirmationDialog( content-class="delete-dialog" ref="confirmationDialogRef" )

  MonitorsYamlModal( ref="monitorsYamlModalRef" )

  IncidentsForm( ref="incidentsFormRef" :ruleIds="selectedMonitors.map((monitor) => monitor.id)" )

</template>
