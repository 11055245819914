<script setup lang="ts">import { computed as _computed } from 'vue';

// eslint-disable-next-line
// @ts-nocheck
import type { DatasourceDto } from '@/api';
import i18n from '@/i18n';
import src from '@/utils/filters/src';

interface DatasourceCardProps {
  active: boolean
  disabled: boolean
  params: DatasourceDto.params
  isBeta?: boolean
}

const props = defineProps({
  active: { type: Boolean },
  disabled: { type: Boolean },
  params: null,
  isBeta: { type: Boolean, default: false }
});

const image = _computed(() => src(props.params.type, 'datasources'));
const datasourceType = _computed(() => i18n.t(`datasource_types.${props.params.type.toUpperCase()}`));

</script>

<template lang="pug">
v-card.card( type="white" :disabled="disabled" :active="active" rounded outlined style="cursor: pointer" )
  .check-icon
    v-icon( v-if="active" color="iconInfo" ) icon-check-circle-fill
  v-avatar( tile size='48' )
    img( :src="image" width='40' :alt="params.type" )
  div.d-flex.align-center.mt-1
    div( class="font-weight-medium " style="cursor: pointer" ) {{ datasourceType }}
    v-chip.ml-1.font-weight-bold.beta-chip(v-if="isBeta" x-small outlined color="textPrimary") {{ $t('common.words.beta') }}
  div( class="text-capitalize" style="cursor: pointer" ) {{params.quantity}}
</template>

<style lang="scss" scoped>
.beta-chip {
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: 700;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  flex-grow: 1;
}

.check-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}
</style>
