<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { TermService, TagDto } from '@/api';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import { Inputs } from '@/@types/types';
import i18n from '@/i18n';
import SRichTextEditor from '@/components/SRichTextEditor.vue';

interface Props {
  selectedTerm: TagDto | null;
}

type Emits = {
  (event: 'onValidate'): void
}

const props = defineProps({
  selectedTerm: null
});
const emit = defineEmits(["onValidate"]);

const modalDialogRef = _ref<InstanceType<typeof ModalDialog> | null>(null);
const richTextEditor = _ref<InstanceType<typeof SRichTextEditor> | null>(null);
const nameCounter = _ref(Inputs.INPUT_VERY_LOW_CHARS);
const isValid = _ref(false);

let editSingle = _ref(false);
let term = _ref<TagDto>({
  id: '',
  name: '',
  description: '',
  type: TagDto.type.TERM,
});

const nameRules = _computed(() => [
  (value: string) => !!value || i18n.t('glossary.term_required'),
  (value: string) => (value && value.length <= nameCounter.value) || i18n.t('glossary.max_character', { count: nameCounter.value }),
]);

const modifyExisting = _computed(() => !!props.selectedTerm || editSingle.value);

const title = _computed(() => (modifyExisting.value ? i18n.t('glossary.edit_existent', { term: term.value.name }) : i18n.t('glossary.add_term')));

const init = () => {
  editSingle.value = false;
  term.value = {
    id: '',
    name: '',
    description: '',
    type: TagDto.type.TERM,
  };
};

const addNewTerm = () => {
  init();
  modalDialogRef.value?.openDialog();
};

const editTerm = async () => {
  term.value = await TermService.getTermById({ id: props.selectedTerm!.id });
  modalDialogRef.value?.openDialog();
};

const editSingleTerm = async (editedTerm: TagDto) => {
  editSingle.value = true;
  term.value = await TermService.getTermById({ id: editedTerm.id });
  modalDialogRef.value?.openDialog();
};

const close = () => modalDialogRef.value?.closeDialog();

const validate = async () => {
  if (modifyExisting.value) await TermService.updateTerm({ id: term.value.id, requestBody: term.value });
  else await TermService.createTerm({ requestBody: term.value });
  emit('onValidate');
  close();
};

const handleDescriptionChange = () => {
  term.value.description = richTextEditor.value?.getHTMLValue();
};

defineExpose({ addNewTerm, editTerm, editSingleTerm });
</script>

<template lang="pug">
ModalDialog( ref="modalDialogRef" :title="title" )
  template( v-slot:body )
    v-form( ref='form' v-model='isValid' )
      v-row
        v-col(cols='12')
          SLabel {{ $t('glossary.term') }}
            v-text-field.mt-1(
              v-model.trim='term.name'
              :rules="nameRules"
              :counter="nameCounter"
              dense clearable outlined required
              name="name")

        v-col(cols='12')
          span.font-weight-medium.body-2.textPrimary--text {{ $t('glossary.definition') }}
          SRichTextEditor.mt-1(ref="richTextEditor" :initial-value="term.description" @editorChanged="handleDescriptionChange")
  template( v-slot:actions )
    v-btn( :disabled="!isValid" color="primary" class="custom-primary" depressed @click='validate' ) {{ $t('glossary.save') }}

</template>
