<template lang="pug">
v-card.card(
  @mouseover="mouseOver"
  @mouseout="mouseOut"
  :disabled="disabled"
  :active="active"
  :hover="hover"
  type="white" outlined )
  .check-icon
    v-icon( v-if="active" color="iconInfo" ) icon-check-circle-fill
  v-avatar( tile size='48' )
    img( v-if="icon" :src="icon" width='40' :alt="value" )
  div( class="mt-1 text-capitalize text-center" style="cursor: pointer;" ) {{ value }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import ProviderIcons from '@/utils/provider-icons';
import src from '@/utils/filters/src';
import { RuleDto } from '@/api';

@Component
export default class RuleCategoryCard extends Vue {
  @Prop({ type: Boolean }) readonly active!: boolean;

  @Prop({ type: Boolean }) readonly disabled!: boolean;

  @Prop({ type: String }) readonly value!: RuleDto['category'];

  hover = false;

  get icon() {
    switch (this.value) {
      case 'Okta':
        return ProviderIcons.OKTA;
      case 'Google':
        return ProviderIcons.GOOGLE;
      case 'Azure Active Directory':
        return ProviderIcons.AZURE;
      case 'Other':
        return src('custom-idp', 'platforms');
      default:
        return undefined;
    }
  }

  mouseOver() {
    this.hover = true;
  }

  mouseOut() {
    this.hover = false;
  }
}
</script>

<style lang="scss">
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  flex-grow: 1;
  cursor: pointer
}

.check-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}

.v-sheet.v-card--hover:hover {
  background-color: var(--v-bgPrimaryHover-base) !important;
}

.category-card.v-item--active {
  border: 1px solid map-get($blue, 'base') !important;
}
</style>
