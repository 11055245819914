import {
 Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import { Route } from 'vue-router';
import { BaseSearchFilterDto } from '@/api';
import router from '@/router';
import { queryToArray } from '@/utils/query';

@Module({ namespaced: true, name: 'dashboard' })
export default class DashboardModule extends VuexModule {
    selectedTags: Array<string> = [];

    query: Route['query'] = {};

    get getSelectedTags() {
        return this.selectedTags;
    }

    get values() {
        return {
            tag: this.selectedTags,
        };
    }

    @Mutation
    setValuesFromQuery() {
        this.selectedTags = this.query.tag ? queryToArray(this.query.tag) : [];
    }

    @Mutation
    setValues(values: { [key: string]: Array<string>}) {
        if (values.tag) {
            this.selectedTags = values.tag;
            router.push({
                query: {
                    ...this.query,
                    tag: values.tag,
                },
            });
        }
    }

    @Mutation
    routeChanged(to: Route) {
      this.query = Object.freeze(to.query);
    }
}
