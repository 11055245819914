import colors from '@/plugins/colors';
import Node from './Node';
import * as CONSTANTS from './constants';

const lineageCreateLockIcons = (
  cardBody: d3.Selection<SVGGElement, Node, SVGGElement, unknown>,
) => {
  const showFields = cardBody
    .append('g')
    .attr('class', 'show_lock')
    .attr('transform', (d) => (d.data.attachedEntity
        ? `translate(210, ${
            CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT + CONSTANTS.DEFAULT_ATTACHED_ENTITY_HEIGHT - 30
          })`
        : `translate(210, ${CONSTANTS.DEFAULT_HEAD_NODE_HEIGHT - 30})`))

    .attr('cursor', 'pointer')
    .attr('visibility', (d) => (d.data.accessAuthorized ? 'hidden' : 'visible'));

  showFields
    .append('image')
    .attr('class', 'image_show_lock')
    .attr('color', colors.red.base)
    .attr('href', '/next/images/lineage/lock-closed.svg')
    .attr('width', '18')
    .attr('height', '18');
};

export default lineageCreateLockIcons;
