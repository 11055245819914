<template lang="pug">
ChartTimeSeries(
    :graph="graph" :id="id" :runId="runId" :qualifications="qualifications"
    :title="$t('charts.graph_type.FRESHNESS.title')"
    :valuesSeriesName="$t('charts.graph_type.FRESHNESS.value_series_title')"
    :formatValue="formatValue"
    :yMin="0" :yMax="1" :yInterval="1")
</template>

<script setup lang="ts">
import ChartTimeSeries from '@/components/charts/Chart-Time-Series.vue';
import type { DatapointQualificationDto, TimeSeriesGraphDto } from '@/api';
import i18n from '@/i18n';

interface Props {
  graph: TimeSeriesGraphDto;
  id: string;
  runId: string;
  qualifications: DatapointQualificationDto[];
}
defineProps({
  graph: null,
  id: null,
  runId: null,
  qualifications: null
})

const formatValue = (value: number) => {
  if (value < 0.5) {
    return i18n.t('charts.graph_type.FRESHNESS.y_axis_min_title');
  }
  return i18n.t('charts.graph_type.FRESHNESS.y_axis_max_title');
};
</script>
