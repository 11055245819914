const DEFAULT_NUMBER_PRECISION = 5;

/**
 * Formats a number into a string with a maximum precision
 * @param {string} value - A number
 * @param {string} precision optional (default 5) - The maximum precision
 * @return string - The formatted number
 * @example 0 => 0
 * @example 1234567.89012 => 1234568
 * @example 1234567.39012 => 1234567
 * @example 123456.789012 => 123457
 * @example 12345.6789012 => 12346
 * @example 1234.56789012 => 1234.6
 * @example 12.3456789012 => 12.346
 * @example 0.000123456789012 => 0.00012346
 * @example 12.3006789012 => 12.301
 * @example 12.3000789012 => 12.3
 * @example -12.3006789012 => -12.301
 */
export default (value?: number, precision = DEFAULT_NUMBER_PRECISION): string => {
  if (value == null) return '';
  if (value === 0 || Math.log10(Math.abs(value)) + 1 >= precision) return value.toFixed().toString();

  return parseFloat(value.toPrecision(precision)).toString();
};
