<template lang="pug">
v-row
  v-col( cols="6")
    InputName( v-model.trim='datasource.name' label='Name' )

  v-col( cols="6")
    InputHost( v-model.trim='datasource.params.host' )

  v-col( cols="6")
    CredentialsSelector( v-model.trim='datasource.secretId' )

</template>

<script lang="ts">
import {
  Vue, Component, VModel,
} from 'vue-property-decorator';
import { DatasourceDto, MicrostrategyParams } from '@/api';

type MicrostategySourceDto = DatasourceDto & { params: MicrostrategyParams; };

@Component
export default class MicrostategyConnection extends Vue {
  @VModel({ type: Object }) datasource!: MicrostategySourceDto;
}
</script>
