/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AsCodeFieldProfilingClauseDto = {
    kind: AsCodeFieldProfilingClauseDto.kind;
};

export namespace AsCodeFieldProfilingClauseDto {

    export enum kind {
        DUPLICATE_PERCENTAGE = 'DuplicatePercentage',
        DUPLICATE_COUNT = 'DuplicateCount',
        NULL_PERCENTAGE = 'NullPercentage',
        NULL_COUNT = 'NullCount',
    }


}
