/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PublicUserPermissionAssignmentDto = {
    domainId: string;
    domainRole?: PublicUserPermissionAssignmentDto.domainRole;
};

export namespace PublicUserPermissionAssignmentDto {

    export enum domainRole {
        EDITOR = 'EDITOR',
        VIEWER = 'VIEWER',
    }


}
