<script setup lang="ts">import { ref as _ref, computed as _computed } from 'vue';

import { IncidentLightDto, IncidentService } from '@/api';
import SiffletifyDataFooter
  from '@/components/siffletify/siffletify-data-footer/siffletify-data-footer.vue';
import { DataOptions } from 'vuetify';
import type { QueryType } from '@/utils/query';
import { computed } from 'vue';
import i18n from '@/i18n';
import IncidentsForm from '@/components/incidents/Incidents-Form.vue';
import IncidentsMergeForm from '@/components/incidents/IncidentsMergeForm.vue';

interface IncidentsResultsProps {
  incidents: IncidentLightDto[],
  incidentsCount: number,
  searchParams: Parameters<typeof IncidentService.getAllIncident>[0]['requestBody'],
  isLoading?: boolean
}

type IncidentsResultsEmits = {
  (event: 'onFilterChange', filter: { [key: string]: QueryType | number }): void
  (event: 'onEdit'): void
}

const props = defineProps({
  incidents: null,
  incidentsCount: null,
  searchParams: null,
  isLoading: { type: Boolean, default: false }
});

const emit = defineEmits(["onFilterChange", "onEdit"]);

let selectedIncidents = _ref<IncidentLightDto[]>([]);

const incidentsFormRef = _ref<InstanceType<typeof IncidentsForm> | null>(null);
const incidentsMergeFormRef = _ref<InstanceType<typeof IncidentsMergeForm> | null>(null);

const options = _computed(() => ({ itemsPerPage: props.searchParams.itemsPerPage, page: props.searchParams.page }));
const from = computed(() => ((options.value.page! - 1) * options.value.itemsPerPage!) + 1);
const to = computed(() => ((options.value.page! - 1) * options.value.itemsPerPage! + options.value.itemsPerPage! <= props.incidentsCount ? (options.value.page! - 1) * options.value.itemsPerPage! + options.value.itemsPerPage! : props.incidentsCount));
const resultsLabel = computed(() => `${from.value}-${to.value} ${i18n.tc('incidents.total_of', props.incidentsCount)}`);
const selectedLabel = computed(() => `${selectedIncidents.value.length} ${i18n.t('incidents.selected')}`);
const areAllChecked = _computed(() => props.incidents.every((incident: IncidentLightDto) => selectedIncidents.value.some((selectedIncident: IncidentLightDto) => incident.id === selectedIncident.id)));
const isIndeterminate = _computed(() => props.incidents.some((incident: IncidentLightDto) => selectedIncidents.value.some((selectedIncident: IncidentLightDto) => incident.id === selectedIncident.id)) && !areAllChecked.value);
const hasSelectionFromOtherPages = _computed(() => selectedIncidents.value.some((selectedIncident: IncidentLightDto) => !props.incidents.some((incident: IncidentLightDto) => incident.id === selectedIncident.id)));
const incidentsId = _computed(() => selectedIncidents.value.map((incident: IncidentLightDto) => incident.id));

const updateOptions = (option: Partial<DataOptions>) => {
  emit('onFilterChange', { page: option.page!, itemsPerPage: option.itemsPerPage! });
};

const clearSelection = () => {
  selectedIncidents.value = [];
};

const handleSelect = () => {
  if (!areAllChecked.value || isIndeterminate.value) {
    if (hasSelectionFromOtherPages.value) {
      selectedIncidents.value = selectedIncidents.value.concat(props.incidents);
    } else {
      selectedIncidents.value = props.incidents;
    }
    return;
  }

  if (areAllChecked.value) {
    selectedIncidents.value = selectedIncidents.value.filter((selectedIncident: IncidentLightDto) => !props.incidents.some((incident: IncidentLightDto) => incident.id === selectedIncident.id));
  }
};

const onEdit = () => {
  emit('onEdit');
};

const handleIncidentsEdit = () => {
  incidentsFormRef.value?.handleIncidentsEdit();
};

/**
 * Handle incidents merge
 */
const handleIncidentsMerge = () => {
  incidentsMergeFormRef.value?.handleIncidentsMerging();
};

/**
 * Incidents merged success
 */
const onIncidentsMerged = () => {
  clearSelection();
  emit('onEdit');
};
</script>

<template lang="pug">
v-data-iterator(
  v-model="selectedIncidents"
  :items="incidents"
  :loading="isLoading"
  :server-items-length="incidentsCount"
  :options="options"
  hide-default-footer
  data-cy="incidents-results-iterator"
)
  template(v-slot:loading)
    MonitorsResultsPlaceholder

  template(v-slot:no-data)
    NoDataAvailablePlaceholder(
      :text="$t('no_data.no_incidents_found')"
      :secondary-text="$t('no_data.no_results_found')"
    )

  template(v-slot:header)
    .d-flex.flex-column.pl-2
      .d-flex.align-center.justify-space-between.incidents-actions
        .d-flex.align-center(v-if="!isLoading && incidents.length")
          v-simple-checkbox(
            color="primary"
            :value="areAllChecked"
            :indeterminate="isIndeterminate"
            @input="handleSelect"
          )
          span.font-weight-medium {{ resultsLabel }}
        .d-flex.align-center(v-if="selectedIncidents.length")
          v-btn.mr-3(color="secondary" text @click="clearSelection")
            | {{ selectedLabel }}
            v-icon(right) icon-dismiss

          SButton.mr-4(
            icon="icon-edit"
            :text="$t('common.words.edit')"
            color="secondary"
            variant="outlined"
            @click="handleIncidentsEdit")

          SButton(
            :disabled="selectedIncidents.length < 2"
            icon="icon-arrow-merge"
            :text="$t('incidents.merge_incidents')"
            color="secondary"
            variant="outlined"
            data-cy="merge-incidents"
            @click="handleIncidentsMerge")

    IncidentsForm(
      ref="incidentsFormRef"
      :incidentsId="incidentsId"
      @onEdit="onEdit")

    IncidentsMergeForm(
      ref="incidentsMergeFormRef"
      :mergeableIncidents="selectedIncidents"
      @onMerge="onIncidentsMerged"
    )

      v-divider.mt-3

  template(v-slot:item="itemProps")
    IncidentsResultsCard(:item-props="itemProps")

  template(v-slot:footer="footer")
    // eslint-disable-next-line vue/valid-v-bind-sync
    SiffletifyDataFooter(:footer.sync="footer" :items-per-page-options="[10, 25, 50, 100]" @update="updateOptions($event)")

</template>

<style lang="scss" scoped>
.incidents-actions {
  min-height: 36px;
}
</style>
