<script setup  lang="ts">import { computed as _computed } from 'vue';

import DatasetPanel from '@/components/panels/Dataset-Panel.vue';
import lineageModule from '@/store/modules/lineage';
import { getModule } from 'vuex-module-decorators';
import store from '@/store';
import { Node } from './lib/lineage/index';

const lineage = getModule(lineageModule, store);

let node = _computed({
  get: () => lineage.getLineageState.selectedNode,
  set: (setNode: Node | null) => lineage.setLineageSelectedNode(setNode),
});

const component = _computed(() => {
  switch (node.value?.data.entityType) {
    case 'DATASET':
      return DatasetPanel;
    case 'DASHBOARD':
      return DatasetPanel;
    case 'DECLARED_ASSET':
      return DatasetPanel;
    case 'TRANSFORMATION':
      return DatasetPanel;
    default:
      return null;
  }
});

const closePanel = () => {
  node.value = null;
  lineage.resetAllSelectedNodeCssClasses();
};
</script>

<template lang="pug">
v-slide-x-reverse-transition
  v-card#lineage-panel( v-if="node" class="border-left d-flex" rounded="0")
    v-btn( icon class="mr-4" id="lineage-panel--close-btn" x-small @click="closePanel" )
      v-icon icon-dismiss
    component( :is="component" :data="node.data" )

</template>

<style lang="scss">
#lineage {
  &-panel {
    position: absolute;
    top: 1px;
    right: -32px;
    bottom: 0;
    left: auto;
    padding-right: 10px;
    z-index: 1;
    width: 30%;
    min-width: 400px;
    overflow-y: scroll;
    overflow-x: hidden;

    &--close-btn {
      position: absolute;
      top: 8px;
      right: 8px;
      z-index: 2;
    }
  }
}
</style>
