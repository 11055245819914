/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateAccessTokenRequest = {
    name?: string;
    role?: CreateAccessTokenRequest.role;
};

export namespace CreateAccessTokenRequest {

    export enum role {
        ADMIN = 'Admin',
        EDITOR = 'Editor',
        VIEWER = 'Viewer',
    }


}
