<template lang="pug">
  v-card.d-flex.flex-column.my-3.justify-center(class="open-card")
    span(class="total") {{ total }}
    div
      span.text-truncate {{ status }}
      span.ml-1.text-truncate( v-if="summary") {{ formatNumber(percentage)  }}
    v-alert.mt-1.pt-0.pb-1.px-0.text-truncate( dense text :color="color" width="auto" )
      v-icon.mr-1( :color="color" small ) {{ icon }}
      span.caption.text-truncate {{ formatNumber(lastWeek) }} {{ $t('dashboard.from_last_week') }}
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import authModule from '@/store/modules/auth';
import store from '@/store';
import { IncidentGroupSummaryDto } from '@/api';

const auth = getModule(authModule, store);

@Component
export default class DashboardIncidentsOpenCard extends Vue {
  @Prop({ type: Object, required: false }) summary!: IncidentGroupSummaryDto;

  @Prop({ type: String }) status!: IncidentGroupSummaryDto;

  @Prop({ type: Number }) averageResponseRate!: number;

  @Prop({ type: Number }) rateComparisonLastWeek!: number;

  get total() {
    if (this.averageResponseRate) {
      return `${this.averageResponseRateInHours}h` as string;
    }
    return this.summary?.totalIncidents ? this.summary?.totalIncidents : 0;
  }

  get percentage() {
    return this.summary?.percentage ? this.summary?.percentage : 0;
  }

  get icon() {
    switch (true) {
      case this.comparisonLastWeek && this.comparisonLastWeek > 0:
        return 'icon-arrow-trending-up';
      case this.comparisonLastWeek && this.comparisonLastWeek < 0:
        return 'icon-arrow-trending-down';
      default:
        return '';
    }
  }

  get color() {
    switch (true) {
      case this.comparisonLastWeek && this.comparisonLastWeek > 0:
        return 'iconDanger';
      case this.comparisonLastWeek && this.comparisonLastWeek < 0:
        return 'iconSuccess';
      default:
        return 'iconInfo';
    }
  }

  get averageResponseRateInHours() {
    return (this.averageResponseRate / 3600).toFixed?.(0);
  }

  get comparisonLastWeek() {
    return this.summary?.comparisonLastWeek as number ?? 0;
  }

  get lastWeek() {
    return this.comparisonLastWeek as number || this.rateComparisonLastWeek as number;
  }

  formatNumber(value: number) {
    return value ? `(${value?.toFixed?.(1)}%)` : '0%';
  }
}
</script>

<style lang="scss" scoped>
.open-card {
  color: var(--v-secondary-base);
}

.total {
  font-style: normal;
  font-weight: 600;
  font-size: 3rem;
  line-height: 3rem;
  color: var(--v-secondary-base);
}
</style>
