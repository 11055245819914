<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="nameRules"
    :counter='counter'
    :label="label"
    data-cy="engine"
    dense clearable outlined)

</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inputs } from '@/@types/types';
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';

@Component
export default class InputEngine extends Vue {
  @Prop({ type: String }) label!: string;

  counter = Inputs.INPUT_LOW_CHARS;

  nameRules = [
    (v: string) => isConvertibleToNotBlankString(v) || 'Engine is required',
    (v: string) => (v && v.length <= this.counter) || `Max ${this.counter} characters`,
  ];
}
</script>
