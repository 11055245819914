import Vuetify from '@/plugins/vuetify';

/**
 * Returns the public path of a image / svg file
 * @param {string} filename - The name of the file that is in public/next
 * @param {string} folderName - The name of the folder that is in public/next
 * @param {string} type - Either the dark or light mode to match corresponding folder
 * @return string - The complete path of the needed file
 */
export default (filename: string | undefined, folderName: string, type = Vuetify.framework.theme.dark ? 'dark' : 'light') => (filename
    ? `/next/images/${folderName}/${type}/${filename.toLowerCase()}.svg`
    : '');

declare module 'vue/types/vue' {
  interface Vue {
    src: (filename: string | undefined, folderName?: string, type?: string) => string;
  }
}
