import {
  MAIN_ROUTES,
  SUB_ROUTES,
} from '@/router/components';
import { BackgroundColors } from '@/@types/types';
import i18n from '@/i18n';

export default {
  path: 'sources',
  meta: {
    requiresAuth: true,
    breadCrumb: 'Sources',
  },
  component: MAIN_ROUTES.DATASOURCES,
  children: [{
    path: '',
    name: 'sources',
    meta: {
      requiresAuth: true,
      hideBreadCrumb: true,
      breadCrumb: i18n.t('sources.search'),
      background: BackgroundColors.PRIMARY,
      appBar: BackgroundColors.PRIMARY,
    },
    component: SUB_ROUTES.DATASOURCES_SEARCH,
  }, {
    path: 'source/add',
    name: 'sources.source.new',
    meta: {
      requiresAuth: true,
      breadCrumb: i18n.t('sources.new_source'),
      breadCrumbFromParamName: true,
      background: BackgroundColors.PRIMARY,
      appBar: BackgroundColors.PRIMARY,
    },
    props: true,
    component: SUB_ROUTES.DATASOURCE_ADD,
  }, {
    path: 'source/:id/',
    name: 'sources.source',
    meta: {
      requiresAuth: true,
      breadCrumbFromParamName: true,
      params: ['id', 'name'],
      background: BackgroundColors.PRIMARY,
      appBar: BackgroundColors.PRIMARY,
    },
    props: true,
    component: SUB_ROUTES.DATASOURCE,
    children: [{
      path: 'overview',
      name: 'sources.source.overview',
      props: true,
      meta: {
        requiresAuth: true,
        hideBreadCrumb: true,
        breadCrumb: i18n.t('sources.overview'),
        background: BackgroundColors.PRIMARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.DATASOURCE_OVERVIEW,
    }, {
      path: 'runs',
      name: 'sources.source.runs',
      props: true,
      meta: {
        requiresAuth: true,
        hideBreadCrumb: true,
        breadCrumb: i18n.t('sources.runs'),
        background: BackgroundColors.PRIMARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.DATASOURCE_RUNS,
    }],
  }, {
    path: 'source/:id/edit',
    name: 'sources.source.edit',
    meta: {
      requiresAuth: true,
      breadCrumb: i18n.t('sources.edit_source'),
      breadCrumbFromParamName: true,
      background: BackgroundColors.PRIMARY,
      appBar: BackgroundColors.PRIMARY,
    },
    props: true,
    component: SUB_ROUTES.DATASOURCE_EDIT,
  }, {
    path: 'source/:id/copy',
    name: 'sources.source.copy',
    meta: {
      requiresAuth: true,
      breadCrumb: i18n.t('sources.copy'),
      breadCrumbFromParamName: true,
      background: BackgroundColors.PRIMARY,
      appBar: BackgroundColors.PRIMARY,
    },
    props: true,
    component: SUB_ROUTES.DATASOURCE_EDIT,
  }],
};
