import { FeatureConsumptionDto, FeatureService } from '@/api';
import {
  Action, Module, Mutation, VuexModule, config,
} from 'vuex-module-decorators';
import getEnv from '@/utils/env';

config.rawError = true;

@Module({ namespaced: true, name: 'features' })
export default class featuresModule extends VuexModule {
  features: FeatureConsumptionDto = {
    monitoredDatasets: 0,
  };

  appConfig = {
    VUE_APP_MAX_MONITORED_TABLES: '',
  };

  get siffletIsInDemoMode() {
    if (+this.appConfig.VUE_APP_MAX_MONITORED_TABLES === 0) {
      return false;
    }
    return +this.appConfig.VUE_APP_MAX_MONITORED_TABLES <= this.features.monitoredDatasets;
  }

  @Mutation
  SET_FEATURES(payload: FeatureConsumptionDto) {
    this.features = payload;
  }

  @Mutation
  SET_APP_MAX_MONITORED_TABLES(payload: string) {
    this.appConfig.VUE_APP_MAX_MONITORED_TABLES = payload;
  }

  @Action
  async getFeatures() {
    const features = await FeatureService.getFeatureConsumption();
    this.SET_FEATURES(features);
    this.SET_APP_MAX_MONITORED_TABLES(getEnv('VUE_APP_MAX_MONITORED_TABLES') || '0');
  }
}
