/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { UserPermissionAssignmentDto } from './UserPermissionAssignmentDto';

export type CreateUserUiRequestDto = {
    domains: Array<UserPermissionAssignmentDto>;
    email: string;
    name: string;
    password: string;
    role: CreateUserUiRequestDto.role;
};

export namespace CreateUserUiRequestDto {

    export enum role {
        ADMIN = 'ADMIN',
        SYSTEM_EDITOR = 'SYSTEM_EDITOR',
        SYSTEM_VIEWER = 'SYSTEM_VIEWER',
    }


}
