<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 120" :height="height" :width="width">
      <ellipse cx="70" cy="50" rx="18" ry="32" stroke="transparent" :fill="between" />
      <circle id="left" cx="50" cy="50" r="40" :stroke="color1" stroke-width="6" fill="#d3d3d3" />
      <circle id="right" cx="90" cy="50" r="40" :stroke="color2" stroke-width="6" fill="transparent" />
      <circle id="left" cx="50" cy="50" r="40" :stroke="color1" stroke-width="6" fill="transparent" />
      <circle id="right" cx="90" cy="50" r="40" :stroke="color2" stroke-width="6" fill="transparent" />
  </svg>
</template>

<script lang="ts">
import {
 Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class ConditionalRulesJoinTypeIconLeft extends Vue {
  @Prop({ type: String }) readonly joinType!: string;

  @Prop({ type: String }) readonly color1!: string;

  @Prop({ type: String }) readonly color2!: string;

  @Prop({ type: String }) readonly fill1!: string;

  @Prop({ type: String }) readonly fill2!: string;

  @Prop({ type: String }) readonly between!: string;

  @Prop({ type: String }) readonly width!: string;

  @Prop({ type: String }) readonly height!: string;
}
</script>

<style scoped>

</style>
