/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AsCodeCalendarReferenceDto } from './AsCodeCalendarReferenceDto';

export type AsCodeDynamicThresholdDto = {
    bounds?: AsCodeDynamicThresholdDto.bounds;
    excludedDates?: Array<AsCodeCalendarReferenceDto>;
    sensitivity?: number;
};

export namespace AsCodeDynamicThresholdDto {

    export enum bounds {
        MIN_AND_MAX = 'MinAndMax',
        MIN = 'Min',
        MAX = 'Max',
    }


}
