<template lang="pug">
div( data-cy="tags" )
  Tag.mr-1(
    v-for="tag in truncatedTags"
    v-bind="$attrs"
    :tag="tag"
    :key="tag.id" )

  v-tooltip(
    v-if="isTruncated"
    nudge-top="5"
    content-class="extended-tooltip tooltip-arrow-bottom with-events"
    z-index="2"
    top
    :value="tooltipVisible")
    template( v-slot:activator="{ attrs }" )
      Tag( v-bind="attrs" :tag="plusTag" @mouseenter.native="openTooltip" @mouseleave.native="closeTooltip" )

    .d-flex.flex-column.align-start(
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      )
      Tag(
        v-for="(tag, i) in otherTags"
        :class="i ? 'mt-2' : ''"
        :tag="tag"
        :key="tag.id" )

</template>

<script lang="ts">
import { TagDto } from '@/api';
import { Vue, Component, Prop } from 'vue-property-decorator';

const MAX_TAGS = 1;

@Component
export default class Tags extends Vue {
  @Prop(Array) readonly tags!: TagDto[];

  @Prop({ type: Boolean, default: () => false }) readonly truncated!: boolean;

  isInTooltip = false;

  tooltipVisible = false;

  isInPlusTag = false;

  closeDelay = 1000;

  openTooltip() {
    this.tooltipVisible = true;
    this.isInTooltip = false;
    this.isInPlusTag = true;
  }

  closeTooltip() {
    this.isInPlusTag = false;
    setTimeout(() => {
      if (!this.isInTooltip) this.tooltipVisible = false;
    }, 400);
  }

  handleMouseEnter() {
    this.isInTooltip = true;
    this.tooltipVisible = true;
    this.isInPlusTag = false;
  }

  handleMouseLeave() {
    this.isInTooltip = false;
    setTimeout(() => {
      if (!this.isInPlusTag) this.tooltipVisible = false;
    }, this.closeDelay);
  }

  get isTruncated() {
    return this.truncated && this.tags.length > MAX_TAGS;
  }

  get truncatedTags() {
    return this.truncated ? this.tags.slice(0, MAX_TAGS) : this.tags;
  }

  get otherTags() {
    return this.truncated ? this.tags.slice(MAX_TAGS) : [];
  }

  get plusTag() {
    return {
      id: 'plus',
      name: `+${this.tags.length - MAX_TAGS}`,
    };
  }
}
</script>
