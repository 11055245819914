/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BaseSearchFilterElementDto } from './BaseSearchFilterElementDto';

export type AssetIngestionSearchFilterElementDto = (BaseSearchFilterElementDto & {
    id?: AssetIngestionSearchFilterElementDto.id;
    label?: string;
});

export namespace AssetIngestionSearchFilterElementDto {

    export enum id {
        DECLARATIVE = 'DECLARATIVE',
        SIFFLET_SOURCED = 'SIFFLET_SOURCED',
    }


}
