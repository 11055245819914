import Port from './Port';

export default class Link {
    readonly source: Port;

    readonly target: Port;

    readonly id: string;

    readonly dataUrn : string;

    readonly connectedElementsUrnData : string[];

    readonly creationMethod: 'BARRACUDA_LINEAGE_EXTRACTION' | 'DECLARATIVE_LINEAGE_API';

    constructor(
      source: Port,
      target: Port,
      creationMethod: 'BARRACUDA_LINEAGE_EXTRACTION' | 'DECLARATIVE_LINEAGE_API',
    ) {
      this.source = source;
      this.target = target;
      this.creationMethod = creationMethod;
      this.id = JSON.stringify([source.parentId, target.parentId]);
      this.dataUrn = `${source.parentId}-${target.parentId}`;
      this.connectedElementsUrnData = [source.parentId, this.dataUrn, target.parentId];
    }
}
