<template lang="pug">
div
    v-checkbox.mb-2( v-model="willRemove" v-if="canRemove" dense hide-details label="Remove existing" )
    v-textarea(
      v-bind="$attrs"
      v-on="$listeners"
      :rules="messageRules"
      :disabled="disabled"
      label='Message'
      :counter='_counter'
      data-cy="message"
      dense outlined)

</template>

<script lang="ts">
import { Vue, Component, Watch } from 'vue-property-decorator';
import { Inputs } from '@/@types/types';

@Component
export default class InputMessage extends Vue {
  max = Inputs.INPUT_HIGH_CHARS;

  willRemove = false;

  messageRules = [
    (v: string) => {
      if (v) return v.length <= Number(this._counter) || `Max ${this._counter} characters`;
      return true;
    },
  ];

  get disabled(): boolean {
    return this.willRemove;
  }

  get _counter() {
    return this.$attrs.counter || this.max;
  }

  get canRemove(): boolean {
    return 'can-remove' in this.$attrs;
  }

  @Watch('willRemove')
  willRemoveHandler(value: boolean) {
    this.$emit('input', value ? null : undefined);
  }
}
</script>
