/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDomainRequestDto } from '../models/CreateDomainRequestDto';
import type { DomainDto } from '../models/DomainDto';
import type { SearchCollectionDomainDto } from '../models/SearchCollectionDomainDto';
import type { UpdateDomainRequestDto } from '../models/UpdateDomainRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DomainService {

    /**
     * Get all domains
     * @returns SearchCollectionDomainDto Successfully fetch all domains
     * @throws ApiError
     */
    public static getAllDomains({
        textSearch,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<SearchCollectionDomainDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/domains',
            query: {
                'textSearch': textSearch,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a domain
     * @returns any Successfully create Domain
     * @throws ApiError
     */
    public static createDomainUi({
        requestBody,
    }: {
        requestBody: CreateDomainRequestDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/domains',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a domain
     * @returns void
     * @throws ApiError
     */
    public static deleteDomain({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/domains/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get domain by id
     * @returns DomainDto Successfully fetch a domain by id
     * @throws ApiError
     */
    public static getDomainById({
        id,
    }: {
        id: string,
    }): CancelablePromise<DomainDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/domains/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a domain
     * @returns any Successfully update Domain
     * @throws ApiError
     */
    public static updateDomainUi({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateDomainRequestDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ui/v1/domains/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
