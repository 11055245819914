/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BaseSearchFilterElementDto } from './BaseSearchFilterElementDto';

export type AssetSearchFilterElementDto = (BaseSearchFilterElementDto & {
    icon?: AssetSearchFilterElementDto.icon;
    id?: string;
    name?: string;
});

export namespace AssetSearchFilterElementDto {

    export enum icon {
        TABLE_AND_VIEW = 'TABLE_AND_VIEW',
        PIPELINE = 'PIPELINE',
        DASHBOARD = 'DASHBOARD',
        MONITOR = 'MONITOR',
        ML_MODEL = 'ML_MODEL',
        GENERIC = 'GENERIC',
    }


}
