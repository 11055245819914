/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MailAlertingHookDto } from '../models/MailAlertingHookDto';
import type { StringPayload } from '../models/StringPayload';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MailService {

    /**
     * Save given mail addresses as alerting tags
     * @returns MailAlertingHookDto Successfully created mail alerting hooks
     * @throws ApiError
     */
    public static saveMailAddresses({
        requestBody,
    }: {
        requestBody: StringPayload,
    }): CancelablePromise<Array<MailAlertingHookDto>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/mail/addresses/_save',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
