<template lang="pug">
v-row
  v-col( cols="6")
    InputName( v-model.trim='datasource.name' label='Name' )

  v-col( cols="6")
    InputHost( v-model.trim='datasource.params.host' )

  v-col( cols="6")
    InputPort( v-model.trim='datasource.params.port' )

  v-col( cols="6")
    InputDatabase( v-model.trim='datasource.params.database' )

  v-col( cols="6")
    InputSchema( v-model.trim='datasource.params.schema' )

  v-col( cols="6")
    CredentialsSelector( v-model.trim='datasource.secretId' )

</template>

<script lang="ts">
import {
  Vue, Component, VModel, Watch,
} from 'vue-property-decorator';
import { DatasourceDto, SynapseParams, MysqlParams } from '@/api';

type SynapseSourceDto = DatasourceDto & { params: SynapseParams; };

@Component
export default class SynapseConnection extends Vue {
  @VModel({ type: Object }) datasource!: SynapseSourceDto;
}
</script>
