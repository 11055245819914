import { type MonitorSettingsOptions } from './common/types';
import { staticSettings } from './common/thresholdSettings';

const SiffletSumRule: MonitorSettingsOptions = {
    thresholdSettings: [
        staticSettings,
    ],
};

export default SiffletSumRule;
