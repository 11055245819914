/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateMetadataJobResponseDto = {
    requestStatus: CreateMetadataJobResponseDto.requestStatus;
};

export namespace CreateMetadataJobResponseDto {

    export enum requestStatus {
        SUCCESS = 'SUCCESS',
        TECHNICAL_ERROR = 'TECHNICAL_ERROR',
        THROTTLING_ERROR = 'THROTTLING_ERROR',
    }


}
