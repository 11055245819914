<template lang="pug">
v-select.join-selector(
  v-bind='$attrs'
  v-on='$listeners'
  :items="items"
  :label="$t('conditional-rules.select_join_type')"
  data-cy="join-selector"
  item-value="key"
  item-text="label"
  dense outlined
  :menu-props="{ bottom: true, offsetY: true }"
)
  template(v-slot:item="{ item }")
    .d-flex.pb-2
      ConditionalRulesJoinTypeIcon( color1="#058fe6" color2="#aa6ce2" :joinType="item.key" height="30" width="45" )
      .d-flex.flex-column
        .font-weight-medium {{ item.label }}
        .font-weight-light {{ item.description }}
</template>

<script lang="ts">
import { ConditionalRulesJoinType } from '@/@types/conditional-rules';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class JoinSelector extends Vue {
  items = [
    {
      key: ConditionalRulesJoinType.LEFT,
      label: this.$t('conditional-rules.left_join'),
      description: this.$t('conditional-rules.left_description'),
      img: 'left-join',
    },
    {
      key: ConditionalRulesJoinType.RIGHT,
      label: this.$t('conditional-rules.right_join'),
      description: this.$t('conditional-rules.right_description'),
      img: 'right-join',
    },
    {
      key: ConditionalRulesJoinType.INNER,
      label: this.$t('conditional-rules.inner_join'),
      description: this.$t('conditional-rules.inner_description'),
      img: 'inner-join',
    },
    {
      key: ConditionalRulesJoinType.OUTER,
      label: this.$t('conditional-rules.outer_join'),
      description: this.$t('conditional-rules.outer_description'),
      img: 'outer-join',
    },
  ];

  getIcon(name: string) {
    return `/next/images/nocode/${name}.svg`;
  }
}
</script>
