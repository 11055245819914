import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

declare module 'vue/types/vue' {
  interface Vue {
    formatDate: (value: string | number, customTimezone: string) => string;
  }
}

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Formats a timestamp or timestamp into a YYYY-MM-DD HH:mm:ss (Z) format
 * @param {string} value - A date or a timestamp
 * @param {string} customTimezone optional - A custom timezone
 * @return string - The formatted date
 */
export default (value: string | number, customTimezone = '') => {
  if (!value) return '';
  return customTimezone ? dayjs(value).tz(customTimezone).format('YYYY-MM-DD HH:mm:ss (Z)') : dayjs(value).tz().format('YYYY-MM-DD HH:mm:ss (Z)');
};
