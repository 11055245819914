/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FieldDto } from '../models/FieldDto';
import type { FieldSearchResultDto } from '../models/FieldSearchResultDto';
import type { SearchCollectionDatasetLightDto } from '../models/SearchCollectionDatasetLightDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DatasetService {

    /**
     * Fetch datasets
     * @returns SearchCollectionDatasetLightDto Successfully fetch datasets
     * @throws ApiError
     */
    public static getAllDataset({
        textSearch,
        urn,
        domain,
        page,
        itemsPerPage = 25,
        sort,
    }: {
        /**
         * Global text search
         */
        textSearch?: string,
        /**
         * Filter on given urns
         */
        urn?: Array<string>,
        /**
         * Domain searched
         */
        domain?: string,
        /**
         * The requested page number. Zero-based page index (0..N)
         */
        page?: number,
        /**
         * The number of elements to be returned inside the page. Pass a value of -1, to bypass pagination and fetch all items
         */
        itemsPerPage?: number,
        /**
         * The resource fields on which to apply the sort, format : property,ASC|DESC
         */
        sort?: Array<string>,
    }): CancelablePromise<SearchCollectionDatasetLightDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/datasets',
            query: {
                'textSearch': textSearch,
                'urn': urn,
                'domain': domain,
                'page': page,
                'itemsPerPage': itemsPerPage,
                'sort': sort,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @returns FieldSearchResultDto Successfully fetch field search results
     * @throws ApiError
     */
    public static getDatasetFields({
        id,
        type,
        includeRepeated = false,
    }: {
        id: string,
        type: 'any' | 'date' | 'time' | 'temporal' | 'temporal_butterfly' | 'numeric',
        includeRepeated?: boolean,
    }): CancelablePromise<Array<FieldSearchResultDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/datasets/{id}/_getFields',
            path: {
                'id': id,
            },
            query: {
                'type': type,
                'includeRepeated': includeRepeated,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * @returns FieldDto Successfully fetch fields
     * @throws ApiError
     */
    public static getFields({
        id,
    }: {
        id: string,
    }): CancelablePromise<Array<FieldDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/datasets/{id}/fields',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
