<template lang="pug">
ModalDialog( ref="modalDialogRef" :title="title")
  template( v-slot:body )
    v-form( ref='tagForm' v-model='isValid' )
      v-row
        v-col(cols='12')
          v-text-field(
            v-model.trim='tag.name'
            :rules="nameRules"
            :counter="nameCounter"
            label='Tag name'
            data-cy="tagName"
            dense clearable outlined required)

        v-col(cols='12' v-show='false')
          v-text-field(
            v-model.trim='tag.type'
            label='Type'
            data-cy="type"
            dense clearable outlined required)

        v-col(cols='12')
          v-textarea(
            v-model.trim='tag.description'
            :rules="descriptionRules"
            :counter="descriptionCounter"
            label='Description'
            data-cy="description"
            dense clearable outlined required)

  template( v-slot:actions )
    v-btn( :disabled="!isValid" color="primary" class="custom-primary" depressed @click='validate' ) {{ $t('common.words.save') }}

</template>

<script lang="ts">
import {
  Vue, Component, Prop, Emit, Ref,
} from 'vue-property-decorator';
import { TagService, TagDto } from '@/api';
import ModalDialog from '@/components/modal-dialog/Modal-Dialog.vue';
import { Inputs } from '@/@types/types';

@Component
export default class TagsEdit extends Vue {
  @Prop(Object) readonly selectedTag!: TagDto;

  @Ref() modalDialogRef! : ModalDialog;

  @Ref() tagForm!: HTMLFormElement;

  nameCounter = Inputs.INPUT_VERY_LOW_CHARS;

  descriptionCounter = Inputs.INPUT_LOW_CHARS;

  nameRules = [
    (value: string) => !!value || this.$t('tags.name_required'),
    (value: string) => (value && value.length <= this.nameCounter) || this.$tc('tags.max_chars', this.nameCounter),
  ];

  descriptionRules = [
    (value: string) => {
      if (value) return value.length <= this.descriptionCounter || this.$tc('tags.max_chars', this.descriptionCounter);
      return true;
    },
  ];

  tag: TagDto = {
    id: '',
    name: '',
    description: '',
    type: TagDto.type.GENERIC,
  };

  isOpen = false;

  isValid = false;

  editSingle = false;

  get title() {
    switch (true) {
      case this.editSingle:
        return this.$t('tags.edit_item', { item: this.tag.name });
      case this.modifyExisting:
        return this.$t('tags.edit_item', { item: this.selectedTag.name });
      default:
        return this.$t('tags.new_tag');
    }
  }

  get modifyExisting() {
    return !!this.selectedTag || this.editSingle;
  }

  init() {
    this.tagForm?.reset();
    this.editSingle = false;
    this.tag = {
      id: '',
      name: '',
      description: '',
      type: TagDto.type.GENERIC,
    };
  }

  addNewTag() {
    this.init();
    this.modalDialogRef.openDialog();
  }

  async editTag() {
    this.editSingle = true;
    this.tag = await TagService.getTagById({ id: this.selectedTag.id });
    this.modalDialogRef.openDialog();
  }

  async editSingleTag(tag: TagDto) {
    this.editSingle = true;
    this.tag = await TagService.getTagById({ id: tag.id });
    this.modalDialogRef.openDialog();
  }

  close() {
    this.modalDialogRef.closeDialog();
  }

  @Emit()
  async validate() {
    if (this.modifyExisting) {
      const tags = await TagService.getAllTag({ textSearch: this.tag.name });
      if (tags?.data!.filter((item) => item.name === this.tag.name && item.id !== this.tag.id).length === 0) {
        await TagService.updateTag({ id: this.tag.id, requestBody: this.tag });
      } else {
        this.$notify({
          title: this.$t('tags.error') as string,
          type: 'error',
          text: this.$t('tags.already_use_name') as string,
        });
      }
    } else {
       await TagService.createTag({ requestBody: this.tag });
    }
    this.editSingle = false;
    this.close();
  }
}
</script>

<style lang="scss" scoped>
::v-deep.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
  padding: 0 8px;
}
</style>
