<template lang="pug">
.d-flex.align-center.text-truncate( data-cy="severity" )
  template(v-if="!isFilter")
    v-icon.mr-1(small :color="color") {{ icon }}
    span.caption.lh-1.grey--text(v-if="!onlyValue") {{ text }}
  template(v-else)
    v-icon.mr-1(:color="color" style="font-size: 20px !important") {{ icon }}
    span.caption.text-truncate(v-if="!onlyValue" style="font-size: 14px !important; margin-top: 1px !important") {{ text }}
</template>

<script lang="ts">
import { CriticalitySearchFilterElementDto } from '@/api';
import {
  Vue, Component, VModel, Prop,
} from 'vue-property-decorator';

@Component
export default class Severity extends Vue {
  @VModel({ type: [String, Number] }) severity!: CriticalitySearchFilterElementDto['id'] | number;

  @Prop({ type: Boolean, default: false }) onlyValue!: boolean;

  @Prop({ type: Boolean, default: false }) isFilter!: boolean;

  get text() {
    switch (this.severity) {
      case CriticalitySearchFilterElementDto.id.CRITICAL:
      case 0:
        return this.$t('criticality.critical');
      case CriticalitySearchFilterElementDto.id.HIGH:
      case 1:
        return this.$t('criticality.high');
      case CriticalitySearchFilterElementDto.id.MODERATE:
      case 2:
        return this.$t('criticality.moderate');
      case CriticalitySearchFilterElementDto.id.LOW:
      case 3:
        return this.$t('criticality.low');
      default:
        return '';
    }
  }

  get color() {
    switch (this.severity) {
      case CriticalitySearchFilterElementDto.id.CRITICAL:
      case 0:
        return 'iconDanger';
      case CriticalitySearchFilterElementDto.id.HIGH:
      case 1:
        return 'iconAttention';
      case CriticalitySearchFilterElementDto.id.MODERATE:
      case 2:
        return 'iconWarning';
      case CriticalitySearchFilterElementDto.id.LOW:
      case 3:
        return 'iconNeutral';
      default:
        return null;
    }
  }

  get icon() {
    switch (this.severity) {
      case CriticalitySearchFilterElementDto.id.CRITICAL:
      case 0:
        return 'icon-chevron-triple-up';
      case CriticalitySearchFilterElementDto.id.HIGH:
      case 1:
        return 'icon-chevron-double-up';
      case CriticalitySearchFilterElementDto.id.MODERATE:
      case 2:
        return 'icon-chevron-up';
      case CriticalitySearchFilterElementDto.id.LOW:
      case 3:
        return 'icon-subtract-middle';
      default:
        return null;
    }
  }
}
</script>
