/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { JsonNode } from './JsonNode';

export type ChangeDto = {
    from?: JsonNode;
    fromWorkspaceId?: string;
    to?: JsonNode;
    toWorkspaceId?: string;
    type?: ChangeDto.type;
};

export namespace ChangeDto {

    export enum type {
        NONE = 'None',
        CREATE = 'Create',
        CREATE_IF_MISSING = 'CreateIfMissing',
        MOVE = 'Move',
        MOVE_AND_UPDATE = 'MoveAndUpdate',
        UPDATE = 'Update',
        DELETE = 'Delete',
    }


}
