<script lang="ts">
const MAX_OWNERS = 5
</script>
<script setup lang="ts">import { computed as _computed } from 'vue';

import { getModule } from 'vuex-module-decorators';
import type { UserDto } from '@/api';
import authModule from '@/store/modules/auth';
import store from '@/store';


const auth = getModule(authModule, store);

interface OwnersProps {
  owners: UserDto[],
  truncated?: boolean,
  isOverview?: boolean,
  facepile?: boolean
}

const props = defineProps({
  owners: null,
  truncated: { type: Boolean, default: false },
  isOverview: { type: Boolean, default: false },
  facepile: { type: Boolean, default: false }
});

const isTruncated = _computed(() => props.truncated && props.owners.length > MAX_OWNERS);
const isFacepile = _computed(() => props.facepile);
const visibleOwners = _computed(() => {
  const currentUserId = auth.getUser ? auth.getUser.id : null;
  const currentUserIdIndex = currentUserId != null ? props.owners.findIndex((owner) => owner.id === currentUserId) : -1;

  const tempOwners = [...props.owners];

  if (currentUserIdIndex !== -1) {
    const currentUser = tempOwners.splice(currentUserIdIndex, 1);
    tempOwners.unshift(currentUser[0]);
  }

  const ownersToDisplay = props.truncated ? tempOwners.slice(0, MAX_OWNERS) : tempOwners;
  return props.facepile ? ownersToDisplay.reverse() : ownersToDisplay;
});

const plusOwners = _computed(() => ({ id: 'plus', name: `+${props.owners.length - MAX_OWNERS}` }));
</script>

<template lang="pug">
.d-flex.align-center( data-cy="owners" )
  .flex( :class="{ facepile : isFacepile }")
    Owner(
      v-for="owner in visibleOwners"
      v-bind="$attrs"
      :owner="owner"
      :key="owner.id"
      :isOverview="isOverview"
      :facepile="facepile" )
  span.ml-2.caption.grey--text(v-if="isFacepile && isTruncated" data-cy="truncated-owners-sign") {{ plusOwners.name }}
</template>

<style lang="scss" scoped>
.facepile {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
}
</style>
