/* eslint-disable no-param-reassign */
import { getModule } from 'vuex-module-decorators';
import { LineageEntityDto } from '@/api/models/LineageEntityDto';
import lineageModule from '@/store/modules/lineage';
import store from '@/store';
import Link from './Link';
import Layer from './Layer';
import Node from './Node';
import Port from './Port';

export const indexes: {
  nodesIndex: Map<string, Node>
  fieldNodesIndex: Map<string, Node>
} = {
  nodesIndex: new Map(),
  fieldNodesIndex: new Map(),
};

export class Canvas {
  store: lineageModule;

  public height: number;

  public width: number;

  private layers: Map<number, Layer> = new Map();

  private _fieldLinks: Link[] = [];

  private _links: Link[] = [];

  private startNode!: LineageEntityDto;

  private internalState: {
    layers: Map<number, Layer>
    nodesIndex: Map<string, Node>
    fieldNodesIndex: Map<string, Node>
    _links: Link[]
    _fieldLinks: Link[]
  } | null = null;

  private lastAddedNodes: Node[] = [];

  private recordLastAddedNodes = false;

  constructor(_: { height: number; width: number }) {
    this.height = _.height;
    this.width = _.width;
    this.store = getModule(lineageModule, store);
  }

  public hasState() {
    this.debugQuery('hasState');
    return this.internalState !== null;
  }

  public nodes(): Node[] {
    this.debugQuery('nodes');
    return Array.from(this.layers.values()).flatMap((layer) => layer.nodes());
  }

  public fieldNodes() {
    this.debugQuery('fieldNodes');
    return this.nodes().flatMap((node) => node.children);
  }

  public links(): Link[] {
    this.debugQuery('links');
    return this._links;
  }

  public fieldLinks(): Link[] {
    this.debugQuery('fieldLinks');
    return this._fieldLinks;
  }

  public toggleFieldsOfNode(urn: string) {
    this.debugAction('toggleFieldsOfNode', urn);
    const node = this.getNodeByUrn(urn);
    if (node.childrenAreCollapsed) {
      this.expandFieldsOfNode(node);
    } else {
      this.collapseFieldsOfNode(node);
    }
  }

  public addStartNode(nodeData: LineageEntityDto, isReset = true) {
    this.debugAction('addStartNode', nodeData);
    this.startNode = nodeData;
    if (isReset) { indexes.nodesIndex.clear(); }
    const addedNode = this.getElseCreateLayerNumber(0).addStartNode(nodeData);
    this.updateNodesIndex(addedNode);
    this.expandOrCollapseAllFieldsOfNodes(addedNode);
    this.layout();
  }

  public deployDownstreamNodes(
    currentNodeUrn: string,
    downstreamNodeDatas: LineageEntityDto[],
  ) {
    this.debugAction(
      'deployDownstreamNodes',
      currentNodeUrn,
      downstreamNodeDatas,
    );
    const layerOfCurrentNode = this.findLayerContainsNode(currentNodeUrn);
    const downstreamLayer = this.findDownstreamLayer(layerOfCurrentNode);
    const addedDownstreamNodes = downstreamLayer.addNodes(
      downstreamNodeDatas.filter((node) => !indexes.nodesIndex.has(node.urn)),
    );
    this.updateNodesIndex(addedDownstreamNodes);
    this.expandOrCollapseAllFieldsOfNodes(addedDownstreamNodes);
    this.saveAddNodesAction(addedDownstreamNodes);
    this.layout();
    return addedDownstreamNodes;
  }

  public deployUpstreamNodes(
    currentNodeUrn: string,
    upstreamNodes: LineageEntityDto[],
  ) {
    this.debugAction('deployUpstreamNodes', currentNodeUrn, upstreamNodes);
    const layerOfCurrentNode = this.findLayerContainsNode(currentNodeUrn);
    const upstreamLayer = this.findUpstreamLayer(layerOfCurrentNode);
    const addedUpstreamNodes = upstreamLayer.addNodes(
      upstreamNodes.filter((node) => !indexes.nodesIndex.has(node.urn)),
    );
    this.updateNodesIndex(addedUpstreamNodes);
    this.expandOrCollapseAllFieldsOfNodes(addedUpstreamNodes);
    this.saveAddNodesAction(addedUpstreamNodes);
    this.layout();
    return addedUpstreamNodes;
  }

  public startRcordingLastAddedNodes() {
    this.lastAddedNodes = [];
    this.recordLastAddedNodes = true;
  }

  public saveRcordedLastAddedNodes() {
    this.recordLastAddedNodes = false;
  }

  private saveAddNodesAction(addedDownstreamNodes: Node[]) {
    this.store.setUndoState(true);
    if (this.recordLastAddedNodes) {
      this.lastAddedNodes.push(...addedDownstreamNodes);
    } else {
      this.lastAddedNodes = [...addedDownstreamNodes];
    }
  }

  public undoAddNodesAction() {
    const dataNodesToBeDeleted = this.lastAddedNodes.map((node) => node.data);
    this.layers.forEach((layer) => layer.deleteNodes(dataNodesToBeDeleted));
    this.resetCountOfDeployedNodes();
    this.removeFromIndexes(this.lastAddedNodes);
    this.layout();
    this.store.setUndoState(false);
  }

  private removeFromIndexes(lastAddedNodes: Node[]) {
    lastAddedNodes.forEach((node) => {
      indexes.nodesIndex.delete(node.data.urn);
      node.data.children.forEach((child) => indexes.fieldNodesIndex.delete(child.urn));
    });
  }

  public toggleFieldsExpanded() {
    this.debugAction('toggleFieldsExpanded');
    this.store.setFieldsExpanded(!this.store.canvasState.allFieldsAreExpanded);
    this.expandOrCollapseAllFieldsOfNodes(
      Array.from(indexes.nodesIndex.values()),
    );
    this.layout();
  }

  public setOnlyFieldsWithLinks(onlyFieldsWithLinks: boolean) {
    this.debugAction('setOnlyFieldsWithLinks', onlyFieldsWithLinks);
    this.store.setOnlyFieldsWithLinks(onlyFieldsWithLinks);
    this.layout();
  }

  public reset() {
    this.debugAction('reset');
    this.layers.clear();
    this._links = [];
    this._fieldLinks = [];
    this.addStartNode(this.startNode);
    this.layout();
  }

  private debugAction(method: string, ...params: any[]) {
    // console.debug(`Canvas->${method} `, ...params);
  }

  private debugQuery(method: string, ...params: any[]) {
    // console.debug(`Canvas<-${method} `, ...params);
  }

  private updateNodesIndex(nodes: Node[]) {
    nodes.forEach((node) => indexes.nodesIndex.set(node.data.urn, node));
  }

  private expandOrCollapseAllFieldsOfNodes(addedUpstreamNodes: Node[]) {
    if (this.store.canvasState.allFieldsAreExpanded) {
      addedUpstreamNodes.forEach((node) => {
        node.expandFields();
      });
    } else {
      addedUpstreamNodes.forEach((node) => {
        node.collapseFields();
      });
    }
  }

  private layout() {
    this.filter();
    Array.from(indexes.nodesIndex.values()).forEach((node) => node.balanceLocation.reset());
    const layerNumbers = Array.from(this.layers.keys()).sort();
    layerNumbers.forEach((layerNumber) => {
      const layer = this.layers.get(layerNumber);

      layer?.layout(this);
      layer?.nodes().forEach((node) => {
        node.data.downstreams.forEach((downstream) => {
          const oneDownstreamNode = indexes.nodesIndex.get(downstream.urn);
          if (oneDownstreamNode) {
            oneDownstreamNode.balanceLocation.addForce(node.positionInTheLayer);
          }
        });
      });
    });
    layerNumbers.forEach((layerNumber) => {
      this.layers.get(layerNumber)?.layout(this);
    });
    this.regenerateLinks(indexes.nodesIndex);
  }

  private collapseFieldsOfNode(node: Node) {
    node.collapseFields();
    this.layout();
  }

  private expandFieldsOfNode(node: Node) {
    node.expandFields();
    this.layout();
  }

  private regenerateLinks(_nodesIndex: Map<string, Node>) {
    this.resetCountOfDeployedNodes();
    this.generateLinks(_nodesIndex);
  }

  private generateLinks(_nodesIndex: Map<string, Node>) {
    this._links = [];
    this._fieldLinks = [];
    _nodesIndex.forEach((sourceNode, _, map) => {
      sourceNode.data.downstreams.forEach((downstream) => {
        const targetNode = map.get(downstream.urn);
        if (targetNode) {
          sourceNode.countOfDeployedDownstreamNodes += 1;
          targetNode.countOfDeployedUpstreamNodes += 1;
          this._links.push(
            new Link(
              new Port({
                location: sourceNode.sourcePoint(),
                parentId: sourceNode.data.urn,
              }),
              new Port({
                location: targetNode.targetPoint(),
                parentId: targetNode.data.urn,
              }),
              downstream.creationMethod!,
            ),
          );
          if (
            sourceNode.childrenAreExpanded
            && targetNode.childrenAreExpanded
          ) {
            sourceNode.children.forEach((sourceChildNode) => {
              sourceChildNode.data.downstreams.forEach((_downstream) => {
                const targetChildNode = targetNode.getChildByUrn(_downstream.urn);
                if (targetChildNode) {
                  this._fieldLinks.push(
                    new Link(
                      new Port({
                        location: sourceChildNode.sourcePoint(),
                        parentId: sourceChildNode.data.urn,
                      }),
                      new Port({
                        location: targetChildNode.targetPoint(),
                        parentId: targetChildNode.data.urn,
                      }),
                      _downstream.creationMethod!,
                    ),
                  );
                }
              });
            });
          }
        }
      });
    });
  }

  private resetCountOfDeployedNodes() {
    indexes.nodesIndex.forEach((node, _, map) => {
      node.data.downstreams.forEach((downstream) => {
        node.countOfDeployedDownstreamNodes = 0;
        const targetNode = map.get(downstream.urn);
        if (targetNode) {
          targetNode.countOfDeployedUpstreamNodes = 0;
        }
      });
    });
  }

  private getNodeByUrn(urn: string): Node {
    const node = indexes.nodesIndex.get(urn);
    if (node) {
      return node;
    }
    throw new Error(`cannot get Node by urn : ${urn}`);
  }

  private filter() {
    indexes.nodesIndex.forEach((node, _, map) => {
      node.stateVisibleOnlyFieldsWithLinks(
        this.store.canvasState.onlyFieldsWithLinks,
      );
    });
  }

  private findDownstreamLayer(layerOfCurrentNode: Layer) {
    return this.getElseCreateLayerNumber(layerOfCurrentNode.id + 1);
  }

  private findUpstreamLayer(layerOfCurrentNode: Layer) {
    return this.getElseCreateLayerNumber(layerOfCurrentNode.id - 1);
  }

  private findLayerContainsNode(urn: string): Layer {
    const foundLayers = Array.from(this.layers.values()).filter((layer) => layer.containsNode(urn));
    if (foundLayers.length === 0) {
      throw new Error(`cannot found Layer contains Node : ${urn}`);
    }
    if (foundLayers.length > 1) {
      throw new Error(`more than one layres contains Node : ${urn}`);
    }
    return foundLayers[0];
  }

  private getElseCreateLayerNumber(layerNumber: number) {
    const result = this.layers.get(layerNumber) ?? new Layer(layerNumber);
    this.layers.set(layerNumber, result);
    return result;
  }
}
