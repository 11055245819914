/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { QueryParameterDto } from './QueryParameterDto';

export type QueryGenerateRequestDto = {
    parameters: Array<QueryParameterDto>;
    queryTemplateMode: QueryGenerateRequestDto.queryTemplateMode;
    queryTemplateName: string;
    sourceDatasetId: string;
};

export namespace QueryGenerateRequestDto {

    export enum queryTemplateMode {
        MAIN = 'MAIN',
        DEBUG = 'DEBUG',
    }


}
