<template lang="pug">
v-card.pa-4(outlined width="320" class="border-all" :to="monitorLink")
  div.d-flex.align-start

    div.mr-2.d-flex.align-center.justify-center.icon-container
      v-icon.pa-2(medium color="iconInfo" ) icon-monitor-square

    div.d-flex.flex-column.infos-container
      span.grey--text.card-title {{ cardTitle }}
      v-tooltip(top)
        template(v-slot:activator='{ on }')
          span(v-on="on").font-weight-medium.rule-container {{ trimmedRuleName }}
        span {{ recommendation.rule.name }}

      div.mt-1.d-flex.align-center

        // interlinked
        div(v-if="isInterlinked").mt-1.d-flex.align-center
          div(v-for="(dataset, index) in ruleDatasets" :key="`${recommendation.id}-${dataset.id}`")
            v-tooltip(top)
              template(v-slot:activator='{ on }')
                v-img(v-on="on" :src="datasourceImage(index)").mr-1.datasource-image
              div.d-flex.align-center
                v-img(:src="datasourceImage(index)").mr-1.datasource-image
                span {{ assetName(index) }}
          span.grey--text {{ `${ruleDatasets.length} ${$t('glossary.data_assets')}` }}

        // not interlinked
        div(v-else).mt-1.d-flex.align-center
          v-img(:src="datasourceImage()").mr-1.datasource-image
          v-tooltip(top)
            template(v-slot:activator='{ on }')
              span(v-on="on").datasource-name.grey--text {{ assetName() }}
            div.d-flex.align-center
              v-img(:src="datasourceImage()").mr-1.datasource-image
              span {{ assetName() }}

  AiAssistantSuggestionCard(
    :title="$tc('dashboard.ai_assistant_recommendation', 1)"
    :can-collapse="hasMultipleSuggestions"
    :collapsed-height="collapsedHeight").mt-5

    template(v-slot:content)
      p.font-weight-medium.text-truncate.mb-2 {{ suggestionDescription }}
      .d-flex.align-center.mt-1(
        v-for="(suggestion, i) in suggestions"
        :key="`${suggestion.from}_${suggestion.to}_${suggestion.datasourceImage}_${i}`")
        v-tooltip(top)
          template(v-slot:activator='{ on }')
            v-img(:src="suggestion.datasourceImage" v-on="on").mr-1.datasource-image
          span {{ suggestion.datasetName }}
        .d-flex.align-center.justify-center(style="max-width: calc(100% - 20px);")
          .text-capitalize.grey--text.interlinked-current {{ suggestion.from || '--' }}
          v-icon(small).ml-6.mr-6 icon-arrow-right
          .text-capitalize.font-weight-medium.text-truncate {{ suggestion.to }}

  p.mt-3.primary--text.learn-more-link(@click.prevent.stop="openModal") {{ modalLinkLabel }}

  div.d-flex.justify-end.pt-2( @click.prevent )
    v-btn(@click="dismissSuggestion" text) {{ $t('common.words.dismiss') }}
    v-btn(@click="acceptSuggestion" text color="primary") {{ $t('common.words.accept') }}

</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  RuleMonitoringRecommendationChangeDto,
  RuleMonitoringRecommendationDto,
  RuleService,
} from '@/api';
import src from '@/utils/filters/src';
import {
  aiAssistantGenerateModalLinkLabel,
  aiAssistantGenerateDescription,
  aiAssistantGenerateMultipleChanges,
  aiAssistantGenerateInterlinkedDescription,
} from '@/utils/AiAssistantGenerators';

const MAX_RULE_NAME_LENGTH = 64;

@Component
export default class DashboardAIAssistantMonitorsCard extends Vue {
  @Prop({ type: Object }) readonly recommendation!: RuleMonitoringRecommendationDto;

  get collapsedHeight() {
    return this.recommendation.changes.length * 30;
  }

  get cardTitle() {
    return this.isInterlinked ? this.$t('dashboard.interlinked_metrics') : this.$tc('common.words.monitor', 1);
  }

  get ruleDatasets() {
    return this.recommendation.rule.datasets || [];
  }

  get isInterlinked() {
    return this.recommendation.changes[0].recommendationType === RuleMonitoringRecommendationChangeDto.recommendationType.INTERLINKED_METRIC_TIME_FIELD;
  }

  get hasMultipleSuggestions() {
    return this.recommendation.changes.length > 1;
  }

  get monitorLink() {
    return {
      name: 'monitors.rule.overview',
      params: {
        id: this.recommendation.rule.id,
      },
    };
  }

  datasourceImage(index = 0) {
    return src(this.ruleDatasets![index].datasourceType, 'datasources');
  }

  assetName(index = 0) {
    return this.ruleDatasets![index].name;
  }

  get suggestions() {
    return aiAssistantGenerateMultipleChanges(this.recommendation);
  }

  get suggestionDescription() {
    if (this.isInterlinked) {
      return aiAssistantGenerateInterlinkedDescription(this.recommendation);
    }

    return aiAssistantGenerateDescription(
      this.recommendation.changes[0].recommendationType,
      this.recommendation.changes[0].to || '',
      this.recommendation.changes[0].from! || '',
    );
  }

  get modalLinkLabel() {
    return aiAssistantGenerateModalLinkLabel(this.recommendation.changes[0].recommendationType); // we might challenge this
  }

  get trimmedRuleName() {
    if (this.recommendation.rule.name.length > MAX_RULE_NAME_LENGTH) {
      return `${this.recommendation.rule.name.substring(0, MAX_RULE_NAME_LENGTH)}...`;
    }

    return this.recommendation.rule.name;
  }

  acceptSuggestion() {
    RuleService.acceptRuleMonitoringRecommendation({ id: this.recommendation.id })
      .then(() => {
        this.$notify({
          title: this.$t('dashboard.ai_assistant_recommendation_accepted_title'),
          type: 'success',
          text: this.$t('dashboard.ai_assistant_recommendation_message'),
        });
        this.$emit('onFeedbackSent', this.recommendation.id);
      });
  }

  dismissSuggestion() {
    RuleService.dismissRuleMonitoringRecommendation({ id: this.recommendation.id })
      .then(() => {
        this.$emit('onFeedbackSent', this.recommendation.id);
      });
  }

  openModal() {
    this.$emit('openModal', this.recommendation.changes[0].recommendationType);
  }
}
</script>

<style lang="scss" scoped>
.card-title {
  font-size: 10px;
  letter-spacing: 0.8px;
  font-weight: bold;
  text-transform: uppercase;
}

.icon-container {
  min-width: 40px;
  overflow: hidden;
  border-radius: 50%;
  background: var(--v-bgAccentPrimary-base);
}

.infos-container {
  max-height: 85px;
  min-height: 85px;
}

.datasource-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 200px;
}

.datasource-image {
  width: 16px;
  max-width: 16px;
  height: 16px;
}

.learn-more-link {
  font-size: 12px;
  cursor: pointer;
}

.interlinked-current {
  text-align: right;
  width: 105px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
