<template lang="pug">
v-toolbar(flat v-if="!disablePagination")
  v-spacer
  v-pagination(
    :value.sync="footer.pagination.page"
    :length="footer.pagination.pageCount"
    :total-visible="totalVisible"
    :dark="$vuetify.theme.dark"
    @input="input($event)"
    class="elevation-0"
  )
  v-spacer
  span.d-flex.justify-end.align-center
    .d-flex.justify-end.caption.w-120.font-weight-medium(data-cy="pagination-count") {{ countOfTotal }}
    v-divider.mx-4( vertical )
    v-select(
      v-model="footer.options.itemsPerPage"
      :items="viewFormatted"
      dense
      variant="solo"
      class="view-select"
      hide-details="auto"
      @change="update"
      :menu-props="{ bottom: true, offsetY: true }"
      data-cy="pagination-view"
    )
      template(v-slot:selection='{ item }')
        .caption.font-weight-medium {{ $tc('design.view', 1) }} {{ item }}
</template>

<script lang="ts">
import {
  Component, Prop, Vue,
} from 'vue-property-decorator';
import { DataOptions, DataPagination } from 'vuetify';
import colors from '@/plugins/colors';

@Component
export default class SiffletifyDataFooter extends Vue {
  @Prop({ type: Object, required: true }) footer!: { options: DataOptions & { itemsPerPageOptions: number[] }, pagination: DataPagination };

  @Prop({ type: Array, required: false }) itemsPerPageOptions !: number[];

  @Prop({ type: String, required: false, default: 'top' }) scrollTo !: string;

  @Prop({ type: Number, required: false, default: 7 }) maximumVisible !: number;

  viewList = [5, 10, 25, 50, 100];

  get viewOptions() {
    if (this.itemsPerPageOptions) return this.itemsPerPageOptions;
    if (this.footer.options.itemsPerPageOptions) return this.footer.options.itemsPerPageOptions;
    return this.viewList;
  }

  get itemsPerPage() {
    return this.footer.options.itemsPerPage;
  }

  get viewFormatted() {
    const id = this.viewOptions.findIndex((item: number) => item > this.total);
    switch (true) {
      case (id === -1):
        return this.viewOptions;
      case (id === 0):
        return [this.viewOptions[0]];
      case (id > 0):
        return this.viewOptions.slice(0, id + 1);
      default:
        return this.viewOptions;
    }
  }

  get color() {
    return colors.grey.lighten4;
  }

  get isLastPage() {
    return this.footer.pagination.page === this.footer.pagination.pageCount;
  }

  get from() {
    return ((this.footer.pagination.page - 1) * this.footer.pagination.itemsPerPage) + 1;
  }

  get to() {
    return this.isLastPage ? this.total : (this.footer.pagination.page - 1) * this.footer.pagination.itemsPerPage + this.footer.pagination.itemsPerPage;
  }

  get total() {
    return this.footer?.pagination?.itemsLength;
  }

  get countOfTotal() {
    return `${this.from}-${this.to} of ${this.total}`;
  }

  get totalVisible() {
    return this.total < this.maximumVisible ? this.total - 1 : this.maximumVisible;
  }

  get disablePagination() {
    return this.total <= this.viewFormatted[0];
  }

  input(event: number) {
    this.footer.options.page = event;
    this.update();
  }

  update() {
    this.$emit('update', this.footer.options);
    if (this.scrollTo) this.$vuetify.goTo(this.scrollTo === 'top' ? 0 : `#${this.scrollTo}`);
    this.$forceUpdate();
  }
}
</script>

<style lang="scss">
.view-select {
  width: 85px;

  .v-input__slot::before,
  .v-input__slot::after {
    border-style: none !important;
  }

  .v-input__icon .v-icon.v-icon {
    font-size: 14px;
    margin-top: 6px;
    margin-right: 32px;
    color: map-deep-get($grey, "base") !important;
  }
}

.w-120 {
  width: 120px;
}
</style>
