<template lang="pug">
v-text-field(
    v-bind="$attrs"
    v-on="$listeners"
    :rules="datasetRules"
    label='Dataset Id'
    data-cy="dataset-id"
    dense clearable outlined required)

</template>

<script lang="ts">
import isConvertibleToNotBlankString from '@/utils/isConvertibleToNotBlankString';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class InputDatasetId extends Vue {
  datasetRules = [(v: string) => isConvertibleToNotBlankString(v) || 'Dataset Id is required'];
}
</script>
