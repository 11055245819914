<template lang="pug">
v-icon( small :color="color" ) icon-circle-fill
</template>

<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import colors from '@/plugins/colors';

@Component
export default class PositionDot extends Vue {
  @Prop({ type: Number }) index!: number;

  get squareColors() {
    return Object.values(colors.colorList);
  }

  get color() {
    return this.squareColors[this.index];
  }
}
</script>
