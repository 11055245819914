/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePasswordRequestDto } from '../models/ChangePasswordRequestDto';
import type { CreateUserUiRequestDto } from '../models/CreateUserUiRequestDto';
import type { SearchCollectionUserUiDto } from '../models/SearchCollectionUserUiDto';
import type { UpdateUserUiRequestDto } from '../models/UpdateUserUiRequestDto';
import type { UserAssetBookmarkDto } from '../models/UserAssetBookmarkDto';
import type { UserDetailDto } from '../models/UserDetailDto';
import type { UserPatchDto } from '../models/UserPatchDto';
import type { UserUiDto } from '../models/UserUiDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * User bookmarked assets
     * @returns UserAssetBookmarkDto List current user bookmarked assets
     * @throws ApiError
     */
    public static getCurrentUserAssetBookmarks(): CancelablePromise<Array<UserAssetBookmarkDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/user/asset-bookmarks',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Bookmark an asset for the current user
     * @returns any Bookmark an asset for the current user
     * @throws ApiError
     */
    public static createUserAssetBookmark({
        requestBody,
    }: {
        requestBody: UserAssetBookmarkDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/user/asset-bookmarks',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a user bookmarked asset by id
     * @returns UserAssetBookmarkDto Get a user bookmarked asset by id
     * @throws ApiError
     */
    public static getCurrentUserAssetBookmark({
        entityId,
    }: {
        entityId: string,
    }): CancelablePromise<UserAssetBookmarkDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/user/asset-bookmarks/{entityId}',
            path: {
                'entityId': entityId,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete user bookmarked asset
     * @returns any Delete user bookmarked asset by id
     * @throws ApiError
     */
    public static deleteCurrentUserAssetBookmark({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/user/asset-bookmarks/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete a user
     * @returns any Delete a user
     * @throws ApiError
     */
    public static deleteUser({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/user/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Patch user
     * @returns UserDetailDto Patch a user
     * @throws ApiError
     */
    public static patchUser({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UserPatchDto,
    }): CancelablePromise<UserDetailDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/ui/v1/user/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Change user password
     * @returns UserDetailDto Change user password
     * @throws ApiError
     */
    public static changePassword({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: ChangePasswordRequestDto,
    }): CancelablePromise<UserDetailDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ui/v1/user/{id}/_changePassword',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get all users
     * @returns SearchCollectionUserUiDto List of all Users
     * @throws ApiError
     */
    public static getAllUsers(): CancelablePromise<SearchCollectionUserUiDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/users',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a user from ui
     * @returns any Create a user from ui
     * @throws ApiError
     */
    public static createUserUi({
        requestBody,
    }: {
        requestBody: CreateUserUiRequestDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get current user from ui
     * @returns UserUiDto Get current user from ui
     * @throws ApiError
     */
    public static getCurrentUserUi(): CancelablePromise<UserUiDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/users/current',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a user by id
     * @returns UserUiDto UserUiDto object
     * @throws ApiError
     */
    public static getUserById({
        id,
    }: {
        id: string,
    }): CancelablePromise<UserUiDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a user from ui
     * @returns any Update a user from ui
     * @throws ApiError
     */
    public static updateUserUi({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateUserUiRequestDto,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ui/v1/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
