/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NodeDto = {
    type: NodeDto.type;
};

export namespace NodeDto {

    export enum type {
        DATASET = 'dataset',
        DATASET_FIELD = 'dataset_field',
    }


}
