<template lang="pug">

.rule-overview-info-status

  .d-flex.flex-column.flex-nowrap( v-if="hasRule" )

    .d-flex.align-center
      HealthDot( :health="rule.lastRunStatus" )
      b {{ $t('common.words.status') }}
    span.ml-5 {{ rule.lastRunStatus | titlecase }}

  v-skeleton-loader(
    v-else
    class="v-skeleton-loader-override"
    width="145"
    type="list-item-two-line" )

</template>

<script lang="ts">
// eslint-disable-next-line
// @ts-nocheck
import { Vue, Component, VModel } from 'vue-property-decorator';
import { RuleInfoDto } from '@/api';

@Component
export default class RuleOverviewInfoStatus extends Vue {
  @VModel({ type: Object }) rule!: RuleInfoDto;

  get hasRule() {
    return this.rule !== null;
  }
}
</script>
