const APP = () => import('@/App.vue');

const MAIN_ROUTES = {
  SIGN_IN: () => import('@/components/sign-in/Sign-In.vue'),
  DASHBOARD: () => import('@/views/Dashboard.vue'),
  CATALOG: () => import('@/views/Catalog.vue'),
  BUSINESS_GLOSSARY: () => import('@/views/Business-Glossary.vue'),
  MONITORS: () => import('@/views/Monitors.vue'),
  INTEGRATIONS: () => import('@/views/Integrations.vue'),
  DATASOURCES: () => import('@/views/Datasources.vue'),
  SETTINGS: () => import('@/views/Settings.vue'),
  INCIDENTS: () => import('@/views/Incidents.vue'),
  DESIGN_SYSTEM: () => import('@/views/Design-System.vue'),
  DOMAINS: () => import('@/views/Domains.vue'),
  GRAPH_TEST: () => import('@/components/graph-test/Graph-Test.vue'),
};

const SUB_ROUTES = {
  DASHBOARD: () => import('@/components/dashboard/Dashboard.vue'),

  CATALOG_SEARCH: () => import('@/components/catalog/Catalog.vue'),

  ASSET: () => import('@/components/asset/Asset.vue'),
  ASSET_OVERVIEW: () => import('@/components/asset/tabs/Asset-Tab-Overview.vue'),
  ASSET_SCHEMA: () => import('@/components/asset/tabs/Asset-Tab-Schema.vue'),
  ASSET_QUALITY: () => import('@/components/asset/tabs/Asset-Tab-Quality.vue'),
  ASSET_LINEAGE: () => import('@/components/asset/tabs/Asset-Tab-Lineage.vue'),
  ASSET_DOCUMENTATION: () => import('@/components/asset/tabs/Asset-Tab-Documentation.vue'),

  BUSINESS_GLOSSARY: () => import('@/components/business-glossary/Business-Glossary.vue'),

  MONITORS_SEARCH: () => import('@/components/monitors/Monitors.vue'),

  RULE: () => import('@/components/rule/Rule.vue'),
  RULE_OVERVIEW: () => import('@/components/rule/tabs/Rule-Tab-Overview.vue'),
  RULE_RUNS: () => import('@/components/rule/tabs/Rule-Tab-Runs.vue'),
  RULE_DETAILS: () => import('@/components/rule/tabs/Rule-Tab-Details.vue'),

  RULE_ADD: () => import('@/components/monitor-wizard/Monitor-Wizard.vue'),
  RULE_EDIT: () => import('@/components/monitor-wizard/Monitor-Wizard.vue'),

  INTEGRATION_CREDENTIALS: () => import('@/components/credentials/Credentials.vue'),

  DATASOURCES_SEARCH: () => import('@/components/datasources/Datasources.vue'),

  DATASOURCE: () => import('@/components/datasource/Datasource.vue'),
  DATASOURCE_OVERVIEW: () => import('@/components/datasource/tabs/Datasource-Tab-Overview.vue'),
  DATASOURCE_RUNS: () => import('@/components/datasource/tabs/Datasource-Tab-Runs.vue'),
  DATASOURCE_ADD: () => import('@/components/datasource/Datasource-Edit.vue'),
  DATASOURCE_EDIT: () => import('@/components/datasource/Datasource-Edit.vue'),

  SETTINGS_TAGS: () => import('@/components/tag/Tags.vue'),
  SETTINGS_USERS: () => import('@/components/users/Users.vue'),
  SETTINGS_TOKENS: () => import('@/components/access-tokens/AccessTokens.vue'),
  SETTINGS_SSO: () => import('@/components/sso/Sso.vue'),
  SETTINGS_TOOLS: () => import('@/components/collaboration-tools/CollaborationTools.vue'),

  DOMAINS: () => import('@/components/domains/Domains.vue'),
  DOMAINS_EDIT: () => import('@/components/domains/Domains-Edit.vue'),

  INCIDENTS: () => import('@/components/incidents/Incidents.vue'),

  INCIDENT: () => import('@/modules/Incidents/Incident/Incident.vue'),
  INCIDENT_OVERVIEW: () => import('@/modules/Incidents/Incident/tabs/overview/IncidentOverview.vue'),
  INCIDENT_LINEAGE: () => import('@/modules/Incidents/Incident/tabs/lineage/IncidentLineage.vue'),

  DESIGN_SYSTEM: () => import('@/components/design-system/Design-System.vue'),
  DESIGN_SYSTEM_SELECTORS: () => import('@/components/design-system/Design-System-Selectors.vue'),
  DESIGN_SYSTEM_TEST: () => import('@/components/design-system/Design-System-Test.vue'),
  DESIGN_SYSTEM_COLORS: () => import('@/components/design-system/Design-System-Colors.vue'),
  DESIGN_SYSTEM_BUTTONS: () => import('@/components/design-system/Design-System-Buttons.vue'),
  DESIGN_SYSTEM_PAGINATION: () => import('@/components/design-system/Design-System-Pagination.vue'),
  DESIGN_SYSTEM_DATA_FOOTER: () => import('@/components/design-system/Design-System-Data-Footer.vue'),
  DESIGN_SYSTEM_DATA_TABLE: () => import('@/components/design-system/Design-System-Data-Table.vue'),
  DESIGN_SYSTEM_DATA_ITERATOR: () => import('@/components/design-system/Design-System-Data-Iterator.vue'),
  DESIGN_SYSTEM_CUSTOM_V_JSF_CUSTOM_CODE: () => import('@/components/design-system/Design-System-V-Jsf-Custom-Code/Design-System-V-Jsf-Custom-Code.vue'),
  DESIGN_SYSTEM_CUSTOM_V_JSF_SMART_REGEX: () => import('@/components/design-system/Design-System-V-Jsf-Smart-Regex.vue'),
  DESIGN_SYSTEM_NESTED_TABLES: () => import('@/components/design-system/Design-System-Nested-Tables.vue'),
  DESIGN_SYSTEM_NESTED_SCHEMA: () => import('@/components/design-system/Design-System-Nested-Schema.vue'),
  DESIGN_SYSTEM_INPUT_CODE: () => import('@/components/design-system/Design-System-Input-Code.vue'),
  DESIGN_SYSTEM_CUSTOM_ICONS: () => import('@/components/design-system/Design-System-Custom-Icons.vue'),
  DESIGN_SYSTEM_CUSTOM_CODE: () => import('@/components/design-system/Design-System-Custom-Code.vue'),
  DESIGN_SYSTEM_SMART_REGEX: () => import('@/components/design-system/Design-System-Smart-Regex.vue'),
  DESIGN_SYSTEM_SMART_FEATURE_FLAGS: () => import('@/components/design-system/Design-System-Feature-Flags.vue'),
  DESIGN_SYSTEM_LABEL: () => import('@/components/design-system/Design-System-Label.vue'),
  DESIGN_SYSTEM_VUE_MACROS: () => import('@/components/design-system/design-system-vue-macros/Design-System-Vue-Macros.vue'),
  DESIGN_SYSTEM_S_BUTTON: () => import('@/components/design-system/Design-System-S-Button.vue'),
};

export { APP, MAIN_ROUTES, SUB_ROUTES };
